import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { ResourceCollection } from "@/modules/api/resource.collection";

import ProductionTimeNorm from "./production-time-norm.model";

export default class ProductionTimeNormRepository extends BasicRepository<ProductionTimeNorm> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ProductionTimeNorm>,
    responseParser: ApiCollectionResponseParserService<ProductionTimeNorm>
  ) {
    super("production_time_norms", api, objectBuilder, responseParser);
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<ResourceCollection<ProductionTimeNorm>> {
    return this.fetchList({ factory: factoryId });
  }

  public async updateProductionTimeNormsForFactory(
    factoryId: string,
    items: ProductionTimeNorm[]
  ): Promise<ProductionTimeNorm[]> {
    const itemsData = items.map((item) => this.prepareData(item));

    const response = await this.api.post(
      "production_time_norms/update_requests",
      {
        factory: factoryId,
        items: itemsData,
      }
    );

    const result = this.responseParser.parse(response.data, this.objectBuilder);

    return result.getItems();
  }

  protected prepareData(
    item: ProductionTimeNorm
  ): Record<string, string | number> {
    const data: Record<string, string | number> = {
      id: item.id,
      factory: item.factory,
      product: +item.product,
      days: item.days,
    };

    return data;
  }
}
