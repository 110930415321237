import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import QaAsset from "./qa-asset.model";
import { QaPhotoAngle } from "./qa-photo-angle";
import QaAssetType from "./qa-asset-type";

export default class QaAssetRepository extends BasicRepository<QaAsset> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<QaAsset>,
    responseParser: ApiCollectionResponseParserService<QaAsset>
  ) {
    super("qa_assets", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(plushieId: string): Promise<QaAsset[]> {
    const collection = await this.fetchList({ plushieId: plushieId });

    return collection.getItems();
  }

  public async createQaAsset(
    id: string,
    plushie: string,
    type: QaAssetType,
    storageItem: string,
    angle?: QaPhotoAngle,
    thumbnailStorageItemId?: string
  ): Promise<QaAsset> {
    const data: {
      id: string;
      plushieId: string;
      type: string;
      storageItemId: string;
      angle?: string;
      thumbnailStorageItemId?: string;
    } = {
      id,
      plushieId: plushie,
      type,
      storageItemId: storageItem,
    };

    if (type === QaAssetType.PHOTO) {
      if (!angle) {
        throw new Error("The Photo Angle field is required for photo assets");
      }

      data.angle = angle;
    }

    if (type === QaAssetType.VIDEO) {
      if (!thumbnailStorageItemId) {
        throw new Error(
          "The thumbnailStorageItemId is required for video assets"
        );
      }

      data.thumbnailStorageItemId = thumbnailStorageItemId;
    }

    const response = await this.api.post(this.resourceName, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: QaAsset): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    return data;
  }
}
