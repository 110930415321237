























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import rootStore from "@/store";
import PlushieStore from "@/modules/plushie/store";

import BulkProjectStore from "../store";
import BulkProject from "../bulk-project.model";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class BulkProjectInfo extends Vue {
  @Prop({ required: true })
  public readonly projectId!: string;

  private fBulkProjectStore: BulkProjectStore;
  private fPlushieStore: PlushieStore;

  private fIsLoading = true;

  get bulkProject(): BulkProject {
    const bulkProject = this.fBulkProjectStore.getBulkProjectById(
      this.projectId
    );

    if (!bulkProject) {
      throw new Error(
        `Data is not loaded for bulk project with ID: ${this.projectId}`
      );
    }

    return bulkProject;
  }

  get deadlineDate(): string {
    return this.bulkProject.deadlineDate
      ? this.bulkProject.deadlineDate.toLocaleDateString()
      : "";
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get productType(): string {
    const product = this.fPlushieStore.getProductById(this.bulkProject.product);

    return product ? product.name : "";
  }

  get statusName(): string {
    const status = this.fBulkProjectStore.getBulkProjectStatusById(
      this.bulkProject.status
    );

    return status ? status.name : "";
  }

  constructor() {
    super();
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    await Promise.all([
      this.fPlushieStore.loadProductById(this.bulkProject.product),
      this.fBulkProjectStore.loadBulkProjectStatuses(true),
    ]);

    this.fIsLoading = false;
  }
}
