


























import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";

import { Dictionary } from "@/lib/Dictionary.type";

import ProductionExtendedTable from "./ProductionExtendedTable.vue";

import ProductionEstimate from "../../production-estimate.interface";
import ProductionExtended from "../../production-extended.interface";
import ProductionExtendedRepository from "../../production-extended.repository";

const metricsOrder: Dictionary<number> = {
  holidaymeasured: 0,
  totalmeasured: 1,
  inprogress: 2,
  inreview: 3,
  readyforshipment: 4,
  totalinproduction: 5,
  productionweeks: 6,
  spotsremaining: 7,
};

@Component({
  components: {
    ProductionExtendedTable,
  },
})
export default class ProductionEstimatesTable extends Vue {
  @Prop({ default: false })
  public readonly isDisabled!: boolean;

  @Prop({ required: true })
  public readonly productionEstimatesList!: ProductionEstimate[];

  @Prop({ required: true })
  public readonly holidayPeriod!: string;

  @Inject("ProductionExtendedRepository")
  private fProductionExtendedRepository!: ProductionExtendedRepository;

  private fIsProductionExtendedDataLoaded = false;
  private fProductionExtendedList: ProductionExtended[] = [];

  get isProductionExtendedDataLoaded(): boolean {
    return this.fIsProductionExtendedDataLoaded;
  }

  get productionExtendedByProductIdDictionary(): Dictionary<
    ProductionExtended[]
  > {
    const dictionary: Dictionary<ProductionExtended[]> = {};

    this.fProductionExtendedList.forEach((item) => {
      if (!dictionary[item.product_id]) {
        dictionary[item.product_id] = [];
      }
      dictionary[item.product_id].push(item);
    });

    return dictionary;
  }

  get tableColumns(): string[] {
    const columns: string[] = [];

    if (!this.tableData[0]) {
      return columns;
    }

    Object.keys(this.tableData[0]).forEach((key) => {
      if (!["product", "product_id", "id"].includes(key)) {
        columns.push(key);
      }
    });

    return columns;
  }

  get tableData(): ProductionEstimate[] {
    return this.productionEstimatesList.map((productionEstimate) => {
      const item: ProductionEstimate = { ...productionEstimate };

      Object.entries(item).forEach(([key, value]) => {
        item[key] = value === "0" ? "" : value;
      });

      if (item.metric) {
        item.order = metricsOrder[item.metric.toLowerCase().replace(/ /g, "")];
      }

      return {
        ...item,
        id: productionEstimate.product_id + productionEstimate.metric,
      };
    });
  }

  get tableOptions(): TableOptions {
    return {
      rowClassCallback: (row: ProductionEstimate) => {
        if (row.metric === "In Progress") {
          return `-in-progress`;
        }
        return "-row";
      },
      columnsClasses: {
        metric: "-metric-column",
      },
      toggleGroups: true,
      filterable: false,
      groupBy: "product",
      orderBy: { column: "product", ascending: true },
      multiSorting: {
        product: [
          {
            column: "order",
            matchDir: true,
          },
        ],
      },
      perPage: 999,
      perPageValues: [],
      sortable: [],
    };
  }

  public onProductionExtendedTableCreated(): void {
    if (this.isProductionExtendedDataLoaded) {
      return;
    }

    void this.loadProductionExtendedStats(this.holidayPeriod);
  }

  private async loadProductionExtendedStats(holidayPeriod: string) {
    this.fProductionExtendedList = await this.fProductionExtendedRepository.fetchProductionExtendedList(
      { holidayPeriodId: Number.parseInt(holidayPeriod, 10) }
    );

    this.fIsProductionExtendedDataLoaded = true;
  }
}
