import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Message from "./message.model";
import { FieldOrderDesc } from "../api/field-order-options";
import MessageCreate from "./message-create.interface";
import MessageUpdate from "./message-update.interface";
import { ChannelValue } from "./channel.value";

export default class MessageRepository extends BasicRepository<Message> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Message>,
    responseParser: ApiCollectionResponseParserService<Message>
  ) {
    super("messages", api, objectBuilder, responseParser);
  }

  public async createMessage(message: MessageCreate): Promise<Message> {
    const data: Dictionary<string | number | boolean> = {
      id: message.id,
      channelId: message.channel,
      plushieId: message.plushie,
      text: message.text,
      isPrivate: false,
    };

    const response = await this.api.post(this.resourceName, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async getByPlushie(plushie: string): Promise<Message[]> {
    const collection = await this.fetchList(
      {
        plushieId: plushie,
      },
      1,
      999,
      [["createdAt", FieldOrderDesc]]
    );

    return collection.getItems();
  }

  public async getUnreadList(
    userId: string,
    userGroupId: string
  ): Promise<Message[]> {
    const collection = await this.fetchList(
      {
        isRead: "false",
        recipientId: [userId, "null"],
        recipientGroup: userGroupId,
        channel: [ChannelValue.STORE_CUSTOMER, ChannelValue.STORE_FACTORY],
      },
      1,
      100,
      [["createdAt", FieldOrderDesc]]
    );

    return collection.getItems();
  }

  public async markAsRead(id: string): Promise<Message | undefined> {
    const data: Dictionary<string[]> = {
      messagesIds: [id],
    };

    const response = await this.api.post(this.resourceName + "/read", data);

    const collection = this.responseParser.parse(
      response.data,
      this.objectBuilder
    );

    return collection.getByIndex(0);
  }

  public async markAsUnread(id: string): Promise<Message | undefined> {
    const data: Dictionary<string[]> = {
      messagesIds: [id],
    };

    const response = await this.api.post(this.resourceName + "/unread", data);

    const collection = this.responseParser.parse(
      response.data,
      this.objectBuilder
    );

    return collection.getByIndex(0);
  }

  public async updateMessage(message: MessageUpdate): Promise<Message> {
    const data: Dictionary<string> = {
      id: message.id,
      text: message.text,
    };

    const response = await this.api.patch(
      this.resourceName + "/" + message.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }
}
