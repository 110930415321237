














import { Component, Vue } from "vue-property-decorator";

import BuyerInfoEditForm from "./InfoEditForm.vue";

@Component({
  metaInfo: {
    title: "Store Billing Information",
  },
  components: {
    BuyerInfoEditForm,
  },
})
export default class BuyerInfoEditPage extends Vue {}
