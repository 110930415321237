import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class FactoryPlushieStatusCapacity implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fFactory: string,
    private fPlushieStatus: string,
    private fValue: number,
    private fUsePullMode: boolean
  ) {
    [fId, fFactory, fPlushieStatus, fValue, fUsePullMode].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get factory(): string {
    return this.fFactory;
  }

  get plushieStatus(): string {
    return this.fPlushieStatus;
  }

  get value(): number {
    return this.fValue;
  }

  get usePullMode(): boolean {
    return this.fUsePullMode;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
