import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import ApiService from "../api/api.service";
import BasicRepository from "../api/basic.repository";
import ObjectBuilderInterface from "../api/object-builder.interface";
import DirectShipmentIntention from "./direct-shipment-intention.model";

export default class DirectShipmentIntentionRepository extends BasicRepository<DirectShipmentIntention> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<DirectShipmentIntention>,
    responseParser: ApiCollectionResponseParserService<DirectShipmentIntention>
  ) {
    super("direct_shipment_intentions", api, objectBuilder, responseParser);
  }

  public async loadById(
    id: string
  ): Promise<DirectShipmentIntention | undefined> {
    const response = await this.getList(1, 1, { id });

    return response.getByIndex(0);
  }

  protected prepareData(
    directShipmentIntention: DirectShipmentIntention
  ): Record<string, string> {
    return {
      plushieId: directShipmentIntention.id,
    };
  }
}
