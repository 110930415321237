









































import { Component, Inject, Vue } from "vue-property-decorator";
import { RowClickEventData, TableOptions } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import AccountStore from "@/modules/account/store";

import MessagingStore from "../store";
import UserGroupResolverService from "../user-group-resolver.service";

interface TableRow {
  plushie: string;
  sku: string;
  createdAt: Date;
  from: string;
}

@Component({
  components: { LoadingSpinner, ActionButton },
})
export default class UnreadMessages extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fAuthenticatedUserProvider!: AuthenticatedUserProvider;

  @Inject("MessagingUserGroupResolverService")
  private fUserGroupResolverService!: UserGroupResolverService;

  private fMessagingStore: MessagingStore;
  private fPlushieStore: PlushieStore;
  private fAccountStore: AccountStore;

  private fLoading = false;

  get isLoading(): boolean {
    return this.fLoading;
  }

  get columns(): string[] {
    return ["sku", "from", "actions"];
  }

  get options(): TableOptions {
    return {
      filterable: false,
      orderBy: {
        column: "createdAt",
        ascending: true,
      },
      headings: {
        sku: "SKU",
        from: "From",
        actions: "",
      },
      columnsClasses: {
        sku: "_sku-column",
      },
      initialPage: 1,
      perPage: 8,
      perPageValues: [8],
    };
  }

  get tableData(): TableRow[] {
    const result: TableRow[] = [];

    const userUnreadMessages = this.fMessagingStore.unreadMessagesList;

    userUnreadMessages.forEach((message) => {
      const plushie = this.fPlushieStore.getPlushieById(message.plushie);
      const senderInfo = this.fAccountStore.getUserInfoById(message.sender);

      result.push({
        plushie: message.plushie,
        sku: plushie ? plushie.storeItemId : "",
        createdAt: message.createdAt,
        from: senderInfo ? senderInfo.shortName : "",
      });
    });

    return result;
  }

  public constructor() {
    super();

    this.fMessagingStore = getModule(MessagingStore, dataStore);
    this.fPlushieStore = getModule(PlushieStore, dataStore);
    this.fAccountStore = getModule(AccountStore, dataStore);
  }

  public async refresh(): Promise<void> {
    await this.loadTableData();
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    const plushieId = data.row.plushie;

    const route = this.$router.resolve({
      name: "PlushieViewPage",
      query: {
        id: plushieId,
      },
    });

    void this.$router.push(route.href);
  }

  protected async loadTableData(): Promise<void> {
    this.fLoading = true;

    try {
      const user = this.getCurrentUser();
      const userGroupId = await this.fUserGroupResolverService.resolveForUser(
        user
      );

      const messages = await this.fMessagingStore.loadUnreadMessages({
        userId: user.id,
        userGroupId: userGroupId,
      });

      const plushieIds = messages.map((message) => message.plushie);
      const userIds = messages.map((message) => message.sender);

      await Promise.all([
        this.fPlushieStore.loadPlushiesByIds(Array.from(new Set(plushieIds))),
        this.fAccountStore.loadUserInfosByIds(Array.from(new Set(userIds))),
      ]);
    } finally {
      this.fLoading = false;
    }
  }

  protected mounted(): void {
    void this.loadTableData();
  }

  private getCurrentUser(): AuthenticatedUser {
    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      throw new Error("The current user not found");
    }

    return user;
  }
}
