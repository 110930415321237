





























































import { Component, Inject, Prop, Vue } from "vue-property-decorator";

import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";

import ProductAllocationViewForm from "./ViewForm.vue";
import ProductAllocationEditForm from "./EditForm.vue";

import { ProductAllocationSlotTypeValue } from "../../product-allication-slot-type.value";

@Component({
  metaInfo() {
    return {
      title: "Product Allocation Rules",
    };
  },
  components: {
    ProductAllocationEditForm,
    ProductAllocationViewForm,
  },
})
export default class ProductAllocationEditPage extends Vue {
  @Prop({ required: true })
  public readonly slotType!: ProductAllocationSlotTypeValue;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsAllocationRulesExpanded = false;

  get isAllocationRulesExpanded(): boolean {
    return this.fIsAllocationRulesExpanded;
  }

  get pageHeader(): string {
    return this.slotType === ProductAllocationSlotTypeValue.PRODUCTION
      ? "Production Allocation Rules"
      : "Designers Allocation Rules";
  }

  get showEditForm(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCT_ALLOCATION_RULES_MANAGE
    );
  }

  get showViewForm(): boolean {
    if (this.showEditForm) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCT_ALLOCATION_RULES_VIEW
    );
  }

  public toggleAllocationRules(): void {
    this.fIsAllocationRulesExpanded = !this.fIsAllocationRulesExpanded;
  }
}
