import StatsDifference from "./stats-difference.interface";

export default function getStatsDifference(
  currentCount: number,
  previousCount: number,
  isReverseImprove = false
): StatsDifference {
  const difference = currentCount - previousCount;
  let value;

  if (!currentCount && !previousCount) {
    value = 0;
  } else if (!previousCount && currentCount) {
    value = 100;
  } else {
    value = (difference / previousCount) * 100;
  }

  let isImproved = value >= 0;

  if (isReverseImprove && value !== 0) {
    isImproved = !isImproved;
  }

  return {
    value,
    isImproved,
  };
}
