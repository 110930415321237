






























import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";

import FactoryInvoiceStore from "../../store";
import PenaltyRule from "../../penalty-rule.model";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class PenaltyRuleViewForm extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop({ default: true })
  public readonly showBackLink!: boolean;

  private fIsLoading = false;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fPlushieDataStore: PlushieStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get penaltyRules(): PenaltyRule[] {
    const rules = this.fFactoryInvoiceDataStore.getPenaltyRulesByFactoryId(
      this.factoryId
    );

    const products = this.fPlushieDataStore.productsList;

    const result: PenaltyRule[] = [];

    products.forEach((product) => {
      const item = rules.find((value) => value.product === product.id);

      if (!item) {
        return;
      }

      result.push(item);
    });

    return result;
  }

  constructor() {
    super();
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public getRuleProductName(rule: PenaltyRule): string {
    const product = this.fPlushieDataStore.getProductById(rule.product);

    return product ? product.name : "";
  }

  public onBackClick(): void {
    this.$emit("back");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fFactoryInvoiceDataStore.loadPenaltyRulesByFactoryId({
          factoryId: this.factoryId,
          useCache: false,
        }),
        this.fPlushieDataStore.loadProducts(),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
