import { ContentType } from "@/modules/file-storage/content-type";
import Item from "@/modules/file-storage/item.model";

import Plushie from "./plushie.model";

export default class PlushieStorageItemNameResolverService {
  public resolve(
    storageItem: Item,
    plushie: Plushie,
    imagePosition?: number
  ): string {
    const storageItemType = this.getStorageItemTypeText(
      Number(storageItem.type)
    );

    let imageName = `${plushie.storeItemId}_${storageItemType}_`;

    if (imagePosition !== undefined) {
      imageName += imagePosition.toString().padStart(3, "0") + "_";
    }

    if (storageItem.fileName) {
      imageName += `${storageItem.fileName}`;
    } else {
      imageName += `${storageItem.key}`;
    }

    return imageName;
  }

  private getStorageItemTypeText(storageItemType: ContentType): string {
    switch (storageItemType) {
      case ContentType.ARTWORK:
        return "artwork";
      case ContentType.QA_PHOTO:
      case ContentType.QA_VIDEO:
        return "qa-asset";
      case ContentType.BODYPART_IMAGE:
        return "body-part-image";
      case ContentType.ALERT_ICON:
        return "alert-icon";
      case ContentType.MESSAGES_ATTACHMENT:
        return "message-attachment";
      case ContentType.AIRWAY_BILL:
        return "airway-bill";
      case ContentType.BULK_REQUEST:
        return "bulk-request";
      case ContentType.QA_VIDEO_THUMB:
        return "qa-asset-thumb";
      case ContentType.PLUSHIE_ATTACHMENT:
        return "plushie-attachment";
    }
  }
}
