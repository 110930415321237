








































































import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import IdGenerator from "@/lib/services/id-generator";

import StrictCapacityModeStore from "../../store";
import { FactoryPlushieStatusCapacityUpdate } from "../../factory-plushie-status-capacity-update.interface";

@Component({
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class StrictCapacityModeEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public spotsInDesign?: number;
  public spotsInProduction?: number;
  public usePullMode?: boolean;

  private fIsLoading = true;

  private fFactoryStrictCapacityModeStore: StrictCapacityModeStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();

    this.fFactoryStrictCapacityModeStore = getModule(
      StrictCapacityModeStore,
      dataStore
    );
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      spotsInDesign: this.spotsInDesign,
      spotsInProduction: this.spotsInProduction,
      usePullMode: this.usePullMode,
    };
  }

  protected async performSubmit(): Promise<void> {
    const capacityUpdates: FactoryPlushieStatusCapacityUpdate[] = [];

    const inDesignCapacity = this.fFactoryStrictCapacityModeStore.getFactoryPlushieStatusCapacityForStatus(
      this.factoryId,
      PlushieStatusValue.IN_DESIGN
    );

    const inProductionCapacity = this.fFactoryStrictCapacityModeStore.getFactoryPlushieStatusCapacityForStatus(
      this.factoryId,
      PlushieStatusValue.IN_PRODUCTION
    );

    if (this.spotsInDesign) {
      capacityUpdates.push({
        id: inDesignCapacity ? inDesignCapacity.id : this.fIdGenerator.getId(),
        plushieStatus: PlushieStatusValue.IN_DESIGN,
        value: this.spotsInDesign,
        usePullMode: this.usePullMode == true,
      });
    }

    if (this.spotsInProduction) {
      capacityUpdates.push({
        id: inProductionCapacity
          ? inProductionCapacity.id
          : this.fIdGenerator.getId(),
        plushieStatus: PlushieStatusValue.IN_PRODUCTION,
        value: this.spotsInProduction,
        usePullMode: false,
      });
    }

    await this.fFactoryStrictCapacityModeStore.createFactoryPlushieStatusCapacitiesUpdateRequest(
      {
        factoryId: this.factoryId,
        capacityUpdates: capacityUpdates,
      }
    );

    this.$emit("updated");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    this.spotsInDesign = undefined;
    this.spotsInProduction = undefined;
    this.usePullMode = undefined;

    try {
      const capacities = await this.fFactoryStrictCapacityModeStore.loadFactoryPlushieStatusCapacitiesByFactoryId(
        { factoryId: this.factoryId }
      );

      capacities.forEach((capacity) => {
        if (capacity.plushieStatus == PlushieStatusValue.IN_DESIGN) {
          this.spotsInDesign = capacity.value;
          this.usePullMode = capacity.usePullMode;
        }

        if (capacity.plushieStatus == PlushieStatusValue.IN_PRODUCTION) {
          this.spotsInProduction = capacity.value;
        }
      });
    } finally {
      this.fIsLoading = false;
    }
  }
}
