import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import UpgradeAllocation from "./upgrade-allocation.model";
import { ResourceCollection } from "../api/resource.collection";

export default class UpgradeAllocationRepository extends BasicRepository<UpgradeAllocation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<UpgradeAllocation>,
    responseParser: ApiCollectionResponseParserService<UpgradeAllocation>
  ) {
    super("upgrade_allocations", api, objectBuilder, responseParser);
  }

  public async saveItems(
    items: UpgradeAllocation[]
  ): Promise<ResourceCollection<UpgradeAllocation>> {
    const data: Dictionary<string | boolean>[] = [];

    items.forEach((item) => {
      data.push(this.prepareData(item));
    });

    const response = await this.api.post(this.resourceName, data);

    return this.responseParser.parse(response.data, this.objectBuilder);
  }

  protected prepareData(item: UpgradeAllocation): Dictionary<string | boolean> {
    const data: Dictionary<string | boolean> = {
      id: item.id,
      factoryId: item.factory,
      upgradeId: item.upgrade,
      canProduce: item.canProduce,
    };

    return data;
  }
}
