import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieRelation from "./plushie-relation.model";

export default class PlushieRelationRepository extends BasicRepository<PlushieRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieRelation>,
    responseParser: ApiCollectionResponseParserService<PlushieRelation>
  ) {
    super(
      "holiday_period_plushie_relations",
      api,
      objectBuilder,
      responseParser
    );
  }

  protected prepareData(
    item: PlushieRelation
  ): Record<string, string | number> {
    const data: Record<string, string | number> = {
      id: item.id,
      period: +item.period,
    };

    return data;
  }
}
