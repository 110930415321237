import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import RemotePrintingConfig from "./remote-printing-config.model";
import RemotePrintingConfigUpdate from "./remote-printing-config-update.interface";

export default class RemotePrintingConfigRepository extends BasicRepository<RemotePrintingConfig> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<RemotePrintingConfig>,
    responseParser: ApiCollectionResponseParserService<RemotePrintingConfig>
  ) {
    super("barcode_printing_configs", api, objectBuilder, responseParser);
  }

  public async updateConfig(
    item: RemotePrintingConfigUpdate
  ): Promise<RemotePrintingConfig> {
    const data: Dictionary<string> = {};

    data.printerId = item.printer;

    const response = await this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: RemotePrintingConfig): Record<string, string> {
    const data: Dictionary<string> = {
      credentials: item.id,
      printerId: item.printer.toString(),
    };

    return data;
  }
}
