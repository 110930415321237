import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import StrictCapacityModeStore from "./store";

export default class IsUserUnderStrictModeResolver {
  private fFactoryDataStore: FactoryStore;
  private fStrictCapacityModeDataStore: StrictCapacityModeStore;

  public constructor(
    private fAuthenticatedUserProvider: AuthenticatedUserProvider
  ) {
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fStrictCapacityModeDataStore = getModule(
      StrictCapacityModeStore,
      dataStore
    );
  }

  public async resolve(): Promise<boolean> {
    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      throw new Error("The current user is not found");
    }

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return false;
    }

    const userFactoryRelation = await this.fFactoryDataStore.loadUserFactoryRelationByUserId(
      {
        userId: user.id,
      }
    );

    if (!userFactoryRelation) {
      return false;
    }

    return await this.fStrictCapacityModeDataStore.loadFactoryStrictCapacityModeById(
      userFactoryRelation.factory
    );
  }
}
