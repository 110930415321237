





























































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import Setting from "@/modules/settings/setting.model";
import SettingStore from "@/modules/settings/store";
import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

@Component({
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class DelayRulesEditForm extends mixins(GeneralFormMixin) {
  public daysBeforeWarningAlert?: string;
  public daysBeforeLockdownAlert?: string;

  private fIsLoading = true;

  private fSettingDataStore: SettingStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();
    this.fSettingDataStore = getModule(SettingStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      daysBeforeWarningAlert: this.daysBeforeWarningAlert,
      daysBeforeLockdownAlert: this.daysBeforeLockdownAlert,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (
      this.daysBeforeWarningAlert === undefined ||
      this.daysBeforeLockdownAlert === undefined
    ) {
      return;
    }

    await Promise.all([
      this.fSettingDataStore.updateSettingValue({
        id: Setting.FACTORY_RESTRICTED_MODE_DAYS_BEFORE_WARNING_ALERT,
        value: this.daysBeforeWarningAlert,
      }),
      this.fSettingDataStore.updateSettingValue({
        id: Setting.FACTORY_RESTRICTED_MODE_DAYS_BEFORE_LOCKDOWN_ALERT,
        value: this.daysBeforeLockdownAlert,
      }),
    ]);

    this.$emit("updated");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    this.daysBeforeWarningAlert = undefined;
    this.daysBeforeLockdownAlert = undefined;

    try {
      await this.fSettingDataStore.loadSettingsByIds([
        Setting.FACTORY_RESTRICTED_MODE_DAYS_BEFORE_WARNING_ALERT,
        Setting.FACTORY_RESTRICTED_MODE_DAYS_BEFORE_LOCKDOWN_ALERT,
      ]);

      const daysBeforeWarningSetting = this.fSettingDataStore.getSettingById(
        Setting.FACTORY_RESTRICTED_MODE_DAYS_BEFORE_WARNING_ALERT
      );

      if (daysBeforeWarningSetting && daysBeforeWarningSetting.value !== null) {
        this.daysBeforeWarningAlert = daysBeforeWarningSetting.value;
      }

      const daysBeforeLockdownSetting = this.fSettingDataStore.getSettingById(
        Setting.FACTORY_RESTRICTED_MODE_DAYS_BEFORE_LOCKDOWN_ALERT
      );

      if (
        daysBeforeLockdownSetting &&
        daysBeforeLockdownSetting.value !== null
      ) {
        this.daysBeforeLockdownAlert = daysBeforeLockdownSetting.value;
      }
    } finally {
      this.fIsLoading = false;
    }
  }
}
