import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ElementPriceHistoryRecord from "./element-price-history-record.model";

export default class ElementPriceHistoryRecordFactory
  implements ObjectBuilderInterface<ElementPriceHistoryRecord> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): ElementPriceHistoryRecord {
    const value = new ElementPriceHistoryRecord(
      data.id,
      data.userId,
      data.historyAction,
      data.factoryId,
      data.type,
      data.entityId,
      +data.qty,
      +data.price
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
