import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PenaltyRuleHistoryRecord implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fUser: string,
    private fAction: string,
    private fFactory: string,
    private fProduct: string,
    private fProductionDaysDelay: number,
    private fPenaltyPercent: number
  ) {
    [
      fId,
      fUser,
      fAction,
      fFactory,
      fProduct,
      fProductionDaysDelay,
      fPenaltyPercent,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get user(): string {
    return this.fUser;
  }

  get action(): string {
    return this.fAction;
  }

  get factory(): string {
    return this.fFactory;
  }

  get product(): string {
    return this.fProduct;
  }

  get productionDaysDelay(): number {
    return this.fProductionDaysDelay;
  }

  get penaltyPercent(): number {
    return this.fPenaltyPercent;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
