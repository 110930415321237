import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import InReviewSnapshot from "./in-review-snapshot.model";

export default class InReviewSnapshotRepository {
  private readonly fResourceName = "in_review_snapshot_stats";

  constructor(
    private api: ApiService,
    private objectBuilder: ObjectBuilderInterface<InReviewSnapshot>
  ) {}

  public async fetchInReviewSnapshots(): Promise<InReviewSnapshot[]> {
    const response = await this.api.get(this.fResourceName);

    return (response.data as any[]).map((item) =>
      this.objectBuilder.buildFromJSON(item)
    );
  }
}
