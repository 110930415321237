import { ValueCollection } from "@/modules/api/value.collection";
import { Value } from "@/modules/api/value.interface";

export class ResourceCollection<T extends Value> extends ValueCollection<T> {
  constructor(
    items: T[],
    readonly totalItemsCount: number,
    readonly page: number,
    readonly totalPages: number,
    readonly perPage: number
  ) {
    super(items);
  }
}
