import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Carrier implements MutableValue {
  public isNew = true;
  public createdAt?: Date;

  constructor(
    private fId: string,
    private fName: string,
    private fCode: string
  ) {
    [fId, fName, fCode].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get name(): string {
    return this.fName;
  }

  get code(): string {
    return this.fCode;
  }
}
