import ObjectBuilderInterface from "../api/object-builder.interface";
import Sender from "./sender.model";

export default class SenderFactory implements ObjectBuilderInterface<Sender> {
  public buildFromJSON(data: { [key: string]: any }): Sender {
    const value = new Sender(data.id, data.email, data.name);

    value.isNew = false;

    return value;
  }
}
