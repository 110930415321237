import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieArbitraryAttribute from "./plushie-arbitrary-attribute.model";

export default class PlushieArbitraryAttributeRepository extends BasicRepository<PlushieArbitraryAttribute> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieArbitraryAttribute>,
    responseParser: ApiCollectionResponseParserService<PlushieArbitraryAttribute>
  ) {
    super("plushie_arbitrary_attributes", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(
    plushieId: string
  ): Promise<PlushieArbitraryAttribute[]> {
    const collection = await this.fetchList({ plushie: plushieId });

    return collection.getItems();
  }
}
