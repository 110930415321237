export enum ContentType {
  ARTWORK = 1,
  QA_PHOTO = 2,
  BODYPART_IMAGE = 3,
  ALERT_ICON = 4,
  MESSAGES_ATTACHMENT = 5,
  AIRWAY_BILL = 6,
  BULK_REQUEST = 7,
  QA_VIDEO = 8,
  QA_VIDEO_THUMB = 9,
  PLUSHIE_ATTACHMENT = 10,
}
