










import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import Plushie from "@/modules/plushie/plushie.model";
import { Dictionary } from "@/lib/Dictionary.type";

interface Item {
  name: string;
  qty: number;
}

@Component({
  components: {},
})
export default class CreateBulkShipmentFormSummary extends Vue {
  @Prop({ required: true })
  public readonly plushieIds!: string[];

  private fPlushieDataStore: PlushieStore;

  get plushies(): Plushie[] {
    return this.fPlushieDataStore.getPlushiesByIds(this.plushieIds);
  }

  get totalSkuQty(): number {
    let qty = 0;
    this.plushies.forEach((plushie) => {
      qty += plushie.quantity;
    });

    return qty;
  }

  get upgradesSummary(): Dictionary<Item> {
    const items: Dictionary<Item> = {};
    this.plushies.forEach((plushie) => {
      const upgradeIds = this.fPlushieDataStore.getPlushieUpgradesByPlushieId(
        plushie.id
      );

      const upgrades = this.fPlushieDataStore.getUpgradesByIds(upgradeIds);

      upgrades.forEach((upgrade) => {
        if (items[upgrade.id]) {
          items[upgrade.id].qty += 1;
          return;
        }

        items[upgrade.id] = {
          qty: 1,
          name: upgrade.shortName || upgrade.name,
        };
      });
    });

    return items;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }
}
