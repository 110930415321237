import TagSearchPage from "./components/TagSearchPage.vue";

export default [
  {
    path: "/tag_search",
    name: "TagSearchPage",
    component: TagSearchPage,
    meta: {
      auth: true,
    },
  },
];
