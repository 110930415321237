import { Dictionary } from "@/lib/Dictionary.type";

import { ApiFilterValue } from "../api/api-filter-value.type";
import { QueryOrderParameter } from "../api/query-order-parameter";
import PlushiesGridExportJobRepository from "./plushies-grid-export-job.repository";

export default class PlushiesGridExportService {
  private fWindow: Window;
  private fPlushiesGridExportJobRepository: PlushiesGridExportJobRepository;

  constructor(
    plushiesGridExportJobRepository: PlushiesGridExportJobRepository,
    window: Window
  ) {
    this.fPlushiesGridExportJobRepository = plushiesGridExportJobRepository;
    this.fWindow = window;
  }

  public async execute(
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<boolean> {
    const downloadUrl = await this.fPlushiesGridExportJobRepository.createPlushiesGridExportJob(
      filter,
      order
    );

    this.fWindow.location.href = downloadUrl;

    return true;
  }
}
