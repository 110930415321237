


































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import AccountStore from "../store";
import UserRole from "../user-role.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class UserPermissionsEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly userId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public role?: string;

  private fAccountDataStore: AccountStore;

  private fUserRole?: UserRole;

  get rolesOptions(): DirectoryValue[] {
    return this.fAccountDataStore.rolesOrderedByName;
  }

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  protected data(): Record<string, unknown> {
    return {
      role: this.role,
    };
  }

  protected mounted(): void {
    void this.fAccountDataStore.loadRoles();
  }

  protected async performSubmit(): Promise<void> {
    let userRole = this.generateUserRoleObject();

    if (!userRole.isNew) {
      await this.fAccountDataStore.deleteUserRoleById(userRole.id);
      userRole.isNew = true;
    }

    userRole = await this.fAccountDataStore.saveUserRole(userRole);
    this.fillFormFromUserRole(userRole);

    this.$emit("updated", userRole);
  }

  private generateUserRoleObject(): UserRole {
    if (!this.role) {
      throw new Error("Unable to create object - the 'role' field is missing!");
    }

    const id = this.fUserRole ? this.fUserRole.id : this.fIdGenerator.getId();

    const value = new UserRole(id, this.userId, this.role);

    if (this.fUserRole) {
      value.isNew = false;
    }

    return value;
  }

  private fillFormFromUserRole(userRole: UserRole) {
    this.fUserRole = userRole;
    this.role = userRole.role;
  }

  @Watch("userId", { immediate: true })
  private async _onUserIdChange() {
    this.role = undefined;
    this.fUserRole = undefined;

    const userRole = await this.fAccountDataStore.loadUserRoleByUserId({
      userId: this.userId,
      useCache: false,
    });

    if (userRole === undefined) {
      return;
    }

    this.fUserRole = userRole;

    this.fillFormFromUserRole(userRole);
  }
}
