import { Route } from "vue-router";

import EmailLayoutsListPage from "./components/EmailLayoutsListPage.vue";
import EmailLayoutEditPage from "./components/EmailLayoutEditPage.vue";
import EmailTemplatesListPage from "./components/EmailTemplatesListPage.vue";
import EmailTemplateEditPage from "./components/EmailTemplateEditPage.vue";

export default [
  {
    path: "/email-layout/list",
    name: "EmailLayoutsListPage",
    component: EmailLayoutsListPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/email-layout/edit",
    name: "EmailLayoutEditPage",
    component: EmailLayoutEditPage,
    props: (route: Route): Record<string, string | (string | null)[]> => {
      return {
        layoutId: route.query.id,
      };
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/email-template/list",
    name: "EmailTemplatesListPage",
    component: EmailTemplatesListPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/email-template/edit",
    name: "EmailTemplateEditPage",
    component: EmailTemplateEditPage,
    props: (route: Route): Record<string, string | (string | null)[]> => {
      return {
        templateId: route.query.id,
      };
    },
    meta: {
      auth: true,
    },
  },
];
