import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Payment implements MutableValue {
  public isNew = true;

  public notes?: string;

  private fCreatedAt?: Date;
  private fUser?: string;

  constructor(
    private fId: string,
    private fInvoice: string,
    private fPaidAt: Date,
    private fType: string,
    private fConfirmationNumber: string
  ) {
    [fId, fInvoice, fPaidAt, fType, fConfirmationNumber].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get user(): string | undefined {
    return this.fUser;
  }

  get invoice(): string {
    return this.fInvoice;
  }

  get paidAt(): Date {
    return this.fPaidAt;
  }

  get type(): string {
    return this.fType;
  }

  get confirmationNumber(): string {
    return this.fConfirmationNumber;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
