




















import Vue, { PropType } from "vue";

import { Dictionary } from "@/lib/Dictionary.type";

import ProductionTimeNormHistoryItem from "./Item.vue";

import HistoryItem from "../../../production-time-norm-history-item.model";

export default Vue.extend({
  name: "ProductionTimeNormHistoryItemsGroup",
  components: {
    ProductionTimeNormHistoryItem,
  },
  props: {
    historyItems: {
      type: Array as PropType<HistoryItem[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    restoreValues(): void {
      this.$emit("restore-values", this.getProductDaysDictionary());
    },
    getProductDaysDictionary(): Dictionary<number> {
      const dictionary: Dictionary<number> = {};

      for (const historyItem of this.historyItems) {
        dictionary[historyItem.product] = historyItem.days;
      }

      return dictionary;
    },
  },
});
