import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProjectionStats from "./projection-stats.model";

export default class ProjectionStatsRepository {
  private readonly fResourceName = "projection_stats";

  constructor(
    private api: ApiService,
    private objectBuilder: ObjectBuilderInterface<ProjectionStats>
  ) {}

  public async fetchProjectionStatsList({
    holidayPeriodId,
    weeksRemaining,
  }: {
    holidayPeriodId: number;
    weeksRemaining: number;
  }): Promise<ProjectionStats[]> {
    const response = await this.api.get(this.fResourceName, {
      holidayPeriodId,
      weeksRemaining,
    });

    return (response.data as any[]).map((item) =>
      this.objectBuilder.buildFromJSON(item)
    );
  }
}
