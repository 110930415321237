import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class UserGroupRelation implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fUser: string,
    private fUserGroup: string
  ) {
    [fId, fUser, fUserGroup].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get user(): string {
    return this.fUser;
  }

  get userGroup(): string {
    return this.fUserGroup;
  }
}
