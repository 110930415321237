import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Item from "./item.model";

export default class ItemFactory implements ObjectBuilderInterface<Item> {
  public buildFromJSON(data: { [key: string]: any }): Item {
    const value = new Item(
      data.id,
      data.type.toString(),
      data.url,
      data.timestampedUrl,
      data.mime,
      data.key,
      data.fileName
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
