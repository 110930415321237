import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class User implements MutableValue {
  public isNew = true;
  public password?: string;

  private fCreatedAt?: Date;

  constructor(private fId: string, private fLogin: string) {
    [fId, fLogin].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get login() {
    return this.fLogin;
  }
}
