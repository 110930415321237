import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Organization from "./organization.model";

export default class OrganizationFactory
  implements ObjectBuilderInterface<Organization> {
  public buildFromJSON(data: { [key: string]: any }): Organization {
    const value = new Organization(data.id, data.name, data.type);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
