
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Paginate from "vuejs-paginate";

@Component({
  components: {
    Paginate,
  },
})
export default class ListPaginator extends Vue {
  @Prop({ required: true })
  pageCount!: number;

  @Prop({ required: true })
  value!: number;

  get currentPage(): number {
    return this.value;
  }

  set currentPage(value: number) {
    this.$emit("input", value);
  }
}
