export enum ProductValue {
  BUDSIE = "1",
  SELFIE = "2",
  BULK_SAMPLE = "3",
  SPECIALITY_COMMISSION = "4",
  PETSIE_CAT = "5",
  PETSIE_DOG = "6",
  PETSIE_OTHER = "7",
  IMAGINABLE = "8",
  HUGGABLES_CAT = "9",
  HUGGABLES_DOG = "10",
  HUGGABLES_OTHER = "11",
  FOREVERS_CAT = "12",
  FOREVERS_DOG = "13",
  FOREVERS_OTHER = "14",
  WAGGABLES_DOG = "15",
  PILLOW = "16",
  PILLOW_BULK_SAMPLE = "17",
  PRINTED_SOCKS = "18",
  BUDSIES_PALS = "19",
  BUDDY_PILLOW = "20",
  PHRASE_PILLOW = "21",
  PRINTED_MASKS = "22",
  PRINTED_KEYCHAINS = "23",
  BUDSIES_PUPPET = "24",
  SELFIES_PUPPET = "25",
  CARTOON_PILLOW = "26",
  FELTED_MAGNETS = "27",
  FELTED_ORNAMENTS = "28",
  RENAISSANCE_BLANKETS = "29",
  CUT_OUT_BLANKETS = "30",
  KEYCHAIN_BULK_SAMPLE = "31",
  BOBBLEHEADS = "32",
  FIGURINES = "33",
  PETSIES_BOBBLEHEADS = "34",
  PETSIES_FIGURINES = "35",
  PAJAMAS = "36",
  GOLF_HEAD_COVER_DOG = "37",
  GOLF_HEAD_COVER_CAT = "38",
  GOLF_HEAD_COVER_OTHER = "39",
  PLUSHIE_BATCH = "40",
  PILLOW_BATCH = "41",
  KEYCHAIN_BATCH = "42",
  GOLF_SHIRTS = "43",
  HAWAIIAN_SHIRTS = "44",
  CUSTOMER_DESIGN_BLANKETS = "45",
  TUMBLERS = "46",
  PHOTO_PORTRAITS = "47",
}
