import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieDesignInformation from "./plushie-design-information.model";
import PlushieDesignInformationUpdate from "./plushie-design-information-update.interface";

export default class PlushieDesignInformationRepository extends BasicRepository<PlushieDesignInformation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieDesignInformation>,
    responseParser: ApiCollectionResponseParserService<PlushieDesignInformation>
  ) {
    super("plushie_design_informations", api, objectBuilder, responseParser);
  }

  public async updatePlushieDesignInformation(
    item: PlushieDesignInformationUpdate
  ): Promise<PlushieDesignInformation> {
    const data: Dictionary<string | number> = {};

    if (item.comment) {
      data.comment = item.comment;
    }

    if (item.commentTranslation) {
      data.commentTranslation = item.commentTranslation;
    }

    const response = await this.api.put(
      this.resourceName + "/" + item.plushieId,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(
    item: PlushieDesignInformation
  ): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    if (item.comment) {
      data.comment = item.comment;
    }

    if (item.commentTranslation) {
      data.commentTranslation = item.commentTranslation;
    }

    if (item.descriptionTranslation) {
      data.descriptionTranslation = item.descriptionTranslation;
    }

    return data;
  }
}
