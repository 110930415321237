import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Setting from "./setting.model";
import SettingUpdateInterface from "./setting-update.interface";

export default class SettingRepository extends BasicRepository<Setting> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Setting>,
    responseParser: ApiCollectionResponseParserService<Setting>
  ) {
    super("settings", api, objectBuilder, responseParser);
  }

  public async getByIds(
    ids: string[]
  ): Promise<{ [key: string]: Setting | undefined }> {
    const result: { [key: string]: Setting | undefined } = {};

    if (!ids.length) {
      return result;
    }

    const items = await this.fetchList({ code: ids }, 1, ids.length);

    items.getItems().forEach((item) => {
      result[item.id] = item;
    });

    return result;
  }

  public async updateSettingValue(
    item: SettingUpdateInterface
  ): Promise<Setting> {
    const data: Dictionary<string | null> = {
      value: item.value,
    };

    const response = await this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }
}
