












































































































import { Component, Inject, Mixins, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import IdGenerator from "@/lib/services/id-generator";
import FactoryStore from "@/modules/factory/store";
import PlushieStore from "@/modules/plushie/store";
import Factory from "@/modules/factory/factory.model";
import Product from "@/modules/plushie/product.model";
import Upgrade from "@/modules/plushie/upgrade.model";

import { DirectShipmentIntentionRuleOrderType } from "../../direct-shipment-intention-rule-order-type";
import ProductionProcessStore from "../../store";
import DirectShipmentIntentionRule from "../../direct-shipment-intention-rule.model";
import { DirectShipmentIntentionRuleUpdateData } from "../../direct-shipment-intention-rule-update-data.interface";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class DirectShipmentIntentionRuleEditForm extends Mixins(
  GeneralFormMixin
) {
  @Prop({ default: undefined })
  public readonly directShipmentIntentionRule?: DirectShipmentIntentionRule;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public name?: string;
  public factory?: string;
  public product?: string;
  public upgrade?: string;
  public forOrderType?: DirectShipmentIntentionRuleOrderType;

  private fFactoryStore: FactoryStore;
  private fPlushieStore: PlushieStore;
  private fProductionProcessStore: ProductionProcessStore;

  get factoryOptions(): Factory[] {
    return this.fFactoryStore.activeFactoriesList;
  }

  get orderTypeOptions(): DirectShipmentIntentionRuleOrderType[] {
    return Object.values(DirectShipmentIntentionRuleOrderType);
  }

  get productOptions(): Product[] {
    return this.fPlushieStore.activeProductsList;
  }

  get upgradeOptions(): Upgrade[] {
    return this.fPlushieStore.upgradesList;
  }

  constructor() {
    super();

    this.fFactoryStore = getModule(FactoryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);
  }

  public onCancelClick(): void {
    void this.$router.push({
      name: "DirectShipmentIntentionRulesListPage",
    });
  }

  protected created(): void {
    void this.loadData();

    if (!this.directShipmentIntentionRule) {
      return;
    }

    this.fillFormData(this.directShipmentIntentionRule);
  }

  protected data(): Record<string, string | undefined> {
    return {
      name: this.name,
      factory: this.factory,
      product: this.product,
      upgrade: this.upgrade,
      forOrderType: this.forOrderType,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.directShipmentIntentionRule) {
      await this.fProductionProcessStore.saveDirectShipmentIntentionRule(
        this.generateDirectShipmentIntentionRule(
          this.directShipmentIntentionRule
        )
      );
    } else {
      await this.fProductionProcessStore.updateDirectShipmentIntentionRuleData({
        id: this.directShipmentIntentionRule.id,
        directShipmentIntentionRuleUpdateData: this.generateDirectShipmentIntentionRuleUpdateData(),
      });
    }

    void this.$router.push({
      name: "DirectShipmentIntentionRulesListPage",
    });
  }

  private generateDirectShipmentIntentionRule(
    existingRule: DirectShipmentIntentionRule | undefined
  ): DirectShipmentIntentionRule {
    if (!this.name || !this.factory || !this.forOrderType) {
      throw new Error("Not all required fields is filled");
    }

    const id = existingRule ? existingRule.id : this.fIdGenerator.getId();

    const directShipmentIntentionRule = new DirectShipmentIntentionRule(
      id,
      this.name,
      this.factory,
      this.forOrderType,
      this.product,
      this.upgrade
    );

    if (existingRule) {
      directShipmentIntentionRule.isNew = false;
    }

    return directShipmentIntentionRule;
  }

  private generateDirectShipmentIntentionRuleUpdateData(): DirectShipmentIntentionRuleUpdateData {
    if (!this.name || !this.factory || !this.forOrderType) {
      throw new Error("Not all required fields is filled");
    }

    return {
      name: this.name,
      factoryId: this.factory,
      forOrderType: this.forOrderType,
      upgradeId: this.upgrade || null,
      productId: this.product || null,
    };
  }

  private fillFormData(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): void {
    this.name = directShipmentIntentionRule.name;
    this.factory = directShipmentIntentionRule.factory;
    this.forOrderType = directShipmentIntentionRule.forOrderType;
    this.product = directShipmentIntentionRule.product;
    this.upgrade = directShipmentIntentionRule.upgrade;
  }

  private async loadData(): Promise<void> {
    await Promise.all([
      this.fPlushieStore.loadProducts(),
      this.fFactoryStore.loadFactories(),
      this.fPlushieStore.loadUpgrades(),
    ]);
  }
}
