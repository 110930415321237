import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { ResourceCollection } from "@/modules/api/resource.collection";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import RoleResource from "./role-resource.model";

export default class RoleResourceRepository extends BasicRepository<RoleResource> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<RoleResource>,
    responseParser: ApiCollectionResponseParserService<RoleResource>
  ) {
    super("role_resources", api, objectBuilder, responseParser);
  }

  public async getByRoleId(
    roleId: string
  ): Promise<ResourceCollection<RoleResource>> {
    return this.fetchList({ role: roleId });
  }

  protected prepareData(item: RoleResource): Record<string, string> {
    return {
      id: item.id,
      role: item.role,
      resource: item.resource,
    };
  }
}
