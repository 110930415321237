





























import "filepond/dist/filepond.min.css";

import { Component, Prop, Inject, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import vueFilePond, { VueFilePondComponent } from "vue-filepond";
import { File as FilePondFile } from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import FormErrors from "@/lib/components/FormErrors.vue";

import Plushie from "@/modules/plushie/plushie.model";
import { FileProcessingImageType } from "@/modules/file-storage/file-processing-image-type";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import QualityInspectionStore from "@/modules/quality-inspection/store";
import ProductionProcessStore from "@/modules/production-process/store";
import FileStorageStore from "@/modules/file-storage/store";
import PlushieStore from "@/modules/plushie/store";
import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import IdGenerator from "@/lib/services/id-generator";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import { ProductValue } from "@/modules/plushie/product.value";

import { QaPhotoAngle } from "../qa-photo-angle";
import QaAssetType from "../qa-asset-type";

// Create component
const FilePondComponent = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

interface FilePondErrorDescription {
  type: string;
  code: number;
  body: string;
}

const disableResizeForProducts = [
  ProductValue.CARTOON_PILLOW,
  ProductValue.PILLOW,
  ProductValue.BUDDY_PILLOW,
  ProductValue.CUT_OUT_BLANKETS,
  ProductValue.RENAISSANCE_BLANKETS,
  ProductValue.PHOTO_PORTRAITS,
];

@Component({
  components: {
    FormErrors,
    FilePond: FilePondComponent,
  },
})
export default class UploadQaAssetsCommand extends Vue {
  @Prop({ default: false })
  public readonly disable!: boolean;

  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fMoveToQAPromise?: Promise<Plushie>;

  private fUploadErrors: string[] = [];

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;
  private fProductionProcessDataStore: ProductionProcessStore;
  private fQualityInspectionDataStore: QualityInspectionStore;

  get isAllowImageResize(): boolean {
    return !disableResizeForProducts.includes(this.plushie.product);
  }

  get mimeType(): string | null {
    if (
      [
        ProductValue.CARTOON_PILLOW,
        ProductValue.PILLOW,
        ProductValue.BUDDY_PILLOW,
      ].includes(this.plushie.product)
    ) {
      return null;
    }

    return "image/jpeg";
  }

  get plushie(): Plushie {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      throw new Error("Plushie not found!!!");
    }

    return plushie;
  }

  get uploadErrors(): string[] {
    return this.fUploadErrors;
  }

  constructor() {
    super();
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);

    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  public browseFiles(): void {
    const fileInput = this.getFileInput();
    if (!fileInput) {
      return;
    }

    fileInput.browse();
    this.fUploadErrors = [];
  }

  public async detectFileType(source: File, type: string): Promise<string> {
    if (type) {
      return type;
    }

    const extension = source.name.toLowerCase().split(".").pop();
    if (!extension) {
      return "";
    }

    if (extension === "mp4") {
      return "video/" + extension;
    }

    return "image/" + extension;
  }

  public processUpload(
    fieldName: string,
    file: File,
    metadata: Dictionary<any>,
    load: (id: string) => void,
    error: (errorText: string) => void,
    progress: (
      lengthComputable: boolean,
      loaded: number,
      total: number
    ) => void,
    abort: () => void
    // transfer: (transferId: string) => void,
    // options: any
  ): { abort: () => void } | void {
    let isAborted = false;

    void (async () => {
      try {
        const isVideo = ["video/mp4", "video/quicktime"].includes(file.type);

        let plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

        if (!plushie) {
          throw new Error("Plushie not found!!!");
        }

        const data = {
          file,
          onUploadProgress: (e: ProgressEvent) => {
            progress(e.lengthComputable, e.loaded, e.total);
          },
        };

        let item;
        let itemThumb;

        if (isVideo) {
          const uploadResult = await this.fFileStorageDataStore.uploadVideoFile(
            data
          );
          item = uploadResult.item;
          itemThumb = uploadResult.itemThumb;
        } else {
          item = await this.fFileStorageDataStore.uploadFile({
            ...data,
            imageType: FileProcessingImageType.QaPhoto,
            product: plushie.product,
          });
        }

        if (isAborted) {
          void this.fFileStorageDataStore.deleteItemById(item.id);

          if (itemThumb) {
            void this.fFileStorageDataStore.deleteItemById(itemThumb.id);
          }

          return;
        }

        let moveToQaInspectionRequestMethod;

        switch (plushie.status) {
          case PlushieStatusValue.IN_PRODUCTION:
          case PlushieStatusValue.IN_DESIGN:
            moveToQaInspectionRequestMethod = this.fProductionProcessDataStore.MoveToQualityInspection.bind(
              this
            );
            break;
          case PlushieStatusValue.IN_BULK_DESIGN:
            moveToQaInspectionRequestMethod = this.fProductionProcessDataStore.moveToPpsInspection.bind(
              this
            );
            break;
          case PlushieStatusValue.IN_BULK_PRODUCTION:
            moveToQaInspectionRequestMethod = this.fProductionProcessDataStore.moveToBulkInspection.bind(
              this
            );
            break;
        }

        if (moveToQaInspectionRequestMethod) {
          if (!this.fMoveToQAPromise) {
            const promise = moveToQaInspectionRequestMethod(plushie.id);

            this.fMoveToQAPromise = promise;
          }

          try {
            plushie = await this.fMoveToQAPromise;
          } finally {
            this.fMoveToQAPromise = undefined;
          }
        }

        if (isAborted) {
          void this.fFileStorageDataStore.deleteItemById(item.id);

          if (itemThumb) {
            void this.fFileStorageDataStore.deleteItemById(itemThumb.id);
          }

          return;
        }

        const qaAsset = await this.fQualityInspectionDataStore.createQaAsset({
          id: this.fIdGenerator.getId(),
          plushie: plushie.id,
          type: isVideo ? QaAssetType.VIDEO : QaAssetType.PHOTO,
          storageItem: item.id,
          angle: isVideo ? undefined : QaPhotoAngle.OTHER,
          thumbnailStorageItemId: itemThumb ? itemThumb.id : undefined,
        });

        load(qaAsset.id);
      } catch (e) {
        const errors = this.fErrorConverterService.describeError(e);
        this.fUploadErrors.push(`"${file.name}" - ` + errors.join(", "));
        //console.error(e);
        error(errors[0]);
      }
    })();

    return {
      abort: () => {
        isAborted = true;
        abort();
      },
    };
  }

  public onFileProcessed(
    error: FilePondErrorDescription | null,
    file: FilePondFile
  ): void {
    if (error) {
      return;
    }

    setTimeout(() => {
      const fileInput = this.getFileInput();
      if (!fileInput) {
        return;
      }

      fileInput.removeFile(file);
    }, 200);
  }

  private getFileInput(): VueFilePondComponent | undefined {
    return (this.$refs["file-input"] as unknown) as VueFilePondComponent;
  }

  @Watch("plushieId")
  private _onPlushieIdChange() {
    this.fMoveToQAPromise = undefined;
  }
}
