











import Vue from "vue";
import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import IdGenerator from "@/lib/services/id-generator";
import User from "@/modules/account/user.model";
import Plushie from "@/modules/plushie/plushie.model";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import UserActiveStateProvider from "@/modules/user-activity-notifier/user-active-state-provider";
import dataStore from "@/store";

import StatisticsStore from "../store";
import OperatorSession from "../operator-session.model";

interface TimeOptionValue {
  medium: number;
  well: number;
}

enum AvailablePlushieStatusValues {
  QUALITY_INSPECTION = PlushieStatusValue.QUALITY_INSPECTION,
  BULK_INSPECTION = PlushieStatusValue.BULK_INSPECTION,
  PPS_INSPECTION = PlushieStatusValue.PPS_INSPECTION,
  REVIEW = PlushieStatusValue.REVIEW,
}

const timeOptions: {
  [key in PlushieStatusValue]?: TimeOptionValue;
} = {
  [AvailablePlushieStatusValues.QUALITY_INSPECTION]: {
    medium: 2.5,
    well: 1.5,
  },
  [AvailablePlushieStatusValues.BULK_INSPECTION]: {
    medium: 2.5,
    well: 1.5,
  },
  [AvailablePlushieStatusValues.PPS_INSPECTION]: {
    medium: 2.5,
    well: 1.5,
  },
  [AvailablePlushieStatusValues.REVIEW]: {
    medium: 7.5,
    well: 5,
  },
};

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class OperatorSessionTimer extends Vue {
  @Prop({ required: true })
  public readonly plushie!: Plushie;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("UserActiveStateProvider")
  private fUserActiveStateProvider!: UserActiveStateProvider;

  private fStatisticsStore: StatisticsStore;

  private fIsLoading = false;
  private fIsOperatorSessionSaving = false;
  private fUser: User;

  private fTimeSpent = 0;
  private fTimeSpentUpdateIntervalId?: number;

  private fOnPageHideHandler?: () => void;
  private fOnVisibilityChangeHandler?: (event: any) => void;
  private fOnUserStateChangeHandler?: () => void;

  get formattedTotalTimeSpent(): string {
    if (!this.totalTimeSpent) {
      return "0:00";
    }

    const minutes = Math.floor(this.totalTimeSpent / 60);
    let seconds: number | string = this.totalTimeSpent - minutes * 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isTimeMedium(): boolean {
    if (!this.timeOptionValueByPlushieStatus) {
      return false;
    }

    return (
      this.totalTimeSpent <= this.timeOptionValueByPlushieStatus.medium * 60
    );
  }

  get isTimeWell(): boolean {
    if (!this.timeOptionValueByPlushieStatus) {
      return false;
    }

    return this.totalTimeSpent <= this.timeOptionValueByPlushieStatus.well * 60;
  }

  get isUserActive(): boolean {
    return this.fUserActiveStateProvider.isActive;
  }

  get operatorSessions(): OperatorSession[] {
    return this.fStatisticsStore.operatorSessionsByPlushieIdAndStatus({
      plushieId: this.plushie.id,
      plushieStatus: this.plushie.status,
    });
  }

  get operatorSessionsTimeSpent(): number {
    return this.operatorSessions.reduce(
      (acc, value) => acc + value.timeSpent,
      0
    );
  }

  get showTimer(): boolean {
    return !this.fIsOperatorSessionSaving && !this.isLoading;
  }

  get timeModificatorClass(): string | undefined {
    let modificatorClass = "-bad";

    if (this.isTimeMedium) {
      modificatorClass = "-medium";
    }

    if (this.isTimeWell) {
      modificatorClass = "-well";
    }

    return modificatorClass;
  }

  get timeOptionValueByPlushieStatus(): TimeOptionValue | undefined {
    return timeOptions[this.plushie.status];
  }

  get totalTimeSpent(): number {
    return this.fTimeSpent + this.operatorSessionsTimeSpent;
  }

  constructor() {
    super();
    this.fStatisticsStore = getModule(StatisticsStore, dataStore);

    const user = this.$auth.user();

    if (!user) {
      throw new Error("Unable to acquire current user!");
    }

    this.fUser = user;
  }

  protected async created(): Promise<void> {
    this.initUserActiveStateProvider();
    await this.startSession();

    this.addEventListeners();
  }

  protected beforeDestroy(): void {
    this.destroyUserActiveStateProvider();
    void this.stopSession(this.plushie);

    this.removeEventListeners();
  }

  private addEventListeners(): void {
    this.fOnPageHideHandler = this.onPageHideHandler.bind(this);
    this.fOnVisibilityChangeHandler = this.onVisibilityChangeHandler.bind(this);

    window.addEventListener("pagehide", this.fOnPageHideHandler);
    document.addEventListener(
      "visibilitychange",
      this.fOnVisibilityChangeHandler
    );
  }

  private destroyUserActiveStateProvider(): void {
    if (this.fOnUserStateChangeHandler) {
      this.fUserActiveStateProvider.onStateChange.unsubscribe(
        this.fOnUserStateChangeHandler
      );
    }

    this.fUserActiveStateProvider.destroy();
  }

  private initUserActiveStateProvider(): void {
    this.fUserActiveStateProvider.init();
    this.fOnUserStateChangeHandler = this.onUserStateChangeHandler.bind(this);
    this.fUserActiveStateProvider.onStateChange.subscribe(
      this.fOnUserStateChangeHandler
    );
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    try {
      await this.fStatisticsStore.loadOperatorSessions({
        plushieId: this.plushie.id,
        plushieStatus: this.plushie.status,
      });
    } finally {
      this.fIsLoading = false;
    }
  }

  private async onPageHideHandler(): Promise<void> {
    await this.stopSession(this.plushie);
  }

  private async onVisibilityChangeHandler(event: any): Promise<void> {
    const isDocumentVisible = event.target.visibilityState === "visible";

    if (isDocumentVisible) {
      await this.startSession();
    } else {
      await this.stopSession(this.plushie);
    }
  }

  private removeEventListeners(): void {
    if (this.fOnPageHideHandler) {
      window.removeEventListener("pagehide", this.fOnPageHideHandler);
    }
    if (this.fOnVisibilityChangeHandler) {
      document.removeEventListener(
        "visibilitychange",
        this.fOnVisibilityChangeHandler
      );
    }
  }

  private async saveOperatorSession(
    plushie: Plushie
  ): Promise<OperatorSession | undefined> {
    if (!this.fTimeSpentUpdateIntervalId || this.fIsOperatorSessionSaving) {
      return;
    }

    this.fIsOperatorSessionSaving = true;

    try {
      const session = await this.fStatisticsStore.saveOperatorSession({
        operatorSession: new OperatorSession(
          this.fIdGenerator.getId(),
          plushie.id,
          plushie.status,
          this.fUser.id,
          this.fTimeSpent
        ),
      });

      return session;
    } finally {
      this.fIsOperatorSessionSaving = false;
    }
  }

  private async startSession(): Promise<void> {
    if (this.fTimeSpentUpdateIntervalId || this.isLoading || document.hidden) {
      return;
    }

    await this.loadData();

    this.fTimeSpentUpdateIntervalId = setInterval(
      () => this.updateTimeSpent(),
      1000
    );
  }

  private async stopSession(plushie: Plushie): Promise<void> {
    if (!this.fTimeSpentUpdateIntervalId) {
      return;
    }

    await this.saveOperatorSession(plushie);
    clearInterval(this.fTimeSpentUpdateIntervalId);
    this.fTimeSpentUpdateIntervalId = undefined;
    this.fTimeSpent = 0;
  }

  private updateTimeSpent(): void {
    this.fTimeSpent += 1;
  }

  private onUserStateChangeHandler(): void {
    if (this.fUserActiveStateProvider.isActive) {
      void this.startSession();
    } else {
      void this.stopSession(this.plushie);
    }
  }

  @Watch("plushie")
  private async _onPlushieIdChanged(val: Plushie, oldVal: Plushie) {
    await this.stopSession(oldVal);

    void this.startSession();
  }
}
