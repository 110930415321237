import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import { ResourceCollection } from "@/modules/api/resource.collection";

import QaDecisionRejectionReason from "./qa-decision-rejection-reason.model";

export default class QaDecisionRejectionReasonRepository extends BasicRepository<QaDecisionRejectionReason> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<QaDecisionRejectionReason>,
    responseParser: ApiCollectionResponseParserService<QaDecisionRejectionReason>
  ) {
    super("qa_decision_rejection_reasons", api, objectBuilder, responseParser);
  }

  public async getListByDateRange(
    fromDate: Date,
    toDate: Date
  ): Promise<ResourceCollection<QaDecisionRejectionReason>> {
    const response = await this.api.get(this.resourceName, {
      page: 1,
      itemsPerPage: 9999,
      "createdAt[after]": fromDate.toISOString(),
      "createdAt[before]": toDate.toISOString(),
    });

    return this.responseParser.parse(response.data, this.objectBuilder);
  }
}
