
































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";

import FactoryInvoiceStore from "../../store";
import ElementPrice from "../../element-price.model";
import Product from "../../../plushie/product.model";
import Upgrade from "../../../plushie/upgrade.model";
import {
  DirectShipmentCompensationEntity,
  directShipmentCompensationEntity,
} from "../../direct-shipment-compensation.entity";
import { ElementTypeValue } from "../../element-type.value";

interface PriceTableLineItem {
  id: string;
  entityTypeId: ElementTypeValue;
  entityId: string;
  entityName: string;
  qty: number;
  price: number;
}

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class PriceConfigurationViewForm extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop({ default: true })
  public readonly showBackLink!: boolean;

  private fIsLoading = false;
  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fPlushieDataStore: PlushieStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get elementPrices(): PriceTableLineItem[] {
    const elementPrices = this.fFactoryInvoiceDataStore.getElementPricesByFactoryId(
      this.factoryId
    );

    const products = this.fPlushieDataStore.productsList;
    const upgrades = this.fPlushieDataStore.upgradesList;

    const result: PriceTableLineItem[] = [];

    elementPrices.forEach((elementPrice: ElementPrice) => {
      const entity = [
        ...products,
        ...upgrades,
        directShipmentCompensationEntity,
      ].find(
        (entity: Product | Upgrade | DirectShipmentCompensationEntity) =>
          elementPrice.entity == entity.id
      );

      if (!entity) {
        return;
      }

      const entityName =
        elementPrice.type === ElementTypeValue.DIRECT_SHIPMENT_COMPENSATION
          ? directShipmentCompensationEntity.name
          : entity.name;

      result.push({
        id: elementPrice.id,
        entityTypeId: elementPrice.type,
        entityId: entity.id,
        entityName: entityName,
        qty: elementPrice.qty,
        price: elementPrice.price,
      });
    });

    result.sort((a, b) => {
      const desiredOrder = {
        [ElementTypeValue.PRODUCT]: 1,
        [ElementTypeValue.UPGRADE]: 2,
        [ElementTypeValue.DIRECT_SHIPMENT_COMPENSATION]: 3,
      };

      const result =
        desiredOrder[a.entityTypeId] - desiredOrder[b.entityTypeId];

      if (result !== 0) {
        return result;
      }

      return a.entityName.localeCompare(b.entityName);
    });

    return result;
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public onBackClick(): void {
    this.$emit("back");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fFactoryInvoiceDataStore.loadElementPricesByFactoryId({
          factoryId: this.factoryId,
          useCache: false,
        }),
        this.fPlushieDataStore.loadProducts(),
        this.fPlushieDataStore.loadUpgrades(),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
