import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import LabelHeaderProductRelation from "./label-header-product-relation.model";

export default class LabelHeaderProductRelationRepository extends BasicRepository<LabelHeaderProductRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<LabelHeaderProductRelation>,
    responseParser: ApiCollectionResponseParserService<LabelHeaderProductRelation>
  ) {
    super(
      "barcode_label_header_product_relations",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByHeaderId(
    headerId: string
  ): Promise<LabelHeaderProductRelation[]> {
    return (await this.fetchList({ labelHeader: headerId }, 1, 999)).getItems();
  }

  protected prepareData(
    item: LabelHeaderProductRelation
  ): Record<string, string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      labelHeader: item.header,
      productId: parseInt(item.product, 10),
    };

    return data;
  }
}
