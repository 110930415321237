import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import AutoApprovementExclude from "./auto-approvement-exclude.model";

export default class AutoApprovementExcludeRepository extends BasicRepository<AutoApprovementExclude> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<AutoApprovementExclude>,
    responseParser: ApiCollectionResponseParserService<AutoApprovementExclude>
  ) {
    super("auto_approvement_excludes", api, objectBuilder, responseParser);
  }

  public async loadByPlushieId(
    plushieId: string
  ): Promise<AutoApprovementExclude | undefined> {
    const collection = await this.getList(1, 1, {
      plushieId,
    });

    return collection.getById(plushieId);
  }

  protected prepareData(item: AutoApprovementExclude): Dictionary<string> {
    return {
      plushieId: item.id,
    };
  }
}
