import StatusSummaryApiResponseItem from "./status-summary-api-response-item.interface";

export default function isStatusSummaryApiResponseItem(
  arg: unknown
): arg is StatusSummaryApiResponseItem {
  if (!(arg instanceof Object)) {
    return false;
  }

  const tmpArg = arg as Record<string | number | symbol, unknown> | unknown[];

  if (!("status" in tmpArg) || typeof tmpArg["status"] !== "number") {
    return false;
  }

  if (!("plushiesQuantity" in tmpArg) || typeof tmpArg["status"] !== "number") {
    return false;
  }

  return true;
}
