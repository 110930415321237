import { InvoiceElementTypeValue } from "./invoice-item-element-type.value";

export interface DirectShipmentCompensationEntity {
  id: InvoiceElementTypeValue;
  name: string;
}

export const directShipmentCompensationEntity = {
  id: InvoiceElementTypeValue.DIRECT_SHIPMENT_COMPENSATION_ID,
  name: "Direct Shipment Compensation",
};
