

















import { Component, Prop, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";

import { Dictionary } from "@/lib/Dictionary.type";

import LabelHeaderEditForm from "./LabelHeaderEditForm.vue";

import LabelHeader from "../label-header.model";

@Component({
  metaInfo() {
    return {
      title: "Add Barcode Label Header",
    };
  },
  components: {
    LabelHeaderEditForm,
  },
})
export default class LabelHeaderAddPage extends Vue {
  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Prop()
  public readonly query!: Record<string, unknown>;

  public onCancel(): void {
    void this.$router.push({
      name: "LabelHeadersListPage",
      query: this.listQuery,
    });
  }

  public onSuccess(labelHeader: LabelHeader): void {
    const editPageUrl = this.generateItemLink(
      labelHeader.id,
      this.query,
      this.listQuery
    );

    void this.$router.push(editPageUrl);
  }

  private generateItemLink(
    id: string,
    query: Record<string, unknown>,
    listQuery?: Record<string, unknown>
  ): RawLocation {
    return {
      name: "LabelHeaderEditPage",
      query: {
        id,
        query: JSON.stringify(query),
        listQuery: JSON.stringify(listQuery),
      },
    };
  }
}
