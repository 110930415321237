import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieStatus from "./plushie-status.model";

export default class PlushieStatusFactory
  implements ObjectBuilderInterface<PlushieStatus> {
  public buildFromJSON(data: { [key: string]: any }): PlushieStatus {
    const value = new PlushieStatus(data.id.toString(), data.name);

    value.isNew = false;

    return value;
  }
}
