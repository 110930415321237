




















































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import AccountStore from "../store";
import Organization from "../organization.model";
import { DirectoryValue } from "../../api/directory-value.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class OrganizationEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly organization?: Organization;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public name?: string;
  public type?: string;

  private fAccountDataStore: AccountStore;

  get typeOptions(): DirectoryValue[] {
    return this.fAccountDataStore.organizationTypes;
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, string | undefined> {
    return {
      name: this.name,
      type: this.type,
    };
  }

  protected mounted(): void {
    void this.fAccountDataStore.loadOrganizationTypes();
  }

  protected async performSubmit(): Promise<void> {
    let organization = this.generateOrganizationObject();

    organization = await this.fAccountDataStore.saveOrganization(organization);
    this.$emit("updated", organization);
  }

  private generateOrganizationObject(): Organization {
    if (!this.name || !this.type) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.organization
      ? this.organization.id
      : this.fIdGenerator.getId();

    const value = new Organization(id, this.name, this.type);

    if (this.organization) {
      value.isNew = false;
    }

    return value;
  }

  @Watch("organization", { immediate: true })
  private _onOrganizationChange(): void {
    this.name = undefined;
    this.type = undefined;

    if (this.organization === undefined) {
      return;
    }

    this.name = this.organization.name;
    this.type = this.organization.type;
  }
}
