import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PenaltyBypass from "./penalty-bypass.model";

export default class PenaltyBypassFactory
  implements ObjectBuilderInterface<PenaltyBypass> {
  public buildFromJSON(data: { [key: string]: any }): PenaltyBypass {
    const value = new PenaltyBypass(data.id, data.plushieId, data.type);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUser = data.userId;

    value.isNew = false;

    return value;
  }
}
