import { DirectiveOptions, VNode } from "vue";
import type { DirectiveBinding } from "vue/types/options";
import insertText from "insert-text-textarea";

const handler = (e: KeyboardEvent): void => {
  const el = e.target;

  if (!(el instanceof HTMLTextAreaElement)) {
    return;
  }

  if (e.keyCode !== 13) {
    return;
  }

  const value = el.value;

  if (!value.length) {
    return;
  }

  const cursorPos = el.selectionStart;

  if (!cursorPos || cursorPos !== el.selectionEnd) {
    return;
  }

  const contentBeforeCursor = value.substring(0, cursorPos);

  const firstLine = contentBeforeCursor.split("\n").pop();

  if (!firstLine) {
    return;
  }

  const hyphenMatchResult = /^(\s*)-(\s*)/.exec(firstLine);

  if (!hyphenMatchResult) {
    return;
  }

  e.preventDefault();

  const valueToAppend =
    "\n" + hyphenMatchResult[1] + "-" + hyphenMatchResult[2];

  insertText(el, valueToAppend);
};

const directive: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
    if (binding.value === false) {
      return;
    }

    if (!(el instanceof HTMLTextAreaElement)) {
      return;
    }

    el.addEventListener("keypress", handler);
  },
  unbind(el: HTMLElement, binding: DirectiveBinding) {
    el.removeEventListener("keypress", handler);
  },
};

export default directive;
