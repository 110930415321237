






























import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import { Dictionary } from "@/lib/Dictionary.type";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import Product from "@/modules/plushie/product.model";
import { ProductValue } from "@/modules/plushie/product.value";

import QuestionTemplateProductRelationRepository from "../../question-template-product-relation.repository";
import QuestionTemplateProductRelation from "../../question-template-product-relation.model";

@Component({
  components: {
    FormErrors,
  },
})
export default class QuestionTemplateProductsEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop()
  public readonly questionTemplateId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("QuestionTemplateProductRelationRepository")
  private fRelationRepository!: QuestionTemplateProductRelationRepository;

  private fPlushieDataStore: PlushieStore;

  private fRelations: QuestionTemplateProductRelation[] = [];
  private fUpdatingRelations: Dictionary<boolean> = {};

  get relations(): QuestionTemplateProductRelation[] {
    return this.fRelations;
  }

  get productOptions(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public isProductAssociateWithTemplate(productId: ProductValue): boolean {
    return this.getRelationByProductId(productId) !== undefined;
  }

  public isRelationUpdating(relationId: string): boolean {
    return this.fUpdatingRelations[relationId];
  }

  public onRelationChange(productId: ProductValue, value: boolean): void {
    if (!value) {
      void this.deleteRelation(productId);
    } else {
      void this.addRelation(productId);
    }
  }

  protected data(): Record<string, unknown> {
    return {
      fRelations: this.fRelations,
    };
  }

  protected mounted(): void {
    void this.fPlushieDataStore.loadProducts();
  }

  private async deleteRelation(productId: ProductValue) {
    const relation = this.getRelationByProductId(productId);

    if (!relation) {
      return;
    }

    this.removeRelationFromList(relation);

    this.markProductAsUpdating(productId);

    try {
      await this.fRelationRepository.delete(relation);
      this.submitErrors = [];
    } catch (e) {
      this.fRelations.push(relation);

      this.submitErrors.push(
        "Something went wrong! Unable to remove the relation"
      );
    }

    this.markProductAsAvailable(productId);
  }

  private async addRelation(productId: ProductValue) {
    const relation = new QuestionTemplateProductRelation(
      this.fIdGenerator.getId(),
      this.questionTemplateId,
      productId
    );

    this.fRelations.push(relation);

    this.markProductAsUpdating(productId);

    try {
      await this.fRelationRepository.save(relation);
      this.submitErrors = [];
    } catch (e) {
      this.removeRelationFromList(relation);
      this.submitErrors.push(
        "Something went wrong! Unable to add the relation"
      );
    }

    this.markProductAsAvailable(productId);
  }

  private getRelationByProductId(
    productId: ProductValue
  ): QuestionTemplateProductRelation | undefined {
    return this.relations.find((item) => item.productId === productId);
  }

  private markProductAsUpdating(productId: ProductValue) {
    Vue.set(this.fUpdatingRelations, productId, true);
  }

  private markProductAsAvailable(productId: ProductValue) {
    Vue.set(this.fUpdatingRelations, productId, false);
  }

  private removeRelationFromList(item: QuestionTemplateProductRelation) {
    const index = this.relations.indexOf(item);

    if (index > -1) {
      this.fRelations.splice(index, 1);
    }
  }

  @Watch("questionTemplateId", { immediate: true })
  private async _onQuestionTemplateIdChange() {
    this.fRelations = [];

    this.fIsDisabled = true;

    try {
      const relations = await this.fRelationRepository.getByTemplateId(
        this.questionTemplateId
      );
      this.fRelations = relations.getItems();

      this.fIsDisabled = false;
    } catch (e) {
      this.fIsDisabled = false;

      throw e;
    }
  }
}
