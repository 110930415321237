import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiService from "@/modules/api/api.service";

import Alert from "./alert.model";

export default class AlertRepository {
  constructor(
    protected resourceName: string,
    protected api: ApiService,
    protected objectBuilder: ObjectBuilderInterface<Alert>
  ) {}

  public async get(): Promise<Alert> {
    return this.api.get(this.resourceName).then((response) => {
      return this.objectBuilder.buildFromJSON(response.data);
    });
  }
}
