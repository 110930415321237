import RejectionRateApiResponse from "./rejection-rate-api-response.interface";

export default function isRejectionRateApiResponse(
  arg: unknown
): arg is RejectionRateApiResponse {
  if (!(arg instanceof Object)) {
    return false;
  }

  const requiredKeys = [
    "weekRate",
    "prevWeekRate",
    "monthRate",
    "prevMonthRate",
  ];
  const tempArg = arg as Record<string, number>;

  for (const key of requiredKeys) {
    if (typeof tempArg[key] !== "number") {
      return false;
    }
  }

  return true;
}
