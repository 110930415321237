import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import FactoryInvoicingSettings from "./factory-invoicing-settings.model";

export default class FactoryInvoicingSettingsFactory
  implements ObjectBuilderInterface<FactoryInvoicingSettings> {
  public buildFromJSON(data: { [key: string]: any }): FactoryInvoicingSettings {
    const value = new FactoryInvoicingSettings(
      data.factoryId,
      data.invoicingModel,
      data.ignoreQuantity,
      data.invoicingPeriod
    );

    value.isNew = false;

    return value;
  }
}
