export default class BlockingPlushie {
  public isNew = true;

  constructor(private fPlushie: string, private fFactory: string) {
    [fPlushie, fFactory].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get factory(): string {
    return this.fFactory;
  }
}
