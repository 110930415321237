import { AxiosPromise } from "axios";

import ApiErrorParserService from "@/modules/api/api-error-parser.service";

import { ApiRequestDescription } from "./api-request-description";

export default class ApiResponseHandlerService {
  constructor(private errorParser: ApiErrorParserService) {}

  public processResponse(
    request: AxiosPromise,
    requestDescription: ApiRequestDescription
  ): AxiosPromise {
    return request.catch((error) => {
      throw this.errorParser.parseError(error, requestDescription);
    });
  }
}
