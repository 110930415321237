import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import FactoryStrictCapacityMode from "./factory-strict-capacity-mode.model";

export default class FactoryStrictCapacityModeFactory
  implements ObjectBuilderInterface<FactoryStrictCapacityMode> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): FactoryStrictCapacityMode {
    const value = new FactoryStrictCapacityMode(data.id);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
