import ApiService from "@/modules/api/api.service";

import ProductionEstimate from "./production-estimate.interface";

export default class ProductionEstimateRepository {
  private readonly fResourceName = "production_estimate_stats";

  constructor(private api: ApiService) {}

  public async fetchProductionEstimatesList({
    holidayPeriodId,
    weeksRemaining,
    lookBackWeeks,
  }: {
    holidayPeriodId: number;
    weeksRemaining: number;
    lookBackWeeks: number;
  }): Promise<ProductionEstimate[]> {
    const response = await this.api.get(this.fResourceName, {
      holidayPeriodId,
      weeksRemaining,
      lookBackWeeks,
    });

    return response.data as ProductionEstimate[];
  }
}
