import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ReviewerReport from "./reviewer-report.model";

export default class ReviewerReportFactory
  implements ObjectBuilderInterface<ReviewerReport> {
  public buildFromJSON(data: { [key: string]: any }): ReviewerReport {
    const value = new ReviewerReport(
      data.reviewer,
      data.time_period,
      data.plushies_count
    );

    return value;
  }
}
