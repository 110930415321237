import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import TagAlertAssignRule from "./tag-alert-assign-rule.model";

export default class TagAlertAssignRuleRepository extends BasicRepository<TagAlertAssignRule> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<TagAlertAssignRule>,
    responseParser: ApiCollectionResponseParserService<TagAlertAssignRule>
  ) {
    super(
      "plushie_alerts/tag_alert_assign_rules",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByAlertId(alertId: string): Promise<TagAlertAssignRule[]> {
    if (!alertId) {
      return [];
    }

    const collection = await this.fetchList({ alert: alertId });

    return collection.getItems();
  }

  protected prepareData(item: TagAlertAssignRule): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
      tagName: item.tagName,
      alertId: item.alert,
    };

    return data;
  }
}
