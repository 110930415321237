<template>
  <div class="VueTables__limit-field">
    <select
      :id="props.selectAttrs.id"
      :class="props.selectAttrs.class"
      :value="props.selectAttrs.value"
      @change="props.selectEvents.change"
    >
      <option v-for="val in props.perPageValues" :key="val" :value="val">
        {{ val }}
      </option>
    </select>

    <span :class="props.labelClass" :for="`VueTables__limit_${props.id}`">
      {{ props.display("limit") }}
    </span>
  </div>
</template>

<script>
export default {
  name: "MyPerPageSelector",
  props: ["props"],
};
</script>
