



































































































































































import { Component, Inject, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import DatePicker from "vuejs-datepicker";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import Plushie from "@/modules/plushie/plushie.model";
import CountryStore from "@/modules/country/store";
import PlushieStore from "@/modules/plushie/store";
import Country from "@/modules/country/country.model";
import PlushieImage from "@/modules/plushie/plushie-image.model";
import IdGenerator from "@/lib/services/id-generator";

import BulkProjectMainImage from "./BulkProjectMainImage.vue";
import SampleSearchForm from "./CreatePage/SampleSearchForm.vue";

import BulkProjectStore from "../store";

const sizeFromDescriptionRegex = /Size: (\d{1,2})/;

@Component({
  components: {
    BulkProjectMainImage,
    DatePicker,
    FormErrors,
    FormField,
    MoonLoader,
    SampleSearchForm,
    SubmitButton,
  },
  metaInfo() {
    return {
      title: "Create Bulk Project",
    };
  },
})
export default class BulkProjectCreatePage extends GeneralFormMixin {
  @Prop({ default: undefined })
  public readonly samplePlushieId?: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public quantity?: number;
  public size?: number;
  public description?: string;
  public country?: string;
  public name?: string;
  public phone?: string;
  public deadlineDate?: Date;
  public clientType?: string;

  private fSamplePlushie?: Plushie;
  private fIsPlushieRelatedDataLoading = false;

  private fBulkProjectStore: BulkProjectStore;
  private fCountryStore: CountryStore;
  private fPlushieStore: PlushieStore;

  get countryOptions(): Country[] {
    return this.fCountryStore.countries;
  }

  get isPlushieRelatedDataLoading(): boolean {
    return this.fIsPlushieRelatedDataLoading;
  }

  get plushieMainImage(): PlushieImage | undefined {
    if (!this.fSamplePlushie) {
      return;
    }

    return this.fPlushieStore.getPlushieMainImageByPlushieId(
      this.fSamplePlushie.id
    );
  }

  get samplePlushieMainImageStorageItemId(): string | undefined {
    if (!this.plushieMainImage) {
      return;
    }

    return this.plushieMainImage.storageItem;
  }

  get showCreateForm(): boolean {
    return !!this.fSamplePlushie;
  }

  constructor() {
    super();

    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
    this.fCountryStore = getModule(CountryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  public async onPlushieSearchResult(plushie: Plushie): Promise<void> {
    // pause validation to prevent attempt to access fields destroyed due to v-if switching
    this.$validator.pause();

    this.resetFormData();

    this.fSamplePlushie = plushie;

    if (!plushie) {
      return;
    }

    await this.loadPlushieRelatedData(plushie);
    this.$validator.resume();

    this.prefillFormData(plushie);
  }

  async created(): Promise<void> {
    await this.loadBaseData();
  }

  data(): Record<string, unknown> {
    return {
      quantity: this.quantity,
      size: this.size,
      description: this.description,
      country: this.country,
      name: this.name,
      phone: this.phone,
      deadlineDate: this.deadlineDate,
      clientType: this.clientType,
      fSamplePlushie: this.fSamplePlushie,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (
      !this.fSamplePlushie ||
      !this.quantity ||
      !this.size ||
      !this.description ||
      !this.country
    ) {
      return;
    }

    const bulkProject = await this.fBulkProjectStore.createBulkProject({
      id: this.fIdGenerator.getId(),
      samplePlushieId: this.fSamplePlushie.id,
      quantity: this.quantity,
      size: this.size,
      description: this.description,
      countryId: Number.parseInt(this.country),
      name: this.name,
      phone: this.phone,
      deadlineDate: this.deadlineDate
        ? this.deadlineDate.toISOString().split("T")[0]
        : undefined,
      clientType: this.clientType,
    });

    this.resetFormData();

    void this.$router.push({
      name: "BulkProjectViewPage",
      query: {
        id: bulkProject.id,
      },
    });
  }

  private getSizeFromDescription(description: string): number | undefined {
    const match = sizeFromDescriptionRegex.exec(description);

    if (!match || !match[1]) {
      return;
    }

    return Number.parseInt(match[1]);
  }

  private async loadBaseData(): Promise<void> {
    await this.fCountryStore.loadCountries();
  }

  private async loadPlushieRelatedData(plushie: Plushie): Promise<void> {
    if (this.fIsPlushieRelatedDataLoading) {
      return;
    }

    this.fIsPlushieRelatedDataLoading = true;

    try {
      await Promise.all([
        this.fPlushieStore.loadPlushieMainImageByPlushieIds([plushie.id]),
        this.fPlushieStore.loadShippingInformationByPlushieId(plushie.id),
      ]);
    } finally {
      this.fIsPlushieRelatedDataLoading = false;
    }
  }

  private prefillFormData(plushie: Plushie): void {
    this.quantity = plushie.quantity;
    this.description = plushie.description
      .replace(sizeFromDescriptionRegex, "")
      .trim();
    this.size = this.getSizeFromDescription(plushie.description);
    this.name = plushie.name;

    const shippingInformation = this.fPlushieStore.getShippingInformationByPlushieId(
      plushie.id
    );

    if (!shippingInformation) {
      return;
    }
    const country = this.fCountryStore.getCountryByCode(
      shippingInformation.country
    );

    this.country = country?.id;
    this.phone = shippingInformation.phone;
  }

  private resetFormData(): void {
    this.submitErrors = [];
    this.fSamplePlushie = undefined;
    this.quantity = undefined;
    this.size = undefined;
    this.description = undefined;
    this.country = undefined;
    this.name = undefined;
    this.phone = undefined;
    this.deadlineDate = undefined;
    this.clientType = undefined;
  }
}
