import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ExcludedProduct from "./excluded-product.model";

export default class ExcludedProductFactory
  implements ObjectBuilderInterface<ExcludedProduct> {
  public buildFromJSON(data: { [key: string]: any }): ExcludedProduct {
    const value = new ExcludedProduct(data.id);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
