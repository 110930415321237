















import { Component, Prop } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";

import OrdersAssignForm from "./RegularOrdersAssignForm.vue";

@Component({
  components: { OrdersAssignForm },
})
export default class RegularOrdersAssignDialog extends DialogComponent<boolean> {
  @Prop({ required: true })
  public readonly periodId!: string;
}
