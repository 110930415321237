import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import StatusSummary from "./status-summary.model";
import StatusSummaryApiResponseItem from "./status-summary-api-response-item.interface";

export default class StatusSummaryFactory
  implements
    ObjectBuilderInterface<StatusSummary, StatusSummaryApiResponseItem> {
  public buildFromJSON(data: StatusSummaryApiResponseItem): StatusSummary {
    const value = new StatusSummary(
      data.status.toString(),
      data.plushiesQuantity
    );

    return value;
  }
}
