import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import DeactivatedUser from "./deactivated-user.model";

export default class DeactivatedUserFactory
  implements ObjectBuilderInterface<DeactivatedUser> {
  public buildFromJSON(data: { [key: string]: any }): DeactivatedUser {
    const value = new DeactivatedUser(data.id);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
