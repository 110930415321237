<template>
  <footer>
    <div class="_copyright-text cell medium-6 xlarge-4">
      Copyright © {{ copyrightYear }} Budsies LLC
    </div>
  </footer>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "PageFooter",
  components: {},
  data() {
    return {
      copyrightYear: new Date().getFullYear(),
    };
  },
});
</script>

<style lang="scss" scoped>
footer {
  border-top: 1px solid color("containerBorder");
  font-size: 92%;
  padding: 1em;
  text-align: right;
}
</style>
