import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

import Setting from "../setting.model";
import SettingUpdateInterface from "../setting-update.interface";

const name = "SettingStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    setting: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class SettingStore extends VuexModule {
  setting: Dictionary<Setting> = {};

  // ################################### SETTINGS #########################################
  get getSettingById(): (id: string) => Setting | undefined {
    return (id: string) => this.setting[id];
  }

  @Mutation
  updateSetting(payload: Setting) {
    Vue.set(this.setting, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadSettingById(id: string): Promise<Setting | undefined> {
    if (this.setting[id] !== undefined) {
      return this.setting[id];
    }

    const setting = await DIContainer.SettingRepository.getById(id);

    if (setting) {
      this.updateSetting(setting);
    }

    return setting;
  }

  @Action({ rawError: true })
  async loadSettingsByIds(
    ids: string[]
  ): Promise<Dictionary<Setting | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<Setting | undefined> = {};

    ids.forEach((id) => {
      if (!this.setting[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.setting[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.SettingRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateSetting(item);
    });

    return { ...result, ...items };
  }

  @Action({ rawError: true })
  async updateSettingValue(
    settingUpdate: SettingUpdateInterface
  ): Promise<Setting> {
    const item = await DIContainer.SettingRepository.updateSettingValue(
      settingUpdate
    );

    this.updateSetting(item);

    return item;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
