import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Upgrade implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fShortName?: string;

  constructor(
    private fId: string,
    private fCode: string,
    private fName: string,
    private fDisplayToFactory: boolean
  ) {
    [fId, fCode, fName, fDisplayToFactory].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get code(): string {
    return this.fCode;
  }

  get name(): string {
    return this.fName;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get shortName(): string | undefined {
    return this.fShortName;
  }

  get displayToFactory(): boolean {
    return this.fDisplayToFactory;
  }
}
