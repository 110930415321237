import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieValueRelation from "./plushie-value-relation.model";
import PlushieValueRelationUpdate from "./plushie-value-relation-update.interface";

export default class PlushieValueRelationRepository extends BasicRepository<PlushieValueRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieValueRelation>,
    responseParser: ApiCollectionResponseParserService<PlushieValueRelation>
  ) {
    super(
      "body_part_value_plushie_relations",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByPlushieId(
    plushieId: string
  ): Promise<PlushieValueRelation[]> {
    const collection = await this.fetchList({ plushie: plushieId });

    return collection.getItems();
  }

  public async updateValue(
    item: PlushieValueRelationUpdate
  ): Promise<PlushieValueRelation> {
    const data: Dictionary<string | number> = {
      value: item.value,
    };

    const response = await this.api.put(
      this.resourceName + "/" + item.relationId,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: PlushieValueRelation): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
      plushie: item.plushie,
      value: item.value,
    };

    return data;
  }
}
