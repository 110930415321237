import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { Dictionary } from "@/lib/Dictionary.type";

import { ApiFilterValue } from "../api/api-filter-value.type";
import QualityInspectionReport from "./quality-inspection-report.model";
import { ReportTimePeriod } from "./report-time-period";

export default class QualityInspectionReportRepository {
  private readonly fResourceName = "quality_inspection_stats";

  constructor(
    private api: ApiService,
    private objectBuilder: ObjectBuilderInterface<QualityInspectionReport>
  ) {}

  public async getList(
    timePeriod: ReportTimePeriod,
    inspectorId?: string,
    startDate?: Date,
    endDate?: Date,
    productId?: string
  ): Promise<QualityInspectionReport[]> {
    const data: Dictionary<ApiFilterValue> = {
      timePeriod,
    };

    if (startDate) {
      data.startDate = startDate.toISOString();
    }

    if (endDate) {
      data.endDate = endDate.toISOString();
    }

    if (productId) {
      data.productId = productId;
    }

    if (inspectorId) {
      data.inspectorId = inspectorId;
    }

    const response = await this.api.get(this.fResourceName, data);

    return (response.data as any[]).map((item) =>
      this.objectBuilder.buildFromJSON(item)
    );
  }
}
