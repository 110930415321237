








































































import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { VueFilePondComponent } from "vue-filepond";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import Plushie from "@/modules/plushie/plushie.model";

import AirwayBillUpload from "./CreateBulkShipmentForm/AirwayBillUpload.vue";
import CreateBulkShipmentFormSelectedItems from "./CreateBulkShipmentForm/SelectedItems.vue";
import CreateBulkShipmentFormSummary from "./CreateBulkShipmentForm/Summary.vue";
import CreateBulkShipmentFormSameOrdersPlushies from "./CreateBulkShipmentForm/SameOrdersPlushies.vue";

import BulkShipmentStore from "../store";
import BulkShipment from "../bulk-shipment.model";
import BulkShipmentItem from "../bulk-shipment-item.model";
import { BulkShipmentStatusValue } from "../bulk-shipment-status.value";

@Component({
  components: {
    AirwayBillUpload,
    FormField,
    FormErrors,
    SubmitButton,
    CreateBulkShipmentFormSelectedItems,
    CreateBulkShipmentFormSummary,
    CreateBulkShipmentFormSameOrdersPlushies,
  },
})
export default class CreateBulkShipmentForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieIds!: string[];

  @Prop({ required: true })
  public readonly factoryId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public trackingNumber?: string;
  public airwayBill?: string;

  private fPlushieDataStore: PlushieStore;
  private fBulkShipmentDataStore: BulkShipmentStore;

  private fIsAirwayBillUploadInProgress = false;

  get isAirwayBillUploadProcessingInProgress(): boolean {
    return this.fIsAirwayBillUploadInProgress;
  }

  get isDisabled(): boolean {
    return (
      this.isAirwayBillUploadProcessingInProgress ||
      this.fIsDisabled ||
      this.disable
    );
  }

  get isUploadDisabled(): boolean {
    return this.fIsDisabled || this.disable;
  }

  get plushies(): Plushie[] {
    return this.fPlushieDataStore.getPlushiesByIds(this.plushieIds);
  }

  constructor() {
    super();
    this.fBulkShipmentDataStore = getModule(BulkShipmentStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public onAirwayBillUploadIsBusyChanged(value: boolean): void {
    this.fIsAirwayBillUploadInProgress = value;
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected async performSubmit(): Promise<void> {
    if (!this.trackingNumber || !this.factoryId || !this.plushies) {
      return;
    }

    let bulkShipment = await this.fBulkShipmentDataStore.saveBulkShipment(
      new BulkShipment(
        this.fIdGenerator.getId(),
        this.factoryId,
        BulkShipmentStatusValue.DRAFT
      )
    );

    bulkShipment = await this.fBulkShipmentDataStore.editBulkShipment({
      id: bulkShipment.id,
      trackingNumber: this.trackingNumber,
      airwayBill: this.airwayBill,
    });

    const bulkShipmentItems: BulkShipmentItem[] = [];

    this.plushies.forEach((plushie) => {
      const bulkShipmentItem = new BulkShipmentItem(
        this.fIdGenerator.getId(),
        bulkShipment.id,
        plushie.id,
        plushie.quantity
      );

      bulkShipmentItems.push(bulkShipmentItem);
    });

    await this.fBulkShipmentDataStore.saveBulkShipmentItems(bulkShipmentItems);

    await this.fBulkShipmentDataStore.publishBulkShipment(bulkShipment);

    this.$emit("formSubmitted");
  }

  protected data(): Record<string, unknown> {
    return {
      trackingNumber: this.trackingNumber,
      airwayBill: this.airwayBill,
    };
  }

  private _getFileInput(): VueFilePondComponent | undefined {
    return (this.$refs["file-input"] as unknown) as VueFilePondComponent;
  }
}
