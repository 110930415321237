import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieAttachmentType from "./plushie-attachment-type.model";

export default class PlushieAttachmentTypeRepository extends BasicRepository<PlushieAttachmentType> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieAttachmentType>,
    responseParser: ApiCollectionResponseParserService<PlushieAttachmentType>
  ) {
    super("plushie_attachment_types", api, objectBuilder, responseParser);
  }
}
