






































import { Component, Inject, Vue } from "vue-property-decorator";

import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";

import UpgradeAllocationEditForm from "./EditForm.vue";
import UpgradeAllocationViewForm from "./ViewForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Upgrade Allocation Rules",
    };
  },
  components: {
    UpgradeAllocationEditForm,
    UpgradeAllocationViewForm,
  },
})
export default class UpgradeAllocationEditPage extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsAllocationRulesExpanded = false;

  get isAllocationRulesExpanded(): boolean {
    return this.fIsAllocationRulesExpanded;
  }

  get showEditForm(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCT_ALLOCATION_RULES_MANAGE
    );
  }

  get showViewForm(): boolean {
    if (this.showEditForm) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCT_ALLOCATION_RULES_VIEW
    );
  }

  public toggleAllocationRules(): void {
    this.fIsAllocationRulesExpanded = !this.fIsAllocationRulesExpanded;
  }
}
