





































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import { DirectoryValue } from "@/modules/api/directory-value.model";
import RoleResource from "@/modules/account/role-resource.model";
import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import AccountStore from "../store";
import RoleResourceRepository from "../role-resource.repository";
import PermissionResource from "../permission-resource.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class RolePermissionsEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly roleId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("RoleResourceRepository")
  private fRoleResourceRepository!: RoleResourceRepository;

  private fAccountDataStore: AccountStore;

  private fRoleResources: RoleResource[] = [];
  private fUpdatingRoleResources: Dictionary<boolean> = {};

  get resourceOptions(): Dictionary<PermissionResource[]> {
    return this.fAccountDataStore.permissionResourcesByService;
  }

  get roleResources(): RoleResource[] {
    return this.fRoleResources;
  }

  get services(): DirectoryValue[] {
    const result: DirectoryValue[] = [];

    const services = this.fAccountDataStore.services;
    services.forEach((service) => {
      if (!this.getResourceOptions(service.id).length) {
        return;
      }

      result.push(service);
    });

    return result;
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public doesRoleHasResource(resourceId: string): boolean {
    return this.getRoleResourceByResourceId(resourceId) !== undefined;
  }

  public getResourceOptions(serviceId: string): PermissionResource[] {
    const role = this.fAccountDataStore.getRoleById(this.roleId);

    if (!role) {
      return [];
    }

    const serviceResources =
      this.fAccountDataStore.permissionResourcesByService[serviceId] || [];

    const metaRoleResources = new Set(
      this.fAccountDataStore.getMetaRoleResources(role.metaRole)
    );

    return serviceResources.filter((item) => metaRoleResources.has(item.id));
  }

  public isRoleResourceUpdating(resourceId: string): boolean {
    return this.fUpdatingRoleResources[resourceId];
  }

  public onRoleResourceChange(
    resource: PermissionResource,
    value: boolean
  ): void {
    if (!value) {
      void this.deleteRoleResource(resource);
    } else {
      void this.addRoleResource(resource);
    }
  }

  protected data(): Record<string, RoleResource[]> {
    return {
      fRoleResources: this.fRoleResources,
    };
  }

  protected mounted(): void {
    void this.fAccountDataStore.loadPermissionResources();
    void this.fAccountDataStore.loadServices();
    void this.fAccountDataStore.loadMetaRoleResources();
  }

  private async deleteRoleResource(resource: PermissionResource) {
    const roleResource = this.getRoleResourceByResourceId(resource.id);

    if (!roleResource) {
      return;
    }

    this.removeRoleResourceFromList(roleResource);

    this.markResourceAsUpdating(resource.id);

    try {
      await this.fRoleResourceRepository.delete(roleResource);
      this.submitErrors = [];
    } catch (e) {
      this.fRoleResources.push(roleResource);

      this.submitErrors.push(
        `Something went wrong! Unable to remove the resource '${resource.name}'`
      );
    }

    this.markResourceAsAvailable(resource.id);
  }

  private async addRoleResource(resource: PermissionResource) {
    const roleResource = new RoleResource(
      this.fIdGenerator.getId(),
      this.roleId,
      resource.id
    );

    this.fRoleResources.push(roleResource);

    this.markResourceAsUpdating(resource.id);

    try {
      await this.fRoleResourceRepository.save(roleResource);
      this.submitErrors = [];
    } catch (e) {
      this.removeRoleResourceFromList(roleResource);
      this.submitErrors.push(
        `Something went wrong! Unable to add the resource '${resource.name}'`
      );
    }

    this.markResourceAsAvailable(resource.id);
  }

  private getRoleResourceByResourceId(
    resourceId: string
  ): RoleResource | undefined {
    return this.roleResources.find((item) => item.resource === resourceId);
  }

  private markResourceAsUpdating(resourceId: string) {
    Vue.set(this.fUpdatingRoleResources, resourceId, true);
  }

  private markResourceAsAvailable(resourceId: string) {
    Vue.set(this.fUpdatingRoleResources, resourceId, false);
  }

  private removeRoleResourceFromList(item: RoleResource) {
    const index = this.roleResources.indexOf(item);

    if (index > -1) {
      this.fRoleResources.splice(index, 1);
    }
  }

  @Watch("roleId", { immediate: true })
  private async _onRoleIdChange() {
    this.fRoleResources = [];

    this.fIsDisabled = true;

    try {
      const roleResources = await this.fRoleResourceRepository.getByRoleId(
        this.roleId
      );
      this.fRoleResources = roleResources.getItems();

      this.fIsDisabled = false;
    } catch (e) {
      this.fIsDisabled = false;

      throw e;
    }
  }
}
