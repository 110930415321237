import ApiService from "@/modules/api/api.service";

import { RegularOrdersAssignJobCommandValue } from "./regular-orders-assign-job-command.value";

export default class RegularOrdersAssignJobRepository {
  protected fResourceName = "holiday_periods/regular_orders_assign_jobs";

  constructor(protected api: ApiService) {}

  public async assign(periodId: string, startDate: Date): Promise<void> {
    await this.api.post(this.fResourceName, {
      command: RegularOrdersAssignJobCommandValue.ASSIGN_COMMAND,
      period: +periodId,
      startDate: startDate.toISOString().split("T")[0],
    });
  }

  public async unassign(): Promise<void> {
    await this.api.post(this.fResourceName, {
      command: RegularOrdersAssignJobCommandValue.UNASSIGN_COMMAND,
    });
  }
}
