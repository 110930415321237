






























































import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import Product from "@/modules/plushie/product.model";

import PenaltyRule from "../../../penalty-rule.model";

@Component({})
export default class PenaltyRuleEditItem extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop({ required: true })
  public readonly id!: string;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: true })
  public readonly isNew!: boolean;

  @Prop({ required: false })
  public readonly value?: PenaltyRule;

  public product?: string;
  public delay?: number;
  public percent?: number;

  private fPlushieDataStore: PlushieStore;

  get productOptions(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public deleteItem(): void {
    if (this.value && !confirm("Are you sure?")) {
      return;
    }

    this.$emit("delete");
  }

  public getProductName(productId?: string): string {
    if (!productId) {
      return "";
    }

    const product = this.fPlushieDataStore.getProductById(productId);

    return product ? product.name : "";
  }

  public onChange(): void {
    if (!this.product || !this.delay || !this.percent) {
      this.$emit("input", undefined);
      return;
    }

    const value = new PenaltyRule(
      this.id,
      this.factoryId,
      this.product,
      this.delay,
      this.percent
    );

    this.$emit("input", value);
  }

  protected data(): Record<string, unknown> {
    return {
      product: this.product,
      delay: this.delay,
      percent: this.percent,
    };
  }

  @Watch("value", { immediate: true })
  private _onValueChange() {
    if (!this.value) {
      return;
    }

    this.product = this.value.product;
    this.delay = this.value.productionDaysDelay;
    this.percent = this.value.penaltyPercent;
  }
}
