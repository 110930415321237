




























































































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import CountryStore from "@/modules/country/store";
import Country from "@/modules/country/country.model";

import FactoryStore from "../store";
import Factory from "../factory.model";
import { DirectoryValue } from "../../api/directory-value.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class FactoryEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly factory?: Factory;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public email?: string;
  public name?: string;
  public isActive?: boolean;
  public language?: number;
  public country?: number;
  public paSafetyNumber?: string;

  private fCountryDataStore: CountryStore;
  private fFactoryDataStore: FactoryStore;

  get languageOptions(): DirectoryValue[] {
    return this.fFactoryDataStore.languages;
  }

  get countryOptions(): Country[] {
    return this.fCountryDataStore.countries;
  }

  constructor() {
    super();
    this.fCountryDataStore = getModule(CountryStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected mounted(): void {
    void this.fFactoryDataStore.loadLanguages();
    void this.fCountryDataStore.loadCountries();
  }

  protected data(): Record<string, unknown> {
    return {
      email: this.email,
      name: this.name,
      isActive: this.isActive,
      language: this.language,
      country: this.country,
      paSafetyNumber: this.paSafetyNumber,
    };
  }

  protected async performSubmit(): Promise<void> {
    let factory = this.generateFactoryObject();

    factory = await this.fFactoryDataStore.saveFactory(factory);
    this.$emit("updated", factory);
  }

  private generateFactoryObject(): Factory {
    if (
      !this.email ||
      !this.name ||
      !this.language ||
      !this.country ||
      !this.paSafetyNumber
    ) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.factory ? this.factory.id : this.fIdGenerator.getId();

    const value = new Factory(
      id,
      this.email,
      this.name,
      this.isActive == true,
      this.language,
      this.country,
      this.paSafetyNumber
    );

    if (this.factory) {
      value.isNew = false;
    }

    return value;
  }

  @Watch("factory", { immediate: true })
  private _onFactoryChange() {
    this.email = undefined;
    this.name = undefined;
    this.isActive = undefined;
    this.language = undefined;
    this.country = undefined;
    this.paSafetyNumber = undefined;

    if (this.factory === undefined) {
      return;
    }

    this.email = this.factory.email;
    this.name = this.factory.name;
    this.isActive = this.factory.isActive;
    this.language = this.factory.language;
    this.country = this.factory.country;
    this.paSafetyNumber = this.factory.paSafetyNumber;
  }
}
