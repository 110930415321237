import { Dictionary } from "@/lib/Dictionary.type";

import { ApiRequestDescription } from "./api-request-description";

export class ApiError extends Error {
  constructor(
    readonly userFriendlyMessage: string,
    readonly status: number,
    readonly statusText: string,
    readonly requestDescription: ApiRequestDescription,
    readonly origianlError: any,
    readonly messages: Dictionary<string> | string[] | null
  ) {
    super();
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiError.prototype);

    const method = this.requestDescription.method;
    const resource = this.requestDescription.resource;

    this.message = `${this.userFriendlyMessage} "${method} /${resource}" ${this.status} ${this.statusText}`;
  }
}
