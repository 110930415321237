




































import { Component, Prop, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import throttle from "lodash.throttle";
import { DialogComponent } from "vue-modal-dialogs";

import ImagesGallery from "@/modules/plushie/components/ImagesGallery.vue";
import MarkedUpImagesGallery from "@/modules/photo-markup/components/MarkedUpImagesGallery.vue";

import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import PhotoMarkupStore from "@/modules/photo-markup/store";
import UiConfigurationStore from "@/modules/ui-configuration/store";
import PlushieStore from "@/modules/plushie/store";
import PlushieImage from "@/modules/plushie/plushie-image.model";

import ReviewApproveForm from "./Form.vue";

@Component({
  components: {
    ReviewApproveForm,
    ImagesGallery,
    MarkedUpImagesGallery,
  },
})
export default class ReviewApproveFullscreenModeDialog extends DialogComponent<void> {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ required: true })
  public readonly isDisabled!: boolean;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("window")
  private fWindow!: Window;

  public launchedGallery?: ImagesGallery | MarkedUpImagesGallery;

  private fFileStorageDataStore: FileStorageStore;
  private fPhotoMarkupDataStore: PhotoMarkupStore;
  private fPlushieDataStore: PlushieStore;
  private fUiConfigurationDataStore: UiConfigurationStore;
  private fIsLandscapeMode = true;
  private fInitialOverflowValue = "";
  private fResizeHandler?: () => void;

  get showReviewForm(): boolean {
    return this.launchedGallery ? true : false;
  }

  get artworks(): PlushieImage[] {
    return this.fPlushieDataStore.getPlushieImagesByPlushieId(this.plushieId);
  }

  get artworkCurrentIndex(): number {
    const artworkIndex = this.fUiConfigurationDataStore.getPlushieViewArtworkIndex(
      this.plushieId
    );

    if (artworkIndex === undefined) {
      return 0;
    }

    return artworkIndex;
  }

  get displayMarkup(): boolean {
    const displayMarkup = this.fUiConfigurationDataStore.getPlushieViewDisplayMarkup(
      this.plushieId
    );

    if (displayMarkup === undefined) {
      return true;
    }

    return displayMarkup;
  }

  get isLandscapeMode(): boolean {
    return this.fIsLandscapeMode;
  }

  public constructor() {
    super();

    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPhotoMarkupDataStore = getModule(PhotoMarkupStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fUiConfigurationDataStore = getModule(UiConfigurationStore, dataStore);
  }

  public switchToArtwork(index: number): void {
    this.fUiConfigurationDataStore.updatePlushieViewArtworkIndex({
      plushieId: this.plushieId,
      currentIndex: index,
    });
  }

  protected onGalleryClosed(): void {
    this.launchedGallery = undefined;

    if (this.fResizeHandler) {
      this.fWindow.removeEventListener("resize", this.fResizeHandler);
    }

    this.fWindow.document.body.style.overflow = this.fInitialOverflowValue;

    this.$close();
  }

  protected onReviewApproved(): void {
    if (this.launchedGallery) {
      this.launchedGallery.close();
    }
  }

  protected beforeMount(): void {
    void this.launch();
  }

  protected beforeDestroy(): void {
    if (this.fResizeHandler) {
      this.fWindow.removeEventListener("resize", this.fResizeHandler);
    }

    this.fWindow.document.body.style.overflow = this.fInitialOverflowValue;
  }

  protected data(): Record<string, unknown> {
    return {
      launchedGallery: this.launchedGallery,
    };
  }

  private async launch(): Promise<void> {
    await this.getPlushieData(this.plushieId);
    this.fInitialOverflowValue = this.fWindow.document.body.style.overflow;
    this.fWindow.document.body.style.overflow = "hidden";

    this.onViewPortResize();

    if (this.displayMarkup) {
      this.launchMarkupArtworkGallery();
    } else {
      this.launchArtworkGallery();
    }

    this.fResizeHandler = throttle(() => this.onViewPortResize(), 300);

    this.fWindow.addEventListener("resize", this.fResizeHandler);
  }

  private launchArtworkGallery(): void {
    const gallery = this.getFullscreenGallery();

    if (!gallery) {
      return;
    }

    const images = this.artworks.map((artwork) => {
      return {
        href: this.getThumbnail(artwork.storageItem, 1920),
        thumbnail: this.getThumbnail(artwork.storageItem, 200, true),
      };
    });

    gallery.launchGalleria(images, this.artworkCurrentIndex, {
      hidePageScrollbars: false,
      enableKeyboardNavigation: false,
    });

    this.launchedGallery = gallery;
  }

  private launchMarkupArtworkGallery(): void {
    const gallery = this.getMarkedUpFullscreenGallery();

    if (!gallery) {
      return;
    }

    const images = this.artworks.map((artwork) => {
      const markup = this.fPhotoMarkupDataStore.getMarkupByImageId(artwork.id);

      return {
        href: this.getThumbnail(artwork.storageItem, 1920),
        thumbnail: this.getThumbnail(artwork.storageItem, 200, true),
        markup: markup ? markup.markup : undefined,
        type: "markedUpImage",
      };
    });

    gallery.launchGalleria(images, this.artworkCurrentIndex, {
      hidePageScrollbars: false,
      enableKeyboardNavigation: false,
    });

    this.launchedGallery = gallery;
  }

  private getThumbnail(
    storageItemId: string,
    size = 1920,
    shouldCrop = false
  ): string {
    const storageItem = this.fFileStorageDataStore.getItemById(storageItemId);

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      size,
      size,
      shouldCrop
    );
  }

  private getFullscreenGallery(): ImagesGallery | undefined {
    return this.$refs["artworks-gallery"] as ImagesGallery;
  }

  private getMarkedUpFullscreenGallery(): MarkedUpImagesGallery | undefined {
    return this.$refs["markup-artworks-gallery"] as MarkedUpImagesGallery;
  }

  private async getPlushieData(plushieId: string) {
    const artworks = await this.fPlushieDataStore.loadPlushieImageByPlushieId({
      plushieId: plushieId,
    });

    const artworksStorageItemsIds = artworks.map(
      (artwork) => artwork.storageItem
    );

    await this.fFileStorageDataStore.loadItemsByIds(artworksStorageItemsIds);
  }

  private setLandscapeMode(value: boolean) {
    const oldValue = this.fIsLandscapeMode;

    this.fIsLandscapeMode = value;

    if (oldValue === value) {
      return;
    }

    setTimeout(() => {
      if (this.launchedGallery) {
        this.launchedGallery.refreshLayout();
      }
    }, 200);
  }

  private onViewPortResize() {
    this.setLandscapeMode(this.fWindow.innerWidth > this.fWindow.innerHeight);
  }
}
