











import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";

import BodyPartStore from "../../store";
import { ValueType } from "../../value-type";
import Value from "../../value.model";

@Component({})
export default class BodyPartViewFormItem extends Vue {
  private static readonly THUMBNAIL_SIZE = 90;

  @Prop({ required: true })
  public readonly bodyPartValues!: Value[];

  @Prop({ required: true })
  public readonly bodyPartId!: string;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fBodyPartDataStore: BodyPartStore;
  private fFileStorageDataStore: FileStorageStore;

  get bodyPartName(): string {
    if (!this.bodyPartId) {
      return "";
    }

    const bodyPart = this.fBodyPartDataStore.getBodyPartById(this.bodyPartId);

    if (!bodyPart) {
      return "";
    }

    return bodyPart.name;
  }

  constructor() {
    super();
    this.fBodyPartDataStore = getModule(BodyPartStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  resolveValueIconStyle(value: Value): string {
    switch (value.type) {
      case ValueType.COLOR: {
        const color = value.color || "#000000";

        return `background-color: ${color}; border-radius: 50%; border: 1px solid black;`;
      }
      case ValueType.IMAGE: {
        const thumbnailUrl = this.getValueThumbnail(value);

        return `background-image: url(${thumbnailUrl})`;
      }
    }

    return "";
  }

  private getValueThumbnail(value: Value) {
    if (!value.storageItem) {
      return this.fImageHandlerService.getThumbnailUrl(
        this.fFileStorageDataStore.placeholderUrl,
        BodyPartViewFormItem.THUMBNAIL_SIZE,
        BodyPartViewFormItem.THUMBNAIL_SIZE,
        false
      );
    }

    const storageItem = this.fFileStorageDataStore.getItemById(
      value.storageItem
    );

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      BodyPartViewFormItem.THUMBNAIL_SIZE,
      BodyPartViewFormItem.THUMBNAIL_SIZE,
      false
    );
  }
}
