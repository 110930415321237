import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieArbitraryAttribute from "./plushie-arbitrary-attribute.model";

export default class PlushieArbitraryAttributeFactory
  implements ObjectBuilderInterface<PlushieArbitraryAttribute> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): PlushieArbitraryAttribute {
    const value = new PlushieArbitraryAttribute(
      data.id,
      data.plushie,
      data.name,
      data.value
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
