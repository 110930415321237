import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class ProductionSchedule implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fProductionStartDate: Date,
    private fDueDate: Date
  ) {
    [fId, fProductionStartDate, fDueDate].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get dueDate() {
    return this.fDueDate;
  }

  get productionStartDate() {
    return this.fProductionStartDate;
  }

  get updatedAt() {
    return this.fUpdatedAt;
  }
}
