






















import { Component, Vue, Inject } from "vue-property-decorator";

import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import DelayRulesEditForm from "./ConfigurationEditPage/DelayRulesEditForm.vue";
import ExcludedProductsEditForm from "./ConfigurationEditPage/ExcludedProductsEditForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Factory Restricted Mode Configuration",
    };
  },
  components: {
    DelayRulesEditForm,
    ExcludedProductsEditForm,
  },
})
export default class FactoryRestrictedModeConfigurationEditPage extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  get canManageDelayRules(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.SETTINGS_MANAGE);
  }

  get canManageExcludedProducts(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_RESTRICTED_MODE_EXCLUDED_PRODUCTS_MANAGE
    );
  }

  public onCancel(): void {
    void this.$router.push({ name: "FactoryRestrictedModeManagementPage" });
  }

  public onUpdated(): void {
    void this.$router.push({ name: "FactoryRestrictedModeManagementPage" });
  }
}
