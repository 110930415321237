import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PreviewCount from "./preview-count.model";

export default class PreviewCountFactory
  implements ObjectBuilderInterface<PreviewCount> {
  public buildFromJSON(data: { [key: string]: any }): PreviewCount {
    const value = new PreviewCount(
      data.plushieId,
      +data.currentValue,
      +data.maxValue
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
