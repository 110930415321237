import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiService from "@/modules/api/api.service";

import RestrictedModeStatus from "./restricted-mode-status.model";

export default class RestrictedModeStatusRepository {
  constructor(
    protected resourceName: string,
    protected api: ApiService,
    protected objectBuilder: ObjectBuilderInterface<RestrictedModeStatus>
  ) {}

  public async getByFactoryId(
    factoryId: string
  ): Promise<RestrictedModeStatus | undefined> {
    if (!factoryId) {
      return undefined;
    }

    const response = await this.api.get(this.resourceName + "/" + factoryId);

    return this.objectBuilder.buildFromJSON(response.data.result);
  }

  public async getByFactoryIds(
    factoryIds: string[]
  ): Promise<RestrictedModeStatus[]> {
    if (!factoryIds) {
      return [];
    }

    const response = await this.api.get(this.resourceName, {
      factoryId: factoryIds,
    });

    const items: Record<string, any>[] = response.data.result;

    const result: RestrictedModeStatus[] = [];

    items.forEach((item) => {
      result.push(this.objectBuilder.buildFromJSON(item));
    });

    return result;
  }
}
