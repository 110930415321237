













































































































































import { Component, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import AccountStore from "../store";
import UserInfo from "../user-info.model";
import User from "../user.model";
import UserRole from "../user-role.model";
import { DirectoryValue } from "../../api/directory-value.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class UserEditForm extends mixins(GeneralFormMixin) {
  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public login?: string;
  public password?: string;

  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public organization?: string;
  public role?: string;

  private fAccountDataStore: AccountStore;

  private fUserId?: string;
  private fUserRoleId?: string;
  private fUserInfoId?: string;

  get organizationOptions(): DirectoryValue[] {
    return this.fAccountDataStore.organizationsOrderedByName;
  }

  get rolesOptions(): DirectoryValue[] {
    return this.fAccountDataStore.rolesOrderedByName;
  }

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      login: this.login,
      password: this.password,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      organization: this.organization,
      role: this.role,
    };
  }

  protected mounted(): void {
    void this.fAccountDataStore.loadOrganizations({});
    void this.fAccountDataStore.loadRoles();
  }

  protected async performSubmit(): Promise<void> {
    let userInfo = this.generateUserInfoObject();
    let user = this.generateUserObject(userInfo.id);
    let userRole = this.generateUserRoleObject(userInfo.id);

    userInfo = await this.fAccountDataStore.saveUserInfo(userInfo);
    this.fUserInfoId = userInfo.id;

    userRole = await this.fAccountDataStore.saveUserRole(userRole);
    this.fUserRoleId = userRole.id;

    user = await this.fAccountDataStore.saveUserAccount(user);
    this.fUserId = user.id;

    this.$emit("success", userInfo.id);
  }

  private generateUserObject(id: string): User {
    if (!this.login) {
      throw new Error("Unable to create object - 'login' field is missing!");
    }

    const value = new User(id, this.login);

    value.password = this.password;

    if (this.fUserId === id) {
      value.isNew = false;
    }

    return value;
  }

  private generateUserInfoObject(): UserInfo {
    if (
      !this.organization ||
      !this.email ||
      !this.firstName ||
      !this.lastName
    ) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.fUserInfoId ? this.fUserInfoId : this.fIdGenerator.getId();

    const value = new UserInfo(
      id,
      this.organization,
      this.email,
      this.firstName,
      this.lastName
    );

    if (this.fUserInfoId) {
      value.isNew = false;
    }

    return value;
  }

  private generateUserRoleObject(userId: string): UserRole {
    if (!this.role) {
      throw new Error("Unable to create object - the 'role' field is missing!");
    }

    const id = this.fUserRoleId ? this.fUserRoleId : this.fIdGenerator.getId();

    const value = new UserRole(id, userId, this.role);

    if (this.fUserRoleId) {
      value.isNew = false;
    }

    return value;
  }
}
