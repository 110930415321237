







































import Vue from "vue";
import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import FactoryInvoicingSettingsEdit from "./Edit.vue";
import FactoryInvoicingSettingsView from "./View.vue";

import Factory from "../../factory.model";
import FactoryStore from "../../store";
import FactoryRepository from "../../factory.repository";
import FactoryInvoicingSettings from "../../factory-invoicing-settings.model";

@Component({
  metaInfo() {
    const component = this as FactoryInvoicingSettingsPage;

    return {
      title: `${component.factoryName} Invoicing Settings`,
    };
  },
  components: {
    FactoryInvoicingSettingsEdit,
    FactoryInvoicingSettingsView,
    LoadingSpinner,
    ListItemPageNavigation,
  },
})
export default class FactoryInvoicingSettingsPage extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop({ required: true })
  public readonly query!: any;

  @Prop({ required: true })
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("FactoryRepository")
  private fFactoryRepository!: FactoryRepository;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fFactoryDataStore: FactoryStore;

  private fIsLoading = false;

  get canEditInvoicingSettings(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_SETTINGS_MANAGE
    );
  }

  get canViewInvoicingSettings(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_SETTINGS_READ
    );
  }

  get factory(): Factory | undefined {
    return this.fFactoryDataStore.getFactoryById(this.factoryId);
  }

  get factoryInvoicingSettings(): FactoryInvoicingSettings | undefined {
    return this.fFactoryDataStore.factoryInvoicingSettings[this.factoryId];
  }

  get factoryName(): string {
    return this.factory ? this.factory.name : "Factory";
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get showEditForm(): boolean {
    return this.canEditInvoicingSettings;
  }

  get showView(): boolean {
    return this.canViewInvoicingSettings && !!this.factoryInvoicingSettings;
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public getFactoriesOrder(): Promise<string[]> {
    return this.fFactoryRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  private async getFactoryData(factoryId: string): Promise<void> {
    await Promise.all([
      this.fFactoryDataStore.loadFactoryById({
        id: factoryId,
        useCache: false,
      }),
      this.fFactoryDataStore.fetchFactoryInvoicingSettingsById({
        factoryId,
        useCache: false,
      }),
    ]);
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (this.factoryId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getFactoryData(this.factoryId);

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
