







































// Import FilePond styles
import "filepond/dist/filepond.min.css";

import { Component, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import vueFilePond, { VueFilePondComponent } from "vue-filepond";
import { File as FilePondFile } from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormErrors from "@/lib/components/FormErrors.vue";

import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import FileStorageStore from "@/modules/file-storage/store";
import ProductionProcessStore from "@/modules/production-process/store";
import QualityInspectionStore from "@/modules/quality-inspection/store";
import { FileProcessingImageType } from "@/modules/file-storage/file-processing-image-type";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import IdGenerator from "@/lib/services/id-generator";
import Plushie from "@/modules/plushie/plushie.model";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import QaAssetType from "../qa-asset-type";
import { QaPhotoAngle } from "../qa-photo-angle";

// Create component
const FilePondComponent = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

@Component({
  components: {
    MoonLoader,
    FilePond: FilePondComponent,
    FormErrors,
  },
})
export default class BulkAssetsUpload extends Vue {
  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fUploadErrors: string[] = [];
  private fIsUploading = false;

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;
  private fProductionProcessDataStore: ProductionProcessStore;
  private fQualityInspectionDataStore: QualityInspectionStore;

  private fPlushies: Dictionary<string> = {};
  private fPlushiesPromises: Dictionary<Promise<Plushie>> = {};

  get isUploading(): boolean {
    return this.fIsUploading;
  }

  get uploadErrors(): string[] {
    return this.fUploadErrors;
  }

  constructor() {
    super();
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);

    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  public clearInput(): void {
    const fileInput = this.getFileInput();
    if (!fileInput) {
      return;
    }

    fileInput.removeFiles();
    this.fUploadErrors = [];
  }

  public browseFiles(): void {
    const fileInput = this.getFileInput();
    if (!fileInput) {
      return;
    }

    fileInput.browse();
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async detectFileType(source: File, type: string): Promise<string> {
    if (type) {
      return type;
    }

    const extension = source.name.toLowerCase().split(".").pop();
    if (!extension) {
      return "";
    }

    if (extension === "mp4") {
      return "video/" + extension;
    }

    return "image/" + extension;
  }

  public processUpload(
    fieldName: string,
    file: File,
    metadata: Dictionary<any>,
    load: (id: string) => void,
    error: (errorText: string) => void,
    progress: (
      lengthComputable: boolean,
      loaded: number,
      total: number
    ) => void,
    abort: () => void
    // transfer: (transferId: string) => void,
    // options: any
  ): { abort: () => void } | void {
    let isAborted = false;

    void (async () => {
      try {
        const isVideo = ["video/mp4", "video/quicktime"].includes(file.type);

        const storeItemId = this.getStoreIdFromFileName(file.name);
        const plushieId = this.fPlushies[storeItemId];

        let plushie = this.fPlushieDataStore.getPlushieById(plushieId);

        if (!plushie) {
          throw new Error("Plushie not found!!!");
        }

        const data = {
          file,
          onUploadProgress: (e: ProgressEvent) => {
            progress(e.lengthComputable, e.loaded, e.total);
          },
        };

        let item;
        let itemThumb;

        if (isVideo) {
          const uploadResult = await this.fFileStorageDataStore.uploadVideoFile(
            data
          );
          item = uploadResult.item;
          itemThumb = uploadResult.itemThumb;
        } else {
          item = await this.fFileStorageDataStore.uploadFile({
            ...data,
            imageType: FileProcessingImageType.QaPhoto,
            product: plushie.product
          });
        }

        if (isAborted) {
          void this.fFileStorageDataStore.deleteItemById(item.id);

          if (itemThumb) {
            void this.fFileStorageDataStore.deleteItemById(itemThumb.id);
          }

          return;
        }

        if (plushie.status === PlushieStatusValue.IN_PRODUCTION) {
          if (!(plushie.id in this.fPlushiesPromises)) {
            const promise = this.fProductionProcessDataStore.MoveToQualityInspection(
              plushie.id
            );

            this.fPlushiesPromises[plushie.id] = promise;
          }

          try {
            plushie = await this.fPlushiesPromises[plushie.id];
          } finally {
            delete this.fPlushiesPromises[plushie.id];
          }
        }

        if (isAborted) {
          void this.fFileStorageDataStore.deleteItemById(item.id);

          if (itemThumb) {
            void this.fFileStorageDataStore.deleteItemById(itemThumb.id);
          }

          return;
        }

        const qaAsset = await this.fQualityInspectionDataStore.createQaAsset({
          id: this.fIdGenerator.getId(),
          plushie: plushie.id,
          type: isVideo ? QaAssetType.VIDEO : QaAssetType.PHOTO,
          storageItem: item.id,
          angle: isVideo ? undefined : QaPhotoAngle.OTHER,
          thumbnailStorageItemId: itemThumb ? itemThumb.id : undefined,
        });

        load(qaAsset.id);
      } catch (e) {
        const errors = this.fErrorConverterService.describeError(e);
        this.fUploadErrors.push(`"${file.name}" - ` + errors.join(", "));
        //console.error(e);
        error(errors[0]);
      }
    })();

    return {
      abort: () => {
        isAborted = true;
        abort();
      },
    };
  }

  public async uploadFiles(): Promise<void> {
    const fileInput = this.getFileInput();
    if (!fileInput || this.fIsUploading) {
      return;
    }

    this.fIsUploading = true;

    this.fUploadErrors = [];

    const files = fileInput.getFiles();

    const filesMap: Dictionary<FilePondFile[]> = {};

    files.forEach((file) => {
      const storeItemId = this.getStoreIdFromFileName(
        file.filenameWithoutExtension
      );

      if (!filesMap[storeItemId]) {
        filesMap[storeItemId] = [];
      }

      filesMap[storeItemId].push(file);
    });

    const plushies = await this.fPlushieDataStore.loadPlushiesByStoreItemIds(
      Object.keys(filesMap)
    );

    const plushieIds: Dictionary<string> = {};

    Object.keys(filesMap).forEach((storeItemId) => {
      const plushie = plushies[storeItemId];
      let error: string | undefined = undefined;

      if (!plushie) {
        error = "no_plushie";
      } else if (
        ![
          PlushieStatusValue.IN_PRODUCTION,
          PlushieStatusValue.QUALITY_INSPECTION,
        ].includes(plushie.status)
      ) {
        error = "wrong_status";
      }

      if (error) {
        filesMap[storeItemId].forEach((file) => {
          const errorMessage =
            error === "no_plushie"
              ? `"${file.filename}" - plushie with the SKU "${storeItemId}" was not found!`
              : `"${file.filename}" - plushie is not in the correct status!`;

          this.fUploadErrors.push(errorMessage);

          fileInput.removeFile(file);
        });

        delete filesMap[storeItemId];
        delete plushies[storeItemId];
      }

      if (!error) {
        plushieIds[storeItemId] = plushie.id;
      }
    });

    if (Object.keys(plushies).length === 0) {
      this.fIsUploading = false;
      return;
    }

    this.fPlushies = {
      ...this.fPlushies,
      ...plushieIds,
    };

    try {
      await fileInput.processFiles();
    } catch (e) {
      //
    } finally {
      this.fIsUploading = false;
    }
  }

  private getFileInput(): VueFilePondComponent | undefined {
    return (this.$refs["file-input"] as unknown) as VueFilePondComponent;
  }

  private getStoreIdFromFileName(fileName: string): string {
    const fileNameWithoutExtension = fileName.split(".")[0];
    return fileNameWithoutExtension.replace(/(^\d+)\D*/g, "$1");
  }
}
