

















import Component, { mixins } from "vue-class-component";
import { Options, SlideData } from "blueimp-gallery/js/blueimp-gallery.min.js";

import ImagesGalleryMixin from "@/lib/mixins/ImagesGallery";

@Component
export default class ImagesGallery extends mixins(ImagesGalleryMixin) {
  public launchGalleria(
    slides: SlideData[],
    index: number,
    options: Options = {}
  ): void {
    this.initGallery(slides, index, options);
  }
}
