import CurrentUserStateReporter from "./current-user-state-reporter";
import UserActiveStateProvider from "./user-active-state-provider";

export default class CurrentUserStateReporterFactory {
  constructor(
    private fUserActiveStateProvider: UserActiveStateProvider,
    private fWindow: Window
  ) {}

  public create(socket: SocketIOClient.Socket): CurrentUserStateReporter {
    return new CurrentUserStateReporter(
      socket,
      this.fUserActiveStateProvider,
      this.fWindow
    );
  }
}
