import { MutableValue } from "@/modules/api/mutable-value.interface";
import StorageItemReferenceInterface from "@/modules/file-storage/storage-item-reference.interface";

export default class PlushieImage
  implements MutableValue, StorageItemReferenceInterface {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fType: string,
    private fStorageItem: string,
    private fSerialNumber: number
  ) {
    [fId, fPlushie, fType, fStorageItem, fSerialNumber].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get plushie() {
    return this.fPlushie;
  }

  get storageItem() {
    return this.fStorageItem;
  }

  get type() {
    return this.fType;
  }

  get serialNumber(): number {
    return this.fSerialNumber;
  }

  get updatedAt() {
    return this.fUpdatedAt;
  }
}
