

























import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

import FactoryInvoicingSettings from "../../factory-invoicing-settings.model";

@Component({})
export default class FactoryInvoicingSettingsView extends Vue {
  @Prop({ required: true })
  public invoicingSettings!: FactoryInvoicingSettings;
}
