














import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import ShippingInformationView from "@/modules/plushie/components/ShippingInformation/View.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import ProductionProcessStore from "@/modules/production-process/store";

@Component({
  components: {
    FormErrors,
    ShippingInformationView,
    SubmitButton,
  },
})
export default class ConfirmBulkShipmentCommand extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fProductionProcessStore: ProductionProcessStore;

  constructor() {
    super();
    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);
  }

  protected async performSubmit(): Promise<void> {
    await this.fProductionProcessStore.confirmBulkShipment(this.plushieId);
  }
}
