









































import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import PlushieStore from "@/modules/plushie/store";
import BulkProjectStore from "@/modules/bulk-project/store";
import { ProductValue } from "@/modules/plushie/product.value";
import Plushie from "@/modules/plushie/plushie.model";

@Component({
  components: {
    FormErrors,
    FormField,
    SubmitButton,
  },
})
export default class SampleSearchForm extends GeneralFormMixin {
  private static readonly PLUSHIE_ID_REGEXP = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  private static readonly STORE_ITEM_ID_REGEXP = /^(MW)?\d*[1-9]\d*$/;
  private static readonly SAMPLE_PRODUCTS = [
    ProductValue.BULK_SAMPLE,
    ProductValue.PILLOW_BULK_SAMPLE,
    ProductValue.KEYCHAIN_BULK_SAMPLE,
  ];

  @Prop({ default: "" })
  public readonly samplePlushieId!: string;

  public searchRequest = "";

  private fPlushieStore: PlushieStore;
  private fBulkProjectStore: BulkProjectStore;

  constructor() {
    super();

    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
  }

  created(): void {
    this.searchRequest = this.samplePlushieId;

    if (this.searchRequest) {
      void this.performSubmit();
    }
  }

  public onChange(): void {
    this.submitErrors = [];
  }

  protected async performSubmit(): Promise<void> {
    if (!this.searchRequest) {
      return;
    }

    let plushie: Plushie | undefined;

    if (SampleSearchForm.PLUSHIE_ID_REGEXP.test(this.searchRequest)) {
      const plushies = await this.fPlushieStore.loadPlushiesByIds([
        this.searchRequest,
      ]);
      plushie = plushies[this.searchRequest];
    } else if (SampleSearchForm.STORE_ITEM_ID_REGEXP.test(this.searchRequest)) {
      const plushies = await this.fPlushieStore.loadPlushiesByStoreItemIds([
        this.searchRequest,
      ]);
      plushie = plushies[this.searchRequest];
    }

    if (!plushie) {
      this.$emit("search-result", undefined);
      throw new Error("Not found");
    }

    if (!SampleSearchForm.SAMPLE_PRODUCTS.includes(plushie.product)) {
      this.$emit("search-result", undefined);
      throw new Error("Not a sample plushie");
    }

    const existingBulkProjectRelation = await this.fBulkProjectStore.loadBulkProjectPlushieRelationByPlushieId(
      plushie.id
    );

    if (existingBulkProjectRelation) {
      this.$emit("search-result", undefined);
      throw new Error("This Sample is already attached to a bulk project");
    }

    this.$emit("search-result", plushie);
  }
}
