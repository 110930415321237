import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

import PlushiePriority from "../plushie-priority.model";

const name = "PlushiePriorityStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    plushiePriority: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class PlushiePriorityStore extends VuexModule {
  plushiePriority: Dictionary<PlushiePriority> = {};

  // ################################### PLUSHIE PRIORITY #########################################

  get getPlushiePriorityByPlushieId(): (
    plushieId: string
  ) => PlushiePriority | undefined {
    return (plushieId: string) => this.plushiePriority[plushieId];
  }

  @Mutation
  updatePlushiePriority(payload: PlushiePriority): void {
    Vue.set(this.plushiePriority, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadPlushiePriorityByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PlushiePriority | undefined> {
    if (useCache && this.plushiePriority[plushieId]) {
      return this.plushiePriority[plushieId];
    }

    const priority = await DIContainer.PlushiePriorityRepository.getById(
      plushieId
    );

    if (priority) {
      this.updatePlushiePriority(priority);
    }

    return priority;
  }

  // ################################### PLUSHIE PRIORITY BONUS #########################################

  @Action({ rawError: true })
  async addPriorityBonusForPlushie({
    plushieId,
    bonusId,
  }: {
    plushieId: string;
    bonusId: number;
  }): Promise<void> {
    this.context.commit("markPlushieAsProcessing", plushieId);

    try {
      await DIContainer.PlushiePriorityRepository.addPriorityBonusForPlushie(
        plushieId,
        bonusId
      );

      await this.context.dispatch("onPlushiePriorityChanged", plushieId);
    } finally {
      this.context.commit("removePlushieProcessingMark", plushieId);
    }
  }

  @Action({ rawError: true })
  async removePriorityBonusForPlushie({
    plushieId,
    bonusId,
  }: {
    plushieId: string;
    bonusId: number;
  }): Promise<void> {
    this.context.commit("markPlushieAsProcessing", plushieId);

    try {
      await DIContainer.PlushiePriorityRepository.removePriorityBonusForPlushie(
        plushieId,
        bonusId
      );

      await this.context.dispatch("onPlushiePriorityChanged", plushieId);
    } finally {
      this.context.commit("removePlushieProcessingMark", plushieId);
    }
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushiePriority[plushieId]) {
      return;
    }

    await this.loadPlushiePriorityByPlushieId({
      plushieId,
      useCache: false,
    });

    return;
  }

  @Action({ rawError: true })
  async onPlushiePriorityChanged(plushieId: string): Promise<void> {
    if (!this.plushiePriority[plushieId]) {
      return;
    }

    await this.loadPlushiePriorityByPlushieId({
      plushieId,
      useCache: false,
    });

    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
