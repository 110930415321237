import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import BulkProject from "./bulk-project.model";
import CreateBulkProjectPayload from "./create-bulk-project-payload.interface";

export default class BulkProjectRepository extends BasicRepository<BulkProject> {
  private readonly fAssignResponsibleUserResourceUrl =
    "bulk_projects_responsible_users_assignments";

  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BulkProject>,
    responseParser: ApiCollectionResponseParserService<BulkProject>
  ) {
    super("bulk_projects", api, objectBuilder, responseParser);
  }

  async assignResponsibleUserToBulkProject(
    bulkProjectId: string,
    responsibleUserId: string
  ): Promise<BulkProject> {
    const result = await this.api.post(this.fAssignResponsibleUserResourceUrl, {
      bulkProjectId,
      responsibleUserId,
    });

    return this.objectBuilder.buildFromJSON(result.data);
  }

  async createBulkProject(
    payload: CreateBulkProjectPayload
  ): Promise<BulkProject> {
    const result = await this.api.post(this.resourceName, payload);

    return this.objectBuilder.buildFromJSON(result.data);
  }
}
