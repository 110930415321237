import { Resource } from "./resource";

export default class AuthenticatedUser {
  public static readonly ROLE_ADMIN = "ROLE_ADMIN";
  public static readonly ROLE_FACTORY = "ROLE_FACTORY";
  public static readonly ROLE_STORE = "ROLE_STORE";
  public static readonly ROLE_CUSTOMER = "ROLE_CUSTOMER";

  constructor(
    private fId: string,
    private fRole: string,
    private fPermissions: string[]
  ) {
    [fId, fRole, fPermissions].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get role() {
    return this.fRole;
  }

  get permissions() {
    return [...this.fPermissions];
  }

  hasPermissionForResource(resource: Resource): boolean {
    return this.fPermissions.includes(resource);
  }
}
