










import { Component, Vue } from "vue-property-decorator";

import HolidayCutoffReportForm from "./CutoffReport/Form.vue";

@Component({
  components: {
    HolidayCutoffReportForm,
  },
  metaInfo: {
    title: "Holiday Cutoff Report",
  },
})
export default class CutoffReportPage extends Vue {}
