import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Alert from "./alert.model";

export default class AlertFactory implements ObjectBuilderInterface<Alert> {
  public buildFromJSON(data: { [key: string]: any }): Alert {
    const value = new Alert(
      data.id,
      data.title,
      data.iconStorageId,
      data.alertType,
      !!data.displayToFactory,
      data.notifyAboutMovements,
      data.notificationRecipientId
    );

    value.description = data.description;

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
