import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import LabelHeader from "./label-header.model";

export default class LabelHeaderFactory
  implements ObjectBuilderInterface<LabelHeader> {
  public buildFromJSON(data: { [key: string]: any }): LabelHeader {
    const value = new LabelHeader(data.id, data.name, data.content);

    if (data.storeId) {
      (value as any).fStoreId = data.storeId.toString();
    }

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
