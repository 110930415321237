import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

import ProductionSchedule from "../production-schedule.model";

const name = "DueDatesManagementStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    productionSchedule: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class DueDatesManagementStore extends VuexModule {
  productionSchedule: Dictionary<ProductionSchedule> = {};

  // ################################### PRODUCTION SCHEDULE #########################################

  get getProductionScheduleByPlushieId(): (
    plushieId: string
  ) => ProductionSchedule | undefined {
    return (plushieId: string) => this.productionSchedule[plushieId];
  }

  @Mutation
  updateProductionSchedule(payload: ProductionSchedule) {
    Vue.set(this.productionSchedule, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadProductionScheduleByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }) {
    if (useCache && this.productionSchedule[plushieId]) {
      return this.productionSchedule[plushieId];
    }

    const schedule = await DIContainer.ProductionScheduleRepository.getById(
      plushieId
    );

    if (schedule) {
      this.updateProductionSchedule(schedule);
    }

    return schedule;
  }

  @Action({ rawError: true })
  async loadProductionSchedulesByPlushieIds({
    plushieIds,
    useCache = true,
  }: {
    plushieIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<ProductionSchedule>> {
    const missing: string[] = [];
    const result: Dictionary<ProductionSchedule> = {};

    plushieIds.forEach((id) => {
      if (useCache && this.productionSchedule[id] != null) {
        result[id] = this.productionSchedule[id];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.ProductionScheduleRepository.getByIds(
      missing
    );

    Object.keys(items).forEach((itemId) => {
      const schedule = items[itemId];

      if (schedule === undefined) {
        return;
      }

      this.updateProductionSchedule(schedule);
      result[schedule.id] = schedule;
    });

    return result;
  }

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string) {
    if (!this.productionSchedule[plushieId]) {
      return;
    }

    await this.loadProductionScheduleByPlushieId({
      plushieId,
      useCache: false,
    });
    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
