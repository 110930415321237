import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import RemotePrintingCredentials from "./remote-printing-credentials.model";

export default class RemotePrintingCredentialsFactory
  implements ObjectBuilderInterface<RemotePrintingCredentials> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): RemotePrintingCredentials {
    const value = new RemotePrintingCredentials(data.factoryId, data.token);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
