import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductionTimeHistory from "./production-time-history.model";

export default class ProductionTimeHistoryFactory
  implements ObjectBuilderInterface<ProductionTimeHistory> {
  public buildFromJSON(data: { [key: string]: any }): ProductionTimeHistory {
    const value = new ProductionTimeHistory(
      data.id,
      data.plushieId,
      new Date(data.dueDate),
      new Date(data.qaApprovementDate),
      data.applyPenalty,
      data.submitToQaDate ? new Date(data.submitToQaDate) : undefined
    );

    value.isNew = false;

    return value;
  }
}
