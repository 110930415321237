import { render, staticRenderFns } from "./FactoryAddPage.vue?vue&type=template&id=352f5717&scoped=true&"
import script from "./FactoryAddPage.vue?vue&type=script&lang=ts&"
export * from "./FactoryAddPage.vue?vue&type=script&lang=ts&"
import style0 from "./FactoryAddPage.vue?vue&type=style&index=0&id=352f5717&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352f5717",
  null
  
)

export default component.exports