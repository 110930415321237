






























































































































































































































































































































































































































import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import BarcodePrintJobCommand from "@/modules/barcode/components/BarcodePrintJobCommand.vue";
import BodyPartsEditForm from "@/modules/body-part/components/BodyPartsEditForm.vue";
import BodyPartsViewForm from "@/modules/body-part/components/BodyPartsViewForm.vue";
import AutoApprovementToggleForm from "@/modules/customer-preview/components/AutoApprovementToggleForm.vue";
import DocumentExportCommands from "@/modules/document-export/components/DocumentExportCommands.vue";
import Commands from "@/modules/production-process/components/CommandsCard.vue";
import ExtraPayments from "@/modules/factory-invoice/components/ExtraPayments.vue";
import PlushieHolidayPeriodManagementWidget from "@/modules/holiday-period/components/PlushieHolidayPeriodManagementWidget.vue";
import FinishedProductPhotosList from "@/modules/quality-inspection/components/FinishedProductPhotosList.vue";
import MessagesHistory from "@/modules/messaging/components/MessagesHistory.vue";
import MessageSendingForm from "@/modules/messaging/components/MessageSendingForm.vue";
import PlushiePenaltyManagementForm from "@/modules/factory-invoice/components/PlushiePenaltyManagementForm.vue";
import PlushieAddTagForm from "@/modules/tag/components/PlushieAddTagForm.vue";
import PlushieTagsList from "@/modules/tag/components/PlushieTagsList.vue";
import PlushieAssignedAlertsList from "@/modules/plushie-alerts/components/AssignedAlertsList.vue";
import QaAssetsGallery from "@/modules/quality-inspection/components/QaAssetsGallery.vue";
import LastRejectionMessage from "@/modules/quality-inspection/components/LastRejectionMessage.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";
import PreviewCountStats from "@/modules/customer-preview/components/preview-count-stats.vue";
import BulkShipmentInfo from "@/modules/bulk-shipment/components/BulkShipmentInfo.vue";
import OperatorSessionTimer from "@/modules/statistics/components/Timer.vue";
import BulkProjectCard from "@/modules/bulk-project/components/BulkProjectCard.vue";
import BatchInformation from "@/modules/bulk-project/components/BatchInformation.vue";
import ProductionTimeHistoryTable from "@/modules/factory-invoice/components/ProductionTimeHistoryTable.vue";
import PlushieShipmentInfo from "@/modules/plushie/components/PlushieShipmentInfo.vue";
import PlushieAttachmentsListView from "@/modules/plushie/components/Attachments/ListView.vue";

import { Resource } from "@/modules/account/resource";
import BasicRepository from "@/modules/api/basic.repository";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import dataStore from "@/store";
import UserPresenceNotifier from "@/modules/user-activity-notifier/user-presence-notifier";
import UserPresenceNotifierFactory from "@/modules/user-activity-notifier/user-presence-notifier.factory";

import PlushieDirectShipmentIntention from "./PlushieDirectShipmentIntention.vue";
import ProductionProgressCard from "./ProductionProgressCard.vue";
import ProductionTaskCard from "./ProductionTaskCard.vue";
import OrderDetailsCard from "./OrderDetailsCard.vue";
import FactoryOrderDetailsCard from "./FactoryOrderDetailsCard.vue";
import EditableText from "./EditableText.vue";
import ArtworkUpload from "./ArtworkUpload.vue";
import ArtworkGallery from "./ArtworkGallery.vue";
import ActivityLog from "./ActivityLog.vue";
import SameOrderPlushies from "./SameOrderPlushies.vue";
import ShippingInformationView from "./ShippingInformation/View.vue";

import PlushieStore from "../store";
import Plushie from "../plushie.model";
import PlushieDesignInformation from "../plushie-design-information.model";
import PlushieStatus from "../plushie-status.model";
import PlushieSummary from "../plushie-summary.model";
import PlushieFactorySummary from "../plushie-factory-summary.model";
import PlushieDesignInformationUpdate from "../plushie-design-information-update.interface";
import Product from "../product.model";
import { PlushieStatusValue } from "../plushie-status.value";
import doesPlushieHaveBodyParts from "../does-plushie-have-body-parts.function";
import { ProductValue } from "../product.value";

@Component({
  metaInfo() {
    const component = this as PlushieViewPage;

    return {
      title: component.plushie
        ? "#" + component.plushie.storeItemId
        : "Plushie View",
    };
  },
  components: {
    AutoApprovementToggleForm,
    PlushieShipmentInfo,
    BatchInformation,
    BarcodePrintJobCommand,
    BodyPartsEditForm,
    BodyPartsViewForm,
    BulkProjectCard,
    DocumentExportCommands,
    MoonLoader,
    ActivityLog,
    ArtworkGallery,
    ArtworkUpload,
    Commands,
    EditableText,
    ExtraPayments,
    PlushieHolidayPeriodManagementWidget,
    FinishedProductPhotosList,
    ListItemPageNavigation,
    MessagesHistory,
    MessageSendingForm,
    OrderDetailsCard,
    FactoryOrderDetailsCard,
    PlushieDirectShipmentIntention,
    PlushiePenaltyManagementForm,
    PlushieAddTagForm,
    PlushieTagsList,
    PlushieAssignedAlertsList,
    PreviewCountStats,
    ProductionProgressCard,
    ProductionTaskCard,
    QaAssetsGallery,
    SameOrderPlushies,
    LastRejectionMessage,
    BulkShipmentInfo,
    ShippingInformationView,
    OperatorSessionTimer,
    ProductionTimeHistoryTable,
    PlushieAttachmentsListView,
  },
})
export default class PlushieViewPage extends Vue {
  @Prop()
  public readonly plushieId!: string;

  @Prop()
  public readonly query!: Record<string, any>;

  @Prop()
  public readonly listQuery?: Record<string, unknown>;

  @Inject("PlushieSummaryRepository")
  private fPlushieSummaryRepository!: BasicRepository<PlushieSummary>;

  @Inject("PlushieFactorySummaryRepository")
  private fPlushieFactorySummaryRepository!: BasicRepository<PlushieFactorySummary>;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("UserPresenceNotifierFactory")
  private fUserPresenceNotifierFactory!: UserPresenceNotifierFactory;

  @Inject("window")
  private fWindow!: Window;

  private fUserPresenceNotifier?: UserPresenceNotifier;
  private fIsLoading = true;
  private fIsActivityLogExpanded = false;
  private fIsDirectShipmentIntentionExpanded = false;
  private fIsExtraPaymentExpanded = false;
  private fIsHolidayPeriodManagementExpanded = false;
  private fIsPenaltyManagementExpanded = false;
  private fIsPlushieAttachmentsListViewExpanded = false;
  private fIsProductionTimeHistoryExpanded = false;
  private fIsShippingInformationViewExpanded = false;

  private fPlushieDataStore: PlushieStore;

  constructor() {
    super();

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isListNavigationAvailable(): boolean {
    return Object.keys(this.query).length > 0;
  }

  get isActivityLogExpanded(): boolean {
    return this.fIsActivityLogExpanded;
  }

  get isDirectShipmentIntentionExpanded(): boolean {
    return this.fIsDirectShipmentIntentionExpanded;
  }

  get isExtraPaymentExpanded(): boolean {
    return this.fIsExtraPaymentExpanded;
  }

  get isHolidayPeriodManagementExpanded(): boolean {
    return this.fIsHolidayPeriodManagementExpanded;
  }

  get isPenaltyManagementExpanded(): boolean {
    return this.fIsPenaltyManagementExpanded;
  }

  get isPlushieAttachmentsListViewExpanded(): boolean {
    return this.fIsPlushieAttachmentsListViewExpanded;
  }

  get isProductionTimeHistoryExpanded(): boolean {
    return this.fIsProductionTimeHistoryExpanded;
  }

  get isShippingInformationViewExpanded(): boolean {
    return this.fIsShippingInformationViewExpanded;
  }

  get canEditDesignerComment(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    const qaStatuses = [
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.BULK_INSPECTION,
    ];

    return (
      !qaStatuses.includes(this.plushie.status) &&
      user.hasPermissionForResource(Resource.PLUSHIE_DESIGN_INFORMATION_UPDATE)
    );
  }

  get canExportPlushie(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      !user.hasPermissionForResource(Resource.PLUSHIE_DOCUMENT_EXPORTS_VIEW)
    ) {
      return false;
    }

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return true;
    }

    return this.plushie.status !== PlushieStatusValue.READY_FOR_PRODUCTION;
  }

  get canManageArtworks(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_IMAGES_MANAGE);
  }

  get canManagePenaltyBypasses(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_PENALTY_BYPASSES_MANAGE
    );
  }

  get canManageTags(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.MANAGE_PLUSHIE_TAGS);
  }

  get canViewArtworks(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (!this.isFactoryView) {
      return true;
    }

    return this.plushie.status !== PlushieStatusValue.READY_FOR_PRODUCTION;
  }

  get canViewAutoApprovementToggleForm(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (![PlushieStatusValue.CUSTOMER_PREVIEW].includes(this.plushie.status)) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.AUTO_APPROVEMENT_EXCLUDES_VIEW) ||
      user.hasPermissionForResource(Resource.AUTO_APPROVEMENT_EXCLUDES_MANAGE)
    );
  }

  get canViewPreviewCountStats(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.CUSTOMER_PREVIEW_COUNT_VIEW);
  }

  get canPrintBarcode(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (!PlushieStatus.PRODUCTION_STATUSES.includes(this.plushie.status)) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get canViewDirectShipmentIntention(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.DIRECT_SHIPMENT_INTENTION_VIEW) ||
      user.hasPermissionForResource(Resource.DIRECT_SHIPMENT_INTENTION_MANAGE)
    );
  }

  get canViewShippingInformation(): boolean {
    if (!this.product || !this.plushie) {
      return false;
    }

    if (
      !this.product.isBatch &&
      this.plushie.status !== PlushieStatusValue.AWAITING_DIRECT_SHIPMENT
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.SHIPPING_INFORMATION_VIEW);
  }

  get canViewExtraPayments(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      ![AuthenticatedUser.ROLE_STORE, AuthenticatedUser.ROLE_ADMIN].includes(
        user.role
      )
    ) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_EXTRA_PAYMENTS_READ
    );
  }

  get canManagePlushieHolidayPeriodRelation(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.HOLIDAY_PERIOD_PLUSHIE_RELATION_MANAGE
    );
  }

  get canViewTags(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.READ_PLUSHIE_TAGS);
  }

  get canUploadArtworks(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.PLUSHIE_IMAGES_MANAGE) &&
      user.hasPermissionForResource(Resource.ARTWORKS_UPLOAD)
    );
  }

  get designInformation(): PlushieDesignInformation | undefined {
    return this.fPlushieDataStore.getDesignInformationByPlushieId(
      this.plushieId
    );
  }

  get isFactoryView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return true;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get isStickyElementEnabled(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (this.fWindow.innerWidth < 1024) {
      return false;
    }

    return [
      PlushieStatusValue.REVIEW,
      PlushieStatusValue.READY_FOR_PRODUCTION,
    ].includes(this.plushie.status);
  }

  get pageClass(): string {
    if (!this.plushie) {
      return "";
    }

    switch (this.plushie.status) {
      case PlushieStatusValue.REVIEW:
        return "-review-process";

      case PlushieStatusValue.READY_FOR_PRODUCTION:
        return "-ready-for-production";

      case PlushieStatusValue.PPS_INSPECTION:
      case PlushieStatusValue.BULK_INSPECTION:
      case PlushieStatusValue.QUALITY_INSPECTION:
        return "-quality-inspection";

      default:
        return "";
    }
  }

  get plushie(): Plushie | undefined {
    if (this.plushieId == null) {
      return undefined;
    }

    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  get product(): Product | undefined {
    if (!this.plushie) {
      return undefined;
    }

    return this.fPlushieDataStore.getProductById(this.plushie.product);
  }

  get showActivityLog(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.ACTION_LOGGER_LOGS_READ);
  }

  get showBodyPartsEditForm(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (!doesPlushieHaveBodyParts(this.plushie.product)) {
      return false;
    }

    if (
      ![
        PlushieStatusValue.REVIEW,
        PlushieStatusValue.ON_HOLD,
        PlushieStatusValue.APPROVED,
        PlushieStatusValue.READY_FOR_PRODUCTION,
        PlushieStatusValue.PENDING_BULK_PRODUCTION,
      ].includes(this.plushie.status)
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BODYPARTS_MANAGE);
  }

  get showBodyPartsViewForm(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (!doesPlushieHaveBodyParts(this.plushie.product)) {
      return false;
    }

    if (
      [
        PlushieStatusValue.REVIEW,
        PlushieStatusValue.ON_HOLD,
        PlushieStatusValue.APPROVED,
        PlushieStatusValue.READY_FOR_PRODUCTION,
        PlushieStatusValue.PENDING_BULK_PRODUCTION,
      ].includes(this.plushie.status)
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BODYPARTS_VIEW);
  }

  get showOperatorSessionTimer(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user || !this.plushie) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.OPERATOR_SESSION_CREATE)) {
      return false;
    }

    if (
      ![
        PlushieStatusValue.QUALITY_INSPECTION,
        PlushieStatusValue.BULK_INSPECTION,
        PlushieStatusValue.PPS_INSPECTION,
        PlushieStatusValue.REVIEW,
      ].includes(this.plushie.status)
    ) {
      return false;
    }

    return true;
  }

  get showPlushieAttachmentsListView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_ATTACHMENT_VIEW);
  }

  get showProductionTimeHistory(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PRODUCTION_TIME_HISTORY_READ);
  }

  get showPlushieShipmentInfo(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (
      this.plushie.status !== PlushieStatusValue.SHIPPED_TO_CUSTOMER &&
      this.plushie.status !== PlushieStatusValue.SENT_TO_CUSTOMER
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.TRACKING_INFORMATION_VIEW);
  }

  get showBulkShipmentInfo(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (this.plushie.status !== PlushieStatusValue.SHIPPED_TO_MAIN_FACILITY) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_SHIPMENTS_READ);
  }

  get canViewLastRejectionMessage(): boolean {
    if (!this.plushie) {
      return false;
    }

    if (
      ![
        PlushieStatusValue.IN_PRODUCTION,
        PlushieStatusValue.REWORK,
        PlushieStatusValue.IN_BULK_PRODUCTION,
        PlushieStatusValue.IN_BULK_DESIGN,
      ].includes(this.plushie.status)
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.QUALITY_INSPECTION_DECISION_READ
    );
  }

  get showBulkProjectRelationCard(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user || !this.plushie) {
      return false;
    }

    if (
      ![
        ProductValue.BULK_SAMPLE,
        ProductValue.PILLOW_BULK_SAMPLE,
        ProductValue.KEYCHAIN_BULK_SAMPLE,
        ProductValue.PILLOW_BATCH,
        ProductValue.PLUSHIE_BATCH,
        ProductValue.KEYCHAIN_BATCH,
      ].includes(this.plushie.product)
    ) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.BULK_PROJECT_VIEW) ||
      user.hasPermissionForResource(Resource.BULK_PROJECT_MANAGE)
    );
  }

  get showOrderDetails(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    //#22088: Temporary show the `eric` user order details card (until better solution will be implemented)
    if (user.id === "5e032274-e510-49f0-b96f-dd420d1eb471") {
      return true;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
    ].includes(user.role);
  }

  get showFactoryOrderDetails(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get showFinishedProductPhotos(): boolean {
    if (!this.plushie) {
      return false;
    }

    const qaStatuses = [
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.BULK_INSPECTION,
    ];

    return !qaStatuses.includes(this.plushie.status);
  }

  get showDesignerComment(): boolean {
    if (!this.plushie || !this.designInformation) {
      return false;
    }

    return PlushieStatus.REVIEWED_STATUSES.includes(this.plushie.status);
  }

  get canEditDesignerCommentTranslation(): boolean {
    if (!this.isDesignerCommentTranslationAvailable) {
      return false;
    }

    const user = this.fUserProvider.getUser();
    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PLUSHIE_DESIGNER_COMMENT_TRANSLATION_UPDATE
    );
  }

  get showDesignerCommentTranslation(): boolean {
    if (this.canEditDesignerCommentTranslation) {
      return false;
    }

    return this.isDesignerCommentTranslationAvailable;
  }

  get isDesignerCommentTranslationAvailable(): boolean {
    if (
      !this.plushie ||
      !this.designInformation ||
      !this.designInformation.commentTranslation
    ) {
      return false;
    }

    if (!PlushieStatus.REVIEWED_STATUSES.includes(this.plushie.status)) {
      return false;
    }

    const user = this.fUserProvider.getUser();
    if (!user) {
      return false;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get showSameOrderPlushies(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();
    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
      AuthenticatedUser.ROLE_FACTORY,
    ].includes(user.role);
  }

  get showQaAssetsGallery(): boolean {
    if (!this.plushie) {
      return false;
    }

    const qaInspectionsStatuses = [
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.BULK_INSPECTION,
    ];

    return qaInspectionsStatuses.includes(this.plushie.status);
  }

  public getPlushiesOrder(): Promise<string[]> {
    return this.isFactoryView
      ? this.fPlushieFactorySummaryRepository.getListOfIds(
          this.query.order,
          this.query.filter
        )
      : this.fPlushieSummaryRepository.getListOfIds(
          this.query.order,
          this.query.filter
        );
  }

  public toggleActivityLog(): void {
    this.fIsActivityLogExpanded = !this.fIsActivityLogExpanded;
  }

  public toggleDirectShipmentIntention(): void {
    this.fIsDirectShipmentIntentionExpanded = !this
      .fIsDirectShipmentIntentionExpanded;
  }

  public toggleExtraPayments(): void {
    this.fIsExtraPaymentExpanded = !this.fIsExtraPaymentExpanded;
  }

  public toggleHolidayPeriodManagement(): void {
    this.fIsHolidayPeriodManagementExpanded = !this
      .fIsHolidayPeriodManagementExpanded;
  }

  public togglePenaltyManagement(): void {
    this.fIsPenaltyManagementExpanded = !this.fIsPenaltyManagementExpanded;
  }

  public togglePlushieAttachmentsListView(): void {
    this.fIsPlushieAttachmentsListViewExpanded = !this
      .fIsPlushieAttachmentsListViewExpanded;
  }

  public toggleProductionTimeHistory(): void {
    this.fIsProductionTimeHistoryExpanded = !this
      .fIsProductionTimeHistoryExpanded;
  }

  public toggleShippingInformationView(): void {
    this.fIsShippingInformationViewExpanded = !this
      .fIsShippingInformationViewExpanded;
  }

  public async updateDesignerComment(comment: string): Promise<void> {
    if (!this.plushie) {
      return;
    }

    const value: PlushieDesignInformationUpdate = {
      plushieId: this.plushieId,
      comment: comment,
    };

    await this.fPlushieDataStore.updatePlushieDesignInformation(value);
  }

  public async updateDesignerCommentTranslation(
    commentTranslation: string
  ): Promise<void> {
    if (!this.plushie) {
      return;
    }

    const value: PlushieDesignInformationUpdate = {
      plushieId: this.plushieId,
      commentTranslation: commentTranslation,
    };

    await this.fPlushieDataStore.updatePlushieDesignInformation(value);
  }

  protected beforeDestroy(): void {
    if (this.fUserPresenceNotifier) {
      this.fUserPresenceNotifier.destroy();
      this.fUserPresenceNotifier = undefined;
    }
  }

  private initUserPresenceNotifier(): void {
    const user = this.fUserProvider.getUser();
    if (!user) {
      return;
    }

    if (
      ![AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE].includes(
        user.role
      )
    ) {
      return;
    }

    if (this.fUserPresenceNotifier) {
      this.fUserPresenceNotifier.destroy();
      this.fUserPresenceNotifier = undefined;
    }

    this.fUserPresenceNotifier = this.fUserPresenceNotifierFactory.create(
      this.plushieId
    );
  }

  private async getPlushieData(plushieId: string) {
    const plushie = await this.fPlushieDataStore.loadPlushieById({
      id: plushieId,
      useCache: false,
    });

    if (!plushie) {
      return;
    }

    void Promise.all([
      this.fPlushieDataStore.loadProducts(),
      this.fPlushieDataStore.loadDesignInformationByPlushieId({ plushieId }),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.initUserPresenceNotifier();

    await this.getPlushieData(this.plushieId);
    this.fIsLoading = false;
  }
}
