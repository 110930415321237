import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { Dictionary } from "@/lib/Dictionary.type";
import { FieldOrderAsc } from "@/modules/api/field-order-options";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import dataStore from "@/store";
import { ResourceCollection } from "@/modules/api/resource.collection";

import Role from "../role.model";
import UserAccount from "../user.model";
import UserInfo from "../user-info.model";
import UserRole from "../user-role.model";
import Organization from "../organization.model";
import PermissionResource from "../permission-resource.model";
import DeactivatedUser from "../deactivated-user.model";
import { MetaRoleValue } from "../meta-role.value";

const name = "AccountStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    deactivatedUser: {},
    metaRole: {},
    metaRoleResource: {},
    organization: {},
    organizationType: {},
    permissionResource: {},
    role: {},
    service: {},
    userAccount: {},
    userInfo: {},
    userRole: {},
    userRelations: {},
    allRolesLoaded: false,
  };
};

interface UserRelations {
  userRole?: string;
}

@Module({ name, dynamic: true, store: dataStore })
export default class AccountStore extends VuexModule {
  deactivatedUser: Dictionary<DeactivatedUser> = {};
  metaRole: Dictionary<DirectoryValue> = {};
  metaRoleResource: Dictionary<string[]> = {};
  organization: Dictionary<Organization> = {};
  organizationType: Dictionary<DirectoryValue> = {};
  permissionResource: Dictionary<PermissionResource> = {};
  role: Dictionary<Role> = {};
  service: Dictionary<DirectoryValue> = {};
  userAccount: Dictionary<UserAccount> = {};
  userInfo: Dictionary<UserInfo> = {};
  userRole: Dictionary<UserRole> = {};
  userRelations: Dictionary<UserRelations> = {};

  allRolesLoaded = false;

  // ################################### DEACTIVATED USERS #########################################

  get deactivatedUsers(): Dictionary<DeactivatedUser> {
    return this.deactivatedUser;
  }

  @Mutation
  removeDeactivatedUser(id: string): void {
    delete this.deactivatedUser[id];
  }

  @Mutation
  updateDeactivatedUser(payload: DeactivatedUser): void {
    Vue.set(this.deactivatedUser, payload.id, payload);
  }

  @Action({ rawError: true })
  async deleteDeactivatedUserById(id: string): Promise<void> {
    await DIContainer.DeactivatedUserRepository.deleteById(id);

    this.removeDeactivatedUser(id);

    return;
  }

  @Action({ rawError: true })
  async loadDeactivatedUsers(): Promise<DeactivatedUser[]> {
    const collection = await DIContainer.DeactivatedUserRepository.getList(
      1,
      999
    );

    const items = collection.getItems();

    items.forEach((item) => {
      this.updateDeactivatedUser(item);
    });

    return items;
  }

  @Action({ rawError: true })
  async saveDeactivatedUser(
    deactivatedUser: DeactivatedUser
  ): Promise<DeactivatedUser> {
    const item = await DIContainer.DeactivatedUserRepository.save(
      deactivatedUser
    );

    this.updateDeactivatedUser(item);

    return item;
  }

  // ################################### META ROLES #########################################

  get metaRoles(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.metaRole).forEach((id) => {
      list.push(this.metaRole[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get metaRolesDictionary(): Dictionary<DirectoryValue> {
    return { ...this.metaRole };
  }

  get getMetaRoleById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.metaRole[id];
  }

  @Mutation
  updateMetaRole(payload: DirectoryValue): void {
    Vue.set(this.metaRole, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadMetaRoles(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.metaRole).length === 0) {
      const collection = await DIContainer.MetaRoleRepository.getList(
        1,
        999,
        undefined,
        [["name", FieldOrderAsc]]
      );

      items = collection.getItems();

      items.forEach((item) => {
        this.updateMetaRole(item);
      });
    }

    items = [];

    Object.keys(this.metaRole).forEach((key) => {
      items.push(this.metaRole[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadMetaRoleById(id: string): Promise<DirectoryValue | undefined> {
    await this.loadMetaRoles();

    return this.getMetaRoleById(id);
  }

  // ################################### META ROLE RESOURCES #########################################

  get getMetaRoleResources(): (metaRoleId: string) => string[] {
    return (metaRoleId: string) => {
      if (!this.metaRoleResource[metaRoleId]) {
        return [];
      }

      return this.metaRoleResource[metaRoleId];
    };
  }

  @Mutation
  updateMetaRoleResources({
    metaRoleId,
    resources,
  }: {
    metaRoleId: string;
    resources: string[];
  }): void {
    Vue.set(this.metaRoleResource, metaRoleId, resources);
  }

  @Action({ rawError: true })
  async loadMetaRoleResources(): Promise<void> {
    if (Object.keys(this.metaRoleResource).length > 0) {
      return;
    }

    const collection = await DIContainer.MetaRoleResourceRepository.getList(
      1,
      9999
    );

    const items = collection.getItems();

    const hashMap: Dictionary<string[]> = {};

    items.forEach((item) => {
      if (!hashMap[item.metaRole]) {
        hashMap[item.metaRole] = [];
      }

      hashMap[item.metaRole].push(item.resource);
    });

    for (const metaRoleId in hashMap) {
      this.updateMetaRoleResources({
        metaRoleId,
        resources: hashMap[metaRoleId],
      });
    }

    return;
  }

  // ################################### ORGANIZATIONS #########################################

  get organizations(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.organization).forEach((id) => {
      list.push(this.organization[id]);
    });

    return list;
  }

  get organizationsOrderedByName(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.organization).forEach((id) => {
      list.push(this.organization[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get getOrganizationById(): (id: string) => Organization | undefined {
    return (id: string) => this.organization[id];
  }

  @Mutation
  removeOrganization(id: string): void {
    delete this.organization[id];
  }

  @Mutation
  updateOrganization(payload: Organization): void {
    Vue.set(this.organization, payload.id, payload);
  }

  @Action({ rawError: true })
  async deleteOrganizationById(id: string): Promise<void> {
    await DIContainer.OrganizationRepository.deleteById(id);

    this.removeOrganization(id);

    return;
  }

  @Action({ rawError: true })
  async loadOrganizations({
    filter,
    order,
  }: {
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<Organization>> {
    const collection = await DIContainer.OrganizationRepository.getList(
      1,
      999,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateOrganization(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadOrganizationsByIds(
    ids: string[]
  ): Promise<Dictionary<Organization | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<Organization | undefined> = {};

    ids.forEach((id) => {
      if (!this.organization[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.organization[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.OrganizationRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateOrganization(item);
    });

    result = { ...result, ...items };

    return result;
  }

  @Action({ rawError: true })
  async loadOrganizationById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<Organization | undefined> {
    if (useCache && this.organization[id]) {
      return this.organization[id];
    }

    const item = await DIContainer.OrganizationRepository.getById(id);

    if (!item) {
      return;
    }

    this.updateOrganization(item);

    return item;
  }

  @Action({ rawError: true })
  async saveOrganization(organization: Organization): Promise<Organization> {
    const item = await DIContainer.OrganizationRepository.save(organization);

    this.updateOrganization(item);

    return item;
  }

  // ################################### ORGANIZATION TYPES #########################################

  get organizationTypes(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.organizationType).forEach((id) => {
      list.push(this.organizationType[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get organizationTypesDictionary(): Dictionary<DirectoryValue> {
    return { ...this.organizationType };
  }

  get getOrganizationTypeById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.organizationType[id];
  }

  @Mutation
  updateOrganizationType(payload: DirectoryValue): void {
    Vue.set(this.organizationType, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadOrganizationTypes(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.organizationType).length === 0) {
      const collection = await DIContainer.OrganizationTypeRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateOrganizationType(item);
      });
    }

    items = [];

    Object.keys(this.organizationType).forEach((key) => {
      items.push(this.organizationType[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadOrganizationTypeById(
    id: string
  ): Promise<DirectoryValue | undefined> {
    await this.loadOrganizationTypes();

    return this.getOrganizationTypeById(id);
  }

  // ################################### PERMISSION RESOURCES #########################################

  get permissionResources(): PermissionResource[] {
    const list: PermissionResource[] = [];

    Object.keys(this.permissionResource).forEach((id) => {
      list.push(this.permissionResource[id]);
    });

    return list;
  }

  get permissionResourcesByService(): Dictionary<PermissionResource[]> {
    const result: Dictionary<PermissionResource[]> = {};

    Object.keys(this.permissionResource).forEach((id) => {
      const resource = this.permissionResource[id];
      if (!result[resource.service]) {
        result[resource.service] = [];
      }

      result[resource.service].push(resource);
    });

    for (const key in result) {
      result[key].sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    }

    return result;
  }

  get permissionResourcesDictionary(): Dictionary<PermissionResource> {
    return { ...this.permissionResource };
  }

  get getPermissionResourceById(): (
    id: string
  ) => PermissionResource | undefined {
    return (id: string) => this.permissionResource[id];
  }

  @Mutation
  updatePermissionResource(payload: PermissionResource): void {
    Vue.set(this.permissionResource, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadPermissionResources(): Promise<PermissionResource[]> {
    let items: PermissionResource[];

    if (Object.keys(this.permissionResource).length === 0) {
      const collection = await DIContainer.PermissionResourceRepository.getList(
        1,
        999,
        undefined,
        [["name", FieldOrderAsc]]
      );

      items = collection.getItems();

      items.forEach((item) => {
        this.updatePermissionResource(item);
      });
    }

    items = [];

    Object.keys(this.permissionResource).forEach((key) => {
      items.push(this.permissionResource[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadPermissionResourceById(
    id: string
  ): Promise<PermissionResource | undefined> {
    await this.loadPermissionResources();

    return this.getPermissionResourceById(id);
  }

  // ################################### ROLES #########################################

  get roles(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.role).forEach((id) => {
      list.push(this.role[id]);
    });

    return list;
  }

  get rolesOrderedByName(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.role).forEach((id) => {
      list.push(this.role[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get getRoleById(): (id: string) => Role | undefined {
    return (id: string) => this.role[id];
  }

  @Mutation
  removeRole(id: string): void {
    delete this.role[id];
  }

  @Mutation
  updateRole(payload: Role): void {
    Vue.set(this.role, payload.id, payload);
  }

  @Mutation
  updateAllRolesLoaded(value: boolean): void {
    this.allRolesLoaded = value == true;
  }

  @Action({ rawError: true })
  async deleteRoleById(id: string): Promise<void> {
    await DIContainer.RoleRepository.deleteById(id);

    this.removeRole(id);

    return;
  }

  @Action({ rawError: true })
  async loadRoles(): Promise<Role[]> {
    let items: Role[];

    if (!this.allRolesLoaded) {
      const collection = await DIContainer.RoleRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateRole(item);
      });

      this.updateAllRolesLoaded(true);
    }

    items = [];

    Object.keys(this.role).forEach((key) => {
      items.push(this.role[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadRolesWithFilter({
    filter,
    order,
  }: {
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<Role>> {
    const collection = await DIContainer.RoleRepository.getList(
      1,
      999,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateRole(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadRolesByIds(ids: string[]): Promise<Dictionary<Role | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<Role | undefined> = {};

    ids.forEach((id) => {
      if (!this.role[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.role[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.RoleRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateRole(item);
    });

    result = { ...result, ...items };

    return result;
  }

  @Action({ rawError: true })
  async loadRoleById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<Role | undefined> {
    if (useCache && this.role[id]) {
      return this.role[id];
    }

    const item = await DIContainer.RoleRepository.getById(id);

    if (!item) {
      return;
    }

    this.updateRole(item);

    return item;
  }

  @Action({ rawError: true })
  async saveRole(role: Role): Promise<Role> {
    const item = await DIContainer.RoleRepository.save(role);

    this.updateRole(item);

    return item;
  }

  // ################################### SERVICES #########################################

  get services(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.service).forEach((id) => {
      list.push(this.service[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get servicesDictionary(): Dictionary<DirectoryValue> {
    return { ...this.service };
  }

  get getServiceById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.service[id];
  }

  @Mutation
  updateService(payload: DirectoryValue): void {
    Vue.set(this.service, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadServices(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.service).length === 0) {
      const collection = await DIContainer.ServiceRepository.getList(
        1,
        999,
        undefined,
        [["name", FieldOrderAsc]]
      );

      items = collection.getItems();

      items.forEach((item) => {
        this.updateService(item);
      });
    }

    items = [];

    Object.keys(this.service).forEach((key) => {
      items.push(this.service[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadServiceById(id: string): Promise<DirectoryValue | undefined> {
    await this.loadServices();

    return this.getServiceById(id);
  }

  // ################################### USER ACCOUNT #########################################

  get getUserAccountById(): (id: string) => UserAccount | undefined {
    return (id: string) => this.userAccount[id];
  }

  @Mutation
  updateUserAccount(payload: UserAccount): void {
    Vue.set(this.userAccount, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadUserAccounts({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<UserAccount>> {
    const collection = await DIContainer.UserRepository.getList(
      page,
      limit,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateUserAccount(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadUserAccountsByIds(
    ids: string[]
  ): Promise<Dictionary<UserAccount | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<UserAccount | undefined> = {};

    ids.forEach((id) => {
      if (!this.userAccount[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.userAccount[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.UserRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateUserAccount(item);
    });

    result = { ...result, ...items };

    return result;
  }

  @Action({ rawError: true })
  async loadUserAccountById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<UserAccount | undefined> {
    if (useCache && this.userAccount[id]) {
      return this.userAccount[id];
    }

    const item = await DIContainer.UserRepository.getById(id);

    if (!item) {
      return;
    }

    this.updateUserAccount(item);

    return item;
  }

  @Action({ rawError: true })
  async saveUserAccount(item: UserAccount): Promise<UserAccount> {
    item = await DIContainer.UserRepository.save(item);

    this.updateUserAccount(item);

    return item;
  }

  // ################################### USER INFO #########################################

  get getUserInfoById(): (id: string) => UserInfo | undefined {
    return (id: string) => this.userInfo[id];
  }

  @Mutation
  updateUserInfo(payload: UserInfo): void {
    Vue.set(this.userInfo, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadUserInfos({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<UserInfo>> {
    const collection = await DIContainer.UserInfoRepository.getList(
      page,
      limit,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateUserInfo(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadUserInfosByIds(
    ids: string[]
  ): Promise<Dictionary<UserInfo | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<UserInfo | undefined> = {};

    ids.forEach((id) => {
      if (!this.userInfo[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.userInfo[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.UserInfoRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateUserInfo(item);
    });

    result = { ...result, ...items };

    return result;
  }

  @Action({ rawError: true })
  async loadUserInfoById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<UserInfo | undefined> {
    if (useCache && this.userInfo[id]) {
      return this.userInfo[id];
    }

    const item = await DIContainer.UserInfoRepository.getById(id);

    if (!item) {
      return;
    }

    this.updateUserInfo(item);

    return item;
  }

  @Action({ rawError: true })
  async saveUserInfo(item: UserInfo): Promise<UserInfo> {
    item = await DIContainer.UserInfoRepository.save(item);

    this.updateUserInfo(item);

    return item;
  }

  @Action({ rawError: true })
  async loadUserInfosByMetaRoleIds(
    metaRoleIds: MetaRoleValue[]
  ): Promise<UserInfo[]> {
    const rolesCollection = await this.loadRolesWithFilter({
      filter: {
        metaRole: metaRoleIds,
      },
    });

    const roles = rolesCollection.getItems();

    const roleIds = roles.map((role) => {
      return role.id;
    });

    if (roleIds.length === 0) {
      return [];
    }

    const userRolesCollection = await this.loadUserRoles({
      page: 1,
      limit: 9999,
      filter: { role: roleIds },
    });

    const userRoles = userRolesCollection.getItems();

    const userIds = userRoles.map((userRole) => {
      return userRole.user;
    });

    const usersDictionary = await this.loadUserInfosByIds(userIds);

    const users: UserInfo[] = [];
    Object.keys(usersDictionary).forEach((id) => {
      const user = usersDictionary[id];
      if (!user) {
        return;
      }

      users.push(user);
    });

    return users;
  }

  // ################################### USER ROLE #########################################

  get getUserRoleById(): (id: string) => UserRole | undefined {
    return (id: string) => this.userRole[id];
  }

  get getUserRelationsByUserId(): (
    userId: string
  ) => UserRelations | undefined {
    return (userId: string) => this.userRelations[userId];
  }

  @Mutation
  removeUserRole(id: string): void {
    delete this.userRole[id];

    for (const key in this.userRelations) {
      if (this.userRelations[key].userRole === id) {
        this.userRelations[key].userRole = undefined;
      }
    }
  }

  @Mutation
  updateUserRole(payload: UserRole): void {
    Vue.set(this.userRole, payload.id, payload);
    this.userRelations[payload.user] = { userRole: payload.id };
  }

  @Action({ rawError: true })
  async deleteUserRoleById(id: string): Promise<void> {
    await DIContainer.UserRoleRepository.deleteById(id);

    this.removeUserRole(id);

    return;
  }

  @Action({ rawError: true })
  async loadUserRoles({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<UserRole>> {
    const collection = await DIContainer.UserRoleRepository.getList(
      page,
      limit,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateUserRole(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadUserRolesByIds(
    ids: string[]
  ): Promise<Dictionary<UserRole | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<UserRole | undefined> = {};

    ids.forEach((id) => {
      if (!this.userRole[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.userRole[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.UserRoleRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateUserRole(item);
    });

    result = { ...result, ...items };

    return result;
  }

  @Action({ rawError: true })
  async loadUserRolesByUserId({
    userIds,
    useCache = true,
  }: {
    userIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<UserRole | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<UserRole | undefined> = {};

    userIds.forEach((userId) => {
      let userRoleId;

      if (useCache && this.userRelations[userId]) {
        userRoleId = this.userRelations[userId].userRole;
      }

      if (userRoleId && this.userRole[userRoleId]) {
        result[userId] = this.userRole[userRoleId];

        return;
      }

      missing.push(userId);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.UserRoleRepository.getByUsersIds(missing);

    for (const key in items) {
      const value = items[key];

      if (!value) {
        continue;
      }

      this.updateUserRole(value);
    }

    result = { ...result, ...items };

    return result;
  }

  @Action({ rawError: true })
  async loadUserRoleById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<UserRole | undefined> {
    if (useCache && this.userRole[id]) {
      return this.userRole[id];
    }

    const item = await DIContainer.UserRoleRepository.getById(id);

    if (!item) {
      return;
    }

    this.updateUserRole(item);

    return item;
  }

  @Action({ rawError: true })
  async loadUserRoleByUserId({
    userId,
    useCache = true,
  }: {
    userId: string;
    useCache?: boolean;
  }): Promise<UserRole | undefined> {
    let userRoleId;

    if (useCache && this.userRelations[userId] != null) {
      userRoleId = this.userRelations[userId].userRole;
    }

    if (userRoleId != null && this.userRole[userRoleId] != null) {
      return this.userRole[userRoleId];
    }

    const userRole = await DIContainer.UserRoleRepository.getByUserId(userId);

    if (userRole) {
      this.updateUserRole(userRole);
    }

    return userRole;
  }

  @Action({ rawError: true })
  async saveUserRole(item: UserRole): Promise<UserRole> {
    item = await DIContainer.UserRoleRepository.save(item);

    this.updateUserRole(item);

    return item;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
