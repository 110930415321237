




import { Component, Prop, Vue } from "vue-property-decorator";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

@Component({
  components: {
    MoonLoader,
  },
})
export default class LoadingSpinner extends Vue {
  @Prop({ default: "#2199e899" })
  public color!: string;

  @Prop({ default: "128px" })
  public size!: string;
}
