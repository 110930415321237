




























import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";

import OrganizationEditForm from "./OrganizationEditForm.vue";

import AccountStore from "../store";
import OrganizationRepository from "../organization.repository";
import Organization from "../organization.model";

@Component({
  metaInfo() {
    const component = this as OrganizationEditPage;

    return {
      title:
        component.organization != null
          ? component.organization.name
          : "Organization Edit",
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    OrganizationEditForm,
  },
})
export default class OrganizationEditPage extends Vue {
  @Prop()
  public readonly organizationId!: string;

  @Prop()
  public readonly query!: Record<string, any>;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("OrganizationRepository")
  private fOrganizationRepository!: OrganizationRepository;

  private fAccountDataStore: AccountStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get organization(): Organization | undefined {
    return this.fAccountDataStore.getOrganizationById(this.organizationId);
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public getOrganizationsOrder(): Promise<string[]> {
    return this.fOrganizationRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  public onCancel(): void {
    void this.$router.push({
      name: "OrganizationsListPage",
      query: this.listQuery,
    });
  }

  public onOrganizationUpdated(): void {
    void this.$router.push({
      name: "OrganizationsListPage",
      query: this.listQuery,
    });
  }

  @Watch("organizationId", { immediate: true })
  private async _onOrganizationIdChange() {
    if (this.organizationId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fAccountDataStore.loadOrganizationById({
        id: this.organizationId,
      });

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
