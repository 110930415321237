import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import QuestionTemplateStatusRelation from "./question-template-status-relation.model";
import { ResourceCollection } from "../api/resource.collection";

export default class QuestionTemplateStatusRelationRepository extends BasicRepository<QuestionTemplateStatusRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<QuestionTemplateStatusRelation>,
    responseParser: ApiCollectionResponseParserService<QuestionTemplateStatusRelation>
  ) {
    super(
      "question_template_status_relations",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByTemplateId(
    templateId: string
  ): Promise<ResourceCollection<QuestionTemplateStatusRelation>> {
    return this.fetchList({ "template.id": templateId }, 1, 999);
  }

  public async getByTemplateIds(
    templateIds: string[]
  ): Promise<ResourceCollection<QuestionTemplateStatusRelation>> {
    return this.fetchList({ "template.id": templateIds }, 1, 999);
  }

  public async getByStatusId(
    statusId: string
  ): Promise<ResourceCollection<QuestionTemplateStatusRelation>> {
    return this.fetchList({ plushieStatusId: statusId }, 1, 999);
  }

  public async getByStatusIds(
    statusIds: string[]
  ): Promise<ResourceCollection<QuestionTemplateStatusRelation>> {
    return this.fetchList({ plushieStatusId: statusIds });
  }

  protected prepareData(
    item: QuestionTemplateStatusRelation
  ): Record<string, string> {
    return {
      id: item.id,
      template: item.template,
      plushieStatusId: item.plushieStatus,
    };
  }
}
