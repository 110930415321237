






























import Vue from "vue";
import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";

import Factory from "@/modules/factory/factory.model";
import Product from "@/modules/plushie/product.model";
import { Dictionary } from "@/lib/Dictionary.type";
import IdGenerator from "@/lib/services/id-generator";

import ProjectionStats from "../../projection-stats.model";
import Projection from "../../projection.model";

interface TableRow {
  [key: string]:
    | number
    | string
    | {
        id: number;
        name: string;
      };

  product: {
    id: number;
    name: string;
  };
}

@Component
export default class ProjectionsTable extends Vue {
  @Prop({ required: true })
  public readonly factoriesList!: Factory[];

  @Prop({ default: false })
  public readonly isEditable!: boolean;

  @Prop({ required: true })
  public readonly products!: Product[];

  @Prop({ required: true })
  public readonly projectionDictionary!: Dictionary<Projection>;

  @Prop({ default: () => ({}) })
  public readonly projectionStatsByProductId!: Dictionary<ProjectionStats>;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fTableData: TableRow[] = [];

  get tableColumns(): string[] {
    const tableColumns = ["product.name"];

    this.factoriesList.forEach((factory) => {
      tableColumns.push(factory.id);
    });

    tableColumns.push(
      ...[
        "projectionTotal",
        "capacity",
        "capacityRemaining",
        "soldTotal",
        "salesWeeks",
      ]
    );

    return tableColumns;
  }

  get tableData(): TableRow[] {
    return this.fTableData;
  }

  set tableData(tableData: TableRow[]) {
    this.fTableData = tableData;
  }

  get tableOptions(): TableOptions {
    const headings: Dictionary<string> = {
      "product.name": "Product",
      projectionTotal: "WeekTo",
      capacity: "Capac",
      capacityRemaining: "ReCap",
      soldTotal: "7Days",
      salesWeeks: "SalesWk",
    };
    const editableColumns: string[] = [];

    this.factoriesList.forEach((factory) => {
      headings[factory.id] = factory.name;
      editableColumns.push(factory.id);
    });

    return {
      columnsClasses: {
        "product.name": "_product-name",
      },
      editableColumns,
      filterable: false,
      headings,
      orderBy: {
        column: "product.name",
        ascending: true,
      },
      perPage: 999,
      perPageValues: [],
    };
  }

  public clearTableData(): void {
    if (this.isEditable) {
      this.calculateTableData(true);
    }
  }

  public getProjectionsData(): Projection[] {
    const projections: Projection[] = [];

    this.tableData.forEach((tableRow) => {
      this.factoriesList.forEach((factory) => {
        const projection = this.projectionDictionary[
          `${factory.id}-${tableRow.product.id}`
        ];

        projections.push(
          new Projection(
            projection ? projection.id : this.fIdGenerator.getId(),
            factory.id,
            tableRow.product.id,
            tableRow[factory.id] ? (tableRow[factory.id] as number) : 0
          )
        );
      });
    });

    return projections;
  }

  public onCellInputHandler(row: TableRow): void {
    this.updateRow(row);
  }

  protected created(): void {
    this.calculateTableData();
  }

  private calculateTableData(shouldClear = false): void {
    const tableData: TableRow[] = [];

    this.products.forEach((product) => {
      const productProjectionStats: ProjectionStats = this
        .projectionStatsByProductId[product.id];

      const row: TableRow = {
        id: product.id,
        product: {
          id: Number.parseInt(product.id, 10),
          name: product.name,
        },
        projectionTotal:
          productProjectionStats && productProjectionStats.projectionTotal
            ? productProjectionStats.projectionTotal
            : "",
        capacity:
          productProjectionStats && productProjectionStats.capacity
            ? productProjectionStats.capacity
            : "",
        capacityRemaining:
          productProjectionStats && productProjectionStats.capacityRemaining
            ? productProjectionStats.capacityRemaining
            : "",
        soldTotal:
          productProjectionStats && productProjectionStats.soldTotal
            ? productProjectionStats.soldTotal
            : "",
        salesWeeks:
          productProjectionStats && productProjectionStats.salesWeeks
            ? productProjectionStats.salesWeeks
            : "",
      };

      this.factoriesList.forEach((factory) => {
        const projection = this.projectionDictionary[
          `${factory.id}-${product.id}`
        ];

        if (!projection || shouldClear) {
          row[factory.id] = "";
          return;
        }

        row[factory.id] = projection.value === 0 ? "" : projection.value;
      });

      tableData.push(row);
    });

    this.fTableData = tableData;
  }

  private updateRow(row: TableRow): void {
    const index = this.tableData.findIndex((item) => item.id === row.id);
    if (index === -1) {
      return;
    }

    this.tableData.splice(index, 1, row);
  }

  @Watch("projectionStatsByProductId", { deep: true })
  private _onProjectionStatsByProductIdChange(): void {
    this.calculateTableData();
  }

  @Watch("factoriesList", { deep: true })
  private _onFactoriesListChange(): void {
    this.calculateTableData();
  }

  @Watch("products", { deep: true })
  private _onProductsChange(): void {
    this.calculateTableData();
  }

  @Watch("projectionDictionary", { deep: true })
  private _onProjectionDictionaryChange(): void {
    this.calculateTableData();
  }
}
