







































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import UserFactoryRelationsEditForm from "./UserFactoryRelationsEditForm.vue";
import UserFactoryRelationsList from "./UserFactoryRelationsList.vue";

import FactoryStore from "../../store";
import FactoryRepository from "../../factory.repository";
import Factory from "../../factory.model";

@Component({
  metaInfo() {
    const component = this as UserFactoryRelationPage;
    const factoryName = component.factory ? component.factory.name : "Factory";

    return {
      title: `Manage ${factoryName} Users`,
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    UserFactoryRelationsEditForm,
    UserFactoryRelationsList,
  },
})
export default class UserFactoryRelationPage extends Vue {
  @Prop()
  public readonly factoryId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("FactoryRepository")
  private fFactoryRepository!: FactoryRepository;

  private fFactoryDataStore: FactoryStore;

  private fIsLoading = false;

  get canFactoryUsersManage(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_USERS_MANAGE);
  }

  get canFactoryUsersView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_USERS_VIEW);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get factory(): Factory | undefined {
    return this.fFactoryDataStore.getFactoryById(this.factoryId);
  }

  get factoryName(): string {
    return this.factory?.name || "Factory";
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public getFactoriesOrder(): Promise<string[]> {
    return this.fFactoryRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  private async getFactoryData(factoryId: string): Promise<void> {
    await this.fFactoryDataStore.loadFactoryById({
      id: factoryId,
      useCache: false,
    });
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (this.factoryId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getFactoryData(this.factoryId);

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
