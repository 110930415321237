import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiService from "@/modules/api/api.service";

import BlockingPlushie from "./blocking-plushie.model";

export default class BlockingPlushieRepository {
  private fResourceName = "factory_restricted_mode/blocking_plushies";

  constructor(
    protected api: ApiService,
    protected objectBuilder: ObjectBuilderInterface<BlockingPlushie>
  ) {}

  public async getByFactoryId(
    factoryId: string,
    limit?: number
  ): Promise<BlockingPlushie[]> {
    const params: Record<string, string | number | string[] | number[]> = {
      factoryId: factoryId,
    };

    if (limit) {
      params["itemsPerPage"] = limit;
    }

    const response = await this.api.get(this.fResourceName, params);

    const items: string[] = response.data.result;

    const result: BlockingPlushie[] = [];

    items.forEach((item) => {
      result.push(
        this.objectBuilder.buildFromJSON({
          factory: factoryId,
          plushie: item,
        })
      );
    });

    return result;
  }
}
