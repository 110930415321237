import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import RemotePrintingCredentials from "./remote-printing-credentials.model";
import RemotePrintingCredentialsUpdate from "./remote-printing-credentials-update.interface";

export default class RemotePrintingCredentialsRepository extends BasicRepository<RemotePrintingCredentials> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<RemotePrintingCredentials>,
    responseParser: ApiCollectionResponseParserService<RemotePrintingCredentials>
  ) {
    super("barcode_printing_credentials", api, objectBuilder, responseParser);
  }

  public async updateCredentials(
    item: RemotePrintingCredentialsUpdate
  ): Promise<RemotePrintingCredentials> {
    const data: Dictionary<string> = {};

    data.token = item.token;

    const response = await this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(
    item: RemotePrintingCredentials
  ): Record<string, string> {
    const data: Dictionary<string> = {
      factoryId: item.id,
      token: item.token,
    };

    return data;
  }
}
