import type { Route } from "vue-router/types/router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import HolidayPeriodsListPage from "./components/HolidayPeriodsListPage.vue";
import HolidayPeriodEditPage from "./components/HolidayPeriodEditPage.vue";
import CutoffReportPage from "./components/CutoffReportPage.vue";

export default [
  {
    path: "/holiday_periods",
    name: "HolidayPeriodsListPage",
    component: HolidayPeriodsListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/holiday_periods/edit",
    name: "HolidayPeriodEditPage",
    component: HolidayPeriodEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["periodId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/reports/holiday_cutoff",
    name: "CutoffReportPage",
    component: CutoffReportPage,
    meta: {
      auth: true,
    },
  },
];
