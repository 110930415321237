



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";

import BulkShipmentStore from "../store";
import BulkShipment from "../bulk-shipment.model";
import BulkShipmentItem from "../bulk-shipment-item.model";

@Component({
  components: {},
})
export default class BulkShipmentInfo extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fIsLoading = true;

  private fBulkShipmentDataStore: BulkShipmentStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get shipment(): BulkShipment | undefined {
    if (!this.shipmentItem) {
      return undefined;
    }

    return this.fBulkShipmentDataStore.getBulkShipmentById(
      this.shipmentItem.shipment
    );
  }

  get shipmentItem(): BulkShipmentItem | undefined {
    return this.fBulkShipmentDataStore.getPublishedBulkShipmentItemByPlushieId(
      this.plushieId
    );
  }

  constructor() {
    super();

    this.fBulkShipmentDataStore = getModule(BulkShipmentStore, dataStore);
  }

  private async getPlushieData(plushieId: string): Promise<void> {
    const shipmentItem = await this.fBulkShipmentDataStore.loadPublishedBulkShipmentItemByPlushieId(
      { plushieId }
    );

    if (!shipmentItem) {
      return;
    }

    await this.fBulkShipmentDataStore.loaBulkShipmentById({
      id: shipmentItem.shipment,
    });
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange(): Promise<void> {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getPlushieData(this.plushieId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
