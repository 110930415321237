















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class TrackingNumber extends Vue {
  @Prop({ default: "" })
  public readonly trackingNumber!: string;

  @Prop({ default: "" })
  public readonly createdAt!: string;
}
