import { render, staticRenderFns } from "./MassActionsCard.vue?vue&type=template&id=ffb62862&scoped=true&"
import script from "./MassActionsCard.vue?vue&type=script&lang=ts&"
export * from "./MassActionsCard.vue?vue&type=script&lang=ts&"
import style0 from "./MassActionsCard.vue?vue&type=style&index=0&id=ffb62862&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffb62862",
  null
  
)

export default component.exports