import { AxiosPromise } from "axios";

import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Config from "./config.model";

export default class ConfigRepository extends BasicRepository<Config> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Config>,
    responseParser: ApiCollectionResponseParserService<Config>
  ) {
    super(
      "factory_restricted_mode/configs",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async save(item: Config): Promise<Config> {
    const data = this.prepareData(item);

    const request: AxiosPromise = this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    const response = await request;

    item = this.objectBuilder.buildFromJSON(response.data);

    return item;
  }

  protected prepareData(item: Config): Record<string, string | boolean> {
    const data: Record<string, string | boolean> = {
      id: item.id,
      enabled: item.isEnabled,
    };

    return data;
  }
}
