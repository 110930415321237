import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import { ResourceCollection } from "@/modules/api/resource.collection";
import dataStore from "@/store";

import ExcludedProduct from "../excluded-product.model";
import Config from "../config.model";
import Alert from "../alert.model";
import RestrictedModeStatus from "../restricted-mode-status.model";

const name = "FactoryRestrictedModeStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    factoryRestrictedModeAlert: {},
    factoryRestrictedModeConfig: {},
    factoryRestrictedModeStatus: {},
    excludedProduct: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class FactoryRestrictedModeStore extends VuexModule {
  factoryRestrictedModeAlert?: Alert;
  factoryRestrictedModeConfig: Dictionary<Config> = {};
  factoryRestrictedModeStatus: Dictionary<RestrictedModeStatus> = {};
  excludedProduct: Dictionary<ExcludedProduct> = {};

  // ################################### RESTRICTED MODE STATUSES #########################################
  get getFactoryRestrictedModeStatusByFactoryId(): (
    factoryId: string
  ) => RestrictedModeStatus | undefined {
    return (factoryId: string) => {
      return this.factoryRestrictedModeStatus[factoryId];
    };
  }

  @Mutation
  updateFactoryRestrictedModeStatus(payload: RestrictedModeStatus): void {
    Vue.set(this.factoryRestrictedModeStatus, payload.factory, payload);
  }

  @Action({ rawError: true })
  async loadFactoryRestrictedModeStatusByFactoryId(
    factoryId: string
  ): Promise<RestrictedModeStatus | undefined> {
    if (this.factoryRestrictedModeStatus[factoryId]) {
      return this.factoryRestrictedModeStatus[factoryId];
    }

    const item = await DIContainer.FactoryRestrictedModeStatusRepository.getByFactoryId(
      factoryId
    );

    if (!item) {
      return undefined;
    }

    this.updateFactoryRestrictedModeStatus(item);

    return item;
  }

  @Action({ rawError: true })
  async loadFactoryRestrictedModeStatusesByFactoryIds({
    factoryIds,
    useCache = true,
  }: {
    factoryIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<RestrictedModeStatus>> {
    const missing: string[] = [];
    const result: Dictionary<RestrictedModeStatus> = {};

    factoryIds.forEach((factoryId) => {
      if (useCache && this.factoryRestrictedModeStatus[factoryId]) {
        result[factoryId] = this.factoryRestrictedModeStatus[factoryId];
        return;
      }

      missing.push(factoryId);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.FactoryRestrictedModeStatusRepository.getByFactoryIds(
      missing
    );

    items.forEach((item) => {
      this.updateFactoryRestrictedModeStatus(item);

      result[item.factory] = item;
    });

    return result;
  }

  // ################################### ALERTS #########################################
  get factoryRestrictedModeAlerts(): Alert | undefined {
    return this.factoryRestrictedModeAlert;
  }

  @Mutation
  updateFactoryRestrictedModeAlerts(payload: Alert): void {
    Vue.set(this, "factoryRestrictedModeAlert", payload);
  }

  @Action({ rawError: true })
  async loadFactoryInvoiceAlerts(): Promise<Alert> {
    if (this.factoryRestrictedModeAlert) {
      return this.factoryRestrictedModeAlert;
    }

    const item = await DIContainer.FactoryRestrictedModeAlertRepository.get();

    this.updateFactoryRestrictedModeAlerts(item);

    return item;
  }

  // ################################### CONFIGS #########################################
  get getFactoryRestrictedModeConfigById(): (id: string) => Config | undefined {
    return (id: string) => this.factoryRestrictedModeConfig[id];
  }

  get factoryRestrictedModeConfigs(): Dictionary<Config> {
    return { ...this.factoryRestrictedModeConfig };
  }

  @Mutation
  updateFactoryRestrictedModeConfig(payload: Config): void {
    Vue.set(this.factoryRestrictedModeConfig, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadFactoryRestrictedModeConfigById(
    id: string
  ): Promise<Config | undefined> {
    if (this.factoryRestrictedModeConfig[id]) {
      return this.factoryRestrictedModeConfig[id];
    }

    const item = await DIContainer.FactoryRestrictedModeConfigRepository.getById(
      id
    );

    if (!item) {
      return undefined;
    }

    this.updateFactoryRestrictedModeConfig(item);

    return item;
  }

  @Action({ rawError: true })
  async loadFactoryRestrictedModeConfigs(): Promise<
    ResourceCollection<Config>
  > {
    const collection = await DIContainer.FactoryRestrictedModeConfigRepository.getList();

    collection.getItems().forEach((item) => {
      this.updateFactoryRestrictedModeConfig(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async saveFactoryRestrictedModeConfig(config: Config): Promise<Config> {
    const item = await DIContainer.FactoryRestrictedModeConfigRepository.save(
      config
    );

    this.updateFactoryRestrictedModeConfig(item);

    return item;
  }

  // ################################### EXCLUDED PRODUCTS #########################################
  get getExcludedProductById(): (id: string) => ExcludedProduct | undefined {
    return (id: string) => this.excludedProduct[id];
  }

  get excludedProductsList(): ExcludedProduct[] {
    const list: ExcludedProduct[] = [];

    Object.keys(this.excludedProduct).forEach((id) => {
      list.push(this.excludedProduct[id]);
    });

    return list;
  }

  get getExcludedProducts(): Dictionary<ExcludedProduct> {
    return { ...this.excludedProduct };
  }

  @Mutation
  removeExcludedProduct(id: string): void {
    delete this.excludedProduct[id];
  }

  @Mutation
  updateExcludedProduct(payload: ExcludedProduct): void {
    Vue.set(this.excludedProduct, payload.id, payload);
  }

  @Action({ rawError: true })
  async deleteExcludedProduct(item: ExcludedProduct): Promise<void> {
    await DIContainer.ExcludedProductRepository.deleteById(item.id);

    this.removeExcludedProduct(item.id);

    return;
  }

  @Action({ rawError: true })
  async loadExcludedProducts(): Promise<ExcludedProduct[]> {
    let items: ExcludedProduct[];

    if (Object.keys(this.excludedProduct).length === 0) {
      const collection = await DIContainer.ExcludedProductRepository.getList(
        1,
        999
      );

      items = collection.getItems();

      items.forEach((item) => {
        this.updateExcludedProduct(item);
      });
    }

    items = [];

    Object.keys(this.excludedProduct).forEach((key) => {
      items.push(this.excludedProduct[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async saveExcludedProduct(
    excludedProduct: ExcludedProduct
  ): Promise<ExcludedProduct> {
    const item = await DIContainer.ExcludedProductRepository.save(
      excludedProduct
    );

    this.updateExcludedProduct(item);

    return item;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
