import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BulkProject from "./bulk-project.model";

export default class BulkProjectFactory
  implements ObjectBuilderInterface<BulkProject> {
  public buildFromJSON(data: { [key: string]: any }): BulkProject {
    const value = new BulkProject(
      data.id,
      new Date(data.createdAt),
      data.product,
      data.imageStorageItemId,
      +data.quantity,
      +data.size,
      data.description,
      +data.country,
      data.customerId,
      +data.incrementalId,
      data.status,
      data.name,
      data.phone,
      data.deadlineDate ? new Date(data.deadlineDate) : undefined,
      data.clientType,
      data.responsibleUserId
    );

    value.isNew = false;

    return value;
  }
}
