



















































import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";

@Component({
  components: {
    FormErrors,
    FormField,
    SubmitButton,
  },
})
export default class EditableWithSelect extends mixins(GeneralFormMixin) {
  @Prop({ default: "" })
  public readonly value!: string;

  @Prop({ default: () => [] })
  public readonly options!: { value: string; name: string }[];

  @Prop({ default: false })
  public readonly required!: boolean;

  @Prop({ required: true })
  public readonly saveCallback!: (value?: string | number) => Promise<void>;

  public selectedValue?: string;

  private fIsInEditMode = false;

  get isInEditMode(): boolean {
    return this.fIsInEditMode;
  }

  get valueText(): string {
    if (!this.value) {
      return "";
    }

    const option = this.options.find((item) => item.value === this.value);

    if (!option) {
      return "";
    }

    return option.name;
  }

  public switchToEditMode(): void {
    this.fIsInEditMode = true;
    this.submitErrors = [];

    this.$nextTick(() => {
      this.selectedValue = this.value;
    });
  }

  public async onCancelClick(): Promise<void> {
    this.fIsInEditMode = false;
    this.submitErrors = [];

    await this.$validator.reset();

    this.selectedValue = undefined;
  }

  protected data(): Record<string, unknown> {
    return {
      selectedValue: this.selectedValue,
    };
  }

  protected async performSubmit(): Promise<void> {
    await this.saveCallback(this.selectedValue);

    this.fIsInEditMode = false;

    await this.$validator.reset();

    this.selectedValue = undefined;
  }
}
