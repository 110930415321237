import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import BulkShipment from "../bulk-shipment.model";
import BulkShipmentItem from "../bulk-shipment-item.model";
import BulkShipmentUpdate from "../bulk-shipment-update.interface";

const name = "BulkShipmentStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    status: {},
    bulkShipment: {},
    bulkShipmentItem: {},
    shipmentItems: {},
    plushieItem: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class BulkShipmentStore extends VuexModule {
  status: Dictionary<DirectoryValue> = {};
  bulkShipment: Dictionary<BulkShipment> = {};
  bulkShipmentItem: Dictionary<BulkShipmentItem> = {};

  shipmentItems: Dictionary<string[]> = {};
  plushiePublishedItem: Dictionary<string> = {};

  // ################################### BULK SHIPMENT STATUS #########################################

  get getStatusById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.status[id];
  }

  get getStatusList(): DirectoryValue[] {
    const list = Object.values(this.status);

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  @Mutation
  updateStatus(payload: DirectoryValue): void {
    Vue.set(this.status, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadStatuses(): Promise<DirectoryValue[]> {
    if (Object.keys(this.status).length === 0) {
      const collection = await DIContainer.BulkShipmentStatusRepository.getList();

      collection.getItems().forEach((item) => {
        this.updateStatus(item);
      });
    }

    return Object.values(this.status);
  }

  // ################################### BULK SHIPMENTS #########################################

  get getBulkShipmentById(): (id: string) => BulkShipment | undefined {
    return (id: string) => {
      return this.bulkShipment[id];
    };
  }

  @Mutation
  updateBulkShipment(payload: BulkShipment): void {
    Vue.set(this.bulkShipment, payload.id, payload);
  }

  @Action({ rawError: true })
  async saveBulkShipment(bulkShipment: BulkShipment): Promise<BulkShipment> {
    const item = await DIContainer.BulkShipmentRepository.save(bulkShipment);

    this.updateBulkShipment(item);

    return item;
  }

  @Action({ rawError: true })
  async editBulkShipment(
    bulkShipmentUpdate: BulkShipmentUpdate
  ): Promise<BulkShipment> {
    const item = await DIContainer.BulkShipmentRepository.updateBulkShipment(
      bulkShipmentUpdate
    );

    this.updateBulkShipment(item);

    return item;
  }

  @Action({ rawError: true })
  async loadBulkShipments({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<BulkShipment>> {
    const collection = await DIContainer.BulkShipmentRepository.getList(
      page,
      limit,
      filter,
      order
    );

    const items = collection.getItems();

    items.forEach((item) => {
      this.updateBulkShipment(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loaBulkShipmentById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<BulkShipment | undefined> {
    if (useCache && this.bulkShipment[id]) {
      return this.bulkShipment[id];
    }

    const item = await DIContainer.BulkShipmentRepository.getById(id);

    if (!item) {
      return undefined;
    }

    this.updateBulkShipment(item);

    return item;
  }

  @Action({ rawError: true })
  async publishBulkShipment(bulkShipment: BulkShipment): Promise<BulkShipment> {
    const item = await DIContainer.BulkShipmentRepository.publishBulkShipment(
      bulkShipment
    );

    this.updateBulkShipment(item);

    return item;
  }

  // ################################### BULK SHIPMENT ITEMS #########################################

  get getBulkShipmentItemsByShipmentId(): (
    shipmentId: string
  ) => BulkShipmentItem[] {
    return (shipmentId: string) => {
      const itemIds = this.shipmentItems[shipmentId];

      if (!itemIds) {
        return [];
      }

      const result: BulkShipmentItem[] = [];

      itemIds.forEach((id) => {
        const item = this.bulkShipmentItem[id];

        if (!item) {
          return;
        }

        result.push(item);
      });

      return result;
    };
  }

  get getPublishedBulkShipmentItemByPlushieId(): (
    plushieId: string
  ) => BulkShipmentItem | undefined {
    return (plushieId: string) => {
      const itemId = this.plushiePublishedItem[plushieId];

      if (!itemId) {
        return undefined;
      }

      return this.bulkShipmentItem[itemId];
    };
  }

  @Mutation
  updateShipmentItems({
    shipmentId,
    items,
  }: {
    shipmentId: string;
    items: BulkShipmentItem[];
  }): void {
    items.forEach((item) => {
      if (item.shipment !== shipmentId) {
        throw new Error("All items should belong to the specified shipment!");
      }
    });

    const itemIds: string[] = [];
    items.forEach((item) => {
      itemIds.push(item.id);
      Vue.set(this.bulkShipmentItem, item.id, item);
    });

    Vue.set(this.shipmentItems, shipmentId, itemIds);
  }

  @Mutation
  updatePlushiePublishedBulkShipmentItem(payload: BulkShipmentItem): void {
    Vue.set(this.bulkShipmentItem, payload.id, payload);
    Vue.set(this.plushiePublishedItem, payload.plushie, payload.id);
  }

  @Action({ rawError: true })
  async loadBulkShipmentsItemsByShipmentsIds({
    shipmentIds,
    useCache = true,
  }: {
    shipmentIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string[]>> {
    const missing: string[] = [];
    const result: Dictionary<string[]> = {};

    shipmentIds.forEach((shipmentId) => {
      if (useCache && this.shipmentItems[shipmentId]) {
        result[shipmentId] = this.shipmentItems[shipmentId];
        return;
      }

      missing.push(shipmentId);
    });

    if (!missing.length) {
      return result;
    }

    const collection = await DIContainer.BulkShipmentItemRepository.getByShipmentIds(
      missing
    );

    const items = collection.getItems();

    const shipmentItems: Dictionary<BulkShipmentItem[]> = {};

    items.forEach((item) => {
      if (!shipmentItems[item.shipment]) {
        shipmentItems[item.shipment] = [];
      }

      shipmentItems[item.shipment].push(item);
    });

    for (const shipmentId in shipmentItems) {
      const items = shipmentItems[shipmentId];

      if (!items) {
        continue;
      }

      result[shipmentId] = items.map((item) => item.id);

      this.updateShipmentItems({ shipmentId, items });
    }

    return result;
  }

  @Action({ rawError: true })
  async loadBulkShipmentItemsByShipmentId({
    shipmentId,
    useCache = true,
  }: {
    shipmentId: string;
    useCache?: boolean;
  }): Promise<BulkShipmentItem[]> {
    const items: BulkShipmentItem[] = [];

    if (useCache && this.shipmentItems[shipmentId]) {
      const shipmentItemIds = this.shipmentItems[shipmentId];

      shipmentItemIds.forEach((id) => {
        if (!this.bulkShipmentItem[id]) {
          return;
        }

        items.push(this.bulkShipmentItem[id]);
      });

      return items;
    }

    const collection = await DIContainer.BulkShipmentItemRepository.getByShipmentIds(
      [shipmentId]
    );

    collection.getItems().forEach((item) => {
      items.push(item);
    });

    this.updateShipmentItems({ shipmentId, items });

    return items;
  }

  @Action({ rawError: true })
  async loadPublishedBulkShipmentItemByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<BulkShipmentItem | undefined> {
    if (useCache && this.plushiePublishedItem[plushieId]) {
      return this.getPublishedBulkShipmentItemByPlushieId(plushieId);
    }

    const item = await DIContainer.BulkShipmentItemRepository.getPublishedByPlushieId(
      plushieId
    );

    if (!item) {
      return undefined;
    }

    this.updatePlushiePublishedBulkShipmentItem(item);

    return item;
  }

  @Action({ rawError: true })
  async saveBulkShipmentItems(
    bulkShipmentItems: BulkShipmentItem[]
  ): Promise<BulkShipmentItem[]> {
    const collection = await DIContainer.BulkShipmentItemRepository.saveItems(
      bulkShipmentItems
    );

    const items = collection.getItems();

    if (items.length < 1) {
      return [];
    }

    const shipmentId = items[0].shipment;

    this.updateShipmentItems({ shipmentId, items });

    return items;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
