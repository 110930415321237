import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Role from "./role.model";

export default class RoleFactory implements ObjectBuilderInterface<Role> {
  public buildFromJSON(data: { [key: string]: any }): Role {
    const value = new Role(data.id, data.name, data.metaRole, data.isDeletable);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
