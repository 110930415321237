import { AxiosPromise } from "axios";
import chunk from "lodash.chunk";

import { Dictionary } from "@/lib/Dictionary.type";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";

import IBasicRepository from "./basic.repositor.interface";
import { MutableValue } from "./mutable-value.interface";
import ObjectBuilderInterface from "./object-builder.interface";
import { QueryOrderParameter } from "./query-order-parameter";
import { ResourceCollection } from "./resource.collection";
import ApiCollectionResponseParserService from "./api-collection-response-parser.service";
import ApiService from "./api.service";

export default class BasicRepository<T extends MutableValue>
  implements IBasicRepository<T> {
  constructor(
    protected resourceName: string,
    protected api: ApiService,
    protected objectBuilder: ObjectBuilderInterface<T>,
    protected responseParser: ApiCollectionResponseParserService<T>
  ) {}

  public async delete(item: T): Promise<void> {
    await this.api.delete(this.resourceName + "/" + item.id);

    return;
  }

  public async deleteById(id: string): Promise<void> {
    await this.api.delete(this.resourceName + "/" + id);

    return;
  }

  public async getById(id: string, useCache = true): Promise<T | undefined> {
    if (!id) {
      return undefined;
    }

    const response = await this.api.get(
      this.resourceName + "/" + id,
      undefined,
      undefined,
      undefined,
      useCache
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async getByIds(
    ids: string[]
  ): Promise<{ [key: string]: T | undefined }> {
    const result: { [key: string]: T | undefined } = {};

    if (!ids.length) {
      return result;
    }

    let items: T[] = [];

    const chunks = chunk(ids, ApiService.MAX_GUIDS_PER_GET_REQUEST);
    const requestPromises = [];

    for (let index = 0; index < chunks.length; index++) {
      const ids = chunks[index];

      requestPromises.push(this.fetchList({ id: ids }, 1, ids.length));
    }

    const collections = await Promise.all(requestPromises);

    collections.map((collection) => {
      items = items.concat(collection.getItems());
    });

    items.forEach((item) => {
      result[item.id] = item;
    });

    return result;
  }

  public getList(
    page = 1,
    limit = 20,
    filter?: Dictionary<ApiFilterValue>,
    order?: QueryOrderParameter,
    useCache = true
  ): Promise<ResourceCollection<T>> {
    return this.fetchList(filter, page, limit, order, useCache);
  }

  public async getListOfIds(
    order?: QueryOrderParameter,
    filter?: Dictionary<ApiFilterValue>
  ): Promise<string[]> {
    const resourceName = this.resourceName;

    const params: Record<string, string | number | string[] | number[]> = {
      page: 1,
      itemsPerPage: 99999,
      properties: ["id"],
    };

    if (filter) {
      Object.assign(params, filter);
    }

    let orderedParams: QueryOrderParameter = [];

    if (order != null) {
      orderedParams = order.map((item) => [`order[${item[0]}]`, item[1]]);
    }

    const response = await this.api.get(resourceName, params, orderedParams, {
      headers: {
        Accept: "application/json",
      },
    });

    const result: string[] = [];

    response.data.forEach((item: any) => {
      result.push(item.id);
    });

    return result;
  }

  public async save(item: T): Promise<T> {
    let request: AxiosPromise;

    const data = this.prepareData(item);

    if (item.isNew) {
      request = this.api.post(this.resourceName, data);
    } else {
      request = this.api.put(this.resourceName + "/" + item.id, data);
    }

    const response = await request;

    item = this.objectBuilder.buildFromJSON(response.data);

    return item;
  }

  public async saveKeepalive(item: T): Promise<T> {
    const data = this.prepareData(item);

    const response = await this.api.postKeepalive(this.resourceName, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: T): Dictionary<string | number | boolean> {
    return {
      id: item.id,
    };
  }

  protected async fetchList(
    query: Record<string, unknown> = {},
    page = 1,
    limit?: number,
    order?: QueryOrderParameter,
    useCache = true
  ): Promise<ResourceCollection<T>> {
    const resourceName = this.resourceName;

    const params: Record<string, string | number | string[] | number[]> = {
      page,
    };

    if (limit != null) {
      params.itemsPerPage = limit;
    }

    Object.assign(params, query);

    let orderedParams: QueryOrderParameter = [];

    if (order != null) {
      orderedParams = order.map((item) => [`order[${item[0]}]`, item[1]]);
    }

    const response = await this.api.get(
      resourceName,
      params,
      orderedParams,
      undefined,
      useCache
    );
    return this.responseParser.parse(response.data, this.objectBuilder);
  }
}
