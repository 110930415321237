
















import { Component, Prop, Vue } from "vue-property-decorator";
import type { RawLocation } from "vue-router/types/router";

import Role from "@/modules/account/role.model";
import { Dictionary } from "@/lib/Dictionary.type";

import RoleEditForm from "./RoleEditForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Add Role",
    };
  },
  components: {
    RoleEditForm,
  },
})
export default class RoleAddPage extends Vue {
  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Prop()
  public readonly query!: any;

  public onCancel(): void {
    void this.$router.push({
      name: "UserRolesListPage",
      query: this.listQuery,
    });
  }

  public onSuccess(role: Role): void {
    const editPageUrl = this.generateItemLink(
      role.id,
      this.query,
      this.listQuery
    );

    void this.$router.push(editPageUrl);
  }

  private generateItemLink(
    id: string,
    query: Record<string, any>,
    listQuery?: Record<string, any>
  ): RawLocation {
    return {
      name: "UserRoleEditPage",
      query: {
        id,
        query: JSON.stringify(query),
        listQuery: JSON.stringify(listQuery),
      },
    };
  }
}
