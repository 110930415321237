<template>
  <div class="page-typography">
    <h1 class="_page-title">Some page title</h1>

    <div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut dolorum
        error eveniet minus neque praesentium quae quam! Dolor ea, laboriosam.
        Ab adipisci harum id itaque numquam obcaecati, officiis porro qui quis
        recusandae repudiandae similique tenetur, ullam unde voluptatem.
        Cupiditate ducimus eius error eveniet, explicabo fuga fugit inventore
        iusto nobis obcaecati odio, optio placeat rem? Beatae ex explicabo fugit
        nobis omnis quo sequi temporibus voluptatibus? Ab, aperiam architecto
        asperiores at blanditiis consectetur consequuntur cupiditate delectus
        distinctio dolorem dolores earum eligendi expedita fuga fugit inventore
        ipsa ipsam maiores molestias nulla odio officia optio placeat
        praesentium quia quidem reiciendis sequi soluta unde vel vitae voluptas
        voluptates voluptatibus! Dolorem necessitatibus omnis quas reiciendis
        rem repudiandae sit! Dolores fugit nihil nostrum obcaecati quam
        quibusdam quo repudiandae tenetur unde voluptate.
      </p>

      <h2>Secondary title</h2>

      <p>
        Cupiditate ducimus eius error eveniet, explicabo fuga fugit inventore
        iusto nobis obcaecati odio, optio placeat rem? Beatae ex explicabo fugit
        nobis omnis quo sequi temporibus voluptatibus? Ab, aperiam architecto
        asperiores at blanditiis consectetur consequuntur cupiditate delectus
        distinctio dolorem dolores earum eligendi expedita fuga fugit inventore
        ipsa ipsam maiores molestias nulla odio officia optio placeat
        praesentium quia quidem reiciendis sequi soluta unde vel vitae voluptas
        voluptates voluptatibus! Dolorem necessitatibus omnis quas reiciendis
        rem repudiandae sit! Dolores fugit nihil nostrum obcaecati quam
        quibusdam quo repudiandae tenetur unde voluptate.
      </p>

      <ul>
        <li>Lorem ipsum dolor sit.</li>
        <li>
          Lorem ipsum dolor sit amet.

          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
            <li>Lorem ipsum.</li>
            <li>Lorem ipsum dolor sit.</li>
          </ol>
        </li>
        <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
        <li>Lorem ipsum.</li>
        <li>Lorem ipsum dolor sit.</li>
      </ul>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad
        aspernatur culpa earum ex ipsum laboriosam magnam qui quia quidem!
      </p>

      <ol>
        <li>Lorem ipsum dolor sit.</li>
        <li>
          Lorem ipsum dolor sit amet.

          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
            <li>Lorem ipsum.</li>
          </ol>
        </li>
        <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
        <li>
          Lorem ipsum.
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing.</li>
            <li>Lorem ipsum.</li>
            <li>Lorem ipsum dolor sit.</li>
          </ul>
        </li>
        <li>Lorem ipsum dolor sit.</li>
      </ol>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad
        aspernatur culpa earum ex ipsum laboriosam magnam qui quia quidem!
      </p>

      <p>Standart text with <a href="#">link</a>.</p>
      <p>
        <small>This line of text is meant to be treated as fine print.</small>
      </p>
      <p>
        The following snippet of text is <strong>rendered as bold text</strong>.
      </p>
      <p>
        The following snippet of text is <em>rendered as italicized text</em>.
      </p>

      <dl>
        <dt>Time</dt>
        <dd>
          The indefinite continued progress of existence and events in the past,
          present, and future regarded as a whole.
        </dd>
        <dt>Space</dt>
        <dd>
          A continuous area or expanse that is free, available, or unoccupied.
        </dd>
        <dd>
          The dimensions of height, depth, and width within which all things
          exist and move.
        </dd>
      </dl>

      <p>
        In my dream last night, I saw
        <abbr title="John Ronald Reuel">J. R. R.</abbr> Tolkien and George
        <abbr title="Raymond Richard">R. R.</abbr> Martin hanging out on Sunset
        <abbr title="Boulevard">Blvd</abbr>.
      </p>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut dolorum
        error eveniet minus neque praesentium quae quam! Dolor ea, laboriosam.
        Ab adipisci harum id itaque numquam obcaecati, officiis porro qui quis
        recusandae repudiandae similique tenetur, ullam unde voluptatem.
        Cupiditate ducimus eius error eveniet, explicabo fuga fugit inventore
        iusto nobis obcaecati odio, optio placeat rem?
      </p>

      <blockquote>
        Great things in business are never done by one person. They're done by a
        team of people.
        <cite title="Source Title">Steve Jobs</cite>
      </blockquote>

      <p>
        Remember to escape angle brackets when printing HTML:
        <code>&lt;div&gt;</code>
      </p>

      <p>
        Press <kbd>Cmd+Q</kbd> (or <kbd>Ctrl+Q</kbd> on Windows) to play
        Half-Life 3.
      </p>

      <hr />

      <h1>h1. This is a very large header.</h1>
      <h2>h2. This is a large header.</h2>
      <h3>h3. This is a medium header.</h3>
      <h4>h4. This is a moderate header.</h4>
      <h5>h5. This is a small header.</h5>
      <h6>h6. This is a tiny header.</h6>

      <hr />

      <table width="100%">
        <thead>
          <tr>
            <th width="200">Table Header</th>
            <th>Table Header</th>
            <th width="150">Table Header</th>
            <th width="150">Table Header</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Regular Table Row</td>
            <td>
              This is longer Content Goes Here Donec id elit non mi porta
              gravida at eget metus.
            </td>
            <td>Content</td>
            <td>Content</td>
          </tr>
          <tr class="primary">
            <td>Primary Table Row</td>
            <td>
              This is longer content Donec id elit non mi porta gravida at eget
              metus.
            </td>
            <td>Content</td>
            <td>Content</td>
          </tr>
          <tr class="success">
            <td>Success Table Row</td>
            <td>
              This is longer Content Goes Here Donec id elit non mi porta
              gravida at eget metus.
            </td>
            <td>Content</td>
            <td>Content</td>
          </tr>
          <tr class="warning">
            <td>Warning Table Row</td>
            <td>
              This is longer Content Goes Here Donec id elit non mi porta
              gravida at eget metus.
            </td>
            <td>Content</td>
            <td>Content</td>
          </tr>
          <tr class="alert">
            <td>Alert Table Row</td>
            <td>
              This is longer Content Goes Here Donec id elit non mi porta
              gravida at eget metus.
            </td>
            <td>Content</td>
            <td>Content</td>
          </tr>
        </tbody>
      </table>

      <hr />

      <div class="grid-x grid-margin-x">
        <div class="cell large-8 medium-8">
          <h4>Input Fields, Select Boxes and Textarea</h4>
          <form>
            <div class="grid-x">
              <div class="cell large-12">
                <label>Input Label</label>
                <input type="text" placeholder="large-12.columns" />
              </div>
            </div>

            <div class="grid-x grid-margin-x">
              <div class="cell large-4 medium-4">
                <label>Text</label>
                <input type="text" placeholder="large-4.columns" />
              </div>
              <div class="cell large-4 medium-4">
                <label>Email</label>
                <input type="email" placeholder="large-4.columns" />
              </div>
              <div class="cell large-4 medium-4">
                <label>Color</label>
                <input type="color" placeholder="large-4.columns" />
              </div>
            </div>

            <div class="grid-x grid-margin-x">
              <div class="cell large-4 medium-4">
                <label>Date</label>
                <input type="date" placeholder="large-4.columns" />
              </div>
              <div class="cell large-4 medium-4">
                <label>Time</label>
                <input type="time" placeholder="large-4.columns" />
              </div>
              <div class="cell large-4 medium-4">
                <label>DateTime Local</label>
                <input type="datetime-local" placeholder="large-4.columns" />
              </div>
            </div>

            <div class="grid-x grid-margin-x">
              <div class="cell large-4 medium-4">
                <label>Password</label>
                <input type="password" placeholder="large-4.columns" />
              </div>
              <div class="cell large-4 medium-4">
                <label>Range</label>
                <input type="range" placeholder="large-4.columns" />
              </div>
            </div>

            <div class="grid-x grid-margin-x">
              <div class="cell large-4 medium-4">
                <label>Phone</label>
                <input type="tel" placeholder="large-4.columns" />
              </div>
              <div class="cell large-4 medium-4">
                <label>Search</label>
                <input type="search" placeholder="large-4.columns" />
              </div>
              <div class="cell large-4 medium-4">
                <label>Url</label>
                <input type="url" placeholder="large-4.columns" />
              </div>
            </div>

            <div class="grid-x grid-margin-x">
              <div class="cell large-6">
                <label>Select Box</label>
                <select>
                  <option value="husker">Husker</option>
                  <option value="starbuck">Starbuck</option>
                  <option value="hotdog">Hot Dog</option>
                  <option value="apollo">Apollo</option>
                </select>
              </div>
              <div class="cell large-6">
                <label>Inline Label</label>
                <div class="input-group">
                  <span class="input-group-label">$</span>
                  <input class="input-group-field" type="number" />
                </div>
              </div>
            </div>

            <div class="grid-x grid-margin-x">
              <div class="cell large-12">
                <label>Textarea Label</label>
                <textarea placeholder="small-12.columns"></textarea>
              </div>
            </div>
          </form>
          <a class="button -primary">Submit</a>
          <a class="button">Cancel</a>
        </div>

        <div class="cell large-4 medium-4">
          <h4>Checkboxes and Radio Buttons</h4>
          <form>
            <div class="grid-x grid-margin-x">
              <div class="cell large-12">
                <input
                  id="pokemonRed"
                  type="radio"
                  name="pokemon"
                  value="Red"
                />
                <label for="pokemonRed">Radio 1 </label>
                <input
                  id="pokemonBlue"
                  type="radio"
                  name="pokemon"
                  value="Blue"
                />
                <label for="pokemonBlue">Radio 2 </label>
              </div>
            </div>

            <div class="grid-grid-margin-x">
              <div class="cell large-12">
                <input id="checkbox1" type="checkbox" />
                <label for="checkbox1">Checkbox 1</label>
                <input id="checkbox2" type="checkbox" />
                <label for="checkbox2">Checkbox 2</label>
              </div>
            </div>

            <div class="grid-x grid-margin-x">
              <div class="cell large-12">
                <fieldset class="fieldset">
                  <legend>Check these out</legend>
                  <input id="checkbox12" type="checkbox" />
                  <label for="checkbox12">Checkbox 1</label>
                  <input id="checkbox22" type="checkbox" />
                  <label for="checkbox22">Checkbox 2</label>
                </fieldset>
              </div>
            </div>
            <div class="grid-x grid-margin-x">
              <div class="cell large-12">
                <div class="switch">
                  <input
                    id="exampleSwitch"
                    class="switch-input"
                    checked
                    type="checkbox"
                    name="exampleSwitch"
                  />
                  <label class="switch-paddle" for="exampleSwitch">
                    <span class="show-for-sr">Download Kittens</span>
                  </label>
                </div>

                <div class="switch large">
                  <input
                    id="yes-no"
                    class="switch-input"
                    type="checkbox"
                    name="exampleSwitch"
                  />
                  <label class="switch-paddle" for="yes-no">
                    <span class="switch-active" aria-hidden="true">Yes</span>
                    <span class="switch-inactive" aria-hidden="true">No</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <hr />

      <ul id="example-tabs" class="tabs" data-tabs>
        <li class="tabs-title is-active">
          <a href="#panel1" aria-selected="true">Tab 1</a>
        </li>
        <li class="tabs-title"><a href="#panel2">Tab 2</a></li>
        <li class="tabs-title"><a href="#panel3">Tab 3</a></li>
      </ul>
      <div class="tabs-content" data-tabs-content="example-tabs">
        <div id="panel1" class="tabs-panel is-active">
          <p>
            Sed auctor neque eu tellus rhoncus ut eleifend nibh porttitor. Ut in
            nulla enim. Phasellus molestie magna non est bibendum non venenatis
            nisl tempor. Suspendisse dictum feugiat nisl ut dapibus.
          </p>
        </div>
        <div id="panel2" class="tabs-panel">
          <p>
            Vivamus hendrerit arcu sed erat molestie vehicula. Ut in nulla enim.
            Phasellus molestie magna non est bibendum non venenatis nisl tempor.
            Sed auctor neque eu tellus rhoncus ut eleifend nibh porttitor.
          </p>
        </div>
        <div id="panel3" class="tabs-panel">
          <p>
            Food truck fixie locavore, accusamus mcsweeney's marfa nulla
            single-origin coffee squid. Exercitation +1 labore velit, blog
            sartorial PBR leggings next level wes anderson artisan four loko
            farm-to-table craft beer twee.
          </p>
        </div>
      </div>

      <hr />

      <div>
        <div class="grid-x">
          <div class="cell medium-3">
            <ul id="example-tabs-vertical" class="vertical tabs" data-tabs>
              <li class="tabs-title is-active">
                <a href="#panel1v" aria-selected="true">Tab 1</a>
              </li>
              <li class="tabs-title"><a href="#panel2v">Tab 2</a></li>
              <li class="tabs-title"><a href="#panel3v">Tab 3</a></li>
              <li class="tabs-title"><a href="#panel4v">Tab 4</a></li>
              <li class="tabs-title"><a href="#panel5v">Tab 5</a></li>
              <li class="tabs-title"><a href="#panel6v">Tab 6</a></li>
            </ul>
          </div>
          <div class="cell medium-9">
            <div
              class="tabs-content vertical"
              data-tabs-content="example-tabs-vertical"
            >
              <div id="panel1v" class="tabs-panel is-active">
                <p>One</p>
                <p>
                  Check me out! I'm a super cool Tab panel with text content!
                </p>
              </div>
              <div id="panel2v" class="tabs-panel">
                <p>Two</p>
                <img
                  class="thumbnail"
                  src="assets/img/generic/rectangle-7.jpg"
                />
              </div>
              <div id="panel3v" class="tabs-panel">
                <p>Three</p>
                <p>
                  Check me out! I'm a super cool Tab panel with text content!
                </p>
              </div>
              <div id="panel4v" class="tabs-panel">
                <p>Four</p>
                <img
                  class="thumbnail"
                  src="assets/img/generic/rectangle-2.jpg"
                />
              </div>
              <div id="panel5v" class="tabs-panel">
                <p>Five</p>
                <p>
                  Check me out! I'm a super cool Tab panel with text content!
                </p>
              </div>
              <div id="panel6v" class="tabs-panel">
                <p>Six</p>
                <img
                  class="thumbnail"
                  src="assets/img/generic/rectangle-8.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <ul class="accordion" data-accordion>
        <li class="accordion-item is-active" data-accordion-item>
          <a href="#" class="accordion-title">Accordion 1</a>
          <div class="accordion-content" data-tab-content>
            I would start in the open state, due to using the `is-active` state
            class.
          </div>
        </li>
        <li class="accordion-item" data-accordion-item>
          <a href="#" class="accordion-title">Accordion 2</a>
          <div class="accordion-content" data-tab-content>
            I would start in the open state, due to using the `is-active` state
            class.
          </div>
        </li>
      </ul>

      <hr />

      <div class="grid-x grid-margin-x">
        <div class="cell">
          <div class="callout">
            <h5>Default Callout</h5>
            <p>Sample Text</p>
            <span></span>
          </div>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="large-6 medium-6 cell">
          <div class="success callout">
            <p>Success Callout</p>
          </div>
        </div>
        <div class="large-6 medium-6 cell">
          <div class="primary callout">
            <p>Primary Callout</p>
          </div>
        </div>
      </div>
      <div class="grid-x grid-margin-x">
        <div class="large-4 medium-4 cell">
          <div class="alert callout">
            <p>Alert Callout</p>
          </div>
        </div>
        <div class="large-4 medium-4 cell">
          <div class="warning callout">
            <p>Warning Callout</p>
          </div>
        </div>
        <div class="large-4 medium-4 cell">
          <div class="secondary callout">
            <p>Secondary Callout</p>
          </div>
        </div>
      </div>

      <div class="success callout" data-closable="slide-out-right">
        <h5>This a friendly message.</h5>
        <p>
          And when you're done with me, I close using a Motion UI animation.
        </p>
        <button
          class="close-button"
          aria-label="Dismiss alert"
          type="button"
          data-close
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="alert callout" data-closable>
        <h5>This is Important!</h5>
        <p>
          But when you're done reading it, click the close button in the corner
          to dismiss this alert.
        </p>
        <button
          class="close-button"
          aria-label="Dismiss alert"
          type="button"
          data-close
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import jQuery from "jquery";

export default Vue.extend({
  name: "TypographicPage",
  metaInfo: {
    title: "Typographic Page",
    meta: [
      {
        vmid: "og:title",
        name: "og:title",
        content: `Typographic Page | Budsies Backend System`,
      },
    ],
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
    jQuery(document).foundation();
  },
});
</script>

<style lang="scss" scoped>
.page-typography {
  text-align: left;
}
</style>
