import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class InvoiceItem implements MutableValue {
  public isNew = true;

  private fPlushieName?: string;
  private fPlushieNameForFactory?: string;

  constructor(
    private fId: string,
    private fInvoice: string,
    private fPlushie: string,
    private fQty: number,
    private fPlushieProductionStartDate: Date,
    private fPlushieProductionEndDate: Date,
    private fPrice: number,
    private fPenaltyPercent: number,
    private fApplyPenalty: boolean,
    private fExtraPaymentAmount: number,
    private fTotalPrice: number,
    private fPlushieProductionDays: number,
    private fPlushieProductionTime: string,
    private fIsDirectlyShipped: boolean,
    private fNonPenalizableAmount: number
  ) {
    [
      fId,
      fInvoice,
      fPlushie,
      fQty,
      fPlushieProductionStartDate,
      fPlushieProductionEndDate,
      fPrice,
      fPenaltyPercent,
      fApplyPenalty,
      fExtraPaymentAmount,
      fTotalPrice,
      fPlushieProductionDays,
      fPlushieProductionTime,
      fIsDirectlyShipped,
      fNonPenalizableAmount,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get invoice(): string {
    return this.fInvoice;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get qty(): number {
    return this.fQty;
  }

  get plushieProductionStartDate(): Date {
    return this.fPlushieProductionStartDate;
  }

  get plushieProductionEndDate(): Date {
    return this.fPlushieProductionEndDate;
  }

  get price(): number {
    return this.fPrice;
  }

  get penaltyPercent(): number {
    return this.fPenaltyPercent;
  }

  get applyPenalty(): boolean {
    return this.fApplyPenalty;
  }

  get extraPaymentAmount(): number {
    return this.fExtraPaymentAmount;
  }

  get totalPrice(): number {
    return this.fTotalPrice;
  }

  get plushieProductionDays(): number {
    return this.fPlushieProductionDays;
  }

  get plushieProductionTime(): string {
    return this.fPlushieProductionTime;
  }

  get plushieName(): string | undefined {
    return this.fPlushieName;
  }

  get plushieNameForFactory(): string | undefined {
    return this.fPlushieNameForFactory;
  }

  get isDirectlyShipped(): boolean {
    return this.fIsDirectlyShipped;
  }

  get nonPenalizableAmount(): number {
    return this.fNonPenalizableAmount;
  }
}
