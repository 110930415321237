































import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import TabsContainer, {
  TabSpecification,
} from "@/lib/components/TabsContainer.vue";

import InReviewSnapshotTable from "./InReviewTable.vue";
import ProductionSnapshotTable from "./ProductionTable.vue";

import ProductionSnapshotRepository from "../../production-snapshot.repository";
import ProductionSnapshot from "../../production-snapshot.model";
import InReviewSnapshotRepository from "../../in-review-snapshot.repository";
import InReviewSnapshot from "../../in-review-snapshot.model";

@Component({
  components: {
    InReviewSnapshotTable,
    MoonLoader,
    ProductionSnapshotTable,
    TabsContainer,
  },
})
export default class SnapshotReportPage extends Vue {
  @Inject("InReviewSnapshotRepository")
  private fInReviewSnapshotRepository!: InReviewSnapshotRepository;

  @Inject("ProductionSnapshotRepository")
  private fProductionSnapshotRepository!: ProductionSnapshotRepository;

  private fIsLoading = false;

  private fInReviewSnapshots: InReviewSnapshot[] = [];
  private fProductionSnapshots: ProductionSnapshot[] = [];

  get inReviewSnapshotTableData(): InReviewSnapshot[] {
    return this.fInReviewSnapshots;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get productionSnapshotTableData(): ProductionSnapshot[] {
    return this.fProductionSnapshots;
  }

  get tabs(): TabSpecification[] {
    return [
      {
        id: "snapshot",
        name: "Snapshot",
      },
      {
        id: "in_review",
        name: "In Review",
      },
    ];
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    try {
      [this.fInReviewSnapshots, this.fProductionSnapshots] = await Promise.all([
        this.fInReviewSnapshotRepository.fetchInReviewSnapshots(),
        this.fProductionSnapshotRepository.fetchProductionSnapshots(),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
