















import { Component, Prop } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";

import BulkReassignToFactoryForm from "./Form.vue";

@Component({
  components: { BulkReassignToFactoryForm },
})
export default class BulkReassignToFactoryDialog extends DialogComponent<boolean> {
  @Prop({ required: true })
  public readonly plushieIds!: string[];
}
