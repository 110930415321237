import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class TrackingInformation implements MutableValue {
  public isNew = true;
  public createdAt?: Date;

  constructor(
    private fId: string,
    private fTrackingNumber: string,
    private fCarrier: string
  ) {
    [fId, fTrackingNumber, fCarrier].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get trackingNumber(): string {
    return this.fTrackingNumber;
  }

  get carrier(): string {
    return this.fCarrier;
  }
}
