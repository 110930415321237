import ApiService from "@/modules/api/api.service";

import ObjectBuilderInterface from "../api/object-builder.interface";
import { FileProcessingImageType } from "./file-processing-image-type";
import Item from "./item.model";

export default class FileProcessingRepository {
  constructor(
    private api: ApiService,
    private objectBuilder: ObjectBuilderInterface<Item>
  ) {}

  public async uploadFile(
    file: File,
    imageType: Exclude<
      FileProcessingImageType,
      FileProcessingImageType.QaVideo | FileProcessingImageType.QaVideoThumb
    >,
    product?: string,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
  ): Promise<Item> {
    const formData = new FormData();
    formData.append("file", file, file.name);

    formData.append("imageType", imageType.toString());

    if (product) {
      formData.append("product", product);
    }

    const result = await this.api.post(
      "file_upload_jobs",
      formData,
      onUploadProgress
    );

    return this.objectBuilder.buildFromJSON(result.data["item"]);
  }

  public async uploadVideoFile(
    file: File,
    product?: string,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
  ): Promise<{
    item: Item;
    itemThumb: Item;
  }> {
    const formData = new FormData();

    formData.append("file", file, file.name);
    formData.append("imageType", FileProcessingImageType.QaVideo.toString());

    if (product) {
      formData.append("product", product);
    }

    const result = await this.api.post(
      "file_upload_jobs",
      formData,
      onUploadProgress
    );

    return {
      item: this.objectBuilder.buildFromJSON(result.data.item),
      itemThumb: this.objectBuilder.buildFromJSON(result.data.item_thumb),
    };
  }

  public async rotateImage(item: Item, rotationDegrees: number): Promise<Item> {
    const result = await this.api.post("image_rotation_jobs", {
      storageItemId: item.id,
      rotationDegrees: rotationDegrees.toString(),
    });

    return this.objectBuilder.buildFromJSON(result.data["item"]);
  }
}
