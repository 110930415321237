












































import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import FactoryInvoiceStore from "../../store/index";
import FactoryInvoice from "../../factory-invoice.model";
import { FactoryInvoiceStatusValue } from "../../factory-invoice-status.value";

@Component({})
export default class FactoryInvoiceViewInfo extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fFactoryInvoiceStore: FactoryInvoiceStore;

  get invoice(): FactoryInvoice | undefined {
    return this.fFactoryInvoiceStore.getFactoryInvoiceById(this.invoiceId);
  }

  get buyerNotesClass(): string {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return "";
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY ? "-highlighted" : "";
  }

  get vendorNotesClass(): string {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return "";
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY ? "" : "-highlighted";
  }

  get invoiceNumber(): string {
    if (!this.invoice) {
      return "";
    }

    return this.invoice.number;
  }

  get isPaid(): boolean {
    if (!this.invoice) {
      return false;
    }

    return this.invoice.status === FactoryInvoiceStatusValue.PAID;
  }

  get invoiceDate(): string {
    if (!this.invoice) {
      return "";
    }

    return this.invoice.createdAt
      ? this.invoice.createdAt.toLocaleDateString()
      : "";
  }

  get status(): string {
    if (!this.invoice) {
      return "";
    }

    const status = this.fFactoryInvoiceStore.getInvoiceStatusById(
      this.invoice.status
    );

    return status ? status.name : "";
  }

  get showVendorNotes(): boolean {
    if (!this.invoice || !this.invoice.vendorNotes) {
      return false;
    }

    return [
      FactoryInvoiceStatusValue.REVIEW,
      FactoryInvoiceStatusValue.UNPAID,
      FactoryInvoiceStatusValue.PAID,
    ].includes(this.invoice.status);
  }

  get showBuyerNotes(): boolean {
    if (!this.invoice || !this.invoice.buyerNotes) {
      return false;
    }

    return [
      FactoryInvoiceStatusValue.UNPAID,
      FactoryInvoiceStatusValue.PAID,
    ].includes(this.invoice.status);
  }

  constructor() {
    super();

    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }
}
