



























import { Component, Prop, Inject, Vue, Watch } from "vue-property-decorator";

import ActionButton from "@/lib/components/ActionButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import BulkMoveToDesignService from "@/modules/production-process/bulk-move-to-design.service";
import BulkMoveToProductionService from "@/modules/production-process/bulk-move-to-production.service";
import BulkPrepareToShipmentService from "@/modules/production-process/bulk-prepare-to-shipment.service";
import BulkReassignToFactoryService from "@/modules/production-process/bulk-reassign-to-factory.service";
import CreateBulkShipmentService from "@/modules/bulk-shipment/create-bulk-shipment.service";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import BulkExportAsExcelService from "@/modules/document-export/bulk-export-as-excel.service";

import { AvailableMassAction } from "../available-mass-action.interface";
import AvailableMassActionsProviderService from "../available-mass-actions-provider.service";
import { PlushieStatusValue } from "../plushie-status.value";
import BulkPlushiesArtworksDownloadService from "../bulk-plushies-artworks-download.service";

@Component({
  components: {
    FormErrors,
    ActionButton,
  },
})
export default class MassActionsCard extends Vue {
  @Prop({ required: true })
  public readonly selectedPlushies!: string[];

  @Prop({ required: true })
  public readonly plushieStatus!: PlushieStatusValue;

  @Inject("AvailableMassActionsProviderService")
  private fAvailableMassActionsProvider!: AvailableMassActionsProviderService;

  @Inject("CreateBulkShipmentService")
  private fCreateBulkShipmentService!: CreateBulkShipmentService;

  @Inject("BulkMoveToDesignService")
  private fBulkMoveToDesignService!: BulkMoveToDesignService;

  @Inject("BulkMoveToProductionService")
  private fBulkMoveToProductionService!: BulkMoveToProductionService;

  @Inject("BulkPrepareToShipmentService")
  private fBulkPrepareToShipmentService!: BulkPrepareToShipmentService;

  @Inject("BulkReassignToFactoryService")
  private fBulkReassignToFactoryService!: BulkReassignToFactoryService;

  @Inject("BulkExportAsExcelService")
  private fBulkExportAsExcelService!: BulkExportAsExcelService;

  @Inject("BulkPlushiesArtworksDownloadService")
  private fBulkPlushiesArtworksDownloadService!: BulkPlushiesArtworksDownloadService;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fIsSubmitting = false;
  private fSubmitErrors: string[] = [];

  private fActionOptions: AvailableMassAction[] = [];

  get actionOptions(): AvailableMassAction[] {
    return this.fActionOptions;
  }

  get isAnyActionAvailable(): boolean {
    return this.fActionOptions.length > 0;
  }

  get isDisabled(): boolean {
    return this.fIsSubmitting || !this.selectedPlushies.length;
  }

  get submitErrors(): string[] {
    return this.fSubmitErrors;
  }

  public async executeAction(action: string): Promise<void> {
    if (!this.selectedPlushies || this.isDisabled) {
      return;
    }

    this.fIsSubmitting = true;
    this.fSubmitErrors = [];

    let isExecuted = false;

    this.$emit("processingStarted");

    try {
      switch (action) {
        case CreateBulkShipmentService.ID:
          isExecuted = await this.fCreateBulkShipmentService.execute(
            this.selectedPlushies
          );
          break;
        case BulkMoveToDesignService.ID:
          isExecuted = await this.fBulkMoveToDesignService.execute(
            this.selectedPlushies
          );
          break;
        case BulkMoveToProductionService.ID:
          isExecuted = await this.fBulkMoveToProductionService.execute(
            this.selectedPlushies
          );
          break;
        case BulkPrepareToShipmentService.ID:
          isExecuted = await this.fBulkPrepareToShipmentService.execute(
            this.selectedPlushies
          );
          break;
        case BulkReassignToFactoryService.ID:
          isExecuted = await this.fBulkReassignToFactoryService.execute(
            this.selectedPlushies
          );
          break;
        case BulkExportAsExcelService.ID:
          isExecuted = await this.fBulkExportAsExcelService.execute(
            this.selectedPlushies
          );
          break;
        case BulkPlushiesArtworksDownloadService.ID:
          isExecuted = await this.fBulkPlushiesArtworksDownloadService.execute(
            this.selectedPlushies
          );
          break;
        default:
          this.fSubmitErrors = ["Unknown action"];
      }

      this.$emit("processingFinished");

      if (isExecuted) {
        this.$emit("actionProcessed", action);
      }
    } catch (error) {
      this.fSubmitErrors = this.fErrorConverterService.describeError(error);
      this.$emit("processingFinished");
    } finally {
      this.fIsSubmitting = false;
    }
  }

  @Watch("plushieStatus", { immediate: true })
  private async _onPlushieStatusChange() {
    if (!this.plushieStatus) {
      return;
    }

    this.fActionOptions = await this.fAvailableMassActionsProvider.provide(
      this.plushieStatus
    );
  }
}
