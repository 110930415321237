import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Carrier from "./carrier.model";

export default class CarrierFactory implements ObjectBuilderInterface<Carrier> {
  public buildFromJSON(data: { [key: string]: any }): Carrier {
    const value = new Carrier(data.id, data.name, data.code);

    value.isNew = false;

    if (data.createdAt) {
      value.createdAt = new Date(data.createdAt);
    }

    return value;
  }
}
