













































































import { Component, Inject, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { RowClickEventData, ServerResponse } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryStore from "../store";

interface TableRow {
  id: string;
  name: string;
  email: string;
  isActive: boolean;
  createdAt: string;
}

@Component({
  metaInfo: {
    title: "Factories management",
  },
  components: {},
})
export default class FactoriesListPage extends mixins(GeneralListPageMixin) {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fFactoryDataStore: FactoryStore;

  private fShowInactiveFactories = false;

  get canFactoryInvoicingSettingsView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_SETTINGS_READ
    );
  }

  get canFactoryUsersManage(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_USERS_MANAGE);
  }

  get canFactoryUsersView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_USERS_VIEW);
  }

  get canManageFactories(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_MANAGE);
  }

  get factoryUsersButtonText(): string {
    if (this.canFactoryUsersManage) {
      return "Manage Users";
    }

    if (this.canFactoryUsersView) {
      return "View Users";
    }

    return "";
  }

  get showInactiveFactories(): boolean {
    return this.fShowInactiveFactories;
  }

  get showInactiveFactoriesCtrlText(): string {
    return this.fShowInactiveFactories ? "Hide inactive" : "Show inactive";
  }

  public constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "email",
        header: "E-mail",
        cssClass: "_email-column -text",
        isFilterable: true,
        isSortable: true,
        displayConstraint: "min_tabletL",
      },
      {
        name: "isActive",
        header: "Active",
        cssClass: "_isactive-column -checkbox",
        isFilterable: true,
        isFilterFromList: true,
        filterValues: [
          { id: "true", text: "Yes" },
          { id: "false", text: "No" },
        ],
      },
      {
        name: "createdAt",
        header: "Created",
        cssClass: "_createdat-column",
        isSortable: true,
        displayConstraint: "min_tabletL",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "name",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    if (!this.canManageFactories) {
      return;
    }

    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "FactoryEditPage"));
  }

  public toggleShowInactiveFactories(): void {
    this.fShowInactiveFactories = !this.fShowInactiveFactories;
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const factoriesCollection = await this.fFactoryDataStore.loadFactoriesWithFilter(
      {
        filter,
        order,
        useCache: false,
      }
    );

    const result: TableRow[] = [];

    factoriesCollection.getItems().forEach((factory) => {
      result.push({
        id: factory.id,
        name: factory.name,
        email: factory.email,
        isActive: factory.isActive,
        createdAt: factory.createdAt
          ? factory.createdAt.toLocaleDateString()
          : "",
      });
    });

    return { data: result, count: factoriesCollection.totalItemsCount };
  }

  protected getExtraParams(): Dictionary<string | number | boolean> {
    return this.fShowInactiveFactories
      ? {}
      : {
          isActive: true,
        };
  }

  @Watch("showInactiveFactories", { immediate: true })
  private _onShowInactiveFactoriesChange(): void {
    if (!this.fTable) {
      return;
    }

    this.fTable.refresh();
  }
}
