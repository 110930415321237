import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieValueRelation from "./plushie-value-relation.model";

export default class PlushieValueRelationFactory
  implements ObjectBuilderInterface<PlushieValueRelation> {
  public buildFromJSON(data: { [key: string]: any }): PlushieValueRelation {
    const value = new PlushieValueRelation(data.id, data.plushie, data.value);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
