import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Alert from "./alert.model";
import StuckInfo from "./stuck-info.model";
import WarningInfo from "./warning-info.model";

export default class AlertFactory implements ObjectBuilderInterface<Alert> {
  public buildFromJSON(data: { [key: string]: any }): Alert {
    const stuckInfo = new StuckInfo(
      data.stuck.plushies,
      data.stuck.largest_delay
    );

    const warningInfo = new WarningInfo(
      data.warning.plushies,
      data.warning.days_before_block
    );

    const value = new Alert(stuckInfo, warningInfo);

    return value;
  }
}
