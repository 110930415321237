import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class UserInfo implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fOrganization: string,
    private fEmail: string,
    private fFirstName: string,
    private fLastName: string
  ) {
    [fId, fOrganization, fEmail, fFirstName, fLastName].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get email(): string {
    return this.fEmail;
  }

  get firstName(): string {
    return this.fFirstName;
  }

  get lastName(): string {
    return this.fLastName;
  }

  get organization(): string {
    return this.fOrganization;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }

  get fullName(): string {
    return (this.fFirstName + " " + this.fLastName).trim();
  }

  get shortName(): string {
    const lastNameInitial = this.fLastName
      ? this.fLastName.charAt(0) + "."
      : "";

    return (this.fFirstName + " " + lastNameInitial).trim();
  }
}
