import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieUpgradeRelation from "./plushie-upgrade-relation.model";

export default class PlushieUpgradeRelationFactory
  implements ObjectBuilderInterface<PlushieUpgradeRelation> {
  public buildFromJSON(data: { [key: string]: any }): PlushieUpgradeRelation {
    const value = new PlushieUpgradeRelation(
      data.id,
      data.plushie,
      data.upgrade
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
