import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { Dictionary } from "@/lib/Dictionary.type";

import { ApiFilterValue } from "../api/api-filter-value.type";
import ThroughputReport from "./throughput-report.model";
import { ThroughputReportTimePeriod } from "./throughput-report-time-period";

export default class ThroughputReportRepository {
  private readonly fResourceName = "throughput_stats";

  constructor(
    private api: ApiService,
    private objectBuilder: ObjectBuilderInterface<ThroughputReport>
  ) {}

  public async getList(
    timePeriod: ThroughputReportTimePeriod,
    factoryId?: string,
    startDate?: Date,
    endDate?: Date,
    productId?: string
  ): Promise<ThroughputReport[]> {
    const data: Dictionary<ApiFilterValue> = {
      timePeriod,
    };

    if (startDate) {
      data.startDate = startDate.toISOString();
    }

    if (endDate) {
      data.endDate = endDate.toISOString();
    }

    if (productId) {
      data.productId = productId;
    }

    if (factoryId) {
      data.factoryId = factoryId;
    }

    const response = await this.api.get(this.fResourceName, data);

    return (response.data as any[]).map((item) =>
      this.objectBuilder.buildFromJSON(item)
    );
  }
}
