












import { Component, Vue } from "vue-property-decorator";

import DirectShipmentIntentionRuleEditForm from "./EditForm.vue";

@Component({
  metaInfo: {
    title: "Add Direct Shipment Intention Rule",
  },
  components: {
    DirectShipmentIntentionRuleEditForm,
  },
})
export default class DirectShipmentIntentionRuleAddPage extends Vue {}
