import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class ShippingInformation implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fMethodName: string,
    private fFirstName: string,
    private fCountry: string,
    private fStreetAddress1: string,
    private fLastName: string,
    private fCompanyName: string,
    private fStreetAddress2: string,
    private fCity: string,
    private fPostalCode: string,
    private fPhone: string,
    private fState: string
  ) {
    [fId, fMethodName, fFirstName, fStreetAddress1, fCountry].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get methodName(): string {
    return this.fMethodName;
  }

  get firstName(): string {
    return this.fFirstName;
  }

  get lastName(): string {
    return this.fLastName;
  }

  get companyName(): string {
    return this.fCompanyName;
  }

  get streetAddress1(): string {
    return this.fStreetAddress1;
  }

  get streetAddress2(): string {
    return this.fStreetAddress2;
  }

  get city(): string {
    return this.fCity;
  }

  get postalCode(): string {
    return this.fPostalCode;
  }

  get country(): string {
    return this.fCountry;
  }

  get phone(): string {
    return this.fPhone;
  }

  get state(): string {
    return this.fState;
  }
}
