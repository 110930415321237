import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import RejectionReasonProductRelation from "./rejection-reason-product-relation.model";

export default class RejectionReasonProductRelationFactory
  implements ObjectBuilderInterface<RejectionReasonProductRelation> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): RejectionReasonProductRelation {
    const value = new RejectionReasonProductRelation(
      data.id,
      data.rejectionReason,
      data.productId.toString()
    );

    value.isNew = false;

    return value;
  }
}
