








































import { Component, Prop, Inject, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import DatePicker from "vuejs-datepicker";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import HolidayPeriodStore from "../store";
import RegularOrdersAssignJobRepository from "../regular-orders-assign-job.repository";
import HolidayPeriod from "../holiday-period.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    DatePicker,
  },
})
export default class RegularOrdersAssignForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly periodId!: string;

  @Inject("HolidayPeriodRegularOrdersAssignJobRepository")
  private fRegularOrdersAssignJobRepository!: RegularOrdersAssignJobRepository;

  public startDate?: Date;

  private fHolidayPeriodDataStore: HolidayPeriodStore;

  get holidayPeriod(): HolidayPeriod | undefined {
    return this.fHolidayPeriodDataStore.getHolidayPeriodById(this.periodId);
  }

  constructor() {
    super();

    this.fHolidayPeriodDataStore = getModule(HolidayPeriodStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      startDate: this.startDate,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.startDate) {
      throw new Error("Not all required fields are filled!");
    }

    await this.fRegularOrdersAssignJobRepository.assign(
      this.periodId,
      this.startDate
    );

    this.$emit("formSubmitted");
  }

  private async getPeriodData(periodId: string): Promise<void> {
    await this.fHolidayPeriodDataStore.loadHolidayPeriodById(periodId);
  }

  @Watch("periodId", { immediate: true })
  private async _onPeriodIdChange() {
    if (this.periodId == null) {
      return;
    }

    await this.getPeriodData(this.periodId);
  }
}
