import { Route } from "vue-router";

export default function listPageUrlParser(
  route: Route
): Record<string, string | number | undefined> {
  let page;
  let pageSize;
  let filter;
  let orderBy;

  if (typeof route.query.page === "string") {
    page = parseInt(route.query.page, 10);
  }

  if (typeof route.query.pageSize === "string") {
    pageSize = parseInt(route.query.pageSize, 10);
  }

  if (typeof route.query.filter === "string") {
    filter = JSON.parse(route.query.filter);
  }

  if (typeof route.query.orderBy === "string") {
    orderBy = JSON.parse(route.query.orderBy);
  }

  return {
    page,
    pageSize,
    filter,
    orderBy,
  };
}
