










import { Component, Vue } from "vue-property-decorator";

import UseOfRejectionReasonReport from "./Report.vue";

@Component({
  components: {
    UseOfRejectionReasonReport,
  },
  metaInfo: {
    title: "Use of Rejection Reason Report",
  },
})
export default class UseOfRejectionReasonPage extends Vue {}
