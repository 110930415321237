import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieTagRelation from "./plushie-tag-relation.model";

export default class PlushieTagRelationRepository extends BasicRepository<PlushieTagRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieTagRelation>,
    responseParser: ApiCollectionResponseParserService<PlushieTagRelation>
  ) {
    super("plushie_tag_relations", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(
    plushieId: string
  ): Promise<PlushieTagRelation[]> {
    if (!plushieId) {
      return [];
    }

    const collection = await this.fetchList({ plushieId });

    return collection.getItems();
  }

  public async getByPlushieIds(
    plushieIds: string[]
  ): Promise<PlushieTagRelation[]> {
    if (!plushieIds) {
      return [];
    }

    const collection = await this.fetchList({ plushieId: plushieIds }, 1, 9999);

    return collection.getItems();
  }

  public async addTagToPlushie(
    tag: string,
    plushieId: string
  ): Promise<PlushieTagRelation> {
    const data = {
      tagName: tag.toLowerCase(),
      plushieId: plushieId,
    };

    const response = await this.api.post("plushie_tags", data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: PlushieTagRelation): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    return data;
  }
}
