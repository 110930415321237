import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import BulkProjectViewPage from "./components/BulkProjectViewPage.vue";
import BulkProjectsListPage from "./components/BulkProjectsListPage.vue";
import BulkProjectCreatePage from "./components/BulkProjectCreatePage.vue";

export default [
  {
    path: "/bulk_project/list",
    name: "BulkProjectsListPage",
    component: BulkProjectsListPage,
    props: (route: Route) => {
      const props = listPageUrlParser(route);

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/bulk_project/view",
    name: "BulkProjectViewPage",
    component: BulkProjectViewPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["bulkProjectId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/bulk_project/create",
    name: "BulkProjectCreatePage",
    component: BulkProjectCreatePage,
    props: (route: Route) => {
      return {
        samplePlushieId: route.query.samplePlushieId,
      };
    },
    meta: {
      auth: true,
    },
  },
];
