import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProjectionStats from "./projection-stats.model";

export default class ProjectionStatsFactory
  implements ObjectBuilderInterface<ProjectionStats> {
  public buildFromJSON(data: { [key: string]: any }): ProjectionStats {
    const value = new ProjectionStats(
      data.product_id,
      data.projection_total,
      data.capacity,
      data.capacity_remaining,
      data.sold_total,
      data.sales_weeks
    );

    return value;
  }
}
