






























import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import RejectionReasonProductRelationRepository from "../rejection-reason-product-relation.repository";
import RejectionReasonProductRelation from "../rejection-reason-product-relation.model";
import Product from "../../plushie/product.model";
import PlushieStore from "../../plushie/store";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class RejectionReasonProductsEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop()
  public readonly reasonId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("RejectionReasonProductRelationRepository")
  private fRelationRepository!: RejectionReasonProductRelationRepository;

  private fPlushieDataStore: PlushieStore;

  private fRelations: RejectionReasonProductRelation[] = [];
  private fUpdatingRelations: Dictionary<boolean> = {};

  get relations(): RejectionReasonProductRelation[] {
    return this.fRelations;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public getProductOptions(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  public isProductAssociatedWithReason(productId: string): boolean {
    return this.getRelationByProductId(productId) !== undefined;
  }

  public isRelationUpdating(relationId: string): boolean {
    return this.fUpdatingRelations[relationId];
  }

  public onRelationChange(product: Product, value: boolean): void {
    if (!value) {
      void this.deleteRelation(product);
    } else {
      void this.addRelation(product);
    }
  }

  protected data(): Record<string, unknown> {
    return {
      fRelations: this.fRelations,
    };
  }

  protected mounted(): void {
    void this.fPlushieDataStore.loadProducts();
  }

  private async deleteRelation(product: Product) {
    const relation = this.getRelationByProductId(product.id);

    if (!relation) {
      return;
    }

    this.removeRelationFromList(relation);

    this.markProductAsUpdating(product.id);

    try {
      await this.fRelationRepository.delete(relation);
      this.submitErrors = [];
    } catch (e) {
      this.fRelations.push(relation);

      this.submitErrors.push(
        `Something went wrong! Unable to remove the product '${product.name}'`
      );
    }

    this.markProductAsAvailable(product.id);
  }

  private async addRelation(product: Product) {
    const relation = new RejectionReasonProductRelation(
      this.fIdGenerator.getId(),
      this.reasonId,
      product.id
    );

    this.fRelations.push(relation);

    this.markProductAsUpdating(product.id);

    try {
      await this.fRelationRepository.save(relation);
      this.submitErrors = [];
    } catch (e) {
      this.removeRelationFromList(relation);
      this.submitErrors.push(
        `Something went wrong! Unable to add the relation '${product.name}'`
      );
    }

    this.markProductAsAvailable(product.id);
  }

  private getRelationByProductId(
    productId: string
  ): RejectionReasonProductRelation | undefined {
    return this.relations.find((item) => item.product === productId);
  }

  private markProductAsUpdating(productId: string) {
    Vue.set(this.fUpdatingRelations, productId, true);
  }

  private markProductAsAvailable(productId: string) {
    Vue.set(this.fUpdatingRelations, productId, false);
  }

  private removeRelationFromList(item: RejectionReasonProductRelation) {
    const index = this.relations.indexOf(item);

    if (index > -1) {
      this.fRelations.splice(index, 1);
    }
  }

  @Watch("reasonId", { immediate: true })
  private async _onReasonIdChange() {
    this.fRelations = [];

    this.fIsDisabled = true;

    try {
      const relations = await this.fRelationRepository.getByReasonId(
        this.reasonId
      );
      this.fRelations = relations.getItems();

      this.fIsDisabled = false;
    } catch (e) {
      this.fIsDisabled = false;

      throw e;
    }
  }
}
