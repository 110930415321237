




























































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import Editor from "@tinymce/tinymce-vue";
import tinymce from "tinymce";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import PlushieStore from "@/modules/plushie/store";

import LabelHeader from "../label-header.model";
import BarcodeStore from "../store";

import "tinymce/themes/silver";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/image";
import "tinymce/plugins/code";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
    Editor,
  },
})
export default class LabelHeaderEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly labelHeader?: LabelHeader;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public name?: string;
  public content?: string;
  public store?: string;

  private fBarcodeDataStore: BarcodeStore;
  private fPlushieDataStore: PlushieStore;

  get editorOptions(): Dictionary<any> {
    return {
      branding: false,
      plugins: "image code preview print autoresize",
      skin: false,
      max_height: 800,
      content_css: false,
      content_style: `
        body {
          width: 122px;
          border: 1px dotted black;
          padding: 0.25em 0 !important;
          border-bottom: 3px solid black;
          color: black;
          font-family: Tahoma, Arial, sans-serif;
          font-size: 10px;
          text-align: center;
        }
	
	    `,
      file_picker_callback: (callback: any, value: any, meta: any) => {
        this.openImagePicker(callback, value, meta);
      },
    };
  }

  get storeOptions(): DirectoryValue[] {
    return this.fPlushieDataStore.storesList;
  }

  constructor() {
    super();
    this.fBarcodeDataStore = getModule(BarcodeStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      name: this.name,
      store: this.store,
      content: this.content,
    };
  }

  protected mounted(): void {
    void this.fPlushieDataStore.loadStores();
  }

  protected async performSubmit(): Promise<void> {
    let lableHeader = this.generateLabelHeaderObject();

    lableHeader = await this.fBarcodeDataStore.saveLabelHeader(lableHeader);
    this.$emit("updated", lableHeader);
  }

  private generateLabelHeaderObject(): LabelHeader {
    if (!this.name || !this.content) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.labelHeader
      ? this.labelHeader.id
      : this.fIdGenerator.getId();

    const value = new LabelHeader(id, this.name, this.content);

    if (this.store !== undefined) {
      (value as any).fStoreId = this.store.toString();
    }

    if (this.labelHeader) {
      value.isNew = false;
    }

    return value;
  }

  private openImagePicker(callback: any, value: any, meta: any): void {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.onchange = function () {
      const files = input.files;

      if (!files || !files[0]) {
        alert("File not found");
        return;
      }

      const file = files[0];

      const reader = new FileReader();

      reader.onload = function () {
        if (!reader.result || typeof reader.result !== "string") {
          alert("Could not read file");
          return;
        }

        const id = "blobid" + new Date().getTime().toString();

        const blobCache = tinymce.activeEditor.editorUpload.blobCache;

        const base64 = reader.result.split(",")[1];

        const blobInfo = blobCache.create(id, file, base64);

        blobCache.add(blobInfo);

        callback(blobInfo.blobUri());
      };

      reader.readAsDataURL(file);
    };

    input.click();
  }

  @Watch("labelHeader", { immediate: true })
  private _onLabelHeaderChange() {
    this.name = undefined;
    this.content = undefined;
    this.store = undefined;

    if (this.labelHeader === undefined) {
      return;
    }

    this.name = this.labelHeader.name;
    this.content = this.labelHeader.content;
    this.store = this.labelHeader.storeId;
  }
}
