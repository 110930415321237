import { Route } from "vue-router";

import PlushiePrintPage from "@/modules/document-export/components/PlushiePrintPage.vue";

export default [
  {
    path: "/plushie/print",
    name: "PlushiePrintPage",
    component: PlushiePrintPage,
    props: (route: Route) => {
      return {
        plushieId: route.query.id,
      };
    },
    meta: {
      auth: true,
    },
  },
];
