import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import CreateBulkShipmentService from "@/modules/bulk-shipment/create-bulk-shipment.service";
import BulkMoveToDesignService from "@/modules/production-process/bulk-move-to-design.service";
import BulkMoveToProductionService from "@/modules/production-process/bulk-move-to-production.service";
import BulkReassignToFactoryService from "@/modules/production-process/bulk-reassign-to-factory.service";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import RestrictedModeStatus from "@/modules/factory-restricted-mode/restricted-mode-status.model";
import FactoryStore from "@/modules/factory/store";
import FactoryRestrictedModeStore from "@/modules/factory-restricted-mode/store";
import BulkExportAsExcelService from "@/modules/document-export/bulk-export-as-excel.service";
import BulkPrepareToShipmentService from "@/modules/production-process/bulk-prepare-to-shipment.service";
import IsUserUnderStrictModeResolver from "@/modules/strict-capacity-mode/is-user-under-strict-mode-resolver.service";
import IsPullModeEnabledResolver from "@/modules/strict-capacity-mode/is-pull-mode-enabled.resolver.service";

import { AvailableMassAction } from "./available-mass-action.interface";
import { PlushieStatusValue } from "./plushie-status.value";
import BulkPlushiesArtworksDownloadService from "./bulk-plushies-artworks-download.service";

export default class AvailableMassActionsProviderService {
  private fUserProvider: AuthenticatedUserProvider;
  private fIsUserUnderStrictModeResolver: IsUserUnderStrictModeResolver;
  private fIsPullModeEnabledResolver: IsPullModeEnabledResolver;
  private fFactoryStore: FactoryStore;
  private fFactoryRestrictedModeStore: FactoryRestrictedModeStore;

  constructor(
    userProvider: AuthenticatedUserProvider,
    isUserUnderStrictModeResolver: IsUserUnderStrictModeResolver,
    isPullModeEnabledResolver: IsPullModeEnabledResolver
  ) {
    this.fUserProvider = userProvider;
    this.fIsUserUnderStrictModeResolver = isUserUnderStrictModeResolver;
    this.fIsPullModeEnabledResolver = isPullModeEnabledResolver;
    this.fFactoryStore = getModule(FactoryStore, dataStore);
    this.fFactoryRestrictedModeStore = getModule(
      FactoryRestrictedModeStore,
      dataStore
    );
  }

  public async provide(
    plushieStatus: PlushieStatusValue
  ): Promise<AvailableMassAction[]> {
    const actions: AvailableMassAction[] = [];

    if (this.isCreateBulkShipmentActionAvailable(plushieStatus)) {
      actions.push({
        id: CreateBulkShipmentService.ID,
        name: "Bulk Shipment",
      });
    }

    if (await this.isBulkMoveToDesignActionAvailable(plushieStatus)) {
      actions.push({
        id: BulkMoveToDesignService.ID,
        name: "Move to Design",
      });
    }

    if (await this.isBulkMoveToProductionActionAvailable(plushieStatus)) {
      actions.push({
        id: BulkMoveToProductionService.ID,
        name: "Move to Production",
      });
    }

    if (await this.isBulkPrepareToShipmentActionAvailable(plushieStatus)) {
      actions.push({
        id: BulkPrepareToShipmentService.ID,
        name: "Prepare to Shipment",
      });
    }

    if (this.isBulkReassingToFactoryActionAvailable(plushieStatus)) {
      actions.push({
        id: BulkReassignToFactoryService.ID,
        name: "Re-assign to Factory",
      });
    }

    if (this.isBulkExportAsExcelActionAvailable(plushieStatus)) {
      actions.push({
        id: BulkExportAsExcelService.ID,
        name: "Export as Excel",
      });
    }

    if (this.isBulkPlushiesArtworksDownloadActionAvailable(plushieStatus)) {
      actions.push({
        id: BulkPlushiesArtworksDownloadService.ID,
        name: "Download Artworks",
      });
    }

    return actions;
  }

  private isCreateBulkShipmentActionAvailable(plushieStatus: string): boolean {
    if (plushieStatus !== PlushieStatusValue.READY_FOR_SHIPMENT) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_SHIPMENTS_MANAGE);
  }

  private async isBulkMoveToDesignActionAvailable(
    plushieStatus: string
  ): Promise<boolean> {
    if (plushieStatus !== PlushieStatusValue.READY_FOR_PRODUCTION) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.PLUSHIE_MOVE_TO_DESIGN)) {
      return false;
    }

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return true;
    }

    const isUserUnderStrictMode = await this.fIsUserUnderStrictModeResolver.resolve();
    const isStrictPullModeEnabled = await this.fIsPullModeEnabledResolver.resolve();

    if (isUserUnderStrictMode && isStrictPullModeEnabled) {
      return false;
    }

    return this.isUsersFactoryRestrictedModeStatusIsNormal(user);
  }

  private async isBulkMoveToProductionActionAvailable(
    plushieStatus: PlushieStatusValue
  ): Promise<boolean> {
    if (
      ![PlushieStatusValue.IN_DESIGN, PlushieStatusValue.REWORK].includes(
        plushieStatus
      )
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.PLUSHIE_MOVE_TO_PRODUCTION)) {
      return false;
    }

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return true;
    }

    return this.isUsersFactoryRestrictedModeStatusIsNormal(user);
  }

  private isBulkPlushiesArtworksDownloadActionAvailable(
    plushieStatus: PlushieStatusValue
  ): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      user.role === AuthenticatedUser.ROLE_FACTORY &&
      plushieStatus === PlushieStatusValue.READY_FOR_PRODUCTION
    ) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_IMAGES_VIEW);
  }

  private async isBulkPrepareToShipmentActionAvailable(
    plushieStatus: PlushieStatusValue
  ): Promise<boolean> {
    if (plushieStatus !== PlushieStatusValue.IN_PRODUCTION) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.PLUSHIE_PREPARE_TO_SHIPMENT)) {
      return false;
    }

    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return true;
    }

    return this.isUsersFactoryRestrictedModeStatusIsNormal(user);
  }

  private isBulkReassingToFactoryActionAvailable(
    plushieStatus: PlushieStatusValue
  ): boolean {
    if (
      ![
        PlushieStatusValue.READY_FOR_PRODUCTION,
        PlushieStatusValue.IN_DESIGN,
        PlushieStatusValue.IN_PRODUCTION,
      ].includes(plushieStatus)
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_ASSIGNMENTS_MANAGE);
  }

  private isBulkExportAsExcelActionAvailable(
    plushieStatus: PlushieStatusValue
  ): boolean {
    if (
      [
        PlushieStatusValue.READY_FOR_PRODUCTION,
        PlushieStatusValue.READY_FOR_SHIPMENT,
      ].includes(plushieStatus)
    ) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PLUSHIE_DOCUMENT_EXPORTS_VIEW
    );
  }

  private async isUsersFactoryRestrictedModeStatusIsNormal(
    user: AuthenticatedUser
  ): Promise<boolean> {
    if (user.role !== AuthenticatedUser.ROLE_FACTORY) {
      return false;
    }

    const userFactoryRelation = await this.fFactoryStore.loadUserFactoryRelationByUserId(
      { userId: user.id }
    );

    if (!userFactoryRelation) {
      return false;
    }

    const restrictedModeStatus = await this.fFactoryRestrictedModeStore.loadFactoryRestrictedModeStatusByFactoryId(
      userFactoryRelation.factory
    );

    if (!restrictedModeStatus) {
      return false;
    }

    return restrictedModeStatus.status === RestrictedModeStatus.NORMAL;
  }
}
