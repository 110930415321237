









































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import Product from "@/modules/plushie/product.model";
import PlushieStore from "@/modules/plushie/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import ProductAllocationEditItem from "./EditForm/EditItem.vue";

import FactoryStore from "../../store";
import Factory from "../../factory.model";
import ProductAllocation from "../../product-allocation.model";
import { ProductAllocationSlotTypeValue } from "../../product-allication-slot-type.value";

interface EditItem {
  value: ProductAllocation;
  isNew: boolean;
  id: string;
}

@Component({
  components: {
    FormErrors,
    LoadingSpinner,
    ProductAllocationEditItem,
    SubmitButton,
  },
})
export default class ProductAllocationEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly slotType!: ProductAllocationSlotTypeValue;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fIsLoading = false;

  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  private fAllocationItems: EditItem[] = [];

  get getProductDistributionSum(): (productId: string) => number {
    return (productId: string) => {
      return this.fAllocationItems
        .filter((item) => item.value.product === productId)
        .reduce((sum, current) => {
          return sum + +current.value.distributionPercent;
        }, 0);
    };
  }

  get isDistributionValid(): (productId: string) => boolean {
    return (productId: string) => {
      return (
        this.getProductDistributionSum(productId) === 0 ||
        this.getProductDistributionSum(productId) === 100
      );
    };
  }

  get getEditItemByFactoryAndProduct(): (
    factoryId: string,
    productId: string
  ) => EditItem | undefined {
    return (factoryId: string, productId: string) => {
      return this.fAllocationItems.find(
        (item) =>
          item.value.factory === factoryId && item.value.product === productId
      );
    };
  }

  get factories(): Factory[] {
    return this.fFactoryDataStore.activeFactoriesList;
  }

  get products(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get allocationItems(): EditItem[] {
    return this.fAllocationItems;
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public onItemChange(value: ProductAllocation): void {
    const item = this.getEditItemByFactoryAndProduct(
      value.factory,
      value.product
    );

    if (!item) {
      return;
    }

    item.value = value;
  }

  protected async performSubmit(): Promise<void> {
    this.products.forEach((product) => {
      if (!this.isDistributionValid(product.id)) {
        throw new Error("Workload is not correct. Please check rows sum");
      }
    });

    const values = this.fAllocationItems.map((item) => item.value);

    const items = await this.fFactoryDataStore.saveProductAllocations(values);
    this.fAllocationItems = this.createEditItems(items);
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fFactoryDataStore.loadProductAllocations(false),
        this.fFactoryDataStore.loadFactories(),
        this.fPlushieDataStore.loadProducts(),
      ]);

      const allocations = this.fFactoryDataStore.getProductAllocationsBySlotType(
        this.slotType
      );

      this.fAllocationItems = this.createEditItems(allocations);
    } finally {
      this.fIsLoading = false;
    }
  }

  private createEditItems(values: ProductAllocation[]): EditItem[] {
    const allocationItems: EditItem[] = [];

    this.products.forEach((product) => {
      this.factories.forEach((factory) => {
        const item = values.find(
          (value) =>
            value.product === product.id &&
            value.factory === factory.id &&
            value.slotType === this.slotType
        );

        if (!item) {
          const value = new ProductAllocation(
            this.fIdGenerator.getId(),
            factory.id,
            product.id,
            0,
            this.slotType
          );

          allocationItems.push({
            value: value,
            id: value.id,
            isNew: true,
          });

          return;
        }

        allocationItems.push({
          value: item,
          id: item.id,
          isNew: false,
        });
      });
    });

    return allocationItems;
  }

  @Watch("slotType", { immediate: false })
  private _onSlotTypeChange() {
    const allocations = this.fFactoryDataStore.getProductAllocationsBySlotType(
      this.slotType
    );
    this.fAllocationItems = this.createEditItems(allocations);
  }
}
