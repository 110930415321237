import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Setting implements MutableValue {
  public static readonly FACTORY_RESTRICTED_MODE_DAYS_BEFORE_WARNING_ALERT =
    "factory_restricted_mode_days_before_warning_alert";

  public static readonly FACTORY_RESTRICTED_MODE_DAYS_BEFORE_LOCKDOWN_ALERT =
    "factory_restricted_mode_days_before_lockdown_alert";

  public static readonly BULK_PROJECT_BATCH_TURNAROUND_DAYS_COUNT =
    "bulk_project_batch_turnaround_days_count";

  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fName: string,
    private fValue: string | null
  ) {
    [fId, fName, fValue].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get name(): string {
    return this.fName;
  }

  get value(): string | null {
    return this.fValue;
  }

  get createAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
