import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

import PreviewCount from "../preview-count.model";
import PreviewCountUpdate from "../preview-count-update.interface";
import AutoApprovementExclude from "../auto-approvement-exclude.model";

const name = "CustomerPreviewStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    previewCount: {},
    autoApprovementExclude: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class CustomerPreviewStore extends VuexModule {
  previewCount: Dictionary<PreviewCount> = {};
  autoApprovementExclude: Dictionary<AutoApprovementExclude> = {};

  // ################################### Auto Approvement Excludes #########################################

  get getAutoApprovementExcludeById(): (
    id: string
  ) => AutoApprovementExclude | undefined {
    return (id: string) => {
      return this.autoApprovementExclude[id];
    };
  }

  @Mutation
  updateAutoApprovementExclude(
    autoApprovementExclude: AutoApprovementExclude
  ): void {
    Vue.set(
      this.autoApprovementExclude,
      autoApprovementExclude.id,
      autoApprovementExclude
    );
  }

  @Mutation
  removeAutoApprovementExclude(id: string): void {
    if (!this.autoApprovementExclude[id]) {
      return;
    }

    Vue.delete(this.autoApprovementExclude, id);
  }

  @Action({ rawError: true })
  async saveAutoApprovementExclude(
    autoApprovementExclude: AutoApprovementExclude
  ): Promise<AutoApprovementExclude> {
    const result = await DIContainer.AutoApprovementExcludeRepository.save(
      autoApprovementExclude
    );

    this.updateAutoApprovementExclude(result);

    return result;
  }

  @Action({ rawError: true })
  async deleteAutoApprovementExclude(id: string): Promise<void> {
    await DIContainer.AutoApprovementExcludeRepository.deleteById(id);

    this.removeAutoApprovementExclude(id);
  }

  @Action({ rawError: true })
  async loadAutoApprovementExcludeById(
    id: string
  ): Promise<AutoApprovementExclude | undefined> {
    const result = await DIContainer.AutoApprovementExcludeRepository.loadByPlushieId(
      id
    );

    if (!result) {
      this.removeAutoApprovementExclude(id);
      return;
    }

    this.updateAutoApprovementExclude(result);

    return result;
  }

  // ################################### Preview Count #########################################

  get getPreviewCountByPlushieId(): (
    plushieId: string
  ) => PreviewCount | undefined {
    return (plushieId: string) => this.previewCount[plushieId];
  }

  @Mutation
  updatePreviewCount(payload: PreviewCount): void {
    Vue.set(this.previewCount, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadPreviewCountByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PreviewCount | undefined> {
    if (useCache && this.previewCount[plushieId]) {
      return this.previewCount[plushieId];
    }

    const previewCount = await DIContainer.CustomerPreviewCountRepository.getById(
      plushieId
    );

    if (previewCount) {
      this.updatePreviewCount(previewCount);
    }

    return previewCount;
  }

  @Action({ rawError: true })
  async updatePreviewCountParams(item: PreviewCountUpdate): Promise<void> {
    this.context.commit("markPlushieAsProcessing", item.id);

    try {
      const previewCount = await DIContainer.CustomerPreviewCountRepository.updatePreviewCount(
        item
      );

      this.updatePreviewCount(previewCount);

      await this.context.dispatch("onPreviewCountUpdated", previewCount.id);
    } finally {
      this.context.commit("removePlushieProcessingMark", item.id);
    }
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (this.previewCount[plushieId]) {
      await this.loadPreviewCountByPlushieId({
        plushieId,
        useCache: false,
      });
    }

    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
