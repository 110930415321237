

















































import Vue from "vue";
import { Component, Prop, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import ErrorConverterService from "@/modules/forms/error-converter.service";
import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import FactoryInvoiceStore from "../store";
import PenaltyBypass from "../penalty-bypass.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    LoadingSpinner,
  },
})
export default class PlushiePenaltyManagementForm extends Vue {
  @Prop({ required: true })
  public readonly plushieId?: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fErrorsList: string[] = [];

  private fIsUpdating = false;
  private fIsLoading = true;

  get errorsList(): string[] {
    return this.fErrorsList;
  }

  get isDisabled(): boolean {
    return this.fIsUpdating;
  }

  get isPenaltyBypassExists(): boolean {
    return !!this.penaltyBypass;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isUpdating(): boolean {
    return this.fIsUpdating;
  }

  get penaltyBypass(): PenaltyBypass | undefined {
    if (!this.plushieId) {
      return;
    }

    return this.fFactoryInvoiceDataStore.getPenaltyBypassByPlushieId(
      this.plushieId
    );
  }

  get penaltyBypassTypes(): DirectoryValue[] {
    return this.fFactoryInvoiceDataStore.penaltyBypassTypesList;
  }

  constructor() {
    super();
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public async onTypeButtonClick(typeId: string | undefined): Promise<void> {
    this.fErrorsList = [];

    if (!this.plushieId) {
      return;
    }

    this.fIsUpdating = true;

    try {
      if (typeId) {
        await this.fFactoryInvoiceDataStore.savePenaltyBypass(
          new PenaltyBypass(this.fIdGenerator.getId(), this.plushieId, typeId)
        );

        return;
      }

      const penaltyByPass = this.fFactoryInvoiceDataStore.getPenaltyBypassByPlushieId(
        this.plushieId
      );

      if (!penaltyByPass) {
        return;
      }

      await this.fFactoryInvoiceDataStore.deletePenaltyBypass(penaltyByPass);
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      this.fErrorsList.push(...errors);
    } finally {
      this.fIsUpdating = false;
    }
  }

  private async loadData(plushieId: string): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fFactoryInvoiceDataStore.loadPenaltyBypassByPlushieId(plushieId),
        this.fFactoryInvoiceDataStore.loadPenaltyBypassTypes(),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }

  @Watch("plushieId", { immediate: true })
  private _onPlushieIdChange() {
    if (this.plushieId === undefined) {
      return;
    }

    void this.loadData(this.plushieId);
  }
}
