import AverageQaRoundsApiResponse from "./average-qa-rounds-api-response.interface";

export default function isAverageQaRoundsApiResponse(
  arg: unknown
): arg is AverageQaRoundsApiResponse {
  if (!(arg instanceof Object)) {
    return false;
  }

  const requiredKeys = [
    "weekAvgRounds",
    "monthAvgRounds",
    "prevWeekAvgRounds",
    "prevMonthAvgRounds",
  ];
  const tempArg = arg as Record<string, number>;

  for (const key of requiredKeys) {
    if (typeof tempArg[key] !== "number") {
      return false;
    }
  }

  return true;
}
