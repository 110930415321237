import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryInvoice from "./factory-invoice.model";

export default class FactoryInvoiceRepository extends BasicRepository<FactoryInvoice> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<FactoryInvoice>,
    responseParser: ApiCollectionResponseParserService<FactoryInvoice>
  ) {
    super("invoices", api, objectBuilder, responseParser);
  }

  public async getByShipmentIds(
    shipmentIds: string[]
  ): Promise<ResourceCollection<FactoryInvoice>> {
    return this.fetchList({ shipmentId: shipmentIds }, 1, shipmentIds.length);
  }

  public async publishInvoice(
    invoice: FactoryInvoice,
    vendorNotes?: string
  ): Promise<FactoryInvoice> {
    const data: Dictionary<string> = {
      id: invoice.id,
    };

    if (vendorNotes) {
      data.vendorNotes = vendorNotes;
    }

    const response = await this.api.post(
      `${this.resourceName}/published`,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async approveInvoice(
    invoice: FactoryInvoice,
    buyerNotes?: string
  ): Promise<FactoryInvoice> {
    const data: Dictionary<string> = {
      id: invoice.id,
    };

    if (buyerNotes) {
      data.buyerNotes = buyerNotes;
    }

    const response = await this.api.post(`${this.resourceName}/approved`, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async getPdfByInvoiceId(invoiceId: string): Promise<any> {
    return this.api.get(
      `${this.resourceName}/${invoiceId}/pdf`,
      undefined,
      undefined,
      { responseType: "blob" }
    );
  }
}
