import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import HolidayPeriod from "./holiday-period.model";

export default class HolidayPeriodFactory
  implements ObjectBuilderInterface<HolidayPeriod> {
  public buildFromJSON(data: { [key: string]: any }): HolidayPeriod {
    const value = new HolidayPeriod(data.id.toString(), data.name);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    return value;
  }
}
