export default class TagSearchResult {
  public isNew = true;

  constructor(private fPlushies: string[]) {
    [fPlushies].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get plushies(): string[] {
    return this.fPlushies;
  }
}
