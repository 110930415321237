import jQuery from "jquery";
import Raphael from "raphael";

import initSketchpadPlugin, {
  ISketchpad,
} from "@/assets/raphael/raphael.sketchpad.js";

import { MarkupData } from "./markup-data";

initSketchpadPlugin(Raphael);

class Color {
  public static readonly PINK = "#e4207c";
  public static readonly WHITE = "#ffffff";
  public static readonly BLUE = "#2fb9f3";
  public static readonly GREEN = "#8eba4c";
}

enum Width {
  Thin = 5,
  Normal = 10,
  Thick = 20,
}

enum Size {
  Small = 10,
  Medium = 20,
  Large = 30,
}

const VIEWBOX_SIZE = 1920;

export class EditMarkup {
  private fWindow: Window;
  private fArtwork: JQuery<HTMLImageElement>;
  private fContainer: JQuery;
  private fSketchpad: ISketchpad;
  private fTabContextSelector: JQuery;
  private fMarkup?: MarkupData;
  private fViewBoxHeight: number;
  private fViewBoxWidth: number;

  get markup(): MarkupData | undefined {
    return this.fMarkup;
  }

  constructor(
    artwork: JQuery<HTMLImageElement>,
    container: JQuery,
    window: Window,
    initialData?: MarkupData
  ) {
    const sketchpadContainer = artwork.closest("._artwork-container");

    const paper = Raphael(
      sketchpadContainer[0],
      artwork.width() || 0,
      artwork.height() || 0
    );

    let viewBoxHeight: number;
    let viewBoxWidth: number;

    if (artwork[0].naturalWidth >= artwork[0].naturalHeight) {
      viewBoxWidth = VIEWBOX_SIZE;
      viewBoxHeight =
        VIEWBOX_SIZE * (artwork[0].naturalHeight / artwork[0].naturalWidth);
    } else {
      viewBoxHeight = VIEWBOX_SIZE;
      viewBoxWidth =
        VIEWBOX_SIZE * (artwork[0].naturalWidth / artwork[0].naturalHeight);
    }

    if (initialData && initialData.height && initialData.width) {
      viewBoxHeight = initialData.height;
      viewBoxWidth = initialData.width;
    }

    this.fViewBoxHeight = viewBoxHeight;
    this.fViewBoxWidth = viewBoxWidth;

    paper.setViewBox(0, 0, viewBoxWidth, viewBoxHeight);

    this.fArtwork = artwork;
    this.fContainer = container;
    this.fWindow = window;

    this.fSketchpad = Raphael.sketchpad(paper);
    this.fTabContextSelector = jQuery("._sketchpad-controls-container");

    this.setDefaults();
    this.loadStrokes(initialData);

    this.fSketchpad.change(() => {
      this.markupOnChange();
    });

    this.addToolbarElementHandler("._color-item", (elem: JQuery) =>
      this.colorControlsHandler(elem)
    );
    this.addToolbarElementHandler("._width-item", (elem: JQuery) =>
      this.widthControlsHandler(elem)
    );
    this.addToolbarElementHandler("._font-item", (elem: JQuery) =>
      this.fontControlsHandler(elem)
    );
    this.addToolbarElementHandler("._tool-item", (elem: JQuery) =>
      this.toolControlsHandler(elem)
    );
    this.addToolbarElementHandler("._undo", () => this.fSketchpad.undo());
    this.addToolbarElementHandler("._redo", () => this.fSketchpad.redo());
    this.addToolbarElementHandler("._clear", () => this.fSketchpad.clear());

    this.initToolbarDropdownMode();
  }

  public getSvgContent(): string {
    return this.fSketchpad.paper().canvas.outerHTML;
  }

  private setDefaults(): void {
    this.fSketchpad.editing(true);

    this.fSketchpad.pen().color(Color.PINK).width(Width.Normal);
    this.fSketchpad.text().color(Color.PINK).size(Size.Medium);
  }

  private loadStrokes(markupData?: MarkupData) {
    if (!markupData || markupData.strokes === undefined) {
      return;
    }

    this.fSketchpad.json(markupData.strokes);
  }

  private markupOnChange() {
    const data: MarkupData = {
      height: this.fViewBoxHeight,
      strokes: this.fSketchpad.json(),
      width: this.fViewBoxWidth,
    };

    this.fMarkup = data;
  }

  private addToolbarElementHandler(
    selector: string,
    handler: (elem: JQuery) => void
  ) {
    jQuery(selector, this.fTabContextSelector).on(
      "click",
      (e: JQueryEventObject) => {
        jQuery(selector, this.fTabContextSelector).removeClass("-selected");
        jQuery(e.target).addClass("-selected");
        handler(jQuery(e.target as HTMLElement));
      }
    );
  }

  private colorControlsHandler(elem: JQuery): void {
    const pen = this.fSketchpad.pen();
    const text = this.fSketchpad.text();
    const color = elem.data("color");

    switch (color) {
      case "pink":
        pen.color(Color.PINK);
        text.color(Color.PINK);
        break;
      case "white":
        pen.color(Color.WHITE);
        text.color(Color.WHITE);
        break;
      case "blue":
        pen.color(Color.BLUE);
        text.color(Color.BLUE);
        break;
      case "green":
        pen.color(Color.GREEN);
        text.color(Color.GREEN);
        break;
    }
  }

  private widthControlsHandler(elem: JQuery): void {
    const pen = this.fSketchpad.pen();
    const width = elem.data("width");

    switch (width) {
      case "thin":
        pen.width(Width.Thin);
        break;
      case "normal":
        pen.width(Width.Normal);
        break;
      case "thick":
        pen.width(Width.Thick);
        break;
    }
  }

  private fontControlsHandler(elem: JQuery): void {
    const text = this.fSketchpad.text();
    const fontSize = elem.data("fontSize");

    switch (fontSize) {
      case "small":
        text.size(Size.Small);
        break;
      case "medium":
        text.size(Size.Medium);
        break;
      case "large":
        text.size(Size.Large);
        break;
    }
  }

  private toolControlsHandler(elem: JQuery): void {
    const tool = elem.data("tool");

    switch (tool) {
      case "curve":
        this.fSketchpad.editing(true);
        break;
      case "line":
      case "text":
      case "erase":
        this.fSketchpad.editing(tool);
        break;
    }
  }

  private initToolbarDropdownMode(): void {
    jQuery("._control-item", this.fTabContextSelector).on(
      "click",
      (e: JQueryEventObject) => {
        jQuery(e.target)
          .closest("._section")
          .toggleClass("-selection-mode")
          .siblings()
          .removeClass("-selection-mode");
      }
    );

    jQuery(this.fWindow).on("click", (e: JQueryEventObject) => {
      if (jQuery(e.target).hasClass("_control-item")) {
        return;
      }

      jQuery("._section", this.fContainer).removeClass("-selection-mode");
    });
  }
}
