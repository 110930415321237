import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import MetaRoleResource from "./meta-role-resource.model";

export default class MetaRoleResourceFactory
  implements ObjectBuilderInterface<MetaRoleResource> {
  public buildFromJSON(data: { [key: string]: any }): MetaRoleResource {
    const value = new MetaRoleResource(data.id, data.metaRole, data.resource);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
