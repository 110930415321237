export enum FileProcessingImageType {
  Artwork = 1,
  QaPhoto = 2,
  BodyPartImage = 3,
  PlushieAlert = 4,
  MessageAttachment = 5,
  AirwayBill = 6,
  QaVideo = 7,
  QaVideoThumb = 8,
  PlushieAttachment = 9,
}
