














import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";

import FactoryInvoiceStore from "../../store";
import FactoryInvoice from "../../factory-invoice.model";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class CreatePaymentsFormSummary extends Vue {
  @Prop({ required: true })
  public readonly invoiceIds!: string[];

  private fFactoryInvoiceDateStore: FactoryInvoiceStore;
  private fIsLoading = true;

  get invoices(): FactoryInvoice[] {
    return this.fFactoryInvoiceDateStore.getFactoryInvoicesByIds(
      this.invoiceIds
    );
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get totalAmount(): number {
    let total = 0;

    this.invoices.forEach((invoice) => {
      total += invoice.total || 0;
    });

    return total;
  }

  constructor() {
    super();

    this.fFactoryInvoiceDateStore = getModule(FactoryInvoiceStore, dataStore);
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      await this.fFactoryInvoiceDateStore.loadFactoryInvoicesByIds({
        invoiceIds: this.invoiceIds,
      });
    } finally {
      this.fIsLoading = false;
    }
  }
}
