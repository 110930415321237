import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import BulkProjectPlushieRelation from "./bulk-project-plushie-relation.model";
import { ResourceCollection } from "../api/resource.collection";
import { BulkProjectPlushieTypeValue } from "./bulk-project-plushie-type.value";

export default class BulkProjectPlushieRelationRepository extends BasicRepository<BulkProjectPlushieRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BulkProjectPlushieRelation>,
    responseParser: ApiCollectionResponseParserService<BulkProjectPlushieRelation>
  ) {
    super("bulk_project_plushie_relations", api, objectBuilder, responseParser);
  }

  public fetchListByBulkProjectId(
    bulkProjectId: string
  ): Promise<ResourceCollection<BulkProjectPlushieRelation>> {
    return this.fetchList(
      {
        bulkProject: bulkProjectId,
      },
      1,
      999
    );
  }

  public async fetchByPlushieId(
    plushieId: string
  ): Promise<BulkProjectPlushieRelation | undefined> {
    const response = await this.fetchList(
      {
        plushie: plushieId,
      },
      1,
      1
    );

    const items = response.getItems();

    return items.length ? items[0] : undefined;
  }

  public async fetchListByBulkProjectIdAndPlushieType(
    bulkProjectId: string,
    plushieType: BulkProjectPlushieTypeValue
  ): Promise<BulkProjectPlushieRelation[]> {
    const response = await this.fetchList(
      {
        bulkProject: bulkProjectId,
        bulkProjectPlushieType: plushieType,
      },
      1,
      999
    );

    return response.getItems();
  }
}
