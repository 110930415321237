import { Dictionary } from "@/lib/Dictionary.type";
import ApiService from "@/modules/api/api.service";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";

export default class PlushiesGridExportJobRepository {
  private readonly fResourceName = "plushies_grid_export_jobs";

  constructor(protected api: ApiService) {}

  public async createPlushiesGridExportJob(
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<string> {
    const orderedParams: Dictionary<string> = {};

    if (order != null) {
      order.forEach((item) => {
        orderedParams[item[0]] = item[1];
      });
    }

    const response = await this.api.post(`${this.fResourceName}`, {
      format: "xls",
      filter,
      order: orderedParams,
    });

    return (response.data as { downloadUrl: string }).downloadUrl;
  }
}
