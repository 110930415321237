import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import Plushie from "@/modules/plushie/plushie.model";

export default class BatchCreationRequestRepository {
  private readonly fResourceName = "bulk_projects/batch_creation_requests";

  constructor(
    protected api: ApiService,
    private plushieBuilder: ObjectBuilderInterface<Plushie>
  ) {}

  public async createBatch({
    bulkProjectId,
    quantity,
    size,
    designerComment,
    notes,
    deadlineDate,
    useCustomSewInLabel,
    customSewInLabelStorageItemId,
  }: {
    bulkProjectId: string;
    quantity: number;
    size: number;
    designerComment: string;
    notes: string;
    deadlineDate: string;
    useCustomSewInLabel: boolean;
    customSewInLabelStorageItemId?: string;
  }): Promise<Plushie> {
    const result = await this.api.post(this.fResourceName, {
      bulkProjectId,
      quantity,
      size,
      designerComment,
      notes,
      deadlineDate,
      useCustomSewInLabel,
      customSewInLabelStorageItemId,
    });

    return this.plushieBuilder.buildFromJSON(result.data);
  }
}
