



























import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import StrictCapacityModeStore from "../../store";
import { PlushieStatusValue } from "../../../plushie/plushie-status.value";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class StrictCapacityModeViewForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly factoryId!: string;

  private fIsLoading = true;

  private fFactoryStrictCapacityModeStore: StrictCapacityModeStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get spotsInDesign(): string {
    const capacity = this.fFactoryStrictCapacityModeStore.getFactoryPlushieStatusCapacityForStatus(
      this.factoryId,
      PlushieStatusValue.IN_DESIGN
    );

    if (!capacity) {
      return "";
    }

    return capacity.value.toString();
  }

  get spotsInProduction(): string {
    const capacity = this.fFactoryStrictCapacityModeStore.getFactoryPlushieStatusCapacityForStatus(
      this.factoryId,
      PlushieStatusValue.IN_PRODUCTION
    );

    if (!capacity) {
      return "";
    }

    return capacity.value.toString();
  }

  constructor() {
    super();

    this.fFactoryStrictCapacityModeStore = getModule(
      StrictCapacityModeStore,
      dataStore
    );
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      await this.fFactoryStrictCapacityModeStore.loadFactoryPlushieStatusCapacitiesByFactoryId(
        { factoryId: this.factoryId }
      );
    } finally {
      this.fIsLoading = false;
    }
  }
}
