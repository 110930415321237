



















































import Vue from "vue";
import { Component, Inject, Watch } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import Factory from "@/modules/factory/factory.model";
import { Dictionary } from "@/lib/Dictionary.type";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import { PriorityBonus } from "@/modules/plushie-priority/priority-bonus";
import { UiPersisterStore } from "@/modules/ui-persister/store";
import PlushieStore from "@/modules/plushie/store";

import ProductionTimeAlertRepository from "../production-time-alert.repository";
import ProductionTimeAlert from "../production-time-alert.model";

interface TableRow {
  plushieId: string;
  plushieStoreItemId: string;
  estimatedShipmentDate: string;
  dueDate: string;
  factory: string;
  status: string;
  priorityBonus: string;
}

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class ProductionTimeAlertReport extends Vue {
  @Inject("ProductionTimeAlertRepository")
  private fProductionTimeAlertRepository!: ProductionTimeAlertRepository;

  private fIsLoading = false;
  private fShowErrorMessage = false;
  private fProductionTimeAlertsList: ProductionTimeAlert[] = [];

  private fFactoryDataStore!: FactoryStore;
  private fPlushieStore!: PlushieStore;
  private fUiPersisterStore!: UiPersisterStore;

  get columns(): string[] {
    return [
      "plushieStoreItemId",
      "dueDate",
      "estimatedShipmentDate",
      "factory",
      "status",
      "priorityBonus",
    ];
  }

  get dayOptions(): number[] {
    return [1, 2, 3, 4, 5, 6, 7, 10, 14, 21, 28, 30, 60];
  }

  get factoryDictionary(): Dictionary<Factory> {
    return this.fFactoryDataStore.factory;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get options(): TableOptions {
    return {
      filterable: false,
      orderBy: {
        column: "dueDate",
        ascending: true,
      },
      headings: {
        plushieStoreItemId: "SKU",
        estimatedShipmentDate: "Promised Date",
        dueDate: "Due Date",
        status: "Budsies Status",
        priorityBonus: "Rush Type",
      },
      columnsDisplay: {
        factory: "min_tablet",
        priorityBonus: "min_tablet",
        estimatedShipmentDate: "min_tabletL",
      },
      initialPage: 1,
      perPage: 10,
      perPageValues: [10, 20, 30, 40, 50],
    };
  }

  get remainingDaysCount(): number {
    return this.fUiPersisterStore.productionTimeAlertDaysCount;
  }

  set remainingDaysCount(value: number) {
    this.fUiPersisterStore.updateProductionTimeAlertDaysCount(value);
  }

  get showErrorMessage(): boolean {
    return this.fShowErrorMessage;
  }

  get tableData(): TableRow[] {
    const data: TableRow[] = [];

    this.fProductionTimeAlertsList.forEach((item) => {
      const factoryName = this.factoryDictionary[item.factoryId].name;
      const row: TableRow = {
        plushieId: item.plushieId,
        plushieStoreItemId: item.plushieStoreItemId,
        estimatedShipmentDate: item.estimatedShipmentDate,
        dueDate: item.dueDate,
        factory: factoryName ? factoryName : "Factory",
        status: this.getStatusTitleById(item.statusId),
        priorityBonus: this.getPriorityBonusClassById(item.priorityBonusId),
      };

      data.push(row);
    });

    return data;
  }

  constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieStore = getModule(PlushieStore, dataStore);
    this.fUiPersisterStore = getModule(UiPersisterStore, dataStore);
  }

  protected created(): void {
    void this.loadReportData();
  }

  private getPriorityBonusClassById(id: number): string {
    if (id === PriorityBonus.SUPER_RUSH) {
      return "-super-rush";
    } else {
      return "-rush";
    }
  }

  private getStatusTitleById(id: PlushieStatusValue): string {
    const plushieStatus = this.fPlushieStore.getPlushieStatusById(id);
    return plushieStatus ? plushieStatus.name : "Unknown Status";
  }

  private async loadReportData(): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.fShowErrorMessage = false;
    this.fIsLoading = true;

    try {
      [this.fProductionTimeAlertsList] = await Promise.all([
        this.fProductionTimeAlertRepository.fetchProductionTimeAlertStats(
          this.remainingDaysCount
        ),
        this.fFactoryDataStore.loadFactories(),
        this.fPlushieStore.loadPlushieStatuses(),
      ]);
    } catch (error) {
      this.fShowErrorMessage = true;
      throw error;
    } finally {
      this.fIsLoading = false;
    }
  }

  @Watch("remainingDaysCount")
  private _onRemainingDaysCountChange() {
    void this.loadReportData();
  }
}
