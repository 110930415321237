

































































import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import FactoryInvoicingSettings from "../../factory-invoicing-settings.model";
import FactoryStore from "../../store";
import FactoryInvoicingModel from "../../factory-invoicing-model";

interface InvoicingModelOption {
  value: string;
  title: string;
}

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class FactoryInvoicingSettingsEdit extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop()
  public invoicingSettings?: FactoryInvoicingSettings;

  public ignoreQuantity = false;
  public invoicingPeriod?: number;
  public invoicingModel?: string;

  private fFactoryDataStore!: FactoryStore;

  get invoicingModelOptions(): InvoicingModelOption[] {
    const options: InvoicingModelOption[] = [];

    Object.values(FactoryInvoicingModel).forEach((model) => {
      switch (model) {
        case FactoryInvoicingModel.PERIODICAL:
          options.push({
            value: FactoryInvoicingModel.PERIODICAL,
            title: "Periodical",
          });
          break;
        case FactoryInvoicingModel.BY_SHIPMENTS_TO_HQ:
          options.push({
            value: FactoryInvoicingModel.BY_SHIPMENTS_TO_HQ,
            title: "By Shipments to the HQ",
          });
      }
    });

    return options;
  }

  get isDisabled(): boolean {
    return this.fIsDisabled;
  }

  get submitButtonText(): string {
    return this.invoicingSettings ? "Update" : "Save";
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  protected created(): void {
    this.resetValues();

    if (this.invoicingSettings) {
      this.fillValues(this.invoicingSettings);
    }
  }

  protected async performSubmit(): Promise<void> {
    const invoicingSettings = this.generateInvoicingSettingsObject();

    await this.fFactoryDataStore.saveFactoryInvoicingSettings(
      invoicingSettings
    );

    this.resetValues();

    if (this.invoicingSettings) {
      this.fillValues(this.invoicingSettings);
    }
  }

  private fillValues(invoicingSettings: FactoryInvoicingSettings): void {
    this.invoicingModel = invoicingSettings.invoicingModel;
    this.invoicingPeriod = invoicingSettings.invoicingPeriod;
    this.ignoreQuantity = invoicingSettings.isIgnoreQuantity;
  }

  private generateInvoicingSettingsObject(): FactoryInvoicingSettings {
    if (!this.invoicingModel || this.invoicingPeriod === undefined) {
      throw new Error("Unable to create Factory Invoicing Settings");
    }

    const invoicingSettings = new FactoryInvoicingSettings(
      this.factoryId,
      this.invoicingModel,
      this.ignoreQuantity,
      this.invoicingPeriod
    );

    if (this.invoicingSettings) {
      invoicingSettings.isNew = false;
    }

    return invoicingSettings;
  }

  private resetValues(): void {
    this.invoicingModel = undefined;
    this.invoicingPeriod = undefined;
    this.ignoreQuantity = false;
  }
}
