

























import { Component, Prop, Vue } from "vue-property-decorator";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

@Component({
  name: "ActionButton",
  components: {
    MoonLoader,
  },
})
export default class ActionButton extends Vue {
  @Prop({ required: true })
  public readonly action!: () => Promise<void>;

  @Prop({ required: true })
  public readonly buttonText!: string;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: false })
  public readonly isPrimary!: boolean;

  @Prop({ default: false })
  public isSmall!: boolean;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get spinnerSize(): string {
    return this.isSmall ? "10px" : "14px";
  }

  private fIsLoading = false;

  public async onClick(): Promise<void> {
    if (this.fIsLoading || this.disabled) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.action();
    } catch (e) {
      // Do nothing
    }

    this.fIsLoading = false;
  }
}
