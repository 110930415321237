import { MutableValue } from "../api/mutable-value.interface";

export default class AutoApprovementExclude implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(private fId: string) {
    if (fId === undefined) {
      throw new Error(`Undefined value passed for Id`);
    }
  }

  get id(): string {
    return this.fId;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
