import UserActiveStateProvider from "./user-active-state-provider";

export default class CurrentUserStateReporter {
  private fUserActivityMessageInterval = 0;

  constructor(
    private fSocket: SocketIOClient.Socket,
    private fUserActiveStateProvider: UserActiveStateProvider,
    private fWindow: Window
  ) {
    this.fSocket.on("connect", () => {
      this.sendUserStateMessage();

      this.fUserActivityMessageInterval = this.fWindow.setInterval(
        () => this.sendUserStateMessage(),
        15 * 1000
      );
    });

    this.fUserActiveStateProvider.init();

    this.fUserActiveStateProvider.onStateChange.subscribe(
      this.fUserStateChangeHandler
    );
  }

  public destroy(): void {
    if (this.fUserActivityMessageInterval) {
      this.fWindow.clearInterval(this.fUserActivityMessageInterval);
    }

    this.fUserActiveStateProvider.onStateChange.unsubscribe(
      this.fUserStateChangeHandler
    );

    this.fUserActiveStateProvider.destroy();
  }

  private sendUserStateMessage(): void {
    this.fSocket.emit(
      "pageViewerState",
      this.fUserActiveStateProvider.isActive
    );
  }

  private fUserStateChangeHandler = () => this.sendUserStateMessage();
}
