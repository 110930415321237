import { MutableValue } from "../api/mutable-value.interface";

export default class Projection implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fFactoryId: string,
    private fProductId: number,
    private fValue: number
  ) {
    [fId, fFactoryId, fProductId, fValue].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get factoryId(): string {
    return this.fFactoryId;
  }

  get productId(): number {
    return this.fProductId;
  }

  get value(): number {
    return this.fValue;
  }
}
