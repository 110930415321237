














import { Component, Vue } from "vue-property-decorator";

import PageHeader from "./PageHeader.vue";
import PageFooter from "./PageFooter.vue";

@Component({
  components: {
    PageHeader,
    PageFooter,
  },
  metaInfo: {},
})
export default class PublicPage extends Vue {}
