









































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";

import RejectionReasonEditForm from "./RejectionReasonEditForm.vue";
import RejectionReasonProductsEditForm from "./RejectionReasonProductsEditForm.vue";

import RejectionReasonRepository from "../rejection-reason.repository";
import QualityInspectionStore from "../store";
import RejectionReason from "../rejection-reason.model";

@Component({
  metaInfo() {
    return {
      title: "Rejection Reason Edit",
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    RejectionReasonEditForm,
    RejectionReasonProductsEditForm,
  },
})
export default class RoleEditPage extends Vue {
  @Prop()
  public readonly reasonId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("RejectionReasonRepository")
  private fRejectionReasonRepository!: RejectionReasonRepository;

  private fQualityInspectionDataStore: QualityInspectionStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get rejectionReason(): RejectionReason | undefined {
    return this.fQualityInspectionDataStore.getRejectionReasonById(
      this.reasonId
    );
  }

  public constructor() {
    super();

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  public getReasonsOrder(): Promise<string[]> {
    return this.fRejectionReasonRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  public onCancel(): void {
    void this.$router.push({
      name: "RejectionReasonsListPage",
      query: this.listQuery,
    });
  }

  public onReasonUpdated(): void {
    void this.$router.push({
      name: "RejectionReasonsListPage",
      query: this.listQuery,
    });
  }

  @Watch("reasonId", { immediate: true })
  private async _onReasonIdChange() {
    if (this.reasonId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fQualityInspectionDataStore.loadRejectionReasonById(
        this.reasonId
      );

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
