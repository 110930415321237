import { Route } from "vue-router";

import StrictCapacityModeManagementPage from "./components/ManagementPage.vue";
import StrictCapacityModeEditPage from "./components/EditPage.vue";

export default [
  {
    path: "/factory_strict_capacity_mode/manage",
    name: "FactoryStrictCapacityModeManagementPage",
    component: StrictCapacityModeManagementPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory_strict_capacity_mode/rules",
    name: "FactoryStrictCapacityModeEditPage",
    component: StrictCapacityModeEditPage,
    props: (route: Route): Record<string, string | number | undefined> => {
      const props: Record<string, string | number | undefined> = {};

      if (typeof route.query.factory === "string") {
        props["factoryId"] = route.query.factory;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
];
