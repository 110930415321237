import { render, staticRenderFns } from "./MassActionsCard.vue?vue&type=template&id=0e51a9ee&scoped=true&"
import script from "./MassActionsCard.vue?vue&type=script&lang=ts&"
export * from "./MassActionsCard.vue?vue&type=script&lang=ts&"
import style0 from "./MassActionsCard.vue?vue&type=style&index=0&id=0e51a9ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e51a9ee",
  null
  
)

export default component.exports