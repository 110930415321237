import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Alert implements MutableValue {
  public isNew = true;
  public description?: string;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fTitle: string,
    private fIconStorageId: string,
    private fType: string,
    private fDisplayToFactory: boolean,
    private fNotifyAboutMovements: boolean = false,
    private fRecipientId?: string
  ) {
    [
      fId,
      fTitle,
      fIconStorageId,
      fType,
      fDisplayToFactory,
      fNotifyAboutMovements,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get title(): string {
    return this.fTitle;
  }

  get iconStorageId(): string {
    return this.fIconStorageId;
  }

  get type(): string {
    return this.fType;
  }

  get displayToFactory(): boolean {
    return this.fDisplayToFactory;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }

  get isNotifyAboutMovements(): boolean {
    return this.fNotifyAboutMovements;
  }

  get recipientId(): string | undefined {
    return this.fRecipientId;
  }
}
