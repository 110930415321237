





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";

import PriceConfigurationHistoryRecord from "./History/Record.vue";

import FactoryInvoiceStore from "../../store";
import HistoryRecord from "../../element-price-history-record.model";

@Component({
  components: {
    LoadingSpinner,
    PriceConfigurationHistoryRecord,
  },
})
export default class PriceConfigurationHistory extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  private fIsLoading = true;
  private fAccountDataStore: AccountStore;
  private fFactoryInvoiceDataStore: FactoryInvoiceStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  get historyRecords(): HistoryRecord[] {
    return this.fFactoryInvoiceDataStore.getElementPriceHistoryRecordsByFactoryId(
      this.factoryId
    );
  }

  private async getFactoryData(factoryId: string) {
    const historyRecords = await this.fFactoryInvoiceDataStore.loadElementPriceHistoryRecordsByFactoryId(
      { factoryId }
    );

    if (historyRecords.length < 1) {
      return;
    }

    const userIds: string[] = [];

    historyRecords.forEach((item) => {
      if (!userIds.includes(item.user)) {
        userIds.push(item.user);
      }
    });

    await Promise.all([
      this.fAccountDataStore.loadUserInfosByIds(userIds),
      this.fAccountDataStore.loadUserRolesByUserId({ userIds: userIds }),
      this.fAccountDataStore.loadRoles(),
      this.fFactoryInvoiceDataStore.loadHistoryActions(),
      this.fFactoryInvoiceDataStore.loadElementTypes(),
    ]);
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (!this.factoryId) {
      return;
    }

    this.fIsLoading = true;

    await this.getFactoryData(this.factoryId);

    this.fIsLoading = false;
  }
}
