import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import RemotePrinterConfigurationPage from "./components/RemotePrinterConfigurationPage.vue";
import LabelHeadersListPage from "./components/LabelHeadersListPage.vue";
import LabelHeaderEditPage from "./components/LabelHeaderEditPage.vue";
import LabelHeaderAddPage from "./components/LabelHeaderAddPage.vue";

export default [
  {
    path: "/barcode/remote_print_configuration",
    name: "RemotePrinterConfigurationPage",
    component: RemotePrinterConfigurationPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/barcode/label_headers/list",
    name: "LabelHeadersListPage",
    component: LabelHeadersListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/barcode/label_header/edit",
    name: "LabelHeaderEditPage",
    component: LabelHeaderEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["labelHeaderId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/barcode/label_header/add",
    name: "LabelHeaderAddPage",
    component: LabelHeaderAddPage,
    props: (route: Route) => {
      return listItemPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
];
