import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import UserGroupRelation from "./user-group-relation.model";

export default class UserGroupRelationFactory
  implements ObjectBuilderInterface<UserGroupRelation> {
  public buildFromJSON(data: { [key: string]: string }): UserGroupRelation {
    const value = new UserGroupRelation(data.id, data.userId, data.userGroup);

    value.isNew = false;

    return value;
  }
}
