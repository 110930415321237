import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Role from "./role.model";

export default class RoleRepository extends BasicRepository<Role> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Role>,
    responseParser: ApiCollectionResponseParserService<Role>
  ) {
    super("roles", api, objectBuilder, responseParser);
  }

  protected prepareData(item: Role): Record<string, string> {
    return {
      id: item.id,
      name: item.name,
      metaRole: item.metaRole,
    };
  }
}
