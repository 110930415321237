





























import { Component, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import QuickPlushieSearch from "@/modules/plushie/components/QuickPlushieSearch.vue";

import dataStore from "@/store";
import MessagingStore from "@/modules/messaging/store";
import UserGroupResolverService from "@/modules/messaging/user-group-resolver.service";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";

import AccountMenu from "./AccountMenu.vue";

@Component({
  components: {
    AccountMenu,
    QuickPlushieSearch,
  },
})
export default class PageHeader extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fAuthenticatedUserProvider!: AuthenticatedUserProvider;

  @Inject("MessagingUserGroupResolverService")
  private fUserGroupResolverService!: UserGroupResolverService;

  private fMessagingStore: MessagingStore;

  get unreadMessagesCount(): number {
    return this.fMessagingStore.unreadMessagesList.length;
  }

  public constructor() {
    super();

    this.fMessagingStore = getModule(MessagingStore, dataStore);
  }

  protected created(): void {
    void this.loadUnreadMessages();
  }

  private async loadUnreadMessages(): Promise<void> {
    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return;
    }

    const userGroupId = await this.fUserGroupResolverService.resolveForUser(
      user
    );

    await this.fMessagingStore.loadUnreadMessages({
      userId: user.id,
      userGroupId: userGroupId,
    });
  }
}
