import FutureTurnaroundReport from "./components/FutureTurnaround.vue";
import QualityInspectionReport from "./components/QualityInspectionReport.vue";
import ReviewerReport from "./components/ReviewerReport.vue";
import SnapshotReportPage from "./components/SnapshotReport/Page.vue";
import ThroughputReportPage from "./components/ThroughputReport.vue";
import ProductionEstimateReportPage from "./components/ProductionEstimate/Page.vue";

export default [
  {
    path: "/reports/future-turnaround",
    name: "FutureTurnaroundReport",
    component: FutureTurnaroundReport,
  },
  {
    name: "QualityInspectionReport",
    path: "/reports/quality-inspection",
    component: QualityInspectionReport,
  },
  {
    path: "/reports/reviewer",
    name: "ReviewerReport",
    component: ReviewerReport,
  },
  {
    path: "/reports/snapshots",
    name: "SnapshotReport",
    component: SnapshotReportPage,
  },
  {
    path: "/reports/throughput",
    name: "ThroughputReport",
    component: ThroughputReportPage,
  },
  {
    path: "/reports/production-estimate",
    name: "ProductionEstimateReport",
    component: ProductionEstimateReportPage,
  },
];
