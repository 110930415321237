























import { Component, Prop, Vue } from "vue-property-decorator";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

@Component({
  name: "SubmitButton",
  components: {
    MoonLoader,
  },
})
export default class SubmitButton extends Vue {
  @Prop({ default: "Submit" })
  public readonly buttonText!: string;

  @Prop({ default: false })
  public readonly isLoading!: boolean;

  @Prop({ default: true })
  public isPrimary!: boolean;

  @Prop({ default: false })
  public isSmall!: boolean;

  get spinnerSize(): string {
    return this.isSmall ? "10px" : "14px";
  }

  public onClick(): void {
    this.$emit("click");
  }
}
