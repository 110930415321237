import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BulkShipmentItem from "./bulk-shipment-item.model";

export default class BulkShipmentItemFactory
  implements ObjectBuilderInterface<BulkShipmentItem> {
  public buildFromJSON(data: { [key: string]: any }): BulkShipmentItem {
    const value = new BulkShipmentItem(
      data.id,
      data.shipment,
      data.plushieId,
      +data.qty
    );

    value.isNew = false;

    return value;
  }
}
