import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PenaltyRule from "./penalty-rule.model";

export default class PenaltyRuleFactory
  implements ObjectBuilderInterface<PenaltyRule> {
  public buildFromJSON(data: { [key: string]: any }): PenaltyRule {
    const value = new PenaltyRule(
      data.id,
      data.factoryId,
      data.productId.toString(),
      +data.productionDaysDelay,
      +data.penaltyPercent
    );

    value.isNew = false;

    return value;
  }
}
