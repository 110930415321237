import { Route } from "vue-router";

import PlushiesListPage from "@/modules/plushie/components/PlushiesListPage.vue";
import PlushieViewPage from "@/modules/plushie/components/PlushieViewPage.vue";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

export default [
  {
    path: "/plushie/list",
    name: "PlushiesListPage",
    component: PlushiesListPage,
    props: (route: Route) => {
      const props = listPageUrlParser(route);

      if (typeof route.query.status === "string") {
        props["statusId"] = route.query.status;
      }

      if (typeof route.query.reviewer === "string") {
        props["reviewer"] = route.query.reviewer;
      }

      if (typeof route.query.responsibleUser === "string") {
        props["responsibleUser"] = route.query.responsibleUser;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/plushie/view",
    name: "PlushieViewPage",
    component: PlushieViewPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["plushieId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
];
