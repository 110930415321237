import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Message from "./message.model";

export default class MessageFactory implements ObjectBuilderInterface<Message> {
  public buildFromJSON(data: { [key: string]: any }): Message {
    const value = new Message(
      data.id,
      data.channel,
      data.senderId,
      data.plushieId,
      data.text,
      data.textTranslation,
      data.isRead == true,
      data.isForwardDirection == true,
      new Date(data.createdAt)
    );

    (value as any).fUpdatedAt = new Date(data.updatedAt);
    (value as any).fRecipient = data.recipientId;

    value.isNew = false;

    return value;
  }
}
