import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";

import PlushiePriority from "./plushie-priority.model";

export default class PlushiePriorityRepository extends BasicRepository<PlushiePriority> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushiePriority>,
    responseParser: ApiCollectionResponseParserService<PlushiePriority>
  ) {
    super("plushie_priorities", api, objectBuilder, responseParser);
  }

  public async addPriorityBonusForPlushie(
    plushieId: string,
    bonusId: number
  ): Promise<PlushiePriority> {
    const response = await this.api.post(
      `${this.resourceName}/${plushieId}/bonuses`,
      {
        bonus: bonusId,
      }
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async removePriorityBonusForPlushie(
    plushieId: string,
    bonusId: number
  ): Promise<void> {
    await this.api.delete(
      `${this.resourceName}/${plushieId}/bonuses/${bonusId}`
    );
  }
}
