import { MutableValue } from "@/modules/api/mutable-value.interface";

import { ProductValue } from "../plushie/product.value";

export default class ProductSetting implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fPeriod: string,
    private fProduct: ProductValue,
    private fInternationalDueDate: Date,
    private fDomesticDueDate: Date
  ) {
    [fId, fPeriod, fProduct, fInternationalDueDate, fDomesticDueDate].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get period(): string {
    return this.fPeriod;
  }

  get product(): ProductValue {
    return this.fProduct;
  }

  get internationalDueDate(): Date {
    return this.fInternationalDueDate;
  }

  get domesticDueDate(): Date {
    return this.fDomesticDueDate;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
