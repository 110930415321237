export default class RestrictedModeStatus {
  public static readonly NORMAL = "1";
  public static readonly RESTRICTED = "2";

  constructor(private fFactory: string, private fStatus: string) {
    [fFactory, fStatus].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get factory(): string {
    return this.fFactory;
  }

  get status(): string {
    return this.fStatus;
  }
}
