
























































import { Component, Mixins, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import rootStore from "@/store";
import AccountStore from "@/modules/account/store";
import { MetaRoleValue } from "@/modules/account/meta-role.value";
import UserInfo from "@/modules/account/user-info.model";

import BulkProjectStore from "../../store";
import BulkProject from "../../bulk-project.model";

@Component({
  components: {
    FormErrors,
    FormField,
    MoonLoader,
    SubmitButton,
  },
})
export default class BulkProjectResponsibleUserAssignForm extends Mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly bulkProjectId!: string;

  @Prop({ default: false })
  public readonly showCancelButton!: boolean;

  @Prop({ default: false })
  public readonly compact!: boolean;

  public selectedUser?: string;

  private fAccountStore: AccountStore;
  private fBulkProjectStore: BulkProjectStore;

  private fIsLoading = true;
  private fUsersInfoList: UserInfo[] = [];

  get bulkProject(): BulkProject {
    const bulkProject = this.fBulkProjectStore.getBulkProjectById(
      this.bulkProjectId
    );

    if (!bulkProject) {
      throw new Error("Bulk project is not found");
    }

    return bulkProject;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get submitButtonText(): string {
    if (this.bulkProject.responsibleUserId) {
      return "Save";
    }

    return "Assign Responsible User";
  }

  get userOptions(): UserInfo[] {
    return this.fUsersInfoList;
  }

  constructor() {
    super();

    this.fAccountStore = getModule(AccountStore, rootStore);
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
  }

  protected async created(): Promise<void> {
    await this.loadData();
    this.prefillFormData();
  }

  protected data(): Record<string, string | undefined> {
    return {
      selectedUser: this.selectedUser,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.selectedUser) {
      return;
    }

    await this.fBulkProjectStore.assignResponsibleUserToBulkProject({
      bulkProjectId: this.bulkProjectId,
      responsibleUserId: this.selectedUser,
    });

    this.$emit("submitted");
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    const [usersInfoList] = await Promise.all([
      this.fAccountStore.loadUserInfosByMetaRoleIds([MetaRoleValue.ROLE_STORE]),
      this.fAccountStore.loadDeactivatedUsers(),
    ]);

    this.fUsersInfoList = usersInfoList
      .filter((item) => {
        return (
          item.id === this.bulkProject.responsibleUserId ||
          !this.fAccountStore.deactivatedUser[item.id]
        );
      })
      .sort((a, b) =>
        a.shortName.toLowerCase() > b.shortName.toLowerCase() ? 1 : -1
      );

    this.fIsLoading = false;
  }

  private prefillFormData(): void {
    this.selectedUser = this.bulkProject.responsibleUserId;
  }
}
