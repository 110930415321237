




































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

export interface TabSpecification {
  id: string;
  name: string;
  dontStretch?: boolean;
  disabled?: boolean;
}

@Component({
  components: {},
})
export default class TabsContainer extends Vue {
  @Prop({ default: false })
  public readonly disableForLargeScreens!: boolean;

  @Prop({ required: true })
  public readonly tabs!: TabSpecification[];

  private fActiveTabId?: string;

  get activeTabId(): string | undefined {
    return this.fActiveTabId;
  }

  data() {
    return {
      fActiveTabId: this.fActiveTabId,
    };
  }

  public switchToTab(tabId: string): void {
    this.fActiveTabId = tabId;
  }

  @Watch("tabs", { immediate: true })
  private _onTabsChange(
    newValue: TabSpecification[],
    oldValue: TabSpecification[]
  ): void {
    if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
      return;
    }

    if (!newValue || newValue.length === 0) {
      this.fActiveTabId = undefined;
      return;
    }

    this.fActiveTabId = newValue[0].id;
  }
}
