














import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import dataStore from "@/store";

import BulkShipment from "../../bulk-shipment.model";
import BulkShipmentStore from "../../store/index";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class BulkShipmentPublishAction extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly shipmentId!: string;

  public vendorNotes?: string;

  private fBulkShipmentStore: BulkShipmentStore;

  get shipment(): BulkShipment | undefined {
    return this.fBulkShipmentStore.getBulkShipmentById(this.shipmentId);
  }

  constructor() {
    super();

    this.fBulkShipmentStore = getModule(BulkShipmentStore, dataStore);
  }

  protected async performSubmit(): Promise<void> {
    if (!this.shipment) {
      return;
    }

    await this.fBulkShipmentStore.publishBulkShipment(this.shipment);
  }
}
