import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import LabelHeaderProductRelation from "./label-header-product-relation.model";

export default class LabelHeaderProductRelationFactory
  implements ObjectBuilderInterface<LabelHeaderProductRelation> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): LabelHeaderProductRelation {
    const value = new LabelHeaderProductRelation(
      data.id,
      data.labelHeader,
      data.productId.toString()
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
