







































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import { Dictionary } from "@/lib/Dictionary.type";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import PenaltyRuleViewForm from "./ViewForm.vue";
import PenaltyRuleEditForm from "./EditForm.vue";
import PenaltyRuleHistory from "./History.vue";

import Factory from "../../../factory/factory.model";

@Component({
  metaInfo() {
    const component = this as PenaltyRuleEditPage;

    return {
      title:
        (component.factory != null ? component.factory.name : "") +
        " Penalty Rules",
    };
  },
  components: {
    PenaltyRuleEditForm,
    PenaltyRuleViewForm,
    PenaltyRuleHistory,
  },
})
export default class PenaltyRuleEditPage extends Vue {
  @Prop()
  public readonly factoryId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fFactoryDataStore: FactoryStore;

  private fIsHistoryExpanded = false;

  get isHistoryExpanded(): boolean {
    return this.fIsHistoryExpanded;
  }

  get factory(): Factory | undefined {
    return this.fFactoryDataStore.getFactoryById(this.factoryId);
  }

  get showBackLink(): boolean {
    return (
      this.listQuery !== undefined && Object.keys(this.listQuery).length > 0
    );
  }

  get showEditForm(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_PENALTIES_MANAGE
    );
  }

  get showHistory(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
    ].includes(user.role);
  }

  get showViewForm(): boolean {
    if (this.showEditForm) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_PENALTIES_READ
    );
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public onCancel(): void {
    void this.$router.push({
      name: "PenaltyRuleListPage",
      query: this.listQuery,
    });
  }

  public onPenaltyRulesUpdated(): void {
    void this.$router.push({
      name: "PenaltyRuleListPage",
      query: this.listQuery,
    });
  }

  public toggleHistory(): void {
    this.fIsHistoryExpanded = !this.fIsHistoryExpanded;
  }

  private async getFactoryData(factoryId: string): Promise<void> {
    await this.fFactoryDataStore.loadFactoryById({ id: factoryId });
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (this.factoryId == null) {
      return;
    }

    await this.getFactoryData(this.factoryId);
  }
}
