















































import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import { Resource } from "@/modules/account/resource";

import ExtraPayment from "../../extra-payment.model";
import FactoryInvoiceStore from "../../store";

@Component({
  components: {
    FormErrors,
  },
})
export default class ExtraPaymentItems extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;

  private fErrorsList: string[] = [];
  private fIsDisabled = false;

  get errorsList(): string[] {
    return this.fErrorsList;
  }

  get isDisabled(): boolean {
    return this.fIsDisabled;
  }

  get paymentItems(): ExtraPayment[] {
    return this.fFactoryInvoiceDataStore.getExtraPaymentsByPlushieId(
      this.plushieId
    );
  }

  get showActionsColumn(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_EXTRA_PAYMENTS_MANAGE
    );
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public getPaymentFormattedCreationDate(payment: ExtraPayment): string {
    if (!payment.createdAt) {
      return "";
    }

    return payment.createdAt.toLocaleDateString(undefined, {
      day: "numeric",
      month: "numeric",
      year: "2-digit",
    });
  }

  public getPaymentFaultName(payment: ExtraPayment): string {
    if (!payment.fault) {
      return "";
    }

    const fault = this.fFactoryInvoiceDataStore.getExtraPaymentFaultById(
      payment.fault
    );

    return fault ? fault.name : "";
  }

  public async deleteItem(item: ExtraPayment): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsDisabled = true;

    try {
      await this.fFactoryInvoiceDataStore.deleteExtraPayment(item);
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      this.fErrorsList.push(...errors);
    } finally {
      this.fIsDisabled = false;
    }
  }
}
