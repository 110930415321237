import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Setting from "./setting.model";

export default class SettingFactory implements ObjectBuilderInterface<Setting> {
  public buildFromJSON(data: { [key: string]: any }): Setting {
    const value = new Setting(data.code, data.name, data.value);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
