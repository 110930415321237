











import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";

import InReviewSnapshot from "../../in-review-snapshot.model";

@Component
export default class InReviewSnapshotTable extends Vue {
  @Prop({ required: true })
  public readonly tableData!: InReviewSnapshot[];

  get columns(): string[] {
    return ["productName", "total"];
  }

  get options(): TableOptions {
    return {
      columnsClasses: {
        productName: "_product-name",
        total: "_total",
      },
      filterable: false,
      headings: {
        productName: "Product",
        total: "Total",
      },
      orderBy: { column: "productName", ascending: true },
      pagination: {
        show: false,
      },
      perPageValues: [],
      perPage: 100,
      sortable: this.columns,
    };
  }
}
