













import { Component, Prop, Vue } from "vue-property-decorator";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import { DirectoryValue } from "@/modules/api/directory-value.model";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class PlushieTagsListItem extends Vue {
  @Prop({ required: true })
  public readonly removeAction!: (tagId: string) => Promise<void>;

  @Prop({ default: true })
  public readonly isReadOnly!: boolean;

  @Prop({ required: true })
  public readonly tag!: DirectoryValue;

  private fIsSubmitting = false;

  get isSubmitting() {
    return this.fIsSubmitting;
  }

  public async removeTag(tagId: string) {
    if (this.isSubmitting || this.isReadOnly) {
      return;
    }

    this.fIsSubmitting = true;

    try {
      await this.removeAction(tagId);
    } finally {
      this.fIsSubmitting = false;
    }
  }
}
