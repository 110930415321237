






































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";

import RoleEditForm from "./RoleEditForm.vue";
import RolePermissionsEditForm from "./RolePermissionsEditForm.vue";

import AccountStore from "../store";
import RoleRepository from "../role.repository";
import Role from "../role.model";

@Component({
  metaInfo() {
    return {
      title: "Role Edit",
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    RoleEditForm,
    RolePermissionsEditForm,
  },
})
export default class RoleEditPage extends Vue {
  @Prop()
  public readonly roleId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("RoleRepository")
  private fRoleRepository!: RoleRepository;

  private fAccountDataStore: AccountStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get role(): Role | undefined {
    return this.fAccountDataStore.getRoleById(this.roleId);
  }

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public onCancel(): void {
    void this.$router.push({
      name: "UserRolesListPage",
      query: this.listQuery,
    });
  }

  public onRoleUpdated(): void {
    void this.$router.push({
      name: "UserRolesListPage",
      query: this.listQuery,
    });
  }

  public getRolesOrder(): Promise<string[]> {
    return this.fRoleRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  @Watch("roleId", { immediate: true })
  private async _onRoleIdChange() {
    if (this.roleId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fAccountDataStore.loadRoleById({ id: this.roleId });

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
