import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductionSnapshot from "./production-snapshot.model";

export default class ProductionSnapshotFactory
  implements ObjectBuilderInterface<ProductionSnapshot> {
  public buildFromJSON(data: { [key: string]: any }): ProductionSnapshot {
    const value = new ProductionSnapshot(
      data.factory_name,
      data.product_name,
      data.in_design ? Number.parseInt(data.in_design) : null,
      data.in_production ? Number.parseInt(data.in_production) : null,
      data.customer_preview ? Number.parseInt(data.customer_preview) : null,
      data.customer_preview_approved
        ? Number.parseInt(data.customer_preview_approved)
        : null,
      data.quality_inspection ? Number.parseInt(data.quality_inspection) : null,
      data.ready_for_production
        ? Number.parseInt(data.ready_for_production)
        : null,
      data.ready_for_shipment ? Number.parseInt(data.ready_for_shipment) : null,
      data.rework ? Number.parseInt(data.rework) : null,
      data.total ? Number.parseInt(data.total) : null
    );

    return value;
  }
}
