






























import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import PlushieStatus from "@/modules/plushie/plushie-status.model";
import { Dictionary } from "@/lib/Dictionary.type";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import QuestionTemplateStatusRelationRepository from "../../question-template-status-relation.repository";
import QuestionTemplateStatusRelation from "../../question-template-status-relation.model";
import { PlushieStatusValue } from "../../../plushie/plushie-status.value";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class QuestionTemplateStatusesEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop()
  public readonly questionTemplateId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("QuestionTemplateStatusRelationRepository")
  private fRelationRepository!: QuestionTemplateStatusRelationRepository;

  private fPlushieDataStore: PlushieStore;

  private fRelations: QuestionTemplateStatusRelation[] = [];
  private fUpdatingRelations: Dictionary<boolean> = {};

  get relations(): QuestionTemplateStatusRelation[] {
    return this.fRelations;
  }

  get statusOptions(): PlushieStatus[] {
    return this.fPlushieDataStore.plushieStatusList;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public isStatusAssociatedWithTemplate(statusId: PlushieStatusValue): boolean {
    return this.getRelationByStatusId(statusId) !== undefined;
  }

  public isRelationUpdating(relationId: string): boolean {
    return this.fUpdatingRelations[relationId];
  }

  public onRelationChange(status: PlushieStatus, value: boolean): void {
    if (!value) {
      void this.deleteRelation(status);
    } else {
      void this.addRelation(status);
    }
  }

  protected data(): Record<string, unknown> {
    return {
      fRelations: this.fRelations,
    };
  }

  protected mounted(): void {
    void this.fPlushieDataStore.loadPlushieStatuses();
  }

  private async deleteRelation(status: PlushieStatus) {
    const relation = this.getRelationByStatusId(status.id);

    if (!relation) {
      return;
    }

    this.removeRelationFromList(relation);

    this.markStatusAsUpdating(status.id);

    try {
      await this.fRelationRepository.delete(relation);
      this.submitErrors = [];
    } catch (e) {
      this.fRelations.push(relation);

      this.submitErrors.push(
        `Something went wrong! Unable to remove the status '${status.name}'`
      );
    }

    this.markStatusAsAvailable(status.id);
  }

  private async addRelation(status: PlushieStatus) {
    const relation = new QuestionTemplateStatusRelation(
      this.fIdGenerator.getId(),
      this.questionTemplateId,
      status.id
    );

    this.fRelations.push(relation);

    this.markStatusAsUpdating(status.id);

    try {
      await this.fRelationRepository.save(relation);
      this.submitErrors = [];
    } catch (e) {
      this.removeRelationFromList(relation);
      this.submitErrors.push(
        `Something went wrong! Unable to add the relation '${status.name}'`
      );
    }

    this.markStatusAsAvailable(status.id);
  }

  private getRelationByStatusId(
    statusId: PlushieStatusValue
  ): QuestionTemplateStatusRelation | undefined {
    return this.relations.find((item) => item.plushieStatus === statusId);
  }

  private markStatusAsUpdating(statusId: string) {
    Vue.set(this.fUpdatingRelations, statusId, true);
  }

  private markStatusAsAvailable(statusId: string) {
    Vue.set(this.fUpdatingRelations, statusId, false);
  }

  private removeRelationFromList(item: QuestionTemplateStatusRelation) {
    const index = this.relations.indexOf(item);

    if (index > -1) {
      this.fRelations.splice(index, 1);
    }
  }

  @Watch("questionTemplateId", { immediate: true })
  private async _onQuestionTemplateIdChange() {
    this.fRelations = [];

    this.fIsDisabled = true;

    try {
      const relations = await this.fRelationRepository.getByTemplateId(
        this.questionTemplateId
      );
      this.fRelations = relations.getItems();

      this.fIsDisabled = false;
    } catch (e) {
      this.fIsDisabled = false;

      throw e;
    }
  }
}
