import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Upgrade from "./upgrade.model";

export default class UpgradeFactory implements ObjectBuilderInterface<Upgrade> {
  public buildFromJSON(data: { [key: string]: any }): Upgrade {
    const value = new Upgrade(
      data.id,
      data.code,
      data.title,
      data.displayToFactory
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fShortName = data.shortName || null;

    value.isNew = false;

    return value;
  }
}
