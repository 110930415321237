


















































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import AccountStore from "../store";
import Role from "../role.model";
import { DirectoryValue } from "../../api/directory-value.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class RoleEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly role?: Role;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public name?: string;
  public metaRole?: string;

  private fAccountDataStore: AccountStore;

  get metaRoleOptions(): DirectoryValue[] {
    return this.fAccountDataStore.metaRoles;
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      name: this.name,
      metaRole: this.metaRole,
    };
  }

  protected mounted(): void {
    void this.fAccountDataStore.loadMetaRoles();
  }

  protected async performSubmit(): Promise<void> {
    let role = this.generateRoleObject();

    role = await this.fAccountDataStore.saveRole(role);
    this.$emit("updated", role);
  }

  private generateRoleObject(): Role {
    if (!this.name || !this.metaRole) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.role ? this.role.id : this.fIdGenerator.getId();

    const value = new Role(id, this.name, this.metaRole, true);

    if (this.role) {
      value.isNew = false;
    }

    return value;
  }

  @Watch("role", { immediate: true })
  private _onRoleChange() {
    this.name = undefined;
    this.metaRole = undefined;

    if (this.role === undefined) {
      return;
    }

    this.name = this.role.name;
    this.metaRole = this.role.metaRole;
  }
}
