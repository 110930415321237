import { render, staticRenderFns } from "./ProductionEstimatesTable.vue?vue&type=template&id=0652eacc&scoped=true&"
import script from "./ProductionEstimatesTable.vue?vue&type=script&lang=ts&"
export * from "./ProductionEstimatesTable.vue?vue&type=script&lang=ts&"
import style0 from "./ProductionEstimatesTable.vue?vue&type=style&index=0&id=0652eacc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0652eacc",
  null
  
)

export default component.exports