





































































import { Component, Prop, Inject, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import ActionButton from "@/lib/components/ActionButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import dataStore from "@/store";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import FactoryStore from "@/modules/factory/store";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import PlushieStore from "@/modules/plushie/store";

import StrictCapacityModeStore from "../store";
import PlushieRepository from "../../plushie/plushie.repository";
import Plushie from "../../plushie/plushie.model";
import FactoryPlushieStatusCapacity from "../factory-plushie-status-capacity.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    ActionButton,
  },
})
export default class QueueControlWidget extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly statusId!: PlushieStatusValue;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("PlushieRepository")
  private fPlushieRepository!: PlushieRepository;

  public pullCount = 1;

  private fIsLoading = false;
  private fPreviousStatusPlushiesQty?: number;
  private fFactoryId?: string;
  private fPulledItems: Plushie[] = [];

  private fStrictCapacityModeDataStore: StrictCapacityModeStore;
  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get availableSpots(): number | undefined {
    if (this.spotsLimit === undefined || this.usedSpots === undefined) {
      return undefined;
    }

    return this.spotsLimit - this.usedSpots;
  }

  get availableItemsToPull(): number | undefined {
    return this.fPreviousStatusPlushiesQty;
  }

  get factoryPlushieStatusCapacity(): FactoryPlushieStatusCapacity | undefined {
    if (!this.fFactoryId) {
      return undefined;
    }

    return this.fStrictCapacityModeDataStore.getFactoryPlushieStatusCapacityForStatus(
      this.fFactoryId,
      this.statusId
    );
  }

  get pulledItems(): Plushie[] {
    return this.fPulledItems;
  }

  get spotsLimit(): number | undefined {
    if (!this.factoryPlushieStatusCapacity) {
      return undefined;
    }

    return this.factoryPlushieStatusCapacity.value;
  }

  get usedSpots(): number | undefined {
    if (!this.fFactoryId) {
      return undefined;
    }

    const qty = this.fStrictCapacityModeDataStore.getFactoryPlushieStatusQtyForStatus(
      this.fFactoryId,
      this.statusId
    );

    if (!qty) {
      return undefined;
    }

    return qty.plushiesQty;
  }

  get previousStatus(): PlushieStatusValue | undefined {
    switch (this.statusId) {
      case PlushieStatusValue.IN_DESIGN:
        return PlushieStatusValue.READY_FOR_PRODUCTION;
      default:
        return undefined;
    }
  }

  get statusName(): string {
    const status = this.fPlushieDataStore.getPlushieStatusById(this.statusId);

    if (!status) {
      return "";
    }

    return status.name;
  }

  get isPullAvailable(): boolean {
    if (!this.factoryPlushieStatusCapacity) {
      return false;
    }

    return this.factoryPlushieStatusCapacity.usePullMode;
  }

  get showAvailableSpots(): boolean {
    if (!this.fFactoryId) {
      return false;
    }

    const isModeEnabled = this.fStrictCapacityModeDataStore.getFactoryStrictCapacityModeById(
      this.fFactoryId
    );

    return isModeEnabled && this.spotsLimit !== undefined;
  }

  constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);

    this.fStrictCapacityModeDataStore = getModule(
      StrictCapacityModeStore,
      dataStore
    );
  }

  protected created(): void {
    void this.loadData();
  }

  protected data(): Record<string, unknown> {
    return {
      fFactoryId: this.fFactoryId,
      fPreviousStatusPlushiesQty: this.fPreviousStatusPlushiesQty,
    };
  }

  protected clearPulledItems(): void {
    this.fPulledItems = [];

    this.$emit("clearPerformed");
  }

  protected async performSubmit(): Promise<void> {
    this.fPulledItems = [];

    if (!this.fFactoryId) {
      return;
    }

    let movedPlushies: Plushie[] = [];

    switch (this.statusId) {
      case PlushieStatusValue.IN_DESIGN:
        movedPlushies = await this.fStrictCapacityModeDataStore.createInDesignTransferOrder(
          this.pullCount
        );

        break;

      default:
        return;
    }

    this.fPulledItems = movedPlushies;

    if (this.fPreviousStatusPlushiesQty !== undefined) {
      this.fPreviousStatusPlushiesQty -= movedPlushies.length;
    }

    await this.fStrictCapacityModeDataStore.loadFactoryPlushieStatusQtiesByFactoryId(
      { factoryId: this.fFactoryId, useCache: false }
    );

    this.$emit("actionPerformed", movedPlushies);
  }

  private async loadData() {
    const user = this.getCurrentUser();

    const userFactoryRelation = await this.fFactoryDataStore.loadUserFactoryRelationByUserId(
      {
        userId: user.id,
      }
    );

    if (!userFactoryRelation) {
      throw new Error(
        "Relation with a factory is not found for the factory user"
      );
    }

    this.fFactoryId = userFactoryRelation.factory;

    await Promise.all([
      this.loadPreviousStatusPlushiesQty(),
      this.fPlushieDataStore.loadPlushieStatuses(),
      this.fStrictCapacityModeDataStore.loadFactoryPlushieStatusCapacitiesByFactoryId(
        { factoryId: this.fFactoryId }
      ),
      this.fStrictCapacityModeDataStore.loadFactoryPlushieStatusQtiesByFactoryId(
        { factoryId: this.fFactoryId }
      ),
      this.fStrictCapacityModeDataStore.loadFactoryStrictCapacityModeById(
        this.fFactoryId
      ),
    ]);
  }

  private async loadPreviousStatusPlushiesQty(): Promise<void> {
    if (!this.previousStatus) {
      return;
    }

    const previousStatusCollection = await this.fPlushieRepository.getList(
      1,
      1,
      {
        status: this.previousStatus,
      }
    );

    if (!previousStatusCollection) {
      this.fPreviousStatusPlushiesQty = 0;
      return;
    }

    this.fPreviousStatusPlushiesQty = previousStatusCollection.totalItemsCount;
  }

  private getCurrentUser(): AuthenticatedUser {
    const user = this.fUserProvider.getUser();

    if (!user) {
      throw new Error("The current user is not found");
    }

    return user;
  }

  @Watch("statusId", { immediate: false })
  private async _onStatusIdChange() {
    if (!this.statusId) {
      return;
    }

    this.fIsLoading = true;

    await this.loadPreviousStatusPlushiesQty();

    this.fIsLoading = false;
  }
}
