import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import PlushieFactoryAssignment from "@/modules/factory/plushie-factory-asignment.model";

export default class PlushieFactoryAssignmentFactory
  implements ObjectBuilderInterface<PlushieFactoryAssignment> {
  public buildFromJSON(data: { [key: string]: any }): PlushieFactoryAssignment {
    const value = new PlushieFactoryAssignment(
      data.id,
      data.plushie,
      data.factory
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
