











import { Component, Vue } from "vue-property-decorator";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

@Component({
  components: {
    LoadingSpinner,
  },
  metaInfo: {
    title: "Login",
    titleTemplate: "%s | Budsies Backend System",
  },
})
export default class App extends Vue {}
