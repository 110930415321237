import { ToastObject } from "vue-toasted";

import AccountStore from "@/modules/account/store";
import ToasterInterface from "@/lib/interfaces/toaster.interface";

import ActiveUser from "./active-user";
import ActiveUserFactory from "./active-user.factory";

export default class OtherUsersDetector {
  private fOtherActiveUsers: ActiveUser[] = [];
  private fAccountsExpirationInterval: number;

  private fToast?: ToastObject;

  constructor(
    socket: SocketIOClient.Socket,
    private fCurrentUserId: string,
    private fActiveUserFactory: ActiveUserFactory,
    private fAccountStore: AccountStore,
    private fToaster: ToasterInterface,
    private fWindow: Window
  ) {
    socket.on("activePageViewer", (userId: string) => {
      this.processAccount(userId);
    });

    socket.on("inactivePageViewer", (userId: string) => {
      this.removeAccountFromList(userId);
    });

    socket.on("activePageViewersList", (userIdsList: string[]) => {
      this.refreshAccountsList(userIdsList);
    });

    socket.on("pageViewerDisconnect", (userId: string) => {
      this.removeAccountFromList(userId);
    });

    this.fAccountsExpirationInterval = this.fWindow.setInterval(
      () => this.removeExpiredAccounts(),
      15 * 1000
    );
  }

  public destroy(): void {
    this.fWindow.clearInterval(this.fAccountsExpirationInterval);

    if (this.fToast) {
      this.fToast.goAway(0);
      this.fToast = undefined;
    }
  }

  private refreshAccountsList(usernamesList: string[]): void {
    this.fOtherActiveUsers = [];

    usernamesList.forEach((username: string) =>
      this.addAccountToList(username)
    );

    void this.refreshNotification();
  }

  private processAccount(userId: string): void {
    if (this.fCurrentUserId === userId) {
      return;
    }

    const activeUserFromList = this.fOtherActiveUsers.find(
      (activeUser: ActiveUser) => {
        return activeUser.userId === userId;
      }
    );

    if (activeUserFromList) {
      activeUserFromList.refreshExpirationDate();
    } else {
      this.addAccountToList(userId);
    }
  }

  private removeAccountFromList(userId: string): void {
    this.fOtherActiveUsers = this.fOtherActiveUsers.filter(
      (activeUser: ActiveUser) => {
        return activeUser.userId !== userId;
      }
    );

    void this.refreshNotification();
  }

  private addAccountToList(userId: string): void {
    this.fOtherActiveUsers.push(this.fActiveUserFactory.create(userId));

    void this.refreshNotification();
  }

  private removeExpiredAccounts(): void {
    this.fOtherActiveUsers = this.fOtherActiveUsers.filter(
      (activeUser: ActiveUser) => {
        return !activeUser.isExpired();
      }
    );

    void this.refreshNotification();
  }

  private async refreshNotification(): Promise<void> {
    const usernames: string[] = [];

    for (const activeUser of this.fOtherActiveUsers) {
      const userInfo = await this.fAccountStore.loadUserInfoById({
        id: activeUser.userId,
      });

      if (!userInfo) {
        continue;
      }

      usernames.push(`${userInfo.firstName} ${userInfo.lastName}`);
    }

    if (!usernames.length) {
      if (!this.fToast) {
        return;
      }

      this.fToast.goAway(0);

      this.fToast = undefined;

      return;
    }

    const verb = usernames.length > 1 ? "are" : "is";
    const text =
      usernames.join(", ") + ` ${verb} also currently looking at this page`;

    if (this.fToast) {
      this.fToast.text(text);
    } else {
      this.fToast = this.fToaster.$toasted.show(text, {
        type: "bubble",
        position: "bottom-right",
      });
    }
  }
}
