
















































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import insertText from "insert-text-textarea";
import autosize from "v-autosize";
import escapeHTML from "lodash.escape";

import ColorsAutocomplete from "@/lib/components/ColorsAutocomplete.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import autoindent from "@/lib/directives/auto-indent.directive";
import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import MessagesSendingFormEmojiPicker from "../EmojiPicker.vue";

import MessagingStore from "../../store";
import Message from "../../message.model";

@Component({
  components: {
    ColorsAutocomplete,
    FormField,
    FormErrors,
    SubmitButton,
    MessagesSendingFormEmojiPicker,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class MessagesHistoryEditItem extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly messageId!: string;

  public currentValue?: string;

  private fMessagingDataStore: MessagingStore;

  private fIsInEditMode = false;

  get isInEditMode(): boolean {
    return this.fIsInEditMode;
  }

  get message(): Message | undefined {
    return this.fMessagingDataStore.getMessageById(this.messageId);
  }

  get sanitizedMessageText(): string | undefined {
    if (!this.message) {
      return;
    }

    return escapeHTML(this.message.text);
  }

  constructor() {
    super();
    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
  }

  public switchToEditMode(): void {
    this.fIsInEditMode = true;
    this.submitErrors = [];

    this.$nextTick(() => {
      if (!this.message) {
        return;
      }

      this.currentValue = this.message.text;
    });
  }

  public async onCancelClick(): Promise<void> {
    this.fIsInEditMode = false;
    this.submitErrors = [];
    await this.$validator.reset();
    this.currentValue = undefined;
  }

  onEmojiPicked(emoji: string): void {
    const editInput = this.getEditInput();

    if (!editInput) {
      return;
    }

    editInput.focus();

    insertText(editInput, emoji);
  }

  protected data(): Record<string, unknown> {
    return {
      currentValue: this.currentValue,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.currentValue) {
      return;
    }

    await this.fMessagingDataStore.updateMessageText({
      id: this.messageId,
      text: this.currentValue,
    });

    this.fIsInEditMode = false;
    await this.$validator.reset();
    this.currentValue = undefined;
  }

  private getEditInput(): HTMLTextAreaElement | undefined {
    return this.$refs["edit-input"] as HTMLTextAreaElement;
  }

  @Watch("fIsInEditMode", { immediate: false })
  private _onfIsInEditModeChange() {
    this.$emit("editModeChange", this.fIsInEditMode);
  }
}
