


























































import {
  TableOptions,
  ColumnFilterDropdownOption,
  RowClickEventData,
} from "vue-tables-2-premium";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { Component, Inject } from "vue-property-decorator";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import rootStore from "@/store";
import TableColumnDefinitionInterface from "@/lib/interfaces/table-column-definition.interface";
import TableColumnDefinitionsParserService from "@/lib/services/table-column-definitions-parser.service";
import FactoryStore from "@/modules/factory/store";
import PlushieStore from "@/modules/plushie/store";
import Product from "@/modules/plushie/product.model";
import Upgrade from "@/modules/plushie/upgrade.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import { Dictionary } from "@/lib/Dictionary.type";

import ProductionProcessStore from "../../store";
import DirectShipmentIntentionRule from "../../direct-shipment-intention-rule.model";
import { DirectShipmentIntentionRuleOrderType } from "../../direct-shipment-intention-rule-order-type";

interface TableRow {
  id: string;
  name: string;
  factory: string;
  product: string;
  forOrderType: string;
  upgrade?: string;
}

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class DirectShipmentIntentionRulesListPage extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fAuthenticatedUserProvider!: AuthenticatedUserProvider;

  protected fColumns: TableColumnDefinitionInterface[] = [];

  protected fOptions: TableOptions = {};

  private fDefinitionsParser?: TableColumnDefinitionsParserService;
  private fIsLoading = false;
  private fRemovingRule: Dictionary<boolean> = {};

  private fFactoryStore: FactoryStore;
  private fPlushieStore: PlushieStore;
  private fProductionProcessStore: ProductionProcessStore;

  get canManageDirectShipmentIntentionRules(): boolean {
    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.DIRECT_SHIPMENT_INTENTION_RULE_MANAGE
    );
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get options(): TableOptions {
    return this.fOptions;
  }

  get products(): Product[] {
    return this.fPlushieStore.productsList;
  }

  get tableData(): TableRow[] {
    return this.fProductionProcessStore.directShipmentIntentionRules.map(
      (item) => {
        return {
          id: item.id,
          name: item.name,
          factory: this.getFactoryForRule(item),
          product: this.getProductForRule(item),
          forOrderType: item.forOrderType,
          upgrade: this.getUpgradeForRule(item),
        };
      }
    );
  }

  get upgrades(): Upgrade[] {
    return this.fPlushieStore.upgradesList;
  }

  public constructor() {
    super();

    this.fFactoryStore = getModule(FactoryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "product",
        header: "Product",
        cssClass: "_product-column",
        isFilterable: true,
        isFilterFromList: true,
        displayConstraint: "min_tablet",
      },
      {
        name: "forOrderType",
        header: "Order Type",
        cssClass: "_order-type-column",
        isFilterable: true,
        isFilterFromList: true,
        displayConstraint: "min_tablet",
      },
      {
        name: "upgrade",
        header: "Upgrade",
        cssClass: "_upgrade-column",
        isFilterable: true,
        isFilterFromList: true,
        displayConstraint: "min_desktop",
      },
    ];

    if (this.canManageDirectShipmentIntentionRules) {
      this.fColumns.push({
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
      });
    }

    this.fOptions = {
      debounce: 500,
      listColumns: {},
      filterByColumn: true,
      orderBy: {
        column: "factory",
        ascending: false,
      },
      groupBy: "factory",
      toggleGroups: true,
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public getColumns(): string[] {
    if (!this.fDefinitionsParser) {
      return [];
    }

    return this.fDefinitionsParser.getColumns();
  }

  public getFactoryForRule(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): string {
    return (
      this.fFactoryStore.getFactoryById(directShipmentIntentionRule.factory)
        ?.name || ""
    );
  }

  public getProductForRule(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): string {
    if (!directShipmentIntentionRule.product) {
      return "";
    }

    return (
      this.fPlushieStore.getProductById(directShipmentIntentionRule.product)
        ?.id || ""
    );
  }

  public getUpgradeForRule(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): string {
    if (!directShipmentIntentionRule.upgrade) {
      return "";
    }

    return (
      this.fPlushieStore.getUpgradeById(directShipmentIntentionRule.upgrade)
        ?.id || ""
    );
  }

  public isRemoving(ruleId: string): boolean {
    return !!this.fRemovingRule[ruleId];
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    void this.$router.push({
      name: "DirectShipmentIntentionRuleEditPage",
      query: {
        id: data.row.id,
      },
    });
  }

  public async removeDirectShipmentIntentionRule(id: string): Promise<void> {
    if (this.isRemoving(id)) {
      return;
    }

    if (!confirm("Are you sure?")) {
      return;
    }

    Vue.set(this.fRemovingRule, id, true);

    const rule = this.fProductionProcessStore.directShipmentIntentionRuleById(
      id
    );

    try {
      if (!rule) {
        this.fProductionProcessStore.removeDirectShipmentIntentionRuleById(id);
        return;
      }

      await this.fProductionProcessStore.deleteDirectShipmentIntentionRule(
        rule
      );
    } finally {
      Vue.delete(this.fRemovingRule, id);
    }
  }

  protected getItemUrl(itemId: string, routeName: string): string {
    const route = this.$router.resolve({
      name: routeName,
      query: {
        id: itemId,
      },
    });

    return route.href;
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    await this.loadTableData();

    this.fDefinitionsParser = new TableColumnDefinitionsParserService(
      this.fColumns
    );

    this.fOptions.headings = this.fDefinitionsParser.getHeadings();
    this.fOptions.columnsClasses = this.fDefinitionsParser.getClasses();
    this.fOptions.columnsDisplay = this.fDefinitionsParser.getColumnDisplay();
    this.fOptions.filterable = this.fDefinitionsParser.getFilterable();

    Vue.set(
      this.fOptions,
      "listColumns",
      this.fDefinitionsParser.getListColumns()
    );
    this.fOptions.sortable = this.fDefinitionsParser.getSortable();

    const productOptions: ColumnFilterDropdownOption[] = [];
    const orderTypeOptions: ColumnFilterDropdownOption[] = [];
    const upgradeOptions: ColumnFilterDropdownOption[] = [];

    this.products.forEach((product) => {
      productOptions.push({ id: product.id, text: product.name });
    });
    this.upgrades.forEach((upgrade) => {
      upgradeOptions.push({ id: upgrade.id, text: upgrade.name });
    });
    Object.values(DirectShipmentIntentionRuleOrderType).forEach((item) => {
      orderTypeOptions.push({ id: item, text: item });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "product", productOptions);
      Vue.set(this.fOptions.listColumns, "forOrderType", orderTypeOptions);
      Vue.set(this.fOptions.listColumns, "upgrade", upgradeOptions);
    }

    this.fIsLoading = false;
  }

  private async loadTableData(): Promise<void> {
    await Promise.all([
      this.fProductionProcessStore.loadDirectShipmentIntentionRules(),
      this.fPlushieStore.loadProducts(),
      this.fFactoryStore.loadFactories(),
      this.fPlushieStore.loadUpgrades(),
    ]);
  }
}
