































import { Component, Prop, Vue, Inject } from "vue-property-decorator";

import ActionButton from "@/lib/components/ActionButton.vue";

import PlushieExportRepository from "../plushie-export.repository";

@Component({
  components: {
    ActionButton,
  },
})
export default class DocumentExportCommands extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("PlushieExportRepository")
  private fPlushieExportRepository!: PlushieExportRepository;

  @Inject("window")
  private fWindow!: Window;

  private fError = false;

  get error(): boolean {
    return this.fError;
  }

  public async downloadPdf(): Promise<void> {
    this.fError = false;

    try {
      const downloadUrl = await this.fPlushieExportRepository.getPdfByPlushieId(
        this.plushieId
      );

      this.fWindow.location.href = downloadUrl;
    } catch (e) {
      this.fError = true;
      throw e;
    }
  }

  public async downloadXls(): Promise<void> {
    this.fError = false;

    try {
      const downloadUrl = await this.fPlushieExportRepository.geXlsByPlushieId(
        this.plushieId
      );

      this.fWindow.location.href = downloadUrl;
    } catch (e) {
      this.fError = true;
      throw e;
    }
  }

  public printButtonClickHandler(): void {
    this.fError = false;
  }
}
