











































































































































import Vue from "vue";
import { mixins } from "vue-class-component";
import { Component, Inject } from "vue-property-decorator";
import DatePicker from "vuejs-datepicker";
import { getModule } from "vuex-module-decorators";
import { ColumnFilterDropdownOption, TableOptions } from "vue-tables-2-premium";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import PlushieStore from "@/modules/plushie/store";
import rootStore from "@/store";
import Product from "@/modules/plushie/product.model";
import AccountDataStore from "@/modules/account/store";
import UserInfo from "@/modules/account/user-info.model";
import { MetaRoleValue } from "@/modules/account/meta-role.value";

import QualityInspectionReport from "../quality-inspection-report.model";
import QualityInspectionReportRepository from "../quality-inspection-report.repository";
import { ReportTimePeriod } from "../report-time-period";

enum QualityInspectionReportState {
  INITIAL = "initial",
  INITIAL_DATA_LOADED = "initial-data-loaded",
  DATA_LOADED = "data-loaded",
}

@Component({
  components: {
    DatePicker,
    FormErrors,
    FormField,
    MoonLoader,
    SubmitButton,
  },
})
export default class QualityInspectionReportPage extends mixins(
  GeneralFormMixin
) {
  @Inject("QualityInspectionReportRepository")
  private fQualityInspectionReportRepository!: QualityInspectionReportRepository;

  public timePeriod?: ReportTimePeriod;
  public productId?: string;
  public inspectorId?: string;
  public startDate?: Date;
  public endDate?: Date;

  private fPlushieStore!: PlushieStore;
  private fAccountDataStore!: AccountDataStore;

  private fInspectorsList: UserInfo[] = [];
  private fState: QualityInspectionReportState =
    QualityInspectionReportState.INITIAL;

  private fQualityInspectionReports: QualityInspectionReport[] = [];
  private fTableOptions!: TableOptions;

  get inspectorOptionsList(): UserInfo[] {
    return this.fInspectorsList;
  }

  get productOptionsList(): Product[] {
    return this.fPlushieStore.activeProductsList;
  }

  get showLoadingIndicator(): boolean {
    return this.fState === QualityInspectionReportState.INITIAL;
  }

  get showReportTable(): boolean {
    return this.fState === QualityInspectionReportState.DATA_LOADED;
  }

  get tableColumns(): string[] {
    return ["inspector", "timePeriod", "plushiesCount"];
  }

  get tableData(): QualityInspectionReport[] {
    return this.fQualityInspectionReports;
  }

  get tableOptions(): TableOptions {
    return this.fTableOptions;
  }

  get timePeriodOptionsList(): ReportTimePeriod[] {
    return Object.values(ReportTimePeriod);
  }

  constructor() {
    super();

    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fAccountDataStore = getModule(AccountDataStore, rootStore);

    this.fTableOptions = {
      columnsClasses: {
        plushiesCount: "_plushies-count",
      },
      headings: {
        inspector: "Inspector",
        timePeriod: "Time Period",
        plushiesCount: "Count",
      },
      filterable: ["inspector"],
      filterByColumn: true,
      listColumns: {},
      toggleGroups: true,
      groupBy: "inspector",
      orderBy: { column: "inspector", ascending: true },
      perPage: 20,
      perPageValues: [10, 20, 30, 50, 100],
      sortable: [],
    };
  }

  protected created(): void {
    void this.loadInitialData();
  }

  protected data(): Record<string, unknown> {
    return {
      timePeriod: this.timePeriod,
      productId: this.productId,
      inspectorId: this.inspectorId,
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.timePeriod || !this.startDate) {
      throw new Error("Not all required fields are filled!");
    }

    if (this.endDate && this.startDate && this.endDate < this.startDate) {
      throw new Error("'End Date' can't be less than 'Start Date'");
    }

    let startDate;
    let endDate;

    if (this.startDate) {
      startDate = new Date(this.startDate);
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(
        startDate.getHours() - startDate.getTimezoneOffset() / 60
      );
    }

    if (this.endDate) {
      endDate = new Date(this.endDate);
      endDate.setHours(23, 59, 59, 999);
      endDate.setHours(endDate.getHours() - endDate.getTimezoneOffset() / 60);
    }

    await this.loadTableData(
      this.timePeriod,
      this.inspectorId,
      startDate,
      endDate,
      this.productId
    );
  }

  private async loadInitialData(): Promise<void> {
    await Promise.all([
      this.loadInspectorsList(),
      this.fPlushieStore.loadProducts(),
    ]);

    this.fState = QualityInspectionReportState.INITIAL_DATA_LOADED;
  }

  private async loadTableData(
    timePeriod: ReportTimePeriod,
    inspectorId?: string,
    startDate?: Date,
    endDate?: Date,
    productId?: string
  ): Promise<void> {
    this.fQualityInspectionReports = await this.fQualityInspectionReportRepository.getList(
      timePeriod,
      inspectorId,
      startDate,
      endDate,
      productId
    );

    const inspectorsInReport: Set<string> = new Set();

    this.fQualityInspectionReports.forEach((item) => {
      inspectorsInReport.add(item.inspector);
    });

    this.setColumnFilterOptions(inspectorsInReport, "inspector");

    this.fState = QualityInspectionReportState.DATA_LOADED;
  }

  private async loadInspectorsList(): Promise<void> {
    this.fInspectorsList = await this.fAccountDataStore.loadUserInfosByMetaRoleIds(
      [MetaRoleValue.ROLE_ADMIN, MetaRoleValue.ROLE_STORE]
    );
  }

  private setColumnFilterOptions(items: Set<string>, column: string): void {
    const options: ColumnFilterDropdownOption[] = [];

    items.forEach((value: string) => {
      options.push({ id: value, text: value });
    });

    options.sort((a, b) => (a.text > b.text ? 1 : -1));

    if (this.tableOptions.listColumns) {
      Vue.set(this.tableOptions.listColumns, column, options);
    }
  }
}
