




















































import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  ColumnFilterDropdownOption,
  RowClickEventData,
  ServerResponse,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";

import AccountStore from "../store";

interface TableRow {
  id: string;
  name: string;
  metaRole: string;
  createdAt: string;
}

@Component({
  metaInfo: {
    title: "Roles management",
  },
  components: {},
})
export default class RolesListPage extends mixins(GeneralListPageMixin) {
  private fAccountDataStore: AccountStore;

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "-text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "metaRole",
        header: "Meta Role",
        cssClass: "-text",
        isFilterable: true,
        isFilterFromList: true,
        isSortable: true,
        sortKey: "metaRole.name",
      },
      {
        name: "createdAt",
        header: "Created",
        isSortable: true,
        displayConstraint: "not_mobile",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "name",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public async deleteItem(id: string): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fAccountDataStore.deleteRoleById(id);

      if (this.fTable) {
        this.fTable.refresh();
      }
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "UserRoleEditPage"));
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const [rolesCollection] = await Promise.all([
      this.fAccountDataStore.loadRolesWithFilter({ filter, order }),
      this.fAccountDataStore.loadMetaRoles(),
    ]);

    const metaRoles = this.fAccountDataStore.metaRolesDictionary;

    const result: TableRow[] = [];

    rolesCollection.getItems().forEach((role) => {
      const metaRole = metaRoles[role.metaRole];

      result.push({
        id: role.id,
        name: role.name,
        metaRole: metaRole ? metaRole.name : "",
        createdAt: role.createdAt ? role.createdAt.toLocaleDateString() : "",
      });
    });

    return { data: result, count: rolesCollection.totalItemsCount };
  }

  protected async created(): Promise<void> {
    await this.fAccountDataStore.loadMetaRoles();

    const metaRoles = this.fAccountDataStore.metaRoles;

    const metaRoleOptions: ColumnFilterDropdownOption[] = [];

    metaRoles.forEach((item) => {
      metaRoleOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "metaRole", metaRoleOptions);
    }
  }
}
