















import { Options } from "blueimp-gallery/js/blueimp-gallery.min.js";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

import ImagesGalleryMixin from "@/lib/mixins/ImagesGallery";

import "../marked-up-image.factory";
import { MarkedUpSlideData } from "../marked-up-slide-data";

@Component
export default class MarkedUpImagesGallery extends mixins(ImagesGalleryMixin) {
  public launchGalleria(
    slides: MarkedUpSlideData[],
    index: number,
    options: Options = {}
  ): void {
    this.initGallery(slides, index, options);
  }
}
