import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ResourceCollection } from "@/modules/api/resource.collection";

import HolidayPeriod from "../holiday-period.model";
import PlushieRelation from "../plushie-relation.model";
import ProductSetting from "../product-setting.model";

const name = "HolidayPeriodStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    holidayPeriod: {},
    plushieRelation: {},
    productSetting: {},
    periodProductSettings: {},
    allHolidayPeriodsLoaded: false,
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class HolidayPeriodStore extends VuexModule {
  holidayPeriod: Dictionary<HolidayPeriod> = {};
  plushieRelation: Dictionary<PlushieRelation> = {};
  productSetting: Dictionary<ProductSetting> = {};

  periodProductSettings: Dictionary<string[]> = {};

  allHolidayPeriodsLoaded = false;

  // ################################### Holiday Periods #########################################

  get holidayPeriodsList(): HolidayPeriod[] {
    const list: HolidayPeriod[] = [];

    Object.keys(this.holidayPeriod).forEach((id) => {
      list.push(this.holidayPeriod[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get getHolidayPeriodById(): (id: string) => HolidayPeriod | undefined {
    return (id: string) => this.holidayPeriod[id];
  }

  @Mutation
  updateAllHolidayPeriodsLoaded(value: boolean): void {
    this.allHolidayPeriodsLoaded = value == true;
  }

  @Mutation
  updateHolidayPeriod(payload: HolidayPeriod): void {
    Vue.set(this.holidayPeriod, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadHolidayPeriods(): Promise<HolidayPeriod[]> {
    let items: HolidayPeriod[];

    if (!this.allHolidayPeriodsLoaded) {
      const collection = await DIContainer.HolidayPeriodRepository.getList(
        1,
        999
      );

      items = collection.getItems();

      items.forEach((item) => {
        this.updateHolidayPeriod(item);
      });

      this.updateAllHolidayPeriodsLoaded(true);
    }

    items = [];

    Object.keys(this.holidayPeriod).forEach((key) => {
      items.push(this.holidayPeriod[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadFHolidayPeriodsWithFilter({
    filter,
    order,
  }: {
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<HolidayPeriod>> {
    const collection = await DIContainer.HolidayPeriodRepository.getList(
      1,
      999,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateHolidayPeriod(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadHolidayPeriodById(id: string): Promise<HolidayPeriod | undefined> {
    if (this.holidayPeriod[id] !== undefined) {
      return this.holidayPeriod[id];
    }

    const holidayPeriod = await DIContainer.HolidayPeriodRepository.getById(id);

    if (holidayPeriod) {
      this.updateHolidayPeriod(holidayPeriod);
    }

    return holidayPeriod;
  }

  // ################################### PLUSHIE RELATION #########################################
  get getPlushieRelationById(): (id: string) => PlushieRelation | undefined {
    return (id: string) => this.plushieRelation[id];
  }

  @Mutation
  updatePlushieRelation(payload: PlushieRelation): void {
    Vue.set(this.plushieRelation, payload.id, payload);
  }

  @Mutation
  removeRelation(payload: PlushieRelation): void {
    Vue.delete(this.plushieRelation, payload.id);
  }

  @Action({ rawError: true })
  async loadPlushieRelationById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<PlushieRelation | undefined> {
    if (useCache && this.plushieRelation[id] !== undefined) {
      return this.plushieRelation[id];
    }

    const plushieRelation = await DIContainer.HolidayPeriodPlushieRelationRepository.getById(
      id
    );

    if (plushieRelation) {
      this.updatePlushieRelation(plushieRelation);
    }

    return plushieRelation;
  }

  @Action({ rawError: true })
  async savePlushieRelation(
    plushieRelation: PlushieRelation
  ): Promise<PlushieRelation> {
    const item = await DIContainer.HolidayPeriodPlushieRelationRepository.save(
      plushieRelation
    );

    this.updatePlushieRelation(item);

    return item;
  }

  @Action({ rawError: true })
  async deletePlushieRelation(plushieRelation: PlushieRelation): Promise<void> {
    await DIContainer.HolidayPeriodPlushieRelationRepository.delete(
      plushieRelation
    );

    this.removeRelation(plushieRelation);

    return;
  }

  // ################################### PRODUCT SETTING #########################################
  get getProductSettingById(): (id: string) => ProductSetting | undefined {
    return (id: string) => this.productSetting[id];
  }

  get getProductSettingsByPeriodId(): (periodId: string) => ProductSetting[] {
    return (periodId: string) => {
      if (this.periodProductSettings[periodId] == null) {
        return [];
      }

      const ids = this.periodProductSettings[periodId];

      const result: ProductSetting[] = [];

      ids.forEach((id) => {
        result.push(this.productSetting[id]);
      });

      return result;
    };
  }

  @Mutation
  updatePeriodProductSettings({
    periodId,
    productSettings,
  }: {
    periodId: string;
    productSettings: ProductSetting[];
  }): void {
    productSettings.forEach((setting) => {
      if (setting.period !== periodId) {
        throw new Error(
          "All product settings should belong to the specified period!"
        );
      }
    });

    const productSettingsIds: string[] = [];

    productSettings.forEach((setting) => {
      productSettingsIds.push(setting.id);
      Vue.set(this.productSetting, setting.id, setting);
    });

    Vue.set(this.periodProductSettings, periodId, productSettingsIds);
  }

  @Action({ rawError: true })
  async loadProductSettingsByPeriodId({
    periodId,
    useCache = true,
  }: {
    periodId: string;
    useCache?: boolean;
  }): Promise<ProductSetting[]> {
    if (useCache && this.periodProductSettings[periodId]) {
      const ids = this.periodProductSettings[periodId];

      const result: ProductSetting[] = [];

      ids.forEach((id) => {
        result.push(this.productSetting[id]);
      });

      return result;
    }

    const collection = await DIContainer.HolidayPeriodProductSettingRepository.getByPeriodId(
      periodId
    );

    const items = collection.getItems();

    this.updatePeriodProductSettings({
      periodId,
      productSettings: items,
    });

    return items;
  }

  @Action({ rawError: true })
  async saveProductSettingsForPeriod({
    periodId,
    values,
  }: {
    periodId: string;
    values: ProductSetting[];
  }): Promise<ProductSetting[]> {
    const items = await DIContainer.HolidayPeriodProductSettingRepository.updateForPeriod(
      periodId,
      values
    );

    this.updatePeriodProductSettings({
      periodId,
      productSettings: items,
    });

    return items;
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushieRelation[plushieId]) {
      return;
    }

    await this.loadPlushieRelationById({ id: plushieId, useCache: false });
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
