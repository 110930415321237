import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductionTimeNorm from "./production-time-norm.model";

export default class ProductionTimeNormFactory
  implements ObjectBuilderInterface<ProductionTimeNorm> {
  public buildFromJSON(data: { [key: string]: any }): ProductionTimeNorm {
    const value = new ProductionTimeNorm(
      data.id,
      data.factory,
      data.product.toString(),
      +data.days
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
