import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import QualityInspectionReport from "./quality-inspection-report.model";

export default class QualityInspectionReportFactory
  implements ObjectBuilderInterface<QualityInspectionReport> {
  public buildFromJSON(data: { [key: string]: any }): QualityInspectionReport {
    const value = new QualityInspectionReport(
      data.inspector,
      data.time_period,
      data.plushies_count
    );

    return value;
  }
}
