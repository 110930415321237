import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PenaltyRuleHistoryRecord from "./penalty-rule-history-record.model";

export default class PenaltyRuleHistoryRecordFactory
  implements ObjectBuilderInterface<PenaltyRuleHistoryRecord> {
  public buildFromJSON(data: { [key: string]: any }): PenaltyRuleHistoryRecord {
    const value = new PenaltyRuleHistoryRecord(
      data.id,
      data.userId,
      data.historyAction,
      data.factoryId,
      data.productId.toString(),
      +data.productionDaysDelay,
      +data.penaltyPercent
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
