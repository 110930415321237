import ApiService from "@/modules/api/api.service";

import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import BasicRepository from "../api/basic.repository";
import ObjectBuilderInterface from "../api/object-builder.interface";
import QaRejectionDetails from "./qa-rejection-details.model";

export default class QaRejectionDetailsRepository extends BasicRepository<QaRejectionDetails> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<QaRejectionDetails>,
    responseParser: ApiCollectionResponseParserService<QaRejectionDetails>
  ) {
    super("qa_rejection_details", api, objectBuilder, responseParser);
  }
}
