import { ThroughputReportTimePeriod } from "./throughput-report-time-period";

export default class ThroughputReport {
  constructor(
    private fFactoryName: string,
    private fTimePeriod: ThroughputReportTimePeriod,
    private fAssignedToFactory: number,
    private fDesigned: number,
    private fSubmittedToInspection: number,
    private fPassedInspection: number,
    private fBulkShipped: number
  ) {
    [
      fFactoryName,
      fTimePeriod,
      fAssignedToFactory,
      fDesigned,
      fSubmittedToInspection,
      fPassedInspection,
      fBulkShipped,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get factoryName(): string {
    return this.fFactoryName;
  }

  get timePeriod(): ThroughputReportTimePeriod {
    return this.fTimePeriod;
  }

  get assignedToFactory(): number {
    return this.fAssignedToFactory;
  }

  get designed(): number {
    return this.fDesigned;
  }

  get submittedToInspection(): number {
    return this.fSubmittedToInspection;
  }

  get passedInspection(): number {
    return this.fPassedInspection;
  }

  get bulkShipped(): number {
    return this.fBulkShipped;
  }
}
