

























































import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import IdGenerator from "@/lib/services/id-generator";

import PenaltyRuleEditItem from "./EditForm/EditItem.vue";

import FactoryInvoiceStore from "../../store";
import PenaltyRule from "../../penalty-rule.model";

interface EditItem {
  value?: PenaltyRule;
  id: string;
  isNew: boolean;
  isDirty: boolean;
}

@Component({
  components: {
    FormErrors,
    LoadingSpinner,
    PenaltyRuleEditItem,
    SubmitButton,
  },
})
export default class PenaltyRuleEditForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fIsLoading = false;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fPlushieDataStore: PlushieStore;

  private fRuleItems: EditItem[] = [];

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get ruleItems(): EditItem[] {
    return this.fRuleItems;
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public addItem(): void {
    const id = this.fIdGenerator.getId();

    this.fRuleItems.push({
      value: undefined,
      id: id,
      isDirty: false,
      isNew: true,
    });
  }

  public onItemChange(value: PenaltyRule | undefined, index: number): void {
    const item = this.fRuleItems[index];
    item.value = value;
    item.isDirty = true;
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  public onItemDelete(item: EditItem): void {
    const index = this.fRuleItems.indexOf(item);
    this.fRuleItems.splice(index, 1);
  }

  protected async performSubmit(): Promise<void> {
    const values = this.fRuleItems
      .map((item) => item.value)
      .filter((item): item is PenaltyRule => item !== undefined);

    const items = await this.fFactoryInvoiceDataStore.savePenaltyRulesForFactory(
      {
        factoryId: this.factoryId,
        values,
      }
    );

    this.fRuleItems = this.createEditItems(items);

    this.$emit("updated");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      const [rules] = await Promise.all([
        this.fFactoryInvoiceDataStore.loadPenaltyRulesByFactoryId({
          factoryId: this.factoryId,
          useCache: false,
        }),
        this.fPlushieDataStore.loadProducts(),
      ]);

      this.fRuleItems = this.createEditItems(rules);
    } finally {
      this.fIsLoading = false;
    }
  }

  private createEditItems(values: PenaltyRule[]): EditItem[] {
    const ruleItems: EditItem[] = [];

    const products = this.fPlushieDataStore.productsList;

    for (const product of products) {
      const items = values
        .filter((value) => value.product === product.id)
        .sort((a, b) =>
          a.productionDaysDelay > b.productionDaysDelay ? 1 : -1
        );

      if (!items.length) {
        continue;
      }

      for (const item of items) {
        ruleItems.push({
          value: item,
          id: item.id,
          isNew: false,
          isDirty: false,
        });
      }
    }

    return ruleItems;
  }
}
