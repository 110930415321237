import { MutableValue } from "./mutable-value.interface";

export class DirectoryValue implements MutableValue {
  public isNew = true;

  constructor(readonly id: string, readonly name: string) {}

  public toString(): string {
    return this.name;
  }
}
