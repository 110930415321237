export default class ErrorConverterService {
  public describeError(e: any): string[] {
    let result: string[] = [];

    if (e.messages) {
      const errors: string[] = [];

      Object.keys(e.messages).forEach((field) => {
        let message = e.messages[field];

        if (isNaN(Number(field)) && typeof message === "string") {
          message = `${field}: ${message}`;
        }

        errors.push(message);
      });

      result = errors;
    } else if (typeof e.message !== "undefined") {
      result = [e.message];
    } else {
      result = ["Sorry! Something went wrong. Try again later, please..."];
    }

    return result;
  }
}
