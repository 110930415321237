

























import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import rootStore from "@/store";
import ProductionProcessStore from "@/modules/production-process/store";
import DirectShipmentIntention from "@/modules/production-process/direct-shipment-intention.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class PlushieDirectShipmentIntention extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = false;
  private fIsDirectShipmentIntentionActionProcessing = false;

  private fProductionProcessStore: ProductionProcessStore;

  get changeDirectShipmentIntentionButtonText(): string {
    return this.directShipmentIntention
      ? "Remove Direct Shipment Intention"
      : "Mark for Direct Shipment";
  }

  get directShipmentIntention(): DirectShipmentIntention | undefined {
    return this.fProductionProcessStore.getDirectShipmentIntentionById(
      this.plushieId
    );
  }

  get isButtonDisabled(): boolean {
    return this.fIsDirectShipmentIntentionActionProcessing;
  }

  get showChangeDirectShipmentIntentionButton(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.DIRECT_SHIPMENT_INTENTION_MANAGE
    );
  }

  get showLoadingSpinner(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();
    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);
  }

  public async onChangeDirectShipmentIntentionButtonClick(): Promise<void> {
    if (this.fIsDirectShipmentIntentionActionProcessing) {
      return;
    }

    this.fIsDirectShipmentIntentionActionProcessing = true;

    try {
      if (this.directShipmentIntention) {
        await this.fProductionProcessStore.deleteDirectShipmentIntention(
          this.directShipmentIntention
        );
        return;
      }

      await this.fProductionProcessStore.createDirectShipmentIntention(
        new DirectShipmentIntention(this.plushieId)
      );
    } finally {
      this.fIsDirectShipmentIntentionActionProcessing = false;
    }
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    await this.fProductionProcessStore.loadDirectShipmentIntentionById(
      this.plushieId
    );

    this.fIsLoading = false;
  }
}
