import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import PlushieStore from "@/modules/plushie/store";

import CreateBulkShipmentDialog from "./components/CreateBulkShipmentDialog.vue";

import MassActionServiceInterface from "../plushie/mass-action-service.interface";

export default class CreateBulkShipmentService
  implements MassActionServiceInterface {
  public static readonly ID = "createBulkShipment";

  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  constructor() {
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async execute(plushieIds: string[]): Promise<boolean> {
    if (plushieIds.length < 1) {
      return false;
    }

    const factoryAssignments = await this.fFactoryDataStore.loadFactoryAssignmentsByPlushieIds(
      { plushieIds }
    );

    const assignmentIds = Object.keys(factoryAssignments);

    if (assignmentIds.length < 1) {
      return false;
    }

    const factoryId = factoryAssignments[assignmentIds[0]].factory;

    assignmentIds.forEach((assignmentId) => {
      if (factoryAssignments[assignmentId].factory !== factoryId) {
        throw new Error("All plushies should belong to same factory");
      }
    });

    const sameOrderPlushieIds = await this.getSameOrderPlushiesIds(plushieIds);

    await Promise.all([
      this.fPlushieDataStore.loadUpgrades(),
      this.fPlushieDataStore.loadPlushiesByIds([
        ...plushieIds,
        ...sameOrderPlushieIds,
      ]),
      this.fPlushieDataStore.loadPlushieUpgradesByPlushieIds({ plushieIds }),
      this.fFactoryDataStore.loadFactoryAssignmentsByPlushieIds({
        plushieIds: sameOrderPlushieIds,
      }),
    ]);

    const dialogFunction = create<Record<string, unknown>, boolean>(
      CreateBulkShipmentDialog
    );

    return await dialogFunction({
      plushieIds: plushieIds,
      factoryId: factoryId,
    });
  }

  private async getSameOrderPlushiesIds(
    plushieIds: string[]
  ): Promise<string[]> {
    const orderInformations = await this.fPlushieDataStore.loadOrderInformationsByPlushieIds(
      plushieIds
    );

    const orderIds: string[] = [];

    Object.keys(orderInformations).forEach((id) => {
      const orderInformation = orderInformations[id];

      orderIds.push(orderInformation.orderId);
    });

    const sameOrders = await this.fPlushieDataStore.loadOrderInformationsByOrderIds(
      orderIds
    );

    const sameOrderPlushieIds: string[] = [];

    Object.keys(sameOrders).forEach((orderId) => {
      const orderInformations = sameOrders[orderId];

      orderInformations.forEach((orderInformation) => {
        if (
          !plushieIds.includes(orderInformation.id) &&
          !sameOrderPlushieIds.includes(orderInformation.id)
        ) {
          sameOrderPlushieIds.push(orderInformation.id);
        }
      });
    });

    return sameOrderPlushieIds;
  }
}
