import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PenaltyRule implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fFactory: string,
    private fProduct: string,
    private fProductionDaysDelay: number,
    private fPenaltyPercent: number
  ) {
    [fId, fFactory, fProduct, fProductionDaysDelay, fPenaltyPercent].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get factory(): string {
    return this.fFactory;
  }

  get product(): string {
    return this.fProduct;
  }

  get productionDaysDelay(): number {
    return this.fProductionDaysDelay;
  }

  get penaltyPercent(): number {
    return this.fPenaltyPercent;
  }
}
