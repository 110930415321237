const autocompleteColorsList = [
  "1 (White)",
  "2 (Cream)",
  "3 (Caucasian)",
  "4 (Tan)",
  "5 (Light Brown)",
  "5B (Golden Brown)",
  "6 (Brown)",
  "7 (Dark Brown)",
  "8 (Pale Yellow)",
  "9 (Yellow)",
  "10 (Golden Yellow)",
  "11 (Light Orange)",
  "12 (Salmon)",
  "13 (Orange)",
  "14 (Rust)",
  "15 (Red)",
  "16 (Light Pink)",
  "17 (Bright Pink)",
  "18 (Maroon)",
  "19 (Light Purple)",
  "20 (Purple)",
  "21 (Light Blue)",
  "22 (Blue)",
  "23 (Navy Blue)",
  "24 (Teal)",
  "25 (Mint Green)",
  "26 (Lime Green)",
  "27 (Green)",
  "28 (Olive Green)",
  "29 (Dark Green)",
  "30 (Light Gray)",
  "31 (Gray)",
  "32 (Dark Gray)",
  "33 (Black)",
  "34 (Robins Egg)",
  "35 (Light green)",
  "36 (Fuschia)",
  "37 (Gold)",
  "14B (Auburn)",
];

export default autocompleteColorsList;
