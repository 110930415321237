import Vue from "vue";
import Vuex, { ModuleTree } from "vuex";
import VuexPersistence from "vuex-persist";

import ReportsStore, {
  name as ReportsStoreName,
} from "@/modules/reports/store";
import {
  UiPersisterStore,
  name as UiPersisterStoreName,
} from "@/modules/ui-persister/store";
import UiConfigurationStore, {
  name as UiConfigurationStoreName,
} from "@/modules/ui-configuration/store";

Vue.use(Vuex);

interface State {
  [ReportsStoreName]: ReportsStore;
  [UiPersisterStoreName]: UiPersisterStore;
  [UiConfigurationStoreName]: UiConfigurationStore;
}

const vuexLocal = new VuexPersistence<State>({
  reducer: (state: State) => {
    const savedState: Partial<State> = {};

    savedState[UiPersisterStoreName] = state[UiPersisterStoreName];
    (savedState[UiConfigurationStoreName] as Partial<UiConfigurationStore>) = {
      mainMenuCompact: state[UiConfigurationStoreName].mainMenuCompact,
    };
    (savedState[ReportsStoreName] as Partial<ReportsStore>) = {
      futureTurnaroundReportFormFieldsDefaultValues:
        state[ReportsStoreName].futureTurnaroundReportFormFieldsDefaultValues,
      productionEstimateReportFormFieldsDefaultValues:
        state[ReportsStoreName].productionEstimateReportFormFieldsDefaultValues,
    };

    return savedState;
  },
});

const staticModules: ModuleTree<State> = {};

staticModules[ReportsStoreName] = ReportsStore;
staticModules[UiPersisterStoreName] = UiPersisterStore;
staticModules[UiConfigurationStoreName] = UiConfigurationStore;

export default new Vuex.Store<State>({
  modules: staticModules,
  strict: process.env && process.env.NODE_ENV !== "production",
  plugins: [vuexLocal.plugin],
});
