







































































import Vue from "vue";
import { Component, Prop, Inject } from "vue-property-decorator";
import { TableOptions, ColumnFilterDropdownOption } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";
import DatePicker from "vuejs-datepicker";
import { mixins } from "vue-class-component";
import chunk from "lodash.chunk";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import FactoryStore from "@/modules/factory/store";
import PlushieStatusPeriodRepository from "@/modules/production-process/plushie-status-period.repository";
import PlushieRepository from "@/modules/plushie/plushie.repository";
import { Dictionary } from "@/lib/Dictionary.type";
import Plushie from "@/modules/plushie/plushie.model";
import PlushieStatusPeriod from "@/modules/production-process/plushie-status-period.model";

import QualityInspectionStore from "../../store";
import QaDecisionRejectionReasonRepository from "../../qa-decision-rejection-reason.repository";
import QaDecisionRepository from "../../qa-decision.repository";
import QaDecision from "../../qa-decision.model";

interface TableRow {
  id: string;
  plushieStoreItemId: string;
  plushieId: string;
  reason: string;
  reasonType: string;
  factory: string;
  reviewer: string;
  qiInspector: string;
  rejectionDate: string;
}

@Component({
  components: {
    LoadingSpinner,
    DatePicker,
    SubmitButton,
    FormField,
    FormErrors,
  },
})
export default class UseOfRejectionReasonReport extends mixins(
  GeneralFormMixin
) {
  @Prop({ default: "Show" })
  public readonly buttonText!: string;

  @Inject("PlushieRepository")
  private fPlushieRepository!: PlushieRepository;

  @Inject("PlushieStatusPeriodRepository")
  private fPlushieStatusPeriodRepository!: PlushieStatusPeriodRepository;

  @Inject("QaDecisionRejectionReasonRepository")
  private fQaDecisionRejectionReasonRepository!: QaDecisionRejectionReasonRepository;

  @Inject("QaDecisionRepository")
  private fQaDecisionRepository!: QaDecisionRepository;

  public fromDate?: Date;
  public toDate?: Date;

  private fQualityInspectionStore: QualityInspectionStore;
  private fAccountStore: AccountStore;
  private fFactoryStore: FactoryStore;

  private fOptions: TableOptions = {};
  private fTableData: TableRow[] = [];

  get columns(): string[] {
    return [
      "plushie",
      "reason",
      "reasonType",
      "factory",
      "reviewer",
      "qiInspector",
      "rejectionDate",
    ];
  }

  get options(): TableOptions {
    return this.fOptions;
  }

  get showReportTable(): boolean {
    return this.tableData.length > 0;
  }

  get tableData(): TableRow[] {
    return this.fTableData;
  }

  public constructor() {
    super();

    this.fQualityInspectionStore = getModule(QualityInspectionStore, dataStore);
    this.fAccountStore = getModule(AccountStore, dataStore);
    this.fFactoryStore = getModule(FactoryStore, dataStore);

    this.fOptions = {
      filterable: [
        "factory",
        "reasonType",
        "reason",
        "reviewer",
        "qiInspector",
      ],
      filterByColumn: true,
      listColumns: {},
      orderBy: {
        column: "rejectionDate",
        ascending: false,
      },
      headings: {
        plushie: "Plushie ID",
        reason: "Reason",
        reasonType: "Reason Type",
        factory: "Factory",
        reviewer: "Reviewer",
        qiInspector: "QI Inspector",
        rejectionDate: "Rejection Date",
      },
      initialPage: 1,
      perPage: 20,
      perPageValues: [10, 20, 30, 40, 50, 100],
    };
  }

  protected plushieUrl(plushieId: string): string {
    const route = this.$router.resolve({
      name: "PlushieViewPage",
      query: {
        id: plushieId,
      },
    });

    return route.href;
  }

  protected async created(): Promise<void> {
    await this.fFactoryStore.loadFactories();
  }

  protected async performSubmit(): Promise<void> {
    if (!this.fromDate || !this.toDate) {
      throw new Error("Not all required fields are filled!");
    }

    this.fromDate.setHours(0, 0, 0, 0);
    this.toDate.setHours(23, 59, 59, 999);

    await this.loadTableData(this.fromDate, this.toDate);
  }

  protected async loadTableData(fromDate: Date, toDate: Date): Promise<void> {
    const result: TableRow[] = [];

    const rejectionsCollection = await this.fQaDecisionRejectionReasonRepository.getListByDateRange(
      fromDate,
      toDate
    );

    const rejections = rejectionsCollection.getItems();

    if (!rejections) {
      this.fTableData = result;
      return;
    }

    const qaDecisionsIds = rejections.map((item) => item.qaDecision);
    const qaDecisionDictionary = await this.fQaDecisionRepository.getByIds(
      qaDecisionsIds
    );

    const plushieIds: string[] = [];
    const qaDecisionsList: QaDecision[] = [];

    Object.values(qaDecisionDictionary).forEach((item) => {
      if (!item) {
        return;
      }

      plushieIds.push(item.plushie);
      qaDecisionsList.push(item);
    });

    const plushiesChunks = chunk(plushieIds, 150);

    const approvementsLoadPromises = plushiesChunks.map((chunk) =>
      this.fPlushieStatusPeriodRepository.getReviewApproveRecordsByPlushies(
        chunk
      )
    );

    const plushiesLoadPromise = this.fPlushieRepository.getByIds(plushieIds);

    const [approvementsBatches, plushies] = await Promise.all([
      Promise.all(approvementsLoadPromises),
      plushiesLoadPromise,
    ]);

    const reviewApprovementsMap: Dictionary<PlushieStatusPeriod> = {};
    const reviewerIds: string[] = [];

    for (const batch of approvementsBatches) {
      for (const item of batch.getItems()) {
        reviewApprovementsMap[item.plushie] = item;
        reviewerIds.push(item.user);
      }
    }

    const plushiesMap: Dictionary<Plushie | undefined> = {};

    for (const plushieId in plushies) {
      plushiesMap[plushieId] = plushies[plushieId];
    }

    const qiInspectorIds = qaDecisionsList.map((item) => item.author);
    let rejectionReasonIds = rejections.map((item) => item.rejectionReason);
    rejectionReasonIds = Array.from(new Set([...rejectionReasonIds]));

    const usersIds = Array.from(new Set([...qiInspectorIds, ...reviewerIds]));

    await Promise.all([
      this.fAccountStore.loadUserInfosByIds(usersIds),
      this.fQualityInspectionStore.loadRejectionReasonsByIds(
        rejectionReasonIds
      ),
      this.fQualityInspectionStore.loadRejectionReasonTypes(),
      this.fFactoryStore.loadFactories(),
    ]);

    const factoriesInTheReport: Dictionary<string> = {};
    const reasonsInTheReport: Dictionary<string> = {};
    const reviewersInTheReport: Dictionary<string> = {};
    const inspectorsInTheReport: Dictionary<string> = {};
    const reasonTypesInTheReport: Dictionary<string> = {};

    rejections.forEach((item) => {
      const qaDecision = qaDecisionDictionary[item.qaDecision];

      const reviewApprovement = qaDecision
        ? reviewApprovementsMap[qaDecision.plushie]
        : undefined;

      const reviewer = reviewApprovement
        ? this.fAccountStore.getUserInfoById(reviewApprovement.user)
        : undefined;
      if (reviewer) {
        reviewersInTheReport[reviewer.id] = reviewer.fullName;
      }

      const qiInspector = qaDecision
        ? this.fAccountStore.getUserInfoById(qaDecision.author)
        : undefined;
      if (qiInspector) {
        inspectorsInTheReport[qiInspector.id] = qiInspector.fullName;
      }

      const plushie = qaDecision ? plushiesMap[qaDecision.plushie] : undefined;

      const factory =
        qaDecision && qaDecision.factory
          ? this.fFactoryStore.getFactoryById(qaDecision.factory)
          : undefined;

      if (factory) {
        factoriesInTheReport[factory.id] = factory.name;
      }

      const rejectionReason = this.fQualityInspectionStore.getRejectionReasonById(
        item.rejectionReason
      );
      if (rejectionReason) {
        reasonsInTheReport[rejectionReason.id] = rejectionReason.name;
      }

      const rejectionReasonType = rejectionReason
        ? this.fQualityInspectionStore.getRejectionReasonTypeById(
            rejectionReason.type
          )
        : undefined;
      if (rejectionReasonType) {
        reasonTypesInTheReport[rejectionReasonType.id] =
          rejectionReasonType.name;
      }

      result.push({
        id: item.id,
        plushieStoreItemId: plushie ? plushie.storeItemId : "",
        plushieId: plushie ? plushie.id : "",
        reason: rejectionReason ? rejectionReason.name : "",
        reasonType: rejectionReasonType ? rejectionReasonType.name : "",
        factory: factory ? factory.name : "",
        reviewer: reviewer ? reviewer.fullName : "",
        qiInspector: qiInspector ? qiInspector.fullName : "",
        rejectionDate: item.createdAt
          ? item.createdAt.toLocaleDateString()
          : "",
      });
    });

    this.setFieldFilterOptions(factoriesInTheReport, "factory");
    this.setFieldFilterOptions(reasonTypesInTheReport, "reasonType");
    this.setFieldFilterOptions(reasonsInTheReport, "reason");
    this.setFieldFilterOptions(reviewersInTheReport, "reviewer");
    this.setFieldFilterOptions(inspectorsInTheReport, "qiInspector");

    this.fTableData = result;
  }

  protected data(): Record<string, unknown> {
    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
  }

  private setFieldFilterOptions(
    items: Dictionary<string>,
    column: string
  ): void {
    const options: ColumnFilterDropdownOption[] = [];

    Object.values(items).forEach((value: string) => {
      options.push({ id: value, text: value });
    });

    options.sort((a, b) => (a.text > b.text ? 1 : -1));

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, column, options);
    }
  }
}
