import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieAttachment from "./plushie-attachment.model";

export default class PlushieAttachmentFactory
  implements ObjectBuilderInterface<PlushieAttachment> {
  public buildFromJSON(data: { [key: string]: any }): PlushieAttachment {
    const value = new PlushieAttachment(
      data.id,
      data.plushie,
      data.type,
      data.storageItemId
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
