

















































import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  ColumnFilterDropdownOption,
  RowClickEventData,
  ServerResponse,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";

import AccountStore from "../store";

interface TableRow {
  id: string;
  name: string;
  type: string;
  createdAt: string;
}

@Component({
  metaInfo: {
    title: "Organizations management",
  },
  components: {},
})
export default class OrganizationsListPage extends mixins(
  GeneralListPageMixin
) {
  private fAccountDataStore: AccountStore;

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "type",
        header: "Type",
        cssClass: "_type-column -text",
        isFilterable: true,
        isFilterFromList: true,
        isSortable: true,
        sortKey: "type.name",
      },
      {
        name: "createdAt",
        header: "Created",
        cssClass: "_createdat-column",
        isSortable: true,
        displayConstraint: "min_tabletL",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "name",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public async deleteItem(id: string): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fAccountDataStore.deleteOrganizationById(id);

      if (this.fTable) {
        this.fTable.refresh();
      }
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "OrganizationEditPage"));
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const [organizationsCollection] = await Promise.all([
      this.fAccountDataStore.loadOrganizations({
        filter,
        order,
      }),
      this.fAccountDataStore.loadOrganizationTypes(),
    ]);

    const result: TableRow[] = [];

    const organizationTypes = this.fAccountDataStore
      .organizationTypesDictionary;

    organizationsCollection.getItems().forEach((organization) => {
      const type = organizationTypes[organization.type];

      result.push({
        id: organization.id,
        name: organization.name,
        type: type ? type.name : "",
        createdAt: organization.createdAt
          ? organization.createdAt.toLocaleDateString()
          : "",
      });
    });

    return { data: result, count: organizationsCollection.totalItemsCount };
  }

  protected async created(): Promise<void> {
    await this.fAccountDataStore.loadOrganizationTypes();

    const types = this.fAccountDataStore.organizationTypes;

    const typeOptions: ColumnFilterDropdownOption[] = [];

    types.forEach((item) => {
      typeOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "type", typeOptions);
    }
  }
}
