


















import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import QualityInspectionStore from "../store";
import QaDecision from "../qa-decision.model";
import QaRejectionDetails from "../qa-rejection-details.model";

@Component({
  components: { LoadingSpinner },
})
export default class LastRejectionMessage extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = true;
  private fQualityInspectionDataStore: QualityInspectionStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get plushieLastRejection(): QaDecision | undefined {
    return this.fQualityInspectionDataStore.getPlushieLastRejection(
      this.plushieId
    );
  }

  get plushieLastRejectionDetails(): QaRejectionDetails | undefined {
    if (!this.plushieLastRejection) {
      return;
    }

    return this.fQualityInspectionDataStore.getRejectionDetailsById(
      this.plushieLastRejection.id
    );
  }

  get showLastRejectionMessage(): boolean {
    return !!this.plushieLastRejectionDetails;
  }

  get showContentTranslation(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get content(): string {
    if (!this.plushieLastRejectionDetails) {
      return "";
    }

    return this.plushieLastRejectionDetails.rejectionMessageText || "";
  }

  get contentTranslation(): string {
    if (!this.plushieLastRejectionDetails) {
      return "";
    }

    return (
      this.plushieLastRejectionDetails.rejectionMessageTextTranslation || ""
    );
  }

  public constructor() {
    super();

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  private async loadPlushieData(plushieId: string) {
    const lastRejection = await this.fQualityInspectionDataStore.loadPlushieLastRejection(
      {
        plushieId: plushieId,
      }
    );

    if (!lastRejection) {
      return;
    }

    return this.fQualityInspectionDataStore.loadRejectionDetailsById(
      lastRejection.id
    );
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.loadPlushieData(this.plushieId);

    this.fIsLoading = false;
  }
}
