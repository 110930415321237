import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { Dictionary } from "@/lib/Dictionary.type";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import UserRole from "./user-role.model";

export default class UserRoleRepository extends BasicRepository<UserRole> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<UserRole>,
    responseParser: ApiCollectionResponseParserService<UserRole>
  ) {
    super("user_roles", api, objectBuilder, responseParser);
  }

  public async getByUserId(userId: string): Promise<UserRole | undefined> {
    const items = await this.fetchList({ userId }, 1, 1);

    if (items.length === 0) {
      return;
    }

    const value = items.getByIndex(0);

    // this.cache[value.id] = value;

    return value;
  }

  public async getByUsersIds(
    userIds: string[]
  ): Promise<Dictionary<UserRole | undefined>> {
    const result: Dictionary<UserRole | undefined> = {};
    userIds.forEach((id) => {
      result[id] = undefined;
    });

    const items = await this.fetchList({ userId: userIds }, 1, userIds.length);

    items.getItems().forEach((item) => {
      result[item.user] = item;
      // this.cache[item.id] = item;
    });

    return result;
  }

  protected prepareData(item: UserRole): Record<string, string> {
    return {
      id: item.id,
      user: item.user,
      role: item.role,
    };
  }
}
