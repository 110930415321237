






























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";

import PlushiePriorityStore from "../store";
import { PriorityBonus } from "../priority-bonus";

@Component({
  components: {
    ActionButton,
  },
})
export default class RushBonusesManageCommands extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: false })
  public readonly disable!: boolean;

  private fPlushiePriorityDataStore: PlushiePriorityStore;

  get isDisabled(): boolean {
    return this.disable;
  }

  get showRush(): boolean {
    const plushiePriority = this.fPlushiePriorityDataStore.getPlushiePriorityByPlushieId(
      this.plushieId
    );

    if (!plushiePriority) {
      return false;
    }

    return (
      !plushiePriority.bonuses.includes(PriorityBonus.RUSH) &&
      !plushiePriority.bonuses.includes(PriorityBonus.SUPER_RUSH)
    );
  }

  get showSuperRush(): boolean {
    const plushiePriority = this.fPlushiePriorityDataStore.getPlushiePriorityByPlushieId(
      this.plushieId
    );

    if (!plushiePriority) {
      return false;
    }

    return !plushiePriority.bonuses.includes(PriorityBonus.SUPER_RUSH);
  }

  get showUnrush(): boolean {
    const plushiePriority = this.fPlushiePriorityDataStore.getPlushiePriorityByPlushieId(
      this.plushieId
    );

    if (!plushiePriority) {
      return false;
    }

    return (
      plushiePriority.bonuses.includes(PriorityBonus.RUSH) ||
      plushiePriority.bonuses.includes(PriorityBonus.SUPER_RUSH)
    );
  }

  constructor() {
    super();

    this.fPlushiePriorityDataStore = getModule(PlushiePriorityStore, dataStore);
  }

  public async rush(): Promise<void> {
    await this.fPlushiePriorityDataStore.addPriorityBonusForPlushie({
      plushieId: this.plushieId,
      bonusId: PriorityBonus.RUSH,
    });
  }

  public async superRush(): Promise<void> {
    await this.fPlushiePriorityDataStore.addPriorityBonusForPlushie({
      plushieId: this.plushieId,
      bonusId: PriorityBonus.SUPER_RUSH,
    });
  }

  public async unrush(): Promise<void> {
    const plushiePriority = this.fPlushiePriorityDataStore.getPlushiePriorityByPlushieId(
      this.plushieId
    );

    if (!plushiePriority) {
      return;
    }

    let priorityBonus;

    if (plushiePriority.bonuses.includes(PriorityBonus.RUSH)) {
      priorityBonus = PriorityBonus.RUSH;
    }

    if (plushiePriority.bonuses.includes(PriorityBonus.SUPER_RUSH)) {
      priorityBonus = PriorityBonus.SUPER_RUSH;
    }

    if (!priorityBonus) {
      throw new Error("Plushie Rush or Super Rush priority bonus is not found");
    }

    await this.fPlushiePriorityDataStore.removePriorityBonusForPlushie({
      plushieId: this.plushieId,
      bonusId: priorityBonus,
    });
  }

  private async getPlushieData(plushieId: string) {
    await Promise.all([
      this.fPlushiePriorityDataStore.loadPlushiePriorityByPlushieId({
        plushieId,
      }),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    await this.getPlushieData(this.plushieId);
  }
}
