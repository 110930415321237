export default class ApiRequestParamsBuilderService {
  public addItemToParams(params: URLSearchParams, field: string, value: any) {
    if (!(value instanceof Array)) {
      params.append(field, value);

      return params;
    }

    value.forEach((subValue: any) => {
      params.append(field + "[]", subValue);
    });

    return params;
  }

  public create(): URLSearchParams {
    return new URLSearchParams();
  }
}
