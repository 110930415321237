






































































import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import ImagesGalleryFullscreenModal from "@/modules/plushie/components/ImagesGalleryFullscreenModal.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import videoPlaceholderIcon from "@/assets/img/mime-types/video.svg";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import FileStorageStore from "@/modules/file-storage/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import ImagesGalleryFullscreenModalProp from "@/lib/images-gallery-fullscreen-modal-prop";
import PlushieStorageItemNameResolverService from "@/modules/plushie/plushie-storage-item-name-resolver.service";
import PlushieStore from "@/modules/plushie/store";
import Plushie from "@/modules/plushie/plushie.model";
import { Resource } from "@/modules/account/resource";
import BucketFileDownloaderService from "@/modules/file-storage/bucket-file-downloader.service";
import BucketFileHandlerService from "@/modules/file-storage/bucket-file-handler.service";

import getAttachmentThumbnail from "../../get-attachment-thumbnail.function";
import Attachment from "../../attachment.model";
import MessagingStore from "../../store";
import Message from "../../message.model";

interface DocumentAttachments {
  id: string;
  storageItem: string;
  name: string;
  mime: string;
}

@Component({
  components: {
    ActionButton,
  },
})
export default class MessagesHistoryAttachmentsGallery extends Vue {
  @Prop({ required: true })
  public readonly messageId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("PlushieStorageItemNameResolverService")
  private fPlushieStorageItemNameResolverService!: PlushieStorageItemNameResolverService;

  @Inject("MessageAttachmentHandlerService")
  private fAttachmentHandlerService!: BucketFileHandlerService;

  @Inject("MessageAttachmentDownloaderService")
  private fAttachmentDownloaderService!: BucketFileDownloaderService;

  @Inject("window")
  private fWindow!: Window;

  private fFileStorageDataStore: FileStorageStore;
  private fMessagingDataStore: MessagingStore;
  private fPlushieDataStore: PlushieStore;

  private fDownloadingAttachmentsDictionary: Dictionary<boolean> = {};
  private fCopyingAttachmentsDictionary: Dictionary<boolean> = {};
  private fCopiedAttachmentsDictionary: Dictionary<boolean> = {};

  get attachments(): Attachment[] {
    return this.fMessagingDataStore
      .getAttachmentsByMessageId(this.messageId)
      .filter((attachment) => !this.isDocument(attachment));
  }

  get containerClass(): string {
    const itemsCount = Math.min(this.attachments.length, 4);
    return `-items-${itemsCount}`;
  }

  get documentAttachments(): DocumentAttachments[] {
    const attachments: DocumentAttachments[] = [];
    this.fMessagingDataStore
      .getAttachmentsByMessageId(this.messageId)
      .forEach((attachment) => {
        if (!this.isDocument(attachment)) {
          return;
        }

        const storageItem = this.fFileStorageDataStore.getItemById(
          attachment.storageItem
        );

        if (!storageItem) {
          throw new Error("Storage item is not loaded");
        }

        if (!this.plushie) {
          throw new Error("Plushie is not loaded");
        }

        let name = storageItem.fileName;
        if (!name) {
          name = this.fPlushieStorageItemNameResolverService.resolve(
            storageItem,
            this.plushie
          );
        }

        attachments.push({
          id: attachment.id,
          storageItem: attachment.storageItem,
          name: name,
          mime: attachment.mime,
        });
      });

    return attachments;
  }

  get isTouchDevice(): boolean {
    return this.fWindow.matchMedia("(pointer: coarse)").matches;
  }

  get message(): Message | undefined {
    return this.fMessagingDataStore.getMessageById(this.messageId);
  }

  get plushie(): Plushie | undefined {
    if (!this.message) {
      return;
    }

    return this.fPlushieDataStore.getPlushieById(this.message.plushie);
  }

  get showGallery(): boolean {
    return !!this.attachments.length || !!this.documentAttachments.length;
  }

  public constructor() {
    super();
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async downloadAttachment(
    attachment: Pick<Attachment, "id" | "storageItem" | "mime">
  ): Promise<void> {
    if (this.isAttachmentDownloading(attachment)) {
      return;
    }

    Vue.set(this.fDownloadingAttachmentsDictionary, attachment.id, true);

    const storageItem = this.fFileStorageDataStore.getItemById(
      attachment.storageItem
    );

    if (!storageItem) {
      throw new Error("Storage item is not loaded");
    }

    if (!this.plushie) {
      throw new Error("Plushie is not loaded");
    }

    const plushieImageDownloadName = this.fPlushieStorageItemNameResolverService.resolve(
      storageItem,
      this.plushie
    );

    try {
      await this.fAttachmentDownloaderService.download(
        attachment,
        plushieImageDownloadName
      );
    } finally {
      Vue.delete(this.fDownloadingAttachmentsDictionary, attachment.id);
    }
  }

  public async copyAttachmentToArtwork(attachment: Attachment): Promise<void> {
    if (this.isAttachmentCopying(attachment)) {
      return;
    }

    if (!this.message) {
      throw new Error("Message is not defined when copy attachment to artwork");
    }

    const attachmentId = attachment.id;

    Vue.set(this.fCopyingAttachmentsDictionary, attachmentId, true);

    try {
      await this.fMessagingDataStore.copyAttachmentToArtwork({
        attachmentId,
        plushieId: this.message.plushie,
      });

      Vue.set(this.fCopiedAttachmentsDictionary, attachmentId, true);
    } finally {
      Vue.delete(this.fCopyingAttachmentsDictionary, attachmentId);
    }
  }

  public canCopyAttachmentToArtwork(attachment: Attachment): boolean {
    if (this.isVideo(attachment)) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_IMAGES_MANAGE);
  }

  public getCopyAttachmentToArtworkIcon(attachment: Attachment): string {
    return this.isAttachmentCopied(attachment) ? "fi-check" : "fi-page-copy";
  }

  public getCopyAttachmentToArtworkTitle(attachment: Attachment): string {
    const baseTitle = "Copy attachment to artworks";

    return this.isAttachmentCopied(attachment)
      ? `${baseTitle} (Already copied)`
      : baseTitle;
  }

  public getFullscreenThumbnail(attachment: Attachment): string {
    const url = this.getStorageItemUrl(attachment);
    return this.getThumbnail(url, 1920);
  }

  public getInlineThumbnail(attachment: Attachment): string {
    if (this.isVideo(attachment)) {
      return videoPlaceholderIcon;
    }

    const url = this.getStorageItemUrl(attachment);
    return getAttachmentThumbnail(url, this.fImageHandlerService);
  }

  public getStorageItemUrl(attachment: Attachment): string {
    const storageItem = this.fFileStorageDataStore.getItemById(
      attachment.storageItem
    );

    return storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;
  }

  public getThumbnail(url: string, size: number, shouldCrop = false): string {
    return this.fImageHandlerService.getThumbnailUrl(
      url,
      size,
      size,
      shouldCrop
    );
  }

  public isAttachmentCopying(attachment: Attachment): boolean {
    return !!this.fCopyingAttachmentsDictionary[attachment.id];
  }

  public isAttachmentCopied(attachment: Attachment): boolean {
    return !!this.fCopiedAttachmentsDictionary[attachment.id];
  }

  public isAttachmentDownloading(attachment: Pick<Attachment, "id">): boolean {
    return !!this.fDownloadingAttachmentsDictionary[attachment.id];
  }

  public launchGalleria(index: number): void {
    const images = this.attachments.map((attachment) => {
      const storageItem = this.fFileStorageDataStore.getItemById(
        attachment.storageItem
      );

      const fullScreenThumbnail = this.isVideo(attachment)
        ? videoPlaceholderIcon
        : this.getFullscreenThumbnail(attachment);

      const href = this.isVideo(attachment)
        ? this.fAttachmentHandlerService.getOriginalUrl(
            storageItem
              ? storageItem.key
              : this.fFileStorageDataStore.placeholderUrl
          )
        : fullScreenThumbnail;

      return {
        href: href,
        poster: fullScreenThumbnail,
        thumbnail: this.getInlineThumbnail(attachment),
        type: attachment.mime,
      };
    });

    const modalFunction = create(ImagesGalleryFullscreenModal);

    const props: ImagesGalleryFullscreenModalProp = {
      slides: images,
      index,
    };

    void modalFunction(props);
  }

  public shouldDisplayDownloadIconForAttachment(
    attachment: Attachment
  ): boolean {
    return !this.isAttachmentDownloading(attachment);
  }

  private isImage(attachment: Pick<Attachment, "mime">): boolean {
    return !!/^image\/(apng|avif|gif|jpeg|png|webp)$/.exec(attachment.mime);
  }

  private isDocument(attachment: Pick<Attachment, "mime">): boolean {
    return !(this.isImage(attachment) || attachment.mime.startsWith("video"));
  }

  private isVideo(attachment: Pick<Attachment, "mime">): boolean {
    return attachment.mime.startsWith("video");
  }
}
