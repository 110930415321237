/* eslint-disable import/order */
import Vue from "vue";
import App from "./App.vue";

import Meta from "vue-meta";

Vue.use(Meta);

import "foundation-sites";

// const jQuery           = require('jquery');
// (window as any).jQuery = jQuery;

import Vue2Filters from "vue2-filters";

Vue.use(Vue2Filters);

import nl2br from "@/lib/filters/nl2br.ts";

Vue.filter("nl2br", nl2br);

import { DIContainer } from "./app.container";

import dataStore from "./store";

import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

import { ServerTable, ClientTable } from "vue-tables-2-premium";

import Toasted from "vue-toasted";

Vue.use(Toasted);

import VueScreen from "vue-screen";

Vue.use(VueScreen, {
  extend: "foundation",
  mobile: 0,
  mobileP: 0,
  mobileL: 320,
  tablet: 480,
  tabletP: 480,
  tabletL: 768,
  desktop: 1024,
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
const zurbFoundationTheme = require("@/assets/vue-tables-2/themes/zurb-foundation");

import MyPerPageSelector from "@/lib/vue-tables-2/MyPerPageSelector.vue";
import MyPagination from "@/lib/vue-tables-2/MyPagination.vue";
import MyDataTable from "@/lib/vue-tables-2/MyDataTable.vue";

const vueTableOptions = {
  sortIcon: {
    base: "_sorting-icon fi-play",
    up: "-sorted-asc",
    down: "-sorted-desc",
    is: "-sorted-is",
  },
  texts: {
    limit: "per page",
  },
  pagination: {
    chunk: 4,
  },
  tabbable: false,
};

Vue.use(ServerTable, vueTableOptions, false, zurbFoundationTheme, {
  dataTable: MyDataTable,
  perPageSelector: MyPerPageSelector,
  pagination: MyPagination,
});

Vue.use(ClientTable, vueTableOptions, false, zurbFoundationTheme, {
  dataTable: MyDataTable,
  perPageSelector: MyPerPageSelector,
  pagination: MyPagination,
});

import * as ModalDialogs from "vue-modal-dialogs";

Vue.use(ModalDialogs);

Vue.config.productionTip = false;

const router = DIContainer.Router;

new Vue({
  router,
  store: dataStore,
  provide: DIContainer,
  render: (h) => h(App),
}).$mount("#app");
