import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Plushie from "./plushie.model";
import PlushieInformationUpdate from "./plushie-information-update.interface";
import { FieldOrderAsc } from "../api/field-order-options";
import { QueryOrderParameter } from "../api/query-order-parameter";

export default class PlushieRepository extends BasicRepository<Plushie> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Plushie>,
    responseParser: ApiCollectionResponseParserService<Plushie>
  ) {
    super("plushies", api, objectBuilder, responseParser);
  }

  public async getByStoreItemIds(ids: string[]): Promise<Dictionary<Plushie>> {
    const result: Dictionary<Plushie> = {};

    if (!ids.length) {
      return result;
    }

    ids = Array.from(new Set(ids));

    const items = await this.fetchList({ storeItemId: ids }, 1, ids.length);

    items.getItems().forEach((item) => {
      result[item.storeItemId] = item;
    });

    return result;
  }

  public async updatePlushieInformation(
    item: PlushieInformationUpdate
  ): Promise<Plushie> {
    const data: Dictionary<string | number> = {};

    if (item.name) {
      data.name = item.name;
    }

    if (item.description) {
      data.description = item.description;
    }

    if (item.breed !== undefined) {
      data.breed = item.breed;
    }

    const response = await this.api.put(
      this.resourceName + "/" + item.plushieId,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async getPlushieIdByStoreItemId(
    storeItemId: string
  ): Promise<string | undefined> {
    const params: Record<string, string | number | string[] | number[]> = {
      page: 1,
      itemsPerPage: 1,
      properties: ["id"],
      storeItemId: storeItemId,
    };

    const orderParams: QueryOrderParameter = [
      ["order[storeItemId]", FieldOrderAsc],
    ];

    const response = await this.api.get(
      this.resourceName,
      params,
      orderParams,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );

    const items = response.data as Dictionary<string>[];

    if (!Array.isArray(items) || items.length !== 1) {
      return undefined;
    }

    const item = items[0];

    return item.id.toString();
  }

  public async createPlushieQuantityUpdateRequest(
    plushieId: string,
    quantity: number
  ): Promise<Plushie> {
    const response = await this.api.post("plushie_quantity_update_requests", {
      plushieId,
      quantity,
    });

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: Plushie): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
      description: item.description,
    };

    if (item.name) {
      data.name = item.name;
    }

    if (item.breed) {
      data.breed = item.breed;
    }

    return data;
  }
}
