import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Vue from "vue";

import dataStore from "@/store";
import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";

import EmailLayout from "../email-layout.model";
import EmailTemplate from "../email-template.model";
import Sender from "../sender.model";

const name = "EmailManagementStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    emailLayout: {},
    emailTemplate: {},
    sender: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class EmailsManagementStore extends VuexModule {
  emailLayout: Dictionary<EmailLayout> = {};
  emailTemplate: Dictionary<EmailTemplate> = {};
  sender: Dictionary<Sender> = {};

  // ################################### EMAIL LAYOUTS #########################################

  get getEmailLayoutById(): (id: string) => EmailLayout | undefined {
    return (id: string) => {
      return this.emailLayout[id];
    };
  }

  get emailLayouts(): EmailLayout[] {
    return Array.from(Object.values(this.emailLayout));
  }

  @Mutation
  updateEmailLayout(emailLayout: EmailLayout): void {
    Vue.set(this.emailLayout, emailLayout.id, emailLayout);
  }

  @Action({ rawError: true })
  async loadEmailLayouts(useCache = true): Promise<EmailLayout[]> {
    if (useCache && !!this.emailLayouts.length) {
      return this.emailLayouts;
    }

    const items = await DIContainer.EmailLayoutRepository.getList(1, 999);

    const layouts = items.getItems();

    layouts.forEach((layout) => {
      this.updateEmailLayout(layout);
    });

    return layouts;
  }

  @Action({ rawError: true })
  async saveEmailLayout(emailLayout: EmailLayout): Promise<EmailLayout> {
    const updatedLayout = await DIContainer.EmailLayoutRepository.save(
      emailLayout
    );

    this.updateEmailLayout(updatedLayout);

    return updatedLayout;
  }

  // ################################### EMAIL TEMPLATES #########################################

  get getEmailTemplateById(): (id: string) => EmailTemplate | undefined {
    return (id: string) => {
      return this.emailTemplate[id];
    };
  }

  get emailTemplates(): EmailTemplate[] {
    return Array.from(Object.values(this.emailTemplate));
  }

  @Mutation
  updateEmailTemplate(emailTemplate: EmailTemplate): void {
    Vue.set(this.emailTemplate, emailTemplate.id, emailTemplate);
  }

  @Action({ rawError: true })
  async loadEmailTemplates(useCache = true): Promise<EmailTemplate[]> {
    if (useCache && !!this.emailTemplates.length) {
      return this.emailTemplates;
    }

    const items = await DIContainer.EmailTemplateRepository.getList(1, 999);

    const layouts = items.getItems();

    layouts.forEach((layout) => {
      this.updateEmailTemplate(layout);
    });

    return layouts;
  }

  @Action({ rawError: true })
  async saveEmailTemplate(
    emailTemplate: EmailTemplate
  ): Promise<EmailTemplate> {
    const updatedTemplate = await DIContainer.EmailTemplateRepository.save(
      emailTemplate
    );

    this.updateEmailTemplate(updatedTemplate);

    return updatedTemplate;
  }

  // ################################### SENDERS #########################################

  get sendersList(): Sender[] {
    return Object.values(this.sender);
  }

  @Mutation
  updateSender(sender: Sender): void {
    Vue.set(this.sender, sender.id, sender);
  }

  @Action
  async loadSenders(useCache = true): Promise<Sender[]> {
    if (useCache && !!this.sendersList.length) {
      return this.sendersList;
    }

    const items = await DIContainer.SenderRepository.getList(1, 999);
    const senders = items.getItems();

    senders.forEach((sender) => {
      this.updateSender(sender);
    });

    return senders;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
