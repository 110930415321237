












































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import autoindent from "@/lib/directives/auto-indent.directive";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import MessagingStore from "../../store";
import QuestionTemplate from "../../question-template.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class QuestionTemplateEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly questionTemplate?: QuestionTemplate;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public name?: string;
  public content?: string;

  private fMessagingDataStore: MessagingStore;

  constructor() {
    super();
    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      name: this.name,
      content: this.content,
    };
  }

  protected async performSubmit(): Promise<void> {
    let questionTemplate = this.generateQuestionTemplateObject();

    questionTemplate = await this.fMessagingDataStore.saveQuestionTemplate(
      questionTemplate
    );
    this.$emit("updated", questionTemplate);
  }

  private generateQuestionTemplateObject(): QuestionTemplate {
    if (!this.name || !this.content) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.questionTemplate
      ? this.questionTemplate.id
      : this.fIdGenerator.getId();

    const value = new QuestionTemplate(id, this.name, this.content);

    if (this.questionTemplate) {
      value.isNew = false;
    }

    return value;
  }

  @Watch("questionTemplate", { immediate: true })
  private _onQuestionTemplateChange() {
    this.name = undefined;
    this.content = undefined;

    if (this.questionTemplate === undefined) {
      return;
    }

    this.name = this.questionTemplate.name;
    this.content = this.questionTemplate.content;
  }
}
