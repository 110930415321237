import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class RemotePrintingCredentials implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(private fId: string, private fToken: string) {
    [fId, fToken].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get token(): string {
    return this.fToken;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
