import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Projection from "./projection.model";

export default class ProjectionFactory
  implements ObjectBuilderInterface<Projection> {
  public buildFromJSON(data: { [key: string]: any }): Projection {
    const value = new Projection(
      data.id,
      data.factoryId,
      Number.parseInt(data.productId),
      data.value
    );

    value.isNew = false;

    return value;
  }
}
