


































































































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";
import Raphael from "raphael";

import FormErrors from "@/lib/components/FormErrors.vue";
import ActionButton from "@/lib/components/ActionButton.vue";
import ImageRotationDialog from "@/modules/general/components/ImageRotationDialog.vue";
import MarkedUpImagesGalleryFullscreenModal from "@/modules/photo-markup/components/MarkedUpImagesGalleryFullscreenModal.vue";

import ErrorConverterService from "@/modules/forms/error-converter.service";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import UiConfigurationStore from "@/modules/ui-configuration/store";
import preloadImage from "@/lib/preload-image.function";
import { EntityType } from "@/modules/photo-markup/entity-type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import { Dictionary } from "@/lib/Dictionary.type";
import PhotoMarkupStore from "@/modules/photo-markup/store";
import ImagesGalleryFullscreenModalProp from "@/lib/images-gallery-fullscreen-modal-prop";
import MarkedUpImagesGalleryFullscreenModalProp from "@/modules/photo-markup/marked-up-images-gallery-fullscreen-modal-prop";
import BucketFileDownloaderService from "@/modules/file-storage/bucket-file-downloader.service";

import ImagesGalleryFullscreenModal from "./ImagesGalleryFullscreenModal.vue";

import PlushieStore from "../store";
import PlushieImage from "../plushie-image.model";
import PlushieImageType from "../plushie-image-type.model";
import { PlushieStatusValue } from "../plushie-status.value";
import PlushieStorageItemNameResolverService from "../plushie-storage-item-name-resolver.service";
import Plushie from "../plushie.model";

@Component({
  components: {
    ActionButton,
    FormErrors,
  },
})
export default class ArtworkGallery extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: true })
  public readonly isReadOnly!: boolean;

  @Inject("ArtworksDownloaderService")
  private fArtworksDownloaderService!: BucketFileDownloaderService;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("PlushieStorageItemNameResolverService")
  private fPlushieStorageItemNameResolverService!: PlushieStorageItemNameResolverService;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("window")
  private fWindow!: Window;

  private fActionErrors: string[] = [];

  private fIsLoading = true;
  private fIsDisabled = false;

  private fFileStorageDataStore: FileStorageStore;
  private fPhotoMarkupDataStore: PhotoMarkupStore;
  private fPlushieDataStore: PlushieStore;
  private fUiConfigurationDataStore: UiConfigurationStore;

  get actionErrors(): string[] {
    return this.fActionErrors;
  }

  get artworks(): PlushieImage[] {
    return this.fPlushieDataStore.getPlushieImagesByPlushieId(this.plushieId);
  }

  get canDelete(): boolean {
    if (this.isReadOnly) {
      return false;
    }

    if (!this.stageImage) {
      return false;
    }

    if (this.stageImage.type === PlushieImageType.MAIN) {
      return false;
    }

    if (this.stageImage.type === PlushieImageType.ADDITIONAL) {
      return true;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PLUSHIE_IMAGES_PRINT_ARTWORK_MANAGE
    );
  }

  get isMarkupAvailable(): boolean {
    if (!this.stageImage) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.PHOTO_MARKUPS_MANAGE)) {
      return false;
    }

    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (!plushie) {
      return false;
    }

    return [
      PlushieStatusValue.REVIEW,
      PlushieStatusValue.READY_FOR_PRODUCTION,
      PlushieStatusValue.IN_DESIGN,
      PlushieStatusValue.IN_PRODUCTION,
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.CUSTOMER_PREVIEW,
      PlushieStatusValue.REWORK,
      PlushieStatusValue.PENDING_BULK_PRODUCTION,
      PlushieStatusValue.IN_BULK_DESIGN,
      PlushieStatusValue.IN_BULK_PRODUCTION,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.BULK_INSPECTION,
      PlushieStatusValue.PPS_PREVIEW,
      PlushieStatusValue.BULK_PREVIEW,
    ].includes(plushie.status);
  }

  get canSetAsMain(): boolean {
    if (this.isReadOnly) {
      return false;
    }

    if (!this.stageImage) {
      return false;
    }

    return this.stageImage.type === PlushieImageType.ADDITIONAL;
  }

  get currentIndex(): number | undefined {
    return this.fUiConfigurationDataStore.getPlushieViewArtworkIndex(
      this.plushieId
    );
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isDisabled(): boolean {
    return this.fIsDisabled;
  }

  get isTouchDevice(): boolean {
    return this.fWindow.matchMedia("(pointer: coarse)").matches;
  }

  get stageImage(): PlushieImage | undefined {
    if (this.currentIndex == null) {
      return undefined;
    }

    return this.artworks[this.currentIndex];
  }

  get displayMarkup(): boolean {
    const displayMarkup = this.fUiConfigurationDataStore.getPlushieViewDisplayMarkup(
      this.plushieId
    );

    if (displayMarkup === undefined) {
      return true;
    }

    return displayMarkup;
  }

  get toggleMarkupButtonText(): string {
    return this.displayMarkup ? "Hide Markup" : "Show Markup";
  }

  get plushie(): Plushie | undefined {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  public constructor() {
    super();

    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPhotoMarkupDataStore = getModule(PhotoMarkupStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fUiConfigurationDataStore = getModule(UiConfigurationStore, dataStore);
  }

  public addMarkupToPreview(): void {
    if (!this.stageImage) {
      return;
    }

    const markup = this.fPhotoMarkupDataStore.getMarkupByImageId(
      this.stageImage.id
    );

    if (!markup) {
      return;
    }

    const stageImageElement = this.getStageImageElement();
    const stageImageWrapper = this.getStageImageWrapper();

    if (!stageImageElement || !stageImageWrapper) {
      return;
    }

    const paper = Raphael(
      stageImageWrapper,
      stageImageElement.offsetWidth,
      stageImageElement.offsetHeight
    );

    paper.setViewBox(0, 0, markup.markup.width, markup.markup.height, true);

    const sketchpad = Raphael.sketchpad(paper, {
      editing: false,
      svgClass: "_sketchpad-svg",
    });

    sketchpad.json(markup.markup.strokes);
  }

  public removeMarkupFromPreview(): void {
    const stageImageWrapper = this.getStageImageWrapper();

    if (!stageImageWrapper) {
      return;
    }

    const oldContainers = stageImageWrapper.getElementsByClassName(
      "_sketchpad-svg"
    );

    while (oldContainers.length > 0) oldContainers[0].remove();
  }

  public async downloadArtwork(artwork: PlushieImage): Promise<void> {
    const storageItem = this.fFileStorageDataStore.getItemById(
      artwork.storageItem
    );

    if (!storageItem) {
      throw new Error("Storage item data is not loaded");
    }

    if (!this.plushie) {
      throw new Error("Plushie data is not loaded");
    }

    const plushieImageDownloadName = this.fPlushieStorageItemNameResolverService.resolve(
      storageItem,
      this.plushie,
      this.currentIndex
    );

    await this.fArtworksDownloaderService.download(
      artwork,
      plushieImageDownloadName
    );
  }

  public doesHaveMarkup(artowrk: PlushieImage): boolean {
    return (
      this.fPhotoMarkupDataStore.getMarkupByImageId(artowrk.id) !== undefined
    );
  }

  public async rotateArtwork(artwork: PlushieImage): Promise<void> {
    const dialogFunction = create<Record<string, unknown>, boolean>(
      ImageRotationDialog
    );

    await dialogFunction({
      storageItemId: artwork.storageItem,
    });
  }

  public async downloadAll(): Promise<void> {
    const downloadPromises: Promise<void>[] = [];

    this.artworks.forEach((artwork, index) => {
      const storageItem = this.fFileStorageDataStore.getItemById(
        artwork.storageItem
      );

      if (!storageItem) {
        throw new Error("Storage item data is not loaded");
      }

      if (!this.plushie) {
        throw new Error("Plushie data is not loaded");
      }

      const plushieImageDownloadName = this.fPlushieStorageItemNameResolverService.resolve(
        storageItem,
        this.plushie,
        index
      );

      downloadPromises.push(
        this.fArtworksDownloaderService.download(
          artwork,
          plushieImageDownloadName
        )
      );
    });

    await Promise.all(downloadPromises);
  }

  public getImageLegend(): string {
    if (this.currentIndex == null) {
      return "";
    }

    const currentIndex = this.currentIndex + 1;

    const imagesQty = this.artworks.length;

    return `${currentIndex}/${imagesQty}`;
  }

  public getStageThumbnail(artwork: PlushieImage): string {
    return this.getThumbnail(artwork, 600);
  }

  public getFullscreenThumbnail(artwork: PlushieImage): string {
    return this.getThumbnail(artwork, 1920);
  }

  public getMarkupQuery(): Dictionary<string> | undefined {
    if (!this.stageImage) {
      return undefined;
    }

    return {
      plushieId: this.plushieId,
      imageId: this.stageImage.id,
      entityTypeId: EntityType.ARTWORK,
      returnUrl: this.$router.currentRoute.fullPath,
    };
  }

  public getThumbnail(
    artwork: PlushieImage,
    size: number,
    shouldCrop = false
  ): string {
    const storageItem = this.fFileStorageDataStore.getItemById(
      artwork.storageItem
    );

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      size,
      size,
      shouldCrop
    );
  }

  public launchGalleria(): void {
    this.displayMarkup
      ? this.launchMarkedUpGalleria()
      : this.launchRegularGalleria();
  }

  public async setAsMain(artwork: PlushieImage): Promise<void> {
    this.fIsDisabled = true;
    this.fActionErrors = [];

    try {
      await this.fPlushieDataStore.setPlushieImageAsMain(artwork);

      this.setCurrentIndex(0);
    } catch (error) {
      this.fActionErrors = this.fErrorConverterService.describeError(error);
    } finally {
      this.fIsDisabled = false;
    }

    return;
  }

  public async deleteImage(artwork: PlushieImage): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsDisabled = true;
    this.fActionErrors = [];

    try {
      await this.fPlushieDataStore.deletePlushieImage(artwork);

      if (this.currentIndex && this.currentIndex > this.artworks.length - 1) {
        this.setCurrentIndex(this.currentIndex - 1);
      }

      await this.fFileStorageDataStore.deleteItemById(artwork.storageItem);
    } catch (error) {
      this.fActionErrors = this.fErrorConverterService.describeError(error);
    } finally {
      this.fIsDisabled = false;
    }

    return;
  }

  public setCurrentIndex(index: number): void {
    this.fUiConfigurationDataStore.updatePlushieViewArtworkIndex({
      plushieId: this.plushieId,
      currentIndex: index,
    });
  }

  public toggleMarkupDisplay(): void {
    this.fUiConfigurationDataStore.updatePlushieViewDisplayMarkup({
      plushieId: this.plushieId,
      displayMarkup: !this.displayMarkup,
    });

    if (!this.displayMarkup) {
      this.removeMarkupFromPreview();
    } else {
      this.addMarkupToPreview();
    }
  }

  private getStageImageElement(): HTMLImageElement | undefined {
    return this.$refs["stageImage"] as HTMLImageElement | undefined;
  }

  private getStageImageWrapper(): HTMLElement | undefined {
    return this.$refs["stageImageWrapper"] as HTMLElement | undefined;
  }

  private launchMarkedUpGalleria(): void {
    if (this.currentIndex == null) {
      return;
    }

    const images = this.artworks.map((artwork) => {
      const markup = this.fPhotoMarkupDataStore.getMarkupByImageId(artwork.id);

      return {
        href: this.getFullscreenThumbnail(artwork),
        thumbnail: this.getThumbnail(artwork, 200, true),
        markup: markup ? markup.markup : undefined,
        type: "markedUpImage",
      };
    });

    const modalFunction = create(MarkedUpImagesGalleryFullscreenModal);

    const props: MarkedUpImagesGalleryFullscreenModalProp = {
      slides: images,
      index: this.currentIndex,
      currentIndexChangeHandler: (index) => this.setCurrentIndex(index),
    };

    void modalFunction(props);
  }

  private launchRegularGalleria(): void {
    if (this.currentIndex == null) {
      return;
    }

    const images = this.artworks.map((artwork) => {
      return {
        href: this.getFullscreenThumbnail(artwork),
        thumbnail: this.getThumbnail(artwork, 200, true),
      };
    });

    const modalFunction = create(ImagesGalleryFullscreenModal);

    const props: ImagesGalleryFullscreenModalProp = {
      slides: images,
      index: this.currentIndex,
      currentIndexChangeHandler: (index) => this.setCurrentIndex(index),
    };

    void modalFunction(props);
  }

  private async getPlushieData(plushieId: string) {
    const user = this.fUserProvider.getUser();

    const images = await this.fPlushieDataStore.loadPlushieImageByPlushieId({
      plushieId: plushieId,
    });

    if (user && user.hasPermissionForResource(Resource.PHOTO_MARKUPS_READ)) {
      const imagesIds = images.map((image) => image.id);

      setTimeout(() => {
        void this.loadMarkupData(imagesIds);
      }, 1000);
    }

    const storageItemsIds = images.map((image) => image.storageItem);
    await this.fFileStorageDataStore.loadItemsByIds(storageItemsIds);

    if (
      this.artworks.length > 0 &&
      (!this.currentIndex || this.currentIndex > this.artworks.length)
    ) {
      this.setCurrentIndex(0);
    }

    void this.preloadImages();
  }

  private async loadMarkupData(imageIds: string[]): Promise<void> {
    await this.fPhotoMarkupDataStore.loadMarkupByImageIds({
      imageIds,
    });

    if (this.displayMarkup) {
      this.addMarkupToPreview();
    }
  }

  private async preloadImages() {
    const storageItemsIds = this.artworks.map((artwork) => artwork.storageItem);
    await this.fFileStorageDataStore.loadItemsByIds(storageItemsIds);

    await Promise.all(
      this.artworks.map((artwork) =>
        preloadImage(this.getStageThumbnail(artwork))
      )
    );

    await Promise.all(
      this.artworks.map((artwork) =>
        preloadImage(this.getFullscreenThumbnail(artwork))
      )
    );
  }

  @Watch("artworks", { immediate: false })
  private async _onArtworksChange() {
    if (this.isLoading) {
      return;
    }

    await this.preloadImages();
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);

    this.fIsLoading = false;

    void this._onStageImageChange();
  }

  @Watch("stageImage", { immediate: false })
  private async _onStageImageChange() {
    await this.$nextTick();

    this.removeMarkupFromPreview();

    if (this.displayMarkup) {
      this.addMarkupToPreview();
    }
  }
}
