import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import InvoiceItem from "./invoice-item.model";
import { ResourceCollection } from "../api/resource.collection";
import InvoiceItemUpdate from "./invoice-item-update.interface";

export default class InvoiceItemRepository extends BasicRepository<InvoiceItem> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<InvoiceItem>,
    responseParser: ApiCollectionResponseParserService<InvoiceItem>
  ) {
    super("invoice_items", api, objectBuilder, responseParser);
  }

  public async getByInvoiceId(
    invoiceId: string
  ): Promise<ResourceCollection<InvoiceItem>> {
    return this.fetchList({ invoice: invoiceId }, 1, 9999);
  }

  public async updateItem(item: InvoiceItemUpdate): Promise<InvoiceItem> {
    const data: Dictionary<boolean> = {
      applyPenalty: item.applyPenalty,
    };

    const response = await this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }
}
