import HydraCollectionResponse from "./hydra-collection-response.interface";

export default function isHydraCollectionResponse(
  arg: unknown
): arg is HydraCollectionResponse {
  if (!(arg instanceof Object)) {
    return false;
  }

  const tmpArg = arg as Record<string | number | symbol, unknown> | unknown[];

  if (!("hydra:member" in tmpArg) || !("hydra:totalItems" in tmpArg)) {
    return false;
  }

  if ("hydra:view" in tmpArg) {
    if (!(tmpArg["hydra:view"] instanceof Object)) {
      return false;
    }

    if (!("@id" in tmpArg["hydra:view"])) {
      return false;
    }
  }

  return true;
}
