















import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { TableOptions } from "vue-tables-2-premium";

import rootStore from "@/store";
import BasicRepository from "@/modules/api/basic.repository";
import PlushieStore from "@/modules/plushie/store";

import ProductionTimeHistory from "../production-time-history.model";

interface TableRow {
  id: string;
  plushie: string;
  plushieSku: string;
  dueDate: Date;
  qaApprovementDate: Date;
  disablePenalty: boolean;
}

@Component
export default class ProductionTimeHistoryTable extends Vue {
  @Prop({ required: true })
  public readonly plushieIds!: string[];

  @Prop({ default: false })
  public readonly showGroupHeaders!: boolean;

  @Inject("ProductionTimeHistoryRepository")
  private fProductionTimeHistoryRepository!: BasicRepository<ProductionTimeHistory>;

  private fPlushieStore: PlushieStore;

  private fProductionTimeHistoryList: ProductionTimeHistory[] = [];

  get tableColumns(): string[] {
    return ["dueDate", "submitToQaDate", "qaApprovementDate", "disablePenalty"];
  }

  get tableData(): TableRow[] {
    return this.fProductionTimeHistoryList.map((item) => {
      const plushie = this.fPlushieStore.getPlushieById(item.plushie);

      if (!plushie) {
        throw new Error(`Plushie with id ${item.plushie} not found`);
      }

      return {
        id: item.id,
        plushie: item.plushie,
        plushieSku: plushie.storeItemId,
        dueDate: item.dueDate,
        submitToQaDate: item.submitToQaDate,
        qaApprovementDate: item.qaApprovementDate,
        disablePenalty: !item.applyPenalty,
      };
    });
  }

  get tableOptions(): TableOptions {
    return {
      groupBy: this.showGroupHeaders ? "plushieSku" : undefined,
      orderBy: { column: "dueDate", ascending: true },
      headings: {
        dueDate: "Due Date",
        submitToQaDate: "QA Submit Date",
        qaApprovementDate: "QA Approvement Date",
        disablePenalty: "Penalties Disabled",
      },
      columnsClasses: {
        dueDate: "_due-date-column",
        submitToQaDate: "_submit-to-qa-date-column",
        qaApprovementDate: "_qa-approvement-date-column",
        disablePenalty: "_disable-penalty-column",
      },
      toggleGroups: false,
      pagination: {
        show: false,
      },
      perPageValues: [],
      perPage: 99999,
      listColumns: {
        disablePenalty: [
          {
            id: "true",
            text: "Yes",
          },
          {
            id: "false",
            text: "No",
          },
        ],
      },
      sortable: ["dueDate", "qaApprovementDate"],
      templates: {
        dueDate: (h, row: ProductionTimeHistory) => {
          return row.dueDate.toLocaleDateString();
        },
        submitToQaDate: (h, row: ProductionTimeHistory) => {
          return row.submitToQaDate
            ? row.submitToQaDate.toLocaleDateString()
            : "";
        },
        qaApprovementDate: (h, row: ProductionTimeHistory) => {
          return row.qaApprovementDate.toLocaleDateString();
        },
      },
      rowClassCallback: (row: TableRow) => {
        if (row.dueDate < row.qaApprovementDate && !row.disablePenalty) {
          return "-warning";
        }
      },
    };
  }

  constructor() {
    super();

    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  protected created(): void {
    void this.loadTableData();
  }

  private async loadTableData(): Promise<void> {
    const [productionTimeHistoryCollection] = await Promise.all([
      this.fProductionTimeHistoryRepository.getList(1, 99999, {
        plushieId: this.plushieIds,
      }),
      this.fPlushieStore.loadPlushiesByIds(this.plushieIds),
    ]);

    this.fProductionTimeHistoryList = productionTimeHistoryCollection.getItems();
  }
}
