




















import { Component, Inject, Mixins, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import rootStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import CustomerPreviewStore from "../store";
import AutoApprovementExclude from "../auto-approvement-exclude.model";

@Component({
  components: {
    FormErrors,
    MoonLoader,
    SubmitButton,
  },
})
export default class AutoApprovementToggleForm extends Mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fCustomerPreviewStore: CustomerPreviewStore;

  private fIsDataLoading = true;

  get autoApprovementExclude(): AutoApprovementExclude | undefined {
    return this.fCustomerPreviewStore.getAutoApprovementExcludeById(
      this.plushieId
    );
  }

  get buttonText(): string {
    return this.autoApprovementExclude
      ? "Enable Auto Approvement"
      : "Disable Auto Approvement";
  }

  get canManageAutoApprovement(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.AUTO_APPROVEMENT_EXCLUDES_MANAGE
    );
  }

  get isLoading(): boolean {
    return this.fIsDataLoading;
  }

  get showButton(): boolean {
    return !this.fIsDataLoading && this.canManageAutoApprovement;
  }

  get showForm(): boolean {
    return this.canManageAutoApprovement || !!this.autoApprovementExclude;
  }

  constructor() {
    super();

    this.fCustomerPreviewStore = getModule(CustomerPreviewStore, rootStore);
  }

  protected created(): void {
    void this.loadData();
  }

  protected async performSubmit(): Promise<void> {
    if (this.autoApprovementExclude) {
      return this.fCustomerPreviewStore.deleteAutoApprovementExclude(
        this.plushieId
      );
    }

    await this.fCustomerPreviewStore.saveAutoApprovementExclude(
      new AutoApprovementExclude(this.plushieId)
    );
  }

  private async loadData(): Promise<void> {
    this.fIsDataLoading = true;

    await this.fCustomerPreviewStore.loadAutoApprovementExcludeById(
      this.plushieId
    );

    this.fIsDataLoading = false;
  }

  @Watch("plushieId")
  private _onPlushieIdChanged(): void {
    void this.loadData();
  }
}
