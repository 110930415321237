import { VueAuth } from "@websanova/vue-auth";

import EventBusInterface from "@/lib/interfaces/event-bus.interface";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";
import ApiErrorParserService from "@/modules/api/api-error-parser.service";

import User from "./user.model";

export default class UserRepository extends BasicRepository<User> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<User>,
    responseParser: ApiCollectionResponseParserService<User>,
    private errorParser: ApiErrorParserService,
    private auth: VueAuth,
    private eventBus: EventBusInterface
  ) {
    super("authentication/users", api, objectBuilder, responseParser);
  }

  public async login(login: string, password: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      void this.auth.login({
        data: { login, password },
        rememberMe: true,
        fetchUser: true,
        success: () => {
          const user = this.auth.user();

          this.eventBus.$emit("auth.login", user);

          resolve(user);
        },
        error: (error: any) => {
          const requestDescription = {
            resource: error.config.url,
            url: error.config.url,
            method: "POST",
            options: {},
            body: `{login: ${login}`,
          };

          reject(this.errorParser.parseError(error, requestDescription));
        },
      });
    });
  }

  protected prepareData(item: User): Dictionary<string> {
    const data: Dictionary<string> = {
      id: item.id,
      login: item.login,
    };

    if (item.password) {
      data.password = item.password;
    }

    return data;
  }
}
