














































































































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";

import { ImageHandlerOutputFormat } from "@/modules/file-storage/image-handler-output-format";
import FileStorageStore from "@/modules/file-storage/store";
import dataStore from "@/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import AccountStore from "@/modules/account/store";
import UserInfo from "@/modules/account/user-info.model";
import { MetaRoleValue } from "@/modules/account/meta-role.value";

import AlertIconUpload from "./AlertEditForm/IconUpload.vue";

import AlertType from "../alert-type.model";
import Alert from "../alert.model";
import AlertStore from "../store";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
    AlertIconUpload,
    Multiselect,
  },
})
export default class PlushieAlertEditForm extends mixins(GeneralFormMixin) {
  private static readonly ICON_SIZE = 45;
  private static readonly ALLOWED_USERS_META_ROLES = [
    MetaRoleValue.ROLE_ADMIN,
    MetaRoleValue.ROLE_STORE,
  ];

  @Prop()
  public readonly alert?: Alert;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  public title?: string;
  public icon?: string;
  public description?: string;
  public displayToFactory?: boolean;
  public isNotifyAboutMovements?: boolean;
  public recipientId?: string;

  private fAlertStore: AlertStore;
  private fFileStorageDataStore: FileStorageStore;
  private fAccountStore: AccountStore;

  private fIsIconUploadInProgress = false;
  private fAllowedRolesUsersUserInfos: UserInfo[] = [];
  private fUserInfosLoaded = false;

  get isIconUploadProcessingInProgress(): boolean {
    return this.fIsIconUploadInProgress;
  }

  get isDisabled(): boolean {
    return (
      this.isIconUploadProcessingInProgress || this.fIsDisabled || this.disable
    );
  }

  get isUploadDisabled(): boolean {
    return this.fIsDisabled || this.disable;
  }

  get recipient(): UserInfo | undefined {
    if (!this.recipientId) {
      return;
    }

    return this.fAccountStore.getUserInfoById(this.recipientId);
  }

  set recipient(value: UserInfo | undefined) {
    this.recipientId = value?.id;
  }

  get recipientOptionsList(): UserInfo[] {
    if (!this.fUserInfosLoaded) {
      return [];
    }

    return this.fAllowedRolesUsersUserInfos
      .filter((userInfo) => {
        return (
          !this.fAccountStore.deactivatedUser[userInfo.id] ||
          this.recipientId === userInfo.id
        );
      })
      .sort((a, b) => (a.fullName > b.fullName ? 1 : -1));
  }

  get showRecipientSelect(): boolean {
    return !!this.isNotifyAboutMovements && this.fUserInfosLoaded;
  }

  constructor() {
    super();
    this.fAlertStore = getModule(AlertStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fAccountStore = getModule(AccountStore, dataStore);
  }

  public created(): void {
    void this.loadUserInfos();
  }

  public onIconUploadIsBusyChanged(value: boolean): void {
    this.fIsIconUploadInProgress = value;
  }

  public onCancelClick() {
    this.$emit("cancel");
  }

  public getThumbnail() {
    if (!this.icon) {
      return;
    }

    const storageItem = this.icon
      ? this.fFileStorageDataStore.getItemById(this.icon)
      : null;

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      PlushieAlertEditForm.ICON_SIZE,
      PlushieAlertEditForm.ICON_SIZE,
      true,
      ImageHandlerOutputFormat.PNG
    );
  }

  protected data() {
    return {
      description: this.description,
      displayToFactory: this.displayToFactory,
      icon: this.icon,
      title: this.title,
      isNotifyAboutMovements: this.isNotifyAboutMovements,
      recipientId: this.recipientId,
    };
  }

  protected async performSubmit() {
    if (!this.icon) {
      throw new Error("Icon is required");
    }

    let alert = this.generateAlertObject();

    alert = await this.fAlertStore.savePlushieAlert(alert);
    this.$emit("updated", alert);
  }

  private generateAlertObject(): Alert {
    if (!this.title || !this.icon) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.alert ? this.alert.id : this.fIdGenerator.getId();

    const value = new Alert(
      id,
      this.title,
      this.icon,
      AlertType.TAG_BASED,
      !!this.displayToFactory,
      this.isNotifyAboutMovements,
      this.recipientId
    );

    value.description = this.description;

    if (this.alert) {
      value.isNew = false;
    }

    return value;
  }

  private async loadUserInfos(): Promise<void> {
    const [userInfos] = await Promise.all([
      this.fAccountStore.loadUserInfosByMetaRoleIds(
        PlushieAlertEditForm.ALLOWED_USERS_META_ROLES
      ),
      this.fAccountStore.loadDeactivatedUsers(),
    ]);

    this.fAllowedRolesUsersUserInfos = userInfos;
    this.fUserInfosLoaded = true;
  }

  @Watch("alert", { immediate: true })
  private _onAlertChange() {
    this.title = undefined;
    this.icon = undefined;
    this.description = undefined;
    this.displayToFactory = undefined;

    if (this.alert === undefined) {
      return;
    }

    this.title = this.alert.title;
    this.icon = this.alert.iconStorageId;
    this.description = this.alert.description;
    this.displayToFactory = this.alert.displayToFactory;
    this.isNotifyAboutMovements = this.alert.isNotifyAboutMovements;
    this.recipientId = this.alert.recipientId;
  }
}
