import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";

import ActiveUserFactory from "./active-user.factory";
import OtherUsersDetector from "./other-users-detector";

export default class OtherUsersDetectorFactory {
  constructor(
    private fActiveUserFactory: ActiveUserFactory,
    private fToaster: Vue,
    private fWindow: Window
  ) {}

  public create(
    socket: SocketIOClient.Socket,
    currentUserId: string
  ): OtherUsersDetector {
    const accountDataStore = getModule(AccountStore, dataStore);

    return new OtherUsersDetector(
      socket,
      currentUserId,
      this.fActiveUserFactory,
      accountDataStore,
      this.fToaster,
      this.fWindow
    );
  }
}
