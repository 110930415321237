












import { Component, Vue } from "vue-property-decorator";

import UserAddForm from "./UserAddForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Add User",
    };
  },
  components: {
    UserAddForm,
  },
})
export default class UserAddPage extends Vue {
  public onCancel(): void {
    void this.$router.push({ name: "UsersListPage" });
  }

  public onSuccess(): void {
    void this.$router.push({ name: "UsersListPage" });
  }
}
