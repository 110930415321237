

























































import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import ProductionProcessStore from "@/modules/production-process/store";

import StatusSummary from "../status-summary.model";

@Component({
  components: {
    LoadingSpinner,
    ActionButton,
  },
})
export default class StatusesSummaries extends Vue {
  @Prop({ default: "regular" })
  public readonly flowType!: "regular" | "batch";

  private fIsLoading = false;

  private fPlushieDataStore: PlushieStore;
  private fProductionProcessDataStore: ProductionProcessStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get statusesSummaries(): StatusSummary[] {
    switch (this.flowType) {
      case "regular":
        return this.fProductionProcessDataStore
          .getRegularFlowStatusesSummariesList;
      case "batch":
        return this.fProductionProcessDataStore
          .getBatchFlowStatusesSummariesList;
      default:
        throw new Error("Invalid Flow Type");
    }
  }

  get title(): string {
    switch (this.flowType) {
      case "regular":
        return "Production status";
      case "batch":
        return "Bulk production status";
      default:
        throw new Error("Invalid Flow Type");
    }
  }

  constructor() {
    super();

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  public getStatusName(statusId: string): string {
    const status = this.fPlushieDataStore.getPlushieStatusById(statusId);

    if (!status) {
      return "";
    }

    return status.name;
  }

  public async refreshStatusesSummaries(): Promise<void> {
    await this.loadData();
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fPlushieDataStore.loadPlushieStatuses(),
        this.fProductionProcessDataStore.loadStatusesSummaries(),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
