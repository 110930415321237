























import { Component, Vue, Inject, Prop } from "vue-property-decorator";

import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import StrictCapacityModeEditForm from "./EditPage/EditForm.vue";
import StrictCapacityModeViewForm from "./EditPage/ViewForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Factory Strict Capacity Mode Configuration",
    };
  },
  components: {
    StrictCapacityModeEditForm,
    StrictCapacityModeViewForm,
  },
})
export default class StrictCapacityModeEditPage extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  get canManageSpots(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_STRICT_CAPACITY_MODE_MANAGE
    );
  }

  public onCancel(): void {
    void this.$router.push({ name: "FactoryStrictCapacityModeManagementPage" });
  }

  public onUpdated(): void {
    void this.$router.push({ name: "FactoryStrictCapacityModeManagementPage" });
  }
}
