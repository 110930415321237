import io from "socket.io-client";

import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";

import CurrentUserStateReporterFactory from "./current-user-state-reporter.factory";
import CurrentUserStateReporter from "./current-user-state-reporter";
import OtherUsersDetectorFactory from "./other-users-detector.factory";
import OtherUsersDetector from "./other-users-detector";

export default class UserPresenceNotifier {
  private fSocket: SocketIOClient.Socket;
  private fCurrentUserStateReporter: CurrentUserStateReporter;
  private fOtherUsersDetector: OtherUsersDetector;

  constructor(
    currentUserStateReporterFactory: CurrentUserStateReporterFactory,
    otherUsersDetectorFactory: OtherUsersDetectorFactory,
    userProvider: AuthenticatedUserProvider,
    socketServerUrl: string,
    plushieId: string
  ) {
    const user = userProvider.getUser();

    if (!user) {
      throw new Error("User not found");
    }

    this.fSocket = io(socketServerUrl, {
      query: {
        room: `plushie_${plushieId}`,
        username: user.id,
      },
    });

    this.fCurrentUserStateReporter = currentUserStateReporterFactory.create(
      this.fSocket
    );

    this.fOtherUsersDetector = otherUsersDetectorFactory.create(
      this.fSocket,
      user.id
    );
  }

  public destroy(): void {
    this.fCurrentUserStateReporter.destroy();
    this.fOtherUsersDetector.destroy();
    this.fSocket.disconnect();
  }
}
