import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductionTimeAlert from "./production-time-alert.model";

export default class ProductionTimeAlertFactory
  implements ObjectBuilderInterface<ProductionTimeAlert> {
  public buildFromJSON(data: { [key: string]: any }): ProductionTimeAlert {
    const value = new ProductionTimeAlert(
      data.id,
      data.status_id.toString(),
      data.store_item_id,
      data.factory_id,
      data.priority_bonus_id,
      data.estimated_shipment_date,
      data.due_date
    );

    return value;
  }
}
