import InProductionDaysApiResponse from "./in-production-days-api-response.interface";

export default function isInProductionDaysApiResponse(
  arg: unknown
): arg is InProductionDaysApiResponse {
  if (!(arg instanceof Object)) {
    return false;
  }

  const requiredKeys = [
    "weekCount",
    "prevWeekCount",
    "monthCount",
    "prevMonthCount",
  ];
  const tempArg = arg as Record<string, number>;

  for (const key of requiredKeys) {
    if (typeof tempArg[key] !== "number") {
      return false;
    }
  }

  return true;
}
