export enum PlushieStatusValue {
  DRAFT = "1",
  CREATED = "2",
  CANCELLED = "3",
  REVIEW = "4",
  APPROVED = "5",
  AWAITING_ARTWORK = "7",
  READY_FOR_PRODUCTION = "8",
  IN_PRODUCTION = "9",
  READY_FOR_SHIPMENT = "10",
  SHIPPED_TO_MAIN_FACILITY = "11",
  SENT_TO_CUSTOMER = "12",
  QUALITY_INSPECTION = "13",
  ON_HOLD = "14",
  IN_DESIGN = "15",
  CUSTOMER_PREVIEW = "16",
  PACKING_FACILITY = "17",
  REWORK = "18",
  CUSTOMER_PREVIEW_APPROVED = "19",
  FINISHED_WITHOUT_SHIPPING = "20",
  AWAITING_DIRECT_SHIPMENT = "21",
  PENDING_BULK_PRODUCTION = "101",
  IN_BULK_DESIGN = "102",
  PPS_INSPECTION = "103",
  PPS_PREVIEW = "104",
  IN_BULK_PRODUCTION = "105",
  BULK_INSPECTION = "106",
  BULK_PREVIEW = "107",
  PENDING_BULK_SHIPMENT = "108",
  READY_FOR_BULK_SHIPMENT = "109",
  SHIPPED_TO_CUSTOMER = "110",
}
