















import { Component, Prop, Vue } from "vue-property-decorator";
import { create } from "vue-modal-dialogs";

import ActionButton from "@/lib/components/ActionButton.vue";

import ReviewApproveForm from "./Form.vue";
import ReviewApproveFullscreenModeDialog from "./FullscreenModeDialog.vue";

@Component({
  components: {
    ReviewApproveForm,
    ReviewApproveFullscreenModeDialog,
    ActionButton,
  },
})
export default class ReviewApproveCommand extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ required: true })
  public readonly isDisabled!: boolean;

  protected launchFullscreenMode(): void {
    const dialogFunction = create<Record<string, unknown>, void>(
      ReviewApproveFullscreenModeDialog
    );

    void dialogFunction({
      plushieId: this.plushieId,
      isDisabled: this.isDisabled,
    });
  }
}
