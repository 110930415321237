import { MutableValue } from "@/modules/api/mutable-value.interface";

import StorageItemReferenceInterface from "../file-storage/storage-item-reference.interface";

export default class PlushieAttachment
  implements MutableValue, StorageItemReferenceInterface {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fType: string,
    private fStorageItem: string
  ) {
    [fId, fPlushie, fType, fStorageItem].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get type(): string {
    return this.fType;
  }

  get storageItem(): string {
    return this.fStorageItem;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
