import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductionTimeAlert from "./production-time-alert.model";

export default class ProductionTimeAlertRepository {
  private readonly fResourceName = "production_time_stats";

  constructor(
    private api: ApiService,
    private objectBuilder: ObjectBuilderInterface<ProductionTimeAlert>
  ) {}

  public async fetchProductionTimeAlertStats(
    remainingDaysCount: number
  ): Promise<ProductionTimeAlert[]> {
    const response = await this.api.get(this.fResourceName, {
      remainingDaysCount,
    });

    return (response.data as any[]).map((item) =>
      this.objectBuilder.buildFromJSON(item)
    );
  }
}
