import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class UserFactoryRelation implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fUser: string,
    private fFactory: string,
    private fCreatedAt: Date
  ) {
    [fId, fUser, fFactory, fCreatedAt].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get user(): string {
    return this.fUser;
  }

  get factory(): string {
    return this.fFactory;
  }

  get createdAt(): Date {
    return this.fCreatedAt;
  }
}
