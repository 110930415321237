import { ReportTimePeriod } from "./report-time-period";

export default class QualityInspectionReport {
  constructor(
    private fInspector: string,
    private fTimePeriod: ReportTimePeriod,
    private fPlushiesCount: number
  ) {
    [fInspector, fTimePeriod, fPlushiesCount].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get inspector(): string {
    return this.fInspector;
  }

  get timePeriod(): ReportTimePeriod {
    return this.fTimePeriod;
  }

  get plushiesCount(): number {
    return this.fPlushiesCount;
  }
}
