























































import { Component, Inject, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  RowClickEventData,
  ServerResponse,
  ColumnFilterDropdownOption,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";
import PlushieStore from "@/modules/plushie/store";

import BarcodeStore from "../store";

interface TableRow {
  id: string;
  name: string;
  store: string;
}

@Component({
  metaInfo: {
    title: "Barcode Label Headers Management",
  },
  components: {},
})
export default class LabelHeadersListPage extends mixins(GeneralListPageMixin) {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fBarcodeDataStore: BarcodeStore;
  private fPlushieDataStore: PlushieStore;

  get canManageLabelHeaders(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.LABEL_HEADER_MANAGE);
  }

  public constructor() {
    super();

    this.fBarcodeDataStore = getModule(BarcodeStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "store",
        header: "Store",
        cssClass: "_store-column -text",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "storeId",
        isSortable: false,
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "name",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public async deleteItem(id: string): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fBarcodeDataStore.deleteLabelHeaderById(id);

      if (this.fTable) {
        this.fTable.refresh();
      }
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    if (!this.canManageLabelHeaders) {
      return;
    }

    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "LabelHeaderEditPage"));
  }

  protected async created(): Promise<void> {
    await this.fPlushieDataStore.loadStores();

    const stores = this.fPlushieDataStore.storesList;

    const storeOptions: ColumnFilterDropdownOption[] = [];

    stores.forEach((item) => {
      storeOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "store", storeOptions);
    }
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const [labelHeadersCollection] = await Promise.all([
      this.fBarcodeDataStore.loadLabelHeaders({
        page,
        limit,
        filter,
        order,
      }),
      this.fPlushieDataStore.loadStores(),
    ]);

    const result: TableRow[] = [];

    const stores = this.fPlushieDataStore.storesDictionary;

    const labelHeaders = labelHeadersCollection.getItems();

    labelHeaders.forEach((labelHeader) => {
      const store = labelHeader.storeId ? stores[labelHeader.storeId] : null;

      result.push({
        id: labelHeader.id,
        name: labelHeader.name,
        store: store ? store.name : "",
      });
    });

    return { data: result, count: labelHeadersCollection.totalItemsCount };
  }
}
