import {
  AxiosRequestConfig,
  AxiosPromise,
  AxiosStatic,
  AxiosInstance,
  AxiosError,
} from "axios";

import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { Dictionary } from "@/lib/Dictionary.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import ApiResponseHandlerService from "@/modules/api/api-response-handler.service";
import ApiRequestParamsBuilderService from "@/modules/api/api-request-params-builder.service";

import ApiSaveKeepaliveResponse from "./api-save-keepalive-response.interface";

export default class ApiService {
  public static readonly MAX_GUIDS_PER_GET_REQUEST = 150;
  private fServer: AxiosInstance;

  private readonly fHeaders = {
    Accept: "application/ld+json",
    "Content-type": "application/ld+json",
  };

  constructor(
    axios: AxiosStatic,
    private responseHandler: ApiResponseHandlerService,
    private paramsBuilder: ApiRequestParamsBuilderService,
    private host: string,
    private path: string
  ) {
    this.fServer = axios.create({
      baseURL: host,
      timeout: 100000,
      headers: this.fHeaders,
    });

    this.fServer.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (
          error.request.responseType === "blob" &&
          error.response &&
          error.response.data &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf("json") != -1
        ) {
          const getDataTextPromise = error.response.data.text();

          return getDataTextPromise.then((result: string) => {
            if (error.response) {
              error.response.data = JSON.parse(result);
            }

            return Promise.reject(error);
          });
        }

        return Promise.reject(error);
      }
    );
  }

  public buildUrl(url: string): string {
    const prefix = url.startsWith(this.path)
      ? this.host
      : this.host + this.path;

    if (prefix.endsWith("/") && url.startsWith("/")) {
      url = url.substr(1);
    }

    return prefix + url;
  }

  public getServer(): AxiosInstance {
    return this.fServer;
  }

  public delete(entityId: string): AxiosPromise {
    const url = this.buildUrl(entityId);
    const options: AxiosRequestConfig = {};

    const request = this.fServer.delete(url, options);

    const requestDescription = {
      resource: entityId,
      url,
      method: "DELETE",
      options,
    };

    return this.responseHandler.processResponse(request, requestDescription);
  }

  public get(
    resource: string,
    queryParams?: Dictionary<ApiFilterValue>,
    orderedParams?: QueryOrderParameter,
    requestOptions?: AxiosRequestConfig,
    useCache = true
  ): AxiosPromise {
    const url = this.buildUrl(resource);

    const params = this.paramsBuilder.create();

    if (queryParams != null) {
      Object.keys(queryParams).forEach((field) => {
        this.paramsBuilder.addItemToParams(params, field, queryParams[field]);
      });
    }

    if (!useCache) {
      this.paramsBuilder.addItemToParams(params, "timestamp", Date.now());
    }

    if (orderedParams != null) {
      orderedParams.forEach((item) => {
        this.paramsBuilder.addItemToParams(params, item[0], item[1]);
      });
    }

    const options: AxiosRequestConfig = { params, ...requestOptions };

    const request = this.fServer.get(url, options);

    const requestDescription = { resource, url, method: "GET", options };

    return this.responseHandler.processResponse(request, requestDescription);
  }

  public post(
    resource: string,
    fields: Dictionary<any> = {},
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
    requestOptions?: AxiosRequestConfig
  ): AxiosPromise {
    const url = this.buildUrl(resource);

    const options: AxiosRequestConfig = {
      onUploadProgress,
      ...requestOptions,
    };

    const request = this.fServer.post(url, fields, options);

    const requestDescription = {
      resource,
      url,
      method: "POST",
      options,
      body: JSON.stringify(fields),
    };

    return this.responseHandler.processResponse(request, requestDescription);
  }

  public put(entityId: string, fields: Dictionary<any>): AxiosPromise {
    const url = this.buildUrl(entityId);

    const options: AxiosRequestConfig = {};

    const request = this.fServer.put(url, fields, options);

    const requestDescription = {
      resource: entityId,
      url,
      method: "PUT",
      options,
      body: JSON.stringify(fields),
    };

    return this.responseHandler.processResponse(request, requestDescription);
  }

  public patch(entityId: string, fields: Dictionary<any>): AxiosPromise {
    const url = this.buildUrl(entityId);

    const options: AxiosRequestConfig = {};

    const request = this.fServer.patch(url, fields, options);

    const requestDescription = {
      resource: entityId,
      url,
      method: "PATCH",
      options,
      body: JSON.stringify(fields),
    };

    return this.responseHandler.processResponse(request, requestDescription);
  }

  public async postKeepalive(
    resource: string,
    fields: Dictionary<any> = {}
  ): Promise<ApiSaveKeepaliveResponse> {
    const authToken = localStorage.getItem("default_auth_token");
    const data = JSON.stringify(fields);
    const url = this.buildUrl(resource);

    const headers = {
      ...this.fHeaders,
      Authorization: `Bearer ${authToken ? authToken : ""}`,
    };

    // TODO Firefox doesn't support keepalive option, so need to use sync request
    const isFirefox =
      CSS && CSS.supports && CSS.supports("-moz-appearance:none");

    if (isFirefox) {
      const request = new XMLHttpRequest();
      request.open("POST", url, false);

      for (const [key, value] of Object.entries(headers)) {
        request.setRequestHeader(key, value);
      }

      request.send(data);
      return Promise.resolve({
        status: request.status,
        data: JSON.parse(request.response),
      });
    }

    const response = await fetch(url, {
      keepalive: true,
      method: "POST",
      headers,
      body: data,
    });

    const responseData = await response.json();

    return {
      status: response.status,
      data: responseData,
    };
  }
}
