











import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { DirectoryValue } from "@/modules/api/directory-value.model";

@Component
export default class UpgradesList extends Vue {
  @Prop({ required: true })
  public readonly upgrades!: DirectoryValue[];
}
