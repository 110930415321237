import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import MessagingStore from "./store";
import { UserGroupValue } from "./user-group.value";

export default class UserGroupResolverService {
  private fMessagingDataStore: MessagingStore;

  constructor() {
    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
  }

  public async resolveForUser(user: AuthenticatedUser): Promise<string> {
    switch (user.role) {
      case AuthenticatedUser.ROLE_ADMIN:
      case AuthenticatedUser.ROLE_STORE:
        return UserGroupValue.STORE;

      case AuthenticatedUser.ROLE_CUSTOMER:
        return UserGroupValue.CUSTOMER;
    }

    const relation = await this.fMessagingDataStore.loadUserGroupRelationByUserId(
      {
        userId: user.id,
      }
    );

    if (!relation) {
      throw new Error("The user group relation is not found");
    }

    return relation.userGroup;
  }
}
