





































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import RejectionReason from "../rejection-reason.model";
import QualityInspectionStore from "../store";
import { DirectoryValue } from "../../api/directory-value.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class RejectionReasonEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly rejectionReason?: RejectionReason;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public name?: string;
  public type?: string;
  public isActive?: boolean;

  private fQualityInspectionDataStore: QualityInspectionStore;

  get typeOptions(): DirectoryValue[] {
    return this.fQualityInspectionDataStore.rejectionReasonTypes;
  }

  constructor() {
    super();
    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      name: this.name,
      type: this.type,
      isActive: this.isActive,
    };
  }

  protected mounted(): void {
    void this.fQualityInspectionDataStore.loadRejectionReasonTypes();
  }

  protected async performSubmit(): Promise<void> {
    let rejectionReason = this.generateRejectionReasonObject();

    rejectionReason = await this.fQualityInspectionDataStore.saveRejectionReason(
      rejectionReason
    );
    this.$emit("updated", rejectionReason);
  }

  private generateRejectionReasonObject(): RejectionReason {
    if (!this.name || !this.type) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }
    const id = this.rejectionReason
      ? this.rejectionReason.id
      : this.fIdGenerator.getId();

    const value = new RejectionReason(
      id,
      this.name,
      this.type,
      this.isActive == true
    );

    if (this.rejectionReason) {
      value.isNew = false;
    }

    return value;
  }

  @Watch("rejectionReason", { immediate: true })
  private _onRejectionReasonChange() {
    this.name = undefined;
    this.type = undefined;
    this.isActive = undefined;

    if (this.rejectionReason === undefined) {
      return;
    }

    this.name = this.rejectionReason.name;
    this.type = this.rejectionReason.type;
    this.isActive = this.rejectionReason.isActive;
  }
}
