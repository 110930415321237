import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import MassActionServiceInterface from "@/modules/plushie/mass-action-service.interface";
import PlushieStore from "@/modules/plushie/store";
import { ProductValue } from "@/modules/plushie/product.value";

import ProductionProcessStore from "./store";

export default class BulkPrepareToShipmentService
  implements MassActionServiceInterface {
  public static readonly ID = "bulkPrepareToShipment";

  private fProductionProcessDataStore: ProductionProcessStore;
  private fPlushieDataStore: PlushieStore;

  constructor() {
    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async execute(plushieIds: string[]): Promise<boolean> {
    if (plushieIds.length < 1) {
      return false;
    }

    await this.fPlushieDataStore.loadPlushiesByIds(plushieIds);

    const plushies = this.fPlushieDataStore.getPlushiesByIds(plushieIds);

    for (const plushie of plushies) {
      const isTargetProduct = [
        ProductValue.PILLOW,
        ProductValue.BUDDY_PILLOW,
        ProductValue.PHRASE_PILLOW,
        ProductValue.PRINTED_SOCKS,
        ProductValue.PRINTED_MASKS,
        ProductValue.PRINTED_KEYCHAINS,
        ProductValue.CARTOON_PILLOW,
        ProductValue.FELTED_MAGNETS,
        ProductValue.FELTED_ORNAMENTS,
        ProductValue.RENAISSANCE_BLANKETS,
        ProductValue.CUT_OUT_BLANKETS,
        ProductValue.PAJAMAS,
        ProductValue.GOLF_SHIRTS,
        ProductValue.HAWAIIAN_SHIRTS,
        ProductValue.CUSTOMER_DESIGN_BLANKETS,
        ProductValue.TUMBLERS,
        ProductValue.PHOTO_PORTRAITS,
      ].includes(plushie.product);

      if (!isTargetProduct) {
        throw new Error(
          `Product ${plushie.product} can not be bulk prepared for shipment`
        );
      }
    }

    await this.fProductionProcessDataStore.bulkPrepareToShipment(plushieIds);

    return true;
  }
}
