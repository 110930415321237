import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";
import { ResourceCollection } from "@/modules/api/resource.collection";

import ElementPrice from "./element-price.model";

export default class ElementPriceRepository extends BasicRepository<ElementPrice> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ElementPrice>,
    responseParser: ApiCollectionResponseParserService<ElementPrice>
  ) {
    super("element_prices", api, objectBuilder, responseParser);
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<ResourceCollection<ElementPrice>> {
    return this.fetchList({ factoryId: factoryId }, 1, 9999);
  }

  public async saveItemsForFactory(
    items: ElementPrice[],
    factoryId: string
  ): Promise<ElementPrice[]> {
    const itemsData = items.map((item) => this.prepareData(item));

    const response = await this.api.post("element_prices/update_requests", {
      factory: factoryId,
      items: itemsData,
    });

    const result = this.responseParser.parse(response.data, this.objectBuilder);

    return result.getItems();
  }

  protected prepareData(item: ElementPrice): Dictionary<string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      factory: item.factory,
      type: item.type,
      entity: item.entity,
      qty: item.qty,
      price: item.price,
    };

    return data;
  }
}
