



























































































import { Component, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Multiselect from "vue-multiselect";
import { mixins } from "vue-class-component";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import FactoryStore from "@/modules/factory/store";
import Factory from "@/modules/factory/factory.model";
import Product from "@/modules/plushie/product.model";
import PlushieStore from "@/modules/plushie/store";
import { UiPersisterStore } from "@/modules/ui-persister/store";

import HolidayPeriod from "../../holiday-period.model";
import HolidayPeriodStore from "../../store";
import CutoffReportDownloadJobRepository from "../../cutoff-report-download-job.repository";

@Component({
  components: {
    LoadingSpinner,
    SubmitButton,
    FormField,
    FormErrors,
    Multiselect,
  },
})
export default class HolidayCutoffReportForm extends mixins(GeneralFormMixin) {
  @Inject("CutoffReportDownloadJobRepository")
  private fCutoffReportDownloadJobRepository!: CutoffReportDownloadJobRepository;

  @Inject("window")
  private fWindow!: Window;

  private fHolidayPeriodStore: HolidayPeriodStore;
  private fPlushieStore: PlushieStore;
  private fFactoryStore: FactoryStore;
  private fUiPersisterStore: UiPersisterStore;

  get periodOptions(): HolidayPeriod[] {
    return this.fHolidayPeriodStore.holidayPeriodsList;
  }

  get factoryOptions(): Factory[] {
    return this.fFactoryStore.factoriesList;
  }

  get productOptions(): Product[] {
    return this.fPlushieStore.productsList;
  }

  get factories(): Factory[] {
    const factories: Factory[] = [];
    const factoriesIds = this.fUiPersisterStore.getCutoffReportFactoriesIds;

    for (const factoryId of factoriesIds) {
      const factory = this.factoryOptions.find((item) => item.id === factoryId);

      if (factory) {
        factories.push(factory);
      }
    }

    return factories;
  }

  set factories(value: Factory[]) {
    this.fUiPersisterStore.updateCutoffReportFactoriesIds(
      value.map((item) => item.id)
    );
  }

  get period(): HolidayPeriod | undefined {
    const id = this.fUiPersisterStore.getCutoffReportHolidayPeriodId;

    if (!id) {
      return;
    }

    return this.periodOptions.find((item) => item.id === id);
  }

  set period(value: HolidayPeriod | undefined) {
    this.fUiPersisterStore.updateCutoffReportHolidayPeriodId(value?.id);
  }

  get products(): Product[] {
    const products: Product[] = [];
    const productsIds = this.fUiPersisterStore.cutoffReportProductsIds;

    for (const productId of productsIds) {
      const product = this.productOptions.find((item) => item.id === productId);

      if (product) {
        products.push(product);
      }
    }

    return products;
  }

  set products(value: Product[]) {
    this.fUiPersisterStore.updateCutoffReportProductsIds(
      value.map((item) => item.id)
    );
  }

  public constructor() {
    super();

    this.fHolidayPeriodStore = getModule(HolidayPeriodStore, dataStore);
    this.fPlushieStore = getModule(PlushieStore, dataStore);
    this.fFactoryStore = getModule(FactoryStore, dataStore);
    this.fUiPersisterStore = getModule(UiPersisterStore, dataStore);
  }

  protected async created(): Promise<void> {
    await Promise.all([
      this.fHolidayPeriodStore.loadHolidayPeriods(),
      this.fFactoryStore.loadFactories(),
      this.fPlushieStore.loadProducts(),
    ]);
  }

  protected async performSubmit(): Promise<void> {
    if (!this.period || !this.products) {
      throw new Error("Not all required fields are filled!");
    }

    const factoriesIds = this.factories.map((factory: Factory) => factory.id);
    const productsIds = this.products.map((product: Product) => product.id);

    const downloadUrl = await this.fCutoffReportDownloadJobRepository.createDownloadJob(
      this.period.id,
      factoriesIds,
      productsIds
    );

    this.fWindow.location.href = downloadUrl;
  }
}
