import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import UserInfo from "./user-info.model";

export default class UserInfoRepository extends BasicRepository<UserInfo> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<UserInfo>,
    responseParser: ApiCollectionResponseParserService<UserInfo>
  ) {
    super("app_users", api, objectBuilder, responseParser);
  }

  protected prepareData(item: UserInfo): Record<string, string> {
    return {
      id: item.id,
      organization: item.organization,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
    };
  }
}
