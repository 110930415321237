import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import OrderInformation from "./order-information.model";

export default class OrderInformationFactory
  implements ObjectBuilderInterface<OrderInformation> {
  public buildFromJSON(data: { [key: string]: any }): OrderInformation {
    const value = new OrderInformation(
      data.id,
      data.userId,
      data.orderId,
      data.orderDisplayId,
      +data.turnaroundTime,
      new Date(data.estimatedShipmentDate)
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
