import ApiService from "@/modules/api/api.service";

export default class CutoffReportDownloadJobRepository {
  constructor(protected resourceName: string, protected api: ApiService) {}

  public async createDownloadJob(
    periodId: string,
    factoryIds: string[],
    productIds: string[]
  ): Promise<string> {
    const convertedProductIds = productIds.map((id) => +id);

    const response = await this.api.post(`${this.resourceName}`, {
      period: +periodId,
      factories: factoryIds,
      products: convertedProductIds,
    });

    return (response.data as { downloadUrl: string }).downloadUrl;
  }
}
