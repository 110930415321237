import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Organization from "./organization.model";

export default class OrganizationRepository extends BasicRepository<Organization> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Organization>,
    responseParser: ApiCollectionResponseParserService<Organization>
  ) {
    super("organizations", api, objectBuilder, responseParser);
  }

  protected prepareData(item: Organization): Record<string, string> {
    return {
      id: item.id,
      name: item.name,
      type: item.type,
    };
  }
}
