import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import BulkShipment from "./bulk-shipment.model";
import BulkShipmentUpdate from "./bulk-shipment-update.interface";

export default class BulkShipmentRepository extends BasicRepository<BulkShipment> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BulkShipment>,
    responseParser: ApiCollectionResponseParserService<BulkShipment>
  ) {
    super("bulk_shipments", api, objectBuilder, responseParser);
  }

  public async getCsvByShipmentId(shipmentId: string): Promise<any> {
    return this.api.get(
      `${this.resourceName}/${shipmentId}/csv`,
      undefined,
      undefined,
      { responseType: "blob" }
    );
  }

  public async updateBulkShipment(
    item: BulkShipmentUpdate
  ): Promise<BulkShipment> {
    const data: Dictionary<string> = {};

    data.trackingNumber = item.trackingNumber;

    if (item.airwayBill) {
      data.airwayBill = item.airwayBill;
    }

    const response = await this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async publishBulkShipment(item: BulkShipment): Promise<BulkShipment> {
    const data = {
      id: item.id,
    };

    const response = await this.api.post(
      this.resourceName + "/published",
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: BulkShipment): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
      factoryId: item.factory,
    };

    if (item.trackingNumber) {
      data.trackingNumber = item.trackingNumber;
    }

    if (item.airwayBill) {
      data.airwayBill = item.airwayBill;
    }

    return data;
  }
}
