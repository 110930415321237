import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { ResourceCollection } from "@/modules/api/resource.collection";

import BulkShipmentItem from "./bulk-shipment-item.model";
import { BulkShipmentStatusValue } from "./bulk-shipment-status.value";

export default class BulkShipmentItemRepository extends BasicRepository<BulkShipmentItem> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BulkShipmentItem>,
    responseParser: ApiCollectionResponseParserService<BulkShipmentItem>
  ) {
    super("bulk_shipment_items", api, objectBuilder, responseParser);
  }

  public async getByShipmentIds(
    shipmentIds: string[]
  ): Promise<ResourceCollection<BulkShipmentItem>> {
    return this.fetchList({ shipment: shipmentIds });
  }

  public async getPublishedByPlushieId(
    plushieId: string
  ): Promise<BulkShipmentItem | undefined> {
    const collection = await this.fetchList(
      { plushieId, "bulkShipment.status": BulkShipmentStatusValue.PUBLISHED },
      1,
      1
    );

    return collection.getItems()[0];
  }

  public async saveItems(
    items: BulkShipmentItem[]
  ): Promise<ResourceCollection<BulkShipmentItem>> {
    const data: Dictionary<string | number>[] = [];

    items.forEach((item) => {
      data.push(this.prepareData(item));
    });

    const response = await this.api.post(this.resourceName, data);

    return this.responseParser.parse(response.data, this.objectBuilder);
  }

  protected prepareData(item: BulkShipmentItem): Dictionary<string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      shipmentId: item.shipment,
      plushieId: item.plushie,
      qty: item.qty,
    };

    return data;
  }
}
