import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Vue from "vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import { DIContainer } from "@/app.container";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import OperatorSession from "../operator-session.model";

const name = "StatisticsStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    operatorSession: {},
    operatorSessionsIdsForPlushieAndStatus: {},
  };
};

const getIdByPlushieIdAndStatus: (
  plushieId: string,
  plushieStatus: PlushieStatusValue
) => string = (plushieId, plushieStatus) => {
  return `${plushieId}-${plushieStatus}`;
};

@Module({ name, dynamic: true, store: dataStore })
export default class StatisticsStore extends VuexModule {
  operatorSession: Dictionary<OperatorSession> = {};
  operatorSessionsIdsForPlushieAndStatus: Dictionary<string[]> = {};

  // ################################### OPERATOR SESSIONS #########################################

  get getOperatorSessionsIdsByPlushieIdAndStatus(): ({
    plushieId,
    plushieStatus,
  }: {
    plushieId: string;
    plushieStatus: PlushieStatusValue;
  }) => string[] {
    return ({ plushieId, plushieStatus }) => {
      const operatorSessionsIds = this.operatorSessionsIdsForPlushieAndStatus[
        getIdByPlushieIdAndStatus(plushieId, plushieStatus)
      ];

      if (!operatorSessionsIds) {
        return [];
      }

      return operatorSessionsIds;
    };
  }

  get operatorSessionsByPlushieIdAndStatus(): ({
    plushieId,
    plushieStatus,
  }: {
    plushieId: string;
    plushieStatus: PlushieStatusValue;
  }) => OperatorSession[] {
    return ({ plushieId, plushieStatus }) => {
      const operatorSessionsIds = this.operatorSessionsIdsForPlushieAndStatus[
        getIdByPlushieIdAndStatus(plushieId, plushieStatus)
      ];

      if (!operatorSessionsIds) {
        return [];
      }

      return operatorSessionsIds.map(
        (operatorSessionId) => this.operatorSession[operatorSessionId]
      );
    };
  }

  @Mutation
  updateOperatorSession(payload: OperatorSession): void {
    Vue.set(this.operatorSession, payload.id, payload);
  }

  @Mutation
  updateOperatorSessionsIdsForPlushieAndStatus({
    plushieId,
    plushieStatus,
    operatorSessionIds,
  }: {
    plushieId: string;
    plushieStatus: PlushieStatusValue;
    operatorSessionIds: string[];
  }): void {
    const id = getIdByPlushieIdAndStatus(plushieId, plushieStatus);

    Vue.set(
      this.operatorSessionsIdsForPlushieAndStatus,
      id,
      operatorSessionIds
    );
  }

  @Action({ rawError: true })
  async loadOperatorSessions({
    plushieId,
    plushieStatus,
  }: {
    plushieId: string;
    plushieStatus: PlushieStatusValue;
  }): Promise<OperatorSession[]> {
    const collection = await DIContainer.OperatorSessionRepository.getList(
      1,
      999,
      {
        plushie: plushieId,
        plushieStatus,
      }
    );

    const items = collection.getItems();
    items.forEach((item) => this.updateOperatorSession(item));

    this.updateOperatorSessionsIdsForPlushieAndStatus({
      plushieId,
      plushieStatus,
      operatorSessionIds: items.map((item) => item.id),
    });

    return items;
  }

  @Action({ rawError: true })
  async saveOperatorSession({
    operatorSession,
  }: {
    operatorSession: OperatorSession;
  }): Promise<OperatorSession | undefined> {
    const item = await DIContainer.OperatorSessionRepository.saveKeepalive(
      operatorSession
    );

    this.updateOperatorSession(item);

    const operatorSessionsIds = this.getOperatorSessionsIdsByPlushieIdAndStatus(
      {
        plushieId: item.plushie,
        plushieStatus: item.plushieStatus,
      }
    );

    this.updateOperatorSessionsIdsForPlushieAndStatus({
      plushieId: item.plushie,
      plushieStatus: item.plushieStatus,
      operatorSessionIds: [...operatorSessionsIds, item.id],
    });

    return item;
  }

  // ################################### DATA WIPING #########################################
  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
