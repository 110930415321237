




import { Component, Vue, Prop } from "vue-property-decorator";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

@Component({
  components: { MoonLoader },
})
export default class InlineSpinner extends Vue {
  @Prop({ default: false })
  public readonly isDarkBackground!: boolean;

  get color(): string {
    return this.isDarkBackground ? "#fefefe" : "#777";
  }
}
