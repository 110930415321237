import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import QuestionTemplatesListPage from "./components/QuestionTemplate/QuestionTemplatesListPage.vue";
import QuestionTemplateEditPage from "./components/QuestionTemplate/QuestionTemplateEditPage.vue";
import QuestionTemplateAddPage from "./components/QuestionTemplate/QuestionTemplateAddPage.vue";

export default [
  {
    path: "/question_template/list",
    name: "QuestionTemplatesListPage",
    component: QuestionTemplatesListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/question_template/edit",
    name: "QuestionTemplateEditPage",
    component: QuestionTemplateEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["questionTemplateId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/question_template/add",
    name: "QuestionTemplateAddPage",
    component: QuestionTemplateAddPage,
    props: (route: Route) => {
      return listItemPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
];
