import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

import PhotoMarkup from "../photo-markup.model";
import PhotoMarkupEdit from "../photo-markup-edit.interface";
import PhotoMarkupCreate from "../photo-markup-create.interface";

const name = "PhotoMarkupStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    photoMarkup: {},
    markup: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class PhotoMarkupStore extends VuexModule {
  photoMarkup: Dictionary<string> = {};
  markup: Dictionary<PhotoMarkup> = {};

  // ################################### PHOTO MARKUPS #########################################

  get getMarkupByImageId(): (imageId: string) => PhotoMarkup | undefined {
    return (imageId: string) => {
      const markupId = this.photoMarkup[imageId];

      if (!markupId) {
        return undefined;
      }

      return this.markup[markupId];
    };
  }

  get markupById(): (id: string) => PhotoMarkup | undefined {
    return (id: string) => this.markup[id];
  }

  @Mutation
  updatePhotoMarkup(payload: PhotoMarkup): void {
    Vue.set(this.markup, payload.id, payload);
    Vue.set(this.photoMarkup, payload.image, payload.id);
  }

  @Action({ rawError: true })
  async loadMarkupByImageId({
    imageId,
    useCache = true,
  }: {
    imageId: string;
    useCache?: boolean;
  }): Promise<PhotoMarkup | undefined> {
    if (useCache && this.photoMarkup[imageId]) {
      return this.markup[this.photoMarkup[imageId]];
    }

    const markup = await DIContainer.PhotoMarkupRepository.getByImageId(
      imageId
    );

    if (!markup) {
      return undefined;
    }

    this.updatePhotoMarkup(markup);

    return markup;
  }

  @Action({ rawError: true })
  async loadMarkupByImageIds({
    imageIds,
    useCache = true,
  }: {
    imageIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<PhotoMarkup | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<PhotoMarkup | undefined> = {};

    imageIds.forEach((imageId) => {
      if (useCache && this.photoMarkup[imageId]) {
        result[imageId] = this.getMarkupByImageId(imageId);
        return;
      }

      missing.push(imageId);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.PhotoMarkupRepository.getByImageIds(
      missing
    );

    for (const item of items) {
      this.updatePhotoMarkup(item);

      result[item.image] = item;
    }

    return result;
  }

  @Action({ rawError: true })
  async createPhotoMarkup(data: PhotoMarkupCreate): Promise<PhotoMarkup> {
    const item = await DIContainer.PhotoMarkupRepository.createMarkup(data);

    this.updatePhotoMarkup(item);

    return item;
  }

  @Action({ rawError: true })
  async editPhotoMarkup(markupEdit: PhotoMarkupEdit): Promise<PhotoMarkup> {
    const item = await DIContainer.PhotoMarkupRepository.editMarkup(markupEdit);

    this.updatePhotoMarkup(item);

    return item;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
