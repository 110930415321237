import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PenaltyBypass implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUser?: string;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fType: string
  ) {
    [fId, fPlushie, fType].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get user(): string | undefined {
    return this.fUser;
  }

  get type(): string {
    return this.fType;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
