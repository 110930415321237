








































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import BarcodeStore from "../../store";
import RemotePrinter from "../../remote-printer.model";
import RemotePrintingConfig from "../../remote-printing-config.model";

@Component({
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class RemotePrinterConfigurationConfigEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly credentialsId?: string;

  public printer?: string;

  private fIsNew = true;
  private fIsLoading = true;
  private fIsPrintersFetchError = false;

  private fBarcodeDataStore: BarcodeStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isPrintersFetchError(): boolean {
    return this.fIsPrintersFetchError;
  }

  get printerOptions(): RemotePrinter[] {
    return this.fBarcodeDataStore.getRemotePrintersList;
  }

  constructor() {
    super();
    this.fBarcodeDataStore = getModule(BarcodeStore, dataStore);
  }

  public async refreshPrintersList(): Promise<void> {
    try {
      await this.fBarcodeDataStore.loadRemotePrinters();
      this.fIsPrintersFetchError = false;
    } catch {
      this.fIsPrintersFetchError = true;
    }
  }

  protected created(): void {
    void this.fBarcodeDataStore.loadRemotePrinters();
  }

  protected data(): Record<string, unknown> {
    return {
      printer: this.printer,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.credentialsId || !this.printer) {
      return;
    }

    let config = new RemotePrintingConfig(this.credentialsId, this.printer);

    config.isNew = this.fIsNew;

    config = await this.fBarcodeDataStore.saveRemotePrintingConfig(config);

    this.fIsNew = false;
  }

  @Watch("credentialsId", { immediate: true })
  private async _onCredentialsIdChange() {
    this.fIsLoading = true;

    this.printer = undefined;
    this.fIsNew = true;

    if (this.credentialsId === undefined) {
      return;
    }

    await this.refreshPrintersList();

    try {
      const config = await this.fBarcodeDataStore.loadRemotePrintingConfigById({
        id: this.credentialsId,
      });

      if (!config) {
        return;
      }

      this.fIsNew = false;

      this.printer = config.printer;
    } finally {
      this.fIsLoading = false;
    }
  }
}
