












import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import ProductionProcessStore from "@/modules/production-process/store";
import { Command } from "@/modules/production-process/command";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

@Component({
  components: {
    FormErrors,
    SubmitButton,
  },
})
export default class BasicCommand extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly commandId!: string;

  @Prop({ required: true })
  public readonly plushieId!: string;

  private fProductionProcessDataStore: ProductionProcessStore;

  get buttonText(): string {
    switch (this.commandId) {
      case Command.ApproveCustomerPreview:
        return "Approve";
      case Command.ReturnToDesign:
        return "Return to Design";
      case Command.ReturnToQualityInspection:
        return "Return to QA";
      case Command.ReturnToQualityInspectionFromCustomerPreview:
        return "Return to QA";
      case Command.PutOnHold:
        return "Put on Hold";
      case Command.MoveToDesign:
        return "Move to Design";
      case Command.MoveToProduction:
        return "Move to Production";
      case Command.PrepareForShipment:
        return "Prepare for Shipment";
      case Command.MoveToReview:
        return "Move to Review";
      case Command.ApproveQualityInspectionAndSkipReview:
        return "Approve & Skip Preview";
      case Command.SkipShipment:
        return "Skip Shipment";
      case Command.ApproveBulkPreview:
        return "Approve Bulk Preview";
      case Command.ApprovePpsPreview:
        return "Approve PPS Preview";
      case Command.MoveToDirectShipment:
        return "Move to Direct Shipment";
      case Command.ReturnIntoPreparedToShipment:
        return "Return to Ready For Shipment";
      default:
        throw new Error("Unknown command id!");
    }
  }

  constructor() {
    super();
    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  protected async performSubmit(): Promise<void> {
    switch (this.commandId) {
      case Command.ApproveCustomerPreview:
        await this.fProductionProcessDataStore.approveCustomerPreview(
          this.plushieId
        );
        break;
      case Command.ReturnToDesign:
        await this.fProductionProcessDataStore.returnToDesign(this.plushieId);
        break;
      case Command.ReturnToQualityInspection:
        await this.fProductionProcessDataStore.returnToQualityInspection(
          this.plushieId
        );
        break;
      case Command.ReturnToQualityInspectionFromCustomerPreview:
        await this.fProductionProcessDataStore.returnFromCustomerPreview(
          this.plushieId
        );
        break;
      case Command.PutOnHold:
        await this.fProductionProcessDataStore.putOnHold(this.plushieId);
        break;
      case Command.MoveToDesign:
        await this.fProductionProcessDataStore.moveToDesign(this.plushieId);
        break;
      case Command.MoveToProduction:
        await this.fProductionProcessDataStore.moveToProduction(this.plushieId);
        break;
      case Command.PrepareForShipment:
        await this.fProductionProcessDataStore.prepareForShipment(
          this.plushieId
        );
        break;
      case Command.MoveToReview:
        await this.fProductionProcessDataStore.moveToReview(this.plushieId);
        break;
      case Command.ApproveQualityInspectionAndSkipReview:
        await this.fProductionProcessDataStore.forceQualityInspectionApprove(
          this.plushieId
        );
        break;
      case Command.SkipShipment:
        await this.fProductionProcessDataStore.skipShipment(this.plushieId);
        break;
      case Command.ApproveBulkPreview:
        await this.fProductionProcessDataStore.approveBulkPreview(
          this.plushieId
        );
        break;
      case Command.ApprovePpsPreview:
        await this.fProductionProcessDataStore.approvePpsPreview(
          this.plushieId
        );
        break;
      case Command.MoveToDirectShipment:
        await this.fProductionProcessDataStore.moveToDirectShipment(
          this.plushieId
        );
        break;
      case Command.ReturnIntoPreparedToShipment:
        await this.fProductionProcessDataStore.returnIntoPreparedToShipment(
          this.plushieId
        );
        break;
      default:
        throw new Error("Unknown command id!");
    }
  }
}
