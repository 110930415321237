import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PlushiePriority implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fValue: number,
    private fBonuses: number[]
  ) {
    [fId, fValue, fBonuses].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get bonuses(): number[] {
    return this.fBonuses;
  }

  get value(): number {
    return this.fValue;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
