import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BlockingPlushie from "./blocking-plushie.model";

export default class BlockingPlushieFactory
  implements ObjectBuilderInterface<BlockingPlushie> {
  public buildFromJSON(data: { [key: string]: any }): BlockingPlushie {
    return new BlockingPlushie(data.plushie, data.factory);
  }
}
