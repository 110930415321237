import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import ShipmentsListPage from "./components/ShipmentsListPage.vue";
import BulkShipmentViewPage from "./components/BulkShipmentViewPage.vue";

export default [
  {
    path: "/bulk_shipment/list",
    name: "ShipmentsListPage",
    component: ShipmentsListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/bulk_shipment/view",
    name: "BulkShipmentViewPage",
    component: BulkShipmentViewPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["bulkShipmentId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
];
