






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import { UpgradeValue } from "@/modules/plushie/upgrade.value";

import CustomerPreviewStore from "../store";
import PreviewCountUpdate from "../preview-count-update.interface";

@Component({
  components: {
    ActionButton,
  },
})
export default class ExtraCustomerPreviewCommand extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: false })
  public readonly disable!: boolean;

  private fCustomerPreviewDataStore: CustomerPreviewStore;
  private fPlushieDataStore: PlushieStore;

  get isDisabled(): boolean {
    return this.disable;
  }

  get showAddExtraCustomerPreviewCommand(): boolean {
    const previewsCount = this.fCustomerPreviewDataStore.getPreviewCountByPlushieId(
      this.plushieId
    );

    if (!previewsCount) {
      return false;
    }

    return previewsCount.currentValue === previewsCount.maxValue;
  }

  get showRemoveExtraCustomerPreviewCommand(): boolean {
    const previewsCount = this.fCustomerPreviewDataStore.getPreviewCountByPlushieId(
      this.plushieId
    );

    if (!previewsCount) {
      return false;
    }

    if (previewsCount.currentValue === previewsCount.maxValue) {
      return false;
    }

    const plushieUpgrades = this.fPlushieDataStore.getPlushieUpgradesByPlushieId(
      this.plushieId
    );

    if (
      [
        UpgradeValue.SNEAK_PEEK,
        UpgradeValue.SPECIALTY_COMMISSION_SNEAK_PEEK,
        UpgradeValue.BULK_SAMPLE_SNEAK_PEEK,
      ].filter((value) => plushieUpgrades.includes(value)).length
    ) {
      return false;
    }

    return true;
  }

  constructor() {
    super();

    this.fCustomerPreviewDataStore = getModule(CustomerPreviewStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async addExtraCustomerPreviews(): Promise<void> {
    const previewsCount = this.fCustomerPreviewDataStore.getPreviewCountByPlushieId(
      this.plushieId
    );

    if (!previewsCount) {
      throw new Error("Previews Count for the plushie is not found");
    }

    const previewCountUpdate: PreviewCountUpdate = {
      id: previewsCount.id,
      maxValue: previewsCount.maxValue + 10,
    };

    await this.fCustomerPreviewDataStore.updatePreviewCountParams(
      previewCountUpdate
    );
  }

  public async removeExtraCustomerPreviews(): Promise<void> {
    const previewsCount = this.fCustomerPreviewDataStore.getPreviewCountByPlushieId(
      this.plushieId
    );

    if (!previewsCount) {
      throw new Error("Previews Count for the plushie is not found");
    }

    const previewCountUpdate: PreviewCountUpdate = {
      id: previewsCount.id,
      maxValue: previewsCount.currentValue,
    };

    await this.fCustomerPreviewDataStore.updatePreviewCountParams(
      previewCountUpdate
    );
  }

  private async updateData(plushieId: string) {
    await Promise.all([
      this.fCustomerPreviewDataStore.loadPreviewCountByPlushieId({
        plushieId: plushieId,
      }),
      this.fPlushieDataStore.loadPlushieUpgradesByPlushieId({
        plushieId: plushieId,
      }),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    await this.updateData(this.plushieId);
  }
}
