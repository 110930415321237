import { Options } from "blueimp-gallery/js/blueimp-gallery.min.js";
import { DialogComponent } from "vue-modal-dialogs";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ImagesGalleryFullscreenModal extends DialogComponent<undefined> {
  @Prop({ default: true })
  public readonly shouldCloseOnSwipe!: boolean;

  @Prop({ required: true })
  public readonly index!: number;

  @Prop({ default: () => ({}) })
  public readonly options!: Options;

  @Prop({
    default: () => {
      return (index: number) => {
        // default handler, do nothing
      };
    },
  })
  public readonly currentIndexChangeHandler!: (index: number) => void;

  @Prop({
    default: () => {
      return () => {
        // default handler, do nothing
      };
    },
  })
  public readonly galleryCloseHandler!: () => void;

  @Prop({
    default: () => {
      return () => {
        // default handler, do nothing
      };
    },
  })
  public readonly galleryClosedHandler!: () => void;

  public onGalleryClosedHandler(): void {
    this.$close(undefined);

    this.galleryClosedHandler();
  }
}
