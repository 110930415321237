import { MutableValue } from "@/modules/api/mutable-value.interface";

import { ProductAllocationSlotTypeValue } from "./product-allication-slot-type.value";

export default class ProductAllocation implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fFactory: string,
    private fProduct: string,
    private fDistributionPercent: number,
    private fSlotType: ProductAllocationSlotTypeValue
  ) {
    [fId, fFactory, fProduct, fDistributionPercent].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get factory(): string {
    return this.fFactory;
  }

  get product(): string {
    return this.fProduct;
  }

  get slotType(): ProductAllocationSlotTypeValue {
    return this.fSlotType;
  }

  get distributionPercent(): number {
    return this.fDistributionPercent;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
