import { ApiError } from "./api.error";
import { ApiRequestDescription } from "./api-request-description";

export default class ApiErrorParserService {
  public parseError(
    error: any,
    requestDescription: ApiRequestDescription
  ): ApiError {
    let messages = null;

    if (
      typeof error.response !== "undefined" &&
      Object.prototype.hasOwnProperty.call(error.response, "data")
    ) {
      if (Object.prototype.hasOwnProperty.call(error.response.data, "errors")) {
        const errors = error.response.data.errors;
        messages =
          Array.isArray(errors) || typeof errors === "object"
            ? errors
            : [errors];
      } else if (
        Object.prototype.hasOwnProperty.call(error.response.data, "message")
      ) {
        messages = [error.response.data.message];
      } else if (
        Object.prototype.hasOwnProperty.call(error.response.data, "error")
      ) {
        messages = [error.response.data.error];
      }
    }

    return new ApiError(
      "Request failed!",
      error.request.status,
      error.request.statusText,
      requestDescription,
      error,
      messages
    );
  }
}
