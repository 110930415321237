











import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";
import PlushieStore from "@/modules/plushie/store";

import FactoryInvoiceStore from "../../../store";
import HistoryRecord from "../../../penalty-rule-history-record.model";

@Component({
  components: {},
})
export default class PenaltyRuleHistoryRecord extends Vue {
  @Prop({ required: true })
  public readonly historyRecord!: HistoryRecord;

  private fAccountDataStore: AccountStore;
  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fPlushieDataStore: PlushieStore;

  get createdAt(): string {
    return this.historyRecord.createdAt
      ? this.historyRecord.createdAt.toLocaleString()
      : "";
  }

  get product(): string {
    const product = this.fPlushieDataStore.getProductById(
      this.historyRecord.product
    );

    if (!product) {
      return "";
    }

    return product.name;
  }

  get action(): string {
    const action = this.fFactoryInvoiceDataStore.getHistoryActionById(
      this.historyRecord.action
    );

    if (!action) {
      return "";
    }

    return action.name;
  }

  get author(): string {
    const userInfo = this.fAccountDataStore.getUserInfoById(
      this.historyRecord.user
    );

    if (userInfo === undefined) {
      return "";
    }

    const userRelations = this.fAccountDataStore.getUserRelationsByUserId(
      this.historyRecord.user
    );

    if (userRelations === undefined || userRelations.userRole === undefined) {
      return "";
    }

    const userRole = this.fAccountDataStore.getUserRoleById(
      userRelations.userRole
    );

    if (userRole === undefined) {
      return "";
    }

    const roleId = userRole.role;

    const role = this.fAccountDataStore.getRoleById(roleId);

    if (role === undefined) {
      return "";
    }

    return `${userInfo.firstName} ${userInfo.lastName} (${role.name})`;
  }

  constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }
}
