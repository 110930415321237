




































import { Component, Prop, Vue } from "vue-property-decorator";

import ProgressBar from "./ProgressBar.vue";

import AverageQaRoundsApiResponse from "../../average-qa-rounds-api-response.interface";
import ProgressBarStep from "../../progress-bar-step.interface";
import getStatsDifference from "../../get-stats-difference.function";
import StatsDifference from "../../stats-difference.interface";

const maxProgressBarValue = 3;
const minProgressBarValue = 0;

@Component({
  components: {
    ProgressBar,
  },
})
export default class PerformanceReportAverageQaRoundsSection extends Vue {
  @Prop({ required: true })
  public readonly averageQaRoundsApiResponse!: AverageQaRoundsApiResponse;

  get monthAverageRoundsProgressBarValue(): number {
    return (
      (this.averageQaRoundsApiResponse.monthAvgRounds / maxProgressBarValue) *
      100
    );
  }

  get monthDifference(): StatsDifference {
    return getStatsDifference(
      this.averageQaRoundsApiResponse.monthAvgRounds,
      this.averageQaRoundsApiResponse.prevMonthAvgRounds,
      true
    );
  }

  get monthDifferenceValue(): string {
    return this.monthDifference.value.toLocaleString(
      "en-US",
      this.numberFormatParams
    );
  }

  get numberFormatParams(): Intl.NumberFormatOptions {
    return {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    };
  }

  get weekDifference(): StatsDifference {
    return getStatsDifference(
      this.averageQaRoundsApiResponse.weekAvgRounds,
      this.averageQaRoundsApiResponse.prevWeekAvgRounds,
      true
    );
  }

  get weekDifferenceValue(): string {
    return this.weekDifference.value.toLocaleString(
      "en-US",
      this.numberFormatParams
    );
  }

  get progressBarText(): string {
    return `${this.averageQaRoundsApiResponse.monthAvgRounds} rounds`;
  }

  get progressBarSteps(): ProgressBarStep[] {
    return [
      {
        name: minProgressBarValue.toString(),
        color: "green",
      },
      {
        name: maxProgressBarValue.toString(),
        color: "red",
      },
    ];
  }
}
