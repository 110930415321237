import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class TagAlertAssignRule implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fTagName: string,
    private fAlert: string
  ) {
    [fId, fTagName, fAlert].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get tagName(): string {
    return this.fTagName;
  }

  get alert(): string {
    return this.fAlert;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
