import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { ResourceCollection } from "@/modules/api/resource.collection";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import ActionLogRecord from "./action-log-record.model";
import { FieldOrderDesc } from "../api/field-order-options";

export default class ActionLogRecordRepository extends BasicRepository<ActionLogRecord> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ActionLogRecord>,
    responseParser: ApiCollectionResponseParserService<ActionLogRecord>
  ) {
    super("/action-logger/logs", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(
    plushieId: string
  ): Promise<ResourceCollection<ActionLogRecord>> {
    return this.fetchList({ plushieId: plushieId }, 1, 9999, [
      ["createdAt", FieldOrderDesc],
    ]);
  }
}
