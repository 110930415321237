


























































import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import IdGenerator from "@/lib/services/id-generator";

import PriceConfigurationEditItem from "./EditForm/EditItem.vue";

import FactoryInvoiceStore from "../../store";
import ElementPrice from "../../element-price.model";
import { DirectoryValue } from "../../../api/directory-value.model";
import { directShipmentCompensationEntity } from "../../direct-shipment-compensation.entity";
import { ElementTypeValue } from "../../element-type.value";

interface EditItem {
  value?: ElementPrice;
  id: string;
  isNew: boolean;
  isDirty: boolean;
}

@Component({
  components: {
    FormErrors,
    LoadingSpinner,
    PriceConfigurationEditItem,
    SubmitButton,
  },
})
export default class PriceConfigurationEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fIsLoading = false;
  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fPlushieDataStore: PlushieStore;
  private fEditItems: EditItem[] = [];

  get elementTypes(): DirectoryValue[] {
    return this.fFactoryInvoiceDataStore.elementTypes;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get editItems(): EditItem[] {
    return this.fEditItems;
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public addItem(): void {
    const id = this.fIdGenerator.getId();

    this.fEditItems.push({
      value: undefined,
      id: id,
      isDirty: false,
      isNew: true,
    });
  }

  public onItemChange(value: ElementPrice | undefined, index: number): void {
    const item = this.fEditItems[index];

    item.value = value;
    item.isDirty = true;
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  public onItemDelete(item: EditItem): void {
    const index = this.fEditItems.indexOf(item);

    this.fEditItems.splice(index, 1);
  }

  protected async performSubmit(): Promise<void> {
    const values = this.fEditItems
      .map((item) => item.value)
      .filter((item): item is ElementPrice => item !== undefined);

    const items = await this.fFactoryInvoiceDataStore.saveElementPricesForFactory(
      {
        factoryId: this.factoryId,
        values,
      }
    );

    this.fEditItems = this.createEditItems(items);

    this.$emit("updated");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      const [elementPrices] = await Promise.all([
        this.fFactoryInvoiceDataStore.loadElementPricesByFactoryId({
          factoryId: this.factoryId,
          useCache: false,
        }),
        this.fFactoryInvoiceDataStore.loadElementTypes(),
        this.fPlushieDataStore.loadProducts(),
        this.fPlushieDataStore.loadUpgrades(),
      ]);

      elementPrices.sort((a, b) => {
        const desiredOrder = {
          [ElementTypeValue.PRODUCT]: 1,
          [ElementTypeValue.UPGRADE]: 2,
          [ElementTypeValue.DIRECT_SHIPMENT_COMPENSATION]: 3,
        };

        const result = desiredOrder[a.type] - desiredOrder[b.type];

        if (result !== 0) {
          return result;
        }

        const entityA =
          this.fPlushieDataStore.getProductById(a.entity) ||
          this.fPlushieDataStore.getUpgradeById(a.entity);

        const entityB =
          this.fPlushieDataStore.getProductById(b.entity) ||
          this.fPlushieDataStore.getUpgradeById(b.entity);

        if (!entityA || !entityB) {
          return result;
        }

        return entityA.name.localeCompare(entityB.name);
      });

      this.fEditItems = this.createEditItems(elementPrices);
    } finally {
      this.fIsLoading = false;
    }
  }

  private createEditItems(elementPrices: ElementPrice[]): EditItem[] {
    const editItems: EditItem[] = [];
    const products = this.fPlushieDataStore.productsList;
    const upgrades = this.fPlushieDataStore.upgradesList;

    elementPrices.forEach((elementPrice) => {
      const entity = [
        ...products,
        ...upgrades,
        directShipmentCompensationEntity,
      ].find((entity) => entity.id == elementPrice.entity);

      if (!entity) {
        return;
      }

      editItems.push({
        value: elementPrice,
        id: elementPrice.id,
        isNew: false,
        isDirty: false,
      });
    });

    return editItems;
  }
}
