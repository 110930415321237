import MassActionServiceInterface from "../plushie/mass-action-service.interface";
import PlushieExportJobRepository from "./plushie-export-job.repository";

export default class BulkExportAsExcelService
  implements MassActionServiceInterface {
  public static readonly ID = "bulkExportAsExcel";

  private fPlushieExportJobRepository: PlushieExportJobRepository;
  private fWindow: Window;

  constructor(
    plushieExportJobRepository: PlushieExportJobRepository,
    window: Window
  ) {
    this.fPlushieExportJobRepository = plushieExportJobRepository;
    this.fWindow = window;
  }

  public async execute(plushieIds: string[]): Promise<boolean> {
    if (plushieIds.length < 1) {
      return false;
    }

    const downloadUrl = await this.fPlushieExportJobRepository.createPlushieExportAsExcelJob(
      plushieIds
    );

    this.fWindow.location.href = downloadUrl;

    return true;
  }
}
