import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import QaDecisionRejectionReason from "./qa-decision-rejection-reason.model";

export default class QaDecisionRejectionReasonFactory
  implements ObjectBuilderInterface<QaDecisionRejectionReason> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): QaDecisionRejectionReason {
    const value = new QaDecisionRejectionReason(
      data.id,
      data.qaDecision,
      data.rejectionReason
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
