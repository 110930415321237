import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import TrackingInformation from "./tracking-information.model";

export default class TrackingInformationFactory
  implements ObjectBuilderInterface<TrackingInformation> {
  public buildFromJSON(data: { [key: string]: any }): TrackingInformation {
    const value = new TrackingInformation(
      data.id,
      data.trackingNumber,
      data.carrier
    );

    value.isNew = false;
    value.createdAt = new Date(data.createdAt);

    return value;
  }
}
