import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import FactoryPlushieStatusQty from "./factory-plushie-status-qty.model";

export default class FactoryPlushieStatusQtyFactory
  implements ObjectBuilderInterface<FactoryPlushieStatusQty> {
  public buildFromJSON(data: { [key: string]: any }): FactoryPlushieStatusQty {
    const value = new FactoryPlushieStatusQty(
      data.id,
      data.factory,
      data.plushieStatus.toString(),
      +data.plushiesQty
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
