



















import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import ErrorConverterService from "@/modules/forms/error-converter.service";

import ListItem from "./PlushieTagsList/Item.vue";

import TagStore from "../store";

@Component({
  components: {
    InlineSpinner,
    FormErrors,
    ListItem,
  },
})
export default class PlushieTagsList extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: true })
  public readonly isReadOnly!: boolean;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fSubmitErrors: string[] = [];

  private fIsLoading = true;

  private fTagDataStore: TagStore;

  get submitErrors() {
    return this.fSubmitErrors;
  }

  get isLoading() {
    return this.fIsLoading;
  }

  get tags() {
    return this.fTagDataStore.getTagsForPlushie(this.plushieId);
  }

  constructor() {
    super();
    this.fTagDataStore = getModule(TagStore, dataStore);
  }

  public async removeTag(tagId: string) {
    const relation = this.fTagDataStore.getPlushieTagRelationByPlushieAndTagId(
      this.plushieId,
      tagId
    );

    if (!relation) {
      this.fSubmitErrors = ["Tag is not assigned to this plushie"];
      return;
    }

    this.fSubmitErrors = [];

    try {
      await this.fTagDataStore.deletePlushieTagRelation(relation);
    } catch (error) {
      this.fSubmitErrors = this.fErrorConverterService.describeError(error);
    }
  }

  private async getPlushieData(plushieId: string) {
    const relations = await this.fTagDataStore.loadPlushieTagRelationsByPlushieId(
      {
        plushieId,
      }
    );

    const tagIds = relations.map((relation) => relation.tag);

    await this.fTagDataStore.loadTagsByIds(tagIds);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);
    this.fIsLoading = false;
  }
}
