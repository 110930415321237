import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { ResourceCollection } from "@/modules/api/resource.collection";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import RejectionReasonProductRelation from "./rejection-reason-product-relation.model";

export default class RejectionReasonProductRelationRepository extends BasicRepository<RejectionReasonProductRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<RejectionReasonProductRelation>,
    responseParser: ApiCollectionResponseParserService<RejectionReasonProductRelation>
  ) {
    super(
      "rejection_reason_product_relations",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByReasonId(
    reasonId: string
  ): Promise<ResourceCollection<RejectionReasonProductRelation>> {
    return this.fetchList({ rejectionReason: reasonId }, 1, 999);
  }

  public async getByReasonIds(
    reasonIds: string[]
  ): Promise<ResourceCollection<RejectionReasonProductRelation>> {
    return this.fetchList({ rejectionReason: reasonIds }, 1, 999);
  }

  public async getByProductId(
    productId: string
  ): Promise<ResourceCollection<RejectionReasonProductRelation>> {
    return this.fetchList({ productId: productId }, 1, 999);
  }

  protected prepareData(
    item: RejectionReasonProductRelation
  ): Record<string, string> {
    return {
      id: item.id,
      rejectionReason: item.rejectionReason,
      productId: item.product,
    };
  }
}
