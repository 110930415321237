import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import BulkProjectPlushieType from "./bulk-project-plushie-type.model";

export default class BulkProjectPlushieTypeRepository extends BasicRepository<BulkProjectPlushieType> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BulkProjectPlushieType>,
    responseParser: ApiCollectionResponseParserService<BulkProjectPlushieType>
  ) {
    super("bulk_project_plushie_types", api, objectBuilder, responseParser);
  }
}
