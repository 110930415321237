import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class ProductionTimeHistory implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fDueDate: Date,
    private fQaApprovementDate: Date,
    private fApplyPenalty: boolean,
    private fSubmitToQaDate?: Date
  ) {
    [fId, fPlushie, fDueDate, fQaApprovementDate, fApplyPenalty].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get dueDate(): Date {
    return this.fDueDate;
  }

  get qaApprovementDate(): Date {
    return this.fQaApprovementDate;
  }

  get applyPenalty(): boolean {
    return this.fApplyPenalty;
  }

  get submitToQaDate(): Date | undefined {
    return this.fSubmitToQaDate;
  }
}
