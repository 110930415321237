import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { ProductValue } from "@/modules/plushie/product.value";

import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import QuestionTemplateProductRelation from "./question-template-product-relation.model";

export default class QuestionTemplateProductRelationRepository extends BasicRepository<QuestionTemplateProductRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<QuestionTemplateProductRelation>,
    responseParser: ApiCollectionResponseParserService<QuestionTemplateProductRelation>
  ) {
    super(
      "question_template_product_relations",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByProductId(
    productId: ProductValue
  ): Promise<ResourceCollection<QuestionTemplateProductRelation>> {
    return this.fetchList({ productId }, 1, 999);
  }

  public async getByTemplateId(
    templateId: string
  ): Promise<ResourceCollection<QuestionTemplateProductRelation>> {
    return this.fetchList({ "template.id": templateId }, 1, 999);
  }

  public getByTemplateIds(
    templateIds: string[]
  ): Promise<ResourceCollection<QuestionTemplateProductRelation>> {
    return this.fetchList({ "template.id": templateIds }, 1, 999);
  }

  protected prepareData(
    item: QuestionTemplateProductRelation
  ): Record<string, string | number> {
    return {
      id: item.id,
      templateId: item.template,
      productId: Number.parseInt(item.productId),
    };
  }
}
