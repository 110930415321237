



































import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";
import FileStorageStore from "@/modules/file-storage/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";

import MessagesHistoryItem from "./MessagesHistory/Item.vue";

import MessagingStore from "../store";
import Message from "../message.model";

@Component({
  components: {
    MoonLoader,
    MessagesHistoryItem,
  },
})
export default class MessagesHistory extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = true;

  private fAccountDataStore: AccountStore;
  private fFileStorageDataStore: FileStorageStore;
  private fMessagingDataStore: MessagingStore;

  private fAnimatingElementsCount = 0;

  get isAnimating(): boolean {
    return this.fAnimatingElementsCount > 0;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get messages(): Message[] {
    return this.fMessagingDataStore.getMessagesByPlushieId(this.plushieId);
  }

  constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
  }

  public onAnimationEnd(): void {
    this.$nextTick(() => {
      this.fAnimatingElementsCount--;
    });
  }

  public onAnimationStart(): void {
    this.$nextTick(() => {
      this.fAnimatingElementsCount++;
    });
  }

  private async getPlushieData(plushieId: string): Promise<void> {
    const messages = await this.fMessagingDataStore.loadMessagesByPlushieId({
      plushieId: plushieId,
      useCache: false,
    });

    if (messages.length < 1) {
      return;
    }

    const messageIds = messages.map((message) => message.id);

    await this.fMessagingDataStore.loadAttachmentsByMessageIds({
      messageIds: messageIds,
      useCache: true,
    });

    const userIds: string[] = [];
    const storageItemIds: string[] = [];

    const currentUser = this.fUserProvider.getUser();
    if (currentUser) {
      userIds.push(currentUser.id);
    }

    messages.forEach((message) => {
      const attachments = this.fMessagingDataStore.getAttachmentsByMessageId(
        message.id
      );

      storageItemIds.push(
        ...attachments.map((attachment) => attachment.storageItem)
      );

      if (!userIds.includes(message.sender)) {
        userIds.push(message.sender);
      }
    });

    await Promise.all([
      this.fAccountDataStore.loadUserInfosByIds(userIds),
      this.fFileStorageDataStore.loadItemsByIds(storageItemIds),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange(): Promise<void> {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);
    this.fIsLoading = false;
  }
}
