import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PhotoMarkup from "./photo-markup.model";

export default class PhotoMarkupFactory
  implements ObjectBuilderInterface<PhotoMarkup> {
  public buildFromJSON(data: { [key: string]: any }): PhotoMarkup {
    const value = new PhotoMarkup(
      data.id,
      data.imageId,
      data.entityTypeId,
      JSON.parse(data.markup)
    );

    value.isNew = false;

    return value;
  }
}
