













import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import ImagesGalleryFullscreenModal from "@/modules/plushie/components/ImagesGalleryFullscreenModal.vue";

import ImagesGalleryFullscreenModalProp from "@/lib/images-gallery-fullscreen-modal-prop";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import FileStorageStore from "@/modules/file-storage/store";
import rootStore from "@/store";

@Component({
  components: {
    MoonLoader,
  },
})
export default class BulkProjectMainImage extends Vue {
  @Prop({ required: true })
  public readonly storageItemId!: string;

  @Prop({ required: true })
  public readonly thumbnailSize!: number;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private readonly fFullScreenImageSize = 1920;

  private fFileStorageStore: FileStorageStore;

  private fIsLoading = true;

  get thumbnailImage(): string {
    return this.getImage(this.thumbnailSize);
  }

  get fullScreenImage(): string {
    return this.getImage(this.fFullScreenImageSize);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();
    this.fFileStorageStore = getModule(FileStorageStore, rootStore);
  }

  created(): void {
    void this.loadData();
  }

  public launchGalleria(): void {
    const images = [
      {
        href: this.fullScreenImage,
        thumbnail: this.thumbnailImage,
      },
    ];

    const modalFunction = create(ImagesGalleryFullscreenModal);

    const props: ImagesGalleryFullscreenModalProp = {
      slides: images,
      index: 0,
    };

    void modalFunction(props);
  }

  private getImage(size: number): string {
    const storageItem = this.fFileStorageStore.getItemById(this.storageItemId);

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(url, size, size);
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;
    await this.fFileStorageStore.loadItemsByIds([this.storageItemId]);
    this.fIsLoading = false;
  }
}
