




























































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import dataStore from "@/store";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import EditableText from "./EditableText.vue";
import UpgradesList from "./UpgradesList.vue";

import PlushieStore from "../store";
import Plushie from "../plushie.model";
import Product from "../product.model";
import PlushieInformationUpdate from "../plushie-information-update.interface";
import { PlushieStatusValue } from "../plushie-status.value";
import PlushieArbitraryAttribute from "../plushie-arbitrary-attribute.model";

@Component({
  components: {
    InlineSpinner,
    EditableText,
    UpgradesList,
  },
})
export default class ProductionTaskCard extends Vue {
  @Prop({ required: true })
  public readonly plushie!: Plushie;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = true;

  private fPlushieDataStore: PlushieStore;

  get canUpdateQuantity(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      [
        PlushieStatusValue.PENDING_BULK_PRODUCTION,
        PlushieStatusValue.IN_BULK_DESIGN,
        PlushieStatusValue.PPS_INSPECTION,
        PlushieStatusValue.PPS_PREVIEW,
      ].includes(this.plushie.status) &&
      user.hasPermissionForResource(Resource.PLUSHIE_UPDATE)
    );
  }

  get canEditName(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      [
        PlushieStatusValue.REVIEW,
        PlushieStatusValue.ON_HOLD,
        PlushieStatusValue.READY_FOR_PRODUCTION,
        PlushieStatusValue.PENDING_BULK_PRODUCTION,
      ].includes(this.plushie.status) &&
      user.hasPermissionForResource(Resource.PLUSHIE_UPDATE)
    );
  }

  get canEditBreed(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      this.shouldShowBreed &&
      user.hasPermissionForResource(Resource.PLUSHIE_UPDATE)
    );
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get product(): Product | undefined {
    if (!this.plushie) {
      return undefined;
    }

    return this.fPlushieDataStore.getProductById(this.plushie.product);
  }

  get quantityClass(): string {
    if (!this.plushie) {
      return "";
    }

    return this.plushie.quantity > 1 ? "-alert" : "";
  }

  get shouldShowBreed(): boolean {
    if (!this.plushie || !this.product) {
      return false;
    }

    return this.product.hasBreed;
  }

  get shouldShowName(): boolean {
    if (!this.plushie || !this.product) {
      return false;
    }

    return this.product.hasName;
  }

  get upgrades(): DirectoryValue[] {
    if (!this.plushie) {
      return [];
    }

    const ids = this.fPlushieDataStore.getPlushieUpgradesByPlushieId(
      this.plushie.id
    );

    const result: DirectoryValue[] = [];

    ids.forEach((id) => {
      const upgrade = this.fPlushieDataStore.getUpgradeById(id);
      if (!upgrade) {
        return;
      }

      result.push(upgrade);
    });

    return result;
  }

  get arbitraryAttributes(): PlushieArbitraryAttribute[] {
    if (!this.plushie) {
      return [];
    }

    return this.fPlushieDataStore.getArbitraryAttributesByPlushieId(
      this.plushie.id
    );
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async updateBreed(breed: string): Promise<void> {
    if (!this.plushie) {
      return;
    }

    const value: PlushieInformationUpdate = {
      plushieId: this.plushie.id,
      breed: breed,
    };

    await this.fPlushieDataStore.updatePlushieInformation(value);
  }

  public async updateName(name: string): Promise<void> {
    if (!this.plushie) {
      return;
    }

    const value: PlushieInformationUpdate = {
      plushieId: this.plushie.id,
      name: name,
    };

    await this.fPlushieDataStore.updatePlushieInformation(value);
  }

  public async updateQuantity(quantity: string): Promise<void> {
    if (!this.plushie) {
      return;
    }

    await this.fPlushieDataStore.createPlushieQuantityUpdateRequest({
      plushieId: this.plushie.id,
      quantity: Number(quantity),
    });
  }

  private async getPlushieData(plushie: Plushie): Promise<void> {
    await Promise.all([
      this.fPlushieDataStore.loadUpgrades(),
      this.fPlushieDataStore.loadPlushieUpgradesByPlushieId({
        plushieId: plushie.id,
      }),
      this.fPlushieDataStore.loadProductById(plushie.product),
      this.fPlushieDataStore.loadArbitraryAttributesByPlushieId({
        plushieId: plushie.id,
      }),
    ]);
  }

  @Watch("plushie.id", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushie) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushie);
    this.fIsLoading = false;
  }
}
