import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import QuestionTemplate from "./question-template.model";

export default class QuestionTemplateFactory
  implements ObjectBuilderInterface<QuestionTemplate> {
  public buildFromJSON(data: { [key: string]: any }): QuestionTemplate {
    const value = new QuestionTemplate(data.id, data.name, data.content);

    value.isNew = false;

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    return value;
  }
}
