











import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";

import ProductionSnapshot from "../../production-snapshot.model";

@Component
export default class ProductionSnapshotTable extends Vue {
  @Prop({ required: true })
  public readonly tableData!: ProductionSnapshot[];

  get columns(): string[] {
    return [
      "productName",
      "readyForProduction",
      "inDesign",
      "rework",
      "inProduction",
      "qualityInspection",
      "customerPreview",
      "customerPreviewApproved",
      "readyForShipment",
      "total",
    ];
  }

  get options(): TableOptions {
    return {
      columnsClasses: {
        productName: "_product-name",
        readyForProduction: "_column",
        inDesign: "_column",
        rework: "_column",
        inProduction: "_column",
        qualityInspection: "_column",
        customerPreview: "_column",
        customerPreviewApproved: "_column",
        readyForShipment: "_column",
        total: "_column",
      },
      filterable: false,
      toggleGroups: true,
      groupBy: "factoryName",
      headings: {
        factoryName: "Factory",
        productName: "Product",
        readyForProduction: "Ready for Prod",
        inDesign: "In Design",
        rework: "Rework",
        inProduction: "In Production",
        qualityInspection: "In Inspection",
        customerPreview: "Cust Preview",
        customerPreviewApproved: "Cust Preview Approved",
        readyForShipment: "Ready For Shipment",
        total: "Total",
      },
      orderBy: { column: "factoryName", ascending: true },
      pagination: {
        show: false,
      },
      perPageValues: [],
      perPage: 99999,
      sortable: [],
    };
  }
}
