import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class BillingInfoRecord implements MutableValue {
  public isNew = true;

  private fAddress?: string;
  private fAdditionalAddress?: string;

  constructor(
    private fId: string,
    private fFactory: string,
    private fFactoryName: string,
    private fCity: string,
    private fStateRegion: string,
    private fPostalCode: string,
    private fCountry: string
  ) {
    [
      fId,
      fFactory,
      fFactoryName,
      fCity,
      fStateRegion,
      fPostalCode,
      fCountry,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get factory(): string {
    return this.fFactory;
  }

  get factoryName(): string {
    return this.fFactoryName;
  }

  get city(): string {
    return this.fCity;
  }

  get stateRegion(): string {
    return this.fStateRegion;
  }

  get postalCode(): string {
    return this.fPostalCode;
  }

  get country(): string {
    return this.fCountry;
  }

  get address(): string | undefined {
    return this.fAddress;
  }

  get additionalAddress(): string | undefined {
    return this.fAdditionalAddress;
  }
}
