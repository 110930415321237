































































































































































































import Component, { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Inject } from "vue-property-decorator";
import DatePicker from "vuejs-datepicker";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import TabsContainer, {
  TabSpecification,
} from "@/lib/components/TabsContainer.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import HolidayPeriodStore from "@/modules/holiday-period/store";
import rootStore from "@/store";
import HolidayPeriod from "@/modules/holiday-period/holiday-period.model";
import ProductSetting from "@/modules/holiday-period/product-setting.model";
import PlushieStore from "@/modules/plushie/store";
import { Dictionary } from "@/lib/Dictionary.type";
import Factory from "@/modules/factory/factory.model";
import FactoryStore from "@/modules/factory/store";
import { ProductAllocationSlotTypeValue } from "@/modules/factory/product-allication-slot-type.value";
import Product from "@/modules/plushie/product.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import ProductionEstimateTable from "./ProductionEstimatesTable.vue";
import ProjectionsTable from "./ProjectionsTable.vue";

import ProjectionRepository from "../../projection.repository";
import Projection from "../../projection.model";
import ProjectionStatsRepository from "../../projection-stats.repository";
import ProjectionStats from "../../projection-stats.model";
import ProductionEstimateRepository from "../../production-estimate.repository";
import ProductionEstimate from "../../production-estimate.interface";
import ReportsStore from "../../store";

const oneWeek = 1000 * 60 * 60 * 24 * 7;

enum PageState {
  INITIAL = "initial",
  INITIAL_DATA_LOADED = "initial-data-loaded",
  PROJECTIONS_DATA_LOADING = "projections-data-loading",
  PROJECTIONS_DATA_LOADED = "projections-data-loaded",
  ESTIMATES_DATA_LOADED = "estimates-data-loaded",
}

@Component({
  components: {
    DatePicker,
    FormErrors,
    FormField,
    MoonLoader,
    ProductionEstimateTable,
    ProjectionsTable,
    SubmitButton,
    TabsContainer,
  },
})
export default class ProductionEstimateReportPage extends mixins(
  GeneralFormMixin
) {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("ProductionEstimateRepository")
  private fProductionEstimateRepository!: ProductionEstimateRepository;

  @Inject("ProjectionRepository")
  private fProjectionRepository!: ProjectionRepository;

  @Inject("ProjectionStatsRepository")
  private fProjectionStatsRepository!: ProjectionStatsRepository;

  public holidayPeriod?: string;
  public todaysDate: Date = new Date();
  public lookBackWeeks = 1;
  public shipmentCutoff?: Date;

  private fState: PageState = PageState.INITIAL;
  private fProductionEstimatesList: ProductionEstimate[] = [];
  private fProjectionsList: Projection[] = [];
  private fProjectionsStats: ProjectionStats[] = [];
  private fIsReportFormVisible = true;

  private fFactoryStore!: FactoryStore;
  private fHolidayPeriodStore!: HolidayPeriodStore;
  private fPlushieStore!: PlushieStore;
  private fReportsStore!: ReportsStore;

  get activeFactoriesListWhichCanProduceHolidayPeriodProducts(): Factory[] {
    const factoriesIds: Set<string> = new Set();
    const factoriesList: Factory[] = [];

    this.holidayPeriodSettings.forEach((setting) => {
      this.fFactoryStore
        .getFactoriesIdsByProductIdAndSlot(
          setting.product,
          ProductAllocationSlotTypeValue.PRODUCTION
        )
        .forEach((factoryId) => factoriesIds.add(factoryId));
    });

    Array.from(factoriesIds).forEach((id) => {
      const factory = this.fFactoryStore.getFactoryById(id);
      if (factory) {
        factoriesList.push(factory);
      }
    });
    return factoriesList;
  }

  get canEditProjections(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REPORTING_PRODUCTION_ESTIMATE_MANAGE
    );
  }

  get canLoadProductionEstimateData(): boolean {
    return this.holidayPeriodSettings.length > 0 || this.canEditProjections;
  }

  get holidayPeriodProductsList(): Product[] {
    const products: Product[] = [];

    this.holidayPeriodSettings.forEach((setting) => {
      const product = this.fPlushieStore.getProductById(setting.product);
      if (product) {
        products.push(product);
      }
    });

    return products;
  }

  get holidayPeriodSettings(): ProductSetting[] {
    if (!this.holidayPeriod) {
      return [];
    }

    return this.fHolidayPeriodStore.getProductSettingsByPeriodId(
      this.holidayPeriod
    );
  }

  get holidayPeriodsList(): HolidayPeriod[] {
    return this.fHolidayPeriodStore.holidayPeriodsList;
  }

  get isClearButtonDisabled(): boolean {
    return [
      PageState.INITIAL,
      PageState.INITIAL_DATA_LOADED,
      PageState.PROJECTIONS_DATA_LOADING,
    ].includes(this.fState);
  }

  get isFormDisabled(): boolean {
    return (
      this.isDisabled || this.fState === PageState.PROJECTIONS_DATA_LOADING
    );
  }

  get isReportFormHidden(): boolean {
    return !this.fIsReportFormVisible;
  }

  get isSubmitButtonDisabled(): boolean {
    return this.isFormDisabled || !this.canLoadProductionEstimateData;
  }

  get productionEstimatesList(): ProductionEstimate[] {
    return this.fProductionEstimatesList;
  }

  get projectionDictionary(): Dictionary<Projection> {
    const dictionary: Dictionary<Projection> = {};

    this.fProjectionsList.forEach((projection) => {
      dictionary[
        `${projection.factoryId}-${projection.productId}`
      ] = projection;
    });

    return dictionary;
  }

  get projectionStatsByProductId(): Dictionary<ProjectionStats> {
    const stats: Dictionary<ProjectionStats> = {};

    this.fProjectionsStats.forEach((stat) => {
      stats[stat.productId] = stat;
    });

    return stats;
  }

  get reportFormTogglerText(): string {
    return this.fIsReportFormVisible ? "Hide Form" : "Show Form";
  }

  get showProjectionsTable(): boolean {
    return ![
      PageState.INITIAL,
      PageState.INITIAL_DATA_LOADED,
      PageState.PROJECTIONS_DATA_LOADING,
    ].includes(this.fState);
  }

  get showLoadingIndicator(): boolean {
    return this.fState === PageState.INITIAL;
  }

  get showProductionEstimateTable(): boolean {
    return (
      this.fState === PageState.ESTIMATES_DATA_LOADED && !this.isSubmitting
    );
  }

  get showProjectionsLoadingIndicator(): boolean {
    return this.fState === PageState.PROJECTIONS_DATA_LOADING;
  }

  get submitButtonText(): string {
    return this.canEditProjections ? "Save & Show Estimates" : "Show Estimates";
  }

  get tabs(): TabSpecification[] {
    return [
      {
        id: "projections",
        name: "Projections",
      },
      {
        id: "report",
        name: "Production Estimates",
        disabled: !this.showProductionEstimateTable,
      },
    ];
  }

  constructor() {
    super();

    this.fFactoryStore = getModule(FactoryStore, rootStore);
    this.fHolidayPeriodStore = getModule(HolidayPeriodStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fReportsStore = getModule(ReportsStore, rootStore);
  }

  public onClearButtonClickHandler(): void {
    this.clearProjectionsData();
  }

  public onHolidayPeriodChangeHandler(event: InputEvent): void {
    if (event.target) {
      this.holidayPeriod = (event.target as any).value;
    }

    void this.loadProjectionsData();
  }

  public toggleReportForm(): void {
    this.fIsReportFormVisible = !this.fIsReportFormVisible;
  }

  protected created(): void {
    this.initDefaultsFormFieldsValues();
    void this.loadInitialData();
  }

  protected data(): Record<string, unknown> {
    return {
      holidayPeriod: this.holidayPeriod,
      shipmentCutoff: this.shipmentCutoff,
    };
  }

  protected async performSubmit(): Promise<void> {
    const projectionsTable = this.getProjectionsTable();
    if (
      !this.holidayPeriod ||
      !this.todaysDate ||
      !this.lookBackWeeks ||
      !this.shipmentCutoff ||
      !projectionsTable
    ) {
      throw new Error("Not all required fields are filled!");
    }

    const todaysDate = new Date(this.todaysDate);
    const shipmentCutoffDate = new Date(this.shipmentCutoff);

    todaysDate.setHours(0, 0, 0, 0);
    shipmentCutoffDate.setHours(23, 59, 59, 999);

    if (todaysDate > shipmentCutoffDate) {
      throw new Error(
        "'Shipment Cutoff' date can't be less than 'Today's Date' date"
      );
    }

    const projectionsData = projectionsTable.getProjectionsData();

    await this.loadTableData(
      this.holidayPeriod,
      todaysDate,
      this.lookBackWeeks,
      shipmentCutoffDate,
      projectionsData
    );
  }

  private calculateWeeksRemaining(
    shipmentCutoff: Date,
    todaysDate: Date
  ): number {
    return (
      Math.round(
        ((shipmentCutoff.getTime() - todaysDate.getTime()) / oneWeek) * 100
      ) / 100
    );
  }

  private clearProjectionsData(): void {
    const projectionsTable = this.getProjectionsTable();

    if (!projectionsTable) {
      return;
    }

    projectionsTable.clearTableData();
  }

  private getProjectionsTable(): ProjectionsTable | undefined {
    return this.$refs.projectionsTable as ProjectionsTable;
  }

  private initDefaultsFormFieldsValues(): void {
    this.lookBackWeeks = this.fReportsStore.productionEstimateReportLookBackWeeks;
    if (this.fReportsStore.productionEstimateReportShipmentCutoff) {
      this.shipmentCutoff = new Date(
        this.fReportsStore.productionEstimateReportShipmentCutoff
      );
    }
  }

  private async loadProjectionsData(): Promise<void> {
    if (!this.holidayPeriod) {
      throw new Error("holidayPeriod is required");
    }

    this.fState = PageState.PROJECTIONS_DATA_LOADING;

    const response = await Promise.all([
      this.fProjectionRepository.getList(1, 999, {
        holidayPeriodId: this.holidayPeriod,
      }),

      this.fHolidayPeriodStore.loadProductSettingsByPeriodId({
        periodId: this.holidayPeriod,
        useCache: true,
      }),
    ]);

    this.fProjectionsList = response[0].getItems();

    this.fState = PageState.PROJECTIONS_DATA_LOADED;
  }

  private async loadInitialData(): Promise<void> {
    await Promise.all([
      this.fFactoryStore.loadFactories(),
      this.fFactoryStore.loadProductAllocations(),
      this.fHolidayPeriodStore.loadHolidayPeriods(),
      this.fPlushieStore.loadProducts(),
    ]);

    this.fState = PageState.INITIAL_DATA_LOADED;
  }

  private async loadProjectionsStats(
    holidayPeriod: string,
    todaysDate: Date,
    shipmentCutoff: Date
  ): Promise<void> {
    const weeksRemaining = this.calculateWeeksRemaining(
      shipmentCutoff,
      todaysDate
    );

    const response = await this.fProjectionStatsRepository.fetchProjectionStatsList(
      {
        holidayPeriodId: Number.parseInt(holidayPeriod, 10),
        weeksRemaining,
      }
    );
    this.fProjectionsStats = response;
  }

  private async loadTableData(
    holidayPeriod: string,
    todaysDate: Date,
    lookBackWeeks: number,
    shipmentCutoff: Date,
    projectionsData: Projection[]
  ): Promise<void> {
    if (this.canEditProjections) {
      await this.saveProjections(holidayPeriod, projectionsData);
    }

    await Promise.all([
      this.loadProjectionsStats(holidayPeriod, todaysDate, shipmentCutoff),
      this.loadProductionEstimateStats(
        holidayPeriod,
        todaysDate,
        lookBackWeeks,
        shipmentCutoff
      ),
    ]);

    this.fReportsStore.updateProductionEstimateReportFormFieldsDefaultValues({
      lookBackWeeks: this.lookBackWeeks,
      shipmentCutoff: this.shipmentCutoff,
    });

    this.fState = PageState.ESTIMATES_DATA_LOADED;
  }

  private async loadProductionEstimateStats(
    holidayPeriod: string,
    todaysDate: Date,
    lookBackWeeks: number,
    shipmentCutoff: Date
  ): Promise<void> {
    const weeksRemaining = this.calculateWeeksRemaining(
      shipmentCutoff,
      todaysDate
    );

    this.fProductionEstimatesList = await this.fProductionEstimateRepository.fetchProductionEstimatesList(
      {
        holidayPeriodId: Number.parseInt(holidayPeriod, 10),
        lookBackWeeks: lookBackWeeks,
        weeksRemaining,
      }
    );
  }

  private async saveProjections(
    holidayPeriod: string,
    projectionsData: Projection[]
  ): Promise<void> {
    this.fProjectionsList = await this.fProjectionRepository.saveList({
      holidayPeriodId: Number.parseInt(holidayPeriod, 10),
      items: projectionsData,
    });
  }
}
