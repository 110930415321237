import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import UserRole from "./user-role.model";

export default class UserRoleFactory
  implements ObjectBuilderInterface<UserRole> {
  public buildFromJSON(data: { [key: string]: any }): UserRole {
    const value = new UserRole(data.id, data.userId, data.role);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
