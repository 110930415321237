import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Value from "./value.model";

export default class ValueFactory implements ObjectBuilderInterface<Value> {
  public buildFromJSON(data: { [key: string]: any }): Value {
    const value = new Value(
      data.id,
      data.bodyPart,
      data.type.toString(),
      data.name,
      +data.sn,
      data.isEnabled == true
    );

    (value as any).fStorageItem = data.imageStorageItemId;
    (value as any).fColor = data.color;

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
