


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import ProductionExtended from "../../production-extended.interface";

@Component({
  components: {
    MoonLoader,
  },
})
export default class ProductionExtendedTable extends Vue {
  @Prop({ required: true })
  public readonly productionExtendedData!: ProductionExtended[];

  @Prop({ default: true })
  public readonly isLoading!: boolean;

  get tableColumns(): string[] {
    const columns: string[] = [];
    if (!this.tableData[0]) {
      return columns;
    }

    Object.keys(this.tableData[0]).forEach((key) => {
      if (!["product", "product_id"].includes(key)) {
        columns.push(key);
      }
    });

    return columns;
  }

  get tableData(): ProductionExtended[] {
    return this.productionExtendedData.map((productionExtended) => {
      const item: ProductionExtended = { ...productionExtended };

      Object.entries(item).forEach(([key, value]) => {
        item[key] = value === "0" ? "" : value;
      });

      return item;
    });
  }

  get tableOptions(): TableOptions {
    return {
      columnsClasses: {
        metric: "-status-column",
      },
      filterable: false,
      headings: {
        metric: "Status",
      },
      perPage: 999,
      perPageValues: [],
    };
  }

  protected created(): void {
    this.$emit("created");
  }
}
