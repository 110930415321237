import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BodyPart from "./body-part.model";

export default class BodyPartFactory
  implements ObjectBuilderInterface<BodyPart> {
  public buildFromJSON(data: { [key: string]: any }): BodyPart {
    const value = new BodyPart(
      data.id,
      data.code,
      data.name,
      data.product.toString(),
      data.isEnabled == true,
      data.isRequired == true,
      +data.maxValues,
      +data.adminSn,
      data.canBeDetailed,
      data.parentBodyPartId,
      data.detailingFlagText
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
