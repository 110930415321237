
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import OrderInformation from "@/modules/plushie/order-information.model";
import rootStore from "@/store";

import OrderNumber from "./OrderNumber.vue";

import PlushieStore from "../store";

@Component({
  components: {
    InlineSpinner,
    OrderNumber,
  },
})
export default class FactoryOrderDetailsCard extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fIsLoading = true;

  private fPlushieStore: PlushieStore;

  constructor() {
    super();

    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get orderInformation(): OrderInformation | undefined {
    return this.fPlushieStore.getOrderInformationByPlushieId(this.plushieId);
  }

  private async loadPlushieData(plushieId: string) {
    await Promise.all([
      this.fPlushieStore.loadPlushieById({ id: plushieId }),
      this.fPlushieStore.loadOrderInformationByPlushieId(plushieId),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.loadPlushieData(this.plushieId);
    this.fIsLoading = false;
  }
}
