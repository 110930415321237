import { AxiosRequestConfig, AxiosInstance } from "axios";

import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";

export default class RequestProcessorService {
  constructor(
    private fServer: AxiosInstance,
    private fUserProvider: AuthenticatedUserProvider
  ) {
    this.fServer.interceptors.request.use((config) =>
      this.addAnalyticsHeaders(config)
    );
  }

  private addAnalyticsHeaders(config: AxiosRequestConfig): AxiosRequestConfig {
    let user;

    try {
      user = this.fUserProvider.getUser();
    } catch {
      //
    }

    if (user) {
      let headers = config.headers;

      if (!headers) {
        headers = {};
      }

      headers["X-App-User-Id"] = user.id;
    }

    return config;
  }
}
