import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Config from "./config.model";

export default class ConfigFactory implements ObjectBuilderInterface<Config> {
  public buildFromJSON(data: { [key: string]: any }): Config {
    const value = new Config(data.id, data.enabled == true);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
