




















import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";
import EditableText from "@/modules/plushie/components/EditableText.vue";

import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import dataStore from "@/store";
import Plushie from "@/modules/plushie/plushie.model";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import BulkProjectStore from "../../store";
import BulkProjectBatchInformation from "../../bulk-project-batch-information.model";

const editAllowedPlushieStatuses = [
  PlushieStatusValue.IN_BULK_DESIGN,
  PlushieStatusValue.PPS_INSPECTION,
  PlushieStatusValue.PPS_PREVIEW,
  PlushieStatusValue.IN_BULK_PRODUCTION,
  PlushieStatusValue.BULK_INSPECTION,
  PlushieStatusValue.BULK_PREVIEW,
  PlushieStatusValue.PENDING_BULK_SHIPMENT,
  PlushieStatusValue.READY_FOR_BULK_SHIPMENT,
];

@Component({
  components: {
    EditableText,
    InlineSpinner,
  },
})
export default class BatchInformationNegotiatedPrice extends Vue {
  @Prop({ required: true })
  public readonly batchInformation!: BulkProjectBatchInformation;

  @Prop({ required: true })
  public readonly plushie!: Plushie;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fBulkProjectStore: BulkProjectStore;

  constructor() {
    super();

    this.fBulkProjectStore = getModule(BulkProjectStore, dataStore);
  }

  get showNegotiatedPrice(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.BULK_PROJECT_BATCH_INFORMATION_VIEW
    );
  }

  get canEditNegotiatedPrice(): boolean {
    if (!editAllowedPlushieStatuses.includes(this.plushie.status)) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.BULK_PROJECT_BATCH_INFORMATION_MANAGE
    );
  }

  public async updateNegotiatedPrice(negotiatedPrice: string): Promise<void> {
    const newBatchInformation = new BulkProjectBatchInformation(
      this.batchInformation.id,
      this.batchInformation.createdAt,
      this.batchInformation.updatedAt,
      this.batchInformation.useCustomSewInLabel
    );

    newBatchInformation.notes = this.batchInformation.notes;
    newBatchInformation.negotiatedPrice = +negotiatedPrice;
    newBatchInformation.isNew = false;

    await this.fBulkProjectStore.saveBulkProjectBatchInformation(
      newBatchInformation
    );
  }
}
