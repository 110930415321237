





































import { Component, Prop, Vue } from "vue-property-decorator";

import PlushieAttachmentUpload from "@/modules/plushie/components/Attachments/Upload.vue";

import Item from "@/modules/file-storage/item.model";

import { CustomSewInLabelFieldValue } from "../custom-sew-in-label-field-value.interface";

@Component({
  components: {
    PlushieAttachmentUpload,
  },
})
export default class CustomSewInLabel extends Vue {
  @Prop({ default: undefined })
  public readonly plushieId?: string;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({
    default: () => ({
      storageItemId: undefined,
      useCustomSewInLabel: false,
    }),
  })
  public readonly value!: CustomSewInLabelFieldValue;

  private fIsAttachmentsProcessingInProgress = false;

  get isAttachmentsProcessingInProgress(): boolean {
    return this.fIsAttachmentsProcessingInProgress;
  }

  get isUploadDisabled(): boolean {
    return this.disabled || this.isAttachmentsProcessingInProgress;
  }

  get useCustomSewInLabel(): boolean {
    return this.value.useCustomSewInLabel;
  }

  set useCustomSewInLabel(value: boolean) {
    this.$emit("input", {
      useCustomSewInLabel: value,
      storageItemId: value ? this.value.storageItemId : undefined,
    });
  }

  public browseFiles(): void {
    const attachmentUpload = this.getAttachmentUpload();

    if (!attachmentUpload) {
      return;
    }

    attachmentUpload.browseFiles();
  }

  public onAttachmentAdded(storageItem: Item): void {
    this.$emit("input", {
      useCustomSewInLabel: this.value.useCustomSewInLabel,
      storageItemId: storageItem.id,
    });
  }

  public onAttachmentIsBusyChanged(value: boolean): void {
    this.fIsAttachmentsProcessingInProgress = value;
  }

  public onAttachmentRemoved(): void {
    this.$emit("input", {
      useCustomSewInLabel: this.value.useCustomSewInLabel,
      storageItemId: undefined,
    });
  }

  private getAttachmentUpload(): PlushieAttachmentUpload | undefined {
    return this.$refs["attachment-upload"] as
      | PlushieAttachmentUpload
      | undefined;
  }
}
