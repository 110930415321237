















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";

import FactoryInvoiceStore from "../../store/index";
import BillingInfoRecord from "../../billing-info-record.model";
import { FactoryInvoicesSettingValue } from "../../factory-invoices-setting.value";

@Component({})
export default class FactoryInvoiceViewCounterpartiesInfo extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  private fIsLoading = false;

  private fFactoryInvoiceStore: FactoryInvoiceStore;

  get buyerName(): string {
    return this.getFactoryInvoicesSettingValueById(
      FactoryInvoicesSettingValue.STORE_BILLING_INFO_NAME
    );
  }

  get buyerAddress(): string {
    return this.getFactoryInvoicesSettingValueById(
      FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDRESS
    );
  }

  get buyerAdditionalAddress(): string {
    return this.getFactoryInvoicesSettingValueById(
      FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDITIONAL_ADDRESS
    );
  }

  get buyerCity(): string {
    return this.getFactoryInvoicesSettingValueById(
      FactoryInvoicesSettingValue.STORE_BILLING_INFO_CITY
    );
  }

  get buyerStateRegion(): string {
    return this.getFactoryInvoicesSettingValueById(
      FactoryInvoicesSettingValue.STORE_BILLING_INFO_STATE_REGION
    );
  }

  get buyerPostalCode(): string {
    return this.getFactoryInvoicesSettingValueById(
      FactoryInvoicesSettingValue.STORE_BILLING_INFO_POSTAL_CODE
    );
  }

  get buyerCountry(): string {
    return this.getFactoryInvoicesSettingValueById(
      FactoryInvoicesSettingValue.STORE_BILLING_INFO_COUNTRY
    );
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get billingInfoRecord(): BillingInfoRecord | undefined {
    const invoice = this.fFactoryInvoiceStore.getFactoryInvoiceById(
      this.invoiceId
    );

    if (!invoice) {
      return undefined;
    }

    return this.fFactoryInvoiceStore.getBillingInfoRecordByFactoryId(
      invoice.factory
    );
  }

  constructor() {
    super();

    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }

  private getFactoryInvoicesSettingValueById(
    id: FactoryInvoicesSettingValue
  ): string {
    const setting = this.fFactoryInvoiceStore.getFactoryInvoicesSettingById(id);

    return setting && setting.value !== undefined ? setting.value : "";
  }

  private async loadData(invoiceId: string): Promise<void> {
    const invoice = await this.fFactoryInvoiceStore.loaFactoryInvoiceById({
      id: invoiceId,
    });

    if (!invoice) {
      return;
    }

    await Promise.all([
      this.fFactoryInvoiceStore.loadBillingInfoRecordByFactoryId({
        factoryId: invoice.factory,
      }),
      this.fFactoryInvoiceStore.loadFactoryInvoicesSettingsByIds([
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_NAME,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDRESS,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDITIONAL_ADDRESS,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_CITY,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_STATE_REGION,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_POSTAL_CODE,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_COUNTRY,
      ]),
    ]);
  }

  @Watch("invoiceId", { immediate: true })
  private async _onInvoiceIdChange() {
    if (!this.invoiceId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.loadData(this.invoiceId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
