import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieDesignInformation from "./plushie-design-information.model";

export default class PlushieDesignInformationFactory
  implements ObjectBuilderInterface<PlushieDesignInformation> {
  public buildFromJSON(data: { [key: string]: any }): PlushieDesignInformation {
    const value = new PlushieDesignInformation(data.id);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.comment = data.comment;
    value.commentTranslation = data.commentTranslation;
    value.descriptionTranslation = data.descriptionTranslation;

    value.isNew = false;

    return value;
  }
}
