















































































































































































































import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";

import PerformanceReportAverageQaRoundsSection from "./AverageQaRoundsSection.vue";
import ProgressBar from "./ProgressBar.vue";

import Factory from "../../factory.model";
import FactoryPerformanceRepository from "../../factory-performance.repository";
import InProductionDaysApiResponse from "../../in-production-days-api-response.interface";
import ProductionVolumeApiResponse from "../../production-volume-api-response.interface";
import ProgressBarStep from "../../progress-bar-step.interface";
import RejectionRateApiResponse from "../../rejection-rate-api-response.interface";
import FactoryStore from "../../store";
import AverageQaRoundsApiResponse from "../../average-qa-rounds-api-response.interface";
import StatsDifference from "../../stats-difference.interface";
import getStatsDifference from "../../get-stats-difference.function";

interface InProductionDaysStats {
  month: {
    count: number;
    difference: StatsDifference;
  };
  week: {
    count: number;
    difference: StatsDifference;
  };
}

interface ProductionVolumeStats {
  month: {
    average: number;
    difference: StatsDifference;
    count: number;
  };
  week: {
    average: number;
    difference: StatsDifference;
    count: number;
  };
}

interface RejectionRateStats {
  month: {
    count: number;
    difference: StatsDifference;
  };
  week: {
    count: number;
    difference: StatsDifference;
  };
}

enum PERIODS {
  WEEK = 7,
  MONTH = 30,
}

@Component({
  components: {
    LoadingSpinner,
    PerformanceReportAverageQaRoundsSection,
    ProgressBar,
  },
})
export default class FactoryPerformanceReport extends Vue {
  @Inject("FactoryPerformanceRepository")
  private fFactoryPerformanceRepository!: FactoryPerformanceRepository;

  public selectedFactoryId = "";

  private fIsShowErrorMessage = false;
  private fFactoryInProductionDaysApiResponse?: InProductionDaysApiResponse;
  private fFactoryProductionVolumeStatsApiResponse?: ProductionVolumeApiResponse;
  private fFactoryRejectionRateStatsApiResponse?: RejectionRateApiResponse;
  private fFactoryAverageQaRoundsStatsApiResponse?: AverageQaRoundsApiResponse;
  private fIsLoading = false;

  private fFactoryStore: FactoryStore;

  get activeFactoriesList(): Factory[] {
    return this.fFactoryStore.activeFactoriesList;
  }

  get factoryAverageQaRoundsStatsApiResponse():
    | AverageQaRoundsApiResponse
    | undefined {
    return this.fFactoryAverageQaRoundsStatsApiResponse;
  }

  get firstFactory(): Factory | undefined {
    return this.activeFactoriesList[0];
  }

  get inProductionDaysFooterText(): string {
    return `${this.inProductionDaysStats.month.count} days`;
  }

  get inProductionDaysProgressBarInterval(): number[] {
    const defaultMaxDaysCount = 40;

    let daysInterval = [0, 10, 20, 30, 40];
    const coef = Math.ceil(
      this.inProductionDaysStats.month.count / defaultMaxDaysCount
    );

    if (coef > 1) {
      daysInterval = daysInterval.map((interval) => {
        return interval * coef;
      });
    }

    return daysInterval;
  }

  get inProductionDaysProgressBarSteps(): ProgressBarStep[] {
    return this.inProductionDaysProgressBarInterval.map((interval, index) => {
      const step: ProgressBarStep = {
        name: `${interval} Days`,
      };

      if (index === 0) {
        step.color = "green";
      }

      if (index === this.inProductionDaysProgressBarInterval.length - 1) {
        step.color = "red";
      }

      return step;
    });
  }

  get inProductionDaysProgressBarValue(): number {
    if (!this.inProductionDaysStats.month.count) {
      return 0;
    }

    const lastStepValue = this.inProductionDaysProgressBarInterval[
      this.inProductionDaysProgressBarInterval.length - 1
    ];

    return (this.inProductionDaysStats.month.count / lastStepValue) * 100;
  }

  get inProductionDaysStats(): InProductionDaysStats {
    const defaultStats = {
      count: 0,
      difference: {
        value: 0,
        isImproved: true,
      },
    };
    const stats: InProductionDaysStats = {
      month: { ...defaultStats },
      week: { ...defaultStats },
    };

    if (!this.fFactoryInProductionDaysApiResponse) {
      return stats;
    }

    stats.month.count = this.fFactoryInProductionDaysApiResponse.monthCount;
    stats.month.difference = getStatsDifference(
      this.fFactoryInProductionDaysApiResponse.monthCount,
      this.fFactoryInProductionDaysApiResponse.prevMonthCount,
      true
    );

    stats.week.count = this.fFactoryInProductionDaysApiResponse.weekCount;
    stats.week.difference = getStatsDifference(
      this.fFactoryInProductionDaysApiResponse.weekCount,
      this.fFactoryInProductionDaysApiResponse.prevWeekCount,
      true
    );

    return stats;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isShowErrorMessage(): boolean {
    return this.fIsShowErrorMessage;
  }

  get isShowFactorySelect(): boolean {
    return this.activeFactoriesList.length > 1;
  }

  get numberFormatParams(): Intl.NumberFormatOptions {
    return {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    };
  }

  get productionVolumeFooterText(): string {
    return `${this.productionVolumeStats.month.count} Pieces`;
  }

  get productionVolumeProgressBarLastStepValue(): number {
    const lastStepDefaultValue = 1000;

    let value = lastStepDefaultValue;
    const coef = Math.ceil(
      this.productionVolumeStats.month.count / lastStepDefaultValue
    );

    if (coef > 1) {
      value = lastStepDefaultValue * coef;
    }

    return value;
  }

  get productionVolumeProgressBarSteps(): ProgressBarStep[] {
    return [
      {
        name: "0",
      },
      {
        name: this.$options.filters!.number(
          this.productionVolumeProgressBarLastStepValue
        ),
      },
    ];
  }

  get productionVolumeTodayCount(): number {
    return this.fFactoryProductionVolumeStatsApiResponse
      ? this.fFactoryProductionVolumeStatsApiResponse.todayCount
      : 0;
  }

  get productionVolumeStats(): ProductionVolumeStats {
    const defaultStats = {
      average: 0,
      count: 0,
      difference: {
        value: 0,
        isImproved: true,
      },
    };
    const stats: ProductionVolumeStats = {
      month: { ...defaultStats },
      week: { ...defaultStats },
    };

    if (!this.fFactoryProductionVolumeStatsApiResponse) {
      return stats;
    }

    stats.month.average = this.getAverage(
      this.fFactoryProductionVolumeStatsApiResponse.monthCount,
      PERIODS.MONTH
    );
    stats.month.difference = getStatsDifference(
      this.fFactoryProductionVolumeStatsApiResponse.monthCount,
      this.fFactoryProductionVolumeStatsApiResponse.prevMonthCount
    );
    stats.month.count = this.fFactoryProductionVolumeStatsApiResponse.monthCount;

    stats.week.average = this.getAverage(
      this.fFactoryProductionVolumeStatsApiResponse.weekCount,
      PERIODS.WEEK
    );
    stats.week.difference = getStatsDifference(
      this.fFactoryProductionVolumeStatsApiResponse.weekCount,
      this.fFactoryProductionVolumeStatsApiResponse.prevWeekCount
    );
    stats.week.count = this.fFactoryProductionVolumeStatsApiResponse.weekCount;

    return stats;
  }

  get productionVolumeValue(): number {
    if (!this.productionVolumeStats.month.count) {
      return 0;
    }

    return (
      (this.productionVolumeStats.month.count /
        this.productionVolumeProgressBarLastStepValue) *
      100
    );
  }

  get rejectionRateFooterText(): string {
    return `${this.rejectionRateStats.month.count} %`;
  }

  get rejectionRateProgressBarSteps(): ProgressBarStep[] {
    return [
      { name: "0%", color: "green" },
      { name: "25%" },
      { name: "50%" },
      { name: "75%" },
      { name: "100%", color: "red" },
    ];
  }

  get rejectionRateStats(): RejectionRateStats {
    const defaultStats = {
      count: 0,
      difference: {
        value: 0,
        isImproved: true,
      },
    };
    const stats: RejectionRateStats = {
      month: { ...defaultStats },
      week: { ...defaultStats },
    };

    if (!this.fFactoryRejectionRateStatsApiResponse) {
      return stats;
    }

    stats.month.count = this.fFactoryRejectionRateStatsApiResponse.monthRate;
    stats.month.difference = getStatsDifference(
      this.fFactoryRejectionRateStatsApiResponse.monthRate,
      this.fFactoryRejectionRateStatsApiResponse.prevMonthRate,
      true
    );

    stats.week.count = this.fFactoryRejectionRateStatsApiResponse.weekRate;
    stats.week.difference = getStatsDifference(
      this.fFactoryRejectionRateStatsApiResponse.weekRate,
      this.fFactoryRejectionRateStatsApiResponse.prevWeekRate,
      true
    );

    return stats;
  }

  public constructor() {
    super();

    this.fFactoryStore = getModule(FactoryStore, dataStore);
  }

  public onSelectedFactoryChange(): void {
    void this.loadPerformanceDataForFactory(this.selectedFactoryId);
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;
    await this.fFactoryStore.loadFactories();

    if (!this.firstFactory) {
      this.fIsShowErrorMessage = true;
      this.fIsLoading = false;
      return;
    }

    await this.loadPerformanceDataForFactory(
      this.activeFactoriesList.length > 1 ? undefined : this.firstFactory.id
    );
    this.fIsLoading = false;
  }

  protected data(): Record<string, any> {
    return {
      fFactoryProductionVolumeStatsApiResponse: this
        .fFactoryProductionVolumeStatsApiResponse,
      fFactoryRejectionRateStatsApiResponse: this
        .fFactoryRejectionRateStatsApiResponse,
      fFactoryInProductionDaysApiResponse: this
        .fFactoryInProductionDaysApiResponse,
      fFactoryAverageQaRoundsStatsApiResponse: this
        .fFactoryAverageQaRoundsStatsApiResponse,
    };
  }

  private getAverage(count: number, period: PERIODS): number {
    return count / period;
  }

  private async loadPerformanceDataForFactory(
    factoryId?: string
  ): Promise<void> {
    this.fIsShowErrorMessage = false;
    this.fIsLoading = true;

    try {
      const collectionList = await Promise.all([
        this.fFactoryPerformanceRepository.getProductionVolumeStatsForFactory(
          factoryId
        ),
        this.fFactoryPerformanceRepository.getRejectionRateStatsForFactory(
          factoryId
        ),
        this.fFactoryPerformanceRepository.getInProductionStatsForFactory(
          factoryId
        ),
        this.fFactoryPerformanceRepository.getAverageQaRoundsStats(factoryId),
      ]);

      this.fFactoryProductionVolumeStatsApiResponse = collectionList[0];
      this.fFactoryRejectionRateStatsApiResponse = collectionList[1];
      this.fFactoryInProductionDaysApiResponse = collectionList[2];
      this.fFactoryAverageQaRoundsStatsApiResponse = collectionList[3];
    } catch (e) {
      this.fIsShowErrorMessage = true;
    } finally {
      this.fIsLoading = false;
    }
  }
}
