import { MutableValue } from "../api/mutable-value.interface";
import { PlushieStatusValue } from "./plushie-status.value";

export default class PlushieStatus implements MutableValue {
  public static readonly PRODUCTION_STATUSES = [
    PlushieStatusValue.READY_FOR_PRODUCTION,
    PlushieStatusValue.IN_DESIGN,
    PlushieStatusValue.IN_PRODUCTION,
    PlushieStatusValue.QUALITY_INSPECTION,
    PlushieStatusValue.CUSTOMER_PREVIEW,
    PlushieStatusValue.REWORK,
    PlushieStatusValue.READY_FOR_SHIPMENT,
    PlushieStatusValue.SHIPPED_TO_MAIN_FACILITY,
    PlushieStatusValue.CUSTOMER_PREVIEW_APPROVED,
    PlushieStatusValue.IN_BULK_DESIGN,
    PlushieStatusValue.IN_BULK_PRODUCTION,
    PlushieStatusValue.PPS_INSPECTION,
    PlushieStatusValue.BULK_INSPECTION,
    PlushieStatusValue.PPS_PREVIEW,
    PlushieStatusValue.BULK_PREVIEW,
    PlushieStatusValue.READY_FOR_BULK_SHIPMENT,
    PlushieStatusValue.SHIPPED_TO_CUSTOMER,
    PlushieStatusValue.PENDING_BULK_SHIPMENT,
    PlushieStatusValue.AWAITING_DIRECT_SHIPMENT,
  ];

  public static readonly REVIEWED_STATUSES = [
    PlushieStatusValue.READY_FOR_PRODUCTION,
    PlushieStatusValue.IN_DESIGN,
    PlushieStatusValue.IN_PRODUCTION,
    PlushieStatusValue.QUALITY_INSPECTION,
    PlushieStatusValue.CUSTOMER_PREVIEW,
    PlushieStatusValue.REWORK,
    PlushieStatusValue.READY_FOR_SHIPMENT,
    PlushieStatusValue.SHIPPED_TO_MAIN_FACILITY,
    PlushieStatusValue.SENT_TO_CUSTOMER,
    PlushieStatusValue.CUSTOMER_PREVIEW_APPROVED,
    PlushieStatusValue.FINISHED_WITHOUT_SHIPPING,
    PlushieStatusValue.PENDING_BULK_PRODUCTION,
    PlushieStatusValue.IN_BULK_DESIGN,
    PlushieStatusValue.IN_BULK_PRODUCTION,
    PlushieStatusValue.BULK_INSPECTION,
    PlushieStatusValue.BULK_PREVIEW,
    PlushieStatusValue.PPS_INSPECTION,
    PlushieStatusValue.PPS_PREVIEW,
    PlushieStatusValue.READY_FOR_BULK_SHIPMENT,
    PlushieStatusValue.SHIPPED_TO_CUSTOMER,
    PlushieStatusValue.AWAITING_DIRECT_SHIPMENT,
  ];

  public static readonly BATCH_STATUSES = [
    PlushieStatusValue.PENDING_BULK_PRODUCTION,
    PlushieStatusValue.IN_BULK_DESIGN,
    PlushieStatusValue.PPS_INSPECTION,
    PlushieStatusValue.PPS_PREVIEW,
    PlushieStatusValue.IN_BULK_PRODUCTION,
    PlushieStatusValue.BULK_INSPECTION,
    PlushieStatusValue.BULK_PREVIEW,
    PlushieStatusValue.PENDING_BULK_SHIPMENT,
    PlushieStatusValue.READY_FOR_BULK_SHIPMENT,
    PlushieStatusValue.SHIPPED_TO_CUSTOMER,
  ];

  public isNew = true;

  constructor(readonly id: PlushieStatusValue, readonly name: string) {}

  public toString(): string {
    return this.name;
  }
}
