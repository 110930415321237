import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import Payment from "./payment.model";

export default class PaymentRepository extends BasicRepository<Payment> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Payment>,
    responseParser: ApiCollectionResponseParserService<Payment>
  ) {
    super("payments", api, objectBuilder, responseParser);
  }

  public async getByInvoiceId(invoiceId: string): Promise<Payment | undefined> {
    const collection = await this.fetchList({ invoice: invoiceId });

    return collection.getItems()[0];
  }

  public async saveItems(items: Payment[]): Promise<Payment[]> {
    const itemsData = items.map((item) => this.prepareData(item));

    const response = await this.api.post(this.resourceName, itemsData);

    const result = this.responseParser.parse(response.data, this.objectBuilder);

    return result.getItems();
  }

  protected prepareData(item: Payment): Dictionary<string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      invoiceId: item.invoice,
      paidAt: item.paidAt.toISOString(),
      type: item.type,
      confirmationNumber: item.confirmationNumber,
    };

    if (item.notes) {
      data.notes = item.notes;
    }

    return data;
  }
}
