








































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";

import NavigationPosition from "../navigation-position.model";

@Component({
  name: "ListItemPageNavigation",
  components: {},
})
export default class ListItemPageNavigation extends Vue {
  @Prop({ required: true })
  public readonly itemId!: string;

  @Prop({ required: true })
  public readonly listPageRouteName!: string;

  @Prop({ required: true })
  public readonly viewPageRouteName!: string;

  @Prop({ required: true })
  public readonly getIdsListFunc!: () => Promise<string[]>;

  @Prop()
  public readonly query?: Record<string, any>;

  @Prop()
  public readonly listQuery?: Record<string, any>;

  private fIsLoading = false;
  private fItemsOrder?: string[];
  private fListPosition = new NavigationPosition();
  private fNextItemLink?: RawLocation;
  private fPreviousItemLink?: RawLocation;

  get listPosition(): NavigationPosition {
    return this.fListPosition;
  }

  get nextItemLink(): RawLocation | undefined {
    return this.fNextItemLink;
  }

  get previousItemLink(): RawLocation | undefined {
    return this.fPreviousItemLink;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  protected data(): Record<string, any> {
    return {
      fNextItemLink: this.fNextItemLink,
      fItemsOrder: this.fItemsOrder,
      fPreviousItemLink: this.fPreviousItemLink,
    };
  }

  private generateItemLink(
    id: string,
    query: Record<string, any>,
    listQuery?: Record<string, any>
  ): RawLocation | undefined {
    if (id == null) {
      return undefined;
    }

    return {
      name: this.viewPageRouteName,
      query: {
        id,
        query: JSON.stringify(query),
        listQuery: JSON.stringify(listQuery),
      },
    };
  }

  private async getItemsOrder(): Promise<string[]> {
    if (this.fItemsOrder != null) {
      return this.fItemsOrder;
    }

    this.fItemsOrder = await this.getIdsListFunc();

    return this.fItemsOrder;
  }

  private async updateNavigation() {
    if (this.itemId == null || this.query == null) {
      this.resetNavigation();
      return;
    }

    const itemsOrder = await this.getItemsOrder();

    if (itemsOrder == null) {
      this.resetNavigation();
      return;
    }

    const position = itemsOrder.indexOf(this.itemId);

    if (position === -1) {
      this.resetNavigation();
      return;
    }

    this.fListPosition.totalItems = itemsOrder.length;
    this.fListPosition.position = position + 1;

    this.fListPosition.previousItem = undefined;
    this.fListPosition.nextItem = undefined;

    if (position > 0) {
      this.fListPosition.previousItem = itemsOrder[position - 1];

      this.fPreviousItemLink = this.generateItemLink(
        this.fListPosition.previousItem,
        this.query,
        this.listQuery
      );
    }

    if (position < itemsOrder.length - 1) {
      this.fListPosition.nextItem = itemsOrder[position + 1];

      this.fNextItemLink = this.generateItemLink(
        this.fListPosition.nextItem,
        this.query,
        this.listQuery
      );
    }
  }

  private resetNavigation() {
    this.fListPosition.position = 1;
    this.fListPosition.totalItems = 1;
    this.fListPosition.previousItem = undefined;
    this.fListPosition.nextItem = undefined;
    this.fNextItemLink = undefined;
    this.fPreviousItemLink = undefined;
  }

  @Watch("itemId", { immediate: true })
  private async _onItemIdChange() {
    this.fIsLoading = true;

    try {
      await this.updateNavigation();
      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
