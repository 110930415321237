





























import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import FactoryRepository from "@/modules/factory/factory.repository";
import FactoryStore from "@/modules/factory/store";

import BillingInfoEditForm from "./InfoEditForm.vue";

import BillingInfoRecord from "../../billing-info-record.model";
import FactoryInvoiceStore from "../../store";

@Component({
  metaInfo() {
    const component = this as BillingInfoInfoEditPage;

    return {
      title: component.title,
    };
  },
  components: {
    BillingInfoEditForm,
    LoadingSpinner,
    ListItemPageNavigation,
  },
})
export default class BillingInfoInfoEditPage extends Vue {
  @Prop()
  public readonly factoryId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("FactoryRepository")
  private fFactoryRepository!: FactoryRepository;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fFactoryDataStore: FactoryStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get title(): string {
    const factory = this.fFactoryDataStore.getFactoryById(this.factoryId);

    const factoryName = factory ? factory.name : "Factory";

    return `${factoryName} Billing Info Edit`;
  }

  get billingInfoRecord(): BillingInfoRecord | undefined {
    return this.fFactoryInvoiceDataStore.getBillingInfoRecordByFactoryId(
      this.factoryId
    );
  }

  constructor() {
    super();
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public getFactoriesOrder(): Promise<string[]> {
    return this.fFactoryRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  public onCancel(): void {
    void this.$router.push({
      name: "BillingInfoFactoriesListPage",
      query: this.listQuery,
    });
  }

  public onBillingInfoRecordUpdated(): void {
    void this.$router.push({
      name: "BillingInfoFactoriesListPage",
      query: this.listQuery,
    });
  }

  private async getBillingInfoRecordData(factoryId: string): Promise<void> {
    await this.fFactoryInvoiceDataStore.loadBillingInfoRecordByFactoryId({
      factoryId: factoryId,
      useCache: false,
    });
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (this.factoryId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fFactoryDataStore.loadFactoryById({ id: this.factoryId });

      await this.getBillingInfoRecordData(this.factoryId);

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
