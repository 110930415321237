import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ThroughputReport from "./throughput-report.model";

export default class ThroughputReportFactory
  implements ObjectBuilderInterface<ThroughputReport> {
  public buildFromJSON(data: { [key: string]: any }): ThroughputReport {
    const value = new ThroughputReport(
      data.factory_name,
      data.time_period,
      data.assigned_to_factory,
      data.designed,
      data.submitted_to_inspection,
      data.passed_inspection,
      data.bulk_shipped
    );

    return value;
  }
}
