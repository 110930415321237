import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import Plushie from "@/modules/plushie/plushie.model";
import dataStore from "@/store";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import { Command } from "../command";
import StatusSummary from "../status-summary.model";
import DirectShipmentIntention from "../direct-shipment-intention.model";
import DirectShipmentIntentionRule from "../direct-shipment-intention-rule.model";
import { DirectShipmentIntentionRuleUpdateData } from "../direct-shipment-intention-rule-update-data.interface";

const name = "ProductionProcessStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    plushieAvailableCommands: {},
    plushiesProcessing: [],
    statusesSummaries: {},
    directShipmentIntention: {},
    directShipmentIntentionRule: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class ProductionProcessStore extends VuexModule {
  plushieAvailableCommands: Dictionary<Set<Command>> = {};
  plushiesProcessing: string[] = [];
  statusesSummaries: Dictionary<StatusSummary> = {};
  directShipmentIntention: Dictionary<DirectShipmentIntention> = {};
  directShipmentIntentionRule: Dictionary<DirectShipmentIntentionRule> = {};

  // ################################### AVAILABLE COMMANDS #########################################

  get getAvailableCommandsForPlushieId(): (plushieId: string) => Set<Command> {
    return (plushieId: string) => {
      const commands = this.plushieAvailableCommands[plushieId];
      if (!commands) {
        return new Set<Command>();
      }

      return new Set<Command>(commands);
    };
  }

  @Mutation
  updatePlushieAvailableCommands({
    plushieId,
    commands,
  }: {
    plushieId: string;
    commands: Set<Command>;
  }): void {
    Vue.set(this.plushieAvailableCommands, plushieId, commands);
  }

  @Action({ rawError: true })
  async loadAvailableCommandsForPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<Set<Command>> {
    if (useCache && this.plushieAvailableCommands[plushieId]) {
      return this.plushieAvailableCommands[plushieId];
    }

    const commands = await DIContainer.ProductionProcessRepository.getAvailableCommands(
      plushieId
    );

    this.updatePlushieAvailableCommands({ plushieId, commands });

    return commands;
  }

  // ################################### COMMANDS EXECUTION #########################################

  get isPlushieProcessing(): (plushieId: string) => boolean {
    return (plushieId: string) => this.plushiesProcessing.includes(plushieId);
  }

  @Mutation
  markPlushieAsProcessing(plushieId: string): void {
    if (this.plushiesProcessing.includes(plushieId)) {
      return;
    }

    this.plushiesProcessing.push(plushieId);
  }

  @Mutation
  removePlushieProcessingMark(plushieId: string): void {
    const index = this.plushiesProcessing.indexOf(plushieId);

    if (index === -1) {
      return;
    }

    this.plushiesProcessing.splice(index, 1);
  }

  @Action({ rawError: true })
  async approveCustomerPreview(plushieId: string): Promise<void> {
    this.markPlushieAsProcessing(plushieId);

    try {
      await DIContainer.ProductionProcessRepository.approveCustomerPreview(
        plushieId
      );

      await this.context.dispatch("refreshPlushie", plushieId);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }
  }

  @Action({ rawError: true })
  async cancelPlushie(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.cancelPlushie(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async moveToDesign(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.moveToDesign(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async bulkMoveToDesign(plushieIds: string[]): Promise<Plushie[]> {
    const collection = await DIContainer.ProductionProcessRepository.bulkMoveToDesign(
      plushieIds
    );

    const items = collection.getItems();

    const dispatchPromises: Promise<any>[] = [];

    items.forEach((item) => {
      dispatchPromises.push(this.context.dispatch("setPlushie", item));
    });

    await Promise.all(dispatchPromises);

    return items;
  }

  @Action({ rawError: true })
  async moveToProduction(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.moveToProduction(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async prepareForShipment(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.prepareForShipment(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async bulkMoveToProduction(plushieIds: string[]): Promise<Plushie[]> {
    const collection = await DIContainer.ProductionProcessRepository.bulkMoveToProduction(
      plushieIds
    );

    const items = collection.getItems();

    const dispatchPromises: Promise<any>[] = [];

    items.forEach((item) => {
      dispatchPromises.push(this.context.dispatch("setPlushie", item));
    });

    await Promise.all(dispatchPromises);

    return items;
  }

  @Action({ rawError: true })
  async bulkPrepareToShipment(plushieIds: string[]): Promise<Plushie[]> {
    const collection = await DIContainer.ProductionProcessRepository.bulkPrepareToShipment(
      plushieIds
    );

    const items = collection.getItems();

    const dispatchPromises: Promise<any>[] = [];

    items.forEach((item) => {
      dispatchPromises.push(this.context.dispatch("setPlushie", item));
    });

    await Promise.all(dispatchPromises);

    return items;
  }

  @Action({ rawError: true })
  async MoveToQualityInspection(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.moveToQualityInspection(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async moveToReview(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.moveToReview(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async putOnHold(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.putOnHold(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async reassignFactory({
    plushieId,
    factoryId,
  }: {
    plushieId: string;
    factoryId: string;
  }): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.reassignFactory(
        plushieId,
        factoryId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async bulkReassignFactory({
    plushieIds,
    factoryId,
  }: {
    plushieIds: string[];
    factoryId: string;
  }): Promise<Plushie[]> {
    const collection = await DIContainer.ProductionProcessRepository.bulkReassignFactory(
      plushieIds,
      factoryId
    );

    const items = collection.getItems();

    const dispatchPromises: Promise<any>[] = [];

    items.forEach((item) => {
      dispatchPromises.push(this.context.dispatch("setPlushie", item));
    });

    await Promise.all(dispatchPromises);

    return items;
  }

  @Action({ rawError: true })
  async returnFromCustomerPreview(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.returnFromCustomerPreview(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async returnToDesign(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.returnToDesign(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async returnToQualityInspection(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.returnToQualityInspection(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async returnToCustomerPreview(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.returnToCustomerPreview(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async reviewApprove({
    plushieId,
    comment,
  }: {
    plushieId: string;
    comment?: string;
  }): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.reviewApprove(
        plushieId,
        comment
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async reviewApproveAndSkipDesign({
    plushieId,
    comment,
  }: {
    plushieId: string;
    comment?: string;
  }): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.reviewApproveAndSkipDesign(
        plushieId,
        comment
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async forceQualityInspectionApprove(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.forceQualityInspectionApprove(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  // ################################### BULK COMMANDS EXECUTION #########################################

  @Action({ rawError: true })
  async startSampleProduction(payload: {
    plushie: string;
    factoryIdToAssign: string;
    taskDetails: string;
    expectedDueDate: string;
    negotiatedPrice: number;
  }): Promise<Plushie> {
    this.markPlushieAsProcessing(payload.plushie);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.startSampleProduction(
        payload
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(payload.plushie);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async approveBulkPreview(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.approveBulKPreview(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async approvePpsPreview(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.approvePpsPreview(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async confirmBulkShipment(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.confirmBulkShipment(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async moveToBulkInspection(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.moveToBulkInspection(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async moveToPpsInspection(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.moveToPpsInspection(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async sendToCustomer({
    plushieId,
    carrierId,
    trackingNumber,
  }: {
    plushieId: string;
    carrierId: string;
    trackingNumber?: string;
  }): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.sendToCustomer(
        plushieId,
        carrierId,
        trackingNumber
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async skipShipment(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.skipShipment(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async moveToDirectShipment(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.moveToDirectShipment(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  @Action({ rawError: true })
  async returnIntoPreparedToShipment(plushieId: string): Promise<Plushie> {
    this.markPlushieAsProcessing(plushieId);
    let plushie;

    try {
      plushie = await DIContainer.ProductionProcessRepository.returnIntoPreparedToShipment(
        plushieId
      );

      await this.context.dispatch("setPlushie", plushie);
    } finally {
      this.removePlushieProcessingMark(plushieId);
    }

    return plushie;
  }

  // ################################### STATUS SUMMARY #########################################

  get getBatchFlowStatusesSummariesList(): StatusSummary[] {
    const list: StatusSummary[] = [];

    [
      PlushieStatusValue.PENDING_BULK_PRODUCTION,
      PlushieStatusValue.IN_BULK_DESIGN,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.PPS_PREVIEW,
      PlushieStatusValue.IN_BULK_PRODUCTION,
      PlushieStatusValue.BULK_INSPECTION,
      PlushieStatusValue.BULK_PREVIEW,
      PlushieStatusValue.PENDING_BULK_SHIPMENT,
      PlushieStatusValue.READY_FOR_BULK_SHIPMENT,
    ].forEach((statusId) => {
      if (!this.statusesSummaries[statusId]) {
        return;
      }

      list.push(this.statusesSummaries[statusId]);
    });

    return list;
  }

  get getRegularFlowStatusesSummariesList(): StatusSummary[] {
    const list: StatusSummary[] = [];

    [
      PlushieStatusValue.ON_HOLD,
      PlushieStatusValue.REVIEW,
      PlushieStatusValue.READY_FOR_PRODUCTION,
      PlushieStatusValue.IN_DESIGN,
      PlushieStatusValue.IN_PRODUCTION,
      PlushieStatusValue.REWORK,
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.READY_FOR_SHIPMENT,
      PlushieStatusValue.CUSTOMER_PREVIEW_APPROVED,
      PlushieStatusValue.AWAITING_DIRECT_SHIPMENT,
      PlushieStatusValue.SHIPPED_TO_MAIN_FACILITY,
    ].forEach((statusId) => {
      if (!this.statusesSummaries[statusId]) {
        return;
      }

      list.push(this.statusesSummaries[statusId]);
    });

    return list;
  }

  @Mutation
  updateStatusSummary(values: StatusSummary[]): void {
    const valuesHash: Dictionary<StatusSummary> = {};

    values.forEach((element: StatusSummary) => {
      valuesHash[element.statusId] = element;
    });

    Vue.set(this, "statusesSummaries", valuesHash);
  }

  @Action({ rawError: true })
  async loadStatusesSummaries(): Promise<StatusSummary[]> {
    const statusesSummaries = await DIContainer.StatusSummaryRepository.getStatusesSummariesList();

    this.updateStatusSummary(statusesSummaries);

    return statusesSummaries;
  }

  // ################################### DIRECT SHIPMENT INTENTIONS #########################################

  get getDirectShipmentIntentionById(): (
    id: string
  ) => DirectShipmentIntention | undefined {
    return (id: string) => {
      return this.directShipmentIntention[id];
    };
  }

  @Mutation
  updateDirectShipmentIntention(
    directShipmentIntention: DirectShipmentIntention
  ): void {
    Vue.set(
      this.directShipmentIntention,
      directShipmentIntention.id,
      directShipmentIntention
    );
  }

  @Mutation
  removeDirectShipmentIntentionById(id: string): void {
    Vue.delete(this.directShipmentIntention, id);
  }

  @Action({ rawError: true })
  async loadDirectShipmentIntentionById(
    id: string
  ): Promise<DirectShipmentIntention | undefined> {
    const directShipmentIntention = await DIContainer.DirectShipmentIntentionRepository.loadById(
      id
    );

    if (directShipmentIntention) {
      this.updateDirectShipmentIntention(directShipmentIntention);
    } else {
      this.removeDirectShipmentIntentionById(id);
    }

    return directShipmentIntention;
  }

  @Action({ rawError: true })
  async createDirectShipmentIntention(
    directShipmentIntention: DirectShipmentIntention
  ): Promise<DirectShipmentIntention> {
    const result = await DIContainer.DirectShipmentIntentionRepository.save(
      directShipmentIntention
    );

    this.updateDirectShipmentIntention(result);

    return result;
  }

  @Action({ rawError: true })
  async deleteDirectShipmentIntention(
    directShipmentIntention: DirectShipmentIntention
  ): Promise<void> {
    await DIContainer.DirectShipmentIntentionRepository.delete(
      directShipmentIntention
    );

    this.removeDirectShipmentIntentionById(directShipmentIntention.id);
  }

  // ################################### DIRECT SHIPMENT INTENTION RULES #########################################

  get directShipmentIntentionRules(): DirectShipmentIntentionRule[] {
    return Array.from(Object.values(this.directShipmentIntentionRule));
  }

  get directShipmentIntentionRuleById(): (
    id: string
  ) => DirectShipmentIntentionRule | undefined {
    return (id: string) => this.directShipmentIntentionRule[id];
  }

  @Mutation
  updateDirectShipmentIntentionRule(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): void {
    Vue.set(
      this.directShipmentIntentionRule,
      directShipmentIntentionRule.id,
      directShipmentIntentionRule
    );
  }

  @Mutation
  removeDirectShipmentIntentionRuleById(id: string): void {
    Vue.delete(this.directShipmentIntentionRule, id);
  }

  @Mutation
  updateDirectShipmentIntentionRules(
    directShipmentIntentionRules: DirectShipmentIntentionRule[]
  ): void {
    const dictionary: Dictionary<DirectShipmentIntentionRule> = {};

    for (const directShipmentIntentionRule of directShipmentIntentionRules) {
      dictionary[directShipmentIntentionRule.id] = directShipmentIntentionRule;
    }

    this.directShipmentIntentionRule = dictionary;
  }

  @Action({ rawError: true })
  async loadDirectShipmentIntentionRules(): Promise<
    DirectShipmentIntentionRule[]
  > {
    const result = await DIContainer.DirectShipmentIntentionRuleRepository.getList(
      1,
      999
    );

    const items = result.getItems();

    this.updateDirectShipmentIntentionRules(items);

    return items;
  }

  @Action({ rawError: true })
  async loadDirectShipmentRuleById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<DirectShipmentIntentionRule | undefined> {
    if (useCache && this.directShipmentIntentionRule[id]) {
      return this.directShipmentIntentionRule[id];
    }

    const item = await DIContainer.DirectShipmentIntentionRuleRepository.loadById(
      id
    );

    if (item) {
      this.updateDirectShipmentIntentionRule(item);
    }

    return item;
  }

  @Action({ rawError: true })
  async deleteDirectShipmentIntentionRule(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): Promise<void> {
    await DIContainer.DirectShipmentIntentionRuleRepository.delete(
      directShipmentIntentionRule
    );

    this.removeDirectShipmentIntentionRuleById(directShipmentIntentionRule.id);
  }

  @Action({ rawError: true })
  async saveDirectShipmentIntentionRule(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): Promise<void> {
    const result = await DIContainer.DirectShipmentIntentionRuleRepository.save(
      directShipmentIntentionRule
    );

    this.updateDirectShipmentIntentionRule(result);
  }

  @Action({ rawError: true })
  async updateDirectShipmentIntentionRuleData({
    id,
    directShipmentIntentionRuleUpdateData,
  }: {
    id: string;
    directShipmentIntentionRuleUpdateData: DirectShipmentIntentionRuleUpdateData;
  }): Promise<DirectShipmentIntentionRule> {
    const result = await DIContainer.DirectShipmentIntentionRuleRepository.updateDirectShipmentIntentionRule(
      id,
      directShipmentIntentionRuleUpdateData
    );

    this.updateDirectShipmentIntentionRule(result);

    return result;
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushieAvailableCommands[plushieId]) {
      return;
    }

    await this.loadAvailableCommandsForPlushieId({
      plushieId,
      useCache: false,
    });
    return;
  }

  @Action({ rawError: true })
  async onFirstTagAdded(plushieId: string): Promise<void> {
    if (!this.plushieAvailableCommands[plushieId]) {
      return;
    }

    await this.loadAvailableCommandsForPlushieId({
      plushieId,
      useCache: false,
    });
    return;
  }

  @Action({ rawError: true })
  async onLastTagRemoved(plushieId: string): Promise<void> {
    if (!this.plushieAvailableCommands[plushieId]) {
      return;
    }

    await this.loadAvailableCommandsForPlushieId({
      plushieId,
      useCache: false,
    });
    return;
  }

  @Action({ rawError: true })
  async onBodyPartValuePlushieRelationChanged(
    plushieId: string
  ): Promise<void> {
    if (!this.plushieAvailableCommands[plushieId]) {
      return;
    }

    await this.loadAvailableCommandsForPlushieId({
      plushieId,
      useCache: false,
    });
    return;
  }

  @Action({ rawError: true })
  async onFirstQaAssetAdded(plushieId: string): Promise<void> {
    if (!this.plushieAvailableCommands[plushieId]) {
      return;
    }

    await this.loadAvailableCommandsForPlushieId({
      plushieId,
      useCache: false,
    });
    return;
  }

  @Action({ rawError: true })
  async onLastQaAssetRemoved(plushieId: string): Promise<void> {
    if (!this.plushieAvailableCommands[plushieId]) {
      return;
    }

    await this.loadAvailableCommandsForPlushieId({
      plushieId,
      useCache: false,
    });
    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
