import { MutableValue } from "@/modules/api/mutable-value.interface";

import { ExtraPaymentAuthorValue } from "./extra-payment-author.value";
import { ExtraPaymentFaultValue } from "./extra-payment-fault.value";

export default class ExtraPayment implements MutableValue {
  public isNew = true;

  public fault?: ExtraPaymentFaultValue;
  public plushie?: string;
  public invoice?: string;

  private fUser?: string;
  private fAuthor?: ExtraPaymentAuthorValue;
  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fAmount: number,
    private fDescription: string
  ) {
    [fId, fAmount, fDescription].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get amount(): number {
    return this.fAmount;
  }

  get description(): string {
    return this.fDescription;
  }

  get user(): string | undefined {
    return this.fUser;
  }

  get author(): ExtraPaymentAuthorValue | undefined {
    return this.fAuthor;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
