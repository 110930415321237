import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { FieldOrderDesc } from "@/modules/api/field-order-options";

import PenaltyRuleHistoryRecord from "./penalty-rule-history-record.model";

export default class PenaltyRuleHistoryRecordRepository extends BasicRepository<PenaltyRuleHistoryRecord> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PenaltyRuleHistoryRecord>,
    responseParser: ApiCollectionResponseParserService<PenaltyRuleHistoryRecord>
  ) {
    super("penalty_rule_history_records", api, objectBuilder, responseParser);
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<ResourceCollection<PenaltyRuleHistoryRecord>> {
    return this.fetchList({ factoryId: factoryId }, 1, 9999, [
      ["createdAt", FieldOrderDesc],
    ]);
  }
}
