import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PlushieFactoryAssignment implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fFactory: string
  ) {
    [fId, fPlushie, fFactory].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get factory() {
    return this.fFactory;
  }

  get plushie() {
    return this.fPlushie;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get updatedAt() {
    return this.fUpdatedAt;
  }
}
