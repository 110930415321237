import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieSummary from "./plushie-summary.model";

export default class PlushieSummaryFactory
  implements ObjectBuilderInterface<PlushieSummary> {
  public buildFromJSON(data: { [key: string]: any }): PlushieSummary {
    const value = new PlushieSummary(
      data.id,
      data.product.toString(),
      +data.quantity,
      data.storeItemId,
      data.status.toString(),
      new Date(data.orderDate),
      data.orderNumber,
      data.store.toString(),
      data.delay,
      data.upgradeIds,
      +data.priority
    );

    (value as any).fDueDate = new Date(data.dueDate);

    (value as any).fFactory = data.factory;
    (value as any).fMainArtworkStorageItemId = data.mainArtworkStorageItemId;
    (value as any).fReviewer = data.reviewer;
    (value as any).fResponsibleUser = data.responsibleUserId;
    (value as any).fNotes = data.notes;

    value.isNew = false;

    return value;
  }
}
