import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class ProductionTimeNormHistoryItem implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fType: string,
    private fUser: string,
    private fFactory: string,
    private fProduct: string,
    private fDays: number
  ) {
    [fId, fType, fUser, fFactory, fProduct, fDays].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get type(): string {
    return this.fType;
  }

  get user(): string {
    return this.fUser;
  }

  get factory(): string {
    return this.fFactory;
  }

  get product(): string {
    return this.fProduct;
  }

  get days(): number {
    return this.fDays;
  }

  get createdAt() {
    return this.fCreatedAt;
  }
}
