









import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import StoreValue from "@/lib/store.value";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import OrderInformation from "@/modules/plushie/order-information.model";
import rootStore from "@/store";

import EditableText from "./EditableText.vue";

import Plushie from "../plushie.model";
import PlushieStore from "../store";

@Component({
  components: {
    EditableText,
  },
})
export default class OrderNumber extends Vue {
  @Prop({ required: true })
  public readonly orderInformation!: OrderInformation;

  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private readonly fShowOrderLinkForStores = [
    StoreValue.BUDSIES,
    StoreValue.PETSIES,
    StoreValue.BULK_ORDERS,
  ];

  private fPlushieStore: PlushieStore;

  constructor() {
    super();
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  get orderUrl(): string | undefined {
    const orderUrlTemplate = process.env.VUE_APP_ORDER_URL_TEMPLATE;

    if (!orderUrlTemplate || !this.orderInformation) {
      return undefined;
    }

    if (typeof orderUrlTemplate != "string") {
      return undefined;
    }
    return orderUrlTemplate.replace(
      "{ORDER_ID}",
      this.orderInformation.orderId
    );
  }

  get plushie(): Plushie | undefined {
    if (this.plushieId == null) {
      return undefined;
    }

    return this.fPlushieStore.getPlushieById(this.plushieId);
  }

  get showOrderLink(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      ![AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE].includes(
        user.role
      )
    ) {
      return false;
    }

    return this.fShowOrderLinkForStores.includes(
      this.plushie.store as StoreValue
    );
  }
}
