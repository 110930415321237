import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import DeactivatedUser from "./deactivated-user.model";

export default class DeactivatedUserRepository extends BasicRepository<DeactivatedUser> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<DeactivatedUser>,
    responseParser: ApiCollectionResponseParserService<DeactivatedUser>
  ) {
    super("deactivated_users", api, objectBuilder, responseParser);
  }

  protected prepareData(item: DeactivatedUser): Record<string, string> {
    return {
      userId: item.id,
    };
  }
}
