




import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  metaInfo: {},
})
export default class PrintPage extends Vue {}
