




































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import dataStore from "@/store";
import DueDatesManagementStore from "@/modules/due-dates-management/store";
import FactoryStore from "@/modules/factory/store";
import PlushiePriorityStore from "@/modules/plushie-priority/store";
import Factory from "@/modules/factory/factory.model";
import ProductionSchedule from "@/modules/due-dates-management/production-schedule.model";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { NoCache } from "@/lib/no-cache.decorator";

import PlushieStatus from "../plushie-status.model";
import Plushie from "../plushie.model";
import PlushieStore from "../store";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class ProductionProgressCard extends Vue {
  @Prop({ required: true })
  public readonly plushie!: Plushie;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = true;

  private fDueDatesManagementDataStore: DueDatesManagementStore;
  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;
  private fPlushiePriorityDataStore: PlushiePriorityStore;

  constructor() {
    super();

    this.fDueDatesManagementDataStore = getModule(
      DueDatesManagementStore,
      dataStore
    );
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fPlushiePriorityDataStore = getModule(PlushiePriorityStore, dataStore);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get canViewFactories(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_VIEW);
  }

  @NoCache
  get factory(): Factory | undefined {
    if (!this.plushie || !this.canViewFactories) {
      return undefined;
    }

    const assignment = this.fFactoryDataStore.getFactoryAssignmentByPlushieId(
      this.plushie.id
    );

    if (assignment == null) {
      return undefined;
    }

    return this.fFactoryDataStore.getFactoryById(assignment.factory);
  }

  get plushieStatus(): PlushieStatus | undefined {
    if (this.plushie == null) {
      return undefined;
    }

    return this.fPlushieDataStore.getPlushieStatusById(this.plushie.status);
  }

  get productionSchedule(): ProductionSchedule | undefined {
    return this.fDueDatesManagementDataStore.getProductionScheduleByPlushieId(
      this.plushie.id
    );
  }

  get speedText(): string | undefined {
    const priority = this.fPlushiePriorityDataStore.getPlushiePriorityByPlushieId(
      this.plushie.id
    );

    if (!priority) {
      return undefined;
    }

    let value = priority.value;

    if (value >= 300) {
      value -= 300;
    }

    if (value >= 200) {
      return "Super Rush";
    } else if (value >= 100) {
      return "Rush";
    } else {
      return "Standard";
    }
  }

  get speedClass(): string {
    const priority = this.fPlushiePriorityDataStore.getPlushiePriorityByPlushieId(
      this.plushie.id
    );

    if (!priority) {
      return "";
    }

    let value = priority.value;

    if (value >= 300) {
      value -= 300;
    }

    if (value >= 100) {
      return "-rush";
    }

    return "";
  }

  private async getPlushieData() {
    if (!this.plushie) {
      return;
    }

    const factoriesPromise = this.canViewFactories
      ? this.fFactoryDataStore.loadFactories()
      : undefined;

    await Promise.all([
      this.fFactoryDataStore.loadFactoryAssignmentByPlushieId({
        plushieId: this.plushie.id,
      }),
      this.fDueDatesManagementDataStore.loadProductionScheduleByPlushieId({
        plushieId: this.plushie.id,
      }),
      this.fPlushiePriorityDataStore.loadPlushiePriorityByPlushieId({
        plushieId: this.plushie.id,
      }),
      this.fPlushieDataStore.loadPlushieStatuses(),
      factoriesPromise,
    ]);
  }

  @Watch("plushie", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushie) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData();
    this.fIsLoading = false;
  }
}
