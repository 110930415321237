










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ListPaginator from "@/modules/general/components/ListPaginator.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import FileStorageStore from "@/modules/file-storage/store";

import Item from "./SearchResult/Item.vue";

import TagStore from "../../store";

@Component({
  components: {
    Item,
    ListPaginator,
    LoadingSpinner,
  },
})
export default class SearchResult extends Vue {
  private static readonly ITEMS_PER_PAGE_DEFAULT = 24;

  @Prop({ required: true })
  public readonly foundPlushies!: string[];

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;
  private fTagDataStore: TagStore;

  private fIsLoading = false;

  private fCurrentPage = 1;

  private fPerPage = SearchResult.ITEMS_PER_PAGE_DEFAULT;

  get perPageOptions(): number[] {
    return [8, 16, 24, 32, 64];
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get currentPage(): number {
    return this.fCurrentPage;
  }

  get pagesQty(): number {
    return Math.ceil(this.foundPlushies.length / this.fPerPage);
  }

  get currentPagePlushieIds(): string[] {
    if (this.foundPlushies.length < 1) {
      return [];
    }

    return this.foundPlushies.slice(
      (this.fCurrentPage - 1) * this.fPerPage,
      this.fCurrentPage * this.fPerPage
    );
  }

  constructor() {
    super();

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fTagDataStore = getModule(TagStore, dataStore);
  }

  public setPage(page: number): void {
    this.fCurrentPage = page;
  }

  private async getPlushiesData(plushieIds: string[]): Promise<void> {
    const [mainImages, tagRelations] = await Promise.all([
      this.fPlushieDataStore.loadPlushieMainImageByPlushieIds(plushieIds),
      this.fTagDataStore.loadPlushieTagRelationsByPlushieIds({
        plushieIds,
      }),
      this.fPlushieDataStore.loadPlushiesByIds(plushieIds),
    ]);

    const plushieTagIds: string[] = [];

    Object.keys(tagRelations).forEach((plushieId) => {
      const tagIds = tagRelations[plushieId].map((relation) => relation.tag);

      tagIds.forEach((tagId) => {
        if (!plushieTagIds.includes(tagId)) {
          plushieTagIds.push(tagId);
        }
      });
    });

    const imageStoreItemIds: string[] = [];
    Object.keys(mainImages).forEach((plushieId) => {
      imageStoreItemIds.push(mainImages[plushieId].storageItem);
    });

    await Promise.all([
      this.fFileStorageDataStore.loadItemsByIds(imageStoreItemIds),
      this.fTagDataStore.loadTagsByIds(plushieTagIds),
    ]);
  }

  @Watch("currentPagePlushieIds", { immediate: true })
  private async _onPlushieIdsChange() {
    if (this.currentPagePlushieIds.length < 1) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getPlushiesData(this.currentPagePlushieIds);
    } finally {
      this.fIsLoading = false;
    }
  }

  @Watch("fPerPage", { immediate: true })
  private _onPerPageChange(): void {
    if (this.pagesQty >= this.fCurrentPage) {
      return;
    }

    this.fCurrentPage = 1;
  }

  @Watch("foundPlushies")
  private _onFoundPlushiesChange(): void {
    this.fCurrentPage = 1;
  }
}
