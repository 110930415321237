



























import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import rootStore from "@/store";

import DirectShipmentIntentionRuleEditForm from "./EditForm.vue";

import DirectShipmentIntentionRule from "../../direct-shipment-intention-rule.model";
import ProductionProcessStore from "../../store";

@Component({
  metaInfo: {
    title: "Edit Direct Shipment Intention Rule",
  },
  components: {
    DirectShipmentIntentionRuleEditForm,
    LoadingSpinner,
  },
})
export default class DirectShipmentIntentionRuleEditPage extends Vue {
  @Prop()
  public readonly id?: string;

  private fProductionProcessStore: ProductionProcessStore;

  private fIsLoading = false;
  private fDirectShipmentIntentionRule?: DirectShipmentIntentionRule;

  get directShipmentIntentionRule(): DirectShipmentIntentionRule | undefined {
    return this.fDirectShipmentIntentionRule;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();

    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);
  }

  protected data(): Record<string, DirectShipmentIntentionRule | undefined> {
    return {
      fDirectShipmentIntentionRule: this.fDirectShipmentIntentionRule,
    };
  }

  private async loadData(): Promise<void> {
    if (!this.id) {
      return;
    }

    this.fIsLoading = true;

    try {
      this.fDirectShipmentIntentionRule = await this.fProductionProcessStore.loadDirectShipmentRuleById(
        {
          id: this.id,
          useCache: false,
        }
      );
    } finally {
      this.fIsLoading = false;
    }
  }

  @Watch("id", { immediate: true })
  private _onIdChange() {
    void this.loadData();
  }
}
