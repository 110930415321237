





















































































import Vue from "vue";
import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import rootStore from "@/store";
import UserInfo from "@/modules/account/user-info.model";
import AccountStore from "@/modules/account/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import BatchCreationForm from "./BatchCreationForm.vue";
import BulkProjectCustomerInfo from "./BulkProjectCustomerInfo.vue";
import BulkProjectInfo from "./BulkProjectInfo.vue";
import BulkProjectRelatedPlushies from "./BulkProjectRelatedPlushies.vue";
import BulkProjectMainImage from "./BulkProjectMainImage.vue";
import BatchCreationDialog from "./BatchCreationDialog.vue";
import BulkProjectResponsibleUserCard from "./ResponsibleUser/Card.vue";
import BulkProjectResponsibleUserAssignForm from "./ResponsibleUser/AssignForm.vue";

import BulkProjectStore from "../store";
import BulkProject from "../bulk-project.model";
import BulkProjectRepository from "../bulk-project.repository";

@Component({
  metaInfo() {
    const component = this as BulkProjectViewPage;

    return {
      title: component.bulkProject
        ? `Bulk Project #${component.bulkProject.incrementalId}`
        : "Bulk Project View",
    };
  },
  components: {
    BatchCreationForm,
    BulkProjectCustomerInfo,
    BulkProjectInfo,
    BulkProjectRelatedPlushies,
    BulkProjectResponsibleUserAssignForm,
    BulkProjectResponsibleUserCard,
    BulkProjectMainImage,
    MoonLoader,
  },
})
export default class BulkProjectViewPage extends Vue {
  @Prop({ required: true })
  public readonly bulkProjectId!: string;

  @Prop()
  public readonly listQuery?: Record<string, unknown>;

  @Inject("AuthenticatedUserProvider")
  private fAuthenticatedUserProvider!: AuthenticatedUserProvider;

  @Inject("BulkProjectRepository")
  private fBulkProjectRepository!: BulkProjectRepository;

  @Inject("window")
  private fWindow!: Window;

  private fAccountStore: AccountStore;
  private fBulkProjectStore: BulkProjectStore;

  private fIsLoading = true;
  private fIsLoadingError = false;

  get bulkProject(): BulkProject | undefined {
    return this.fBulkProjectStore.getBulkProjectById(this.bulkProjectId);
  }

  get bulkProjectMainImageStorageItemId(): string {
    return this.bulkProject ? this.bulkProject.imageStorageItemId : "";
  }

  get customerInfo(): UserInfo | undefined {
    if (!this.bulkProject) {
      return;
    }

    return this.fAccountStore.getUserInfoById(this.bulkProject.customer);
  }

  get isLinkToBulkProjectsListAvailable(): boolean {
    return !!this.listQuery && Object.keys(this.listQuery).length > 0;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isLoadingError(): boolean {
    return this.fIsLoadingError;
  }

  get isStickyElementEnabled(): boolean {
    return this.fWindow.innerWidth > 1024;
  }

  get pageTitle(): string {
    if (!this.bulkProject) {
      return `Bulk Project`;
    }

    let title = `Bulk Project: ${this.bulkProject.incrementalId}`;

    if (this.bulkProject.name) {
      title += `, ${this.bulkProject.name}`;
    }

    if (this.customerInfo) {
      title += this.bulkProject.name ? "" : ",";
      title += ` by ${this.customerInfo.fullName}`;
    }

    return title;
  }

  get showActionsSection(): boolean {
    return this.showBatchCreationForm;
  }

  get showBatchCreationForm(): boolean {
    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BATCH_PLUSHIE_CREATE);
  }

  get showResponsibleUserAssignForm(): boolean {
    if (!this.bulkProject || this.bulkProject.responsibleUserId) {
      return false;
    }

    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_PROJECT_MANAGE);
  }

  get showResponsibleUserCard(): boolean {
    return !!this.bulkProject && !!this.bulkProject.responsibleUserId;
  }

  constructor() {
    super();
    this.fAccountStore = getModule(AccountStore, rootStore);
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
  }

  created(): void {
    void this.loadBulkProjectData();
  }

  public async onCreateBatchButtonClick(): Promise<void> {
    const dialogFunction = create<Record<string, unknown>, boolean>(
      BatchCreationDialog
    );

    const isBatchCreated = await dialogFunction({
      projectId: this.bulkProjectId,
    });

    if (!isBatchCreated) {
      return;
    }

    this.onBatchCreated();
  }

  public onBatchCreated(): void {
    const bulkProjectRelatedPlushiesComponent = this.getBulkProjectRelatedPlushiesComponent();

    if (!bulkProjectRelatedPlushiesComponent) {
      return;
    }

    bulkProjectRelatedPlushiesComponent.updateTableData();
  }

  private getBulkProjectRelatedPlushiesComponent():
    | InstanceType<typeof BulkProjectRelatedPlushies>
    | undefined {
    return this.$refs["relatedPlushies"] as
      | InstanceType<typeof BulkProjectRelatedPlushies>
      | undefined;
  }

  private async loadBulkProjectData(): Promise<void> {
    this.fIsLoading = true;
    this.fIsLoadingError = false;

    try {
      const bulkProject = await this.fBulkProjectStore.loadBulkProjectById(
        this.bulkProjectId
      );

      if (!bulkProject) {
        return;
      }

      await this.fAccountStore.loadUserInfoById({
        id: bulkProject.customer,
      });
    } catch (error) {
      this.fIsLoadingError = true;
      throw error;
    } finally {
      this.fIsLoading = false;
    }
  }

  @Watch("bulkProjectId")
  private _onBulkProjectIdChange() {
    if (!this.bulkProjectId) {
      return;
    }

    void this.loadBulkProjectData();
  }
}
