import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import { ResourceCollection } from "@/modules/api/resource.collection";
import dataStore from "@/store";

import Alert from "../alert.model";
import TagAlertAssignRule from "../tag-alert-assign-rule.model";

const name = "PlushieAlertStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    plushieAlert: {},
    plushieTagAlertAssignRule: {},
    plushieAlertAssignRules: {},
    plushieAssignedAlerts: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class PlushieAlertStore extends VuexModule {
  plushieAlert: Dictionary<Alert> = {};
  plushieTagAlertAssignRule: Dictionary<TagAlertAssignRule> = {};

  plushieAlertAssignRules: Dictionary<string[]> = {};
  plushieAssignedAlerts: Dictionary<string[]> = {};

  // ################################### ALERTS #########################################
  get plushieAlertsList(): Alert[] {
    const list: Alert[] = [];

    Object.keys(this.plushieAlert).forEach((id) => {
      list.push(this.plushieAlert[id]);
    });

    list.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
    );

    return list;
  }

  get getPlushieAlertById(): (id: string) => Alert | undefined {
    return (id: string) => this.plushieAlert[id];
  }

  @Mutation
  updatePlushieAlert(payload: Alert): void {
    Vue.set(this.plushieAlert, payload.id, payload);
  }

  @Mutation
  removePlushieAlert(id: string): void {
    delete this.plushieAlert[id];
  }

  @Action({ rawError: true })
  async deletePlushieAlertById(id: string): Promise<void> {
    await DIContainer.PlushieAlertRepository.deleteById(id);

    this.removePlushieAlert(id);
  }

  @Action({ rawError: true })
  async loadPlushieAlerts({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<Alert>> {
    const collection = await DIContainer.PlushieAlertRepository.getList(
      page,
      limit,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updatePlushieAlert(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadPlushieAlertById(id: string): Promise<Alert | undefined> {
    if (this.plushieAlert[id] !== undefined) {
      return this.plushieAlert[id];
    }

    const alert = await DIContainer.PlushieAlertRepository.getById(id);

    if (alert) {
      this.updatePlushieAlert(alert);
    }

    return alert;
  }

  @Action({ rawError: true })
  async loadPlushieAlertsByIds(
    ids: string[]
  ): Promise<Dictionary<Alert | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<Alert | undefined> = {};

    ids.forEach((id) => {
      if (!this.plushieAlert[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.plushieAlert[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.PlushieAlertRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updatePlushieAlert(item);
    });

    return { ...result, ...items };
  }

  @Action({ rawError: true })
  async savePlushieAlert(alert: Alert): Promise<Alert> {
    const item = await DIContainer.PlushieAlertRepository.save(alert);

    this.updatePlushieAlert(item);

    return item;
  }

  // ################################### ALERT ASSIGNMENTS #########################################
  get getPlushieAlertsByPlushieId(): (plushieId: string) => string[] {
    return (plushieId: string) => {
      if (this.plushieAssignedAlerts[plushieId] == null) {
        return [];
      }

      return [...this.plushieAssignedAlerts[plushieId]];
    };
  }

  @Mutation
  updatePlushieAlertsAssignments({
    plushieId,
    alerts,
  }: {
    plushieId: string;
    alerts: string[];
  }): void {
    Vue.set(this.plushieAssignedAlerts, plushieId, alerts);
  }

  @Action({ rawError: true })
  async loadPlushieAlertsByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<string[]> {
    if (useCache && this.plushieAssignedAlerts[plushieId] != null) {
      return this.plushieAssignedAlerts[plushieId];
    }

    const relations = await DIContainer.PlushieAlertAssignmentRepository.getByPlushieId(
      plushieId
    );

    const alerts: string[] = [];

    relations.forEach((relation) => {
      alerts.push(relation.alert);
    });

    this.updatePlushieAlertsAssignments({ plushieId, alerts });

    return alerts;
  }

  @Action({ rawError: true })
  async loadPlushieAlertsByPlushieIds({
    plushieIds,
    useCache = true,
  }: {
    plushieIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string[]>> {
    const missing: string[] = [];
    const result: Dictionary<string[]> = {};

    plushieIds.forEach((id) => {
      if (useCache && this.plushieAssignedAlerts[id]) {
        result[id] = this.plushieAssignedAlerts[id];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const relations = await DIContainer.PlushieAlertAssignmentRepository.getByPlushieIds(
      missing
    );

    const alerts: Dictionary<string[]> = {};

    missing.forEach((plushieId) => {
      alerts[plushieId] = [];
    });

    relations.forEach((relation) => {
      alerts[relation.plushie].push(relation.alert);
    });

    Object.keys(alerts).forEach((plushieId) => {
      this.updatePlushieAlertsAssignments({
        plushieId,
        alerts: alerts[plushieId],
      });

      result[plushieId] = alerts[plushieId];
    });

    return result;
  }

  // ################################### TAG ALERT ASSIGN RULES #########################################
  get getPlushieTagAlertAssignRuleById(): (
    id: string
  ) => TagAlertAssignRule | undefined {
    return (id: string) => this.plushieTagAlertAssignRule[id];
  }

  get getPlushieTagAlertAssignRulesByAlertId(): (
    alertId: string
  ) => TagAlertAssignRule[] {
    return (alertId: string) => {
      const rulesIds = this.plushieAlertAssignRules[alertId];

      if (!rulesIds) {
        return [];
      }

      const result: TagAlertAssignRule[] = [];

      rulesIds.forEach((id) => {
        const rule = this.plushieTagAlertAssignRule[id];
        if (!rule) {
          return;
        }

        result.push(rule);
      });

      result.sort((a, b) =>
        a.tagName.toLowerCase() > b.tagName.toLowerCase() ? 1 : -1
      );

      return result;
    };
  }

  @Mutation
  updatePlushieTagAlertAssignRule(payload: TagAlertAssignRule): void {
    Vue.set(this.plushieTagAlertAssignRule, payload.id, payload);

    if (this.plushieAlertAssignRules[payload.alert] === undefined) {
      return;
    }

    if (this.plushieAlertAssignRules[payload.alert].includes(payload.id)) {
      return;
    }

    this.plushieAlertAssignRules[payload.alert].push(payload.id);
  }

  @Mutation
  updatePlushieTagAlertAssignRules({
    alertId,
    rules,
  }: {
    alertId: string;
    rules: TagAlertAssignRule[];
  }): void {
    rules.forEach((rule) => {
      if (rule.alert !== alertId) {
        throw new Error("All rules should belong to the specified alert!");
      }
    });

    const rulesIds: string[] = [];

    rules.forEach((rule) => {
      rulesIds.push(rule.id);
      Vue.set(this.plushieTagAlertAssignRule, rule.id, rule);
    });

    Vue.set(this.plushieAlertAssignRules, alertId, rulesIds);
  }

  @Mutation
  removePlushieTagAlertAssignRule(id: string): void {
    const rule = this.plushieTagAlertAssignRule[id];

    if (!rule) {
      return;
    }

    delete this.plushieTagAlertAssignRule[id];

    const rulesList = this.plushieAlertAssignRules[rule.alert];
    if (!rulesList) {
      return;
    }

    const index = rulesList.indexOf(rule.id);
    if (index === -1) {
      return;
    }

    rulesList.splice(index, 1);
  }

  @Action({ rawError: true })
  async deleteTagAlertAssignRuleById(id: string): Promise<void> {
    await DIContainer.PlushieTagAlertAssignRuleRepository.deleteById(id);

    this.removePlushieTagAlertAssignRule(id);
  }

  @Action({ rawError: true })
  async loadPlushieTagAlertAssignRulesByAlertId({
    alertId,
    useCache = true,
  }: {
    alertId: string;
    useCache?: boolean;
  }): Promise<TagAlertAssignRule[]> {
    let rules: TagAlertAssignRule[] = [];

    if (useCache && this.plushieAlertAssignRules[alertId]) {
      const rulesIds = this.plushieAlertAssignRules[alertId];

      rulesIds.forEach((id) => {
        rules.push(this.plushieTagAlertAssignRule[id]);
      });

      return rules;
    }

    rules = await DIContainer.PlushieTagAlertAssignRuleRepository.getByAlertId(
      alertId
    );

    this.updatePlushieTagAlertAssignRules({ alertId, rules });

    return rules;
  }

  @Action({ rawError: true })
  async loadPlushieTagAlertAssignRuleById(
    id: string
  ): Promise<TagAlertAssignRule | undefined> {
    if (this.plushieTagAlertAssignRule[id] !== undefined) {
      return this.plushieTagAlertAssignRule[id];
    }

    const rule = await DIContainer.PlushieTagAlertAssignRuleRepository.getById(
      id
    );

    if (rule) {
      this.updatePlushieTagAlertAssignRule(rule);
    }

    return rule;
  }

  @Action({ rawError: true })
  async savePlushieTagAlertAssignRule(
    rule: TagAlertAssignRule
  ): Promise<TagAlertAssignRule> {
    const item = await DIContainer.PlushieTagAlertAssignRuleRepository.save(
      rule
    );

    this.updatePlushieTagAlertAssignRule(item);

    return item;
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushieAssignedAlerts[plushieId]) {
      return;
    }

    const alerts = await this.loadPlushieAlertsByPlushieId({
      plushieId,
      useCache: false,
    });

    await this.loadPlushieAlertsByIds(alerts);
  }

  @Action({ rawError: true })
  async onPreviewCountUpdated(plushieId: string): Promise<void> {
    if (!this.plushieAssignedAlerts[plushieId]) {
      return;
    }

    const alerts = await this.loadPlushieAlertsByPlushieId({
      plushieId,
      useCache: false,
    });

    await this.loadPlushieAlertsByIds(alerts);
  }

  @Action({ rawError: true })
  async onPlushiePriorityChanged(plushieId: string): Promise<void> {
    if (!this.plushieAssignedAlerts[plushieId]) {
      return;
    }

    const alerts = await this.loadPlushieAlertsByPlushieId({
      plushieId,
      useCache: false,
    });

    await this.loadPlushieAlertsByIds(alerts);
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
