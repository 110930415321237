import { render, staticRenderFns } from "./ProductionProgressCard.vue?vue&type=template&id=4b5becbe&scoped=true&"
import script from "./ProductionProgressCard.vue?vue&type=script&lang=ts&"
export * from "./ProductionProgressCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductionProgressCard.vue?vue&type=style&index=0&id=4b5becbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b5becbe",
  null
  
)

export default component.exports