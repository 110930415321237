import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import PreviewCount from "./preview-count.model";
import PreviewCountUpdate from "./preview-count-update.interface";

export default class PreviewCountRepository extends BasicRepository<PreviewCount> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PreviewCount>,
    responseParser: ApiCollectionResponseParserService<PreviewCount>
  ) {
    super("customer_preview_counts", api, objectBuilder, responseParser);
  }

  public async updatePreviewCount(
    item: PreviewCountUpdate
  ): Promise<PreviewCount> {
    const data: Dictionary<string | number> = {};

    data.maxValue = item.maxValue;

    const response = await this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }
}
