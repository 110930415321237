import { VueAuth } from "@websanova/vue-auth";

import AuthenticatedUser from "./authenticated-user.model";

export default class AuthenticatedUserProvider {
  constructor(private auth: VueAuth) {}

  public getUser(): AuthenticatedUser | undefined {
    const user = this.auth.user();

    if (typeof user !== "undefined" && !(user instanceof AuthenticatedUser)) {
      throw new Error("User object is of unsupported type");
    }

    return user;
  }
}
