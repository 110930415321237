import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import QuestionTemplateStatusRelation from "./question-template-status-relation.model";

export default class QuestionTemplateStatusRelationFactory
  implements ObjectBuilderInterface<QuestionTemplateStatusRelation> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): QuestionTemplateStatusRelation {
    const value = new QuestionTemplateStatusRelation(
      data.id,
      data.template,
      data.plushieStatusId.toString()
    );

    value.isNew = false;

    return value;
  }
}
