


















































import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";

import Product from "../../../plushie/product.model";
import ProductionTimeNorm from "../../production-time-norm.model";

@Component({})
export default class ProductionTimeNormEditItem extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop({ required: true })
  public readonly id!: string;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: [] })
  public readonly existingProducts!: string[];

  @Prop({ default: true })
  public readonly isNew!: boolean;

  @Prop({ required: false })
  public readonly value?: ProductionTimeNorm;

  public days?: number;
  public product?: string;

  private fPlushieDataStore: PlushieStore;

  get productOptions(): Product[] {
    const allProducts = this.fPlushieDataStore.activeProductsList;

    const existingProducts = this.existingProducts.filter(
      (item) => item !== this.product
    );

    return allProducts.filter((item) => !existingProducts.includes(item.id));
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public deleteItem(): void {
    if (this.value && !confirm("Are you sure?")) {
      return;
    }

    this.$emit("delete");
  }

  public getTimeNormProductName(productId?: string): string {
    if (!productId) {
      return "";
    }

    const product = this.fPlushieDataStore.getProductById(productId);

    return product ? product.name : "";
  }

  public onChange(): void {
    if (!this.product || !this.days) {
      this.$emit("input", undefined);
      return;
    }

    const value = new ProductionTimeNorm(
      this.id,
      this.factoryId,
      this.product,
      this.days
    );

    this.$emit("input", value);
  }

  protected data(): Record<string, unknown> {
    return {
      days: this.days,
      product: this.product,
    };
  }

  @Watch("value", { immediate: true })
  private _onValueChange() {
    if (!this.value) {
      return;
    }

    this.days = this.value.days;
    this.product = this.value.product;
  }
}
