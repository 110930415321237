





































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";

import BulkShipmentStore from "../../store/index";
import BulkShipmentItem from "../../bulk-shipment-item.model";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class BulkShipmentViewItemsList extends Vue {
  @Prop({ required: true })
  public readonly bulkShipmentId!: string;

  private fIsLoading = false;

  private fBulkShipmentStore: BulkShipmentStore;
  private fPlushieStore: PlushieStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get bulkShipmentItems(): BulkShipmentItem[] {
    if (!this.bulkShipmentId) {
      return [];
    }

    return this.fBulkShipmentStore.getBulkShipmentItemsByShipmentId(
      this.bulkShipmentId
    );
  }

  constructor() {
    super();

    this.fBulkShipmentStore = getModule(BulkShipmentStore, dataStore);
    this.fPlushieStore = getModule(PlushieStore, dataStore);
  }

  public onRowClick(plushieId: string): void {
    const href = this.getPlushieUrl(plushieId);

    void this.$router.push(href);
  }

  public getPlushieStoreItemId(plushieId: string): string {
    const plushie = this.fPlushieStore.getPlushieById(plushieId);

    return plushie ? plushie.storeItemId : "";
  }

  public getPlushieUrl(plushieId: string): string {
    const route = this.$router.resolve({
      name: "PlushieViewPage",
      query: {
        id: plushieId,
      },
    });

    return route.href;
  }

  private async getBulkShipmentItemsData(bulkShipmentId: string) {
    const bulkShipmentItems = await this.fBulkShipmentStore.loadBulkShipmentItemsByShipmentId(
      { shipmentId: bulkShipmentId }
    );

    const plushieIds = bulkShipmentItems.map((item) => item.plushie);

    await this.fPlushieStore.loadPlushiesByIds(plushieIds);
  }

  @Watch("bulkShipmentId", { immediate: true })
  private async _onBulkShipmentIdChange() {
    if (!this.bulkShipmentId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getBulkShipmentItemsData(this.bulkShipmentId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
