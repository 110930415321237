






































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";

import UserAccountEditForm from "./UserAccountEditForm.vue";
import UserEditForm from "./UserEditForm.vue";
import UserPermissionsEditForm from "./UserPermissionsEditForm.vue";

import AccountStore from "../store";
import UserInfo from "../user-info.model";

@Component({
  metaInfo() {
    const component = this as UserEditPage;

    return {
      title:
        component.userInfo != null
          ? component.userInfo.firstName +
            " " +
            component.userInfo.lastName +
            "'s Profile"
          : "User Profile",
    };
  },
  components: {
    LoadingSpinner,
    UserAccountEditForm,
    UserEditForm,
    UserPermissionsEditForm,
  },
})
export default class UserEditPage extends Vue {
  @Prop()
  public readonly userId!: string;

  private fAccountDataStore: AccountStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get userInfo(): UserInfo | undefined {
    return this.fAccountDataStore.getUserInfoById(this.userId);
  }

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  @Watch("userId", { immediate: true })
  private async _onUserIdChange() {
    if (this.userId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fAccountDataStore.loadUserInfoById({ id: this.userId });

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
