import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Plushie from "./plushie.model";

export default class PlushieFactory implements ObjectBuilderInterface<Plushie> {
  public buildFromJSON(data: { [key: string]: any }): Plushie {
    const value = new Plushie(
      data.id,
      data.product.toString(),
      data.status.toString(),
      data.store.toString(),
      data.description,
      +data.quantity,
      data.storeItemId
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    (value as any).fName = data.name;

    value.breed = data.breed;

    value.isNew = false;

    return value;
  }
}
