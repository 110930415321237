import { AxiosResponse } from "axios";

import ApiService from "@/modules/api/api.service";

import QaDecision from "./qa-decision.model";
import BasicRepository from "../api/basic.repository";
import ObjectBuilderInterface from "../api/object-builder.interface";
import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import { FieldOrderDesc } from "../api/field-order-options";

export default class QaDecisionRepository extends BasicRepository<QaDecision> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<QaDecision>,
    responseParser: ApiCollectionResponseParserService<QaDecision>
  ) {
    super("qa_decisions", api, objectBuilder, responseParser);
  }

  public async approvePlushie(plushieId: string): Promise<AxiosResponse> {
    return this.api.post("approvements", { plushieId: plushieId });
  }

  public async rejectPlushie(
    plushieId: string,
    rejectionReasonIds: string[],
    comment: string
  ): Promise<AxiosResponse> {
    return this.api.post("rejections", {
      plushieId: plushieId,
      rejectionReasonIds: rejectionReasonIds,
      comment: comment,
    });
  }

  public async getPlushieLastRejection(
    plushieId: string
  ): Promise<QaDecision | undefined> {
    const collection = await this.fetchList({ plushieId: plushieId }, 1, 1, [
      ["createdAt", FieldOrderDesc],
    ]);

    const item = collection.getItems().shift();

    if (!item) {
      return;
    }

    if (item.isApprove) {
      return;
    }

    return item;
  }
}
