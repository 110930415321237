
















import { Component, Prop, Vue } from "vue-property-decorator";

import { Dictionary } from "@/lib/Dictionary.type";

import OrganizationEditForm from "./OrganizationEditForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Add Organization",
    };
  },
  components: {
    OrganizationEditForm,
  },
})
export default class OrganizationAddPage extends Vue {
  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  public onCancel(): void {
    void this.$router.push({
      name: "OrganizationsListPage",
      query: this.listQuery,
    });
  }

  public onSuccess(): void {
    void this.$router.push({
      name: "OrganizationsListPage",
      query: this.listQuery,
    });
  }
}
