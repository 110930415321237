export default class StuckInfo {
  constructor(private fPlushies: string[], private fLargestDelay: number) {
    [fPlushies, fLargestDelay].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get plushies(): string[] {
    return this.fPlushies;
  }

  get largestDelay(): number {
    return this.fLargestDelay;
  }
}
