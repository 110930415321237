import { MutableValue } from "@/modules/api/mutable-value.interface";

import { BulkShipmentStatusValue } from "./bulk-shipment-status.value";

export default class BulkShipment implements MutableValue {
  public isNew = true;
  public trackingNumber?: string;

  private fCreatedAt?: Date;
  private fAirwayBill?: string;
  private fItemsQty?: number;

  constructor(
    private fId: string,
    private fFactory: string,
    private fStatus: BulkShipmentStatusValue
  ) {
    [fId, fFactory].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get factory(): string {
    return this.fFactory;
  }

  get status(): BulkShipmentStatusValue {
    return this.fStatus;
  }

  get itemsQty(): number | undefined {
    return this.fItemsQty;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get airwayBill(): string | undefined {
    return this.fAirwayBill;
  }
}
