import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import ApiService from "../api/api.service";
import BasicRepository from "../api/basic.repository";
import ObjectBuilderInterface from "../api/object-builder.interface";
import { DirectShipmentIntentionRuleUpdateData } from "./direct-shipment-intention-rule-update-data.interface";
import DirectShipmentIntentionRule from "./direct-shipment-intention-rule.model";

export default class DirectShipmentIntentionRuleRepository extends BasicRepository<DirectShipmentIntentionRule> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<DirectShipmentIntentionRule>,
    responseParser: ApiCollectionResponseParserService<DirectShipmentIntentionRule>
  ) {
    super(
      "direct_shipment_intention_rules",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async loadById(
    id: string
  ): Promise<DirectShipmentIntentionRule | undefined> {
    const result = await this.getList(1, 1, { id });

    return result.getByIndex(0);
  }

  public async updateDirectShipmentIntentionRule(
    id: string,
    directShipmentIntentionRuleUpdateData: DirectShipmentIntentionRuleUpdateData
  ): Promise<DirectShipmentIntentionRule> {
    const response = await this.api.put(
      this.resourceName + "/" + id,
      directShipmentIntentionRuleUpdateData
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(
    directShipmentIntentionRule: DirectShipmentIntentionRule
  ): Record<string, string | number> {
    const data: Record<string, string | number> = {
      id: directShipmentIntentionRule.id,
      name: directShipmentIntentionRule.name,
      factoryId: directShipmentIntentionRule.factory,
      forOrderType: directShipmentIntentionRule.forOrderType,
    };

    if (directShipmentIntentionRule.upgrade) {
      data.upgradeId = directShipmentIntentionRule.upgrade;
    }

    if (directShipmentIntentionRule.product) {
      data.productId = Number.parseInt(directShipmentIntentionRule.product);
    }

    return data;
  }
}
