import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import BodyPart from "./body-part.model";
import { FieldOrderAsc } from "../api/field-order-options";

export default class BodyPartRepository extends BasicRepository<BodyPart> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BodyPart>,
    responseParser: ApiCollectionResponseParserService<BodyPart>
  ) {
    super("body_parts", api, objectBuilder, responseParser);
  }

  public async getByProduct(product: string): Promise<BodyPart[]> {
    const collection = await this.fetchList(
      {
        product: product,
      },
      1,
      999,
      [["adminSn", FieldOrderAsc]]
    );

    return collection.getItems();
  }

  protected prepareData(item: BodyPart): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    return data;
  }
}
