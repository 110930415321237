



































import { Component, Vue } from "vue-property-decorator";
import {
  ColumnFilterDropdownOption,
  RowClickEventData,
  TableOptions,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import rootStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import EmailsManagementStore from "../store";
import EmailTemplate from "../email-template.model";
import Sender from "../sender.model";

@Component({
  metaInfo: {
    title: "Email Templates List",
  },
})
export default class EmailTemplatesListPage extends Vue {
  private fEmailsManagementStore: EmailsManagementStore;
  private fPlushieStore: PlushieStore;

  private fTableOptions: TableOptions;

  get senders(): Sender[] {
    return this.fEmailsManagementStore.sendersList;
  }

  get stores(): DirectoryValue[] {
    return this.fPlushieStore.storesList;
  }

  get tableData(): EmailTemplate[] {
    return this.fEmailsManagementStore.emailTemplates;
  }

  get tableColumns(): string[] {
    return ["code", "store", "subject", "sender", "actions"];
  }

  get tableOptions(): TableOptions {
    return this.fTableOptions;
  }

  constructor() {
    super();

    this.fEmailsManagementStore = getModule(EmailsManagementStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);

    this.fTableOptions = {
      columnsDisplay: {
        actions: "min_desktop",
        subject: "min_desktop",
        sender: "min_tabletL",
      },
      columnsClasses: {
        code: "_code-column",
        subject: "_subject-column",
      },
      filterable: ["code", "store", "subject", "sender"],
      filterByColumn: true,
      listColumns: {},
      perPage: 20,
      perPageValues: [10, 20, 30, 50, 100],
    };
  }

  public onRowClick(data: RowClickEventData<EmailTemplate>): void {
    void this.$router.push({
      name: "EmailTemplateEditPage",
      query: {
        id: data.row.id,
      },
    });
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    await Promise.all([
      this.fPlushieStore.loadStores(),
      this.fEmailsManagementStore.loadEmailTemplates(),
      this.fEmailsManagementStore.loadSenders(),
    ]);

    this.updateListColumns();
  }

  private updateListColumns(): void {
    const storeOptions: ColumnFilterDropdownOption[] = [];
    const senderOptions: ColumnFilterDropdownOption[] = [];

    this.stores.forEach((item) => {
      storeOptions.push({ id: item.id, text: item.name });
    });
    this.senders.forEach((item) => {
      senderOptions.push({ id: item.id, text: item.email });
    });

    if (this.fTableOptions.listColumns) {
      Vue.set(this.fTableOptions.listColumns, "store", storeOptions);
      Vue.set(this.fTableOptions.listColumns, "sender", senderOptions);
    }
  }
}
