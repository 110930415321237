import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class OrderInformation implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fCustomer: string,
    private fOrderId: string,
    private fOrderDisplayId: string,
    private fTurnaroundTime: number,
    private fEstimatedShipmentDate: Date
  ) {
    [
      fId,
      fCustomer,
      fOrderId,
      fOrderDisplayId,
      fTurnaroundTime,
      fEstimatedShipmentDate,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get customer() {
    return this.fCustomer;
  }

  get estimatedShipmentDate() {
    return this.fEstimatedShipmentDate;
  }

  get orderDisplayId() {
    return this.fOrderDisplayId;
  }

  get orderId() {
    return this.fOrderId;
  }

  get turnaroundTime() {
    return this.fTurnaroundTime;
  }

  get updatedAt() {
    return this.fUpdatedAt;
  }
}
