export default class ActiveUser {
  private fExpirationDate = 0;

  get userId(): string {
    return this.fUserId;
  }

  constructor(private fUserId: string) {
    this.refreshExpirationDate();
  }

  public isExpired(): boolean {
    return this.fExpirationDate <= Date.now();
  }

  public refreshExpirationDate(): void {
    this.fExpirationDate = Date.now() + 30 * 1000;
  }
}
