import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import InvoiceItem from "./invoice-item.model";

export default class InvoiceItemFactory
  implements ObjectBuilderInterface<InvoiceItem> {
  public buildFromJSON(data: { [key: string]: any }): InvoiceItem {
    const value = new InvoiceItem(
      data.id,
      data.invoiceId,
      data.plushieId,
      +data.qty,
      new Date(data.plushieProductionStartDate),
      new Date(data.plushieProductionEndDate),
      +data.price,
      +data.penaltyPercent,
      data.applyPenalty == true,
      +data.extraPaymentAmount,
      +data.totalPrice,
      +data.plushieProductionDays,
      data.plushieProductionTime,
      data.isDirectlyShipped,
      data.nonPenalizableAmount
    );

    (value as any).fPlushieName = data.plushieName;
    (value as any).fPlushieNameForFactory = data.plushieNameForFactory;

    value.isNew = false;

    return value;
  }
}
