import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import ProductionTimeNormHistoryItem from "./production-time-norm-history-item.model";
import { ResourceCollection } from "../api/resource.collection";
import { FieldOrderDesc } from "../api/field-order-options";

export default class ProductionTimeNormHistoryItemRepository extends BasicRepository<ProductionTimeNormHistoryItem> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ProductionTimeNormHistoryItem>,
    responseParser: ApiCollectionResponseParserService<ProductionTimeNormHistoryItem>
  ) {
    super("production_time_norm_histories", api, objectBuilder, responseParser);
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<ResourceCollection<ProductionTimeNormHistoryItem>> {
    return this.fetchList({ factory: factoryId }, 1, 9999, [
      ["createdAt", FieldOrderDesc],
    ]);
  }
}
