import { AxiosResponse } from "axios";

import ApiService from "@/modules/api/api.service";

export default class PlushieExportRepository {
  constructor(protected resourceName: string, protected api: ApiService) {}

  public async getPdfByPlushieId(plushieId: string): Promise<string> {
    const response = await this.api.get(
      `${this.resourceName}/${plushieId}/pdf`
    );

    return (response.data as { downloadUrl: string }).downloadUrl;
  }

  public async geXlsByPlushieId(plushieId: string): Promise<string> {
    const response = await this.api.get(
      `${this.resourceName}/${plushieId}/xls`
    );

    return (response.data as { downloadUrl: string }).downloadUrl;
  }

  public async getHtmlByPlushieId(
    plushieId: string
  ): Promise<AxiosResponse<string>> {
    return this.api.get(`${this.resourceName}/${plushieId}/html`);
  }
}
