import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PlushieSummary implements MutableValue {
  public isNew = true;

  private fFactory?: string;
  private fDueDate?: Date;
  private fMainArtworkStorageItemId?: string;
  private fReviewer?: string;
  private fResponsibleUser?: string;
  private fNotes?: string;

  public constructor(
    private fId: string,
    private fProduct: string,
    private fQuantity: number,
    private fStoreItemId: string,
    private fStatus: string,
    private fOrderDate: Date,
    private fOrderNumber: string,
    private fStoreId: string,
    private fDelay: number,
    private fUpgrades: string[],
    private fPriority: number
  ) {
    [
      fId,
      fProduct,
      fQuantity,
      fStoreItemId,
      fStatus,
      fOrderDate,
      fOrderNumber,
      fStoreId,
      fDelay,
      fUpgrades,
      fPriority,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get product() {
    return this.fProduct;
  }

  get quantity() {
    return this.fQuantity;
  }

  get storeItemId() {
    return this.fStoreItemId;
  }

  get status() {
    return this.fStatus;
  }

  get orderDate() {
    return this.fOrderDate;
  }

  get orderNumber() {
    return this.fOrderNumber;
  }

  get storeId() {
    return this.fStoreId;
  }

  get factory() {
    return this.fFactory;
  }

  get dueDate() {
    return this.fDueDate;
  }

  get mainArtworkStorageItemId() {
    return this.fMainArtworkStorageItemId;
  }

  get delay() {
    return this.fDelay;
  }

  get upgrades() {
    return this.fUpgrades;
  }

  get priority() {
    return this.fPriority;
  }

  get reviewer(): string | undefined {
    return this.fReviewer;
  }

  get responsibleUser(): string | undefined {
    return this.fResponsibleUser;
  }

  get notes(): string | undefined {
    return this.fNotes;
  }
}
