





















import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import PlushieStore from "@/modules/plushie/store";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

interface Item {
  id: string;
  sku: string;
}

@Component({})
export default class CreateBulkShipmentFormSameOrdersPlushies extends Vue {
  @Prop({ required: true })
  public readonly plushieIds!: string[];

  @Prop({ required: true })
  public readonly factoryId!: string;

  private fPlushieDataStore: PlushieStore;
  private fFactoryDataStore: FactoryStore;

  get sameOrdersPlushies(): Item[] {
    const orderIds: string[] = [];

    this.plushieIds.forEach((plushieId) => {
      const orderInformation = this.fPlushieDataStore.getOrderInformationByPlushieId(
        plushieId
      );

      if (!orderInformation) {
        return;
      }

      if (!orderIds.includes(orderInformation.orderId)) {
        orderIds.push(orderInformation.orderId);
      }
    });

    const sameOrders = this.fPlushieDataStore.getOrderInformationsByOrderIds(
      orderIds
    );

    const plushieIds = sameOrders
      .filter((order) => !this.plushieIds.includes(order.id))
      .map((order) => order.id);

    const plushies = this.fPlushieDataStore.getPlushiesByIds(plushieIds);

    const sameOrderPlushies: Item[] = [];

    plushies.forEach((plushie) => {
      const readyForShipmentStatuses = [
        PlushieStatusValue.READY_FOR_SHIPMENT,
        PlushieStatusValue.READY_FOR_BULK_SHIPMENT,
      ];

      if (!readyForShipmentStatuses.includes(plushie.status)) {
        return;
      }

      const factoryAssignment = this.fFactoryDataStore.getFactoryAssignmentByPlushieId(
        plushie.id
      );

      if (!factoryAssignment || factoryAssignment.factory !== this.factoryId) {
        return;
      }

      sameOrderPlushies.push({ id: plushie.id, sku: plushie.storeItemId });
    });

    return sameOrderPlushies;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }
}
