import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import AlertAssignment from "./alert-assignment.model";

export default class PlushieAlertAssignmentFactory
  implements ObjectBuilderInterface<AlertAssignment> {
  public buildFromJSON(data: { [key: string]: any }): AlertAssignment {
    const value = new AlertAssignment(data.id, data.plushieId, data.alert);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
