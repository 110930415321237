










import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { SlideData } from "blueimp-gallery/js/blueimp-gallery.min.js";

import ImagesGalleryFullscreenModalMixin from "@/lib/mixins/ImagesGalleryFullscreenModal";

import ImagesGallery from "./ImagesGallery.vue";

@Component({
  components: {
    ImagesGallery,
  },
})
export default class ImagesGalleryFullscreenModal extends mixins(
  ImagesGalleryFullscreenModalMixin
) {
  @Prop({ required: true })
  public readonly slides!: SlideData[];

  protected mounted(): void {
    const imagesGallery = this.getImagesGallery();

    if (!imagesGallery) {
      return;
    }

    imagesGallery.launchGalleria(this.slides, this.index, this.options);
  }

  private getImagesGallery(): ImagesGallery | undefined {
    return this.$refs.imagesGallery as ImagesGallery;
  }
}
