import StuckInfo from "./stuck-info.model";
import WarningInfo from "./warning-info.model";

export default class Alert {
  constructor(
    private fStuckInfo: StuckInfo,
    private fWarningInfo: WarningInfo
  ) {
    [fStuckInfo, fWarningInfo].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get stuckInfo(): StuckInfo {
    return this.fStuckInfo;
  }

  get warningInfo(): WarningInfo {
    return this.fWarningInfo;
  }
}
