import ApiService from "@/modules/api/api.service";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { Dictionary } from "@/lib/Dictionary.type";

import { ApiFilterValue } from "../api/api-filter-value.type";
import ReviewerReport from "./reviewer-report.model";
import { ReportTimePeriod } from "./report-time-period";

export default class ReviewerReportRepository {
  private readonly fResourceName = "reviewer_stats";

  constructor(
    private api: ApiService,
    private objectBuilder: ObjectBuilderInterface<ReviewerReport>
  ) {}

  public async getList(
    timePeriod: ReportTimePeriod,
    reviewerId?: string,
    startDate?: Date,
    endDate?: Date,
    productId?: string
  ): Promise<ReviewerReport[]> {
    const data: Dictionary<ApiFilterValue> = {
      timePeriod,
    };

    if (startDate) {
      data.startDate = startDate.toISOString();
    }

    if (endDate) {
      data.endDate = endDate.toISOString();
    }

    if (productId) {
      data.productId = productId;
    }

    if (reviewerId) {
      data.reviewerId = reviewerId;
    }

    const response = await this.api.get(this.fResourceName, data);

    return (response.data as any[]).map((item) =>
      this.objectBuilder.buildFromJSON(item)
    );
  }
}
