import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import MassActionServiceInterface from "@/modules/plushie/mass-action-service.interface";

import BulkReassignToFactoryDialog from "./components/BulkReassignToFactory/Dialog.vue";

export default class BulkReassignToFactoryService
  implements MassActionServiceInterface {
  public static readonly ID = "bulkReassignToFactory";

  private fFactoryDataStore: FactoryStore;

  constructor() {
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public async execute(plushieIds: string[]): Promise<boolean> {
    if (plushieIds.length < 1) {
      return false;
    }

    await this.fFactoryDataStore.loadFactories();

    const dialogFunction = create<Record<string, unknown>, boolean>(
      BulkReassignToFactoryDialog
    );

    return await dialogFunction({
      plushieIds: plushieIds,
    });
  }
}
