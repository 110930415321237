import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class BodyPart implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fCode: string,
    private fName: string,
    private fProduct: string,
    private fIsEnabled: boolean,
    private fIsRequired: boolean,
    private fMaxValues: number,
    private fAdminSn: number,
    private fCanBeDetailed = false,
    private fParentBodyPart?: string,
    private fDetailingFlagText?: string
  ) {
    [
      fId,
      fCode,
      fName,
      fProduct,
      fIsEnabled,
      fIsRequired,
      fMaxValues,
      fAdminSn,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get code() {
    return this.fCode;
  }

  get name() {
    return this.fName;
  }

  get product() {
    return this.fProduct;
  }

  get isEnabled() {
    return this.fIsEnabled;
  }

  get isRequired() {
    return this.fIsRequired;
  }

  get maxValues() {
    return this.fMaxValues;
  }

  get adminSn() {
    return this.fAdminSn;
  }

  get canBeDetailed(): boolean {
    return this.fCanBeDetailed;
  }

  get parentBodyPart(): string | undefined {
    return this.fParentBodyPart;
  }

  get detailingFlagText(): string | undefined {
    return this.fDetailingFlagText;
  }

  get createdAt() {
    return this.fCreatedAt;
  }
}
