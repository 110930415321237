





















import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Dictionary } from "@/lib/Dictionary.type";

import ProductSettingEditForm from "./ProductSetting/EditForm.vue";

import HolidayPeriodStore from "../store";
import HolidayPeriod from "../holiday-period.model";

@Component({
  metaInfo() {
    const component = this as HolidayPeriodEditPage;

    return {
      title:
        (component.holidayPeriod != null ? component.holidayPeriod.name : "") +
        " Management",
    };
  },
  components: {
    ProductSettingEditForm,
  },
})
export default class HolidayPeriodEditPage extends Vue {
  @Prop()
  public readonly periodId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fHolidayPeriodDataStore: HolidayPeriodStore;

  get holidayPeriod(): HolidayPeriod | undefined {
    return this.fHolidayPeriodDataStore.getHolidayPeriodById(this.periodId);
  }

  get showProductSettingsEditForm(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.HOLIDAY_PERIOD_MANAGE);
  }

  constructor() {
    super();

    this.fHolidayPeriodDataStore = getModule(HolidayPeriodStore, dataStore);
  }

  public onCancel(): void {
    void this.$router.push({
      name: "HolidayPeriodsListPage",
      query: this.listQuery,
    });
  }

  public onSettingsUpdated(): void {
    void this.$router.push({
      name: "HolidayPeriodsListPage",
      query: this.listQuery,
    });
  }

  private async getPeriodData(periodId: string): Promise<void> {
    await this.fHolidayPeriodDataStore.loadHolidayPeriodById(periodId);
  }

  @Watch("periodId", { immediate: true })
  private async _onPeriodIdChange() {
    if (this.periodId == null) {
      return;
    }

    await this.getPeriodData(this.periodId);
  }
}
