import { Route } from "vue-router";

import { Dictionary } from "@/lib/Dictionary.type";

import DirectShipmentIntentionRulesListPage from "./components/DirectShipmentIntentionRule/ListPage.vue";
import DirectShipmentIntentionRulesEditPage from "./components/DirectShipmentIntentionRule/EditPage.vue";
import DirectShipmentIntentionRulesAddPage from "./components/DirectShipmentIntentionRule/AddPage.vue";

export default [
  {
    path: "/direct-shipment-intention-rule/list",
    name: "DirectShipmentIntentionRulesListPage",
    component: DirectShipmentIntentionRulesListPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/direct-shipment-intention-rule/edit",
    name: "DirectShipmentIntentionRuleEditPage",
    component: DirectShipmentIntentionRulesEditPage,
    props: (route: Route): Dictionary<string | null | (string | null)[]> => {
      return {
        id: route.query.id,
      };
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/direct-shipment-intention-rule/add",
    name: "DirectShipmentIntentionRuleAddPage",
    component: DirectShipmentIntentionRulesAddPage,
    meta: {
      auth: true,
    },
  },
];
