













































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import AccountStore from "../store";
import UserInfo from "../user-info.model";
import { DirectoryValue } from "../../api/directory-value.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class UserEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly userInfo?: UserInfo;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public organization?: string;

  private fAccountDataStore: AccountStore;

  get organizationOptions(): DirectoryValue[] {
    return this.fAccountDataStore.organizationsOrderedByName;
  }

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  protected data(): Record<string, unknown> {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      organization: this.organization,
    };
  }

  protected mounted(): void {
    void this.fAccountDataStore.loadOrganizations({});
  }

  protected async performSubmit(): Promise<void> {
    let userInfo = this.generateUserInfoObject();

    userInfo = await this.fAccountDataStore.saveUserInfo(userInfo);
    this.$emit("updated", userInfo);
  }

  private generateUserInfoObject(): UserInfo {
    if (
      !this.organization ||
      !this.email ||
      !this.firstName ||
      !this.lastName
    ) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.userInfo ? this.userInfo.id : this.fIdGenerator.getId();

    const value = new UserInfo(
      id,
      this.organization,
      this.email,
      this.firstName,
      this.lastName
    );

    if (this.userInfo) {
      value.isNew = false;
    }

    return value;
  }

  @Watch("userInfo", { immediate: true })
  private _onUserInfoChange() {
    this.firstName = undefined;
    this.lastName = undefined;
    this.email = undefined;
    this.organization = undefined;

    if (this.userInfo === undefined) {
      return;
    }

    this.firstName = this.userInfo.firstName;
    this.lastName = this.userInfo.lastName;
    this.email = this.userInfo.email;
    this.organization = this.userInfo.organization;
  }
}
