import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { DirectoryValue } from "@/modules/api/directory-value.model";

export default class DirectoryValueFactory
  implements ObjectBuilderInterface<DirectoryValue> {
  public buildFromJSON(data: { [key: string]: any }): DirectoryValue {
    const value = new DirectoryValue(data.id.toString(), data.name);

    value.isNew = false;

    return value;
  }
}
