





























import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { RowClickEventData, TableOptions } from "vue-tables-2-premium";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import rootStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import BasicRepository from "@/modules/api/basic.repository";
import PlushieSummary from "@/modules/plushie/plushie-summary.model";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import PlushieStore from "@/modules/plushie/store";
import truncate from "@/lib/filters/truncate";

import BulkProjectStore from "../store";

interface RowItem {
  plushieId: string;
  sku: string;
  plushieType: string;
  creationDate: Date;
  plushieStatus: string;
  factoryName: string;
  quantity: number;
  daysInCurrentStatus: number;
  notes?: string;
}

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class BulkProjectRelatedPlushies extends Vue {
  @Prop({ required: true })
  public readonly projectId!: string;

  @Inject("PlushieSummaryRepository")
  private fPlushieSummaryRepository!: BasicRepository<PlushieSummary>;

  private fBulkProjectStore: BulkProjectStore;
  private fFactoryStore: FactoryStore;
  private fPlushieStore: PlushieStore;

  private fIsLoading = false;
  private fTableItems: RowItem[] = [];

  get columns(): string[] {
    return [
      "sku",
      "plushieType",
      "creationDate",
      "plushieStatus",
      "factoryName",
      "quantity",
      "daysInCurrentStatus",
      "notes",
      "actions",
    ];
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get tableData(): RowItem[] {
    return this.fTableItems;
  }

  get tableOptions(): TableOptions {
    return {
      filterable: false,
      headings: {
        plushieType: "Type",
        creationDate: "Created At",
        plushieStatus: "Status",
        factoryName: "Factory",
        daysInCurrentStatus: "Days In Status",
        notes: "Notes",
      },
      columnsDisplay: {
        actions: "min_tabletL",
        daysInCurrentStatus: "min_tabletL",
        factoryName: "not_mobile",
        plushieStatus: "min_tabletL",
        quantity: "min_tabletL",
        notes: "min_desktop",
      },
      orderBy: { column: "creationDate", ascending: false },
      templates: {
        creationDate: (h, row: RowItem) => {
          return row.creationDate.toLocaleDateString();
        },
      },
    };
  }

  constructor() {
    super();
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
    this.fFactoryStore = getModule(FactoryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  created(): void {
    void this.loadTableData();
  }

  public onRowClick(data: RowClickEventData<RowItem>): void {
    data.event.preventDefault();

    void this.$router.push({
      name: "PlushieViewPage",
      query: { id: data.row.plushieId },
    });
  }

  public updateTableData(): void {
    void this.loadTableData();
  }

  private getStatusTitleById(id: PlushieStatusValue): string {
    const plushieStatus = this.fPlushieStore.getPlushieStatusById(id);
    return plushieStatus ? plushieStatus.name : "";
  }

  private async loadTableData(): Promise<void> {
    this.fIsLoading = true;
    const tableItems: RowItem[] = [];

    const [relations] = await Promise.all([
      this.fBulkProjectStore.loadBulkProjectPlushieRelationsByBulkProjectId(
        this.projectId
      ),
      this.fFactoryStore.loadFactories(),
      this.fBulkProjectStore.loadBulkProjectPlushieTypes(),
      this.fPlushieStore.loadPlushieStatuses(),
    ]);

    const plushieIds = relations.map((relation) => relation.plushie);

    const plushiesSummaries = await this.fPlushieSummaryRepository.getByIds(
      plushieIds
    );

    plushieIds.forEach((plushieId) => {
      const plushieSummary = plushiesSummaries[plushieId];
      const relation = relations.find(
        (relation) => relation.plushie === plushieId
      );

      if (!relation) {
        return;
      }

      const plushieType = this.fBulkProjectStore.getBulkProjectPlushieTypeById(
        relation.plushieType
      );

      if (!plushieSummary || !plushieType) {
        return;
      }

      const factory = plushieSummary.factory
        ? this.fFactoryStore.getFactoryById(plushieSummary.factory)
        : undefined;

      tableItems.push({
        plushieId: plushieId,
        sku: plushieSummary.storeItemId,
        plushieType: plushieType.name,
        creationDate: plushieSummary.orderDate,
        plushieStatus: this.getStatusTitleById(
          plushieSummary.status as PlushieStatusValue
        ),
        factoryName: factory ? factory.name : "",
        quantity: plushieSummary.quantity,
        daysInCurrentStatus: plushieSummary.delay,
        notes: plushieSummary.notes ? truncate(plushieSummary.notes, 15) : "",
      });
    });

    this.fTableItems = tableItems;
    this.fIsLoading = false;
  }
}
