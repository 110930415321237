


































import { Component, Vue } from "vue-property-decorator";

import BulkAssetsUpload from "./BulkAssetsUpload.vue";
//import Role from "@/modules/account/role.model";

@Component({
  metaInfo() {
    return {
      title: "Upload photos for QA",
    };
  },
  components: {
    BulkAssetsUpload,
  },
})
export default class AssetsUploadPage extends Vue {}
