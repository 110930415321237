import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductionSchedule from "./production-schedule.model";

export default class ProductionScheduleFactory
  implements ObjectBuilderInterface<ProductionSchedule> {
  public buildFromJSON(data: { [key: string]: any }): ProductionSchedule {
    const value = new ProductionSchedule(
      data.id,
      new Date(data.productionStartDate),
      new Date(data.dueDate)
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
