import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ElementPrice from "./element-price.model";

export default class ElementPriceFactory
  implements ObjectBuilderInterface<ElementPrice> {
  public buildFromJSON(data: { [key: string]: any }): ElementPrice {
    const value = new ElementPrice(
      data.id,
      data.factoryId,
      data.type,
      data.entityId,
      +data.qty,
      +data.price
    );

    value.isNew = false;

    return value;
  }
}
