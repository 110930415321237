import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BillingInfoRecord from "./billing-info-record.model";

export default class BillingInfoRecordFactory
  implements ObjectBuilderInterface<BillingInfoRecord> {
  public buildFromJSON(data: { [key: string]: any }): BillingInfoRecord {
    const value = new BillingInfoRecord(
      data.id,
      data.factoryId,
      data.factoryName,
      data.city,
      data.stateRegion,
      data.postalCode,
      data.country
    );

    (value as any).fAddress = data.address;
    (value as any).fAdditionalAddress = data.additionalAddress;

    value.isNew = false;

    return value;
  }
}
