
















import { Component, Vue } from "vue-property-decorator";

import TagSearchForm from "./TagSearch/Form.vue";
import SearchResult from "./TagSearch/SearchResult.vue";

@Component({
  metaInfo() {
    return {
      title: "Tag Search",
    };
  },
  components: {
    TagSearchForm,
    SearchResult,
  },
})
export default class TagSearchPage extends Vue {
  public foundPlushies?: string[];

  constructor() {
    super();
  }

  public onSearchCompleted(foundPlushies: string[]): void {
    this.foundPlushies = foundPlushies;
  }

  protected data(): Record<string, unknown> {
    return {
      foundPlushies: this.foundPlushies,
    };
  }
}
