import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import FactoryInvoice from "../factory-invoice.model";
import ExtraPayment from "../extra-payment.model";
import PenaltyBypass from "../penalty-bypass.model";
import BillingInfoRecord from "../billing-info-record.model";
import InvoiceItem from "../invoice-item.model";
import InvoiceItemUpdate from "../invoice-item-update.interface";
import InvoiceItemElement from "../invoice-item-element.model";
import ExtraPaymentUpdate from "../extra-payment-update.interface";
import Payment from "../payment.model";
import ElementPrice from "../element-price.model";
import ElementPriceHistoryRecord from "../element-price-history-record.model";
import PenaltyRule from "../penalty-rule.model";
import PenaltyRuleHistoryRecord from "../penalty-rule-history-record.model";
import FactoryInvoicesSetting from "../factory-invoices-setting.model";
import { FactoryInvoicesSettingValue } from "../factory-invoices-setting.value";
import FactoryInvoicesSettingUpdateInterface from "../factory-invoices-setting-update.interface";

const name = "FactoryInvoiceStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    billingInfoRecord: {},
    extraPayment: {},
    extraPaymentFault: {},
    extraPaymentAuthor: {},
    factoryInvoice: {},
    factoryInvoiceItem: {},
    invoiceItemElement: {},
    historyAction: {},
    penaltyBypass: {},
    penaltyBypassType: {},
    payment: {},
    elementType: {},
    elementPrice: {},
    elementPriceHistoryRecord: {},
    penaltyRule: {},
    penaltyRuleHistoryRecord: {},
    factoryInvoicesSetting: {},
    invoiceStatus: {},
    factoryBillingInfoRecord: {},
    factoryElementPrices: {},
    factoryElementPricesHistoryRecords: {},
    factoryPenaltyRules: {},
    factoryPenaltyRuleHistoryRecords: {},
    invoiceExtraPayments: {},
    invoiceItems: {},
    itemElements: {},
    invoicePayment: {},
    plushieExtraPayments: {},
    shipmentRelation: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class FactoryInvoiceStore extends VuexModule {
  billingInfoRecord: Dictionary<BillingInfoRecord> = {};
  extraPayment: Dictionary<ExtraPayment> = {};
  extraPaymentFault: Dictionary<DirectoryValue> = {};
  extraPaymentAuthor: Dictionary<DirectoryValue> = {};
  factoryInvoice: Dictionary<FactoryInvoice> = {};
  factoryInvoiceItem: Dictionary<InvoiceItem> = {};
  invoiceItemElement: Dictionary<InvoiceItemElement> = {};
  historyAction: Dictionary<DirectoryValue> = {};
  penaltyBypass: Dictionary<PenaltyBypass> = {};
  penaltyBypassType: Dictionary<DirectoryValue> = {};
  payment: Dictionary<Payment> = {};
  elementType: Dictionary<DirectoryValue> = {};
  elementPrice: Dictionary<ElementPrice> = {};
  elementPriceHistoryRecord: Dictionary<ElementPriceHistoryRecord> = {};
  penaltyRule: Dictionary<PenaltyRule> = {};
  penaltyRuleHistoryRecord: Dictionary<PenaltyRuleHistoryRecord> = {};
  factoryInvoicesSetting: Dictionary<FactoryInvoicesSetting> = {};
  invoiceStatus: Dictionary<DirectoryValue> = {};

  factoryBillingInfoRecord: Dictionary<string> = {};
  factoryElementPrices: Dictionary<string[]> = {};
  factoryElementPricesHistoryRecords: Dictionary<string[]> = {};
  factoryPenaltyRules: Dictionary<string[]> = {};
  factoryPenaltyRuleHistoryRecords: Dictionary<string[]> = {};
  invoiceExtraPayments: Dictionary<string[]> = {};
  invoiceItems: Dictionary<string[]> = {};
  itemElements: Dictionary<string[]> = {};
  invoicePayment: Dictionary<string> = {};
  plushieExtraPayments: Dictionary<string[]> = {};
  shipmentRelation: Dictionary<string> = {};

  // ################################### BILLING INFO RECORDS #########################################
  get getBillingInfoRecordById(): (
    id: string
  ) => BillingInfoRecord | undefined {
    return (id: string) => {
      return this.billingInfoRecord[id];
    };
  }

  get getBillingInfoRecordByFactoryId(): (
    factoryId: string
  ) => BillingInfoRecord | undefined {
    return (factoryId: string) => {
      const recordId = this.factoryBillingInfoRecord[factoryId];

      if (!recordId) {
        return undefined;
      }

      return this.billingInfoRecord[recordId];
    };
  }

  @Mutation
  updateBillingInfoRecord(payload: BillingInfoRecord): void {
    Vue.set(this.billingInfoRecord, payload.id, payload);
    Vue.set(this.factoryBillingInfoRecord, payload.factory, payload.id);
  }

  @Action({ rawError: true })
  async loadBillingInfoRecordByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<BillingInfoRecord | undefined> {
    if (useCache && this.factoryBillingInfoRecord[factoryId]) {
      return this.getBillingInfoRecordByFactoryId(factoryId);
    }

    const item = await DIContainer.BillingInfoRecordRepository.getByFactoryId(
      factoryId,
      useCache
    );

    if (!item) {
      return undefined;
    }

    this.updateBillingInfoRecord(item);

    return item;
  }

  @Action({ rawError: true })
  async saveBillingInfoRecord(
    billingInfoRecord: BillingInfoRecord
  ): Promise<BillingInfoRecord> {
    const item = await DIContainer.BillingInfoRecordRepository.save(
      billingInfoRecord
    );

    this.updateBillingInfoRecord(item);

    return item;
  }

  // ################################### ELEMENT TYPES #################################

  get getElementTypeById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.elementType[id];
  }

  get elementTypes(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.elementType).forEach((id) => {
      list.push(this.elementType[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  @Mutation
  updateElementType(payload: DirectoryValue): void {
    Vue.set(this.elementType, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadElementTypes(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.elementType).length === 0) {
      const collection = await DIContainer.FactoryInvoiceElementTypeRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateElementType(item);
      });
    }

    items = [];

    Object.keys(this.elementType).forEach((key) => {
      items.push(this.elementType[key]);
    });

    return items;
  }

  // ################################### ELEMENT PRICES #########################################
  get getElementPriceById(): (id: string) => ElementPrice | undefined {
    return (id: string) => this.elementPrice[id];
  }

  get getElementPricesByFactoryId(): (factoryId: string) => ElementPrice[] {
    return (factoryId: string) => {
      if (this.factoryElementPrices[factoryId] == null) {
        return [];
      }

      const ids = this.factoryElementPrices[factoryId];

      const result: ElementPrice[] = [];

      ids.forEach((id) => {
        result.push(this.elementPrice[id]);
      });

      return result;
    };
  }

  @Mutation
  updateFactoryElementPrices({
    factoryId,
    elementPrices,
  }: {
    factoryId: string;
    elementPrices: ElementPrice[];
  }): void {
    elementPrices.forEach((elementPrice) => {
      if (elementPrice.factory !== factoryId) {
        throw new Error(
          "All element prices should belong to the specified factory!"
        );
      }
    });

    const elementPricesIds: string[] = [];

    elementPrices.forEach((elementPrice) => {
      elementPricesIds.push(elementPrice.id);
      Vue.set(this.elementPrice, elementPrice.id, elementPrice);
    });

    Vue.set(this.factoryElementPrices, factoryId, elementPricesIds);
  }

  @Action({ rawError: true })
  async loadElementPricesByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<ElementPrice[]> {
    if (useCache && this.factoryElementPrices[factoryId]) {
      const ids = this.factoryElementPrices[factoryId];

      const result: ElementPrice[] = [];

      ids.forEach((id) => {
        result.push(this.elementPrice[id]);
      });

      return result;
    }

    const collection = await DIContainer.ElementPriceRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryElementPrices({
      factoryId,
      elementPrices: items,
    });

    return items;
  }

  @Action({ rawError: true })
  async saveElementPricesForFactory({
    factoryId,
    values,
  }: {
    factoryId: string;
    values: ElementPrice[];
  }): Promise<ElementPrice[]> {
    const items = await DIContainer.ElementPriceRepository.saveItemsForFactory(
      values,
      factoryId
    );

    this.updateFactoryElementPrices({
      factoryId,
      elementPrices: items,
    });

    await this.context.dispatch("onFactoryElementPricesUpdated", factoryId);

    return items;
  }

  // ################################### ELEMENT PRICE HISTORY RECORDS #########################################
  get getElementPriceHistoryRecordById(): (
    id: string
  ) => ElementPriceHistoryRecord | undefined {
    return (id: string) => this.elementPriceHistoryRecord[id];
  }

  get getElementPriceHistoryRecordsByFactoryId(): (
    factoryId: string
  ) => ElementPriceHistoryRecord[] {
    return (factoryId: string) => {
      if (this.factoryElementPricesHistoryRecords[factoryId] == null) {
        return [];
      }

      const ids = this.factoryElementPricesHistoryRecords[factoryId];

      const result: ElementPriceHistoryRecord[] = [];

      ids.forEach((id) => {
        result.push(this.elementPriceHistoryRecord[id]);
      });

      return result;
    };
  }

  @Mutation
  updateFactoryElementPriceHistoryRecords({
    factoryId,
    elementPriceHistoryRecords,
  }: {
    factoryId: string;
    elementPriceHistoryRecords: ElementPriceHistoryRecord[];
  }): void {
    elementPriceHistoryRecords.forEach((historyRecord) => {
      if (historyRecord.factory !== factoryId) {
        throw new Error(
          "All element price history records should belong to the specified factory!"
        );
      }
    });

    const elementPriceHistoryRecordIds: string[] = [];

    elementPriceHistoryRecords.forEach((historyRecord) => {
      elementPriceHistoryRecordIds.push(historyRecord.id);
      Vue.set(this.elementPriceHistoryRecord, historyRecord.id, historyRecord);
    });

    Vue.set(
      this.factoryElementPricesHistoryRecords,
      factoryId,
      elementPriceHistoryRecordIds
    );
  }

  @Action({ rawError: true })
  async loadElementPriceHistoryRecordsByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<ElementPriceHistoryRecord[]> {
    if (useCache && this.factoryElementPricesHistoryRecords[factoryId]) {
      const ids = this.factoryElementPricesHistoryRecords[factoryId];

      const result: ElementPriceHistoryRecord[] = [];

      ids.forEach((id) => {
        result.push(this.elementPriceHistoryRecord[id]);
      });

      return result;
    }

    const collection = await DIContainer.ElementPriceHistoryRecordRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryElementPriceHistoryRecords({
      factoryId,
      elementPriceHistoryRecords: items,
    });

    return items;
  }

  // ################################### EXTRA PAYMENT FAULTS #########################################

  get extraPaymentFaults(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.extraPaymentFault).forEach((id) => {
      list.push(this.extraPaymentFault[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get extraPaymentFaultsDictionary(): Dictionary<DirectoryValue> {
    return { ...this.extraPaymentFault };
  }

  get getExtraPaymentFaultById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.extraPaymentFault[id];
  }

  @Mutation
  updateExtraPaymentFault(payload: DirectoryValue): void {
    Vue.set(this.extraPaymentFault, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadExtraPaymentFaults(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.extraPaymentFault).length === 0) {
      const collection = await DIContainer.ExtraPaymentFaultRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateExtraPaymentFault(item);
      });
    }

    items = [];

    Object.keys(this.extraPaymentFault).forEach((key) => {
      items.push(this.extraPaymentFault[key]);
    });

    return items;
  }

  // ################################### EXTRA PAYMENT AUTHORS #########################################

  get extraPaymentAuthors(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.extraPaymentAuthor).forEach((id) => {
      list.push(this.extraPaymentAuthor[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get extraPaymentAuthorsDictionary(): Dictionary<DirectoryValue> {
    return { ...this.extraPaymentAuthor };
  }

  get getExtraPaymentAuthorById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.extraPaymentAuthor[id];
  }

  @Mutation
  updateExtraPaymentAuthor(payload: DirectoryValue): void {
    Vue.set(this.extraPaymentAuthor, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadExtraPaymentAuthors(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.extraPaymentAuthor).length === 0) {
      const collection = await DIContainer.ExtraPaymentAuthorRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateExtraPaymentAuthor(item);
      });
    }

    items = [];

    Object.keys(this.extraPaymentAuthor).forEach((key) => {
      items.push(this.extraPaymentAuthor[key]);
    });

    return items;
  }

  // ################################### EXTRA PAYMENTS #########################################

  get getExtraPaymentById(): (id: string) => ExtraPayment | undefined {
    return (id: string) => this.extraPayment[id];
  }

  get getExtraPaymentsByPlushieId(): (plushieId: string) => ExtraPayment[] {
    return (plushieId: string) => {
      const extraPaymentIds = this.plushieExtraPayments[plushieId];

      if (!extraPaymentIds) {
        return [];
      }

      const result: ExtraPayment[] = [];

      extraPaymentIds.forEach((id) => {
        if (!this.extraPayment[id]) {
          return;
        }

        result.push(this.extraPayment[id]);
      });

      return result;
    };
  }

  get getExtraPaymentsByInvoiceId(): (invoiceId: string) => ExtraPayment[] {
    return (invoiceId: string) => {
      const extraPaymentIds = this.invoiceExtraPayments[invoiceId];

      if (!extraPaymentIds) {
        return [];
      }

      const result: ExtraPayment[] = [];

      extraPaymentIds.forEach((id) => {
        if (!this.extraPayment[id]) {
          return;
        }

        result.push(this.extraPayment[id]);
      });

      return result;
    };
  }

  @Mutation
  removeExtraPayment(payload: ExtraPayment): void {
    if (payload.plushie) {
      const plushieRelations = this.plushieExtraPayments[payload.plushie];

      let index = -1;

      if (plushieRelations) {
        index = plushieRelations.indexOf(payload.id);
      }

      if (index !== -1) {
        plushieRelations.splice(index, 1);
      }
    }

    if (payload.invoice) {
      let index = -1;

      const invoiceRelations = this.invoiceExtraPayments[payload.invoice];

      if (invoiceRelations) {
        index = invoiceRelations.indexOf(payload.id);
      }

      if (index !== -1) {
        invoiceRelations.splice(index, 1);
      }
    }

    delete this.extraPayment[payload.id];
  }

  @Mutation
  updateExtraPayment(payload: ExtraPayment): void {
    Vue.set(this.extraPayment, payload.id, payload);

    if (
      payload.plushie &&
      this.plushieExtraPayments[payload.plushie] !== undefined &&
      !this.plushieExtraPayments[payload.plushie].includes(payload.id)
    ) {
      this.plushieExtraPayments[payload.plushie].push(payload.id);
    }

    if (
      payload.invoice &&
      this.invoiceExtraPayments[payload.invoice] !== undefined &&
      !this.invoiceExtraPayments[payload.invoice].includes(payload.id)
    ) {
      this.invoiceExtraPayments[payload.invoice].push(payload.id);
    }
  }

  @Mutation
  updatePlushieExtraPayments({
    plushieId,
    extraPayments,
  }: {
    plushieId: string;
    extraPayments: ExtraPayment[];
  }): void {
    extraPayments.forEach((extraPayment) => {
      if (!extraPayment.plushie || extraPayment.plushie !== plushieId) {
        throw new Error(
          "All extra payments should belong to the specified plushie!"
        );
      }
    });

    const plushieExtraPayments: string[] = [];

    extraPayments.forEach((extraPayment) => {
      plushieExtraPayments.push(extraPayment.id);
      Vue.set(this.extraPayment, extraPayment.id, extraPayment);

      if (
        extraPayment.invoice &&
        this.invoiceExtraPayments[extraPayment.invoice] !== undefined &&
        !this.invoiceExtraPayments[extraPayment.invoice].includes(
          extraPayment.id
        )
      ) {
        this.invoiceExtraPayments[extraPayment.invoice].push(extraPayment.id);
      }
    });

    Vue.set(this.plushieExtraPayments, plushieId, plushieExtraPayments);
  }

  @Mutation
  updateInvoiceExtraPayments({
    invoiceId,
    extraPayments,
  }: {
    invoiceId: string;
    extraPayments: ExtraPayment[];
  }): void {
    extraPayments.forEach((extraPayment) => {
      if (!extraPayment.invoice || extraPayment.invoice !== invoiceId) {
        throw new Error(
          "All extra payments should belong to the specified invoice!"
        );
      }
    });

    const invoiceExtraPayments: string[] = [];

    extraPayments.forEach((extraPayment) => {
      invoiceExtraPayments.push(extraPayment.id);
      Vue.set(this.extraPayment, extraPayment.id, extraPayment);

      if (
        extraPayment.plushie &&
        this.plushieExtraPayments[extraPayment.plushie] !== undefined &&
        !this.plushieExtraPayments[extraPayment.plushie].includes(
          extraPayment.id
        )
      ) {
        this.plushieExtraPayments[extraPayment.plushie].push(extraPayment.id);
      }
    });

    Vue.set(this.invoiceExtraPayments, invoiceId, invoiceExtraPayments);
  }

  @Action({ rawError: true })
  async loadExtraPaymentsByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<ExtraPayment[]> {
    let extraPayments: ExtraPayment[] = [];

    if (useCache && this.plushieExtraPayments[plushieId]) {
      const extraPaymentIds = this.plushieExtraPayments[plushieId];

      extraPaymentIds.forEach((id) => {
        extraPayments.push(this.extraPayment[id]);
      });

      return extraPayments;
    }

    const collection = await DIContainer.ExtraPaymentRepository.getByPlushieId(
      plushieId
    );

    extraPayments = collection.getItems();

    this.updatePlushieExtraPayments({ plushieId, extraPayments });

    return extraPayments;
  }

  @Action({ rawError: true })
  async loadExtraPaymentsByInvoiceId({
    invoiceId,
    useCache = true,
  }: {
    invoiceId: string;
    useCache?: boolean;
  }): Promise<ExtraPayment[]> {
    let extraPayments: ExtraPayment[] = [];

    if (useCache && this.invoiceExtraPayments[invoiceId]) {
      const extraPaymentIds = this.invoiceExtraPayments[invoiceId];

      extraPaymentIds.forEach((id) => {
        extraPayments.push(this.extraPayment[id]);
      });

      return extraPayments;
    }

    const collection = await DIContainer.ExtraPaymentRepository.getByInvoiceId(
      invoiceId
    );

    extraPayments = collection.getItems();

    this.updateInvoiceExtraPayments({ invoiceId, extraPayments });

    return extraPayments;
  }

  @Action({ rawError: true })
  async createExtraPayment(extraPayment: ExtraPayment): Promise<ExtraPayment> {
    const item = await DIContainer.ExtraPaymentRepository.save(extraPayment);

    this.updateExtraPayment(item);

    await this.onExtraPaymentUpdated(item);

    return item;
  }

  @Action({ rawError: true })
  async deleteExtraPayment(extraPayment: ExtraPayment): Promise<void> {
    await DIContainer.ExtraPaymentRepository.delete(extraPayment);

    this.removeExtraPayment(extraPayment);

    await this.onExtraPaymentUpdated(extraPayment);

    return;
  }

  @Action({ rawError: true })
  async updateExtraPaymentFaultValue(
    extraPaymentUpdate: ExtraPaymentUpdate
  ): Promise<ExtraPayment> {
    const item = await DIContainer.ExtraPaymentRepository.updateFault(
      extraPaymentUpdate
    );

    this.updateExtraPayment(item);

    await this.onExtraPaymentUpdated(item);

    return item;
  }

  // ################################### FACTORY INVOICES #########################################

  get getFactoryInvoiceById(): (
    invoiceId: string
  ) => FactoryInvoice | undefined {
    return (id: string) => this.factoryInvoice[id];
  }

  get getFactoryInvoicesByIds(): (ids: string[]) => FactoryInvoice[] {
    return (ids: string[]) => {
      const invoices: FactoryInvoice[] = [];

      ids.forEach((id) => {
        if (this.factoryInvoice[id]) {
          invoices.push(this.factoryInvoice[id]);
        }
      });

      return invoices;
    };
  }

  get getFactoryInvoiceByShipmentId(): (
    shipmentId: string
  ) => FactoryInvoice | undefined {
    return (shipmentId: string) => {
      const invoiceId = this.shipmentRelation[shipmentId];

      if (!invoiceId) {
        return undefined;
      }

      return this.factoryInvoice[invoiceId];
    };
  }

  @Mutation
  updateFactoryInvoice(payload: FactoryInvoice): void {
    Vue.set(this.factoryInvoice, payload.id, payload);

    if (!payload.shipment) {
      return;
    }

    Vue.set(this.shipmentRelation, payload.shipment, payload.id);
  }

  @Action({ rawError: true })
  async loaFactoryInvoiceById({
    id,
    useCache = true,
    stopUpdateEvent = false,
  }: {
    id: string;
    useCache?: boolean;
    stopUpdateEvent?: boolean;
  }): Promise<FactoryInvoice | undefined> {
    if (useCache && this.factoryInvoice[id]) {
      return this.factoryInvoice[id];
    }

    const item = await DIContainer.FactoryInvoiceRepository.getById(id);

    if (!item) {
      return undefined;
    }

    this.updateFactoryInvoice(item);

    if (!stopUpdateEvent) {
      void this.context.dispatch("onInvoiceUpdated", item.id);
    }

    return item;
  }

  @Action({ rawError: true })
  async loadFactoryInvoices({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<FactoryInvoice>> {
    const collection = await DIContainer.FactoryInvoiceRepository.getList(
      page,
      limit,
      filter,
      order
    );

    const items = collection.getItems();

    items.forEach((item) => {
      this.updateFactoryInvoice(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadFactoryInvoicesByIds({
    invoiceIds,
    useCache = true,
  }: {
    invoiceIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<FactoryInvoice>> {
    const missing: string[] = [];
    const result: Dictionary<FactoryInvoice> = {};

    invoiceIds.forEach((invoiceId) => {
      if (useCache && this.factoryInvoice[invoiceId]) {
        result[invoiceId] = this.factoryInvoice[invoiceId];

        return;
      }

      missing.push(invoiceId);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.FactoryInvoiceRepository.getByIds(missing);

    for (const key in items) {
      const item = items[key];

      if (!item) {
        continue;
      }

      this.updateFactoryInvoice(item);

      result[item.id] = item;
    }

    return result;
  }

  @Action({ rawError: true })
  async loadFactoryInvoicesByShipmentIds({
    shipmentIds,
    useCache = true,
  }: {
    shipmentIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string>> {
    const missing: string[] = [];
    const result: Dictionary<string> = {};

    shipmentIds.forEach((shipmentId) => {
      if (useCache && this.shipmentRelation[shipmentId]) {
        result[shipmentId] = this.shipmentRelation[shipmentId];
        return;
      }

      missing.push(shipmentId);
    });

    if (!missing.length) {
      return result;
    }

    const collection = await DIContainer.FactoryInvoiceRepository.getByShipmentIds(
      missing
    );

    const items = collection.getItems();

    items.forEach((item) => {
      this.updateFactoryInvoice(item);

      if (!item.shipment) {
        return;
      }

      result[item.shipment] = item.id;
    });

    return result;
  }

  @Action({ rawError: true })
  async publishFactoryInvoice({
    invoice,
    vendorNotes,
  }: {
    invoice: FactoryInvoice;
    vendorNotes?: string;
  }): Promise<FactoryInvoice> {
    const item = await DIContainer.FactoryInvoiceRepository.publishInvoice(
      invoice,
      vendorNotes
    );

    this.updateFactoryInvoice(item);

    return item;
  }

  @Action({ rawError: true })
  async approveFactoryInvoice({
    invoice,
    buyerNotes,
  }: {
    invoice: FactoryInvoice;
    buyerNotes?: string;
  }): Promise<FactoryInvoice> {
    const item = await DIContainer.FactoryInvoiceRepository.approveInvoice(
      invoice,
      buyerNotes
    );

    this.updateFactoryInvoice(item);

    return item;
  }

  // ################################### INVOICE ITEMS #########################################
  get getInvoiceItemById(): (id: string) => InvoiceItem | undefined {
    return (id: string) => this.factoryInvoiceItem[id];
  }

  get getInvoiceItemsByInvoiceId(): (invoiceId: string) => InvoiceItem[] {
    return (invoiceId: string) => {
      if (this.invoiceItems[invoiceId] == null) {
        return [];
      }

      const ids = this.invoiceItems[invoiceId];

      const result: InvoiceItem[] = [];

      ids.forEach((id) => {
        result.push(this.factoryInvoiceItem[id]);
      });

      return result;
    };
  }

  @Mutation
  updateInvoiceItem(payload: InvoiceItem): void {
    Vue.set(this.factoryInvoiceItem, payload.id, payload);

    if (
      this.invoiceItems[payload.invoice] &&
      !this.invoiceItems[payload.invoice].includes(payload.id)
    ) {
      this.invoiceItems[payload.invoice].push(payload.id);
    }
  }

  @Mutation
  updateInvoiceItems({
    invoiceId,
    items,
  }: {
    invoiceId: string;
    items: InvoiceItem[];
  }): void {
    items.forEach((item) => {
      if (item.invoice !== invoiceId) {
        throw new Error("All items should belong to the specified invoice!");
      }
    });

    const itemIds: string[] = [];

    items.forEach((item) => {
      itemIds.push(item.id);
      Vue.set(this.factoryInvoiceItem, item.id, item);
    });

    Vue.set(this.invoiceItems, invoiceId, itemIds);
  }

  @Action({ rawError: true })
  async loadInvoiceItemsByInvoiceId({
    invoiceId,
    useCache = true,
  }: {
    invoiceId: string;
    useCache?: boolean;
  }): Promise<InvoiceItem[]> {
    if (useCache && this.invoiceItems[invoiceId]) {
      const ids = this.invoiceItems[invoiceId];

      const result: InvoiceItem[] = [];

      ids.forEach((id) => {
        result.push(this.factoryInvoiceItem[id]);
      });

      return result;
    }

    const collection = await DIContainer.InvoiceItemRepository.getByInvoiceId(
      invoiceId
    );

    const items = collection.getItems();

    this.updateInvoiceItems({
      invoiceId,
      items,
    });

    return items;
  }

  @Action({ rawError: true })
  async updateFactoryInvoiceItem(
    itemUpdate: InvoiceItemUpdate
  ): Promise<InvoiceItem> {
    const item = await DIContainer.InvoiceItemRepository.updateItem(itemUpdate);

    this.updateInvoiceItem(item);

    await this.onInvoiceItemUpdated(item);

    return item;
  }

  // ################################### INVOICE ITEM ELEMENTS #########################################

  get getInvoiceItemElementById(): (
    id: string
  ) => InvoiceItemElement | undefined {
    return (id: string) => this.invoiceItemElement[id];
  }

  get getInvoiceItemElementsByItemId(): (
    itemId: string
  ) => InvoiceItemElement[] {
    return (itemId: string) => {
      if (!this.itemElements[itemId]) {
        return [];
      }

      const elements: InvoiceItemElement[] = [];

      this.itemElements[itemId].forEach((elementId) => {
        elements.push(this.invoiceItemElement[elementId]);
      });

      return elements;
    };
  }

  @Mutation
  updateInvoiceItemElements({
    itemId,
    elements,
  }: {
    itemId: string;
    elements: InvoiceItemElement[];
  }): void {
    elements.forEach((element) => {
      if (element.invoiceItem !== itemId) {
        throw new Error("All elements should belong to the specified item!");
      }
    });

    const elementIds: string[] = [];

    elements.forEach((element) => {
      elementIds.push(element.id);
      Vue.set(this.invoiceItemElement, element.id, element);
    });

    Vue.set(this.itemElements, itemId, elementIds);
  }

  @Action({ rawError: true })
  async loadElementsByInvoiceItemIds({
    itemIds,
    useCache = true,
  }: {
    itemIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string[] | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<string[] | undefined> = {};

    itemIds.forEach((itemId) => {
      if (useCache && this.itemElements[itemId]) {
        result[itemId] = this.itemElements[itemId];
        return;
      }

      missing.push(itemId);
    });

    if (!missing.length) {
      return result;
    }

    const collection = await DIContainer.InvoiceItemElementRepository.getByItemIds(
      missing
    );

    const elements = collection.getItems();

    const itemElements: Dictionary<InvoiceItemElement[]> = {};

    elements.forEach((element) => {
      if (!itemElements[element.invoiceItem]) {
        itemElements[element.invoiceItem] = [];
      }
      itemElements[element.invoiceItem].push(element);
    });

    for (const itemId in itemElements) {
      const elements = itemElements[itemId];

      if (!elements) {
        continue;
      }

      this.updateInvoiceItemElements({ itemId, elements });
    }

    return Object.assign({}, this.itemElements);
  }

  // ################################### INVOICE STATUSES #########################################
  get getInvoiceStatusById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.invoiceStatus[id];
  }

  get invoiceStatusList(): DirectoryValue[] {
    const list: DirectoryValue[] = [];

    Object.keys(this.invoiceStatus).forEach((id) => {
      list.push(this.invoiceStatus[id]);
    });

    list.sort((a, b) => (a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1));

    return list;
  }

  @Mutation
  updateInvoiceStatus(payload: DirectoryValue): void {
    Vue.set(this.invoiceStatus, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadInvoiceStatuses(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.invoiceStatus).length === 0) {
      const collection = await DIContainer.FactoryInvoiceStatusRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateInvoiceStatus(item);
      });
    }

    items = [];

    Object.keys(this.invoiceStatus).forEach((key) => {
      items.push(this.invoiceStatus[key]);
    });

    return items;
  }

  // ################################### HISTORY ACTIONS #########################################
  get getHistoryActionById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.historyAction[id];
  }

  @Mutation
  updateHistoryAction(payload: DirectoryValue): void {
    Vue.set(this.historyAction, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadHistoryActions(): Promise<DirectoryValue[]> {
    let items: DirectoryValue[];

    if (Object.keys(this.historyAction).length === 0) {
      const collection = await DIContainer.FactoryInvoiceHistoryActionRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateHistoryAction(item);
      });
    }

    items = [];

    Object.keys(this.historyAction).forEach((key) => {
      items.push(this.historyAction[key]);
    });

    return items;
  }

  // ################################### PAYMENTS #########################################

  get getPaymentById(): (id: string) => Payment | undefined {
    return (id: string) => {
      return this.payment[id];
    };
  }

  get getPaymentByInvoiceId(): (invoiceId: string) => Payment | undefined {
    return (invoiceId: string) => {
      const paymentId = this.invoicePayment[invoiceId];

      if (!paymentId) {
        return undefined;
      }

      return this.payment[paymentId];
    };
  }

  @Mutation
  updatePayment(payload: Payment): void {
    Vue.set(this.payment, payload.id, payload);
    Vue.set(this.invoicePayment, payload.invoice, payload.id);
  }

  @Action({ rawError: true })
  async loadPaymentByInvoiceId({
    invoiceId,
    useCache = true,
  }: {
    invoiceId: string;
    useCache?: boolean;
  }): Promise<Payment | undefined> {
    if (useCache && this.invoicePayment[invoiceId]) {
      return this.getPaymentByInvoiceId(invoiceId);
    }

    const item = await DIContainer.PaymentRepository.getByInvoiceId(invoiceId);

    if (!item) {
      return undefined;
    }

    this.updatePayment(item);

    return item;
  }

  @Action({ rawError: true })
  async savePayments(payments: Payment[]): Promise<Payment[]> {
    const items = await DIContainer.PaymentRepository.saveItems(payments);

    items.forEach((item) => {
      this.updatePayment(item);
    });

    await this.context.dispatch("onFactoryInvoicesPaymentsSaved", items);

    return items;
  }

  // ################################### PENALTY BYPASSES #########################################
  get getPenaltyBypassByPlushieId(): (id: string) => PenaltyBypass | undefined {
    return (id: string) => this.penaltyBypass[id];
  }

  @Mutation
  updatePenaltyBypass(payload: PenaltyBypass): void {
    Vue.set(this.penaltyBypass, payload.plushie, payload);
  }

  @Mutation
  removePenaltyBypass(penaltyBypass: PenaltyBypass): void {
    Vue.delete(this.penaltyBypass, penaltyBypass.plushie);
  }

  @Action({ rawError: true })
  async deletePenaltyBypass(penaltyBypass: PenaltyBypass): Promise<void> {
    await DIContainer.PenaltyBypassRepository.deleteById(penaltyBypass.id);

    this.removePenaltyBypass(penaltyBypass);

    return;
  }

  @Action({ rawError: true })
  async loadPenaltyBypassByPlushieId(
    plushieId: string
  ): Promise<PenaltyBypass | undefined> {
    if (this.penaltyBypass[plushieId]) {
      return this.penaltyBypass[plushieId];
    }

    const item = await DIContainer.PenaltyBypassRepository.getByPlushieId(
      plushieId
    );

    if (!item) {
      return undefined;
    }

    this.updatePenaltyBypass(item);

    return item;
  }

  @Action({ rawError: true })
  async savePenaltyBypass(
    penaltyBypass: PenaltyBypass
  ): Promise<PenaltyBypass> {
    const item = await DIContainer.PenaltyBypassRepository.save(penaltyBypass);

    this.updatePenaltyBypass(item);

    return item;
  }

  // ################################### PENALTY BYPASS TYPES #########################################
  get penaltyBypassTypesList(): DirectoryValue[] {
    return Object.values(this.penaltyBypassType);
  }

  @Mutation
  updatePenaltyBypassType(penaltyBypassType: DirectoryValue): void {
    Vue.set(this.penaltyBypassType, penaltyBypassType.id, penaltyBypassType);
  }

  @Action({ rawError: true })
  async loadPenaltyBypassTypes(): Promise<DirectoryValue[]> {
    if (this.penaltyBypassTypesList.length) {
      return this.penaltyBypassTypesList;
    }

    const collection = await DIContainer.PenaltyBypassTypeRepository.getList(
      1,
      100
    );
    const items = collection.getItems();

    items.forEach((item) => {
      this.updatePenaltyBypassType(item);
    });

    return items;
  }

  // ################################### PENALTY RULES #########################################
  get getPenaltyRuleById(): (id: string) => PenaltyRule | undefined {
    return (id: string) => this.penaltyRule[id];
  }

  get getPenaltyRulesByFactoryId(): (factoryId: string) => PenaltyRule[] {
    return (factoryId: string) => {
      if (this.factoryPenaltyRules[factoryId] == null) {
        return [];
      }

      const ids = this.factoryPenaltyRules[factoryId];

      const result: PenaltyRule[] = [];

      ids.forEach((id) => {
        result.push(this.penaltyRule[id]);
      });

      return result;
    };
  }

  @Mutation
  updateFactoryPenaltyRules({
    factoryId,
    penaltyRules,
  }: {
    factoryId: string;
    penaltyRules: PenaltyRule[];
  }): void {
    penaltyRules.forEach((rule) => {
      if (rule.factory !== factoryId) {
        throw new Error(
          "All penalty rules should belong to the specified factory!"
        );
      }
    });

    const penaltyRuleIds: string[] = [];

    penaltyRules.forEach((rule) => {
      penaltyRuleIds.push(rule.id);
      Vue.set(this.penaltyRule, rule.id, rule);
    });

    Vue.set(this.factoryPenaltyRules, factoryId, penaltyRuleIds);
  }

  @Action({ rawError: true })
  async loadPenaltyRulesByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<PenaltyRule[]> {
    if (useCache && this.factoryPenaltyRules[factoryId]) {
      const ids = this.factoryPenaltyRules[factoryId];

      const result: PenaltyRule[] = [];

      ids.forEach((id) => {
        result.push(this.penaltyRule[id]);
      });

      return result;
    }

    const collection = await DIContainer.PenaltyRuleRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryPenaltyRules({
      factoryId,
      penaltyRules: items,
    });

    return items;
  }

  @Action({ rawError: true })
  async savePenaltyRulesForFactory({
    factoryId,
    values,
  }: {
    factoryId: string;
    values: PenaltyRule[];
  }): Promise<PenaltyRule[]> {
    const items = await DIContainer.PenaltyRuleRepository.saveItemsForFactory(
      values,
      factoryId
    );

    this.updateFactoryPenaltyRules({
      factoryId,
      penaltyRules: items,
    });

    await this.context.dispatch("onFactoryPenaltyRulesUpdated", factoryId);

    return items;
  }

  // ################################### SETTINGS #########################################
  get getFactoryInvoicesSettingById(): (
    id: FactoryInvoicesSettingValue
  ) => FactoryInvoicesSetting | undefined {
    return (id: string) => this.factoryInvoicesSetting[id];
  }

  @Mutation
  updateFactoryInvoicesSetting(payload: FactoryInvoicesSetting): void {
    Vue.set(this.factoryInvoicesSetting, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadFactoryInvoicesSettingsByIds(
    ids: FactoryInvoicesSettingValue[]
  ): Promise<Dictionary<FactoryInvoicesSetting | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<FactoryInvoicesSetting | undefined> = {};

    ids.forEach((id) => {
      if (!this.factoryInvoicesSetting[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.factoryInvoicesSetting[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.FactoryInvoicesSettingRepository.getByIds(
      missing
    );

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateFactoryInvoicesSetting(item);
    });

    return { ...result, ...items };
  }

  @Action({ rawError: true })
  async updateFactoryInvoicesSettingValue(
    settingUpdate: FactoryInvoicesSettingUpdateInterface
  ): Promise<FactoryInvoicesSetting> {
    const item = await DIContainer.FactoryInvoicesSettingRepository.updateSettingValue(
      settingUpdate
    );

    this.updateFactoryInvoicesSetting(item);

    return item;
  }

  // ################################### PENALTY RULE HISTORY RECORDS #########################################
  get getPenaltyRuleHistoryRecordById(): (
    id: string
  ) => PenaltyRuleHistoryRecord | undefined {
    return (id: string) => this.penaltyRuleHistoryRecord[id];
  }

  get getPenaltyRuleHistoryRecordsByFactoryId(): (
    factoryId: string
  ) => PenaltyRuleHistoryRecord[] {
    return (factoryId: string) => {
      if (this.factoryPenaltyRuleHistoryRecords[factoryId] == null) {
        return [];
      }

      const ids = this.factoryPenaltyRuleHistoryRecords[factoryId];

      const result: PenaltyRuleHistoryRecord[] = [];

      ids.forEach((id) => {
        result.push(this.penaltyRuleHistoryRecord[id]);
      });

      return result;
    };
  }

  @Mutation
  updateFactoryPenaltyRuleHistoryRecords({
    factoryId,
    penaltyRuleHistoryRecords,
  }: {
    factoryId: string;
    penaltyRuleHistoryRecords: PenaltyRuleHistoryRecord[];
  }): void {
    penaltyRuleHistoryRecords.forEach((historyRecord) => {
      if (historyRecord.factory !== factoryId) {
        throw new Error(
          "All penalty rule history records should belong to the specified factory!"
        );
      }
    });

    const penaltyRuleHistoryRecordIds: string[] = [];

    penaltyRuleHistoryRecords.forEach((historyRecord) => {
      penaltyRuleHistoryRecordIds.push(historyRecord.id);
      Vue.set(this.penaltyRuleHistoryRecord, historyRecord.id, historyRecord);
    });

    Vue.set(
      this.factoryPenaltyRuleHistoryRecords,
      factoryId,
      penaltyRuleHistoryRecordIds
    );
  }

  @Action({ rawError: true })
  async loadPenaltyRuleHistoryRecordsByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<PenaltyRuleHistoryRecord[]> {
    if (useCache && this.factoryPenaltyRuleHistoryRecords[factoryId]) {
      const ids = this.factoryPenaltyRuleHistoryRecords[factoryId];

      const result: PenaltyRuleHistoryRecord[] = [];

      ids.forEach((id) => {
        result.push(this.penaltyRuleHistoryRecord[id]);
      });

      return result;
    }

    const collection = await DIContainer.PenaltyRuleHistoryRecordRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryPenaltyRuleHistoryRecords({
      factoryId,
      penaltyRuleHistoryRecords: items,
    });

    return items;
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onInvoiceUpdated(invoiceId: string): Promise<void> {
    const promises = [];

    if (this.invoiceItems[invoiceId]) {
      promises.push(
        void this.loadInvoiceItemsByInvoiceId({ invoiceId, useCache: false })
      );
    }

    if (this.invoiceExtraPayments[invoiceId]) {
      promises.push(
        void this.loadExtraPaymentsByInvoiceId({ invoiceId, useCache: false })
      );
    }

    await Promise.all(promises);
  }

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushieExtraPayments[plushieId]) {
      return;
    }

    await this.loadExtraPaymentsByPlushieId({ plushieId, useCache: false });
  }

  @Action({ rawError: true })
  async onFactoryInvoicesPaymentsSaved(payments: Payment[]): Promise<void> {
    const invoicesToUpdate = [];

    for (const payment of payments) {
      if (!this.factoryInvoice[payment.invoice]) {
        continue;
      }

      invoicesToUpdate.push(payment.invoice);
    }

    if (!invoicesToUpdate.length) {
      return;
    }

    await this.loadFactoryInvoicesByIds({
      invoiceIds: invoicesToUpdate,
      useCache: false,
    });
  }

  @Action({ rawError: true })
  async onInvoiceItemUpdated(invoiceItem: InvoiceItem): Promise<void> {
    if (!this.factoryInvoice[invoiceItem.invoice]) {
      return;
    }

    await this.loaFactoryInvoiceById({
      id: invoiceItem.invoice,
      useCache: false,
      stopUpdateEvent: true,
    });
  }

  @Action({ rawError: true })
  async onExtraPaymentUpdated(extraPayment: ExtraPayment): Promise<void> {
    if (!extraPayment.invoice) {
      return;
    }

    const promises = [];

    if (this.factoryInvoice[extraPayment.invoice]) {
      promises.push(
        void this.loaFactoryInvoiceById({
          id: extraPayment.invoice,
          useCache: false,
          stopUpdateEvent: true,
        })
      );
    }

    if (extraPayment.plushie && this.invoiceItems[extraPayment.invoice]) {
      promises.push(
        void this.loadInvoiceItemsByInvoiceId({
          invoiceId: extraPayment.invoice,
          useCache: false,
        })
      );
    }

    await Promise.all(promises);
  }

  @Action({ rawError: true })
  async onFactoryPenaltyRulesUpdated(factoryId: string): Promise<void> {
    if (!this.factoryPenaltyRuleHistoryRecords[factoryId]) {
      return;
    }

    await this.loadPenaltyRuleHistoryRecordsByFactoryId({
      factoryId,
      useCache: false,
    });
  }

  @Action({ rawError: true })
  async onFactoryElementPricesUpdated(factoryId: string): Promise<void> {
    if (!this.factoryElementPricesHistoryRecords[factoryId]) {
      return;
    }

    await this.loadElementPriceHistoryRecordsByFactoryId({
      factoryId,
      useCache: false,
    });
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
