import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class ElementPriceHistoryRecord implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fUser: string,
    private fAction: string,
    private fFactory: string,
    private fType: string,
    private fEntity: string,
    private fQty: number,
    private fPrice: number
  ) {
    [fId, fUser, fAction, fFactory, fType, fEntity, fQty, fPrice].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get user(): string {
    return this.fUser;
  }

  get action(): string {
    return this.fAction;
  }

  get factory(): string {
    return this.fFactory;
  }

  get type(): string {
    return this.fType;
  }

  get entity(): string {
    return this.fEntity;
  }

  get qty(): number {
    return this.fQty;
  }

  get price(): number {
    return this.fPrice;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
