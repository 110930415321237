










import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { saveAs } from "file-saver";

import FormErrors from "@/lib/components/FormErrors.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import ErrorConverterService from "@/modules/forms/error-converter.service";
import ResponseFileNameResolver from "@/modules/general/response-file-name-resolver.service";

import FactoryInvoiceRepository from "../../factory-invoice.repository";

@Component({
  components: {
    FormErrors,
    ActionButton,
  },
})
export default class FactoryInvoiceDownloadPdfAction extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  @Inject("ErrorConverterService")
  protected fErrorConverterService!: ErrorConverterService;

  @Inject("FactoryInvoiceRepository")
  protected fFactoryInvoiceRepository!: FactoryInvoiceRepository;

  private fSubmitErrors: string[] = [];

  get submitErrors(): string[] {
    return this.fSubmitErrors;
  }

  public async download(): Promise<void> {
    this.fSubmitErrors = [];

    try {
      const response = await this.fFactoryInvoiceRepository.getPdfByInvoiceId(
        this.invoiceId
      );

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const fileName =
        ResponseFileNameResolver.resolve(response) ||
        `factory_invoice_${this.invoiceId}.pdf`;

      saveAs(blob, fileName);
    } catch (e) {
      this.fSubmitErrors = this.fErrorConverterService.describeError(e);
      throw e;
    }
  }
}
