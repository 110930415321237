













































import Vue from "vue";
import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import PlushieStore from "@/modules/plushie/store";
import Upgrade from "@/modules/plushie/upgrade.model";
import dataStore from "@/store";

import Factory from "../../factory.model";
import FactoryStore from "../../store";
import UpgradeAllocation from "../../upgrade-allocation.model";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class UpgradeAllocationEditForm extends Vue {
  private fIsLoading = false;

  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  private fAllocationItems: UpgradeAllocation[] = [];

  get factories(): Factory[] {
    return this.fFactoryDataStore.activeFactoriesList;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get upgrades(): Upgrade[] {
    return this.fPlushieDataStore.upgradeListForFactories;
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public getUpgradeAllocationValueByFactoryAndUpgrade(
    factoryId: string,
    upgradeId: string
  ): boolean {
    const item = this.fAllocationItems.find(
      (item) => item.factory === factoryId && item.upgrade === upgradeId
    );

    if (!item) {
      return false;
    }
    return item.canProduce;
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    try {
      [this.fAllocationItems] = await Promise.all([
        this.fFactoryDataStore.loadUpgradeAllocations(false),
        this.fFactoryDataStore.loadFactories(),
        this.fPlushieDataStore.loadUpgrades(),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
