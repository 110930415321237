


















import { Component, Inject } from "vue-property-decorator";
import Vue from "vue";

import UserInfoRepository from "@/modules/account/user-info.repository";
import User from "@/modules/account/user.model";
import UserInfo from "@/modules/account/user-info.model";

@Component({
  components: {},
  metaInfo: {},
})
export default class AccountMenu extends Vue {
  @Inject("UserInfoRepository")
  private fUserInfoRepository!: UserInfoRepository;

  private fUser: User;
  private fUserInfo?: UserInfo;

  get userInfo(): UserInfo | undefined {
    return this.fUserInfo;
  }

  constructor() {
    super();

    const user = this.$auth.user();

    if (!user) {
      throw new Error("Unable to acquire current user!");
    }

    this.fUser = this.$auth.user();
  }

  public logout(): void {
    this.$auth.logout({
      makeRequest: false,
    });
  }

  protected data(): Record<string, unknown> {
    return {
      fUserInfo: this.fUserInfo,
    };
  }

  protected async created(): Promise<void> {
    this.fUserInfo = await this.fUserInfoRepository.getById(this.fUser.id);
  }
}
