import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import RejectionReason from "./rejection-reason.model";

export default class RejectionReasonRepository extends BasicRepository<RejectionReason> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<RejectionReason>,
    responseParser: ApiCollectionResponseParserService<RejectionReason>
  ) {
    super("rejection_reasons", api, objectBuilder, responseParser);
  }

  protected prepareData(
    item: RejectionReason
  ): Record<string, string | boolean> {
    return {
      id: item.id,
      name: item.name,
      type: item.type,
      isActive: item.isActive,
    };
  }
}
