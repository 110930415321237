























































import { Component, Vue, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  RowClickEventData,
  ServerResponse,
  ColumnFilterDropdownOption,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import { Dictionary } from "@/lib/Dictionary.type";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";

import QuestionTemplateShortContentResolverService from "../../question-template-short-content-resolver.service";
import MessagingStore from "../../store";

interface TableRow {
  id: string;
  name: string;
  content: string;
  associatedStatuses: string;
  associatedProducts: string;
}

@Component({
  metaInfo: {
    title: "Question Templates Management",
  },
  components: {},
})
export default class QuestionTemplatesListPage extends mixins(
  GeneralListPageMixin
) {
  @Inject("QuestionTemplateShortContentResolverService")
  private fShortContentResolverService!: QuestionTemplateShortContentResolverService;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fPlushieDataStore: PlushieStore;
  private fMessagingDataStore: MessagingStore;

  get canManageQuestionTemplates(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.QUESTION_TEMPLATE_MANAGE);
  }

  public constructor() {
    super();

    this.fMessagingDataStore = getModule(MessagingStore, dataStore);

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "content",
        header: "Text",
        cssClass: "_content-column -text",
        displayConstraint: "min_tabletL",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "associatedStatuses",
        header: "Available for Statuses",
        cssClass: "_associatedstatuses-column -text",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "statusRelations.plushieStatusId",
      },
      {
        name: "associatedProducts",
        header: "Available for Products",
        cssClass: "_associatedproducts-column -text",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "productRelations.productId",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "name",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    if (!this.canManageQuestionTemplates) {
      return;
    }

    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "QuestionTemplateEditPage"));
  }

  public async deleteItem(id: string): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fMessagingDataStore.deleteQuestionTemplateById(id);

      if (this.fTable) {
        this.fTable.refresh();
      }
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const [questionTemplatesCollection] = await Promise.all([
      this.fMessagingDataStore.loadQuestionTemplates({
        page,
        limit,
        filter,
        order,
      }),
      this.fPlushieDataStore.loadPlushieStatuses(),
      this.fPlushieDataStore.loadProducts(),
    ]);

    const result: TableRow[] = [];

    const statuses = this.fPlushieDataStore.statusesDictionary;
    const products = this.fPlushieDataStore.productsDictionary;

    const questionTemplates = questionTemplatesCollection.getItems();

    const questionTemplateIds = questionTemplates.map(
      (template) => template.id
    );

    await Promise.all([
      this.fMessagingDataStore.loadQuestionTemplateStatusRelationsByTemplateIds(
        { templateIds: questionTemplateIds, useCache: false }
      ),
      this.fMessagingDataStore.loadQuestionTemplateProductRelationsByTemplateIds(
        { templateIds: questionTemplateIds, useCache: false }
      ),
    ]);

    questionTemplates.forEach((questionTemplate) => {
      const statusRelations = this.fMessagingDataStore.getQuestionTemplateStatusRelationsByTemplateId(
        questionTemplate.id
      );
      const productRelations = this.fMessagingDataStore.getQuestionTemplateProductRelationsByTemplateId(
        questionTemplate.id
      );

      const associatedStatusesColumnValue =
        statusRelations.length > 0
          ? statusRelations
              .map((relation) => statuses[relation.plushieStatus])
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              .join(", ")
          : "";

      const associatedProductsColumnValue =
        productRelations.length > 0
          ? productRelations
              .map((relation) => products[relation.productId])
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              .join(", ")
          : "";

      result.push({
        id: questionTemplate.id,
        name: questionTemplate.name,
        content: this.fShortContentResolverService.resolve(
          questionTemplate.content
        ),
        associatedStatuses: associatedStatusesColumnValue,
        associatedProducts: associatedProductsColumnValue,
      });
    });

    return { data: result, count: questionTemplatesCollection.totalItemsCount };
  }

  protected async created(): Promise<void> {
    await Promise.all([
      this.fPlushieDataStore.loadPlushieStatuses(),
      this.fPlushieDataStore.loadProducts(),
    ]);

    const statuses = this.fPlushieDataStore.plushieStatusList;
    const products = this.fPlushieDataStore.activeProductsList;

    const associatedStatusOptions: ColumnFilterDropdownOption[] = [];
    const associatedProductsOptions: ColumnFilterDropdownOption[] = [];

    statuses.forEach((item) => {
      associatedStatusOptions.push({ id: item.id, text: item.name });
    });

    products.forEach((product) => {
      associatedProductsOptions.push({ id: product.id, text: product.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(
        this.fOptions.listColumns,
        "associatedStatuses",
        associatedStatusOptions
      );
    }

    if (this.fOptions.listColumns) {
      Vue.set(
        this.fOptions.listColumns,
        "associatedProducts",
        associatedProductsOptions
      );
    }
  }
}
