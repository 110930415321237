export default class ProjectionStats {
  constructor(
    private fProductId: string,
    private fProjectionTotal: number,
    private fCapacity: number,
    private fCapacityRemaining: number,
    private fSoldTotal: number,
    private fSalesWeeks: number
  ) {
    [
      fProductId,
      fProjectionTotal,
      fCapacity,
      fCapacityRemaining,
      fSoldTotal,
      fSalesWeeks,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get productId(): string {
    return this.fProductId;
  }

  get projectionTotal(): number {
    return this.fProjectionTotal;
  }

  get capacity(): number {
    return this.fCapacity;
  }

  get capacityRemaining(): number {
    return this.fCapacityRemaining;
  }

  get soldTotal(): number {
    return this.fSoldTotal;
  }

  get salesWeeks(): number {
    return this.fSalesWeeks;
  }
}
