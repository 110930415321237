import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import InReviewSnapshot from "./in-review-snapshot.model";

export default class InReviewSnapshotFactory
  implements ObjectBuilderInterface<InReviewSnapshot> {
  public buildFromJSON(data: { [key: string]: any }): InReviewSnapshot {
    const value = new InReviewSnapshot(data.product_name, data.total);

    return value;
  }
}
