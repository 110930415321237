

























































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";
import DatePicker from "vuejs-datepicker";
import { Validator } from "vee-validate";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import ColorsAutocomplete from "@/lib/components/ColorsAutocomplete.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import autoindent from "@/lib/directives/auto-indent.directive";
import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import PlushieDesignInformation from "@/modules/plushie/plushie-design-information.model";
import PlushieStore from "@/modules/plushie/store";
import Setting from "@/modules/settings/setting.model";
import SettingStore from "@/modules/settings/store";

import CustomSewInLabelField from "./CustomSewInLabelField.vue";

import BulkProject from "../bulk-project.model";
import BulkProjectStore from "../store";
import BulkProjectPlushieRelation from "../bulk-project-plushie-relation.model";
import { CustomSewInLabelFieldValue } from "../custom-sew-in-label-field-value.interface";

const defaultQuantity = 50;
const defaultSize = 12;

const createValidator = () => {
  let shouldValidateStorageItemId = false;

  return ({
    useCustomSewInLabel,
    storageItemId,
  }: CustomSewInLabelFieldValue) => {
    if (!useCustomSewInLabel) {
      shouldValidateStorageItemId = false;
      return true;
    }

    if (!shouldValidateStorageItemId) {
      shouldValidateStorageItemId = true;
      return true;
    }

    return !!storageItemId;
  };
};

const validator = createValidator();

Validator.extend("custom-sew-in-label", {
  getMessage: () => "Need to upload custom Sew In Label",
  validate: validator,
});

@Component({
  components: {
    ColorsAutocomplete,
    CustomSewInLabelField,
    DatePicker,
    FormErrors,
    FormField,
    MoonLoader,
    SubmitButton,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class BatchCreationForm extends Mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly projectId!: string;

  public quantity = defaultQuantity;
  public size = defaultSize;
  public designerComment = "";
  public notes = "";
  public deadlineDate: Date = new Date();
  public customSewInLabel: CustomSewInLabelFieldValue = {
    useCustomSewInLabel: false,
    storageItemId: undefined,
  };

  private fBulkProjectStore: BulkProjectStore;
  private fPlushieStore: PlushieStore;
  private fSettingStore: SettingStore;

  private fIsLoading = true;

  get bulkProject(): BulkProject {
    const bulkProject = this.fBulkProjectStore.getBulkProjectById(
      this.projectId
    );

    if (!bulkProject) {
      throw new Error(
        `Data is not loaded for bulk project with ID: ${this.projectId}`
      );
    }

    return bulkProject;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get lastProjectRelatedPlushieDesignInformation():
    | PlushieDesignInformation
    | undefined {
    if (!this.lastBulkProjectPlushieRelation) {
      return;
    }

    return this.fPlushieStore.getDesignInformationByPlushieId(
      this.lastBulkProjectPlushieRelation.plushie
    );
  }

  get lastBulkProjectPlushieRelation(): BulkProjectPlushieRelation | undefined {
    return this.fBulkProjectStore.getLastPlushieRelationByBulkProjectId(
      this.projectId
    );
  }

  get turnaroundDaysCount(): number {
    const setting = this.fSettingStore.getSettingById(
      Setting.BULK_PROJECT_BATCH_TURNAROUND_DAYS_COUNT
    );

    if (!setting || !setting.value) {
      return 0;
    }

    return Number.parseInt(setting.value);
  }

  constructor() {
    super();
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fSettingStore = getModule(SettingStore, rootStore);
  }

  async created(): Promise<void> {
    await this.loadData();
    this.prefillFormData();
  }

  protected async performSubmit(): Promise<void> {
    await this.fBulkProjectStore.createBatch({
      bulkProjectId: this.projectId,
      quantity: this.quantity,
      size: this.size,
      designerComment: this.designerComment,
      notes: this.notes,
      deadlineDate: this.deadlineDate.toISOString().split("T")[0],
      useCustomSewInLabel: this.customSewInLabel.useCustomSewInLabel,
      customSewInLabelStorageItemId: this.customSewInLabel.storageItemId,
    });

    this.$emit("batch-created");
  }

  private getDefaultDeadlineDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + this.turnaroundDaysCount);
    return date;
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    await Promise.all([
      this.fBulkProjectStore.loadBulkProjectPlushieRelationsByBulkProjectId(
        this.projectId
      ),
      this.fSettingStore.loadSettingById(
        Setting.BULK_PROJECT_BATCH_TURNAROUND_DAYS_COUNT
      ),
    ]);

    const lastPlushieRelation = this.fBulkProjectStore.getLastPlushieRelationByBulkProjectId(
      this.projectId
    );

    if (lastPlushieRelation) {
      await this.fPlushieStore.loadDesignInformationByPlushieId({
        plushieId: lastPlushieRelation.plushie,
      });
    }

    this.fIsLoading = false;
  }

  private prefillFormData(): void {
    this.quantity = this.bulkProject.quantity;
    this.size = this.bulkProject.size;

    this.designerComment =
      this.lastProjectRelatedPlushieDesignInformation?.comment || "";

    this.deadlineDate = this.getDefaultDeadlineDate();
  }
}
