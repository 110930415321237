/**
 * Truncate string to max characters length.
 *
 * If word boundary is enabled then the sting is truncated to the last whole word.
 */

function truncate(
  text: string,
  length = 25,
  useWordBoundary = true,
  ending = "..."
): string {
  if (text.length <= length) {
    return text;
  }

  const subString = text.slice(0, length + 1);

  if (!useWordBoundary) {
    return subString + ending;
  }

  return subString.slice(0, subString.lastIndexOf(" ")) + ending;
}

export default truncate;
