import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import {
  FilterValues,
  OrderByOption,
  ServerRequestParameters,
  ServerResponse,
  ServerTableInstance,
  TableOptions,
} from "vue-tables-2-premium";

import {
  FieldOrderAsc,
  FieldOrderDesc,
} from "@/modules/api/field-order-options";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";

import { Dictionary } from "../Dictionary.type";
import TableColumnDefinitionInterface from "../interfaces/table-column-definition.interface";
import TableColumnDefinitionsParserService from "../services/table-column-definitions-parser.service";

interface TableQueryDescription {
  order?: QueryOrderParameter;
  filter?: Dictionary<ApiFilterValue>;
}

@Component
export default class GeneralListPageMixin extends Vue {
  @Prop()
  public filter?: Dictionary<ApiFilterValue>;

  @Prop()
  public orderBy?: QueryOrderParameter;

  @Prop({ default: 1 })
  public page!: number;

  @Prop()
  public pageSize?: number;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isInitialized(): boolean {
    return this.fIsInitialized;
  }

  get options(): TableOptions {
    return this.fOptions;
  }

  get filterForRequest(): Dictionary<ApiFilterValue> | undefined {
    return this.fFilterForRequest;
  }

  get orderForRequest(): QueryOrderParameter | undefined {
    return this.fOrderForRequest;
  }

  protected fColumns: TableColumnDefinitionInterface[] = [];

  protected fOptions: TableOptions = {
    requestFunction: (data: ServerRequestParameters) => this.fetchList(data),
    texts: {
      filterBy: "",
      defaultOption: "",
    },
  };

  protected fIsLoading = false;

  protected fTable?: ServerTableInstance;

  private fDefinitionsParser?: TableColumnDefinitionsParserService;
  private fIsInitialized = false;
  private fQuery?: TableQueryDescription;
  private fFilterForRequest?: Dictionary<ApiFilterValue>;
  private fOrderForRequest?: QueryOrderParameter;

  public getColumns(): string[] {
    if (!this.fDefinitionsParser) {
      return [];
    }

    return this.fDefinitionsParser.getColumns();
  }

  protected data(): Record<string, unknown> {
    return {
      fFilterForRequest: this.fFilterForRequest,
      fOrderForRequest: this.fOrderForRequest,
    };
  }

  protected async created(): Promise<void> {
    this.fDefinitionsParser = new TableColumnDefinitionsParserService(
      this.fColumns
    );

    this.fOptions.headings = this.fDefinitionsParser.getHeadings();
    this.fOptions.columnsClasses = this.fDefinitionsParser.getClasses();
    this.fOptions.columnsDisplay = this.fDefinitionsParser.getColumnDisplay();
    this.fOptions.filterable = this.fDefinitionsParser.getFilterable();
    // this.fOptions.listColumns = this.fDefinitionsParser.getListColumns();
    Vue.set(
      this.fOptions,
      "listColumns",
      this.fDefinitionsParser.getListColumns()
    );
    this.fOptions.sortable = this.fDefinitionsParser.getSortable();

    await this.init();
    this.fIsInitialized = true;
    this.fTable = (this.$refs.listTable as unknown) as ServerTableInstance;
  }

  protected async fetchList(
    data: ServerRequestParameters
  ): Promise<ServerResponse> {
    const order = this.prepareOrderForQuery(data);
    const filter = this.prepareFilterForQuery(data);

    const filterForRequest = Object.assign({}, filter, this.getExtraParams());
    const orderForRequest = this.buildOrderFieldsList(order);

    this.fFilterForRequest = filterForRequest;
    this.fOrderForRequest = orderForRequest;

    const result = await this.fetchFromServer(
      data.page,
      data.limit,
      filterForRequest,
      orderForRequest
    );

    const query: TableQueryDescription = { order, filter };

    this.fQuery = query;

    await this.navigateToPage(data.page, data.limit, query.filter, query.order);

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    return {
      data: [],
      count: 0,
    };
  }

  protected getExtraParams(): Dictionary<ApiFilterValue> {
    return {};
  }

  protected buildOrderFieldsList(
    order: QueryOrderParameter
  ): QueryOrderParameter {
    return order;
  }

  protected getItemUrl(itemId: string, routeName: string): string {
    const localQuery: {
      filter: Dictionary<ApiFilterValue>;
      order?: QueryOrderParameter;
    } = {
      filter: {},
      order: [],
    };

    let order: QueryOrderParameter = [];

    if (this.fQuery && this.fQuery.order) {
      order = this.fQuery.order;
    }

    localQuery.order = this.buildOrderFieldsList(order);

    if (this.fQuery && this.fQuery.filter) {
      Object.assign(localQuery.filter, this.fQuery.filter);
    }
    Object.assign(localQuery.filter, this.getExtraParams());

    const route = this.$router.resolve({
      name: routeName,
      query: {
        id: itemId,
        query: JSON.stringify(localQuery),
        listQuery: this.getListQuery(),
      },
    });

    return route.href;
  }

  protected getListQuery(): string {
    return JSON.stringify(this.$route.query);
  }

  protected init(): Promise<void> {
    return Promise.resolve();
  }

  protected async navigateToPage(
    page: number,
    pageSize: number,
    filter?: Dictionary<ApiFilterValue>,
    orderBy?: QueryOrderParameter
  ): Promise<void> {
    if (!this.$route.name) {
      throw new Error("Route is not defined!");
    }

    const query: Dictionary<string> = {
      page: page.toString(),
      pageSize: pageSize.toString(),
    };

    Object.assign(query, this.getExtraParams());

    if (filter != null) {
      query.filter = JSON.stringify(filter);
    }

    if (orderBy != null) {
      query.orderBy = JSON.stringify(Array.from(orderBy));
    }

    const newRoute = this.$router.resolve({
      name: this.$route.name.toString(),
      query,
    });

    if (this.$router.currentRoute.fullPath === newRoute.href) {
      return;
    }

    await this.$router.replace(newRoute.location);
  }

  protected onLoading(): void {
    this.fIsLoading = true;
  }

  protected onLoaded(): void {
    this.fIsLoading = false;
    this.fTable = (this.$refs.listTable as unknown) as ServerTableInstance;
  }

  private prepareFilterForTable(
    values: Dictionary<ApiFilterValue>
  ): FilterValues {
    const filters: FilterValues = {};

    if (!this.fDefinitionsParser) {
      return filters;
    }

    const definitionsParser = this.fDefinitionsParser;

    Object.keys(values).forEach((field: string) => {
      const column = definitionsParser.getColumnByFilterKey(field);

      if (!column || !this.filter) {
        return;
      }

      filters[column] = this.filter[field];
    });

    return filters;
  }

  private prepareOrderForTable(
    values: QueryOrderParameter
  ): OrderByOption | undefined {
    if (!this.fDefinitionsParser) {
      return;
    }

    const definitionsParser = this.fDefinitionsParser;
    let result: OrderByOption | undefined;

    values.some((item) => {
      const column = definitionsParser.getColumnBySortKey(item[0]);

      if (!column) {
        return false;
      }

      result = {
        column,
        ascending: item[1] === FieldOrderAsc,
      };

      return true;
    });

    return result;
  }

  private prepareFilterForQuery(
    data: ServerRequestParameters
  ): Dictionary<ApiFilterValue> {
    const filter: Dictionary<ApiFilterValue> = {};

    if (!this.fDefinitionsParser || !data.query) {
      return filter;
    }

    const definitionsParser = this.fDefinitionsParser;

    Object.keys(data.query).forEach((field) => {
      const filterKey = definitionsParser.getFilterKeyForColumn(field);
      filter[filterKey] = data.query[field];
    });

    return filter;
  }

  private prepareOrderForQuery(
    data: ServerRequestParameters
  ): QueryOrderParameter {
    if (!this.fDefinitionsParser || !data.orderBy) {
      return [];
    }

    const order: QueryOrderParameter = [];
    const field = data.orderBy;

    const sortKey = this.fDefinitionsParser.getSortKeyForColumn(field);
    order.push([sortKey, data.ascending ? FieldOrderAsc : FieldOrderDesc]);

    return order;
  }

  @Watch("page", { immediate: true })
  @Watch("pageSize", { immediate: false })
  @Watch("filter", { immediate: false })
  @Watch("orderBy", { immediate: false })
  protected _onQueryChange(): void {
    if (this.page != null) {
      this.fOptions.initialPage = this.page;
    }

    if (this.pageSize != null) {
      this.fOptions.perPage = this.pageSize;
    }

    if (this.filter != null) {
      this.fOptions.initFilters = this.prepareFilterForTable(this.filter);
    }

    if (this.orderBy != null) {
      this.fOptions.orderBy = this.prepareOrderForTable(this.orderBy);
    }
  }
}
