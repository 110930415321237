import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieAttachmentType from "./plushie-attachment-type.model";

export default class PlushieAttachmentTypeFactory
  implements ObjectBuilderInterface<PlushieAttachmentType> {
  public buildFromJSON(data: { [key: string]: any }): PlushieAttachmentType {
    const value = new PlushieAttachmentType(data.id, data.code);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
