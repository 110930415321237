












































































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import ColorsAutocomplete from "@/lib/components/ColorsAutocomplete.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import autoindent from "@/lib/directives/auto-indent.directive";
import PlushieStore from "@/modules/plushie/store";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import Plushie from "@/modules/plushie/plushie.model";
import { UiPersisterStore } from "@/modules/ui-persister/store";

import RejectionReason from "../rejection-reason.model";
import QualityInspectionStore from "../store";

@Component({
  components: {
    ColorsAutocomplete,
    FormField,
    FormErrors,
    MoonLoader,
    SubmitButton,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class QualityInspectionRejectCommand extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fIsLoading = true;

  private fQualityInspectionDataStore: QualityInspectionStore;
  private fPlushieDataStore: PlushieStore;
  private fUiPersisterDataStore: UiPersisterStore;

  get comment(): string {
    return (
      this.fUiPersisterDataStore.getPlushieRejectComment(this.plushieId) || ""
    );
  }

  set comment(comment: string) {
    this.fUiPersisterDataStore.updatePlushieRejectComment({
      plushieId: this.plushieId,
      comment: comment,
    });
  }

  get rejectionReasonIds(): string[] {
    return (
      this.fUiPersisterDataStore.getPlushieRejectionReasonIds(this.plushieId) ||
      []
    );
  }

  set rejectionReasonIds(ids: string[]) {
    this.fUiPersisterDataStore.updatePlushieRejectionReasonIds({
      plushieId: this.plushieId,
      ids: ids,
    });
  }

  get rejectionReasonTypes(): DirectoryValue[] {
    const types = this.fQualityInspectionDataStore.rejectionReasonTypes;
    return types.filter(
      (type) => this.typeActiveRejectionReasons(type.id).length
    );
  }

  get rejectionReasons(): RejectionReason[] {
    if (!this.plushie) {
      return [];
    }

    const rejectionReasonProductRelations = this.fQualityInspectionDataStore.getRejectionReasonProductRelationsByProductId(
      this.plushie.product
    );

    const rejectionReasonIds = rejectionReasonProductRelations.map(
      (relation) => relation.rejectionReason
    );

    return this.fQualityInspectionDataStore.getRejectionReasonsByIds(
      rejectionReasonIds
    );
  }

  get typeActiveRejectionReasons(): (typeId: string) => RejectionReason[] {
    return (typeId: string) => {
      return this.rejectionReasons.filter(
        (reason) => reason.isActive && reason.type === typeId
      );
    };
  }

  get plushie(): Plushie | undefined {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();
    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fUiPersisterDataStore = getModule(UiPersisterStore, dataStore);
  }

  protected async performSubmit(): Promise<void> {
    if (
      !this.comment ||
      !this.rejectionReasonIds ||
      !this.rejectionReasonIds.length
    ) {
      throw new Error("Not all required params are specified");
    }

    await this.fQualityInspectionDataStore.rejectPlushie({
      plushieId: this.plushieId,
      rejectionReasonIds: this.rejectionReasonIds,
      comment: this.comment,
    });

    this.fUiPersisterDataStore.deletePlushieRejectComment({
      plushieId: this.plushieId,
    });

    this.fUiPersisterDataStore.deletePlushieRejectionReasonIds({
      plushieId: this.plushieId,
    });
  }

  private async getPlushieData(plushieId: string): Promise<void> {
    const plushie = await this.fPlushieDataStore.loadPlushieById({
      id: plushieId,
    });

    if (!plushie) {
      return;
    }

    const productId = plushie.product;

    const rejectionReasonProductRelations = await this.fQualityInspectionDataStore.loadRejectionReasonProductRelationsByProductId(
      { productId }
    );

    const rejectionReasonIds = rejectionReasonProductRelations.map(
      (relation) => relation.rejectionReason
    );

    await Promise.all([
      this.fQualityInspectionDataStore.loadRejectionReasonsByIds(
        rejectionReasonIds
      ),
      this.fQualityInspectionDataStore.loadRejectionReasonTypes(),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);

    await this.$validator.reset();

    this.fIsLoading = false;
  }
}
