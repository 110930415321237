import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { ResourceCollection } from "@/modules/api/resource.collection";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryPlushieStatusCapacity from "./factory-plushie-status-capacity.model";
import { FactoryPlushieStatusCapacityUpdate } from "./factory-plushie-status-capacity-update.interface";

export default class FactoryPlushieStatusCapacityRepository extends BasicRepository<FactoryPlushieStatusCapacity> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<FactoryPlushieStatusCapacity>,
    responseParser: ApiCollectionResponseParserService<FactoryPlushieStatusCapacity>
  ) {
    super(
      "factory_plushie_status_capacities",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<ResourceCollection<FactoryPlushieStatusCapacity>> {
    return this.fetchList({ factory: factoryId }, 1, 99);
  }

  public async createUpdateRequest(
    factoryId: string,
    capacityUpdates: FactoryPlushieStatusCapacityUpdate[]
  ): Promise<ResourceCollection<FactoryPlushieStatusCapacity>> {
    const items: Dictionary<string | number | boolean>[] = [];

    capacityUpdates.forEach((capacityUpdate) => {
      items.push({
        id: capacityUpdate.id,
        plushieStatus: +capacityUpdate.plushieStatus,
        value: +capacityUpdate.value,
        usePullMode: capacityUpdate.usePullMode == true,
      });
    });

    const response = await this.api.post(
      this.resourceName + "/update_requests",
      {
        factory: factoryId,
        items: items,
      }
    );

    return this.responseParser.parse(response.data, this.objectBuilder);
  }
}
