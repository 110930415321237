


























































import { Component, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import FactoryStore from "@/modules/factory/store";

@Component({
  components: {},
  metaInfo: {},
})
export default class FactoryManagementMenu extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fFactoryDataStore: FactoryStore;
  private fFactoryId?: string;
  private fIsLoading = true;

  get canEditFactoriesBillingInfo(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_BILLING_INFO_MANAGE);
  }

  get canViewFactoriesList(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_VIEW);
  }

  get canViewProductAllocation(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCT_ALLOCATION_RULES_VIEW
    );
  }

  get canViewFactoryInvoices(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.INVOICE_READ);
  }

  get canViewProductionTimeNorms(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(Resource.PRODUCTION_TIME_NORM_VIEW)
    );
  }

  get canViewPriceConfiguration(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(Resource.FACTORY_INVOICE_PRICES_READ)
    );
  }

  get canViewPenaltyRules(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      user.hasPermissionForResource(Resource.FACTORY_VIEW) &&
      user.hasPermissionForResource(Resource.FACTORY_INVOICE_PENALTIES_READ)
    );
  }

  get factoryId(): string | undefined {
    return this.fFactoryId;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      const user = this.fUserProvider.getUser();

      if (!user || user.role !== AuthenticatedUser.ROLE_FACTORY) {
        return;
      }

      const userFactoryRelation = await this.fFactoryDataStore.loadUserFactoryRelationByUserId(
        { userId: user.id }
      );

      if (!userFactoryRelation) {
        throw Error("User should be assigned to a factory");
      }

      this.fFactoryId = userFactoryRelation.factory;
    } finally {
      this.fIsLoading = false;

      await this.$nextTick();

      this.$emit("stateUpdated");
    }
  }

  protected data(): Record<string, unknown> {
    return {
      fFactoryId: this.fFactoryId,
    };
  }
}
