import { render, staticRenderFns } from "./SearchResult.vue?vue&type=template&id=1b035063&scoped=true&"
import script from "./SearchResult.vue?vue&type=script&lang=ts&"
export * from "./SearchResult.vue?vue&type=script&lang=ts&"
import style0 from "./SearchResult.vue?vue&type=style&index=0&id=1b035063&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b035063",
  null
  
)

export default component.exports