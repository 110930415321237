




































import "v-autocomplete/dist/v-autocomplete.css";

import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import Autocomplete from "v-autocomplete";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import TagStore from "../store";
import TagRepository from "../tag.repository";

@Component({
  components: {
    Autocomplete,
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class PlushieAddTagForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("TagRepository")
  private fTagRepository!: TagRepository;

  public tag?: string;

  private fIsLoading = true;

  private fTagDataStore: TagStore;

  private fTagOptions: string[] = [];

  get isLoading() {
    return this.fIsLoading;
  }

  get tagOptions() {
    return this.fTagOptions;
  }

  constructor() {
    super();
    this.fTagDataStore = getModule(TagStore, dataStore);
  }

  public onInput(value: string) {
    this.tag = value;
  }

  public async searchTags(term: string): Promise<void> {
    this.fTagOptions = (await this.fTagRepository.searchTags(term)).map(
      (tag) => tag.name
    );
  }

  protected data() {
    return {
      tag: this.tag,
    };
  }

  protected async afterSubmit(): Promise<void> {
    this.$nextTick(() => {
      const tagInput = this.getTagInput();
      if (!tagInput) {
        return;
      }

      tagInput.focus();
    });
  }

  protected async performSubmit() {
    if (!this.tag) {
      return;
    }

    await this.fTagDataStore.addTagToPlushie({
      tag: this.tag,
      plushieId: this.plushieId,
    });

    this.tag = undefined;
  }

  private getTagInput(): Autocomplete | undefined {
    return this.$refs["tag-input"] as Autocomplete;
  }
}
