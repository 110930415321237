import { render, staticRenderFns } from "./AutoApprovementToggleForm.vue?vue&type=template&id=60e561de&scoped=true&"
import script from "./AutoApprovementToggleForm.vue?vue&type=script&lang=ts&"
export * from "./AutoApprovementToggleForm.vue?vue&type=script&lang=ts&"
import style0 from "./AutoApprovementToggleForm.vue?vue&type=style&index=0&id=60e561de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e561de",
  null
  
)

export default component.exports