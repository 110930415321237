import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import User from "./user.model";

export default class UserFactory implements ObjectBuilderInterface<User> {
  public buildFromJSON(data: { [key: string]: any }): User {
    const value = new User(data.id, data.login);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
