import { Route } from "vue-router";

import LoginPage from "./components/LoginPage.vue";

export default [
  {
    path: "/account/login",
    name: "LoginPage",
    component: LoginPage,
    props: (route: Route) => ({
      redirect: route.query.redirect,
    }),
    meta: {
      auth: false,
    },
  },
];
