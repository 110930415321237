import { ProductValue } from "@/modules/plushie/product.value";
import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class QuestionTemplateProductRelation implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fTemplate: string,
    private fProductId: ProductValue
  ) {
    [fId, fTemplate, fProductId].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get template(): string {
    return this.fTemplate;
  }

  get productId(): ProductValue {
    return this.fProductId;
  }
}
