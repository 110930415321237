import { MutableValue } from "@/modules/api/mutable-value.interface";

import { ProductValue } from "./product.value";
import { PlushieStatusValue } from "./plushie-status.value";

export default class Plushie implements MutableValue {
  public isNew = true;

  public breed?: string;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;
  private fName?: string;

  public constructor(
    private fId: string,
    private fProduct: ProductValue,
    private fStatus: PlushieStatusValue,
    private fStore: string,
    private fDescription: string,
    private fQuantity: number,
    private fStoreItemId: string
  ) {
    [
      fId,
      fProduct,
      fStatus,
      fStore,
      fDescription,
      fQuantity,
      fStoreItemId,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get name(): string | undefined {
    return this.fName;
  }

  get store(): string {
    return this.fStore;
  }

  get storeItemId(): string {
    return this.fStoreItemId;
  }

  get product(): ProductValue {
    return this.fProduct;
  }

  get status(): PlushieStatusValue {
    return this.fStatus;
  }

  get description(): string {
    return this.fDescription;
  }

  get quantity(): number {
    return this.fQuantity;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
