import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import QaAssetType from "./qa-asset-type";
import QaAssetVideo from "./qa-asset-video.model";
import QaAsset from "./qa-asset.model";

export default class QaAssetFactory implements ObjectBuilderInterface<QaAsset> {
  public buildFromJSON(data: { [key: string]: any }): QaAsset {
    let value;
    switch (data.type) {
      case QaAssetType.VIDEO:
        value = new QaAssetVideo(
          data.id,
          data.plushieId,
          data.storageItemId,
          data.type,
          +data.groupNumber,
          new Date(data.createdAt),
          data.thumbnailStorageItemId
        );
        break;
      default:
        value = new QaAsset(
          data.id,
          data.plushieId,
          data.storageItemId,
          data.type,
          +data.groupNumber,
          new Date(data.createdAt)
        );
    }

    value.isNew = false;

    return value;
  }
}
