import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushiePriority from "./plushie-priority.model";

export default class PlushiePriorityFactory
  implements ObjectBuilderInterface<PlushiePriority> {
  public buildFromJSON(data: { [key: string]: any }): PlushiePriority {
    const value = new PlushiePriority(
      data.id,
      +data.value,
      data.bonusesIds || []
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
