



































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import PlushieStore from "@/modules/plushie/store";

import EmailsManagementStore from "../store";
import EmailLayout from "../email-layout.model";

@Component({
  metaInfo: {
    title: "Email Layout Edit",
  },
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class EmailLayoutEditPage extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly layoutId!: string;

  public content: string | undefined;
  public store: number | undefined;

  private fEmailsManagementStore: EmailsManagementStore;
  private fPlushieStore: PlushieStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get storeOptions(): DirectoryValue[] {
    return this.fPlushieStore.storesList;
  }

  get layout(): EmailLayout | undefined {
    return this.fEmailsManagementStore.getEmailLayoutById(this.layoutId);
  }

  constructor() {
    super();

    this.fEmailsManagementStore = getModule(EmailsManagementStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  public onCancelButtonClick(): void {
    void this.$router.push({
      name: "EmailLayoutsListPage",
    });
  }

  protected created(): void {
    void this.loadData();
  }

  protected data(): Record<string, string | number | undefined> {
    return {
      content: this.content,
      store: this.store,
    };
  }

  protected async performSubmit(): Promise<void> {
    const layout = this.generateLayoutObject();

    await this.fEmailsManagementStore.saveEmailLayout(layout);
  }

  private fillFormData(): void {
    if (!this.layout) {
      return;
    }

    this.store = this.layout.store;
    this.content = this.layout.content;
  }

  private generateLayoutObject(): EmailLayout {
    if (!this.content || !this.store) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const layout = new EmailLayout(this.layoutId, this.content, this.store);

    layout.isNew = false;

    return layout;
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fPlushieStore.loadStores(),
        this.fEmailsManagementStore.loadEmailLayouts(),
      ]);

      this.fillFormData();
    } finally {
      this.fIsLoading = false;
    }
  }
}
