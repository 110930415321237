import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Attachment from "./attachment.model";

export default class AttachmentFactory
  implements ObjectBuilderInterface<Attachment> {
  public buildFromJSON(data: { [key: string]: any }): Attachment {
    const value = new Attachment(
      data.id,
      data.message,
      data.storageItemId,
      new Date(data.createdAt),
      data.mime
    );

    value.isNew = false;

    return value;
  }
}
