









import { Component, Vue, Prop, Watch, Inject } from "vue-property-decorator";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import PlushieExportRepository from "../plushie-export.repository";

@Component({
  components: {
    LoadingSpinner,
  },
  metaInfo: {},
})
export default class PlushiePrintPage extends Vue {
  @Prop()
  public readonly plushieId!: string;

  @Inject("PlushieExportRepository")
  private fPlushieExportRepository!: PlushieExportRepository;

  @Inject("window")
  private fWindow!: Window;

  private fError = false;
  private fIsLoading = true;

  get error(): boolean {
    return this.fError;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  private async getPlushieData(plushieId: string) {
    const response = await this.fPlushieExportRepository.getHtmlByPlushieId(
      plushieId
    );

    if (typeof response.data !== "string") {
      throw new Error("Unexpected response!");
    }

    const html =
      response.data +
      // eslint-disable-next-line no-useless-escape
      `<script type="text/javascript">window.onload = function(){ window.print(); };<\/script>`;

    this.fWindow.document.open();
    this.fWindow.document.write(html);
    this.fWindow.document.close();
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    this.fError = false;

    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getPlushieData(this.plushieId);
    } catch (e) {
      this.fError = true;
      throw e;
    } finally {
      this.fIsLoading = false;
    }
  }
}
