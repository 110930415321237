import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class BulkProjectBatchInformation implements MutableValue {
  public isNew = true;

  public notes?: string;
  public negotiatedPrice?: number;

  constructor(
    private fId: string,
    private fCreatedAt: Date,
    private fUpdatedAt: Date,
    private fuseCustomSewInLabel: boolean
  ) {
    [fId, fCreatedAt, fUpdatedAt].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  public get id(): string {
    return this.fId;
  }

  public get createdAt(): Date {
    return this.fCreatedAt;
  }

  public get updatedAt(): Date {
    return this.fUpdatedAt;
  }

  public get useCustomSewInLabel(): boolean {
    return this.fuseCustomSewInLabel;
  }
}
