import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieStatusPeriod from "./plushie-status-period.model";

export default class PlushieStatusPeriodFactory
  implements ObjectBuilderInterface<PlushieStatusPeriod> {
  public buildFromJSON(data: { [key: string]: any }): PlushieStatusPeriod {
    const value = new PlushieStatusPeriod(
      data.id,
      data.plushie,
      data.status,
      data.assignDate,
      data.userId
    );

    (value as any).fFactory = data.factory;
    (value as any).fPreviousStatus = data.previousStatus;
    (value as any).fQuitDate = data.quitDate;

    value.isNew = false;

    return value;
  }
}
