import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class QaRejectionDetails implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fRejectionMessageId?: string,
    private fRejectionMessageText?: string,
    private fRejectionMessageTextTranslation?: string
  ) {
    if (fId === undefined) {
      throw new Error(`Id for RejectionDetails is not defined`);
    }
  }

  get id(): string {
    return this.fId;
  }

  get rejectionMessageId(): string | undefined {
    return this.fRejectionMessageId;
  }

  get rejectionMessageText(): string | undefined {
    return this.fRejectionMessageText;
  }

  get rejectionMessageTextTranslation(): string | undefined {
    return this.fRejectionMessageTextTranslation;
  }
}
