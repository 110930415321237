















import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import ExtraPaymentItems from "./ExtraPayment/Items.vue";
import CreateExtraPaymentItemForm from "./ExtraPayment/CreateItemForm.vue";

import FactoryInvoiceStore from "../store/index";

@Component({
  components: {
    LoadingSpinner,
    ExtraPaymentItems,
    CreateExtraPaymentItemForm,
  },
})
export default class ExtraPayments extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = false;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get canViewItems(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_EXTRA_PAYMENTS_READ
    );
  }

  get canAddItems(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_EXTRA_PAYMENTS_MANAGE
    );
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  protected async created(): Promise<void> {
    await this.fFactoryInvoiceDataStore.loadExtraPaymentFaults();
  }

  private async getPlushieData(plushieId: string) {
    await this.fFactoryInvoiceDataStore.loadExtraPaymentsByPlushieId({
      plushieId,
    });
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getPlushieData(this.plushieId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
