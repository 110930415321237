import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiService from "@/modules/api/api.service";
import isHydraCollectionResponse from "@/modules/api/is-hydra-collection-response.typeguard";

import StatusSummary from "./status-summary.model";
import isStatusSummaryApiResponseItem from "./is-status-summary-api-response-item.typeguard";
import StatusSummaryApiResponseItem from "./status-summary-api-response-item.interface";

export default class StatusSummaryRepository {
  constructor(
    protected api: ApiService,
    protected objectBuilder: ObjectBuilderInterface<
      StatusSummary,
      StatusSummaryApiResponseItem
    >
  ) {}

  public async getStatusesSummariesList(): Promise<StatusSummary[]> {
    const response = await this.api.get(
      "production_process/statuses_summaries"
    );

    const items: StatusSummary[] = [];

    if (!isHydraCollectionResponse(response.data)) {
      throw new Error("Unexpected response!");
    }

    const itemsData = response.data["hydra:member"];

    itemsData.forEach((element) => {
      if (!isStatusSummaryApiResponseItem(element)) {
        throw new Error("Unexpected response!");
      }

      items.push(this.objectBuilder.buildFromJSON(element));
    });

    return items;
  }
}
