



















































import { Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import HolidayPeriodStore from "../store";
import PlushieRelation from "../plushie-relation.model";
import HolidayPeriod from "../holiday-period.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    LoadingSpinner,
    ActionButton,
  },
})
export default class PlushieHolidayPeriodManagementWidget extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId?: string;

  public period: HolidayPeriod | undefined;

  private fHolidayPeriodDataStore: HolidayPeriodStore;

  private fIsLoading = true;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get showAssignForm(): boolean {
    if (!this.plushieId) {
      return false;
    }

    const relation = this.fHolidayPeriodDataStore.getPlushieRelationById(
      this.plushieId
    );

    return relation === undefined;
  }

  get periodOptions(): HolidayPeriod[] {
    return this.fHolidayPeriodDataStore.holidayPeriodsList;
  }

  get plushiePeriodName(): string {
    if (!this.plushieId) {
      return "";
    }

    const relation = this.fHolidayPeriodDataStore.getPlushieRelationById(
      this.plushieId
    );

    if (!relation) {
      return "";
    }

    const period = this.fHolidayPeriodDataStore.getHolidayPeriodById(
      relation.period
    );

    return period ? period.name : "";
  }

  constructor() {
    super();

    this.fHolidayPeriodDataStore = getModule(HolidayPeriodStore, dataStore);
  }

  public async unassignPlushieFromPeriod(): Promise<void> {
    if (!this.plushieId) {
      throw new Error("The Plushie ID should be set for unassign action");
    }

    const relation = this.fHolidayPeriodDataStore.getPlushieRelationById(
      this.plushieId
    );

    if (!relation) {
      throw new Error(
        "The relation is not found for Plushie and Holiday Period"
      );
    }

    await this.fHolidayPeriodDataStore.deletePlushieRelation(relation);
  }

  protected async performSubmit(): Promise<void> {
    if (!this.plushieId || !this.period) {
      throw new Error("Not all required params are specified");
    }

    await this.fHolidayPeriodDataStore.savePlushieRelation(
      new PlushieRelation(this.plushieId, this.period.id)
    );
  }

  protected created(): void {
    void this.fHolidayPeriodDataStore.loadHolidayPeriods();
  }

  protected data(): Record<string, unknown> {
    return {
      period: this.period,
    };
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (this.plushieId === undefined) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fHolidayPeriodDataStore.loadPlushieRelationById({
        id: this.plushieId,
      });
    } finally {
      this.fIsLoading = false;
    }
  }
}
