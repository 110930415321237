



































import { Component, Vue } from "vue-property-decorator";
import {
  ColumnFilterDropdownOption,
  RowClickEventData,
  TableOptions,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import rootStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import EmailsManagementStore from "../store";
import EmailLayout from "../email-layout.model";

@Component({
  metaInfo: {
    title: "Email Layouts List",
  },
})
export default class EmailLayoutsListPage extends Vue {
  private fEmailsManagementStore: EmailsManagementStore;
  private fPlushieStore: PlushieStore;

  private fTableOptions: TableOptions;

  get stores(): DirectoryValue[] {
    return this.fPlushieStore.storesList;
  }

  get tableData(): EmailLayout[] {
    return this.fEmailsManagementStore.emailLayouts;
  }

  get tableColumns(): string[] {
    return ["id", "store", "actions"];
  }

  get tableOptions(): TableOptions {
    return this.fTableOptions;
  }

  constructor() {
    super();

    this.fEmailsManagementStore = getModule(EmailsManagementStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);

    this.fTableOptions = {
      columnsClasses: {
        id: "_id-column",
      },
      filterable: ["id", "store"],
      filterByColumn: true,
      listColumns: {},
      perPage: 10,
      perPageValues: [10, 20, 30, 50, 100],
    };
  }

  public onRowClick(data: RowClickEventData<EmailLayout>): void {
    void this.$router.push({
      name: "EmailLayoutEditPage",
      query: {
        id: data.row.id,
      },
    });
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    await Promise.all([
      this.fPlushieStore.loadStores(),
      this.fEmailsManagementStore.loadEmailLayouts(),
    ]);

    this.updateListColumns();
  }

  private updateListColumns(): void {
    const storeOptions: ColumnFilterDropdownOption[] = [];

    this.stores.forEach((item) => {
      storeOptions.push({ id: item.id, text: item.name });
    });

    if (this.fTableOptions.listColumns) {
      Vue.set(this.fTableOptions.listColumns, "store", storeOptions);
    }
  }
}
