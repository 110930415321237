import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import QaRejectionDetails from "./qa-rejection-details.model";

export default class QaDecisionRejectionReasonFactory
  implements ObjectBuilderInterface<QaRejectionDetails> {
  public buildFromJSON(data: { [key: string]: any }): QaRejectionDetails {
    const value = new QaRejectionDetails(
      data.id,
      data.rejectionMessageId,
      data.rejectionMessageText,
      data.rejectionMessageTextTranslation
    );

    value.isNew = false;

    return value;
  }
}
