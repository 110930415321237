import NotFoundPage from "./components/NotFoundPage.vue";

export default [
  {
    path: "/404",
    name: "404",
    component: NotFoundPage,
  },
  {
    path: "*",
    redirect: "/404",
  },
];
