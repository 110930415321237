import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BulkProjectPlushieRelation from "./bulk-project-plushie-relation.model";

export default class BulkProjectPlushieRelationFactory
  implements ObjectBuilderInterface<BulkProjectPlushieRelation> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): BulkProjectPlushieRelation {
    const value = new BulkProjectPlushieRelation(
      data.id,
      data.bulkProject,
      data.bulkProjectPlushieType,
      data.plushie
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
