import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ActionLogRecord from "./action-log-record.model";

export default class ActionLogRecordFactory
  implements ObjectBuilderInterface<ActionLogRecord> {
  public buildFromJSON(data: { [key: string]: any }): ActionLogRecord {
    const value = new ActionLogRecord(
      data.id,
      data.creatorId,
      data.action,
      data.plushieId,
      data.shortDescription,
      data.description,
      new Date(data.createdAt)
    );

    value.isNew = false;

    return value;
  }
}
