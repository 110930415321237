














































































































































import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import { ColumnFilterDropdownOption, TableOptions } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import DatePicker from "vuejs-datepicker";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import AccountDataStore from "@/modules/account/store";
import { MetaRoleValue } from "@/modules/account/meta-role.value";
import UserInfo from "@/modules/account/user-info.model";
import PlushieStore from "@/modules/plushie/store";
import rootStore from "@/store";
import Product from "@/modules/plushie/product.model";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import ReviewerReport from "../reviewer-report.model";
import ReviewerReportRepository from "../reviewer-report.repository";
import { ReportTimePeriod } from "../report-time-period";

enum ReviewerReportState {
  INITIAL = "initial",
  INITIAL_DATA_LOADED = "initial-data-loaded",
  DATA_LOADED = "data-loaded",
}

@Component({
  components: {
    DatePicker,
    FormErrors,
    FormField,
    MoonLoader,
    SubmitButton,
  },
})
export default class ReviewerReportPage extends mixins(GeneralFormMixin) {
  @Inject("ReviewerReportRepository")
  private fReviewerReportRepository!: ReviewerReportRepository;

  public reviewerId?: string;
  public endDate?: Date;
  public productId?: string;
  public startDate?: Date;
  public timePeriod?: ReportTimePeriod;

  private fAccountDataStore!: AccountDataStore;
  private fPlushieStore!: PlushieStore;

  private fReviewerReports: ReviewerReport[] = [];
  private fReviewersList: UserInfo[] = [];
  private fState: ReviewerReportState = ReviewerReportState.INITIAL;
  private fTableOptions!: TableOptions;

  get productOptionsList(): Product[] {
    return this.fPlushieStore.activeProductsList;
  }

  get reviewerReportRepository(): ReviewerReportRepository {
    return this.fReviewerReportRepository;
  }

  get reviewersOptionsList(): Array<{ id: string; title: string }> {
    return this.fReviewersList
      .map((reviwer) => ({
        id: reviwer.id,
        title: `${reviwer.lastName}, ${reviwer.firstName}`,
      }))
      .sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  get showLoadingIndicator(): boolean {
    return this.fState === ReviewerReportState.INITIAL;
  }

  get showReportTable(): boolean {
    return this.fState === ReviewerReportState.DATA_LOADED;
  }

  get tableColumns(): string[] {
    return ["reviewer", "timePeriod", "plushiesCount"];
  }

  get tableData(): ReviewerReport[] {
    return this.fReviewerReports;
  }

  get tableOptions(): TableOptions {
    return this.fTableOptions;
  }

  get timePeriodOptionsList(): ReportTimePeriod[] {
    return Object.values(ReportTimePeriod);
  }

  constructor() {
    super();

    this.fAccountDataStore = getModule(AccountDataStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);

    this.fTableOptions = {
      columnsClasses: {
        plushiesCount: "_plushies-count",
      },
      headings: {
        inspector: "Reviewer",
        timePeriod: "Time Period",
        plushiesCount: "Count",
      },
      filterable: ["reviewer"],
      filterByColumn: true,
      listColumns: {},
      groupBy: "reviewer",
      toggleGroups: true,
      orderBy: { column: "reviewer", ascending: true },
      perPage: 20,
      perPageValues: [10, 20, 30, 50, 100],
      sortable: [],
    };
  }

  protected created(): void {
    void this.loadInitialData();
  }

  protected data(): Record<string, unknown> {
    return {
      endDate: this.endDate,
      productId: this.productId,
      reviewerId: this.reviewerId,
      startDate: this.startDate,
      timePeriod: this.timePeriod,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.timePeriod || !this.startDate) {
      throw new Error("Not all required fields are filled!");
    }

    if (this.endDate && this.startDate && this.endDate < this.startDate) {
      throw new Error("'End Date' can't be less than 'Start Date'");
    }

    let startDate;
    let endDate;

    if (this.startDate) {
      startDate = new Date(this.startDate);
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(
        startDate.getHours() - startDate.getTimezoneOffset() / 60
      );
    }

    if (this.endDate) {
      endDate = new Date(this.endDate);
      endDate.setHours(23, 59, 59, 999);
      endDate.setHours(endDate.getHours() - endDate.getTimezoneOffset() / 60);
    }

    await this.loadTableData(
      this.timePeriod,
      this.reviewerId,
      startDate,
      endDate,
      this.productId
    );
  }

  private async loadInitialData(): Promise<void> {
    await Promise.all([
      this.loadInspectorsList(),
      this.fPlushieStore.loadProducts(),
    ]);

    this.fState = ReviewerReportState.INITIAL_DATA_LOADED;
  }

  private async loadInspectorsList(): Promise<void> {
    this.fReviewersList = await this.fAccountDataStore.loadUserInfosByMetaRoleIds(
      [MetaRoleValue.ROLE_ADMIN, MetaRoleValue.ROLE_STORE]
    );
  }

  private async loadTableData(
    timePeriod: ReportTimePeriod,
    reviewerId?: string,
    startDate?: Date,
    endDate?: Date,
    productId?: string
  ): Promise<void> {
    this.fReviewerReports = await this.fReviewerReportRepository.getList(
      timePeriod,
      reviewerId,
      startDate,
      endDate,
      productId
    );

    this.updateColumnFilterOptions();

    this.fState = ReviewerReportState.DATA_LOADED;
  }

  private setColumnFilterOptions(items: Set<string>, column: string): void {
    const options: ColumnFilterDropdownOption[] = [];

    items.forEach((value: string) => {
      options.push({ id: value, text: value });
    });

    options.sort((a, b) => (a.text > b.text ? 1 : -1));

    if (this.tableOptions.listColumns) {
      Vue.set(this.tableOptions.listColumns, column, options);
    }
  }

  private updateColumnFilterOptions(): void {
    const reviewersInReport: Set<string> = new Set();

    this.fReviewerReports.forEach((item) => {
      reviewersInReport.add(item.reviewer);
    });

    this.setColumnFilterOptions(reviewersInReport, "reviewer");
  }
}
