import { Module, Mutation, VuexModule } from "vuex-module-decorators";

export const name = "ReportsStore";

interface FutureTurnaroundReportFormFieldsDefaultValues {
  inProductionAdj: number;
  inReworkAdj: number;
  qualityInspectionAdj: number;
  weeksCount: number;
}

interface ProductionEstimateReportFormFieldsDefaultValues {
  lookBackWeeks: number;
  shipmentCutoff?: Date;
}

@Module({ name })
export default class ReportsStore extends VuexModule {
  futureTurnaroundReportFormFieldsDefaultValues: FutureTurnaroundReportFormFieldsDefaultValues = {
    inProductionAdj: 0.8,
    inReworkAdj: 0.25,
    qualityInspectionAdj: 0.5,
    weeksCount: 2,
  };

  productionEstimateReportFormFieldsDefaultValues: ProductionEstimateReportFormFieldsDefaultValues = {
    lookBackWeeks: 2,
    shipmentCutoff: undefined,
  };

  // ################################### FUTURE TURNAROUND REPORT FORM FIELDS DEFAULT VALUES #########################################
  get futureTurnaroundReportWeeksCount(): number {
    return this.futureTurnaroundReportFormFieldsDefaultValues.weeksCount;
  }

  get futureTurnaroundReportInProductionAdj(): number {
    return this.futureTurnaroundReportFormFieldsDefaultValues.inProductionAdj;
  }

  get futureTurnaroundReportQualityInspectionAdj(): number {
    return this.futureTurnaroundReportFormFieldsDefaultValues
      .qualityInspectionAdj;
  }

  get futureTurnaroundReportInReworkAdj(): number {
    return this.futureTurnaroundReportFormFieldsDefaultValues.inReworkAdj;
  }

  @Mutation
  updateFutureTurnaroundReportFormFieldsDefaultValues(
    payload: FutureTurnaroundReportFormFieldsDefaultValues
  ): void {
    this.futureTurnaroundReportFormFieldsDefaultValues = payload;
  }

  // ################################### PRODUCTION ESTIMATE REPORT FORM FIELDS DEFAULT VALUES #########################################
  get productionEstimateReportLookBackWeeks(): number {
    return this.productionEstimateReportFormFieldsDefaultValues.lookBackWeeks;
  }

  get productionEstimateReportShipmentCutoff(): Date | undefined {
    return this.productionEstimateReportFormFieldsDefaultValues.shipmentCutoff;
  }

  @Mutation
  updateProductionEstimateReportFormFieldsDefaultValues(
    payload: ProductionEstimateReportFormFieldsDefaultValues
  ): void {
    this.productionEstimateReportFormFieldsDefaultValues = payload;
  }
}
