import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BulkProjectBatchInformation from "./bulk-project-batch-information.model";

export default class BulkProjectBatchInformationFactory
  implements ObjectBuilderInterface<BulkProjectBatchInformation> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): BulkProjectBatchInformation {
    const value = new BulkProjectBatchInformation(
      data.id,
      new Date(data.createdAt),
      new Date(data.createdAt),
      data.useCustomSewInLabel
    );

    value.notes = data.notes as string | undefined;
    value.negotiatedPrice = data.negotiatedPrice as number | undefined;

    value.isNew = false;

    return value;
  }
}
