















































import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import PlushieStore from "@/modules/plushie/store";
import ProductionProcessStore from "@/modules/production-process/store";
import Carrier from "@/modules/plushie/carrier.model";

@Component({
  components: {
    FormErrors,
    FormField,
    SubmitButton,
  },
})
export default class SendToCustomerCommand extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  public carrierId?: string;
  public trackingNumber?: string;

  private fPlushieStore: PlushieStore;
  private fProductionProcessStore: ProductionProcessStore;

  get carriers(): Carrier[] {
    return this.fPlushieStore.carriersList;
  }

  constructor() {
    super();
    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);
  }

  protected created(): void {
    void this.fPlushieStore.loadCarriers();
  }

  protected data(): Record<string, string | undefined> {
    return {
      carrierId: this.carrierId,
      trackingNumber: this.trackingNumber,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.trackingNumber || !this.carrierId) {
      return;
    }

    await this.fProductionProcessStore.sendToCustomer({
      plushieId: this.plushieId,
      carrierId: this.carrierId,
      trackingNumber: this.trackingNumber,
    });
  }
}
