









































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";

import LabelHeaderEditForm from "./LabelHeaderEditForm.vue";
import LabelHeaderProductsEditForm from "./LabelHeaderProductsEditForm.vue";

import LabelHeaderRepository from "../label-header.repository";
import BarcodeStore from "../store";
import LabelHeader from "../label-header.model";

@Component({
  metaInfo() {
    return {
      title: "Barcode Label Header Edit",
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    LabelHeaderEditForm,
    LabelHeaderProductsEditForm,
  },
})
export default class LabelHeaderEditPage extends Vue {
  @Prop()
  public readonly labelHeaderId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("LabelHeaderRepository")
  private fLabelHeaderRepository!: LabelHeaderRepository;

  private fBarcodeDataStore: BarcodeStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get labelHeader(): LabelHeader | undefined {
    return this.fBarcodeDataStore.getLabelHeaderById(this.labelHeaderId);
  }

  public constructor() {
    super();

    this.fBarcodeDataStore = getModule(BarcodeStore, dataStore);
  }

  public onCancel(): void {
    void this.$router.push({
      name: "LabelHeadersListPage",
      query: this.listQuery,
    });
  }

  public onLabelHeaderUpdated(): void {
    void this.$router.push({
      name: "LabelHeadersListPage",
      query: this.listQuery,
    });
  }

  public getLabelHeadersOrder(): Promise<string[]> {
    return this.fLabelHeaderRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  @Watch("labelHeaderId", { immediate: true })
  private async _onLabelHeaderIdIdChange() {
    if (this.labelHeaderId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fBarcodeDataStore.loadLabelHeaderById({
        id: this.labelHeaderId,
      });

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
