































































































import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import Factory from "@/modules/factory/factory.model";
import { Resource } from "@/modules/account/resource";

import Config from "../config.model";
import FactoryRestrictedModeStore from "../store";
import RestrictedModeStatus from "../restricted-mode-status.model";

@Component({
  metaInfo: {
    title: "Restricted Mode Management",
  },
  components: {
    ActionButton,
    FormErrors,
    LoadingSpinner,
  },
})
export default class RestrictedModeManagementPage extends Vue {
  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fErrorsList: string[] = [];
  private fFactoryDataStore: FactoryStore;
  private fFactoryRestrictedModeDataStore: FactoryRestrictedModeStore;
  private fIsLoading = false;

  get canViewConfig(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.SETTINGS_MANAGE);
  }

  get canManageFactoriesList(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_RESTRICTED_MODE_CONFIG_MANAGE
    );
  }

  get getCurrentFactoryStatus(): (factory: Factory) => string {
    return (factory: Factory) => {
      const currentStatus = this.fFactoryRestrictedModeDataStore.getFactoryRestrictedModeStatusByFactoryId(
        factory.id
      );

      if (!currentStatus) {
        return "";
      }

      return currentStatus.status === RestrictedModeStatus.NORMAL
        ? "Normal"
        : "Restricted";
    };
  }

  get shouldShowLinkToBlockingPlushiesForFactory(): (
    factory: Factory
  ) => boolean {
    return (factory: Factory) => {
      const currentStatus = this.fFactoryRestrictedModeDataStore.getFactoryRestrictedModeStatusByFactoryId(
        factory.id
      );

      if (!currentStatus) {
        return false;
      }

      return currentStatus.status === RestrictedModeStatus.RESTRICTED;
    };
  }

  get enabledFactories(): Factory[] {
    const result: Factory[] = [];

    const factoriesList = this.fFactoryDataStore.activeFactoriesList;

    factoriesList.forEach((item) => {
      const config = this.fFactoryRestrictedModeDataStore
        .factoryRestrictedModeConfigs[item.id];

      if (!config || !config.isEnabled) {
        return;
      }

      result.push(item);
    });

    return result;
  }

  get errorsList(): string[] {
    return this.fErrorsList;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get notEnabledFactories(): Factory[] {
    const result: Factory[] = [];

    const factoriesList = this.fFactoryDataStore.activeFactoriesList;

    factoriesList.forEach((item) => {
      const config = this.fFactoryRestrictedModeDataStore
        .factoryRestrictedModeConfigs[item.id];

      if (!config || !config.isEnabled) {
        result.push(item);
        return;
      }
    });

    return result;
  }

  public constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fFactoryRestrictedModeDataStore = getModule(
      FactoryRestrictedModeStore,
      dataStore
    );
  }

  public async disableItem(factory: Factory): Promise<void> {
    this.fErrorsList = [];

    try {
      await this.fFactoryRestrictedModeDataStore.saveFactoryRestrictedModeConfig(
        new Config(factory.id, false)
      );
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      this.fErrorsList.push(...errors);
    }
  }

  public async enableItem(factory: Factory): Promise<void> {
    this.fErrorsList = [];

    try {
      await this.fFactoryRestrictedModeDataStore.saveFactoryRestrictedModeConfig(
        new Config(factory.id, true)
      );
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      this.fErrorsList.push(...errors);
    }
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    const factories = await this.fFactoryDataStore.loadFactories();

    const factoryIds = factories.map((factory) => factory.id);

    try {
      await Promise.all([
        this.fFactoryRestrictedModeDataStore.loadFactoryRestrictedModeConfigs(),
        this.fFactoryRestrictedModeDataStore.loadFactoryRestrictedModeStatusesByFactoryIds(
          { factoryIds }
        ),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
