

































































































import "v-autocomplete/dist/v-autocomplete.css";

import { Component, Prop, Watch, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import Autocomplete from "v-autocomplete/src/Autocomplete.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import PlushieStore from "@/modules/plushie/store";

import AutocompleteItemTpl from "./AddExtraPaymentForm/PlushieAutocompleteItem.vue";

import ExtraPayment from "../../extra-payment.model";
import FactoryInvoiceStore from "../../store";
import { ExtraPaymentFaultValue } from "../../extra-payment-fault.value";

interface PlushieItemAutocompleteOption {
  text: string;
  value: string;
}

@Component({
  components: {
    Autocomplete,
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class FactoryInvoiceAddExtraPaymentForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly invoiceId?: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public plushie?: PlushieItemAutocompleteOption;
  public description?: string;
  public amount?: number;
  public fault?: ExtraPaymentFaultValue;

  private fIsAutocompleteDataLoaded = false;
  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fPlushieDataStore: PlushieStore;

  private fItemOptions: PlushieItemAutocompleteOption[] = [];

  get itemOptions(): PlushieItemAutocompleteOption[] {
    return this.fItemOptions;
  }

  get showFaultOptions(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
    ].includes(user.role);
  }

  get faultOptions(): DirectoryValue[] {
    const values = [...this.fFactoryInvoiceDataStore.extraPaymentFaults];
    values.push(new DirectoryValue("", "No one's"));

    return values;
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async searchItems(term: string): Promise<void> {
    if (!this.invoiceId) {
      return;
    }

    if (!this.fIsAutocompleteDataLoaded) {
      await this.loadAutocompleteData();
    }

    const searchDictionary: PlushieItemAutocompleteOption[] = [];

    const items = this.fFactoryInvoiceDataStore.getInvoiceItemsByInvoiceId(
      this.invoiceId
    );

    items.forEach((item) => {
      const plushie = this.fPlushieDataStore.getPlushieById(item.plushie);

      if (!plushie) {
        return;
      }

      if (!plushie.storeItemId.startsWith(term)) {
        return;
      }

      searchDictionary.push({ text: plushie.storeItemId, value: plushie.id });
    });

    this.fItemOptions = searchDictionary;
  }

  protected created(): void {
    void this.fFactoryInvoiceDataStore.loadExtraPaymentFaults();
  }

  protected data(): Record<string, unknown> {
    return {
      plushie: this.plushie,
      description: this.description,
      amount: this.amount,
      fault: this.fault,
      AutocompleteItemTpl: AutocompleteItemTpl,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.description || !this.amount) {
      return;
    }

    let payment = new ExtraPayment(
      this.fIdGenerator.getId(),
      +this.amount,
      this.description
    );

    payment.invoice = this.invoiceId;

    if (this.plushie) {
      payment.plushie = this.plushie.value;
    }

    if (this.fault) {
      payment.fault = this.fault;
    }

    payment = await this.fFactoryInvoiceDataStore.createExtraPayment(payment);

    this.resetForm();

    setTimeout(() => {
      const amountInput = this.getAmountInput();

      if (!amountInput) {
        return;
      }

      amountInput.focus();
    }, 0);
  }

  private getAmountInput(): HTMLInputElement | undefined {
    return this.$refs["amount-input"] as HTMLInputElement | undefined;
  }

  private async loadAutocompleteData() {
    if (!this.invoiceId) {
      return;
    }

    const items = await this.fFactoryInvoiceDataStore.loadInvoiceItemsByInvoiceId(
      { invoiceId: this.invoiceId }
    );

    const plushiesIds = items.map((item) => item.plushie);

    await this.fPlushieDataStore.loadPlushiesByIds(plushiesIds);

    this.fIsAutocompleteDataLoaded = true;
  }

  private resetForm() {
    this.plushie = undefined;
    this.description = undefined;
    this.amount = undefined;
    this.fault = undefined;
  }

  @Watch("invoiceId", { immediate: true })
  private _onInvoiceIdChange() {
    this.fIsAutocompleteDataLoaded = false;
    this.resetForm();
  }
}
