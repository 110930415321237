export default class StatusSummary {
  constructor(private fStatusId: string, private fPlushiesQty: number) {
    [fStatusId, fPlushiesQty].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get statusId(): string {
    return this.fStatusId;
  }

  get plushiesQty(): number {
    return this.fPlushiesQty;
  }
}
