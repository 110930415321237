
















import { Component, Prop, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";

import { Dictionary } from "@/lib/Dictionary.type";

import QuestionTemplateEditForm from "./QuestionTemplateEditForm.vue";

import QuestionTemplate from "../../question-template.model";

@Component({
  metaInfo() {
    return {
      title: "Add Question Template",
    };
  },
  components: {
    QuestionTemplateEditForm,
  },
})
export default class QuestionTemplateAddPage extends Vue {
  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Prop()
  public readonly query!: Record<string, unknown>;

  public onCancel(): void {
    void this.$router.push({
      name: "QuestionTemplatesListPage",
      query: this.listQuery,
    });
  }

  public onSuccess(questionTemplate: QuestionTemplate): void {
    const editPageUrl = this.generateItemLink(
      questionTemplate.id,
      this.query,
      this.listQuery
    );

    void this.$router.push(editPageUrl);
  }

  private generateItemLink(
    id: string,
    query: Record<string, unknown>,
    listQuery?: Record<string, unknown>
  ): RawLocation {
    return {
      name: "QuestionTemplateEditPage",
      query: {
        id,
        query: JSON.stringify(query),
        listQuery: JSON.stringify(listQuery),
      },
    };
  }
}
