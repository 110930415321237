










import { Component, Vue } from "vue-property-decorator";

import ExtraPaymentsReport from "./Report.vue";

@Component({
  components: {
    ExtraPaymentsReport,
  },
  metaInfo: {
    title: "Extra Payments Report",
  },
})
export default class ExtraPaymentsReportPage extends Vue {}
