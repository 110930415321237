






































import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import Autocomplete from "v-autocomplete";
import "v-autocomplete/dist/v-autocomplete.css";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import TagRepository from "@/modules/tag/tag.repository";
import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import AlertStore from "../../store";
import TagAlertAssignRule from "../../tag-alert-assign-rule.model";

@Component({
  components: {
    Autocomplete,
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class PlushieTagAlertAssignRulesAddItem extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly alertId!: string;

  @Prop({ default: [] })
  public readonly assignedTags!: string[];

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("TagRepository")
  private fTagRepository!: TagRepository;

  public tag?: string;

  private fTagOptions: string[] = [];
  private fAlertStore: AlertStore;

  get tagOptions() {
    return this.fTagOptions;
  }

  constructor() {
    super();

    this.fAlertStore = getModule(AlertStore, dataStore);
  }

  public async searchTags(term: string): Promise<void> {
    this.fTagOptions = (await this.fTagRepository.searchTags(term))
      .filter((tag) => !this.assignedTags.includes(tag.name))
      .map((tag) => tag.name);
  }

  public onTagInput(value: string) {
    this.tag = value;
  }

  protected data() {
    return {
      tag: this.tag,
    };
  }

  protected async performSubmit() {
    if (this.tag && this.assignedTags.includes(this.tag)) {
      throw new Error("Tag is already assigned!");
    }

    const rule = this.generateRuleObject();

    await this.fAlertStore.savePlushieTagAlertAssignRule(rule);

    this.tag = undefined;
  }

  private generateRuleObject(): TagAlertAssignRule {
    if (!this.tag) {
      throw new Error("Tag name should be defined!");
    }

    const value = new TagAlertAssignRule(
      this.fIdGenerator.getId(),
      this.tag,
      this.alertId
    );

    return value;
  }
}
