import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PlushieAttachment from "./plushie-attachment.model";

export default class PlushieAttachmentRepository extends BasicRepository<PlushieAttachment> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieAttachment>,
    responseParser: ApiCollectionResponseParserService<PlushieAttachment>
  ) {
    super("plushie_attachments", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(plushieId: string): Promise<PlushieAttachment[]> {
    const collection = await this.fetchList({ plushie: plushieId });

    return collection.getItems();
  }
}
