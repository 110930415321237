import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import ProductAllocation from "./product-allocation.model";
import { ResourceCollection } from "../api/resource.collection";

export default class ProductAllocationRepository extends BasicRepository<ProductAllocation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ProductAllocation>,
    responseParser: ApiCollectionResponseParserService<ProductAllocation>
  ) {
    super("product_allocations", api, objectBuilder, responseParser);
  }

  public async saveItems(
    items: ProductAllocation[]
  ): Promise<ResourceCollection<ProductAllocation>> {
    const data: Dictionary<string | number>[] = [];

    items.forEach((item) => {
      data.push(this.prepareData(item));
    });

    const response = await this.api.post(this.resourceName, data);

    return this.responseParser.parse(response.data, this.objectBuilder);
  }

  protected prepareData(item: ProductAllocation): Dictionary<string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      factory: item.factory,
      product: item.product,
      distributionPercent: item.distributionPercent,
      slotType: item.slotType,
    };

    return data;
  }
}
