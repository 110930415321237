import { Route } from "vue-router";

import EditMarkupPage from "@/modules/photo-markup/components/EditMarkupPage.vue";

export default [
  {
    path: "/photo_markup/edit",
    name: "EditMarkupPage",
    component: EditMarkupPage,
    props: (route: Route) => {
      return {
        plushieId: route.query.plushieId,
        imageId: route.query.imageId,
        entityTypeId: route.query.entityTypeId,
        returnUrl: route.query.returnUrl,
      };
    },
    meta: {
      auth: true,
    },
  },
];
