











































































































import { Component, Prop, Watch, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import { DirectoryValue } from "@/modules/api/directory-value.model";
import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import FactoryInvoiceStore from "../../store";
import ExtraPayment from "../../extra-payment.model";
import { ExtraPaymentFaultValue } from "../../extra-payment-fault.value";

@Component({
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class CreateExtraPaymentItemForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId?: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public description?: string;
  public amount?: number;
  public fault?: ExtraPaymentFaultValue;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fSelectedAmountOption?: string;

  get amountOptions(): number[] {
    return [5, 10, 15];
  }

  get faultOptions(): DirectoryValue[] {
    const values = [...this.fFactoryInvoiceDataStore.extraPaymentFaults];
    values.push(new DirectoryValue("", "No one's"));

    return values;
  }

  get isCustomAmountEnabled(): boolean {
    return this.fSelectedAmountOption === "custom";
  }

  get selectedAmountOption(): string | undefined {
    return this.fSelectedAmountOption;
  }

  set selectedAmountOption(value: string | undefined) {
    this.fSelectedAmountOption = value;

    if (!value || isNaN(+value)) {
      this.amount = undefined;
      return;
    }

    this.amount = +value;
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  protected data(): Record<string, unknown> {
    return {
      description: this.description,
      amount: this.amount,
      fault: this.fault,
      fSelectedAmountOption: this.fSelectedAmountOption,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.description || !this.amount) {
      return;
    }

    let payment = new ExtraPayment(
      this.fIdGenerator.getId(),
      +this.amount,
      this.description
    );

    payment.plushie = this.plushieId;

    if (this.fault) {
      payment.fault = this.fault;
    }

    payment = await this.fFactoryInvoiceDataStore.createExtraPayment(payment);

    this.description = undefined;
    this.amount = undefined;
    this.fault = undefined;
    this.fSelectedAmountOption = undefined;

    this.$emit("updated");
  }

  @Watch("plushieId", { immediate: true })
  private _onPlushieIdChange() {
    this.description = undefined;
    this.amount = undefined;
    this.fault = undefined;
    this.fSelectedAmountOption = undefined;
  }
}
