import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import UpgradeAllocation from "./upgrade-allocation.model";

export default class UpgradeAllocationFactory
  implements ObjectBuilderInterface<UpgradeAllocation> {
  public buildFromJSON(data: { [key: string]: any }): UpgradeAllocation {
    const value = new UpgradeAllocation(
      data.id,
      data.factoryId,
      data.upgradeId,
      data.canProduce
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
