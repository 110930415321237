




























































import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import PlushieStore from "@/modules/plushie/store";
import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import ProductSettingEditItem from "./EditItem.vue";

import ProductSetting from "../../product-setting.model";
import HolidayPeriodStore from "../../store";

interface EditItem {
  value?: ProductSetting;
  id: string;
  isNew: boolean;
  isDirty: boolean;
}

@Component({
  components: {
    FormErrors,
    LoadingSpinner,
    ProductSettingEditItem,
    SubmitButton,
  },
})
export default class ProductSettingEditForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly periodId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fIsLoading = false;

  private fHolidayPeriodDataStore: HolidayPeriodStore;
  private fPlushieDataStore: PlushieStore;

  private fSettingsItems: EditItem[] = [];

  get existingProducts(): string[] {
    return this.settingsItems
      .map((item) => item.value)
      .filter((item): item is ProductSetting => item !== undefined)
      .map((item) => item.product);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get settingsItems(): EditItem[] {
    return this.fSettingsItems;
  }

  constructor() {
    super();
    this.fHolidayPeriodDataStore = getModule(HolidayPeriodStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async addItem(): Promise<void> {
    const isValid = await this.$validator.validateAll();

    if (!isValid) {
      this.processValidationErrors(this.$validator.errors);

      return;
    }

    const id = this.fIdGenerator.getId();

    this.fSettingsItems.push({
      value: undefined,
      id: id,
      isDirty: false,
      isNew: true,
    });
  }

  public onItemChange(value: ProductSetting | undefined, index: number): void {
    const item = this.fSettingsItems[index];
    item.value = value;
    item.isDirty = true;
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  public onItemDelete(item: EditItem): void {
    const index = this.fSettingsItems.indexOf(item);
    this.fSettingsItems.splice(index, 1);
  }

  protected async performSubmit(): Promise<void> {
    const values = this.fSettingsItems
      .map((item) => item.value)
      .filter((item): item is ProductSetting => item !== undefined);

    const items = await this.fHolidayPeriodDataStore.saveProductSettingsForPeriod(
      {
        periodId: this.periodId,
        values,
      }
    );

    this.fSettingsItems = this.createEditItems(items);

    this.$emit("updated");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      const [settings] = await Promise.all([
        this.fHolidayPeriodDataStore.loadProductSettingsByPeriodId({
          periodId: this.periodId,
          useCache: true,
        }),
        this.fPlushieDataStore.loadProducts(),
      ]);

      this.fSettingsItems = this.createEditItems(settings);
    } finally {
      this.fIsLoading = false;
    }
  }

  private createEditItems(values: ProductSetting[]): EditItem[] {
    const settingsItems: EditItem[] = [];

    const products = this.fPlushieDataStore.activeProductsList;

    products.forEach((product) => {
      const item = values.find((value) => value.product === product.id);

      if (!item) {
        return;
      }

      settingsItems.push({
        value: item,
        id: item.id,
        isNew: false,
        isDirty: false,
      });
    });

    return settingsItems;
  }
}
