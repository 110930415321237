export default class WarningInfo {
  constructor(private fPlushies: string[], private fDaysBeforeBlock: number) {
    [fPlushies, fDaysBeforeBlock].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get plushies(): string[] {
    return this.fPlushies;
  }

  get daysBeforeBlock(): number {
    return this.fDaysBeforeBlock;
  }
}
