export default class FutureTurnaround {
  constructor(
    private fStoreName: string,
    private fProductName: string,
    private fFactoryName: string,
    private fDistributionPercent: number | null,
    private fWeeklyProduction: number | null,
    private fInReview: number | null,
    private fInDesign: number | null,
    private fReadyForProduction: number | null,
    private fCustomerPreview: number | null,
    private fCustomerPreviewApproved: number | null,
    private fInProduction: number | null,
    private fInInspection: number | null,
    private fInRework: number | null,
    private fTotal: number | null,
    private fTurnaround: number | null
  ) {
    [
      fStoreName,
      fProductName,
      fFactoryName,
      fDistributionPercent,
      fWeeklyProduction,
      fInReview,
      fInDesign,
      fReadyForProduction,
      fCustomerPreview,
      fCustomerPreviewApproved,
      fInProduction,
      fInRework,
      fTotal,
      fTurnaround,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get storeName(): string {
    return this.fStoreName;
  }

  get productName(): string {
    return this.fProductName;
  }

  get factoryName(): string {
    return this.fFactoryName;
  }

  get distributionPercent(): number | null {
    return this.fDistributionPercent;
  }

  get weeklyProduction(): number | null {
    return this.fWeeklyProduction;
  }

  get inReview(): number | null {
    return this.fInReview;
  }

  get inDesign(): number | null {
    return this.fInDesign;
  }

  get readyForProduction(): number | null {
    return this.fReadyForProduction;
  }

  get customerPreview(): number | null {
    return this.fCustomerPreview;
  }

  get customerPreviewApproved(): number | null {
    return this.fCustomerPreviewApproved;
  }

  get inProduction(): number | null {
    return this.fInProduction;
  }

  get inInspection(): number | null {
    return this.fInInspection;
  }

  get inRework(): number | null {
    return this.fInRework;
  }

  get total(): number | null {
    return this.fTotal;
  }

  get turnaround(): number | null {
    return this.fTurnaround;
  }
}
