import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import Plushie from "@/modules/plushie/plushie.model";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import FactoryPlushieStatusCapacity from "../factory-plushie-status-capacity.model";
import { FactoryPlushieStatusCapacityUpdate } from "../factory-plushie-status-capacity-update.interface";
import FactoryStrictCapacityMode from "../factory-strict-capacity-mode.model";
import FactoryPlushieStatusQty from "../factory-plushie-status-qty.model";

const name = "StrictCapacityModeStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    factoryStrictCapacityMode: {},
    factoryPlushieStatusCapacity: {},
    factoryPlushieStatusQty: {},
    factoryCapacities: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class StrictCapacityModeStore extends VuexModule {
  factoryStrictCapacityMode: Dictionary<boolean> = {};
  factoryPlushieStatusCapacity: Dictionary<FactoryPlushieStatusCapacity> = {};
  factoryPlushieStatusQty: Dictionary<FactoryPlushieStatusQty> = {};

  factoryCapacities: Dictionary<string[]> = {};
  factoryQties: Dictionary<string[]> = {};

  // ################################### FACTORY STRICT CAPACITY MODES #########################################
  get getFactoryStrictCapacityModeById(): (id: string) => boolean {
    return (id: string) => {
      return this.factoryStrictCapacityMode[id];
    };
  }

  @Mutation
  updateFactoryStrictCapacityMode({
    id,
    value,
  }: {
    id: string;
    value: boolean;
  }): void {
    Vue.set(this.factoryStrictCapacityMode, id, value);
  }

  @Action({ rawError: true })
  async disableFactoryStrictCapacityModeById(id: string): Promise<void> {
    await DIContainer.FactoryStrictCapacityModeRepository.deleteById(id);

    this.updateFactoryStrictCapacityMode({ id, value: false });

    return;
  }

  @Action({ rawError: true })
  async enableFactoryStrictCapacityModeById(id: string): Promise<void> {
    const mode = new FactoryStrictCapacityMode(id);

    await DIContainer.FactoryStrictCapacityModeRepository.save(mode);

    this.updateFactoryStrictCapacityMode({ id, value: true });

    return;
  }

  @Action({ rawError: true })
  async loadFactoryStrictCapacityModeById(id: string): Promise<boolean> {
    if (id in this.factoryStrictCapacityMode) {
      return this.factoryStrictCapacityMode[id];
    }

    const items = await DIContainer.FactoryStrictCapacityModeRepository.getByIds(
      [id]
    );

    const item = items[id];

    const isEnabled = item != undefined;

    this.updateFactoryStrictCapacityMode({ id, value: isEnabled });

    return isEnabled;
  }

  @Action({ rawError: true })
  async loadFactoryStrictCapacityModeByIds({
    ids,
    useCache = true,
  }: {
    ids: string[];
    useCache?: boolean;
  }): Promise<Dictionary<boolean>> {
    const missing: string[] = [];
    const result: Dictionary<boolean> = {};

    ids.forEach((id) => {
      if (useCache && this.factoryStrictCapacityMode[id]) {
        result[id] = this.factoryStrictCapacityMode[id];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.FactoryStrictCapacityModeRepository.getByIds(
      missing
    );

    missing.forEach((id) => {
      const isEnabled = items[id] !== undefined;

      this.updateFactoryStrictCapacityMode({ id, value: isEnabled });

      result[id] = isEnabled;
    });

    return { ...result };
  }

  // ################################### FACTORY PLUSHIE STATUS CAPACITIES #########################################
  get getFactoryPlushieStatusCapacityById(): (
    id: string
  ) => FactoryPlushieStatusCapacity | undefined {
    return (id: string) => this.factoryPlushieStatusCapacity[id];
  }

  get getFactoryPlushieStatusCapacitiesByFactoryId(): (
    factoryId: string
  ) => FactoryPlushieStatusCapacity[] {
    return (factoryId: string) => {
      if (this.factoryCapacities[factoryId] == null) {
        return [];
      }

      const ids = this.factoryCapacities[factoryId];

      const result: FactoryPlushieStatusCapacity[] = [];

      ids.forEach((id) => {
        result.push(this.factoryPlushieStatusCapacity[id]);
      });

      return result;
    };
  }

  get getFactoryPlushieStatusCapacityForStatus(): (
    factoryId: string,
    statusId: PlushieStatusValue
  ) => FactoryPlushieStatusCapacity | undefined {
    return (factoryId: string, statusId: string) => {
      return Object.values(this.factoryPlushieStatusCapacity)
        .filter(
          (value) =>
            value.factory === factoryId && value.plushieStatus == statusId
        )
        .shift();
    };
  }

  @Mutation
  updateFactoryPlushieStatusCapacities({
    factoryId,
    capacities,
  }: {
    factoryId: string;
    capacities: FactoryPlushieStatusCapacity[];
  }): void {
    capacities.forEach((capacity) => {
      if (capacity.factory !== factoryId) {
        throw new Error(
          "All capacities should belong to the specified factory!"
        );
      }
    });

    const capacityIds: string[] = [];

    capacities.forEach((capacity) => {
      capacityIds.push(capacity.id);
      Vue.set(this.factoryPlushieStatusCapacity, capacity.id, capacity);
    });

    Vue.set(this.factoryCapacities, factoryId, capacityIds);
  }

  @Action({ rawError: true })
  async loadFactoryPlushieStatusCapacitiesByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<FactoryPlushieStatusCapacity[]> {
    if (useCache && this.factoryCapacities[factoryId]) {
      const ids = this.factoryCapacities[factoryId];

      const result: FactoryPlushieStatusCapacity[] = [];

      ids.forEach((id) => {
        result.push(this.factoryPlushieStatusCapacity[id]);
      });

      return result;
    }

    const collection = await DIContainer.FactoryPlushieStatusCapacityRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryPlushieStatusCapacities({
      factoryId,
      capacities: items,
    });

    return items;
  }

  @Action({ rawError: true })
  async createFactoryPlushieStatusCapacitiesUpdateRequest({
    factoryId,
    capacityUpdates,
  }: {
    factoryId: string;
    capacityUpdates: FactoryPlushieStatusCapacityUpdate[];
  }): Promise<void> {
    const collection = await DIContainer.FactoryPlushieStatusCapacityRepository.createUpdateRequest(
      factoryId,
      capacityUpdates
    );

    this.updateFactoryPlushieStatusCapacities({
      factoryId,
      capacities: collection.getItems(),
    });
  }

  // ################################### FACTORY PLUSHIE STATUS QTIES #########################################
  get getFactoryPlushieStatusQtyForStatus(): (
    factoryId: string,
    statusId: PlushieStatusValue
  ) => FactoryPlushieStatusQty | undefined {
    return (factoryId: string, statusId: string) => {
      return Object.values(this.factoryPlushieStatusQty)
        .filter(
          (value) =>
            value.factory === factoryId && value.plushieStatus == statusId
        )
        .shift();
    };
  }

  @Mutation
  updateFactoryPlushieStatusQties({
    factoryId,
    qties,
  }: {
    factoryId: string;
    qties: FactoryPlushieStatusQty[];
  }): void {
    qties.forEach((qty) => {
      if (qty.factory !== factoryId) {
        throw new Error("All qties should belong to the specified factory!");
      }
    });

    const qtyIds: string[] = [];

    qties.forEach((qty) => {
      qtyIds.push(qty.id);
      Vue.set(this.factoryPlushieStatusQty, qty.id, qty);
    });

    Vue.set(this.factoryQties, factoryId, qtyIds);
  }

  @Action({ rawError: true })
  async loadFactoryPlushieStatusQtiesByFactoryId({
    factoryId,
    useCache = true,
  }: {
    factoryId: string;
    useCache?: boolean;
  }): Promise<FactoryPlushieStatusQty[]> {
    if (useCache && this.factoryQties[factoryId]) {
      const ids = this.factoryQties[factoryId];

      const result: FactoryPlushieStatusQty[] = [];

      ids.forEach((id) => {
        result.push(this.factoryPlushieStatusQty[id]);
      });

      return result;
    }

    const collection = await DIContainer.FactoryPlushieStatusQtyRepository.getByFactoryId(
      factoryId
    );

    const items = collection.getItems();

    this.updateFactoryPlushieStatusQties({
      factoryId,
      qties: items,
    });

    return items;
  }

  // ################################### IN DESIGN TRANSFER ORDER #########################################

  @Action({ rawError: true })
  async createInDesignTransferOrder(count: number): Promise<Plushie[]> {
    const collection = await DIContainer.PlushieQueueControlRepository.createInDesignTransferOrder(
      count
    );

    const items = collection.getItems();

    const dispatchPromises: Promise<void>[] = [];

    items.forEach((item) => {
      dispatchPromises.push(this.context.dispatch("setPlushie", item));
    });

    await Promise.all(dispatchPromises);

    return items;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
