



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import ProgressBarStep from "../../progress-bar-step.interface";

@Component
export default class PerformanceReportProgressBar extends Vue {
  @Prop({ required: true })
  public readonly footerText!: string;

  @Prop({ required: true })
  public readonly title!: string;

  @Prop({ required: true })
  public readonly steps!: ProgressBarStep[];

  @Prop({ required: true })
  public readonly value!: number;
}
