import DashboardPage from "./components/DashboardPage.vue";

export default [
  {
    path: "/",
    redirect: "/admin/dashboard",
    meta: {
      auth: true,
    },
  },
  {
    path: "/admin/dashboard",
    name: "DashboardPage",
    component: DashboardPage,
    meta: {
      auth: true,
    },
  },
];
