import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { ProductValue } from "@/modules/plushie/product.value";

import Attachment from "../attachment.model";
import Message from "../message.model";
import MessageCreate from "../message-create.interface";
import MessageUpdate from "../message-update.interface";
import QuestionTemplate from "../question-template.model";
import QuestionTemplateProductRelation from "../question-template-product-relation.model";
import QuestionTemplateStatusRelation from "../question-template-status-relation.model";
import UserGroupRelation from "../user-group-relation.model";

const name = "MessagingStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    attachment: {},
    message: {},
    questionTemplate: {},
    questionTemplateStatusRelation: {},
    questionTemplateProductRelation: {},
    userGroupRelation: {},
    messageAttachments: {},
    plushieMessages: {},
    unreadMessages: [],
    statusRelations: [],
    userGroupRelationByUser: [],
    templateRelations: [],
    fLoadPromises: {
      loadUnreadMessages: undefined,
      loadUserGroupRelationByUserId: {},
    },
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class MessagingStore extends VuexModule {
  attachment: Dictionary<Attachment> = {};
  message: Dictionary<Message> = {};
  questionTemplate: Dictionary<QuestionTemplate> = {};
  questionTemplateProductRelation: Dictionary<QuestionTemplateProductRelation> = {};
  questionTemplateStatusRelation: Dictionary<QuestionTemplateStatusRelation> = {};

  userGroupRelation: Dictionary<UserGroupRelation> = {};

  messageAttachments: Dictionary<string[]> = {};
  plushieMessages: Dictionary<string[]> = {};
  unreadMessages: string[] = [];
  productRelations: Dictionary<string[]> = {};
  statusRelations: Dictionary<string[]> = {};
  userGroupRelationByUser: Dictionary<string> = {};
  templateStatusRelations: Dictionary<string[]> = {};
  templateProductRelations: Dictionary<string[]> = {};

  private fLoadPromises: {
    loadUnreadMessages?: Promise<Message[]>;
    loadUserGroupRelationByUserId: Dictionary<
      Promise<UserGroupRelation | undefined>
    >;
  } = {
    loadUnreadMessages: undefined,
    loadUserGroupRelationByUserId: {},
  };

  // ################################### ATTACHMENTS #########################################

  get getAttachmentById(): (id: string) => Attachment | undefined {
    return (id: string) => this.attachment[id];
  }

  get getAttachmentsByMessageId(): (messageId: string) => Attachment[] {
    return (messageId: string) => {
      const attachmentIds = this.messageAttachments[messageId];

      if (!attachmentIds) {
        return [];
      }

      const result: Attachment[] = [];

      attachmentIds.forEach((id) => {
        const attachment = this.attachment[id];

        if (!attachment) {
          return;
        }

        result.push(this.attachment[id]);
      });

      result.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

      return result;
    };
  }

  @Mutation
  updateAttachment(payload: Attachment): void {
    Vue.set(this.attachment, payload.id, payload);

    if (this.messageAttachments[payload.message] === undefined) {
      return;
    }

    if (!this.messageAttachments[payload.message].includes(payload.id)) {
      this.messageAttachments[payload.message].push(payload.id);
    }
  }

  @Mutation
  updateMessageAttachments({
    messageId,
    attachments,
  }: {
    messageId: string;
    attachments: Attachment[];
  }): void {
    attachments.forEach((attachment) => {
      if (attachment.message !== messageId) {
        throw new Error(
          "All attachments should belong to the specified message!"
        );
      }
    });

    const attachmentIds: string[] = [];

    attachments.forEach((attachment) => {
      attachmentIds.push(attachment.id);

      Vue.set(this.attachment, attachment.id, attachment);
    });

    Vue.set(this.messageAttachments, messageId, attachmentIds);
  }

  @Action({ rawError: true })
  async createAttachment({
    messageId,
    storageItemId,
    mime,
  }: {
    messageId: string;
    storageItemId: string;
    mime: string;
  }): Promise<Attachment> {
    const item = await DIContainer.AttachmentRepository.createAttachment(
      messageId,
      storageItemId,
      mime
    );

    this.updateAttachment(item);

    return item;
  }

  @Action({ rawError: true })
  async loadAttachmentsByMessageIds({
    messageIds,
    useCache = true,
  }: {
    messageIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<Attachment[]>> {
    const missing: string[] = [];
    const result: Dictionary<Attachment[]> = {};

    messageIds.forEach((messageId) => {
      if (useCache && this.messageAttachments[messageId]) {
        result[messageId] = this.getAttachmentsByMessageId(messageId);
        return;
      }

      missing.push(messageId);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.AttachmentRepository.getByMessageIds(
      missing
    );

    const messageAttachments: Dictionary<Attachment[]> = {};

    items.forEach((item) => {
      if (!messageAttachments[item.message]) {
        messageAttachments[item.message] = [];
      }

      messageAttachments[item.message].push(item);
    });

    for (const messageId in messageAttachments) {
      const attachments = messageAttachments[messageId];

      if (!attachments) {
        continue;
      }

      this.updateMessageAttachments({ messageId, attachments });
    }

    return { ...result, ...messageAttachments };
  }

  @Action({ rawError: true })
  async copyAttachmentToArtwork({
    attachmentId,
    plushieId,
  }: {
    attachmentId: string;
    plushieId: string;
  }): Promise<string> {
    const artworkId = await DIContainer.CopyAttachmentToArtworkRequestRepository.create(
      attachmentId
    );

    await this.context.dispatch("refreshPlushie", plushieId);
    await this.context.dispatch("loadPlushieImagesByIds", { ids: [artworkId] });

    return artworkId;
  }

  // ################################### MESSAGES #########################################

  get getMessageById(): (id: string) => Message | undefined {
    return (id: string) => this.message[id];
  }

  get getMessagesByPlushieId(): (plushieId: string) => Message[] {
    return (plushieId: string) => {
      const messageIds = this.plushieMessages[plushieId];

      if (!messageIds) {
        return [];
      }

      const result: Message[] = [];

      messageIds.forEach((id) => {
        const message = this.message[id];

        if (!message) {
          return;
        }

        result.push(this.message[id]);
      });

      result.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

      return result;
    };
  }

  get unreadMessagesList(): Message[] {
    const result: Message[] = [];

    this.unreadMessages.forEach((messageId) => {
      result.push(this.message[messageId]);
    });

    result.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return result;
  }

  @Mutation
  removeMessage(payload: Message): void {
    const plushieRelations = this.plushieMessages[payload.plushie];
    let index = -1;

    if (plushieRelations) {
      index = plushieRelations.indexOf(payload.id);
    }

    if (index !== -1) {
      plushieRelations.splice(index, 1);
    }

    if (this.messageAttachments[payload.id]) {
      this.messageAttachments[payload.id].forEach((attachmentId) => {
        delete this.attachment[attachmentId];
      });

      delete this.messageAttachments[payload.id];
    }

    index = this.unreadMessages.indexOf(payload.id);
    if (index !== -1) {
      this.unreadMessages.splice(index, 1);
    }

    delete this.message[payload.id];
  }

  @Mutation
  updateMessage(payload: Message): void {
    Vue.set(this.message, payload.id, payload);

    if (
      Array.isArray(this.plushieMessages[payload.plushie]) &&
      !this.plushieMessages[payload.plushie].includes(payload.id)
    ) {
      this.plushieMessages[payload.plushie].push(payload.id);
    }

    if (payload.isRead && this.unreadMessages.includes(payload.id)) {
      this.unreadMessages.splice(this.unreadMessages.indexOf(payload.id), 1);
    }
  }

  @Mutation
  updateMessages({
    plushieId,
    messages,
  }: {
    plushieId: string;
    messages: Message[];
  }): void {
    messages.forEach((message) => {
      if (message.plushie !== plushieId) {
        throw new Error("All messages should belong to the specified plushie!");
      }
    });

    const plushieMessages: string[] = [];

    messages.forEach((message) => {
      plushieMessages.push(message.id);

      Vue.set(this.message, message.id, message);

      if (message.isRead && this.unreadMessages.includes(message.id)) {
        this.unreadMessages.splice(this.unreadMessages.indexOf(message.id), 1);
      }
    });

    Vue.set(this.plushieMessages, plushieId, plushieMessages);
  }

  @Mutation
  updateUnreadMessages(messages: Message[]): void {
    const messagesIds: string[] = messages.map((message) => message.id);

    messages.forEach((payload) => {
      Vue.set(this.message, payload.id, payload);

      if (this.plushieMessages[payload.plushie] === undefined) {
        return;
      }

      if (!this.plushieMessages[payload.plushie].includes(payload.id)) {
        this.plushieMessages[payload.plushie].push(payload.id);
      }
    });

    this.unreadMessages = messagesIds;
  }

  @Mutation
  updateUnreadMessagesLoadPromise(value?: Promise<Message[]>): void {
    this.fLoadPromises.loadUnreadMessages = value;
  }

  @Action({ rawError: true })
  async loadMessagesByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<Message[]> {
    let messages: Message[] = [];

    if (useCache && this.plushieMessages[plushieId]) {
      const messageIds = this.plushieMessages[plushieId];

      messageIds.forEach((id) => {
        messages.push(this.message[id]);
      });

      return messages;
    }

    messages = await DIContainer.MessageRepository.getByPlushie(plushieId);

    this.updateMessages({ plushieId, messages });

    return messages;
  }

  @Action({ rawError: true })
  async loadUnreadMessages({
    userId,
    userGroupId,
  }: {
    userId: string;
    userGroupId: string;
  }): Promise<Message[]> {
    if (this.fLoadPromises.loadUnreadMessages) {
      return this.fLoadPromises.loadUnreadMessages;
    }

    const loadPromise = (async () => {
      const messages = await DIContainer.MessageRepository.getUnreadList(
        userId,
        userGroupId
      );

      this.updateUnreadMessages(messages);

      this.updateUnreadMessagesLoadPromise(undefined);

      return messages;
    })();

    this.updateUnreadMessagesLoadPromise(loadPromise);

    return loadPromise;
  }

  @Action({ rawError: true })
  async createMessage(message: MessageCreate): Promise<Message> {
    const item = await DIContainer.MessageRepository.createMessage(message);

    this.updateMessage(item);
    this.updateMessageAttachments({ messageId: item.id, attachments: [] });

    if (!message.attachments) {
      return item;
    }

    const attachmentCreatePromises: Promise<Attachment>[] = [];
    message.attachments.forEach((storageItem) => {
      attachmentCreatePromises.push(
        this.createAttachment({
          messageId: message.id,
          storageItemId: storageItem.id,
          mime: storageItem.mime,
        })
      );
    });

    await Promise.all(attachmentCreatePromises);

    return item;
  }

  @Action({ rawError: true })
  async deleteMessage(message: Message): Promise<void> {
    await DIContainer.MessageRepository.delete(message);

    this.removeMessage(message);

    return;
  }

  @Action({ rawError: true })
  async markMessageAsRead(messageId: string): Promise<Message | undefined> {
    const item = await DIContainer.MessageRepository.markAsRead(messageId);

    if (!item) {
      return;
    }

    this.updateMessage(item);

    return item;
  }

  @Action({ rawError: true })
  async markMessageAsUnread(messageId: string): Promise<Message | undefined> {
    const item = await DIContainer.MessageRepository.markAsUnread(messageId);

    if (!item) {
      return;
    }

    this.updateMessage(item);

    return item;
  }

  @Action({ rawError: true })
  async updateMessageText(message: MessageUpdate): Promise<Message> {
    const item = await DIContainer.MessageRepository.updateMessage(message);

    this.updateMessage(item);

    return item;
  }

  // ################################### QUESTION TEMPLATES #########################################
  get getQuestionTemplateById(): (id: string) => QuestionTemplate | undefined {
    return (id: string) => this.questionTemplate[id];
  }

  get getQuestionTemplatesByStatusAndProductId(): (
    statusId: string,
    productId: ProductValue
  ) => QuestionTemplate[] {
    return (statusId: string, productId: ProductValue) => {
      if (
        !this.statusRelations[statusId] ||
        !this.productRelations[productId]
      ) {
        return [];
      }

      const templatesProductDictionary: Dictionary<boolean> = {};
      const result: QuestionTemplate[] = [];

      this.productRelations[productId].forEach((relationId) => {
        if (!this.questionTemplateProductRelation[relationId]) {
          return [];
        }

        const relation = this.questionTemplateProductRelation[relationId];

        const template = this.questionTemplate[relation.template];
        templatesProductDictionary[template.id] = true;
      });

      this.statusRelations[statusId].forEach((relationId) => {
        if (!this.questionTemplateStatusRelation[relationId]) {
          return [];
        }

        const relation = this.questionTemplateStatusRelation[relationId];
        if (templatesProductDictionary[relation.template]) {
          result.push(this.questionTemplate[relation.template]);
        }
      });

      result.sort((a, b) => (a.name > b.name ? 1 : -1));

      return result;
    };
  }

  @Mutation
  removeQuestionTemplate(id: string): void {
    delete this.questionTemplate[id];
  }

  @Mutation
  updateQuestionTemplate(payload: QuestionTemplate): void {
    Vue.set(this.questionTemplate, payload.id, payload);
  }

  @Action({ rawError: true })
  async deleteQuestionTemplateById(id: string): Promise<void> {
    await DIContainer.QuestionTemplateRepository.deleteById(id);

    this.removeQuestionTemplate(id);

    return;
  }

  @Action({ rawError: true })
  async loadQuestionTemplates({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<QuestionTemplate>> {
    const collection = await DIContainer.QuestionTemplateRepository.getList(
      page,
      limit,
      filter,
      order
    );

    collection.getItems().forEach((item) => {
      this.updateQuestionTemplate(item);
    });

    return collection;
  }

  @Action({ rawError: true })
  async loadQuestionTemplateById(
    id: string
  ): Promise<QuestionTemplate | undefined> {
    if (this.questionTemplate[id] !== undefined) {
      return this.questionTemplate[id];
    }

    const questionTemplate = await DIContainer.QuestionTemplateRepository.getById(
      id
    );

    if (questionTemplate) {
      this.updateQuestionTemplate(questionTemplate);
    }

    return questionTemplate;
  }

  @Action({ rawError: true })
  async loadQuestionTemplatesByIds({
    ids,
    useCache = true,
  }: {
    ids: string[];
    useCache?: boolean;
  }): Promise<Dictionary<QuestionTemplate | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<QuestionTemplate | undefined> = {};

    ids.forEach((id) => {
      if (useCache && this.questionTemplate[id]) {
        result[id] = this.questionTemplate[id];
        return;
      }

      missing.push(id);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.QuestionTemplateRepository.getByIds(
      missing
    );

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateQuestionTemplate(item);
    });

    return { ...result, ...items };
  }

  @Action({ rawError: true })
  async saveQuestionTemplate(
    questionTemplate: QuestionTemplate
  ): Promise<QuestionTemplate> {
    const item = await DIContainer.QuestionTemplateRepository.save(
      questionTemplate
    );

    this.updateQuestionTemplate(item);

    return item;
  }

  // ################################### QUESTION TEMPLATE STATUS RELATIONS #########################################
  get getQuestionTemplateStatusRelationsByTemplateId(): (
    templateId: string
  ) => QuestionTemplateStatusRelation[] {
    return (templateId: string) => {
      if (!this.templateStatusRelations[templateId]) {
        return [];
      }

      const relations: QuestionTemplateStatusRelation[] = [];
      this.templateStatusRelations[templateId].forEach((relationId) => {
        relations.push(this.questionTemplateStatusRelation[relationId]);
      });

      return relations;
    };
  }

  @Mutation
  updateStatusRelations({
    statusId,
    relations,
  }: {
    statusId: string;
    relations: QuestionTemplateStatusRelation[];
  }): void {
    const relationIds: string[] = [];
    relations.forEach((relation) => {
      Vue.set(this.questionTemplateStatusRelation, relation.id, relation);

      relationIds.push(relation.id);

      if (this.templateStatusRelations[relation.template] === undefined) {
        return;
      }

      if (
        !this.templateStatusRelations[relation.template].includes(relation.id)
      ) {
        this.templateStatusRelations[relation.template].push(relation.id);
      }
    });

    Vue.set(this.statusRelations, statusId, relationIds);
  }

  @Mutation
  updateQuestionTemplateStatusRelations({
    templateId,
    relations,
  }: {
    templateId: string;
    relations: QuestionTemplateStatusRelation[];
  }): void {
    const relationIds: string[] = [];
    relations.forEach((relation) => {
      Vue.set(this.questionTemplateStatusRelation, relation.id, relation);

      relationIds.push(relation.id);

      if (!this.statusRelations[relation.plushieStatus]) {
        return;
      }

      if (!this.statusRelations[relation.plushieStatus].includes(relation.id)) {
        this.statusRelations[relation.plushieStatus].push(relation.id);
      }
    });

    Vue.set(this.templateStatusRelations, templateId, relationIds);
  }

  @Action({ rawError: true })
  async loadQuestionTemplateStatusRelationsByTemplateIds({
    templateIds,
    useCache = true,
  }: {
    templateIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string[] | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<string[] | undefined> = {};

    templateIds.forEach((templateId) => {
      if (useCache && this.templateStatusRelations[templateId]) {
        result[templateId] = this.templateStatusRelations[templateId];
        return;
      }

      missing.push(templateId);
    });

    if (!missing.length) {
      return result;
    }

    const collection = await DIContainer.QuestionTemplateStatusRelationRepository.getByTemplateIds(
      missing
    );

    const items = collection.getItems();

    const relations: Dictionary<QuestionTemplateStatusRelation[]> = {};

    items.forEach((item) => {
      if (!relations[item.template]) {
        relations[item.template] = [];
      }
      relations[item.template].push(item);
    });

    for (const templateId in relations) {
      const templateRelations = relations[templateId];

      if (!templateRelations) {
        continue;
      }

      result[templateId] = templateRelations.map((item) => item.id);

      this.updateQuestionTemplateStatusRelations({
        templateId,
        relations: templateRelations,
      });
    }

    return result;
  }

  @Action({ rawError: true })
  async loadQuestionTemplateStatusRelationsByStatusId({
    statusId,
    useCache = true,
  }: {
    statusId: string;
    useCache?: boolean;
  }): Promise<QuestionTemplateStatusRelation[]> {
    if (useCache && this.statusRelations[statusId]) {
      const relationIds = this.statusRelations[statusId];

      const result: QuestionTemplateStatusRelation[] = [];
      relationIds.forEach((id) => {
        result.push(this.questionTemplateStatusRelation[id]);
      });

      return result;
    }

    const collection = await DIContainer.QuestionTemplateStatusRelationRepository.getByStatusId(
      statusId
    );

    const relations = collection.getItems();

    this.updateStatusRelations({ statusId, relations });

    return relations;
  }

  // ################################### QUESTION TEMPLATES PRODUCT ID RELATIONS #########################################

  get getQuestionTemplateProductRelationsByTemplateId(): (
    templateId: string
  ) => QuestionTemplateProductRelation[] {
    return (templateId: string) => {
      const templateRelationIds = this.templateProductRelations[templateId];
      if (!templateRelationIds) {
        return [];
      }

      return templateRelationIds.map(
        (relationId) => this.questionTemplateProductRelation[relationId]
      );
    };
  }

  @Mutation
  updateProductTemplateRelations({
    productId,
    relations,
  }: {
    productId: ProductValue;
    relations: QuestionTemplateProductRelation[];
  }): void {
    const relationsIds: string[] = [];

    relations.forEach((relation) => {
      relationsIds.push(relation.id);

      Vue.set(this.questionTemplateProductRelation, relation.id, relation);

      if (!this.templateProductRelations[relation.template]) {
        return;
      }

      if (
        !this.templateProductRelations[relation.template].includes(relation.id)
      ) {
        this.templateProductRelations[relation.template].push(relation.id);
      }
    });

    Vue.set(this.productRelations, productId, relationsIds);
  }

  @Mutation
  updateTemplateProductRelations({
    templateId,
    relations,
  }: {
    templateId: string;
    relations: QuestionTemplateProductRelation[];
  }): void {
    const relationIds: string[] = [];

    relations.forEach((relation) => {
      Vue.set(this.questionTemplateProductRelation, relation.id, relation);

      relationIds.push(relation.id);

      if (!this.productRelations[relation.productId]) {
        return;
      }

      if (!this.productRelations[relation.productId].includes(relation.id)) {
        this.productRelations[relation.productId].push(relation.id);
      }
    });

    Vue.set(this.templateProductRelations, templateId, relationIds);
  }

  @Action({ rawError: true })
  async loadQuestionTemplateProductRelationsByTemplateIds({
    templateIds,
    useCache = true,
  }: {
    templateIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<string[] | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<string[] | undefined> = {};

    templateIds.forEach((templateId) => {
      if (useCache && this.templateProductRelations[templateId]) {
        result[templateId] = this.templateProductRelations[templateId];
        return;
      }

      missing.push(templateId);
    });

    if (!missing.length) {
      return result;
    }

    const collection = await DIContainer.QuestionTemplateProductRelationRepository.getByTemplateIds(
      missing
    );

    const items = collection.getItems();

    const relations: Dictionary<QuestionTemplateProductRelation[]> = {};

    items.forEach((item) => {
      if (!relations[item.template]) {
        relations[item.template] = [];
      }

      relations[item.template].push(item);
    });

    for (const templateId in relations) {
      const templateRelations = relations[templateId];

      if (!templateRelations) {
        continue;
      }

      result[templateId] = templateRelations.map((item) => item.id);

      this.updateTemplateProductRelations({
        templateId,
        relations: templateRelations,
      });
    }

    return result;
  }

  @Action({ rawError: true })
  async loadQuestionTemplateProductRelationsByProductId({
    productId,
    useCache = true,
  }: {
    productId: ProductValue;
    useCache?: boolean;
  }): Promise<QuestionTemplateProductRelation[]> {
    if (useCache && this.productRelations[productId]) {
      const relationIds = this.productRelations[productId];

      return relationIds.map((id) => this.questionTemplateProductRelation[id]);
    }

    const collection = await DIContainer.QuestionTemplateProductRelationRepository.getByProductId(
      productId
    );

    const relations = collection.getItems();

    this.updateProductTemplateRelations({ productId, relations });

    return relations;
  }

  // ################################### USER GROUP RELATION #########################################

  get getUserGroupRelationByUserId(): (
    userId: string
  ) => UserGroupRelation | undefined {
    return (userId: string) => {
      const relationId = this.userGroupRelationByUser[userId];

      if (!relationId) {
        return undefined;
      }

      return this.userGroupRelation[relationId];
    };
  }

  @Mutation
  updateUserGroupRelation(payload: UserGroupRelation): void {
    Vue.set(this.userGroupRelation, payload.id, payload);

    Vue.set(this.userGroupRelationByUser, payload.user, payload.id);
  }

  @Mutation
  updateUserGroupRelationByUserIdLoadPromise({
    userId,
    value,
  }: {
    userId: string;
    value?: Promise<UserGroupRelation | undefined>;
  }): void {
    void Vue.set(
      this.fLoadPromises.loadUserGroupRelationByUserId,
      userId,
      value
    );
  }

  @Action({ rawError: true })
  async loadUserGroupRelationByUserId({
    userId,
    useCache = true,
  }: {
    userId: string;
    useCache?: boolean;
  }): Promise<UserGroupRelation | undefined> {
    if (
      this.fLoadPromises.loadUserGroupRelationByUserId[userId] !== undefined
    ) {
      return this.fLoadPromises.loadUserGroupRelationByUserId[userId];
    }

    if (useCache && this.userGroupRelationByUser[userId]) {
      const relationId = this.userGroupRelationByUser[userId];

      return this.userGroupRelation[relationId];
    }

    const loadPromise = (async () => {
      const item = await DIContainer.MessagingUserGroupRelationRepository.getByUser(
        userId
      );

      if (!item) {
        return undefined;
      }

      this.updateUserGroupRelation(item);
      this.updateUserGroupRelationByUserIdLoadPromise({
        userId,
        value: undefined,
      });

      return item;
    })();

    this.updateUserGroupRelationByUserIdLoadPromise({
      userId,
      value: loadPromise,
    });

    return loadPromise;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
