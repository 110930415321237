
























import { Component, Prop, Watch, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import ErrorConverterService from "@/modules/forms/error-converter.service";

import AddItem from "./TagAlertAssignRules/AddItem.vue";

import AlertStore from "../store";
import TagAlertAssignRule from "../tag-alert-assign-rule.model";

@Component({
  components: {
    ActionButton,
    AddItem,
  },
})
export default class PlushieTagAlertAssignRules extends Vue {
  @Prop({ required: true })
  public readonly alertId!: string;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fAlertStore: AlertStore;

  get assignedTags(): string[] {
    const result: string[] = [];

    this.rules.forEach((rule) => {
      result.push(rule.tagName);
    });

    return result;
  }

  get rules(): TagAlertAssignRule[] {
    if (!this.alertId) {
      return [];
    }

    return this.fAlertStore.getPlushieTagAlertAssignRulesByAlertId(
      this.alertId
    );
  }

  constructor() {
    super();

    this.fAlertStore = getModule(AlertStore, dataStore);
  }

  public async deleteRule(ruleId: string): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    try {
      await this.fAlertStore.deleteTagAlertAssignRuleById(ruleId);
    } catch (e) {
      const error = this.fErrorConverterService.describeError(e);
      alert(error[0]);
      throw e;
    }
  }

  @Watch("alertId", { immediate: true })
  private async _onAlertIdChange() {
    if (!this.alertId) {
      return;
    }

    await this.fAlertStore.loadPlushieTagAlertAssignRulesByAlertId({
      alertId: this.alertId,
      useCache: false,
    });
  }
}
