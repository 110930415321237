import { SignalDispatcher, ISignal } from "ste-signals";

export default class UserActiveStateProvider {
  private static readonly ACTIVITY_EVENTS = [
    "mousemove",
    "mousedown",
    "scroll",
    "focus",
    "keypress",
    "pageshow",
    "resize",
    "touchend",
  ];

  private fOnStateChange = new SignalDispatcher();

  private fIsActive = true;
  private fUserInactiveTimeout?: number;
  private fInitCallCount = 0;

  constructor(private fWindow: Window) {}

  get onStateChange(): ISignal {
    return this.fOnStateChange.asEvent();
  }

  public destroy(): void {
    this.fInitCallCount--;

    if (this.fInitCallCount > 0) {
      return;
    }

    if (this.fUserInactiveTimeout) {
      clearTimeout(this.fUserInactiveTimeout);
    }

    for (const event of UserActiveStateProvider.ACTIVITY_EVENTS) {
      this.fWindow.removeEventListener(event, this.fActivityHandler, {
        capture: true,
      });
    }
  }

  public init(): void {
    if (this.fInitCallCount === 0) {
      this.setUserInactiveTimeout();

      for (const event of UserActiveStateProvider.ACTIVITY_EVENTS) {
        this.fWindow.addEventListener(event, this.fActivityHandler, {
          capture: true,
          passive: true,
        });
      }
    }

    this.fInitCallCount++;
  }

  public get isActive(): boolean {
    return this.fIsActive;
  }

  public set isActive(value: boolean) {
    if (this.fIsActive === value) {
      return;
    }

    this.fIsActive = value;

    this.fOnStateChange.dispatch();
  }

  private activateUser(): void {
    this.isActive = true;

    this.setUserInactiveTimeout();
  }

  private setUserInactiveTimeout(): void {
    if (this.fUserInactiveTimeout) {
      clearTimeout(this.fUserInactiveTimeout);
    }

    this.fUserInactiveTimeout = this.fWindow.setTimeout(() => {
      this.isActive = false;
      this.fUserInactiveTimeout = undefined;
    }, 60 * 1000);
  }

  private fActivityHandler = () => this.activateUser();
}
