















import { Component, Prop } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";

import BatchCreationForm from "./BatchCreationForm.vue";

@Component({
  components: { BatchCreationForm },
})
export default class BatchCreationDialog extends DialogComponent<boolean> {
  @Prop({ required: true })
  public readonly projectId!: string;
}
