import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Item implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fType: string,
    private fUrl: string,
    private fTimestampedUrl: string,
    private fMime: string,
    private fKey: string,
    private fFileName: string | undefined
  ) {
    [fId, fType, fUrl, fMime, fKey].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get timestampedUrl(): string {
    return this.fTimestampedUrl;
  }

  get type(): string {
    return this.fType;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }

  get url(): string {
    return this.fUrl;
  }

  get mime(): string {
    return this.fMime;
  }

  get key(): string {
    return this.fKey;
  }

  get fileName(): string | undefined {
    return this.fFileName;
  }
}
