import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";

import UserPresenceNotifier from "./user-presence-notifier";
import CurrentUserStateReporterFactory from "./current-user-state-reporter.factory";
import OtherUsersDetectorFactory from "./other-users-detector.factory";

export default class UserPresenceNotifierFactory {
  public constructor(
    private fCurrentUserStateReporterFactory: CurrentUserStateReporterFactory,
    private fOtherUsersDetectorFactory: OtherUsersDetectorFactory,
    private fAuthenticatedUserProvider: AuthenticatedUserProvider,
    private fSocketServerHost: string
  ) {}

  public create(plushieId: string): UserPresenceNotifier {
    return new UserPresenceNotifier(
      this.fCurrentUserStateReporterFactory,
      this.fOtherUsersDetectorFactory,
      this.fAuthenticatedUserProvider,
      this.fSocketServerHost,
      plushieId
    );
  }
}
