import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import FactoryInvoicesSetting from "./factory-invoices-setting.model";

export default class FactoryInvoicesSettingFactory
  implements ObjectBuilderInterface<FactoryInvoicesSetting> {
  public buildFromJSON(data: { [key: string]: any }): FactoryInvoicesSetting {
    const value = new FactoryInvoicesSetting(data.id, data.name);

    (value as any).fValue = data.value;

    value.isNew = false;

    return value;
  }
}
