import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class QuestionTemplate implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fName: string,
    private fContent: string
  ) {
    [fId, fName, fContent].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get name(): string {
    return this.fName;
  }

  get content(): string {
    return this.fContent;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
