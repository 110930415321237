import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import QuestionTemplate from "./question-template.model";

export default class QuestionTemplateRepository extends BasicRepository<QuestionTemplate> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<QuestionTemplate>,
    responseParser: ApiCollectionResponseParserService<QuestionTemplate>
  ) {
    super("question_templates", api, objectBuilder, responseParser);
  }

  protected prepareData(item: QuestionTemplate): Record<string, string> {
    return {
      id: item.id,
      name: item.name,
      content: item.content,
    };
  }
}
