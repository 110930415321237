










import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import ImagesGalleryFullscreenModalMixin from "@/lib/mixins/ImagesGalleryFullscreenModal";

import MarkedUpImagesGallery from "./MarkedUpImagesGallery.vue";

import { MarkedUpSlideData } from "../marked-up-slide-data";

@Component({
  components: {
    MarkedUpImagesGallery,
  },
})
export default class MarkedUpImagesGalleryFullscreenModal extends mixins(
  ImagesGalleryFullscreenModalMixin
) {
  @Prop({ required: true })
  public readonly slides!: MarkedUpSlideData[];

  protected mounted(): void {
    const imagesGallery = this.getImagesGallery();

    if (!imagesGallery) {
      return;
    }

    imagesGallery.launchGalleria(this.slides, this.index, this.options);
  }

  private getImagesGallery(): MarkedUpImagesGallery | undefined {
    return this.$refs.markedUpImagesGallery as MarkedUpImagesGallery;
  }
}
