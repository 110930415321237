











































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import FactoryStore from "@/modules/factory/store";
import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import ProductionProcessStore from "../../store";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class BulkReassignToFactoryForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieIds!: string[];

  public factory?: string;

  private fFactoryDataStore: FactoryStore;
  private fProductionProcessDataStore: ProductionProcessStore;

  get factoryOptions() {
    return this.fFactoryDataStore.activeFactoriesList;
  }

  constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);

    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected async performSubmit(): Promise<void> {
    if (!this.factory) {
      return;
    }

    await this.fProductionProcessDataStore.bulkReassignFactory({
      plushieIds: this.plushieIds,
      factoryId: this.factory,
    });

    this.$emit("formSubmitted");
  }

  protected data() {
    return {
      factory: this.factory,
    };
  }
}
