







































import { Location } from "vue-router";
import { Inject, Prop } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import { focus } from "vue-focus";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import LoginRedirectResolverService from "../login-redirect-resolver.service";
import UserRepository from "../user.repository";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
  directives: {
    focus: focus,
  },
})
export default class LoginForm extends mixins(GeneralFormMixin) {
  @Prop({ type: [Object, String] })
  public redirect?: string | Location;

  @Inject("LoginRedirectResolverService")
  private fRedirectResolver!: LoginRedirectResolverService;

  @Inject("UserRepository")
  private fUserRepository!: UserRepository;

  public login = "";
  public password = "";

  protected async performSubmit(): Promise<void> {
    let redirect = this.redirect;

    if (redirect == null && this.$auth.redirect()) {
      redirect = this.$auth.redirect().from.fullPath;
    }

    const user = await this.fUserRepository.login(this.login, this.password);

    if (redirect) {
      await this.$router.replace(redirect);
      return;
    }

    const url = await this.fRedirectResolver.calculateUrl(user);

    await this.$router.replace(url);
  }
}
