


















import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import PlushieStore from "@/modules/plushie/store";
import PlushieImage from "@/modules/plushie/plushie-image.model";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import Plushie from "@/modules/plushie/plushie.model";

import TagStore from "../../../store";

@Component({})
export default class TagSearchResultItem extends Vue {
  private static readonly THUMBNAIL_SIZE = 250;

  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;
  private fTagDataStore: TagStore;

  get plushie(): Plushie | undefined {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  get tags(): DirectoryValue[] {
    return this.fTagDataStore.getTagsForPlushie(this.plushieId);
  }

  get plushieMainArtwork(): PlushieImage | undefined {
    if (!this.plushie) {
      return undefined;
    }

    return this.fPlushieDataStore.getPlushieMainImageByPlushieId(
      this.plushie.id
    );
  }

  get plushieThumbnail(): string {
    const mainArtwork = this.plushieMainArtwork;

    if (!mainArtwork || !mainArtwork.storageItem) {
      return this.fImageHandlerService.getThumbnailUrl(
        this.fFileStorageDataStore.placeholderUrl,
        TagSearchResultItem.THUMBNAIL_SIZE * 1.5,
        TagSearchResultItem.THUMBNAIL_SIZE * 1.5,
        true
      );
    }

    const storageItem = this.fFileStorageDataStore.getItemById(
      mainArtwork.storageItem
    );

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      TagSearchResultItem.THUMBNAIL_SIZE * 1.5,
      TagSearchResultItem.THUMBNAIL_SIZE * 1.5,
      true
    );
  }

  get storeItemId(): string | undefined {
    if (!this.plushie) {
      return undefined;
    }

    return this.plushie.storeItemId;
  }

  constructor() {
    super();

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fTagDataStore = getModule(TagStore, dataStore);
  }
}
