















import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";

import PlushieStore from "../../store";
import PlushieImage from "../../plushie-image.model";

@Component({})
export default class SameOrderPlushieItem extends Vue {
  private static readonly THUMBNAIL_SIZE = 80;

  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;

  get plushie() {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  get plushieMainArtwork(): PlushieImage | undefined {
    if (!this.plushie) {
      return undefined;
    }

    return this.fPlushieDataStore.getPlushieMainImageByPlushieId(
      this.plushie.id
    );
  }

  get plushieThumbnail(): string {
    const mainArtwork = this.plushieMainArtwork;

    if (!mainArtwork || !mainArtwork.storageItem) {
      return this.fImageHandlerService.getThumbnailUrl(
        this.fFileStorageDataStore.placeholderUrl,
        SameOrderPlushieItem.THUMBNAIL_SIZE * 1.5,
        SameOrderPlushieItem.THUMBNAIL_SIZE * 1.5,
        true
      );
    }

    const storageItem = this.fFileStorageDataStore.getItemById(
      mainArtwork.storageItem
    );

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      SameOrderPlushieItem.THUMBNAIL_SIZE * 1.5,
      SameOrderPlushieItem.THUMBNAIL_SIZE * 1.5,
      true
    );
  }

  get storeItemId(): string | undefined {
    if (!this.plushie) {
      return undefined;
    }

    return this.plushie.storeItemId;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }
}
