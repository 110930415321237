








































































import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import InvoiceItem from "../../invoice-item.model";
import FactoryInvoiceStore from "../../store/index";
import FactoryInvoice from "../../factory-invoice.model";
import { FactoryInvoiceStatusValue } from "../../factory-invoice-status.value";

@Component({
  components: {
    FormErrors,
  },
})
export default class FactoryInvoiceViewDetails extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fFactoryInvoiceStore: FactoryInvoiceStore;

  private fErrorsList: string[] = [];
  private fIsDisabled = false;

  get errorsList(): string[] {
    return this.fErrorsList;
  }

  get isDisabled(): boolean {
    return this.fIsDisabled;
  }

  get invoice(): FactoryInvoice | undefined {
    return this.fFactoryInvoiceStore.getFactoryInvoiceById(this.invoiceId);
  }

  get invoiceItems(): InvoiceItem[] {
    const items = this.fFactoryInvoiceStore.getInvoiceItemsByInvoiceId(
      this.invoiceId
    );

    items.sort((a, b) =>
      (a.plushieNameForFactory || "") > (b.plushieNameForFactory || "") ? 1 : -1
    );

    return items;
  }

  get canManagePenalties(): boolean {
    if (!this.invoice) {
      return false;
    }

    if (this.invoice.status !== FactoryInvoiceStatusValue.REVIEW) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_INVOICE_APPROVE);
  }

  get isFactoryView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return true;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  constructor() {
    super();

    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public getItemName(item: InvoiceItem): string {
    if (this.isFactoryView) {
      return item.plushieNameForFactory || "";
    }

    return item.plushieName || "";
  }

  public async togglePenalty(item: InvoiceItem): Promise<void> {
    this.fIsDisabled = true;

    try {
      await this.fFactoryInvoiceStore.updateFactoryInvoiceItem({
        id: item.id,
        applyPenalty: !item.applyPenalty,
      });
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      this.fErrorsList.push(...errors);
    } finally {
      this.fIsDisabled = false;
    }
  }
}
