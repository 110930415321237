import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

export default class ProductionTimeAlert {
  constructor(
    private fPlushieId: string,
    private fStatusId: PlushieStatusValue,
    private fPlushieStoreItemId: string,
    private fFactoryId: string,
    private fPriorityBonusId: number,
    private fEstimatedShipmentDate: string,
    private fDueDate: string
  ) {
    [
      fPlushieId,
      fStatusId,
      fPlushieStoreItemId,
      fFactoryId,
      fPriorityBonusId,
      fEstimatedShipmentDate,
      fDueDate,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get plushieId(): string {
    return this.fPlushieId;
  }

  get statusId(): PlushieStatusValue {
    return this.fStatusId;
  }

  get plushieStoreItemId(): string {
    return this.fPlushieStoreItemId;
  }

  get factoryId(): string {
    return this.fFactoryId;
  }

  get priorityBonusId(): number {
    return this.fPriorityBonusId;
  }

  get estimatedShipmentDate(): string {
    return this.fEstimatedShipmentDate;
  }

  get dueDate(): string {
    return this.fDueDate;
  }
}
