












































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Dictionary } from "@/lib/Dictionary.type";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import ProductionTimeNormViewForm from "./ViewForm.vue";
import ProductionTimeNormEditForm from "./EditForm.vue";
import ProductionTimeNormHistory from "./History.vue";

import FactoryStore from "../../store";
import Factory from "../../factory.model";

@Component({
  metaInfo() {
    const component = this as ProductionTimeNormEditPage;

    return {
      title:
        (component.factory != null ? component.factory.name : "") +
        " Production Time Norms",
    };
  },
  components: {
    ProductionTimeNormEditForm,
    ProductionTimeNormViewForm,
    ProductionTimeNormHistory,
  },
})
export default class ProductionTimeNormEditPage extends Vue {
  @Prop()
  public readonly factoryId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fFactoryDataStore: FactoryStore;

  private fIsHistoryExpanded = false;

  get isHistoryExpanded(): boolean {
    return this.fIsHistoryExpanded;
  }

  get factory(): Factory | undefined {
    return this.fFactoryDataStore.getFactoryById(this.factoryId);
  }

  get showBackLink(): boolean {
    return (
      this.listQuery !== undefined && Object.keys(this.listQuery).length > 0
    );
  }

  get showEditForm(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PRODUCTION_TIME_NORM_MANAGE);
  }

  get showHistory(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_ADMIN,
      AuthenticatedUser.ROLE_STORE,
    ].includes(user.role);
  }

  get showViewForm(): boolean {
    if (this.showEditForm) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PRODUCTION_TIME_NORM_VIEW);
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public onCancel(): void {
    void this.$router.push({
      name: "ProductionTimeNormListPage",
      query: this.listQuery,
    });
  }

  public onRestoreValuesFromHistory(
    productDaysDictionary: Dictionary<number>
  ): void {
    const editForm = this.getEditForm();

    if (!editForm) {
      return;
    }

    editForm.replaceProductValues(productDaysDictionary);
  }

  public onTimeNormsUpdated(): void {
    void this.$router.push({
      name: "ProductionTimeNormListPage",
      query: this.listQuery,
    });
  }

  public toggleHistory(): void {
    this.fIsHistoryExpanded = !this.fIsHistoryExpanded;
  }

  private getEditForm():
    | InstanceType<typeof ProductionTimeNormEditForm>
    | undefined {
    return this.$refs.editForm as
      | InstanceType<typeof ProductionTimeNormEditForm>
      | undefined;
  }

  private async getFactoryData(factoryId: string): Promise<void> {
    await this.fFactoryDataStore.loadFactoryById({ id: factoryId });
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (this.factoryId == null) {
      return;
    }

    await this.getFactoryData(this.factoryId);
  }
}
