import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import dataStore from "@/store";

import PlushieTagRelation from "../plushie-tag-relation.model";

const name = "TagStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    tag: {},
    plushieTagRelation: {},
    plushieRelations: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class TagStore extends VuexModule {
  tag: Dictionary<DirectoryValue> = {};
  plushieTagRelation: Dictionary<PlushieTagRelation> = {};

  plushieRelations: Dictionary<string[]> = {};

  // ################################### TAG #########################################

  get getTagById(): (id: string) => DirectoryValue | undefined {
    return (id: string) => this.tag[id];
  }

  @Mutation
  updateTag(payload: DirectoryValue): void {
    Vue.set(this.tag, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadTagById(id: string): Promise<DirectoryValue | undefined> {
    if (this.tag[id]) {
      return this.tag[id];
    }

    const tag = await DIContainer.TagRepository.getById(id);

    if (!tag) {
      return;
    }

    this.updateTag(tag);

    return tag;
  }

  @Action({ rawError: true })
  async loadTagsByIds(
    ids: string[]
  ): Promise<Dictionary<DirectoryValue | undefined>> {
    const missing: string[] = [];
    const result: Dictionary<DirectoryValue | undefined> = {};

    ids.forEach((id) => {
      if (!this.tag[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.tag[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.TagRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateTag(item);
    });

    return { ...result, ...items };
  }

  // ################################### PLUSHIE TAG RELATION #########################################

  get getPlushieTagRelationById(): (
    id: string
  ) => PlushieTagRelation | undefined {
    return (id: string) => this.plushieTagRelation[id];
  }

  get getPlushieTagRelationByPlushieAndTagId(): (
    plushieId: string,
    tagId: string
  ) => PlushieTagRelation | undefined {
    return (plushieId: string, tagId: string) => {
      if (!this.plushieRelations[plushieId]) {
        return;
      }

      const relationId = this.plushieRelations[plushieId].find(
        (relationId) => this.plushieTagRelation[relationId].tag === tagId
      );

      if (!relationId) {
        return;
      }

      return this.plushieTagRelation[relationId];
    };
  }

  get getPlushieTagRelationsByPlushieId(): (
    plushieId: string
  ) => PlushieTagRelation[] {
    return (plushieId: string) => {
      const relationsIds = this.plushieRelations[plushieId];

      if (!relationsIds) {
        return [];
      }

      const result: PlushieTagRelation[] = [];

      relationsIds.forEach((id) => {
        const relation = this.plushieTagRelation[id];
        if (!relation) {
          return;
        }

        result.push(relation);
      });

      return result;
    };
  }

  get getTagsForPlushie(): (plushieId: string) => DirectoryValue[] {
    return (plushieId: string) => {
      const relations = this.getPlushieTagRelationsByPlushieId(plushieId);

      const tagsIds = relations.map((relation) => relation.tag);

      const tags: DirectoryValue[] = [];
      tagsIds.forEach((tagId) => {
        const tag = this.tag[tagId];
        if (!tag) {
          return;
        }

        tags.push(tag);
      });

      tags.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );

      return tags;
    };
  }

  @Mutation
  removePlushieTagRelation(id: string): void {
    const relation = this.plushieTagRelation[id];

    if (!relation) {
      return;
    }

    delete this.plushieTagRelation[id];

    const relationsList = this.plushieRelations[relation.plushie];
    if (!relationsList) {
      return;
    }

    const index = relationsList.indexOf(relation.id);
    if (index === -1) {
      return;
    }

    relationsList.splice(index, 1);
  }

  @Mutation
  updatePlushieTagRelation(payload: PlushieTagRelation): void {
    Vue.set(this.plushieTagRelation, payload.id, payload);

    if (this.plushieRelations[payload.plushie] === undefined) {
      return;
    }

    if (this.plushieRelations[payload.plushie].includes(payload.id)) {
      return;
    }

    this.plushieRelations[payload.plushie].push(payload.id);
  }

  @Mutation
  updatePlushieTagRelations({
    plushieId,
    relations,
  }: {
    plushieId: string;
    relations: PlushieTagRelation[];
  }): void {
    relations.forEach((relation) => {
      if (relation.plushie !== plushieId) {
        throw new Error(
          "All tag relations should belong to the specified plushie!"
        );
      }
    });

    const plushieTagRelations: string[] = [];

    relations.forEach((relation) => {
      plushieTagRelations.push(relation.id);
      Vue.set(this.plushieTagRelation, relation.id, relation);
    });

    Vue.set(this.plushieRelations, plushieId, plushieTagRelations);
  }

  @Action({ rawError: true })
  async addTagToPlushie({
    tag,
    plushieId,
  }: {
    tag: string;
    plushieId: string;
  }): Promise<PlushieTagRelation> {
    const relation = await DIContainer.PlushieTagRelationRepository.addTagToPlushie(
      tag,
      plushieId
    );

    this.updatePlushieTagRelation(relation);

    await this.loadTagById(relation.tag);

    if (this.plushieRelations[plushieId].length === 1) {
      void this.context.dispatch("onFirstTagAdded", relation.plushie);
    }

    return relation;
  }

  @Action({ rawError: true })
  async deletePlushieTagRelation(relation: PlushieTagRelation): Promise<void> {
    await DIContainer.PlushieTagRelationRepository.deleteById(relation.id);

    this.removePlushieTagRelation(relation.id);

    if (this.plushieRelations[relation.plushie].length === 0) {
      void this.context.dispatch("onLastTagRemoved", relation.plushie);
    }

    return;
  }

  @Action({ rawError: true })
  async loadPlushieTagRelationsByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PlushieTagRelation[]> {
    let relations: PlushieTagRelation[] = [];

    if (useCache && this.plushieRelations[plushieId]) {
      const relationsIds = this.plushieRelations[plushieId];

      relationsIds.forEach((id) => {
        relations.push(this.plushieTagRelation[id]);
      });

      return relations;
    }

    relations = await DIContainer.PlushieTagRelationRepository.getByPlushieId(
      plushieId
    );

    this.updatePlushieTagRelations({ plushieId, relations });

    return relations;
  }

  @Action({ rawError: true })
  async loadPlushieTagRelationsByPlushieIds({
    plushieIds,
    useCache = true,
  }: {
    plushieIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<PlushieTagRelation[]>> {
    const missing: string[] = [];
    const result: Dictionary<PlushieTagRelation[]> = {};

    plushieIds.forEach((plushieId) => {
      if (!useCache || !this.plushieRelations[plushieId]) {
        missing.push(plushieId);
        return;
      }

      const relationsIds = this.plushieRelations[plushieId];

      for (const id of relationsIds) {
        if (!result[plushieId]) {
          result[plushieId] = [];
        }

        result[plushieId].push(this.plushieTagRelation[id]);
      }
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.PlushieTagRelationRepository.getByPlushieIds(
      missing
    );

    const loadedRelations: Dictionary<PlushieTagRelation[]> = {};
    for (const item of items) {
      if (!loadedRelations[item.plushie]) {
        loadedRelations[item.plushie] = [];
      }

      loadedRelations[item.plushie].push(item);
    }

    for (const plushieId in loadedRelations) {
      this.updatePlushieTagRelations({
        plushieId,
        relations: loadedRelations[plushieId],
      });
    }

    return { ...result, ...loadedRelations };
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushieRelations[plushieId]) {
      return;
    }

    const relations = await this.loadPlushieTagRelationsByPlushieId({
      plushieId,
      useCache: false,
    });

    if (!relations.length) {
      return;
    }

    const tagsIds = relations.map((relation) => relation.tag);

    await this.loadTagsByIds(tagsIds);

    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
