import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ShippingInformation from "./shipping-information.model";
import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";

export default class ShippingInformationRepository extends BasicRepository<ShippingInformation> {
  constructor(
    api: ApiService,

    objectBuilder: ObjectBuilderInterface<ShippingInformation>,
    responseParser: ApiCollectionResponseParserService<ShippingInformation>
  ) {
    super("shipping_informations", api, objectBuilder, responseParser);
  }

  public async createShippingInformation(
    shippingInformation: ShippingInformation
  ): Promise<ShippingInformation> {
    const result = await this.api.post(
      `${this.resourceName}/${shippingInformation.id}`,
      {
        methodName: shippingInformation.methodName,
        firstName: shippingInformation.firstName,
        lastName: shippingInformation.lastName,
        companyName: shippingInformation.companyName,
        streetAddress1: shippingInformation.streetAddress1,
        streetAddress2: shippingInformation.streetAddress2,
        city: shippingInformation.city,
        postalCode: shippingInformation.postalCode,
        country: shippingInformation.country,
        phone: shippingInformation.phone,
        state: shippingInformation.state,
      }
    );

    return this.objectBuilder.buildFromJSON(result.data);
  }
}
