export enum UpgradeValue {
  DISPLAY_TRAY = "91b690bf-27d9-4468-bc1e-c3bbc1d19300",
  WOOD_ORNAMENT_CAT = "a02ba40f-e865-4559-87a1-d745ceaa4129",
  WOOD_ORNAMENT_DOG = "bc80f035-ef77-4328-a697-f647612f47db",
  SPECIALTY_COMMISSION_SNEAK_PEEK = "d7cb83bb-441f-4e00-8495-9f8f815bad11",
  BULK_SAMPLE_SNEAK_PEEK = "ce168797-21bd-4a84-a556-cd65770b950d",
  SNEAK_PEEK = "e73a2193-153d-4816-8ac1-9704ba4d23c0",
  MEMORY_POCKET = "fc6c4e55-f1e6-4690-b951-61b997aa886a",
  RECORDABLE_VOICE = "061b1f8e-5968-45a6-8517-4e3fc06ea6d3",
  BIRTH_CERTIFICATE = "f02b98d5-4b62-400c-91bc-0ca4426820fd",
  SUPER_SIZE = "303fc784-380c-45a1-af99-82a4e36c1041",
  PLUSHIE_3D_RENDERING = "b4e3950e-c5f8-48b2-a231-b8afa110bbf8",

  PILLOW_SIZE_SMALL = "4159af31-2665-43c1-b514-c7dbb4326069",
  PILLOW_SIZE_MEDIUM = "960e925c-f8b2-4db6-8853-56ad02d37568",
  PILLOW_SIZE_LARGE = "9f01b081-0ba1-4c91-96c7-14e76c19c183",

  BASIC_RUSH = "8e3ac36d-0455-499a-899c-03b2ef747759",
  SUPER_RUSH = "4cd73f00-e52d-46b0-b3e7-f5b9ddbdf25a",
  INTERNATIONAL_RUSH = "dd32c925-8815-47bd-a32c-26bd3d3ca769",
}
