import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import { FieldOrderAsc } from "@/modules/api/field-order-options";

export default class TagRepository extends BasicRepository<DirectoryValue> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<DirectoryValue>,
    responseParser: ApiCollectionResponseParserService<DirectoryValue>
  ) {
    super("tags", api, objectBuilder, responseParser);
  }

  public async searchTags(term: string, limit = 10): Promise<DirectoryValue[]> {
    const collection = await this.fetchList({ name: term }, 1, limit, [
      ["name", FieldOrderAsc],
    ]);

    return collection.getItems();
  }
}
