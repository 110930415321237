











import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";

import ActionButton from "@/lib/components/ActionButton.vue";

import { Dictionary } from "@/lib/Dictionary.type";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import PlushiesGridExportService from "../plushies-grid-export.service";

@Component({
  components: {
    ActionButton,
  },
})
export default class PlushiesGridExportAction extends Vue {
  @Prop({ required: true })
  public readonly filter!: Dictionary<ApiFilterValue>;

  @Prop({ required: true })
  public readonly order!: QueryOrderParameter;

  @Prop({ required: true })
  public readonly status!: PlushieStatusValue;

  @Inject("PlushiesGridExportService")
  private fPlushiesGridExportService!: PlushiesGridExportService;

  private fIsExporting = false;

  get filterWithStatus(): Dictionary<ApiFilterValue> {
    return {
      ...this.filter,
      status: this.status,
    };
  }

  get isDisabled(): boolean {
    return this.fIsExporting;
  }

  public async onExportButtonClick(): Promise<void> {
    if (this.fIsExporting) {
      return;
    }

    this.fIsExporting = true;

    try {
      await this.fPlushiesGridExportService.execute(
        this.filterWithStatus,
        this.order
      );
    } finally {
      this.fIsExporting = false;
    }
  }
}
