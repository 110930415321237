<template>
  <div slot-scope="props" :class="`VueTables VueTables--${props.source}`">
    <div :class="props.theme.row">
      <div :class="props.theme.column">
        <div
          :class="`${props.theme.field} ${props.theme.inline} ${props.theme.left} VueTables__search`"
          v-if="!props.opts.filterByColumn && props.opts.filterable"
        >
          <vnodes :vnodes="props.slots.beforeFilter" />
          <vt-generic-filter />
          <vnodes :vnodes="props.slots.afterFilter" />
        </div>
        <vnodes :vnodes="props.slots.afterFilterWrapper" />

        <div
          class="VueTables__pagination-wrapper"
          v-if="props.opts.pagination.dropdown && props.totalPages > 1"
        >
          <div
            :class="`${props.theme.field} ${props.theme.inline} ${props.theme.right} VueTables__dropdown-pagination`"
          >
            <vt-dropdown-pagination />
          </div>
        </div>

        <div
          :class="`VueTables__columns-dropdown-wrapper ${props.theme.right} ${props.theme.dropdown.container}`"
          v-if="props.opts.columnsDropdown"
        >
          <vt-columns-dropdown />
        </div>
      </div>
    </div>

    <div class="pagination-controls">
      <div
        :class="`${props.theme.field} ${props.theme.inline} ${props.theme.right} VueTables__limit`"
        v-if="props.perPageValues.length > 1"
      >
        <vnodes :vnodes="props.slots.beforeLimit" />
        <vt-per-page-selector />
        <vnodes :vnodes="props.slots.afterLimit" />
      </div>

      <vt-pagination />
    </div>

    <vnodes :vnodes="props.slots.beforeTable" />
    <div class="table-responsive">
      <vt-table ref="vt_table" />
    </div>
    <vnodes :vnodes="props.slots.afterTable" />
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unsafe-return */
import VtColumnsDropdown from "vue-tables-2-premium/compiled/components/VtColumnsDropdown";
import VtDropdownPagination from "vue-tables-2-premium/compiled/components/VtDropdownPagination";
import VtGenericFilter from "vue-tables-2-premium/compiled/components/VtGenericFilter";
import VtPerPageSelector from "vue-tables-2-premium/compiled/components/VtPerPageSelector";
import VtPagination from "vue-tables-2-premium/compiled/components/VtPagination";
import VtTable from "vue-tables-2-premium/compiled/components/VtTable";

export default {
  name: "MyDataTable",
  components: {
    VtGenericFilter,
    VtPerPageSelector,
    VtColumnsDropdown,
    VtDropdownPagination,
    VtTable,
    VtPagination,
    vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: ["props"],
};
</script>
