


















import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { saveAs } from "file-saver";

import FormErrors from "@/lib/components/FormErrors.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import ResponseFileNameResolver from "@/modules/general/response-file-name-resolver.service";
import ErrorConverterService from "@/modules/forms/error-converter.service";

import BarcodePrintJobRepository from "../barcode-print-job.repository";
import BarcodePrintJob from "../barcode-print-job.model";

@Component({
  components: {
    FormErrors,
    ActionButton,
  },
})
export default class PlushieBarcodeCommands extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ErrorConverterService")
  protected fErrorConverterService!: ErrorConverterService;

  @Inject("BarcodePrintJobRepository")
  private fBarcodePrintJobRepository!: BarcodePrintJobRepository;

  private fSubmitErrors: string[] = [];

  get submitErrors(): string[] {
    return this.fSubmitErrors;
  }

  public async download(): Promise<void> {
    this.fSubmitErrors = [];

    try {
      const response = await this.fBarcodePrintJobRepository.getPdfByPlushieId(
        this.plushieId
      );

      const blob = new Blob([response.data], {
        type: "application/pdf",
      });

      const fileName =
        ResponseFileNameResolver.resolve(response) || this.plushieId;

      saveAs(blob, fileName);
    } catch (e) {
      this.fSubmitErrors = this.fErrorConverterService.describeError(e);
      throw e;
    }
  }

  public async print(): Promise<void> {
    const job = new BarcodePrintJob(this.plushieId);
    try {
      await this.fBarcodePrintJobRepository.save(job);
    } catch (e) {
      this.fSubmitErrors = this.fErrorConverterService.describeError(e);
      throw e;
    }
  }
}
