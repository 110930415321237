"use strict";

module.exports = function () {
  return {
    framework: "zurb-foundation",
    table: "table hover",
    row: "grid-x",
    column: "cell small-12",
    label: "",
    input: "",
    select: "",
    field: "field",
    inline: "is-horizontal",
    right: "is-pulled-right",
    left: "is-pulled-left",
    center: "text-center",
    contentCenter: "text-center",
    icon: "icon",
    small: "is-small",
    nomargin: "marginless",
    button: "button",
    groupTr: "is-selected",
    dropdown: {
      container: "dropdown",
      trigger: "dropdown-trigger",
      menu: "dropdown-menu",
      content: "dropdown-content",
      item: "dropdown-item",
      caret: "fa fa-angle-down",
    },
    pagination: {
      nav: "",
      count: "",
      wrapper: "_pagination-wrapper",
      list: "pagination-control",
      item: "_page-item",
      link: "_pagination-link",
      next: "_next-item",
      prev: "_prev-item",
      active: "-active",
      disabled: "-disabled",
    },
  };
};
