












import { Component, Prop } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";

import CreatePaymentsForm from "./CreatePaymentsForm.vue";

@Component({
  components: { CreatePaymentsForm },
})
export default class CreatePaymentsDialog extends DialogComponent<boolean> {
  @Prop({ required: true })
  public readonly invoiceIds!: string[];
}
