import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import ExtraPaymentsReportPage from "./components/ExtraPaymentsReport/Page.vue";
import FactoryInvoiceViewPage from "./components/FactoryInvoiceViewPage.vue";
import PriceConfigurationListPage from "./components/PriceConfiguration/ListPage.vue";
import PriceConfigurationEditPage from "./components/PriceConfiguration/EditPage.vue";
import FactoryInvoicesListPage from "./components/FactoryInvoicesListPage.vue";
import PenaltyRuleEditPage from "./components/PenaltyRule/EditPage.vue";
import PenaltyRuleListPage from "./components/PenaltyRule/ListPage.vue";
import BillingInfoFactoriesListPage from "./components/BillingInfo/FactoriesListPage.vue";
import BillingInfoEditPage from "./components/BillingInfo/InfoEditPage.vue";
import BuyerInfoEditPage from "./components/BuyerInfo/InfoEditPage.vue";

export default [
  {
    path: "/factory/penalty-rule/list",
    name: "PenaltyRuleListPage",
    component: PenaltyRuleListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/penalty-rule/edit",
    name: "PenaltyRuleEditPage",
    component: PenaltyRuleEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["factoryId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory_invoices",
    name: "FactoryInvoicesListPage",
    component: FactoryInvoicesListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory_invoices/view",
    name: "FactoryInvoiceViewPage",
    component: FactoryInvoiceViewPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["invoiceId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/price-configuration/list",
    name: "PriceConfigurationListPage",
    component: PriceConfigurationListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/price-configuration/edit",
    name: "PriceConfigurationEditPage",
    component: PriceConfigurationEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["factoryId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/billing-info/list",
    name: "BillingInfoFactoriesListPage",
    component: BillingInfoFactoriesListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/billing-info/edit",
    name: "BillingInfoEditPage",
    component: BillingInfoEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["factoryId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory_invoices/store_billing_info",
    name: "BuyerInfoEditPage",
    component: BuyerInfoEditPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/reports/extra-payments",
    name: "ExtraPaymentReport",
    component: ExtraPaymentsReportPage,
    meta: {
      auth: true,
    },
  },
];
