import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import CountryRegion from "./country-region.model";

export default class CountryRegionFactory
  implements ObjectBuilderInterface<CountryRegion> {
  public buildFromJSON(data: { [key: string]: any }): CountryRegion {
    const value = new CountryRegion(
      data.id,
      data.country,
      data.name,
      data.code
    );

    value.isNew = false;

    return value;
  }
}
