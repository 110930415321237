import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import ProductSetting from "./product-setting.model";
import { ResourceCollection } from "../api/resource.collection";

export default class ProductSettingRepository extends BasicRepository<ProductSetting> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ProductSetting>,
    responseParser: ApiCollectionResponseParserService<ProductSetting>
  ) {
    super(
      "holiday_period_product_settings",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByPeriodId(
    periodId: string
  ): Promise<ResourceCollection<ProductSetting>> {
    return this.fetchList({ period: periodId }, 1, 9999);
  }

  public async updateForPeriod(
    periodId: string,
    items: ProductSetting[]
  ): Promise<ProductSetting[]> {
    const itemsData = items.map((item) => this.prepareData(item));

    const response = await this.api.post(
      `${this.resourceName}/update_requests`,
      {
        period: +periodId,
        items: itemsData,
      }
    );

    const result = this.responseParser.parse(response.data, this.objectBuilder);

    return result.getItems();
  }

  protected prepareData(item: ProductSetting): Record<string, string | number> {
    const data: Record<string, string | number> = {
      id: item.id,
      period: +item.period,
      product: +item.product,
      internationalDueDate: item.internationalDueDate
        .toISOString()
        .split("T")[0],
      domesticDueDate: item.domesticDueDate.toISOString().split("T")[0],
    };

    return data;
  }
}
