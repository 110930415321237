import ApiService from "../api/api.service";

export default class PlushiesArtworksExportJobRepository {
  constructor(protected resourceName: string, protected api: ApiService) {}

  public async createPlushiesArtworksExportJob(
    plushieIds: string[]
  ): Promise<string> {
    const response = await this.api.post(`${this.resourceName}`, {
      plushiesIds: plushieIds,
    });

    return (response.data as { downloadUrl: string }).downloadUrl;
  }
}
