
















import { Component, Prop, Vue } from "vue-property-decorator";
import type { RawLocation } from "vue-router/types/router";

import { Dictionary } from "@/lib/Dictionary.type";

import AlertEditForm from "./AlertEditForm.vue";

import Alert from "../alert.model";

@Component({
  metaInfo() {
    return {
      title: "Add Hashtag Alert",
    };
  },
  components: {
    AlertEditForm,
  },
})
export default class PlushieAlertAddPage extends Vue {
  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Prop()
  public readonly query!: Record<string, unknown>;

  public onCancel(): void {
    void this.$router.push({
      name: "PlushieAlertsListPage",
      query: this.listQuery,
    });
  }

  public onSuccess(alert: Alert): void {
    const editPageUrl = this.generateItemLink(
      alert.id,
      this.query,
      this.listQuery
    );

    void this.$router.push(editPageUrl);
  }

  private generateItemLink(
    id: string,
    query: Record<string, unknown>,
    listQuery?: Record<string, unknown>
  ): RawLocation {
    return {
      name: "PlushieAlertEditPage",
      query: {
        id,
        query: JSON.stringify(query),
        listQuery: JSON.stringify(listQuery),
      },
    };
  }
}
