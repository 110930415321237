import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Alert from "./alert.model";

export default class AlertRepository extends BasicRepository<Alert> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Alert>,
    responseParser: ApiCollectionResponseParserService<Alert>
  ) {
    super("plushie_alerts/alerts", api, objectBuilder, responseParser);
  }

  protected prepareData(
    item: Alert
  ): Record<string, string | number | boolean> {
    const data: Record<string, string | number | boolean> = {
      id: item.id,
      title: item.title,
      iconStorageId: item.iconStorageId,
      alertTypeId: item.type,
      displayToFactory: item.displayToFactory,
      notifyAboutMovements: item.isNotifyAboutMovements,
    };

    if (item.recipientId) {
      data.notificationRecipientId = item.recipientId;
    }

    if (item.description) {
      data.description = item.description;
    }

    return data;
  }
}
