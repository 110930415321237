














import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";

interface Item {
  id: string;
  description: string;
}

@Component({
  components: {},
})
export default class CreateBulkShipmentFormSelectedItems extends Vue {
  @Prop({ required: true })
  public readonly plushieIds!: string[];

  private fPlushieDataStore: PlushieStore;

  get items(): Item[] {
    const items: Item[] = [];
    this.plushieIds.forEach((plushieId) => {
      const plushie = this.fPlushieDataStore.getPlushieById(plushieId);

      if (!plushie) {
        return;
      }

      const upgradeIds = this.fPlushieDataStore.getPlushieUpgradesByPlushieId(
        plushieId
      );

      const upgrades = this.fPlushieDataStore.getUpgradesByIds(upgradeIds);

      items.push({
        id: plushieId,
        description:
          `${plushie.storeItemId} ` +
          upgrades.map((upgrade) => upgrade.shortName).join(", "),
      });
    });

    return items;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }
}
