export default async function preloadImage(
  url: string
): Promise<{ url: string; status: string }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ url, status: "ok" });
    img.onerror = () => resolve({ url, status: "error" });

    img.src = url;
  });
}
