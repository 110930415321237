






































import "v-autocomplete/dist/v-autocomplete.css";

import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import Autocomplete from "v-autocomplete";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import TagRepository from "../../tag.repository";

@Component({
  components: {
    InlineSpinner,
    Autocomplete,
  },
})
export default class TagInput extends Vue {
  @Prop({ default: [] })
  public readonly value!: string[];

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Inject("TagRepository")
  private fTagRepository!: TagRepository;

  private fTagOptions: string[] = [];

  get tagOptions(): string[] {
    return this.fTagOptions;
  }

  public onInput(value: string): void {
    if (!value || value.length < 2) {
      return;
    }

    if (this.value.includes(value)) {
      return;
    }

    const newValue = [...this.value, value];
    this.$emit("input", newValue);
  }

  public removeTag(tag: string): void {
    const newValue = this.value.filter((item) => item !== tag);
    this.$emit("input", newValue);
  }

  public async searchTags(term: string): Promise<void> {
    const tags = await this.fTagRepository.searchTags(term);

    const tagOptions: string[] = [];

    for (const tag of tags) {
      if (this.value.includes(tag.name)) {
        continue;
      }

      tagOptions.push(tag.name);
    }

    this.fTagOptions = tagOptions;
  }
}
