import Vue from "vue";
import Component from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";

import ErrorConverterService from "@/modules/forms/error-converter.service";

@Component
export default class GeneralFormMixin extends Vue {
  @Prop({ default: false })
  public disable!: boolean;

  @Inject("ErrorConverterService")
  protected fErrorConverterService!: ErrorConverterService;

  public submitErrors: string[] = [];

  protected fIsDisabled = false;

  private fIsSubmitting = false;

  get isDisabled(): boolean {
    return this.fIsDisabled || this.disable;
  }

  get isSubmitting(): boolean {
    return this.fIsSubmitting;
  }

  set isSubmitting(value: boolean) {
    this.fIsSubmitting = value;
    this.fIsDisabled = value;
  }

  public submitForm(): void {
    const button = this.$el.querySelector("[type='submit']");

    if (!button) {
      return;
    }

    (button as HTMLButtonElement).click();
  }

  protected afterSubmit(): Promise<void> {
    return Promise.resolve();
  }

  protected mounted(): void {
    this.$el.addEventListener("submit", this.fSubmitHandler);
  }

  protected beforeUpdate(): void {
    this.$el.removeEventListener("submit", this.fSubmitHandler);
  }

  protected updated(): void {
    this.$el.addEventListener("submit", this.fSubmitHandler);
  }

  protected beforeDestroy(): void {
    this.$el.removeEventListener("submit", this.fSubmitHandler);
  }

  protected performSubmit(): Promise<void> {
    return Promise.resolve();
  }

  protected processValidationErrors(errors: ErrorBag): void {
    return;
  }

  private async onFormSubmit(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isDisabled) {
      return;
    }

    this.submitErrors = [];

    const isValid = await this.$validator.validateAll();

    if (!isValid) {
      this.processValidationErrors(this.$validator.errors);
      return;
    }

    this.isSubmitting = true;

    try {
      await this.performSubmit();
      this.isSubmitting = false;
    } catch (error) {
      this.isSubmitting = false;

      this.submitErrors = this.fErrorConverterService.describeError(error);
    }

    await this.afterSubmit();

    return;
  }

  private fSubmitHandler = (e: Event) => this.onFormSubmit(e);
}
