import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";

import PlushieFactoryAssignment from "./plushie-factory-asignment.model";

export default class PlushieFactoryAssignmentRepository extends BasicRepository<PlushieFactoryAssignment> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieFactoryAssignment>,
    responseParser: ApiCollectionResponseParserService<PlushieFactoryAssignment>
  ) {
    super("plushie_factory_relations", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(
    plushieId: string
  ): Promise<PlushieFactoryAssignment | undefined> {
    const items = await this.getByPlushieIds([plushieId]);

    return items[plushieId];
  }

  public async getByPlushieIds(
    plushieIds: string[]
  ): Promise<Dictionary<PlushieFactoryAssignment | undefined>> {
    const result: Dictionary<PlushieFactoryAssignment | undefined> = {};

    plushieIds.forEach((id) => {
      result[id] = undefined;
    });

    const items = await this.fetchList({ plushie: plushieIds });

    items.getItems().forEach((item) => {
      result[item.plushie] = item;
    });

    return result;
  }

  public async getListOfPlushieIds(
    order?: QueryOrderParameter,
    filter?: Dictionary<ApiFilterValue>
  ): Promise<string[]> {
    const resourceName = this.resourceName;

    const params: Dictionary<any> = {
      page: 1,
      itemsPerPage: 99999,
      properties: ["plushie"],
    };

    if (filter) {
      Object.assign(params, filter);
    }

    let orderedParams: QueryOrderParameter = [];

    if (order != null) {
      orderedParams = order.map((item) => [`order[${item[0]}]`, item[1]]);
    }

    const response = await this.api.get(resourceName, params, orderedParams);

    const result: string[] = [];

    response.data["hydra:member"].forEach((item: any) => {
      result.push(item.plushie);
    });

    return result;
  }

  protected prepareData(
    item: PlushieFactoryAssignment
  ): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    return data;
  }
}
