import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieFactorySummary from "./plushie-factory-summary.model";

export default class PlushieFactorySummaryFactory
  implements ObjectBuilderInterface<PlushieFactorySummary> {
  public buildFromJSON(data: { [key: string]: any }): PlushieFactorySummary {
    const value = new PlushieFactorySummary(
      data.id,
      data.product.toString(),
      +data.quantity,
      data.storeItemId,
      data.status.toString(),
      data.store.toString(),
      data.delay,
      data.upgradeIds,
      +data.priority
    );

    (value as any).fDueDate = new Date(data.dueDate);
    (value as any).fMainArtworkStorageItemId = data.mainArtworkStorageItemId;

    value.isNew = false;

    return value;
  }
}
