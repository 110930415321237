





























































import { Component, Vue } from "vue-property-decorator";
import {
  RowClickEventData,
  TableOptions,
  ColumnFilterDropdownOption,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import { Dictionary } from "@/lib/Dictionary.type";
import TableColumnDefinitionInterface from "@/lib/interfaces/table-column-definition.interface";
import TableColumnDefinitionsParserService from "@/lib/services/table-column-definitions-parser.service";

import AccountStore from "../store";
import DeactivatedUser from "../deactivated-user.model";

interface TableRow {
  id: string;
  login?: string;
  name: string;
  email: string;
  role?: string;
  organization: string;
  isActive: boolean;
  createdAt?: Date;
}

@Component({
  metaInfo: {
    title: "Users management",
  },
  components: {
    LoadingSpinner,
  },
})
export default class UsersListPage extends Vue {
  protected fColumns: TableColumnDefinitionInterface[] = [];

  protected fOptions: TableOptions = {
    texts: {
      filterBy: "",
      defaultOption: "",
    },
  };

  private fTableData: TableRow[] = [];

  private fDefinitionsParser?: TableColumnDefinitionsParserService;

  private fAccountDataStore: AccountStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get tableData(): TableRow[] {
    return this.fTableData;
  }

  get options(): TableOptions {
    return this.fOptions;
  }

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);

    this.fColumns = [
      {
        name: "login",
        header: "Login",
        isFilterable: true,
        isSortable: true,
        cssClass: "_login-column -text",
        displayConstraint: "min_tablet",
      },
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
        sortKey: "firstName",
        filterKey: "firstName",
        displayConstraint: "min_desktop",
      },
      {
        name: "email",
        header: "E-mail",
        cssClass: "_email-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "role",
        header: "Role",
        cssClass: "_role-column -text",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "role.id",
      },
      {
        name: "organization",
        header: "Organization",
        cssClass: "_organization-column -text",
        displayConstraint: "min_desktop",
      },
      {
        name: "isActive",
        header: "Active",
        cssClass: "_isactive-column -checkbox",
        displayConstraint: "min_desktop",
        isSortable: true,
      },
      {
        name: "createdAt",
        header: "Created",
        cssClass: "_createdat-column",
        isSortable: true,
        displayConstraint: "min_desktop",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      debounce: 1000,
      listColumns: {},
      filterByColumn: true,
      orderBy: {
        column: "isActive",
        ascending: false,
      },
      multiSorting: {
        isActive: [
          {
            column: "name",
            matchDir: false,
          },
        ],
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
      templates: {
        createdAt: (h, row: TableRow) => {
          return row.createdAt ? row.createdAt.toLocaleDateString() : "";
        },
      },
    };
  }

  public getColumns(): string[] {
    if (!this.fDefinitionsParser) {
      return [];
    }

    return this.fDefinitionsParser.getColumns();
  }

  public async activateUser(id: string): Promise<void> {
    this.fIsLoading = true;

    try {
      await this.fAccountDataStore.deleteDeactivatedUserById(id);

      await this.refresh();
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  public async deactivateUser(id: string): Promise<void> {
    this.fIsLoading = true;

    try {
      const deactivatedUser = new DeactivatedUser(id);

      await this.fAccountDataStore.saveDeactivatedUser(deactivatedUser);

      await this.refresh();
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "UserEditPage"));
  }

  protected getItemUrl(itemId: string, routeName: string): string {
    const route = this.$router.resolve({
      name: routeName,
      query: {
        id: itemId,
      },
    });

    return route.href;
  }

  protected async created(): Promise<void> {
    this.fDefinitionsParser = new TableColumnDefinitionsParserService(
      this.fColumns
    );

    this.fOptions.headings = this.fDefinitionsParser.getHeadings();
    this.fOptions.columnsClasses = this.fDefinitionsParser.getClasses();
    this.fOptions.columnsDisplay = this.fDefinitionsParser.getColumnDisplay();
    this.fOptions.filterable = this.fDefinitionsParser.getFilterable();

    Vue.set(
      this.fOptions,
      "listColumns",
      this.fDefinitionsParser.getListColumns()
    );
    this.fOptions.sortable = this.fDefinitionsParser.getSortable();

    await this.fAccountDataStore.loadRoles();

    const roles = this.fAccountDataStore.roles;

    const roleOptions: ColumnFilterDropdownOption[] = [];

    roles.forEach((item) => {
      roleOptions.push({ id: item.name, text: item.name });
    });

    roleOptions.sort((a, b) => (a.text > b.text ? 1 : -1));

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "role", roleOptions);
    }
  }

  protected mounted(): void {
    void this.loadTableData();
  }

  protected async loadTableData(): Promise<void> {
    this.fIsLoading = true;

    try {
      const [usersCollection] = await Promise.all([
        this.fAccountDataStore.loadUserInfos({
          page: 1,
          limit: 9999,
          filter: { isAutoGenerated: false },
        }),
        this.fAccountDataStore.loadDeactivatedUsers(),
      ]);

      const users = usersCollection.getItems();

      const deactivatedUsers = this.fAccountDataStore.deactivatedUsers;

      const userIds: string[] = [];
      const organizationsIds: string[] = [];

      users.forEach((user) => {
        userIds.push(user.id);
        organizationsIds.push(user.organization);
      });

      let roles: Dictionary<DirectoryValue | undefined>;

      const usersRolesRequest = this.fAccountDataStore
        .loadUserRolesByUserId({ userIds, useCache: false })
        .then(async (items) => {
          let rolesIds: string[] = [];

          userIds.forEach((id) => {
            const userRole = items[id];
            if (userRole === undefined) {
              return;
            }

            rolesIds.push(userRole.role);
          });

          rolesIds = Array.from(new Set(rolesIds));

          roles = await this.fAccountDataStore.loadRolesByIds(rolesIds);

          return items;
        });

      const [organizations, userAccounts, usersRoles] = await Promise.all([
        this.fAccountDataStore.loadOrganizationsByIds(organizationsIds),
        this.fAccountDataStore.loadUserAccountsByIds(userIds),
        usersRolesRequest,
      ]);

      const result: TableRow[] = [];

      users.forEach((user) => {
        const userAccount = userAccounts[user.id];
        const userRole = usersRoles[user.id];
        const role = userRole ? roles[userRole.role] : undefined;
        const organization = organizations[user.organization];
        const isActive = deactivatedUsers[user.id] === undefined;

        result.push({
          id: user.id,
          login: userAccount ? userAccount.login : undefined,
          name: user.firstName + " " + user.lastName,
          email: user.email,
          role: role ? role.name : undefined,
          organization: organization ? organization.name : "",
          isActive: isActive,
          createdAt: user.createdAt ? user.createdAt : undefined,
        });
      });

      this.fTableData = result;
    } finally {
      this.fIsLoading = false;
    }
  }

  private async refresh(): Promise<void> {
    await this.loadTableData();
  }
}
