import { AxiosResponse } from "axios";

export default class ResponseFileNameResolver {
  public static resolve(response: AxiosResponse): string | undefined {
    const headers = response.headers;

    const disposition = headers["content-disposition"];

    const match = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

    return match ? match[1].trim() : undefined;
  }
}
