
































import { Component, Prop, Inject } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { ProductValue } from "@/modules/plushie/product.value";

import MessagingStore from "../../store";
import QuestionTemplate from "../../question-template.model";
import QuestionTemplateShortContentResolverService from "../../question-template-short-content-resolver.service";

@Component({
  components: {},
})
export default class MessagingQuestionTemplates extends DialogComponent<string> {
  @Prop({ required: true })
  public readonly statusId!: string;

  @Prop({ required: true })
  public readonly productId!: ProductValue;

  @Inject("QuestionTemplateShortContentResolverService")
  private fShortContentResolverService!: QuestionTemplateShortContentResolverService;

  private fMessagingDataStore: MessagingStore;

  private fSearchText = "";

  get filterText(): string {
    return this.fSearchText;
  }

  set filterText(text: string) {
    this.fSearchText = text;
  }

  get templates(): QuestionTemplate[] {
    return this.fMessagingDataStore.getQuestionTemplatesByStatusAndProductId(
      this.statusId,
      this.productId
    );
  }

  get filteredTemplates(): QuestionTemplate[] {
    const formattedSearchText = this.filterText.toLowerCase().trim();
    if (!formattedSearchText) {
      return this.templates;
    }

    return this.templates.filter((template) => {
      return (
        template.name.toLowerCase().includes(formattedSearchText) ||
        template.content.toLowerCase().includes(formattedSearchText)
      );
    });
  }

  constructor() {
    super();
    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
  }

  public getShortText(text: string): string {
    return this.fShortContentResolverService.resolve(text);
  }

  protected created(): void {
    document.addEventListener("keyup", this.fKeyUpHandler);
  }

  protected beforeDestroy(): void {
    document.removeEventListener("keyup", this.fKeyUpHandler);
  }

  private onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.$close("");
    }
  }

  private fKeyUpHandler = (e: KeyboardEvent) => this.onKeyUp(e);
}
