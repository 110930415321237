






























import { Component, Inject, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import RemotePrinterConfigurationConfigEditForm from "./RemotePrinterConfigurationPage/ConfigEditForm.vue";
import RemotePrinterConfigurationCredentialsEditForm from "./RemotePrinterConfigurationPage/CredentialsEditForm.vue";

@Component({
  metaInfo() {
    return {
      title: "Remote Print Configuration",
    };
  },
  components: {
    RemotePrinterConfigurationCredentialsEditForm,
    RemotePrinterConfigurationConfigEditForm,
  },
})
export default class RemotePrinterConfigurationPage extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fFactoryDataStore: FactoryStore;

  private fFactoryId?: string;

  get factoryId(): string | undefined {
    return this.fFactoryId;
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public onCredentialsUpdated(): void {
    const configEditForm = this.$refs["configEditForm"] as
      | RemotePrinterConfigurationConfigEditForm
      | undefined;

    if (!configEditForm) {
      return;
    }

    void configEditForm.refreshPrintersList();
  }

  protected data(): Record<string, unknown> {
    return {
      fFactoryId: this.fFactoryId,
    };
  }

  protected async created(): Promise<void> {
    const user = this.fUserProvider.getUser();

    if (!user || user.role !== AuthenticatedUser.ROLE_FACTORY) {
      throw Error("User should be assigned to a factory");
    }

    const userFactoryRelation = await this.fFactoryDataStore.loadUserFactoryRelationByUserId(
      { userId: user.id }
    );

    if (!userFactoryRelation) {
      throw Error("User should be assigned to a factory");
    }

    this.fFactoryId = userFactoryRelation.factory;
  }
}
