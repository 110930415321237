import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PlushieDesignInformation implements MutableValue {
  public isNew = true;

  public comment?: string;
  public commentTranslation?: string;
  public descriptionTranslation?: string;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(private fId: string) {
    [fId].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get updatedAt() {
    return this.fUpdatedAt;
  }
}
