import { MutableValue } from "@/modules/api/mutable-value.interface";

import { EntityType } from "./entity-type";
import { MarkupData } from "./markup-data";

export default class PhotoMarkup implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fImage: string,
    private fEntityType: EntityType,
    private fMarkup: MarkupData
  ) {
    [fId, fImage, fEntityType, fMarkup].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get image(): string {
    return this.fImage;
  }

  get entityType(): EntityType {
    return this.fEntityType;
  }

  get markup(): MarkupData {
    return this.fMarkup;
  }
}
