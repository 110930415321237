import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Projection from "./projection.model";

export default class ProjectionRepository extends BasicRepository<Projection> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Projection>,
    responseParser: ApiCollectionResponseParserService<Projection>
  ) {
    super("projections", api, objectBuilder, responseParser);
  }

  public async saveList({
    holidayPeriodId,
    items,
  }: {
    holidayPeriodId: number;
    items: Projection[];
  }): Promise<Projection[]> {
    const data: {
      holidayPeriodId: number;
      projections: Array<Record<string, string | number>>;
    } = { holidayPeriodId, projections: [] };

    items.forEach((item) => data.projections.push(this.prepareData(item)));

    const response = await this.api.post(this.resourceName, data);

    const collection = this.responseParser.parse(
      response.data,
      this.objectBuilder
    );
    return collection.getItems();
  }

  protected prepareData(item: Projection): Record<string, string | number> {
    const data: Record<string, string | number> = {
      id: item.id,
      factoryId: item.factoryId,
      productId: item.productId,
      value: item.value,
    };

    return data;
  }
}
