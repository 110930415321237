












import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import QualityInspectionStore from "../store";

@Component({
  components: {
    FormErrors,
    SubmitButton,
  },
})
export default class QualityInspectionApproveCommand extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fQualityInspectionDataStore: QualityInspectionStore;

  constructor() {
    super();
    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  protected async performSubmit() {
    await this.fQualityInspectionDataStore.approvePlushie(this.plushieId);
  }
}
