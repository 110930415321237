


























































































































import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import moment from "moment";
import { getModule } from "vuex-module-decorators";
import escapeHTML from "lodash.escape";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import MessagesHistoryAttachmentsGallery from "./AttachmentsGallery.vue";
import MessagesHistoryEditItem from "./EditItem.vue";

import MessagingStore from "../../store";
import Message from "../../message.model";
import { ChannelValue } from "../../channel.value";

enum UserRoleBadge {
  CUSTOMER = "C",
  FACTORY = "F",
  STORE = "B",
}

@Component({
  components: {
    InlineSpinner,
    MessagesHistoryAttachmentsGallery,
    MessagesHistoryEditItem,
  },
})
export default class MessagesHistoryItem extends Vue {
  @Prop({ required: true })
  public readonly messageId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fAccountDataStore: AccountStore;
  private fMessagingDataStore: MessagingStore;

  private fIsDeleting = false;
  private fIsEditMode = false;
  private fIsReadMarkUpdating = false;

  get canEditMessage(): boolean {
    return this.isMyGroupMessage;
  }

  get canManageMessageReadMark(): boolean {
    return !this.isMyGroupMessage;
  }

  get directionTitle(): string {
    if (!this.message) {
      return "";
    }

    if (!this.hasAccessToMultipleChannels) {
      return "";
    }

    if (this.message.channel === ChannelValue.PRIVATE) {
      return "[ private ]";
    }

    if (!this.isMyGroupMessage) {
      return "";
    }

    switch (this.message.channel) {
      case ChannelValue.STORE_CUSTOMER:
        return "[ to: customer ]";
      case ChannelValue.STORE_FACTORY:
        return "[ to: factory ]";
    }

    return "";
  }

  get channelClass(): string {
    if (!this.message) {
      return "";
    }

    switch (this.message.channel) {
      case ChannelValue.STORE_CUSTOMER:
        return "-store-customer-channel";
      case ChannelValue.STORE_FACTORY:
        return "-store-factory-channel";
      case ChannelValue.PRIVATE:
        return "-private";
    }

    return "";
  }

  get createdAt(): string {
    if (!this.message) {
      return "";
    }

    const date = moment(this.message.createdAt);
    if (date.isSame(moment(), "day")) {
      return date.format("LT");
    } else {
      return date.format("ll LT");
    }
  }

  get isEditMode(): boolean {
    return this.fIsEditMode;
  }

  get isMyGroupMessage(): boolean {
    if (!this.message) {
      return false;
    }

    if (this.message.channel === ChannelValue.PRIVATE) {
      return true;
    }

    return this.message.isForwardDirection === this.isUserOnChannelFrontEdge();
  }

  get isUnread(): boolean {
    if (!this.message) {
      return false;
    }

    return !this.message.isRead;
  }

  get isDeleting(): boolean {
    return this.fIsDeleting;
  }

  get isReadMarkUpdating(): boolean {
    return this.fIsReadMarkUpdating;
  }

  get message(): Message | undefined {
    return this.fMessagingDataStore.getMessageById(this.messageId);
  }

  get sender(): string {
    if (!this.message) {
      return "";
    }

    const userInfo = this.fAccountDataStore.getUserInfoById(
      this.message.sender
    );

    if (!userInfo) {
      return "";
    }

    return userInfo.shortName;
  }

  get hasAccessToMultipleChannels(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_STORE,
      AuthenticatedUser.ROLE_ADMIN,
    ].includes(user.role);
  }

  get sanitizedMessageTranslation(): string | undefined {
    if (!this.message) {
      return;
    }

    return escapeHTML(this.message.textTranslation);
  }

  get showDirectionTitle(): boolean {
    return !!this.directionTitle;
  }

  get showSenderRole(): boolean {
    return !this.isMyGroupMessage;
  }

  get showTranslation(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get userRoleBadgeClass(): string {
    switch (this.userRoleBadge) {
      case UserRoleBadge.CUSTOMER:
        return "-customer";
      case UserRoleBadge.FACTORY:
        return "-factory";
      case UserRoleBadge.STORE:
        return "-store";
      default:
        return "";
    }
  }

  get userRoleBadge(): UserRoleBadge {
    if (!this.message) {
      throw new Error("Message is not defined");
    }

    if (this.message.isForwardDirection) {
      return UserRoleBadge.STORE;
    }

    switch (this.message.channel) {
      case ChannelValue.STORE_CUSTOMER:
        return UserRoleBadge.CUSTOMER;
      case ChannelValue.STORE_FACTORY:
        return UserRoleBadge.FACTORY;
      case ChannelValue.PRIVATE:
        return UserRoleBadge.STORE;
      default:
        throw new Error("Unknown channel");
    }
  }

  constructor() {
    super();

    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  async deleteMessage(): Promise<void> {
    if (!this.message || this.isDeleting) {
      return;
    }

    if (!confirm("Do you want to delete this message?")) {
      return;
    }

    this.fIsDeleting = true;

    try {
      await this.fMessagingDataStore.deleteMessage(this.message);
    } catch (e) {
      alert(e.message);
      throw e;
    } finally {
      this.fIsDeleting = false;
    }
  }

  editMessage(): void {
    const editItem = this.getEditItem();

    if (!editItem || this.fIsDeleting) {
      return;
    }

    editItem.switchToEditMode();
  }

  async toggleReadMark(value: boolean): Promise<void> {
    if (!this.message || this.fIsReadMarkUpdating) {
      return;
    }

    this.fIsReadMarkUpdating = true;

    try {
      value
        ? await this.fMessagingDataStore.markMessageAsRead(this.message.id)
        : await this.fMessagingDataStore.markMessageAsUnread(this.message.id);
    } catch (e) {
      alert(e.message);

      throw e;
    } finally {
      this.fIsReadMarkUpdating = false;
    }
  }

  public onEditModeChange(value: boolean): void {
    this.fIsEditMode = value;
  }

  private getEditItem(): MessagesHistoryEditItem | undefined {
    return this.$refs["edit-item"] as MessagesHistoryEditItem;
  }

  private isUserOnChannelFrontEdge(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return [
      AuthenticatedUser.ROLE_STORE,
      AuthenticatedUser.ROLE_ADMIN,
    ].includes(user.role);
  }
}
