import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import Plushie from "@/modules/plushie/plushie.model";
import { ResourceCollection } from "@/modules/api/resource.collection";

export default class PlushieQueueControlRepository extends BasicRepository<Plushie> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Plushie>,
    responseParser: ApiCollectionResponseParserService<Plushie>
  ) {
    super(
      "plushies/in_design_transfer_order",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async createInDesignTransferOrder(
    count: number
  ): Promise<ResourceCollection<Plushie>> {
    const response = await this.api.post(this.resourceName, {
      count: count,
    });

    return this.responseParser.parse(response.data, this.objectBuilder);
  }
}
