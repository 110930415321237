import { Store } from "vuex";

export default class StoreWiperService {
  private fStore: Store<any>;

  constructor(store: Store<any>) {
    this.fStore = store;
  }

  public wipe(): void {
    this.fStore.commit("resetState");
  }
}
