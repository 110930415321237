import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Factory from "./factory.model";

export default class FactoryRepository extends BasicRepository<Factory> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Factory>,
    responseParser: ApiCollectionResponseParserService<Factory>
  ) {
    super("factories", api, objectBuilder, responseParser);
  }

  protected prepareData(
    item: Factory
  ): Record<string, string | number | boolean> {
    const data: Record<string, string | number | boolean> = {
      id: item.id,
      email: item.email,
      name: item.name,
      isActive: item.isActive,
      language: +item.language,
      country: +item.country,
    };

    if (item.paSafetyNumber) {
      data.paSafetyNumber = item.paSafetyNumber;
    }

    return data;
  }
}
