








































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import rootStore from "@/store";
import UserInfo from "@/modules/account/user-info.model";
import AccountStore from "@/modules/account/store";
import CountryStore from "@/modules/country/store";

import BulkProjectStore from "../store";
import BulkProject from "../bulk-project.model";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class BulkProjectCustomerInfo extends Vue {
  @Prop({ required: true })
  public readonly projectId!: string;

  private fAccountStore: AccountStore;
  private fBulkProjectStore: BulkProjectStore;
  private fCountryStore: CountryStore;

  private fIsLoading = true;

  get country(): string {
    const country = this.fCountryStore.getCountryById(
      this.bulkProject.country.toString()
    );

    if (!country) {
      return "";
    }

    return country.name;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get bulkProject(): BulkProject {
    const bulkProject = this.fBulkProjectStore.getBulkProjectById(
      this.projectId
    );

    if (!bulkProject) {
      throw new Error(
        `Data is not loaded for bulk project with ID: ${this.projectId}`
      );
    }

    return bulkProject;
  }

  get email(): string {
    return this.userInfo.email;
  }

  get fullName(): string {
    return this.userInfo.fullName;
  }

  get userInfo(): UserInfo {
    const userInfo = this.fAccountStore.getUserInfoById(
      this.bulkProject.customer
    );

    if (!userInfo) {
      throw new Error("User info is not loaded");
    }

    return userInfo;
  }

  constructor() {
    super();
    this.fAccountStore = getModule(AccountStore, rootStore);
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
    this.fCountryStore = getModule(CountryStore, rootStore);
  }

  created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    if (!this.country) {
      await this.fCountryStore.loadCountryById(
        this.bulkProject.country.toString()
      );
    }

    this.fIsLoading = false;
  }
}
