


































































































import { Component, Prop, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import DatePicker from "vuejs-datepicker";
import autosize from "v-autosize";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import autoindent from "@/lib/directives/auto-indent.directive";
import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import CreatePaymentsFormSummary from "./CreatePaymentsForm/Summary.vue";

import FactoryInvoiceStore from "../store";
import Payment from "../payment.model";
import { PaymentType } from "../payment-type";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    DatePicker,
    CreatePaymentsFormSummary,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class CreatePaymentsForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly invoiceIds!: string[];

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public type?: string;
  public paymentDate?: Date;
  public confirmationNumber?: string;
  public notes?: string;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;

  get typeOptions(): { value: string; text: string }[] {
    return [
      {
        value: PaymentType.WIRE,
        text: PaymentType.WIRE,
      },
      {
        value: PaymentType.PAY_PAL,
        text: PaymentType.PAY_PAL,
      },
    ];
  }

  constructor() {
    super();

    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected async performSubmit(): Promise<void> {
    if (!this.type || !this.paymentDate || !this.confirmationNumber) {
      throw new Error("Not all required fields are filled!");
    }

    const payments: Payment[] = [];

    for (const invoiceId of this.invoiceIds) {
      const payment = new Payment(
        this.fIdGenerator.getId(),
        invoiceId,
        this.paymentDate,
        this.type,
        this.confirmationNumber
      );

      if (this.notes) {
        payment.notes = this.notes;
      }

      payments.push(payment);
    }

    await this.fFactoryInvoiceDataStore.savePayments(payments);

    this.$emit("formSubmitted");
  }

  protected data(): Record<string, unknown> {
    return {
      type: this.type,
      paymentDate: this.paymentDate,
      confirmationNumber: this.confirmationNumber,
      notes: this.notes,
    };
  }
}
