
































import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
// Import Vue FilePond
import vueFilePond, { VueFilePondComponent } from "vue-filepond";
import { File as FilePondFile } from "filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import image preview and file type validation plugins
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import ErrorConverterService from "@/modules/forms/error-converter.service";
import FileStorageStore from "@/modules/file-storage/store";
import dataStore from "@/store";
import { FileProcessingImageType } from "@/modules/file-storage/file-processing-image-type";
import { Dictionary } from "@/lib/Dictionary.type";
import IdGenerator from "@/lib/services/id-generator";

import PlushieStore from "../store";

// Create component
const FilePondComponent = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

interface FilePondErrorDescription {
  type: string;
  code: number;
  body: string;
}

@Component({
  components: {
    MoonLoader,
    FilePond: FilePondComponent,
  },
})
export default class ArtworkUpload extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public browseFiles(): void {
    const fileInput = this.getFileInput();
    if (!fileInput) {
      return;
    }

    fileInput.browse();
  }

  public async detectFileType(source: File, type: string) {
    if (type) {
      return type;
    }

    const extension = source.name.toLowerCase().split(".").pop();
    if (!extension) {
      return "";
    }

    return "image/" + extension;
  }

  public processUpload(
    fieldName: string,
    file: File,
    metadata: Dictionary<any>,
    load: (id: string) => void,
    error: (errorText: string) => void,
    progress: (
      lengthComputable: boolean,
      loaded: number,
      total: number
    ) => void,
    abort: () => void
    // transfer: (transferId: string) => void,
    // options: any
  ): { abort: () => void } | void {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);
    if (!plushie) {
      error("Plushie not found!!!");
      return;
    }

    let isAborted = false;

    void (async () => {
      try {
        const item = await this.fFileStorageDataStore.uploadFile({
          file,
          imageType: FileProcessingImageType.Artwork,
          product: plushie.product,
          onUploadProgress: (e: ProgressEvent) => {
            progress(e.lengthComputable, e.loaded, e.total);
          },
        });

        if (isAborted) {
          void this.fFileStorageDataStore.deleteItemById(item.id);
          return;
        }

        const artwork = await this.fPlushieDataStore.createPlushieImage({
          id: this.fIdGenerator.getId(),
          plushie: this.plushieId,
          storageItem: item.id,
        });

        load(artwork.id);
      } catch (e) {
        const errors = this.fErrorConverterService.describeError(e);
        error(errors[0]);
      }
    })();

    return {
      abort: () => {
        //request.abort();
        isAborted = true;
        abort();
      },
    };
  }

  public onFileProcessed(
    error: FilePondErrorDescription | null,
    file: FilePondFile
  ): void {
    if (error) {
      return;
    }

    setTimeout(() => {
      const fileInput = this.getFileInput();
      if (!fileInput) {
        return;
      }

      fileInput.removeFile(file);
    }, 200);
  }

  protected mounted(): void {
    const dropzone = this.getDropzone();
    const dropzoneOverlay = this.getDropzoneOverlay();

    if (!dropzone || !dropzoneOverlay) {
      return;
    }

    dropzone.addEventListener("dragover", this.fDragHoverHandler, {
      capture: true,
    });

    dropzoneOverlay.addEventListener("dragleave", this.fDragHoverHandler, {
      capture: true,
    });

    dropzoneOverlay.addEventListener("drop", this.fDragDropHandler, {
      capture: true,
    });
  }

  private getDropzone(): HTMLElement | undefined {
    return this.$refs["dropzone"] as HTMLElement;
  }

  private getDropzoneOverlay(): HTMLElement | undefined {
    return this.$refs["dropzone-overlay"] as HTMLElement;
  }

  private getFileInput(): VueFilePondComponent | undefined {
    return (this.$refs["file-input"] as unknown) as VueFilePondComponent;
  }

  private onDropzoneDragHover(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const dropzone = this.getDropzone();
    if (!dropzone) {
      return;
    }

    if (e.type == "dragover") {
      dropzone.classList.add("-drag-hover");
    } else {
      dropzone.classList.remove("-drag-hover");
    }
  }

  private async onDropzoneDrop(e: DragEvent): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const dropzone = this.getDropzone();
    if (dropzone) {
      dropzone.classList.remove("-drag-hover");
    }

    const fileInput = this.getFileInput();
    if (!e.dataTransfer || !fileInput) {
      return;
    }

    const droppedFiles = Array.from(e.dataTransfer.files);

    try {
      await fileInput.addFiles(droppedFiles);
    } catch (e) {
      //
    }
  }

  private fDragHoverHandler = (e: DragEvent) => this.onDropzoneDragHover(e);
  private fDragDropHandler = (e: DragEvent) => this.onDropzoneDrop(e);
}
