





















import { Component, Prop, Inject, Vue } from "vue-property-decorator";

import ActionButton from "@/lib/components/ActionButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import { Resource } from "@/modules/account/resource";

import CreatePaymentsService from "../create-payments.service";
import MassActionServiceInterface from "../mass-action-service.interface";

@Component({
  components: {
    FormErrors,
    ActionButton,
  },
})
export default class MassActionsCard extends Vue {
  @Prop({ required: true })
  public readonly selectedInvoices!: string[];

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("CreatePaymentsService")
  private fCreatePaymentsService!: MassActionServiceInterface;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fIsSubmitting = false;
  private fSubmitErrors: string[] = [];

  get actionOptions(): { id: string; name: string }[] {
    const actions: { id: string; name: string }[] = [];

    if (this.isCreatePaymentsActionAvailable) {
      actions.push({
        id: CreatePaymentsService.ID,
        name: "Pay Invoices",
      });
    }

    return actions;
  }

  get isAnyActionAvailable(): boolean {
    return this.actionOptions.length > 0;
  }

  get isCreatePaymentsActionAvailable(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_PAYMENTS_MANAGE
    );
  }

  get isDisabled(): boolean {
    return this.fIsSubmitting || !this.selectedInvoices.length;
  }

  get submitErrors(): string[] {
    return this.fSubmitErrors;
  }

  public async executeAction(action: string): Promise<void> {
    if (!this.fCreatePaymentsService || this.isDisabled) {
      return;
    }

    this.fIsSubmitting = true;
    this.fSubmitErrors = [];

    let isExecuted = false;

    this.$emit("processingStarted");

    try {
      switch (action) {
        case CreatePaymentsService.ID:
          isExecuted = await this.fCreatePaymentsService.execute(
            this.selectedInvoices
          );
          break;
        default:
          this.fSubmitErrors = ["Unknown action"];
      }

      this.$emit("processingFinished");

      if (isExecuted) {
        this.$emit("actionProcessed", action);
      }
    } catch (error) {
      this.fSubmitErrors = this.fErrorConverterService.describeError(error);
      this.$emit("processingFinished");
    } finally {
      this.fIsSubmitting = false;
    }
  }
}
