





























import { Component, Prop, Watch, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import RemotePrintingCredentialsRepository from "../../remote-printing-credentials.repository";
import RemotePrintingCredentials from "../../remote-printing-credentials.model";

@Component({
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class RemotePrinterConfigurationCredentialsEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly factoryId?: string;

  @Inject("RemotePrintingCredentialsRepository")
  private fCredentialsRepository!: RemotePrintingCredentialsRepository;

  public token?: string;

  private fIsLoading = true;
  private fIsNew = true;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  protected data() {
    return {
      token: this.token,
    };
  }

  protected async performSubmit() {
    if (!this.factoryId || !this.token) {
      return;
    }

    let credentials = new RemotePrintingCredentials(this.factoryId, this.token);

    credentials.isNew = this.fIsNew;

    credentials = await this.fCredentialsRepository.save(credentials);

    this.fIsNew = false;

    this.$emit("updated");
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    this.fIsLoading = true;

    this.token = undefined;
    this.fIsNew = true;

    if (this.factoryId === undefined) {
      return;
    }

    try {
      const credentials = await this.fCredentialsRepository.getById(
        this.factoryId
      );

      if (!credentials) {
        return;
      }

      this.fIsNew = false;

      this.token = credentials.token;
    } finally {
      this.fIsLoading = false;
    }
  }
}
