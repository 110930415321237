
































































import { Component, Vue, Inject, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  RowClickEventData,
  ServerResponse,
  ColumnFilterDropdownOption,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";

import PlushieStore from "../../plushie/store";
import QualityInspectionStore from "../store";

interface TableRow {
  id: string;
  name: string;
  type: string;
  isActive: boolean;
  associatedProducts: string;
}

@Component({
  metaInfo: {
    title: "QA Rejection Reasons Management",
  },
  components: {},
})
export default class RejectionReasonsListPage extends mixins(
  GeneralListPageMixin
) {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fPlushieDataStore: PlushieStore;
  private fQualityInspectionDataStore: QualityInspectionStore;

  private fShowInactiveRejectionReasons = false;

  get canManageRejectionReasons(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.REJECTION_REASONS_MANAGE);
  }

  get showInactiveRejectionReasons(): boolean {
    return this.fShowInactiveRejectionReasons;
  }

  get showInactiveRejectionReasonsText(): string {
    return this.fShowInactiveRejectionReasons
      ? "Hide inactive"
      : "Show inactive";
  }

  public constructor() {
    super();

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Text",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "type",
        header: "Type",
        cssClass: "_type-column -text",
        isFilterable: true,
        isFilterFromList: true,
        isSortable: true,
        sortKey: "type.name",
      },
      {
        name: "isActive",
        header: "Active",
        cssClass: "_isactive-column -checkbox",
        isFilterable: true,
        isFilterFromList: true,
        filterValues: [
          { id: "true", text: "Yes" },
          { id: "false", text: "No" },
        ],
      },
      {
        name: "associatedProducts",
        header: "Associated products",
        cssClass: "_associatedproducts-column -text",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "productRelations.productId",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "name",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    if (!this.canManageRejectionReasons) {
      return;
    }

    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "RejectionReasonEditPage"));
  }

  public toggleShowInactiveRejectionReasons(): void {
    this.fShowInactiveRejectionReasons = !this.fShowInactiveRejectionReasons;
  }

  public async deleteItem(id: string): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fQualityInspectionDataStore.deleteRejectionReasonById(id);

      if (this.fTable) {
        this.fTable.refresh();
      }
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const [rejectionReasonsCollection] = await Promise.all([
      this.fQualityInspectionDataStore.loadRejectionReasons({
        page,
        limit,
        filter,
        order,
      }),
      this.fQualityInspectionDataStore.loadRejectionReasonTypes(),
      this.fPlushieDataStore.loadProducts(),
    ]);

    const result: TableRow[] = [];

    const rejectionReasonsTypes = this.fQualityInspectionDataStore
      .rejectionReasonTypesDictionary;

    const products = this.fPlushieDataStore.productsDictionary;

    const rejectionReasons = rejectionReasonsCollection.getItems();

    const rejectionReasonIds = rejectionReasons.map((reason) => reason.id);

    await this.fQualityInspectionDataStore.loadRejectionReasonProductRelationsByReasonIds(
      { reasonIds: rejectionReasonIds, useCache: false }
    );

    rejectionReasons.forEach((rejectionReason) => {
      const type = rejectionReasonsTypes[rejectionReason.type];

      const relations = this.fQualityInspectionDataStore.getRejectionReasonProductRelationsByReasonId(
        rejectionReason.id
      );

      const associatedProductsColumnValue =
        relations.length > 0
          ? relations
              .map((relation) => products[relation.product])
              .sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              .join(", ")
          : "";

      result.push({
        id: rejectionReason.id,
        name: rejectionReason.name,
        type: type ? type.name : "",
        isActive: rejectionReason.isActive,
        associatedProducts: associatedProductsColumnValue,
      });
    });

    return { data: result, count: rejectionReasonsCollection.totalItemsCount };
  }

  protected async created(): Promise<void> {
    await Promise.all([
      this.fQualityInspectionDataStore.loadRejectionReasonTypes(),
      this.fPlushieDataStore.loadProducts(),
    ]);

    const reasonTypes = this.fQualityInspectionDataStore.rejectionReasonTypes;
    const products = this.fPlushieDataStore.activeProductsList;

    const reasonTypeOptions: ColumnFilterDropdownOption[] = [];

    reasonTypes.forEach((item) => {
      reasonTypeOptions.push({ id: item.id, text: item.name });
    });

    const associatedProductOptions: ColumnFilterDropdownOption[] = [];

    products.forEach((item) => {
      associatedProductOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "type", reasonTypeOptions);
      Vue.set(
        this.fOptions.listColumns,
        "associatedProducts",
        associatedProductOptions
      );
    }
  }

  protected getExtraParams(): Dictionary<string | number | boolean> {
    return this.fShowInactiveRejectionReasons
      ? {}
      : {
          isActive: true,
        };
  }

  @Watch("showInactiveRejectionReasons", { immediate: true })
  private _onShowInactiveRejectionReasonsChange(): void {
    if (!this.fTable) {
      return;
    }

    this.fTable.refresh();
  }
}
