








































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";

import FactoryInvoiceStore from "../../store/index";
import Payment from "../../payment.model";

@Component({
  components: {},
})
export default class FactoryInvoiceViewPaymentSummary extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  private fAccountStore: AccountStore;
  private fFactoryInvoiceStore: FactoryInvoiceStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get payment(): Payment | undefined {
    return this.fFactoryInvoiceStore.getPaymentByInvoiceId(this.invoiceId);
  }

  constructor() {
    super();

    this.fAccountStore = getModule(AccountStore, dataStore);
    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public getFormattedPaymentDate(payment: Payment): string {
    return payment.paidAt.toLocaleDateString();
  }

  public getPaymentCreator(payment: Payment): string {
    if (!payment.user) {
      return "";
    }

    const userInfo = this.fAccountStore.getUserInfoById(payment.user);

    if (userInfo === undefined) {
      return "";
    }

    return `${userInfo.email}`;
  }

  private async loadData(invoiceId: string): Promise<void> {
    const payment = await this.fFactoryInvoiceStore.loadPaymentByInvoiceId({
      invoiceId,
    });

    if (payment && payment.user) {
      await Promise.all([
        this.fAccountStore.loadUserInfosByIds([payment.user]),
        this.fAccountStore.loadUserRolesByUserId({
          userIds: [payment.user],
        }),
        this.fAccountStore.loadRoles(),
      ]);
    }
  }

  @Watch("invoiceId", { immediate: true })
  private async _onInvoiceIdChange() {
    if (!this.invoiceId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.loadData(this.invoiceId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
