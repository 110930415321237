import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Value implements MutableValue {
  public isNew = true;

  private fStorageItem?: string;
  private fColor?: string;
  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fBodyPart: string,
    private fType: string,
    private fName: string,
    private fSn: number,
    private fIsEnabled: boolean
  ) {
    [fId, fBodyPart, fType, fName, fSn, fIsEnabled].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get bodyPart(): string {
    return this.fBodyPart;
  }

  get type(): string {
    return this.fType;
  }

  get name(): string {
    return this.fName;
  }

  get storageItem(): string | undefined {
    return this.fStorageItem;
  }

  get color(): string | undefined {
    return this.fColor;
  }

  get sn(): number {
    return this.fSn;
  }

  get isEnabled(): boolean {
    return this.fIsEnabled;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
