import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class BulkShipmentItem implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fShipment: string,
    private fPlushie: string,
    private fQty: number
  ) {
    [fId, fShipment, fPlushie, fQty].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get shipment(): string {
    return this.fShipment;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get qty(): number {
    return this.fQty;
  }
}
