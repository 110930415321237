










import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FormErrors extends Vue {
  @Prop({
    default: () => {
      return [];
    },
  })
  public formErrors!: string[];
}
