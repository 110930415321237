



























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";

import SameOrderPlushieItem from "./SameOrderPlushies/Item.vue";

import PlushieStore from "../store";

@Component({
  components: {
    MoonLoader,
    SameOrderPlushieItem,
  },
})
export default class SameOrderPlushies extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fIsLoading = true;

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get orderInformation() {
    return this.fPlushieDataStore.getOrderInformationByPlushieId(
      this.plushieId
    );
  }

  get sameOrderPlushieIds() {
    if (!this.orderInformation) {
      return [];
    }

    const sameOrders = this.fPlushieDataStore.getOrderInformationsByOrderId(
      this.orderInformation.orderId
    );

    const plushieIds = sameOrders
      .filter((order) => order.id !== this.plushieId)
      .map((order) => order.id);

    return plushieIds;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  private async getPlushieData(plushieId: string) {
    const orderInformation = await this.fPlushieDataStore.loadOrderInformationByPlushieId(
      plushieId
    );

    if (!orderInformation) {
      return;
    }

    const sameOrders = await this.fPlushieDataStore.loadOrderInformationsByOrderId(
      orderInformation.orderId
    );

    if (!sameOrders.length) {
      return;
    }

    const sameOrderPlushieIds = sameOrders
      .filter((order) => order.id !== plushieId)
      .map((order) => order.id);

    const [mainImages] = await Promise.all([
      this.fPlushieDataStore.loadPlushieMainImageByPlushieIds(
        sameOrderPlushieIds
      ),
      this.fPlushieDataStore.loadPlushiesByIds(sameOrderPlushieIds),
    ]);

    const imageStoreItemIds: string[] = [];
    Object.keys(mainImages).forEach((plushieId) => {
      imageStoreItemIds.push(mainImages[plushieId].storageItem);
    });

    await this.fFileStorageDataStore.loadItemsByIds(imageStoreItemIds);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);

    this.fIsLoading = false;
  }
}
