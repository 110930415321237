import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import dataStore from "@/store";

import CreatePaymentsDialog from "./components/CreatePaymentsDialog.vue";

import MassActionServiceInterface from "./mass-action-service.interface";
import FactoryInvoiceStore from "./store";
import { FactoryInvoiceStatusValue } from "./factory-invoice-status.value";
import FactoryInvoice from "./factory-invoice.model";

export default class CreatePaymentsService
  implements MassActionServiceInterface {
  public static readonly ID = "createPayments";

  private fFactoryInvoiceStore: FactoryInvoiceStore;

  constructor() {
    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public async execute(invoiceIds: string[]): Promise<boolean> {
    if (invoiceIds.length < 1) {
      return false;
    }

    const invoices: FactoryInvoice[] = [];

    await this.fFactoryInvoiceStore.loadFactoryInvoicesByIds({ invoiceIds });

    invoiceIds.forEach((invoiceId) => {
      const invoice = this.fFactoryInvoiceStore.getFactoryInvoiceById(
        invoiceId
      );

      if (!invoice) {
        throw new Error("Invoice corresponding to payment is not found");
      }

      if (invoice.status !== FactoryInvoiceStatusValue.UNPAID) {
        throw new Error("All invoices should be unpaid");
      }

      invoices.push(invoice);
    });

    const dialogFunction = create<Record<string, unknown>, boolean>(
      CreatePaymentsDialog
    );

    return await dialogFunction({
      invoiceIds: invoiceIds,
    });
  }
}
