


















































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";

import QuestionTemplateEditForm from "./QuestionTemplateEditForm.vue";
import QuestionTemplateStatusesEditForm from "./QuestionTemplateStatusesEditForm.vue";
import QuestionTemplateProductsEditForm from "./QuestionTemplateProductsEditForm.vue";

import MessagingStore from "../../store";
import QuestionTemplateRepository from "../../question-template.repository";
import QuestionTemplate from "../../question-template.model";

@Component({
  metaInfo() {
    return {
      title: "Question Template Edit",
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    QuestionTemplateEditForm,
    QuestionTemplateProductsEditForm,
    QuestionTemplateStatusesEditForm,
  },
})
export default class QuestionTemplateEditPage extends Vue {
  @Prop()
  public readonly questionTemplateId!: string;

  @Prop()
  public readonly query!: Record<string, any>;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("QuestionTemplateRepository")
  private fQuestionTemplateRepository!: QuestionTemplateRepository;

  private fMessagingDataStore: MessagingStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get questionTemplate(): QuestionTemplate | undefined {
    return this.fMessagingDataStore.getQuestionTemplateById(
      this.questionTemplateId
    );
  }

  public constructor() {
    super();

    this.fMessagingDataStore = getModule(MessagingStore, dataStore);
  }

  public onCancel(): void {
    void this.$router.push({
      name: "QuestionTemplatesListPage",
      query: this.listQuery,
    });
  }

  public onQuestionTemplateUpdated(): void {
    void this.$router.push({
      name: "QuestionTemplatesListPage",
      query: this.listQuery,
    });
  }

  public getQuestionTemplatesOrder(): Promise<string[]> {
    return this.fQuestionTemplateRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  @Watch("questionTemplateId", { immediate: true })
  private async _onQuestionTemplateIdChange() {
    if (this.questionTemplateId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fMessagingDataStore.loadQuestionTemplateById(
        this.questionTemplateId
      );

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
