import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class ActionLogRecord implements MutableValue {
  public isNew = true;

  public constructor(
    private fId: string,
    private fCreator: string,
    private fAction: string,
    private fPlushie: string,
    private fShortDescription: string,
    private fDescription: string,
    private fCreatedAt: Date
  ) {
    [
      fId,
      fCreator,
      fAction,
      fPlushie,
      fShortDescription,
      fDescription,
      fCreatedAt,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get creator() {
    return this.fCreator;
  }

  get action() {
    return this.fAction;
  }

  get plushie() {
    return this.fPlushie;
  }

  get shortDescription() {
    return this.fShortDescription;
  }

  get description() {
    return this.fDescription;
  }
}
