import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class QaDecision implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fFactory: string,
    private fAuthor: string,
    private fIsApprove: boolean
  ) {
    [fId, fPlushie, fFactory, fAuthor, fIsApprove].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get factory(): string {
    return this.fFactory;
  }

  get author(): string {
    return this.fAuthor;
  }

  get isApprove(): boolean {
    return this.fIsApprove;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
