






















































import { Component, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { RowClickEventData, ServerResponse } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";

import RegularOrdersAssignDialog from "./RegularOrdersAssignDialog.vue";

import HolidayPeriodStore from "../store";
import RegularOrdersAssignJobRepository from "../regular-orders-assign-job.repository";

interface TableRow {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

@Component({
  metaInfo: {
    title: "Holiday Periods management",
  },
  components: {
    ActionButton,
    RegularOrdersAssignDialog,
  },
})
export default class HolidayPeriodsListPage extends mixins(
  GeneralListPageMixin
) {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("HolidayPeriodRegularOrdersAssignJobRepository")
  private fRegularOrdersAssignJobRepository!: RegularOrdersAssignJobRepository;

  private fHolidayPeriodDataStore: HolidayPeriodStore;

  get canView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.HOLIDAY_PERIOD_VIEW);
  }

  get showOrdersAssignCommands(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.HOLIDAY_PERIOD_PLUSHIE_RELATION_MANAGE
    );
  }

  public constructor() {
    super();

    this.fHolidayPeriodDataStore = getModule(HolidayPeriodStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
      },
      {
        name: "createdAt",
        header: "Created",
        cssClass: "_createdat-column",
        isSortable: true,
        displayConstraint: "min_tabletL",
      },
      {
        name: "updatedAt",
        header: "Updated",
        cssClass: "_updatedat-column",
        displayConstraint: "min_tabletL",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterable: [],
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "createdAt",
        ascending: false,
      },
      initialPage: 1,
      perPage: 999,
    };
  }

  public async assignOrders(periodId: string): Promise<void> {
    const dialogFunction = create<Record<string, unknown>, boolean>(
      RegularOrdersAssignDialog
    );

    await dialogFunction({
      periodId,
    });
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "HolidayPeriodEditPage"));
  }

  protected async unassignRegularOrders(): Promise<void> {
    await this.fRegularOrdersAssignJobRepository.unassign();
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const holidayPeriodsCollection = await this.fHolidayPeriodDataStore.loadFHolidayPeriodsWithFilter(
      {
        filter,
        order,
      }
    );

    const result: TableRow[] = [];

    holidayPeriodsCollection.getItems().forEach((holidayPeriod) => {
      result.push({
        id: holidayPeriod.id,
        name: holidayPeriod.name,
        createdAt: holidayPeriod.createdAt
          ? holidayPeriod.createdAt.toLocaleDateString()
          : "",
        updatedAt: holidayPeriod.updatedAt
          ? holidayPeriod.updatedAt.toLocaleDateString()
          : "",
      });
    });

    return { data: result, count: holidayPeriodsCollection.totalItemsCount };
  }
}
