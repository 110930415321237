











































































































import Vue from "vue";
import { mixins } from "vue-class-component";
import { Component, Inject } from "vue-property-decorator";
import DatePicker from "vuejs-datepicker";
import { getModule } from "vuex-module-decorators";
import {
  ClientTableInstance,
  ColumnFilterDropdownOption,
  TableOptions,
} from "vue-tables-2-premium";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import { Dictionary } from "@/lib/Dictionary.type";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import PlushieStore from "@/modules/plushie/store";
import dataStore from "@/store";

import ExtraPaymentRepository from "../../extra-payment.repository";
import FactoryInvoiceStore from "../../store";
import { ExtraPaymentFaultValue } from "../../extra-payment-fault.value";

interface TableRow {
  id: string;
  plushie: {
    id?: string;
    storeItemId?: string;
  };
  invoice: {
    id?: string;
    number?: string;
  };
  reason: string;
  charge: number;
  author: string;
  fault: {
    id?: string;
    name?: string;
  };
  date: string;
}

interface TotalSection {
  customerFaults: number;
  storeFaults: number;
  noOnesFaults: number;
  totalFaults: number;
}

@Component({
  components: {
    DatePicker,
    FormErrors,
    FormField,
    SubmitButton,
  },
})
export default class ExtraPaymentsReport extends mixins(GeneralFormMixin) {
  @Inject("ExtraPaymentRepository")
  private fExtraPaymentRepository!: ExtraPaymentRepository;

  public fromDate?: Date;
  public toDate?: Date;

  private fOptions: TableOptions = {};
  private fTableData: TableRow[] = [];
  private fTotalSectionData: TotalSection = {
    customerFaults: 0,
    storeFaults: 0,
    noOnesFaults: 0,
    totalFaults: 0,
  };

  private fFactoryInvoiceStore: FactoryInvoiceStore;
  private fPlushieStore: PlushieStore;

  get columns(): string[] {
    return [
      "plushie.storeItemId",
      "invoice.number",
      "reason",
      "charge",
      "author",
      "fault.name",
      "date",
    ];
  }

  get options(): TableOptions {
    return this.fOptions;
  }

  get tableData(): TableRow[] {
    return this.fTableData;
  }

  get totalSectionData(): TotalSection {
    return this.fTotalSectionData;
  }

  get showReportTable(): boolean {
    return !!this.fTableData.length;
  }

  public constructor() {
    super();

    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
    this.fPlushieStore = getModule(PlushieStore, dataStore);

    this.fOptions = {
      columnsClasses: {
        "plushie.storeItemId": "_plushie-column",
        "invoice.number": "_invoice-column",
        reason: "_reason-column",
        charge: "_charge-column",
        author: "_author-column",
        "fault.name": "_fault-column",
        date: "_date-column",
      },
      filterable: ["author", "fault.name", "reason"],
      filterByColumn: true,
      headings: {
        "plushie.storeItemId": "Item",
        "invoice.number": "Invoice",
        reason: "Reason",
        charge: "Charge",
        author: "Author",
        "fault.name": "Fault",
        date: "Date",
      },
      initialPage: 1,
      listColumns: {},
      orderBy: {
        column: "date",
        ascending: false,
      },
      perPage: 30,
      perPageValues: [10, 20, 30, 40, 50, 100],
    };
  }

  public getInvoiceUrl(invoiceNumber: string): string {
    const route = this.$router.resolve({
      name: "FactoryInvoiceViewPage",
      query: {
        id: invoiceNumber,
      },
    });

    return route.href;
  }

  public onTableFiltered(): void {
    this.updateTotals();
  }

  public getPlushieUrl(plushieId: string): string {
    const route = this.$router.resolve({
      name: "PlushieViewPage",
      query: {
        id: plushieId,
      },
    });

    return route.href;
  }

  protected data(): Record<string, unknown> {
    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
    };
  }

  protected async created(): Promise<void> {
    await Promise.all([
      this.fFactoryInvoiceStore.loadExtraPaymentAuthors(),
      this.fFactoryInvoiceStore.loadExtraPaymentFaults(),
    ]);
  }

  protected async performSubmit(): Promise<void> {
    if (!this.fromDate || !this.toDate) {
      throw new Error("Not all required fields are filled!");
    }

    if (this.toDate < this.fromDate) {
      throw new Error("'To' date can't be less than 'from' date");
    }

    this.fromDate.setHours(0, 0, 0, 0);
    this.toDate.setHours(23, 59, 59, 999);

    await this.loadTableData(this.fromDate, this.toDate);
  }

  private calculateTotals(tableData: TableRow[]): TotalSection {
    const totalData: TotalSection = {
      customerFaults: 0,
      storeFaults: 0,
      noOnesFaults: 0,
      totalFaults: 0,
    };

    tableData.forEach((row) => {
      switch (row.fault.id) {
        case "":
          totalData.noOnesFaults += row.charge;
          break;
        case ExtraPaymentFaultValue.BUDSIES:
          totalData.storeFaults += row.charge;
          break;
        case ExtraPaymentFaultValue.CUSTOMER:
          totalData.customerFaults += row.charge;
          break;
      }

      totalData.totalFaults += row.charge;
    });

    return totalData;
  }

  private async loadTableData(fromDate: Date, toDate: Date): Promise<void> {
    const result: TableRow[] = [];

    const extraPaymentsCollection = await this.fExtraPaymentRepository.getListByDateRange(
      fromDate,
      toDate
    );

    const extraPayments = extraPaymentsCollection.getItems();

    if (!extraPayments) {
      this.fTableData = result;
      return;
    }

    const plushieIds: Set<string> = new Set();
    const invoiceIds: Set<string> = new Set();

    extraPayments.forEach((item) => {
      if (item.plushie) {
        plushieIds.add(item.plushie);
      }

      if (item.invoice) {
        invoiceIds.add(item.invoice);
      }
    });

    await Promise.all([
      this.fPlushieStore.loadPlushiesByIds(Array.from(plushieIds)),
      this.fFactoryInvoiceStore.loadFactoryInvoicesByIds({
        invoiceIds: Array.from(invoiceIds),
      }),
    ]);

    const faultsInReport: Dictionary<string> = { "No one's": "No one's" };
    const authorsInReport: Dictionary<string> = {};

    extraPayments.forEach((item) => {
      let author;
      let fault;
      let invoice;
      let plushie;

      if (item.author) {
        author = this.fFactoryInvoiceStore.getExtraPaymentAuthorById(
          item.author
        );
      }

      if (item.fault) {
        fault = this.fFactoryInvoiceStore.getExtraPaymentFaultById(item.fault);
      }

      if (item.invoice) {
        invoice = this.fFactoryInvoiceStore.getFactoryInvoiceById(item.invoice);
      }

      if (item.plushie) {
        plushie = this.fPlushieStore.getPlushieById(item.plushie);
      }

      if (author) {
        authorsInReport[author.id] = author.name;
      }

      if (fault) {
        faultsInReport[fault.id] = fault.name;
      }

      const row: TableRow = {
        id: item.id,
        plushie: {
          id: item.plushie,
          storeItemId: plushie ? plushie.storeItemId : "",
        },
        invoice: {
          id: item.invoice,
          number: invoice ? invoice.number : "",
        },
        reason: item.description,
        charge: item.amount,
        author: author ? author.name : "",
        fault: {
          id: item.fault ? item.fault : "",
          name: fault ? fault.name : "No one's",
        },
        date: item.createdAt ? item.createdAt.toLocaleDateString() : "",
      };

      result.push(row);
    });

    this.setColumnFilterOptions(authorsInReport, "author");
    this.setColumnFilterOptions(faultsInReport, "fault.name");

    this.fTableData = result;

    this.updateTotals();
  }

  private setColumnFilterOptions(
    items: Dictionary<string>,
    column: string
  ): void {
    const options: ColumnFilterDropdownOption[] = [];

    Object.values(items).forEach((value: string) => {
      options.push({ id: value, text: value });
    });

    options.sort((a, b) => (a.text > b.text ? 1 : -1));

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, column, options);
    }
  }

  private updateTotals(): void {
    this.$nextTick(() => {
      if (!this.$refs.listTable) {
        return;
      }

      this.fTotalSectionData = this.calculateTotals(
        ((this.$refs.listTable as unknown) as ClientTableInstance)
          .allFilteredData
      );
    });
  }
}
