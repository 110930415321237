



































































import Vue from "vue";
import { Component, Inject, Mixins } from "vue-property-decorator";
import {
  ColumnFilterDropdownOption,
  RowClickEventData,
  ServerResponse,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { Dictionary } from "@/lib/Dictionary.type";
import rootStore from "@/store";
import AccountStore from "@/modules/account/store";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import PlushieStore from "@/modules/plushie/store";
import UiConfigurationStore from "@/modules/ui-configuration/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import FileStorageStore from "@/modules/file-storage/store";
import { MetaRoleValue } from "@/modules/account/meta-role.value";
import UserInfoRepository from "@/modules/account/user-info.repository";

import BulkProjectRepository from "../bulk-project.repository";
import BulkProjectStore from "../store";

interface TableRow {
  id: string;
  incrementalId: number;
  createdAt: Date;
  artwork: string;
  status: string;
  quantity: number;
  product: string;
  deadlineDate?: Date;
  customerName: string;
  customerEmail: string;
  responsibleUser: string;
}

const maxCustomerIdsCount = 150;

@Component({
  metaInfo() {
    const component = this as BulkProjectsListPage;

    return {
      title: component.pageTitle,
    };
  },
})
export default class BulkProjectsListPage extends Mixins(GeneralListPageMixin) {
  private static readonly ARTWORK_SIZE = 150;

  @Inject("BulkProjectRepository")
  private fBulkProjectRepository!: BulkProjectRepository;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("UserInfoRepository")
  private fUserInfoRepository!: UserInfoRepository;

  public readonly pageTitle = "Bulk Projects List";

  private fAccountStore: AccountStore;
  private fBulkProjectStore: BulkProjectStore;
  private fFileStorageStore: FileStorageStore;
  private fPlushieStore: PlushieStore;
  private fUiConfigurationStore: UiConfigurationStore;

  private fFiltersError?: string;

  get displayArtworksCtrlText(): string {
    return this.fUiConfigurationStore.bulkProjectListDisplayImages
      ? "Hide artworks"
      : "Display artworks";
  }

  get filtersError(): string | undefined {
    return this.fFiltersError;
  }

  get shouldDisplayArtworks(): boolean {
    return this.fUiConfigurationStore.bulkProjectListDisplayImages;
  }

  constructor() {
    super();
    this.fAccountStore = getModule(AccountStore, rootStore);
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
    this.fFileStorageStore = getModule(FileStorageStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fUiConfigurationStore = getModule(UiConfigurationStore, rootStore);

    this.fColumns = [
      {
        name: "incrementalId",
        header: "ID",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "createdAt",
        header: "Created At",
        isSortable: true,
        displayConstraint: "min_tablet",
      },
      {
        name: "artwork",
        header: "Art",
        displayConstraint: "min_tablet",
        cssClass: "_artwork-column",
      },
      {
        name: "status",
        header: "Status",
        isFilterable: true,
        isFilterFromList: true,
      },
      {
        name: "quantity",
        header: "Qty",
        displayConstraint: "min_tabletL",
      },
      {
        name: "deadlineDate",
        header: "Deadline",
        displayConstraint: "min_desktop",
      },
      {
        name: "customerName",
        header: "Customer Name",
        isFilterable: true,
        displayConstraint: "min_desktop",
      },
      {
        name: "customerEmail",
        header: "Customer Email",
        isFilterable: true,
        cssClass: "_customer-email",
        displayConstraint: "min_desktopLarge",
      },
      {
        name: "product",
        header: "Product",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "product",
      },
      {
        name: "responsibleUser",
        header: "Responsible User",
        isFilterable: true,
        isFilterFromList: true,
        displayConstraint: "min_desktop",
        filterKey: "responsibleUserId",
      },
      {
        name: "actions",
        header: "Action",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
      orderBy: { column: "createdAt", ascending: false },
      templates: {
        createdAt: (h, row: TableRow) => {
          return row.createdAt.toLocaleDateString();
        },
        deadlineDate: (h, row: TableRow) => {
          return row.deadlineDate ? row.deadlineDate.toLocaleDateString() : "";
        },
      },
    };
  }

  public getThumbnail(artworkStorageItemId?: string): string {
    const storageItem = artworkStorageItemId
      ? this.fFileStorageStore.getItemById(artworkStorageItemId)
      : null;

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      BulkProjectsListPage.ARTWORK_SIZE,
      BulkProjectsListPage.ARTWORK_SIZE,
      true
    );
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    data.event.preventDefault();

    void this.$router.push(this.getItemUrl(data.row.id, "BulkProjectViewPage"));
  }

  public toggleDisplayArtworks(): void {
    this.fUiConfigurationStore.toggleBulkProjectListDisplayImages();
  }

  protected data(): Record<string, string | undefined> {
    return {
      fFiltersError: this.fFiltersError,
    };
  }

  protected async init(): Promise<void> {
    if (!this.fOptions.listColumns) {
      return;
    }

    const [usersInfoList] = await Promise.all([
      this.fAccountStore.loadUserInfosByMetaRoleIds([MetaRoleValue.ROLE_STORE]),
      this.fPlushieStore.loadProducts(),
      this.fBulkProjectStore.loadBulkProjectStatuses(true),
    ]);

    const bulkProjectStatuses = this.fBulkProjectStore.bulkProjectStatuses;
    const products = this.fPlushieStore.batchesProductsList;
    const sortedUsers = usersInfoList.sort((a, b) =>
      a.shortName.toLowerCase() > b.shortName.toLowerCase() ? 1 : -1
    );

    const bulkProjectStatusesOptions: ColumnFilterDropdownOption[] = [];
    bulkProjectStatuses.forEach((item) => {
      bulkProjectStatusesOptions.push({
        id: item.id,
        text: item.name,
      });
    });

    const productOptions: ColumnFilterDropdownOption[] = [];
    products.forEach((item) => {
      productOptions.push({ id: item.id, text: item.name });
    });

    const responsibleUsersOptions: ColumnFilterDropdownOption[] = [];
    sortedUsers.forEach((item) => {
      responsibleUsersOptions.push({ id: item.id, text: item.shortName });
    });

    Vue.set(this.fOptions.listColumns, "status", bulkProjectStatusesOptions);
    Vue.set(this.fOptions.listColumns, "product", productOptions);
    Vue.set(
      this.fOptions.listColumns,
      "responsibleUser",
      responsibleUsersOptions
    );
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    this.fFiltersError = undefined;

    if (filter.customerName || filter.customerEmail) {
      const userInfoFilter: Dictionary<string | boolean> = {
        isAutoGenerated: true,
      };

      if (filter.customerName) {
        userInfoFilter.fullName = filter.customerName as string;
      }

      if (filter.customerEmail) {
        userInfoFilter.email = filter.customerEmail as string;
      }

      const userIds = await this.fUserInfoRepository.getListOfIds(
        undefined,
        userInfoFilter
      );

      if (!userIds.length) {
        return { data: [], count: 0 };
      }

      if (userIds.length >= maxCustomerIdsCount) {
        this.fFiltersError =
          "Customer filter has returned too many records. Please specify a more precise value.";
        return { data: [], count: 0 };
      }

      filter.customerId = userIds;
    }

    const [bulkProjectsCollection] = await Promise.all([
      this.fBulkProjectRepository.getList(page, limit, filter, order),
    ]);

    const bulkProjects = bulkProjectsCollection.getItems();

    const userInfoIdSet: Set<string> = new Set();
    const artworkStorageItemsIds: string[] = [];

    bulkProjects.forEach((item) => {
      userInfoIdSet.add(item.customer);
      artworkStorageItemsIds.push(item.imageStorageItemId);

      if (item.responsibleUserId) {
        userInfoIdSet.add(item.responsibleUserId);
      }
    });

    const [customerInfoDictionary] = await Promise.all([
      this.fAccountStore.loadUserInfosByIds(Array.from(userInfoIdSet)),
      this.fFileStorageStore.loadItemsByIds(artworkStorageItemsIds),
    ]);

    const result: TableRow[] = [];

    bulkProjects.forEach((bulkProject) => {
      const product = this.fPlushieStore.getProductById(bulkProject.product);
      const status = this.fBulkProjectStore.getBulkProjectStatusById(
        bulkProject.status
      );
      const customerInfo = customerInfoDictionary[bulkProject.customer];
      const responsibleUserInfo = bulkProject.responsibleUserId
        ? customerInfoDictionary[bulkProject.responsibleUserId]
        : undefined;

      result.push({
        id: bulkProject.id,
        incrementalId: bulkProject.incrementalId,
        createdAt: bulkProject.createdAt,
        status: status ? status.name : "",
        quantity: bulkProject.quantity,
        product: product ? product.name : "",
        deadlineDate: bulkProject.deadlineDate,
        customerName: customerInfo ? customerInfo.fullName : "",
        customerEmail: customerInfo ? customerInfo.email : "",
        artwork: bulkProject.imageStorageItemId,
        responsibleUser: responsibleUserInfo
          ? responsibleUserInfo.shortName
          : "",
      });
    });

    return { data: result, count: bulkProjectsCollection.totalItemsCount };
  }
}
