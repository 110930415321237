import ObjectBuilderInterface from "../api/object-builder.interface";
import EmailLayout from "./email-layout.model";

export default class EmailLayoutFactory
  implements ObjectBuilderInterface<EmailLayout> {
  public buildFromJSON(data: { [key: string]: any }): EmailLayout {
    const value = new EmailLayout(data.id, data.content, data.storeId);

    value.isNew = false;

    return value;
  }
}
