import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class RejectionReason implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fName: string,
    private fType: string,
    private fIsActive: boolean
  ) {
    [fId, fName, fType, fIsActive].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get name() {
    return this.fName;
  }

  get type() {
    return this.fType;
  }

  get isActive() {
    return this.fIsActive;
  }
}
