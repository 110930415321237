





















































































import Vue from "vue";
import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import rootStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import CountryStore from "@/modules/country/store";
import Country from "@/modules/country/country.model";

import ShippingInformationEditDialog from "./Dialog.vue";

import PlushieStore from "../../store";
import ShippingInformation from "../../shipping-information.model";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class ShippingInformationView extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fCountryStore: CountryStore;
  private fPlushieStore: PlushieStore;

  private fIsLoading = true;
  private fIsCountryRegionLoading = false;

  get address(): string {
    if (!this.shippingInformation) {
      return "";
    }

    if (!this.shippingInformation.streetAddress2) {
      return this.shippingInformation.streetAddress1;
    }

    return `${this.shippingInformation.streetAddress1}, ${this.shippingInformation.streetAddress2}`;
  }

  get city(): string {
    return this.shippingInformation ? this.shippingInformation.city : "";
  }

  get companyName(): string {
    return this.shippingInformation ? this.shippingInformation.companyName : "";
  }

  get country(): Country | undefined {
    if (!this.shippingInformation?.country) {
      return;
    }

    return this.fCountryStore.getCountryByCode(
      this.shippingInformation.country
    );
  }

  get countryStateKey(): string | undefined {
    if (!this.shippingInformation) {
      return;
    }

    return `${this.shippingInformation.country}_${this.shippingInformation.state}`;
  }

  get countryName(): string {
    if (!this.country) {
      return this.shippingInformation?.country || "";
    }

    return this.country.name;
  }

  get fullName(): string {
    if (!this.shippingInformation) {
      return "";
    }

    return `${this.shippingInformation.firstName} ${this.shippingInformation.lastName}`;
  }

  get isCountryRegionLoading(): boolean {
    return this.fIsLoading || this.fIsCountryRegionLoading;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get methodName(): string {
    return this.shippingInformation ? this.shippingInformation.methodName : "";
  }

  get phone(): string {
    return this.shippingInformation ? this.shippingInformation.phone : "";
  }

  get postalCode(): string {
    return this.shippingInformation ? this.shippingInformation.postalCode : "";
  }

  get region(): string {
    if (!this.shippingInformation || this.isCountryRegionLoading) {
      return "";
    }

    if (!this.country) {
      return this.shippingInformation.state;
    }

    const region = this.fCountryStore.getCountryRegionByRegionCodeAndCountryId(
      this.shippingInformation.state,
      this.country.id
    );

    return region?.name || this.shippingInformation.state;
  }

  get shippingInformation(): ShippingInformation | undefined {
    return this.fPlushieStore.getShippingInformationByPlushieId(this.plushieId);
  }

  get canShowShippingInformationEditForm(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.SHIPPING_INFORMATION_MANAGE);
  }

  constructor() {
    super();
    this.fCountryStore = getModule(CountryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  public onChangeShippingInformationButtonClick(): void {
    const dialogFunction = create(ShippingInformationEditDialog);

    void dialogFunction({
      plushieId: this.plushieId,
    });
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    await Promise.all([
      this.fPlushieStore.loadShippingInformationByPlushieId(this.plushieId),
      this.fCountryStore.loadCountries(),
    ]);

    void this.loadCountryRegionData();

    this.fIsLoading = false;
  }

  private async loadCountryRegionData(): Promise<void> {
    if (!this.shippingInformation || !this.country) {
      return;
    }

    this.fIsCountryRegionLoading = true;

    await this.fCountryStore.loadCountryRegionByRegionCodeAndCountryId({
      regionCode: this.shippingInformation.state,
      countryId: this.country.id,
    });

    this.fIsCountryRegionLoading = false;
  }

  @Watch("countryStateKey")
  private _onCountryStateKeyChangeHandler(
    newVal: string,
    oldVal: string
  ): void {
    if (!oldVal) {
      return;
    }

    void this.loadCountryRegionData();
  }
}
