import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import OrganizationsListPage from "./components/OrganizationsListPage.vue";
import OrganizationAddPage from "./components/OrganizationAddPage.vue";
import OrganizationEditPage from "./components/OrganizationEditPage.vue";
import RolesListPage from "./components/RolesListPage.vue";
import RoleAddPage from "./components/RoleAddPage.vue";
import RoleEditPage from "./components/RoleEditPage.vue";
import UsersListPage from "./components/UsersListPage.vue";
import UserAddPage from "./components/UserAddPage.vue";
import UserEditPage from "./components/UserEditPage.vue";

export default [
  {
    path: "/organization/list",
    name: "OrganizationsListPage",
    component: OrganizationsListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/organization/edit",
    name: "OrganizationEditPage",
    component: OrganizationEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["organizationId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/organization/add",
    name: "OrganizationAddPage",
    component: OrganizationAddPage,
    props: (route: Route) => {
      return listItemPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },

  {
    path: "/role/list",
    name: "UserRolesListPage",
    component: RolesListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/role/edit",
    name: "UserRoleEditPage",
    component: RoleEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["roleId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/role/add",
    name: "UserRoleAddPage",
    component: RoleAddPage,
    props: (route: Route) => {
      return listItemPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },

  {
    path: "/account/list",
    name: "UsersListPage",
    component: UsersListPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/account/add",
    name: "UserAddPage",
    component: UserAddPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/account/edit",
    name: "UserEditPage",
    component: UserEditPage,
    props: (route: Route) => {
      return {
        userId: route.query.id,
      };
    },
    meta: {
      auth: true,
    },
  },
];
