import Gallery from "blueimp-gallery/js/blueimp-gallery.min.js";
import jQuery from "jquery";
import Raphael from "raphael";

import { MarkedUpSlideData } from "./marked-up-slide-data";

(Gallery as any).prototype.markedUpImageFactory = function (
  obj: MarkedUpSlideData,
  callback: any
): any {
  const options = this.options;

  const wrapperDiv = document.createElement("div");

  wrapperDiv.className = "_image-wrapper slide-content";

  const img = this.imagePrototype.cloneNode(false);

  wrapperDiv.appendChild(img);

  let isCalled = false;

  /**
   * Wraps the callback function for the load/error event
   *
   * @param {event} event load/error event
   * @returns {number} timeout ID
   */
  function callbackWrapper(event: any) {
    if (!isCalled) {
      event = {
        type: event.type,
        target: wrapperDiv,
      };

      isCalled = true;

      if (obj.markup) {
        const paper = Raphael(wrapperDiv, img.offsetWidth, img.offsetHeight);

        paper.setViewBox(0, 0, obj.markup.width, obj.markup.height, true);

        const sketchpad = Raphael.sketchpad(paper, {
          editing: false,
          svgClass: "_sketchpad-svg",
        });

        sketchpad.json(obj.markup.strokes);
      }

      jQuery(img).off("load error", callbackWrapper);

      callback(event);
    }
  }

  const url = this.getItemProperty(obj, options.urlProperty);
  const srcset = this.getItemProperty(obj, options.srcsetProperty);
  const sizes = this.getItemProperty(obj, options.sizesProperty);
  const title = this.getItemProperty(obj, options.titleProperty);
  const altText = this.getItemProperty(obj, options.altTextProperty) || title;

  img.draggable = false;

  if (title) {
    wrapperDiv.title = title;
    img.title = title;
  }

  if (altText) {
    img.alt = altText;
  }

  jQuery(img).on("load error", callbackWrapper);

  if (srcset) {
    if (sizes) {
      img.sizes = sizes;
    }
    img.srcset = srcset;
  }

  img.src = url;

  return wrapperDiv;
};
