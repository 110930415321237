import { Dictionary } from "@/lib/Dictionary.type";

import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import ApiService from "../api/api.service";
import BasicRepository from "../api/basic.repository";
import ObjectBuilderInterface from "../api/object-builder.interface";
import EmailTemplate from "./email-template.model";

export default class EmailTemplateRepository extends BasicRepository<EmailTemplate> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<EmailTemplate>,
    responseParser: ApiCollectionResponseParserService<EmailTemplate>
  ) {
    super("templates", api, objectBuilder, responseParser);
  }

  protected prepareData(item: EmailTemplate): Dictionary<string | number> {
    return {
      id: item.id,
      code: item.code,
      sender: item.sender,
      subject: item.subject,
      content: item.content,
      storeId: item.store as number,
    };
  }
}
