export enum PriorityBonus {
  QA_FAILED = 1,
  FROM_HOLD = 2,
  RUSH = 3,
  SUPER_RUSH = 4,
  CUSTOMER_PREVIEW_MESSAGE_RECEIVED = 5,
  BULK_SAMPLE = 6,
  DOMESTIC_HOLIDAY_DELIVERY = 7,
  INTL_HOLIDAY_DELIVERY = 8,
  HOLIDAY_RUSH = 9,
}
