import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { FieldOrderDesc } from "@/modules/api/field-order-options";

import ElementPriceHistoryRecord from "./element-price-history-record.model";

export default class ElementPriceHistoryRecordRepository extends BasicRepository<ElementPriceHistoryRecord> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ElementPriceHistoryRecord>,
    responseParser: ApiCollectionResponseParserService<ElementPriceHistoryRecord>
  ) {
    super("element_price_history_records", api, objectBuilder, responseParser);
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<ResourceCollection<ElementPriceHistoryRecord>> {
    return this.fetchList({ factoryId: factoryId }, 1, 9999, [
      ["createdAt", FieldOrderDesc],
    ]);
  }
}
