


































import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import rootStore from "@/store";
import AccountStore from "@/modules/account/store";
import UserInfo from "@/modules/account/user-info.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

import BulkProjectResponsibleUserAssignForm from "./AssignForm.vue";

import BulkProjectStore from "../../store";
import BulkProject from "../../bulk-project.model";

@Component({
  components: {
    BulkProjectResponsibleUserAssignForm,
    InlineSpinner,
  },
})
export default class BulkProjectResponsibleUserCard extends Vue {
  @Prop({ required: true })
  public readonly bulkProjectId!: string;

  @Inject("AuthenticatedUserProvider")
  private fAuthenticatedUserProvider!: AuthenticatedUserProvider;

  private fAccountStore: AccountStore;
  private fBulkProjectStore: BulkProjectStore;

  private fIsLoading = false;
  private fIsInEditMode = false;

  get isInEditMode(): boolean {
    return this.fIsInEditMode;
  }

  get isInViewMode(): boolean {
    return !this.isInEditMode || !this.canEditResponsibleUser;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get bulkProject(): BulkProject {
    const bulkProject = this.fBulkProjectStore.getBulkProjectById(
      this.bulkProjectId
    );

    if (!bulkProject) {
      throw new Error("Bulk Project not found");
    }

    return bulkProject;
  }

  get canEditResponsibleUser(): boolean {
    if (this.isLoading) {
      return false;
    }

    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_PROJECT_MANAGE);
  }

  get responsibleUserInfo(): UserInfo | undefined {
    if (!this.bulkProject.responsibleUserId) {
      return;
    }

    return this.fAccountStore.getUserInfoById(
      this.bulkProject.responsibleUserId
    );
  }

  get userShortName(): string {
    if (!this.responsibleUserInfo) {
      return "";
    }

    return this.responsibleUserInfo.shortName;
  }

  constructor() {
    super();

    this.fAccountStore = getModule(AccountStore, rootStore);
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
  }

  public switchToEditMode(): void {
    this.fIsInEditMode = true;
  }

  public switchToViewMode(): void {
    this.fIsInEditMode = false;
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    if (!this.bulkProject.responsibleUserId) {
      this.fIsLoading = false;
      return;
    }

    this.fIsLoading = true;

    await this.fAccountStore.loadUserInfoById({
      id: this.bulkProject.responsibleUserId,
    });

    this.fIsLoading = false;
  }
}
