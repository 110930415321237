import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import OperatorSession from "./operator-session.model";

export default class OperatorSessionFactory
  implements ObjectBuilderInterface<OperatorSession> {
  public buildFromJSON(data: { [key: string]: any }): OperatorSession {
    const value = new OperatorSession(
      data.id,
      data.plushie,
      data.plushieStatus,
      data.userId,
      data.timeSpent
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
