



























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";

import BodyPartViewFormItem from "./BodyPartViewForm/Item.vue";

import BodyPartStore from "../store";
import Value from "../value.model";

@Component({
  components: {
    MoonLoader,
    BodyPartViewFormItem,
  },
})
export default class BodyPartsViewForm extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fIsLoading = true;

  private fBodyPartDataStore: BodyPartStore;
  private fFileStorageDataStore: FileStorageStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get relations() {
    return this.fBodyPartDataStore.getPlushieValueRelationsByPlushieId(
      this.plushieId
    );
  }

  get values() {
    const valueIds = this.relations.map((relation) => relation.value);

    return this.fBodyPartDataStore.getValuesByIds(valueIds);
  }

  get bodyParts() {
    let bodyPartIds = this.values.map((value) => value.bodyPart);

    bodyPartIds = Array.from(new Set(bodyPartIds));

    return this.fBodyPartDataStore.getBodyPartsByIds(bodyPartIds);
  }

  get getBodyPartValues(): (bodyPartId: string) => Value[] {
    return (bodyPartId: string) => {
      const bodyPartValues: Value[] = [];

      this.relations.forEach((relation) => {
        const value = this.fBodyPartDataStore.getValueById(relation.value);

        if (!value || value.bodyPart !== bodyPartId) {
          return;
        }

        bodyPartValues.push(value);
      });

      bodyPartValues.sort((a, b) => (a.sn > b.sn ? 1 : b.sn > a.sn ? -1 : 0));

      return bodyPartValues;
    };
  }

  constructor() {
    super();
    this.fBodyPartDataStore = getModule(BodyPartStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  private async getPlushieData(plushieId: string) {
    if (!plushieId) {
      return;
    }

    const relations = await this.fBodyPartDataStore.loadPlushieValueRelationsByPlushieId(
      {
        plushieId: this.plushieId,
      }
    );

    const valueIds = relations.map((relation) => relation.value);

    await this.fBodyPartDataStore.loadValuesByIds(valueIds);

    const values = this.fBodyPartDataStore.getValuesByIds(valueIds);

    const valueStoreItemIds: string[] = [];
    values.forEach((value) => {
      if (value.storageItem != null) {
        valueStoreItemIds.push(value.storageItem);
      }
    });

    const bodyPartIds = values.map((value) => value.bodyPart);

    await Promise.all([
      this.fFileStorageDataStore.loadItemsByIds(valueStoreItemIds),
      this.fBodyPartDataStore.loadBodyPartsByIds(bodyPartIds),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);
    this.fIsLoading = false;
  }
}
