








































































import { Component, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import Multiselect from "vue-multiselect";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import Factory from "@/modules/factory/factory.model";
import FactoryStore from "@/modules/factory/store";
import Product from "@/modules/plushie/product.model";
import PlushieStore from "@/modules/plushie/store";

import TagInput from "./TagInput.vue";

import TagSearchResultRepository from "../../tag-search-result.repository";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    TagInput,
    Multiselect,
  },
})
export default class TagSearchForm extends mixins(GeneralFormMixin) {
  @Inject("TagSearchResultRepository")
  private fTagSearchResultRepository!: TagSearchResultRepository;

  public factory: Factory[] = [];
  public product: Product[] = [];
  public tags: string[] = [];

  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  private fIsLoading = true;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get factories(): Factory[] {
    return this.fFactoryDataStore.activeFactoriesList;
  }

  get products(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  protected async created(): Promise<void> {
    await Promise.all([
      this.fFactoryDataStore.loadFactories(),
      this.fPlushieDataStore.loadProducts(),
    ]);
  }

  protected async performSubmit(): Promise<void> {
    if (this.tags.length < 1) {
      throw new Error("Please specify at least one tag");
    }

    try {
      const searchResult = await this.fTagSearchResultRepository.createSearchRequest(
        {
          tags: this.tags,
          factory: this.factory.length
            ? this.factory.map((factory: Factory) => factory.id)
            : undefined,
          product: this.product.length
            ? this.product.map((product: Product) => product.id)
            : undefined,
        }
      );

      this.$emit("submitted", searchResult.plushies);
    } catch (e) {
      this.$emit("submitted", []);

      throw e;
    }
  }
}
