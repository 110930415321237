



















































import Vue from "vue";
import { Component, Inject, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Location } from "vue-router";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import rootStore from "@/store";
import BulkProjectStore from "@/modules/bulk-project/store";
import BulkProject from "@/modules/bulk-project/bulk-project.model";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import Plushie from "@/modules/plushie/plushie.model";
import PlushieStore from "@/modules/plushie/store";
import Product from "@/modules/plushie/product.model";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import { BulkProjectPlushieTypeValue } from "../bulk-project-plushie-type.value";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class BulkProjectCard extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fBulkProjectStore: BulkProjectStore;
  private fPlushieStore: PlushieStore;

  private fIsLoading = true;
  private fRelatedSamplePlushie: Plushie | undefined;

  get bulkProjectIncrementalId(): string {
    if (!this.bulkProject) {
      return "";
    }

    return this.bulkProject.incrementalId.toString();
  }

  get bulkProject(): BulkProject | undefined {
    return this.fBulkProjectStore.getBulkProjectByPlushieId(this.plushieId);
  }

  get bulkProjectLink(): Location | undefined {
    if (!this.bulkProject) {
      return;
    }

    return {
      name: "BulkProjectViewPage",
      query: {
        id: this.bulkProject.id,
      },
    };
  }

  get canCreateBulkProject(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_PROJECT_MANAGE);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get plushie(): Plushie | undefined {
    return this.fPlushieStore.getPlushieById(this.plushieId);
  }

  get product(): Product | undefined {
    if (!this.plushie) {
      return;
    }

    return this.fPlushieStore.getProductById(this.plushie.product);
  }

  get relatedSamplePlushieLink(): Location | undefined {
    if (!this.fRelatedSamplePlushie) {
      return;
    }

    return {
      name: "PlushieViewPage",
      query: {
        id: this.fRelatedSamplePlushie.id,
      },
    };
  }

  get relatedSamplePlushieSku(): string {
    if (!this.fRelatedSamplePlushie) {
      return "";
    }

    return this.fRelatedSamplePlushie.storeItemId;
  }

  get showBulkProjectLink(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.role !== AuthenticatedUser.ROLE_FACTORY;
  }

  get showRelatedSamplePlushie(): boolean {
    if (!this.product) {
      return false;
    }

    return this.product.isBatch;
  }

  constructor() {
    super();
    this.fBulkProjectStore = getModule(BulkProjectStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  created(): void {
    void this.loadData();
  }

  protected data(): Record<string, Plushie | undefined> {
    return {
      fRelatedSamplePlushie: this.fRelatedSamplePlushie,
    };
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    const relation = await this.fBulkProjectStore.loadBulkProjectPlushieRelationByPlushieId(
      this.plushieId
    );

    if (!relation) {
      this.fIsLoading = false;
      return;
    }

    const [samplePlushieRelations] = await Promise.all([
      this.fBulkProjectStore.loadBulkProjectPlushieRelationsByBulkProjectIdAndPlushieType(
        {
          bulkProjectId: relation.bulkProject,
          plushieType: BulkProjectPlushieTypeValue.SAMPLE,
        }
      ),
      this.fBulkProjectStore.loadBulkProjectById(relation.bulkProject),
    ]);

    const samplePlushieRelation = samplePlushieRelations[0];

    if (!samplePlushieRelation) {
      this.fIsLoading = false;
      return;
    }

    this.fRelatedSamplePlushie = await this.fPlushieStore.loadPlushieById({
      id: samplePlushieRelation.plushie,
    });

    this.fIsLoading = false;
  }
}
