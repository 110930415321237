import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Factory from "./factory.model";

export default class FactoryFactory implements ObjectBuilderInterface<Factory> {
  public buildFromJSON(data: { [key: string]: any }): Factory {
    const value = new Factory(
      data.id,
      data.email,
      data.name,
      data.isActive,
      data.language,
      data.country,
      data.paSafetyNumber
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
