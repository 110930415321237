













import { Inject, Vue } from "vue-property-decorator";

import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";
import IsUserUnderStrictModeResolver from "@/modules/strict-capacity-mode/is-user-under-strict-mode-resolver.service";
import IsPullModeEnabledResolver from "@/modules/strict-capacity-mode/is-pull-mode-enabled.resolver.service";

export default class ReadyForProductionMassActionWarning extends Vue {
  @Inject("IsUserUnderStrictModeResolver")
  private fIsUserUnderStrictModeResolver!: IsUserUnderStrictModeResolver;

  @Inject("IsPullModeEnabledResolver")
  private fIsPullModeEnabledResolver!: IsPullModeEnabledResolver;

  private fIsUserUnderStrictMode = false;
  private fIsStrictPullModeEnabled = false;

  get show(): boolean {
    return this.fIsUserUnderStrictMode && this.fIsStrictPullModeEnabled;
  }

  get inDesignRoute(): Record<string, string | Record<string, string>> {
    return {
      name: "PlushiesListPage",
      query: { status: PlushieStatusValue.IN_DESIGN },
    };
  }

  protected async mounted(): Promise<void> {
    this.fIsUserUnderStrictMode = await this.fIsUserUnderStrictModeResolver.resolve();
    this.fIsStrictPullModeEnabled = await this.fIsPullModeEnabledResolver.resolve();
  }
}
