import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { ResourceCollection } from "@/modules/api/resource.collection";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import FactoryPlushieStatusQty from "./factory-plushie-status-qty.model";

export default class FactoryPlushieStatusQtyRepository extends BasicRepository<FactoryPlushieStatusQty> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<FactoryPlushieStatusQty>,
    responseParser: ApiCollectionResponseParserService<FactoryPlushieStatusQty>
  ) {
    super("factory_plushie_status_qties", api, objectBuilder, responseParser);
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<ResourceCollection<FactoryPlushieStatusQty>> {
    return this.fetchList({ factory: factoryId }, 1, 99);
  }
}
