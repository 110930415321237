






























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";
import UserInfo from "@/modules/account/user-info.model";

import FactoryStore from "../../store";
import Factory from "../../factory.model";

@Component({
  components: {
    MoonLoader,
  },
})
export default class UserFactoryRelationsEditForm extends Vue {
  @Prop({ required: true })
  public readonly factory!: Factory;

  @Prop({ default: false })
  public readonly canRemove!: boolean;

  private fAccountDataStore: AccountStore;
  private fFactoryDataStore: FactoryStore;

  private fIsDataLoaded = false;

  get isDataLoaded(): boolean {
    return this.fIsDataLoaded;
  }

  get showList(): boolean {
    return this.fIsDataLoaded && !!this.usersList.length;
  }

  get showLoader(): boolean {
    return !this.fIsDataLoaded;
  }

  get usersList(): UserInfo[] {
    const relations = this.fFactoryDataStore.getUserFactoryRelationsByFactoryId(
      this.factory.id
    );
    const userInfos: UserInfo[] = [];

    for (const relation of relations) {
      const userInfo = this.fAccountDataStore.getUserInfoById(relation.user);

      if (userInfo) {
        userInfos.push(userInfo);
      }
    }

    return userInfos;
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public async removeRelation(userId: string): Promise<void> {
    const relation = this.fFactoryDataStore.getUserFactoryRelationByUserId(
      userId
    );

    if (!relation) {
      throw new Error("Relation for user not found");
    }

    await this.fFactoryDataStore.deleteUserFactoryRelation(relation);
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    const relations = await this.fFactoryDataStore.loadUserFactoryRelationsByFactoryId(
      this.factory.id
    );

    await this.fAccountDataStore.loadUserInfosByIds(
      relations.map((relation) => relation.user)
    );

    this.fIsDataLoaded = true;
  }
}
