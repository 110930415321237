/* eslint-disable import/order */
import Page from "@/modules/layout/internal/components/Page.vue";
import PrintPage from "@/modules/layout/print/components/Page.vue";
import PublicPage from "@/modules/layout/public/components/Page.vue";

import TypographyPage from "@/modules/general/components/TypographyPage.vue";

import generalRoutes from "@/modules/general/routes";

import accountRoutes from "@/modules/account/routes";
import accountPublicRoutes from "@/modules/account/routes-public";

import plushieAlertsRoutes from "@/modules/plushie-alerts/routes";

import barcodeRoutes from "@/modules/barcode/routes";

import bulkShipmentRoutes from "@/modules/bulk-shipment/routes";

import dashboardRoutes from "@/modules/dashboard/routes";

import factoryRoutes from "@/modules/factory/routes";

import factoryRestrictedModeRoutes from "@/modules/factory-restricted-mode/routes";

import factoryInvoiceRoutes from "@/modules/factory-invoice/routes";

import messagingRoutes from "@/modules/messaging/routes";

import plushieRoutes from "@/modules/plushie/routes";

import tagRoutes from "@/modules/tag/routes";

import documentExportPrintRoutes from "@/modules/document-export/routes-print";

import photoMarkupRoutes from "@/modules/photo-markup/routes";

import qualityInspectionRoutes from "@/modules/quality-inspection/routes";

import strictCapacityModeRoutes from "@/modules/strict-capacity-mode/routes";

import holidayPeriodRoutes from "@/modules/holiday-period/routes";

import reportsRoutes from "@/modules/reports/routes";

import bulkProjectRoutes from "@/modules/bulk-project/routes";

import productionProcessRoutes from "@/modules/production-process/routes";

import emailRenderingRoutes from "@/modules/emails-management/routes";

let publicRoutes: any[] = [];

publicRoutes = publicRoutes.concat(accountPublicRoutes, generalRoutes);

let printRoutes: any[] = [];
printRoutes = printRoutes.concat(documentExportPrintRoutes);

let internalRoutes: any[] = [
  {
    path: "/typography",
    name: "TypographyPage",
    component: TypographyPage,
  },
];

internalRoutes = internalRoutes.concat(
  accountRoutes,
  barcodeRoutes,
  bulkProjectRoutes,
  bulkShipmentRoutes,
  dashboardRoutes,
  factoryRoutes,
  factoryRestrictedModeRoutes,
  factoryInvoiceRoutes,
  messagingRoutes,
  plushieAlertsRoutes,
  plushieRoutes,
  photoMarkupRoutes,
  reportsRoutes,
  tagRoutes,
  qualityInspectionRoutes,
  strictCapacityModeRoutes,
  holidayPeriodRoutes,
  productionProcessRoutes,
  emailRenderingRoutes
);

const baseRoutes: any[] = [
  {
    path: "/",
    component: Page,
    children: internalRoutes,
    meta: {
      auth: true,
    },
  },
  {
    path: "/",
    component: PrintPage,
    children: printRoutes,
  },
  {
    path: "/",
    component: PublicPage,
    children: publicRoutes,
  },
];

export default baseRoutes;
