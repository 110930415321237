

























import { Inject, Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";

import ActivityLogItem from "./ActivityLogItem.vue";

import ActionLogRecordRepository from "../action-log-record.repository";
import ActionLogRecord from "../action-log-record.model";

@Component({
  components: {
    MoonLoader,
    ActivityLogItem,
  },
})
export default class ActivityLog extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ActionLogRecordRepository")
  private fActionLogRecordRepository!: ActionLogRecordRepository;

  private fActionLogRecords: ActionLogRecord[] = [];

  private fIsLoading = true;

  private fAccountDataStore: AccountStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  get actionLogRecords() {
    return this.fActionLogRecords;
  }

  private async getPlushieData(plushieId: string) {
    const collection = await this.fActionLogRecordRepository.getByPlushieId(
      plushieId
    );

    this.fActionLogRecords = collection.getItems();

    if (this.fActionLogRecords.length < 1) {
      return;
    }

    const userIds: string[] = [];
    this.fActionLogRecords.forEach((log) => {
      if (!userIds.includes(log.creator)) {
        userIds.push(log.creator);
      }
    });

    await Promise.all([
      this.fAccountDataStore.loadUserInfosByIds(userIds),
      this.fAccountDataStore.loadUserRolesByUserId({ userIds: userIds }),
      this.fAccountDataStore.loadRoles(),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);
    this.fIsLoading = false;
  }
}
