import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BulkShipment from "./bulk-shipment.model";

export default class BulkShipmentFactory
  implements ObjectBuilderInterface<BulkShipment> {
  public buildFromJSON(data: { [key: string]: any }): BulkShipment {
    const value = new BulkShipment(data.id, data.factoryId, data.status);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fAirwayBill = data.airwayBill;
    (value as any).fItemsQty = data.itemsQty;

    value.trackingNumber = data.trackingNumber;
    value.isNew = false;

    return value;
  }
}
