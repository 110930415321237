import { MutableValue } from "@/modules/api/mutable-value.interface";
import StorageItemReferenceInterface from "@/modules/file-storage/storage-item-reference.interface";

export default class Attachment
  implements MutableValue, StorageItemReferenceInterface {
  public isNew = true;

  constructor(
    private fId: string,
    private fMessage: string,
    private fStorageItem: string,
    private fCreatedAt: Date,
    private fMime: string
  ) {
    [fId, fMessage, fStorageItem, fCreatedAt].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get message(): string {
    return this.fMessage;
  }

  get storageItem(): string {
    return this.fStorageItem;
  }

  get createdAt(): Date {
    return this.fCreatedAt;
  }

  get mime(): string {
    return this.fMime;
  }
}
