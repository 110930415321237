enum StoreValue {
  BUDSIES = "1",
  PETSIES = "2",
  BULK_ORDERS = "3",
  WAGGABLES = "4",
  FLUNFY = "5",
  CRAYOLA = "6",
  MAKERWARE = "0",
}

export default StoreValue;
