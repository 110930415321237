















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import UpgradeAllocation from "@/modules/factory/upgrade-allocation.model";

@Component
export default class UpgradeAllocationEditFormItem extends Vue {
  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ required: true })
  public readonly id!: string;

  @Prop({ required: true })
  public readonly value!: UpgradeAllocation;
}
