import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import FactoriesListPage from "./components/FactoriesListPage.vue";
import FactoryAddPage from "./components/FactoryAddPage.vue";
import FactoryEditPage from "./components/FactoryEditPage.vue";
import ProductionTimeNormListPage from "./components/ProductionTimeNorm/ListPage.vue";
import ProductionTimeNormEditPage from "./components/ProductionTimeNorm/EditPage.vue";
import ProductAllocationEditPage from "./components/ProductAllocation/EditPage.vue";
import UpgradeAllocationPage from "./components/UpgradeAllocation/Page.vue";
import UserFactoryRelationsPage from "./components/UserFactoryRelation/Page.vue";
import FactoryInvoicingSettingsPage from "./components/InvoicingSettings/Page.vue";

export default [
  {
    path: "/factory/list",
    name: "FactoriesListPage",
    component: FactoriesListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/production-time-norm/list",
    name: "ProductionTimeNormListPage",
    component: ProductionTimeNormListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/product_allocation",
    name: "ProductAllocationEditPage",
    component: ProductAllocationEditPage,
    props: (route: Route) => {
      const props = listPageUrlParser(route);

      if (typeof route.query.slotType === "string") {
        props["slotType"] = route.query.slotType;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/edit",
    name: "FactoryEditPage",
    component: FactoryEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["factoryId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/production-time-norm/edit",
    name: "ProductionTimeNormEditPage",
    component: ProductionTimeNormEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["factoryId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/add",
    name: "FactoryAddPage",
    component: FactoryAddPage,
    props: (route: Route) => {
      return listItemPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/upgrade-allocation",
    name: "UpgradeAllocationPage",
    component: UpgradeAllocationPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/user-factory-relation",
    name: "UserFactoryRelationPage",
    component: UserFactoryRelationsPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["factoryId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory/invoicing-settings",
    name: "FactoryInvoicingSettingsPage",
    component: FactoryInvoicingSettingsPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["factoryId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
];
