import { MutableValue } from "@/modules/api/mutable-value.interface";

import { PlushieStatusValue } from "../plushie/plushie-status.value";

export default class OperatorSession implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fPlushieStatus: PlushieStatusValue,
    private fUserId: string,
    private fTimeSpent: number
  ) {
    [fId, fPlushie, fPlushieStatus, fUserId, fTimeSpent].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fId;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get plushieStatus(): PlushieStatusValue {
    return this.fPlushieStatus;
  }

  get userId(): string {
    return this.fUserId;
  }

  get timeSpent(): number {
    return this.fTimeSpent;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
