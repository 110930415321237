













import Vue from "vue";

import AtTa from "vue-at/src/AtTextarea.vue";

import autocompleteColorsList from "../autocomplete-colors.list";

export default Vue.extend({
  name: "ColorsAutocomplete",
  components: {
    AtTa,
  },
  computed: {
    colors(): string[] {
      return autocompleteColorsList;
    },
  },
  methods: {
    filterMatch(name: string, chunk: string) {
      return name.toLowerCase().indexOf(chunk.toLowerCase()) === 0;
    },
  },
});
