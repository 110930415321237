




























import { Component, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import PlushieRepository from "../plushie.repository";

@Component({
  components: {
    FormField,
    FormErrors,
    InlineSpinner,
  },
})
export default class QuickPlushieSearch extends mixins(GeneralFormMixin) {
  private static readonly PLUSHIE_ID_REGEXP = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  private static readonly STORE_ITEM_ID_REGEXP = /^(MW)?\d*[1-9]\d*$/;

  @Inject("PlushieRepository")
  private fPlushieRepository?: PlushieRepository;

  public searchRequest = "";

  public onChange(): void {
    this.submitErrors = [];
  }

  protected async performSubmit(): Promise<void> {
    if (!this.fPlushieRepository || !this.searchRequest) {
      return;
    }

    let plushieId: string | undefined = undefined;

    if (QuickPlushieSearch.PLUSHIE_ID_REGEXP.test(this.searchRequest)) {
      plushieId = this.searchRequest;
    } else if (
      QuickPlushieSearch.STORE_ITEM_ID_REGEXP.test(this.searchRequest)
    ) {
      plushieId = await this.fPlushieRepository.getPlushieIdByStoreItemId(
        this.searchRequest
      );
    }

    if (!plushieId) {
      throw new Error(`Not found`);
    }

    const route = this.$router.resolve({
      name: "PlushieViewPage",
      query: {
        id: plushieId,
      },
    });

    void this.$router.push(route.href);

    this.searchRequest = "";
  }
}
