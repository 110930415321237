import { ColumnFilterDropdownOption } from "vue-tables-2-premium";

import { Dictionary } from "@/lib/Dictionary.type";

import TableColumnDefinitionInterface from "../interfaces/table-column-definition.interface";

export default class TableColumnDefinitionsParserService {
  constructor(private definitions: TableColumnDefinitionInterface[]) {}

  public getColumns(): string[] {
    const result: string[] = [];

    this.definitions.forEach((item) => {
      result.push(item.name);
    });

    return result;
  }

  public getClasses(): Dictionary<string> {
    const result: Dictionary<string> = {};

    this.definitions.forEach((item) => {
      if (!item.cssClass) {
        return;
      }

      result[item.name] = item.cssClass;
    });

    return result;
  }

  public getColumnByFilterKey(key: string): string | undefined {
    let result: string | undefined;

    this.definitions.some((item) => {
      const itemKey = item.filterKey || item.name;
      if (itemKey !== key) {
        return false;
      }

      result = item.name;
      return true;
    });

    return result;
  }

  public getColumnBySortKey(key: string): string | undefined {
    let result: string | undefined;

    this.definitions.some((item) => {
      const itemKey = item.sortKey || item.name;
      if (itemKey !== key) {
        return false;
      }

      result = item.name;
      return true;
    });

    return result;
  }

  public getColumnDisplay(): { [key: string]: string } {
    const result: Dictionary<string> = {};

    this.definitions.forEach((item) => {
      if (!item.displayConstraint) {
        return;
      }

      result[item.name] = item.displayConstraint;
    });

    return result;
  }

  public getFilterKeyForColumn(name: string): string {
    let result: string = name;

    this.definitions.some((item) => {
      if (item.name !== name) {
        return false;
      }

      if (item.filterKey) {
        result = item.filterKey;
      }

      return true;
    });

    return result;
  }

  public getHeadings(): Dictionary<string> {
    const result: Dictionary<string> = {};

    this.definitions.forEach((item) => {
      result[item.name] = item.header;
    });

    return result;
  }

  public getFilterable(): string[] {
    return this.getColumnsByCriteria((item) => item.isFilterable);
  }

  public getListColumns(): Dictionary<ColumnFilterDropdownOption[]> {
    const result: Dictionary<ColumnFilterDropdownOption[]> = {};

    this.definitions.forEach((item) => {
      if (!item.isFilterFromList) {
        return;
      }

      result[item.name] = item.filterValues ? item.filterValues : [];
    });

    return result;
  }

  public getSortable(): string[] {
    return this.getColumnsByCriteria((item) => item.isSortable);
  }

  public getSortKeyForColumn(name: string): string {
    let result: string = name;

    this.definitions.some((item) => {
      if (item.name !== name) {
        return false;
      }

      if (item.sortKey) {
        result = item.sortKey;
      }

      return true;
    });

    return result;
  }

  private getColumnsByCriteria(
    criteria: (item: TableColumnDefinitionInterface) => boolean | undefined
  ): string[] {
    const result: string[] = [];

    this.definitions.forEach((item) => {
      if (!criteria(item)) {
        return;
      }

      result.push(item.name);
    });

    return result;
  }
}
