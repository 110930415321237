export enum Command {
  ApproveCustomerPreview = "approve_customer_preview",
  ApproveQualityInspectionAndSkipReview = "approve_quality_inspection_and_skip_review",
  ApproveQualityInspection = "approve_quality_inspection",
  ApproveReview = "approve_review",
  ApproveReviewAndSkipDesign = "approve_review_and_skip_design",
  CancelPlushie = "cancel_plushie",
  PutOnHold = "hold_plushie",
  MoveToDesign = "move_to_design",
  MoveToProduction = "move_to_production",
  PrepareForShipment = "prepare_to_shipment",
  MoveToQualityInspection = "move_to_quality_inspection",
  MoveToReview = "move_to_review",
  ReassignFactory = "reassign_factory",
  RejectQualityInspection = "reject_quality_inspection",
  ReturnToDesign = "return_to_design",
  ReturnToQualityInspection = "return_to_quality_inspection",
  ReturnToQualityInspectionFromCustomerPreview = "return_to_quality_inspection_from_customer_preview",
  SendToCustomer = "send_to_customer",
  ShipToMainFacility = "ship_to_main_facility",
  UndoCustomerPreviewApprove = "undo_customer_preview_approve",
  SkipShipment = "skip_shipment",
  MoveToDirectShipment = "move_to_direct_shipment",
  ReturnIntoPreparedToShipment = "return_into_prepared_to_shipment",

  // Batches commands
  ApproveBulkInspection = "approve_bulk_inspection",
  ApproveBulkPreview = "approve_bulk_preview",
  ApprovePpsInspection = "approve_pps_inspection",
  ApprovePpsPreview = "approve_pps_preview",
  ConfirmBulkShipment = "confirm_bulk_shipment",
  MoveToBulkInspection = "move_to_bulk_inspection",
  MoveToPpsInspection = "move_to_pps_inspection",
  RejectBulkInspection = "reject_bulk_inspection",
  RejectBulkPreview = "reject_bulk_preview",
  RejectPpsInspection = "reject_pps_inspection",
  RejectPpsPreview = "reject_pps_preview",
  StartSampleProduction = "start_sample_production",
}
