




































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import AccountStore from "../store";
import User from "../user.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
    MoonLoader,
  },
})
export default class UserAccountEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly userId!: string;

  public login?: string;
  public password?: string;

  private fAccountDataStore: AccountStore;

  private fIsNew = true;

  public constructor() {
    super();

    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  protected data(): Record<string, unknown> {
    return {
      login: this.login,
      password: this.password,
    };
  }

  protected async performSubmit(): Promise<void> {
    let user = this.generateUserObject();

    user = await this.fAccountDataStore.saveUserAccount(user);
    this.fillFormFromUser(user);

    this.$emit("updated", user);
  }

  private generateUserObject(): User {
    if (!this.login) {
      throw new Error(
        "Unable to create object - the 'login' field is missing!"
      );
    }

    const value = new User(this.userId, this.login);

    value.password = this.password;
    value.isNew = this.fIsNew;

    return value;
  }

  private fillFormFromUser(user: User) {
    this.password = undefined;
    this.login = user.login;
    this.fIsNew = user.isNew;
  }

  @Watch("userId", { immediate: true })
  private async _onUserIdChange() {
    this.login = undefined;
    this.password = undefined;

    const user = await this.fAccountDataStore.loadUserAccountById({
      id: this.userId,
    });
    if (user === undefined) {
      this.fIsNew = true;
      return;
    }

    this.fillFormFromUser(user);
  }
}
