import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class FactoryInvoicingSettings implements MutableValue {
  public isNew = true;

  constructor(
    private fFactoryId: string,
    private fInvoicingModel: string,
    private fIsIgnoreQuantity: boolean,
    private fInvoicingPeriod: number
  ) {
    [fFactoryId, fInvoicingModel, fIsIgnoreQuantity, fInvoicingPeriod].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id(): string {
    return this.fFactoryId;
  }

  get factoryId(): string {
    return this.fFactoryId;
  }

  get invoicingModel(): string {
    return this.fInvoicingModel;
  }

  get isIgnoreQuantity(): boolean {
    return this.fIsIgnoreQuantity;
  }

  get invoicingPeriod(): number {
    return this.fInvoicingPeriod;
  }
}
