import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import RejectionReason from "./rejection-reason.model";

export default class RejectionReasonFactory
  implements ObjectBuilderInterface<RejectionReason> {
  public buildFromJSON(data: { [key: string]: any }): RejectionReason {
    const value = new RejectionReason(
      data.id,
      data.name,
      data.type,
      data.isActive == true
    );

    value.isNew = false;

    return value;
  }
}
