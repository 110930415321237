

















import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import dataStore from "@/store";

import AccountStore from "../../account/store";
import ActionLog from "../action-log-record.model";

@Component({
  components: {
    MoonLoader,
  },
})
export default class ActivityLogItem extends Vue {
  @Prop({ required: true })
  public readonly logItem!: ActionLog;

  private fIsExpanded = false;

  private fAccountDataStore: AccountStore;

  get createdAt() {
    return this.logItem.createdAt
      ? this.logItem.createdAt.toLocaleString()
      : "";
  }

  get creator() {
    const userInfo = this.fAccountDataStore.getUserInfoById(
      this.logItem.creator
    );

    if (userInfo === undefined) {
      return "";
    }

    const userRelations = this.fAccountDataStore.getUserRelationsByUserId(
      this.logItem.creator
    );

    if (userRelations === undefined || userRelations.userRole === undefined) {
      return "";
    }

    const userRole = this.fAccountDataStore.getUserRoleById(
      userRelations.userRole
    );

    if (userRole === undefined) {
      return "";
    }

    const roleId = userRole.role;

    const role = this.fAccountDataStore.getRoleById(roleId);

    if (role === undefined) {
      return "";
    }

    return `${userInfo.firstName} ${userInfo.lastName} (${role.name})`;
  }

  get description() {
    return this.fIsExpanded
      ? this.logItem.description
      : this.logItem.shortDescription;
  }

  get expandToggleText() {
    return this.fIsExpanded ? "less" : "more";
  }

  get showExpandToggle() {
    return (
      this.logItem.description !== null && this.logItem.description.length > 0
    );
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  public toggleExpand() {
    this.fIsExpanded = !this.fIsExpanded;
  }
}
