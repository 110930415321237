



























































































































import { mixins } from "vue-class-component";
import { Component, Inject } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import TabsContainer, {
  TabSpecification,
} from "@/lib/components/TabsContainer.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import rootStore from "@/store";

import FutureTurnaroundReport from "../future-turnaround.model";
import FutureTurnaroundReportRepository from "../future-turnaround.repository";
import ReportsStore from "../store";

const enum TabsIds {
  SETTINGS = "settings",
  REPORT = "report",
}

@Component({
  components: {
    FormErrors,
    FormField,
    SubmitButton,
    TabsContainer,
  },
})
export default class FutureTurnaroundReportPage extends mixins(
  GeneralFormMixin
) {
  @Inject("FutureTurnaroundRepository")
  private fFutureTurnaroundRepository!: FutureTurnaroundReportRepository;

  public inProductionAdj = 0.8;
  public inReworkAdj = 0.25;
  public qualityInspectionAdj = 0.5;
  public weeksCount = 2;

  private fIsTableDataLoaded = false;
  private fFutureTurnaroundReportList: FutureTurnaroundReport[] = [];

  private fReportsStore!: ReportsStore;

  get showReportTable(): boolean {
    return this.fIsTableDataLoaded;
  }

  get tableColumns(): string[] {
    return [
      "factoryName",
      "distributionPercent",
      "weeklyProduction",
      "readyForProduction",
      "inReview",
      "inDesign",
      "inRework",
      "inProduction",
      "inInspection",
      "customerPreview",
      "customerPreviewApproved",
      "total",
      "turnaround",
    ];
  }

  get tableData(): FutureTurnaroundReport[] {
    return this.fFutureTurnaroundReportList;
  }

  get tableOptions(): TableOptions {
    return {
      filterable: false,
      columnsClasses: {
        factoryName: "_factory-name",
      },
      toggleGroups: true,
      groupBy: ["storeName", "productName"],
      headings: {
        factoryName: "Factory",
        distributionPercent: "Alloc. Ratio",
        weeklyProduction: "Weekly Production",
        readyForProduction: "Ready For Prod",
        inReview: "In Review",
        inDesign: "In Design",
        inRework: "In Rework",
        inProduction: "In Production",
        inInspection: "In Inspection",
        customerPreview: "Cust Preview",
        customerPreviewApproved: "Cust Preview Approved",
        total: "Total",
        turnaround: "Turnaround",
      },
      multiSorting: {
        storeName: [
          {
            column: "productName",
            matchDir: true,
          },
          {
            column: "factoryName",
            matchDir: true,
          },
        ],
      },
      orderBy: { column: "storeName", ascending: true },
      pagination: {
        show: false,
      },
      perPageValues: [],
      perPage: 99999,
      sortable: [],
    };
  }

  get tabs(): TabSpecification[] {
    return [
      {
        id: TabsIds.SETTINGS,
        name: "Settings",
        dontStretch: true,
      },
      {
        id: TabsIds.REPORT,
        name: "Report",
      },
    ];
  }

  constructor() {
    super();

    this.fReportsStore = getModule(ReportsStore, rootStore);
  }

  protected created(): void {
    this.initDefaultFormFieldsValues();
  }

  protected async performSubmit(): Promise<void> {
    await this.loadTableData();
  }

  private getTabsContainer(): TabsContainer | undefined {
    return this.$refs["tabsContainer"] as TabsContainer | undefined;
  }

  private initDefaultFormFieldsValues(): void {
    this.inProductionAdj = this.fReportsStore.futureTurnaroundReportInProductionAdj;
    this.inReworkAdj = this.fReportsStore.futureTurnaroundReportInReworkAdj;
    this.qualityInspectionAdj = this.fReportsStore.futureTurnaroundReportQualityInspectionAdj;
    this.weeksCount = this.fReportsStore.futureTurnaroundReportWeeksCount;
  }

  private async loadTableData(): Promise<void> {
    try {
      this.fFutureTurnaroundReportList = await this.fFutureTurnaroundRepository.fetchFutureTurnaroundList(
        this.weeksCount,
        this.inProductionAdj,
        this.qualityInspectionAdj,
        this.inReworkAdj
      );

      this.fReportsStore.updateFutureTurnaroundReportFormFieldsDefaultValues({
        inProductionAdj: this.inProductionAdj,
        inReworkAdj: this.inReworkAdj,
        qualityInspectionAdj: this.qualityInspectionAdj,
        weeksCount: this.weeksCount,
      });

      this.fIsTableDataLoaded = true;

      const tabsContainer = this.getTabsContainer();

      if (!tabsContainer) {
        return;
      }

      tabsContainer.switchToTab(TabsIds.REPORT);
    } catch (e) {
      this.fIsTableDataLoaded = false;
      throw e;
    }
  }
}
