import { MutableValue } from "@/modules/api/mutable-value.interface";
import StorageItemReferenceInterface from "@/modules/file-storage/storage-item-reference.interface";

import QaAssetType from "./qa-asset-type";

export default class QaAsset
  implements MutableValue, StorageItemReferenceInterface {
  public isNew = true;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fStorageItem: string,
    private fType: QaAssetType,
    private fGroupNumber: number,
    private fCreatedAt: Date
  ) {
    [fId, fPlushie, fStorageItem, fType, fGroupNumber, fCreatedAt].forEach(
      (arg, index) => {
        if (arg === undefined) {
          throw new Error(`Undefined value passed at position: ${index}`);
        }
      }
    );
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get plushie() {
    return this.fPlushie;
  }

  get storageItem() {
    return this.fStorageItem;
  }

  get type() {
    return this.fType;
  }

  get groupNumber() {
    return this.fGroupNumber;
  }
}
