import { MutableValue } from "../api/mutable-value.interface";

export default class EmailTemplate implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fCode: string,
    private fSubject: string,
    private fContent: string,
    private fSender: string,
    private fStore: number | null
  ) {
    [fId, fCode, fSubject, fContent, fSender].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get code(): string {
    return this.fCode;
  }

  get subject(): string {
    return this.fSubject;
  }

  get content(): string {
    return this.fContent;
  }

  get sender(): string {
    return this.fSender;
  }

  get store(): number | null {
    return this.fStore;
  }
}
