






























import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import Product from "@/modules/plushie/product.model";
import { Dictionary } from "@/lib/Dictionary.type";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import IdGenerator from "@/lib/services/id-generator";

import BarcodeStore from "../store";
import LabelHeaderProductRelation from "../label-header-product-relation.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class LabelHeaderProductsEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop()
  public readonly labelHeaderId!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fPlushieDataStore: PlushieStore;
  private fBarcodeDataStore: BarcodeStore;

  private fUpdatingProducts: Dictionary<boolean> = {};

  get getProductOptions(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  constructor() {
    super();

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fBarcodeDataStore = getModule(BarcodeStore, dataStore);
  }

  public isProductUpdating(productId: string): boolean {
    return this.fUpdatingProducts[productId];
  }

  public isProductAssociated(productId: string): boolean {
    if (!this.labelHeaderId) {
      return false;
    }

    return (
      this.fBarcodeDataStore.getLabelHeaderProductRelationByHeaderIdAndProductId(
        this.labelHeaderId,
        productId
      ) !== undefined
    );
  }

  public onProductChange(product: Product, value: boolean): void {
    if (!value) {
      void this.deleteAssociatedProduct(product);
    } else {
      void this.addAssociatedProduct(product);
    }
  }

  protected async mounted(): Promise<void> {
    await this.fPlushieDataStore.loadProducts();
  }

  private async deleteAssociatedProduct(product: Product) {
    this.markProductAsUpdating(product.id);

    if (!this.labelHeaderId) {
      return;
    }

    try {
      const associatedProduct = this.fBarcodeDataStore.getLabelHeaderProductRelationByHeaderIdAndProductId(
        this.labelHeaderId,
        product.id
      );

      if (associatedProduct === undefined) {
        this.submitErrors = [];
        return;
      }

      await this.fBarcodeDataStore.deleteLabelHeaderProductRelation(
        associatedProduct.id
      );

      this.submitErrors = [];
    } catch (e) {
      this.submitErrors.push(
        `Something went wrong! Unable to remove the product '${product.name}'`
      );
    } finally {
      this.markProductAsAvailable(product.id);
    }
  }

  private async addAssociatedProduct(product: Product) {
    if (!this.labelHeaderId) {
      return;
    }

    const associatedProduct = new LabelHeaderProductRelation(
      this.fIdGenerator.getId(),
      this.labelHeaderId,
      product.id
    );

    this.markProductAsUpdating(product.id);

    try {
      await this.fBarcodeDataStore.saveLabelHeaderProductRelation(
        associatedProduct
      );
      this.submitErrors = [];
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);

      this.submitErrors.push(
        `Unable to add the product '${product.name}': ${errors[0]}`
      );
    } finally {
      this.markProductAsAvailable(product.id);
    }
  }

  private markProductAsUpdating(productId: string) {
    Vue.set(this.fUpdatingProducts, productId, true);
  }

  private markProductAsAvailable(productId: string) {
    Vue.set(this.fUpdatingProducts, productId, false);
  }

  @Watch("labelHeaderId", { immediate: true })
  private async _onLabelHeaderIdChange() {
    if (this.labelHeaderId === undefined) {
      return;
    }

    await this.fBarcodeDataStore.loadLabelHeaderProductRelationByHeaderId({
      headerId: this.labelHeaderId,
    });
  }
}
