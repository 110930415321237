import QaAssetType from "./qa-asset-type";
import QaAsset from "./qa-asset.model";

export default class QaAssetVideo extends QaAsset {
  constructor(
    id: string,
    plushie: string,
    storageItem: string,
    type: QaAssetType,
    groupNumber: number,
    createdAt: Date,
    private fThumbnailStorageItem: string
  ) {
    super(id, plushie, storageItem, type, groupNumber, createdAt);

    if (!fThumbnailStorageItem) {
      throw new Error("Thumbnail storage item is undefined");
    }
  }

  get thumbnailStorageItem(): string {
    return this.fThumbnailStorageItem;
  }
}
