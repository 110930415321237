import BodyPartStoreActionContext from "./body-part-store-action-context.type";
import { Getters } from "./body-part-store-getters.type";
import BodyPart from "./body-part.model";
import PlushieValueRelation from "./plushie-value-relation.model";

function removePlushieValueRelationsByPlushieIdAndBodyPartId(
  context: BodyPartStoreActionContext,
  {
    plushieId,
    bodyPartId,
  }: {
    plushieId: string;
    bodyPartId: string;
  }
): void {
  const getters = context.getters as Getters;
  const plushieValueRelations = getters.getPlushieValueRelationsByPlushieIdAndBodyPartId(
    plushieId,
    bodyPartId
  );

  plushieValueRelations.forEach((relation) => {
    context.commit("removePlushieRelation", relation.id);
  });
}

function removeChildrenBodyPartsPlushieValueRelations(
  context: BodyPartStoreActionContext,
  {
    plushieValueRelation,
    bodyPart,
  }: {
    plushieValueRelation: PlushieValueRelation;
    bodyPart: BodyPart;
  }
): void {
  const getters = context.getters as Getters;
  const childrenBodyParts: BodyPart[] = [];
  const childrenPlushieValueRelations: PlushieValueRelation[] = [];

  Object.values(context.state.bodyPart).forEach((item) => {
    if (item.parentBodyPart !== bodyPart.id) {
      return;
    }

    childrenBodyParts.push(item);
  });

  childrenBodyParts.forEach((item) => {
    childrenPlushieValueRelations.push(
      ...getters.getPlushieValueRelationsByPlushieIdAndBodyPartId(
        plushieValueRelation.plushie,
        item.id
      )
    );
  });

  childrenPlushieValueRelations.forEach((relation) => {
    context.commit("removePlushieRelation", relation.id);
  });
}

export default function removeConflictingPlushieValueRelations(
  context: BodyPartStoreActionContext,
  payload: PlushieValueRelation
): void {
  const getters = context.getters as Getters;
  const bodyPart = getters.getBodyPartByPlushieValueRelation(payload);

  if (!bodyPart) {
    return;
  }

  if (!bodyPart.parentBodyPart) {
    removeChildrenBodyPartsPlushieValueRelations(context, {
      plushieValueRelation: payload,
      bodyPart,
    });
    return;
  }

  const parentBodyPart = getters.getBodyPartById(bodyPart.parentBodyPart);

  if (!parentBodyPart) {
    return;
  }

  removePlushieValueRelationsByPlushieIdAndBodyPartId(context, {
    plushieId: payload.plushie,
    bodyPartId: parentBodyPart.id,
  });
}
