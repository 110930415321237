import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

import Country from "../country.model";
import CountryRegion from "../country-region.model";

const name = "CountryStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    country: {},
    countryByCodeDictionary: {},
    countryRegion: {},
    countryRegionByRegionCodeAndCountryIdDictionary: {},
    countryRegionsByCountryDictionary: {},
  };
};

function getRegionCodeAndCountryIdKey(
  regionCode: string,
  countryId: string
): string {
  return `${regionCode}_${countryId}`;
}

@Module({ name, dynamic: true, store: dataStore })
export default class CountryStore extends VuexModule {
  country: Dictionary<Country> = {};
  countryByCodeDictionary: Dictionary<Country> = {};

  countryRegion: Dictionary<CountryRegion> = {};
  countryRegionByRegionCodeAndCountryIdDictionary: Dictionary<string> = {};
  countryRegionsByCountryDictionary: Dictionary<string[]> = {};

  get countries(): Country[] {
    const list: Country[] = [];

    Object.keys(this.country).forEach((id) => {
      list.push(this.country[id]);
    });

    list.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    return list;
  }

  get countriesDictionary(): Dictionary<Country> {
    return { ...this.country };
  }

  get getCountryById(): (id: string) => Country | undefined {
    return (id: string) => this.country[id];
  }

  get getCountryByCode(): (code: string) => Country | undefined {
    return (code: string) => this.countryByCodeDictionary[code];
  }

  @Mutation
  updateCountry(payload: Country): void {
    Vue.set(this.country, payload.id, payload);
    Vue.set(this.countryByCodeDictionary, payload.code, payload);
  }

  @Action({ rawError: true })
  async loadCountries(): Promise<Country[]> {
    let items: Country[];

    if (Object.keys(this.country).length === 0) {
      const collection = await DIContainer.CountryRepository.getList();
      items = collection.getItems();

      items.forEach((item) => {
        this.updateCountry(item);
      });
    }

    items = [];

    Object.keys(this.country).forEach((key) => {
      items.push(this.country[key]);
    });

    return items;
  }

  @Action({ rawError: true })
  async loadCountryById(id: string): Promise<Country | undefined> {
    await this.loadCountries();

    return this.getCountryById(id);
  }

  // ################################### COUNTRY REGIONS #########################################

  get getCountryRegionsListByCountryId(): (
    countryId: string
  ) => CountryRegion[] {
    return (countryId: string) => {
      const countryRegionsList: CountryRegion[] = [];
      const relations = this.countryRegionsByCountryDictionary[countryId];

      if (!relations) {
        return countryRegionsList;
      }

      relations.forEach((item) => {
        if (!this.countryRegion[item]) {
          return;
        }

        countryRegionsList.push(this.countryRegion[item]);
      });

      return countryRegionsList;
    };
  }

  get getCountryRegionByRegionCodeAndCountryId(): (
    regionCode: string,
    countryId: string
  ) => CountryRegion | undefined {
    return (regionCode: string, countryId: string) => {
      const regionId = this.countryRegionByRegionCodeAndCountryIdDictionary[
        getRegionCodeAndCountryIdKey(regionCode, countryId)
      ];

      if (!regionId) {
        return;
      }

      return this.countryRegion[regionId];
    };
  }

  @Mutation
  updateCountryRegion(countryRegion: CountryRegion): void {
    Vue.set(this.countryRegion, countryRegion.id, countryRegion);
    Vue.set(
      this.countryRegionByRegionCodeAndCountryIdDictionary,
      getRegionCodeAndCountryIdKey(countryRegion.code, countryRegion.country),
      countryRegion.id
    );

    if (
      this.countryRegionsByCountryDictionary[countryRegion.country] ===
      undefined
    ) {
      return;
    }

    if (
      this.countryRegionsByCountryDictionary[countryRegion.country].includes(
        countryRegion.id
      )
    ) {
      return;
    }

    this.countryRegionsByCountryDictionary[countryRegion.country].push(
      countryRegion.id
    );
  }

  @Mutation
  updateCountryRegions({
    countryId,
    countryRegions,
  }: {
    countryId: string;
    countryRegions: CountryRegion[];
  }): void {
    const countryRegionIds: string[] = [];

    countryRegions.forEach((countryRegion) => {
      countryRegionIds.push(countryRegion.id);

      Vue.set(this.countryRegion, countryRegion.id, countryRegion);
      Vue.set(
        this.countryRegionByRegionCodeAndCountryIdDictionary,
        getRegionCodeAndCountryIdKey(countryRegion.code, countryRegion.country),
        countryRegion.id
      );
    });

    Vue.set(
      this.countryRegionsByCountryDictionary,
      countryId,
      countryRegionIds
    );
  }

  @Action({ rawError: true })
  async loadCountryRegionsByCountryId({
    countryId,
    useCache = true,
  }: {
    countryId: string;
    useCache?: boolean;
  }): Promise<CountryRegion[]> {
    if (this.countryRegionsByCountryDictionary[countryId] && useCache) {
      return this.getCountryRegionsListByCountryId(countryId);
    }

    const response = await DIContainer.CountryRegionRepository.getList(1, 999, {
      country: countryId,
    });

    const items = response.getItems();

    this.updateCountryRegions({
      countryId,
      countryRegions: items,
    });

    return items;
  }

  @Action({ rawError: true })
  async loadCountryRegionByRegionCodeAndCountryId({
    regionCode,
    countryId,
    useCache = true,
  }: {
    regionCode: string;
    countryId: string;
    useCache?: boolean;
  }): Promise<CountryRegion | undefined> {
    const region = this.getCountryRegionByRegionCodeAndCountryId(
      regionCode,
      countryId
    );

    if (region && useCache) {
      return region;
    }

    const result = await DIContainer.CountryRegionRepository.getList(1, 1, {
      country: countryId,
      code: regionCode,
    });

    const item = result.getByIndex(0);

    if (!item) {
      return;
    }

    this.updateCountryRegion(item);

    return item;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
