import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import InvoiceItemElement from "./invoice-item-element.model";

export default class InvoiceItemElementFactory
  implements ObjectBuilderInterface<InvoiceItemElement> {
  public buildFromJSON(data: { [key: string]: any }): InvoiceItemElement {
    const value = new InvoiceItemElement(
      data.id,
      data.invoiceItemId,
      data.type,
      data.entityId,
      +data.price,
      data.noPriceDefined == true,
      +data.qty,
      data.name
    );

    value.isNew = false;

    return value;
  }
}
