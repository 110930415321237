import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";
import { DIContainer } from "@/app.container";
import Plushie from "@/modules/plushie/plushie.model";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import BulkProject from "../bulk-project.model";
import BulkProjectPlushieRelation from "../bulk-project-plushie-relation.model";
import BulkProjectPlushieType from "../bulk-project-plushie-type.model";
import CreateBulkProjectPayload from "../create-bulk-project-payload.interface";
import { BulkProjectPlushieTypeValue } from "../bulk-project-plushie-type.value";
import BulkProjectBatchInformation from "../bulk-project-batch-information.model";

const name = "BulkProjectStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    bulkProject: {},
    bulkProjectPlushieRelation: {},
    bulkProjectPlushieType: {},
    bulkProjectPlushieRelations: {},
    plushieBulkProjectRelation: {},
    bulkProjectStatus: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class BulkProjectStore extends VuexModule {
  bulkProject: Dictionary<BulkProject> = {};
  bulkProjectPlushieRelation: Dictionary<BulkProjectPlushieRelation> = {};
  bulkProjectPlushieType: Dictionary<BulkProjectPlushieType> = {};
  bulkProjectBatchInformation: Dictionary<BulkProjectBatchInformation> = {};
  bulkProjectStatus: Dictionary<DirectoryValue> = {};

  bulkProjectPlushieRelations: Dictionary<string[]> = {};
  plushieBulkProjectRelation: Dictionary<string> = {};

  // ################################### BULK PROJECT #########################################

  get getBulkProjectById(): (bulkProjectId: string) => BulkProject | undefined {
    return (bulkProjectId: string) => {
      return this.bulkProject[bulkProjectId];
    };
  }

  get getBulkProjectByPlushieId(): (
    plushieId: string
  ) => BulkProject | undefined {
    return (plushieId: string) => {
      const projectId = this.plushieBulkProjectRelation[plushieId];
      if (!projectId) {
        return;
      }

      return this.bulkProject[projectId];
    };
  }

  @Mutation
  updateBulkProject(bulkProject: BulkProject): void {
    Vue.set(this.bulkProject, bulkProject.id, bulkProject);
  }

  @Action({ rawError: true })
  async loadBulkProjectById(
    bulkProjectId: string
  ): Promise<BulkProject | undefined> {
    const bulkProject = await DIContainer.BulkProjectRepository.getById(
      bulkProjectId
    );

    if (!bulkProject) {
      return;
    }

    this.updateBulkProject(bulkProject);

    return bulkProject;
  }

  @Action({ rawError: true })
  async createBatch(payload: {
    bulkProjectId: string;
    quantity: number;
    size: number;
    designerComment: string;
    notes: string;
    deadlineDate: string;
    useCustomSewInLabel: boolean;
    customSewInLabelStorageItemId?: string;
  }): Promise<Plushie> {
    const plushie = await DIContainer.BatchCreationRequestRepository.createBatch(
      payload
    );

    await Promise.all([
      this.loadBulkProjectById(payload.bulkProjectId),
      this.context.dispatch("setPlushie", plushie),
    ]);

    return plushie;
  }

  @Action({ rawError: true })
  async assignResponsibleUserToBulkProject({
    bulkProjectId,
    responsibleUserId,
  }: {
    bulkProjectId: string;
    responsibleUserId: string;
  }): Promise<BulkProject> {
    const result = await DIContainer.BulkProjectRepository.assignResponsibleUserToBulkProject(
      bulkProjectId,
      responsibleUserId
    );

    this.updateBulkProject(result);

    return result;
  }

  @Action({ rawError: true })
  async createBulkProject(
    payload: CreateBulkProjectPayload
  ): Promise<BulkProject> {
    const result = await DIContainer.BulkProjectRepository.createBulkProject(
      payload
    );

    this.updateBulkProject(result);

    return result;
  }

  // ################################### BULK PROJECT PLUSHIE RELATION #########################################

  get getBulkProjectPlushieRelationsByProjectId(): (
    projectId: string
  ) => BulkProjectPlushieRelation[] {
    return (projectId: string): BulkProjectPlushieRelation[] => {
      const bulkProjectPlushieRelation: BulkProjectPlushieRelation[] = [];
      const relationsIds = this.bulkProjectPlushieRelations[projectId];

      if (!relationsIds) {
        return bulkProjectPlushieRelation;
      }

      relationsIds.forEach((relationId) => {
        bulkProjectPlushieRelation.push(
          this.bulkProjectPlushieRelation[relationId]
        );
      });

      return bulkProjectPlushieRelation;
    };
  }

  get getLastPlushieRelationByBulkProjectId(): (
    projectId: string
  ) => BulkProjectPlushieRelation | undefined {
    return (projectId: string) => {
      const relationsIds = this.bulkProjectPlushieRelations[projectId];

      if (!relationsIds) {
        return;
      }

      const relations = relationsIds.map((id) => {
        return this.bulkProjectPlushieRelation[id];
      });

      relations.sort((relationA, relationB) => {
        if (!relationA.createdAt || !relationB.createdAt) {
          return 0;
        }

        return relationA.createdAt > relationB.createdAt ? -1 : 1;
      });

      return relations[0];
    };
  }

  @Mutation
  updateBulkProjectPlushieRelation(
    bulkProjectPlushieRelation: BulkProjectPlushieRelation
  ): void {
    Vue.set(
      this.bulkProjectPlushieRelation,
      bulkProjectPlushieRelation.id,
      bulkProjectPlushieRelation
    );
    Vue.set(
      this.plushieBulkProjectRelation,
      bulkProjectPlushieRelation.plushie,
      bulkProjectPlushieRelation.bulkProject
    );

    if (
      this.bulkProjectPlushieRelations[
        bulkProjectPlushieRelation.bulkProject
      ] === undefined
    ) {
      return;
    }

    if (
      this.bulkProjectPlushieRelations[
        bulkProjectPlushieRelation.bulkProject
      ].includes(bulkProjectPlushieRelation.id)
    ) {
      return;
    }

    this.bulkProjectPlushieRelations[
      bulkProjectPlushieRelation.bulkProject
    ].push(bulkProjectPlushieRelation.id);
  }

  @Mutation
  updateBulkProjectPlushieRelations({
    bulkProjectId,
    bulkProjectPlushieRelations,
  }: {
    bulkProjectId: string;
    bulkProjectPlushieRelations: BulkProjectPlushieRelation[];
  }): void {
    const bulkProjectPlushieRelationsIds: string[] = [];

    bulkProjectPlushieRelations.forEach((relation) => {
      bulkProjectPlushieRelationsIds.push(relation.id);

      Vue.set(this.bulkProjectPlushieRelation, relation.id, relation);
      Vue.set(
        this.plushieBulkProjectRelation,
        relation.plushie,
        relation.bulkProject
      );
    });

    Vue.set(
      this.bulkProjectPlushieRelations,
      bulkProjectId,
      bulkProjectPlushieRelationsIds
    );
  }

  @Action({ rawError: true })
  async loadBulkProjectPlushieRelationsByBulkProjectId(
    bulkProjectId: string
  ): Promise<BulkProjectPlushieRelation[]> {
    const collection = await DIContainer.BulkProjectPlushieRelationRepository.fetchListByBulkProjectId(
      bulkProjectId
    );

    const items = collection.getItems();

    this.updateBulkProjectPlushieRelations({
      bulkProjectId,
      bulkProjectPlushieRelations: items,
    });

    return items;
  }

  @Action({ rawError: true })
  async loadBulkProjectPlushieRelationByPlushieId(
    plushieId: string
  ): Promise<BulkProjectPlushieRelation | undefined> {
    const relation = await DIContainer.BulkProjectPlushieRelationRepository.fetchByPlushieId(
      plushieId
    );

    if (!relation) {
      return;
    }

    this.updateBulkProjectPlushieRelation(relation);

    return relation;
  }

  @Action({ rawError: true })
  async loadBulkProjectPlushieRelationsByBulkProjectIdAndPlushieType({
    bulkProjectId,
    plushieType,
  }: {
    bulkProjectId: string;
    plushieType: BulkProjectPlushieTypeValue;
  }): Promise<BulkProjectPlushieRelation[]> {
    const relations = await DIContainer.BulkProjectPlushieRelationRepository.fetchListByBulkProjectIdAndPlushieType(
      bulkProjectId,
      plushieType
    );

    relations.forEach((relation) => {
      this.updateBulkProjectPlushieRelation(relation);
    });

    return relations;
  }

  // ################################### BULK PROJECT PLUSHIE TYPE #########################################

  get getBulkProjectPlushieTypeById(): (
    plushieTypeId: string
  ) => BulkProjectPlushieType {
    return (plushieTypeId: string) => {
      return this.bulkProjectPlushieType[plushieTypeId];
    };
  }

  @Mutation
  updateBulkProjectPlushieType(
    bulkProjectPlushieType: BulkProjectPlushieType
  ): void {
    Vue.set(
      this.bulkProjectPlushieType,
      bulkProjectPlushieType.id,
      bulkProjectPlushieType
    );
  }

  @Action({ rawError: true })
  async loadBulkProjectPlushieTypes(
    useCache = true
  ): Promise<BulkProjectPlushieType[]> {
    const plushieTypes = Object.values(this.bulkProjectPlushieType);

    if (useCache && !!plushieTypes.length) {
      return plushieTypes;
    }

    const collection = await DIContainer.BulkProjectPlushieTypeRepository.getList(
      1,
      999
    );
    const items = collection.getItems();

    items.forEach((item) => {
      this.updateBulkProjectPlushieType(item);
    });

    return items;
  }

  // ################################### BULK PROJECT BATCH INFORMATION #########################################

  get getBulkProjectBatchInformationByPlushieId(): (
    plushieId: string
  ) => BulkProjectBatchInformation | undefined {
    return (plushieId: string) => {
      return this.bulkProjectBatchInformation[plushieId];
    };
  }

  @Mutation
  updateBulkProjectBatchInformation(
    bulkProjectBatchInformation: BulkProjectBatchInformation
  ): void {
    Vue.set(
      this.bulkProjectBatchInformation,
      bulkProjectBatchInformation.id,
      bulkProjectBatchInformation
    );
  }

  @Action({ rawError: true })
  async loadBulkProjectBatchInformationByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<BulkProjectBatchInformation | undefined> {
    if (useCache && this.bulkProjectBatchInformation[plushieId]) {
      return this.bulkProjectBatchInformation[plushieId];
    }

    const batchInformation = await DIContainer.BulkProjectBatchInformationRepository.fetchByPlushieId(
      plushieId
    );

    if (!batchInformation) {
      return;
    }

    this.updateBulkProjectBatchInformation(batchInformation);

    return batchInformation;
  }

  @Action({ rawError: true })
  async saveBulkProjectBatchInformation(
    bulkProjectBatchInformation: BulkProjectBatchInformation
  ): Promise<BulkProjectBatchInformation> {
    const batchInformation = await DIContainer.BulkProjectBatchInformationRepository.save(
      bulkProjectBatchInformation
    );

    this.updateBulkProjectBatchInformation(batchInformation);

    return batchInformation;
  }

  // ################################### BULK PROJECT STATUS #########################################

  get getBulkProjectStatusById(): (
    statusId: string
  ) => DirectoryValue | undefined {
    return (statusId: string) => {
      return this.bulkProjectStatus[statusId];
    };
  }

  get bulkProjectStatuses(): DirectoryValue[] {
    return Object.values(this.bulkProjectStatus);
  }

  @Mutation
  updateBulkProjectStatus(bulkProjectStatus: DirectoryValue): void {
    Vue.set(this.bulkProjectStatus, bulkProjectStatus.id, bulkProjectStatus);
  }

  @Action({ rawError: true })
  async loadBulkProjectStatuses(useCache: boolean): Promise<DirectoryValue[]> {
    if (useCache && this.bulkProjectStatuses.length > 0) {
      return this.bulkProjectStatuses;
    }

    const response = await DIContainer.BulkProjectStatusRepository.getList(
      1,
      999
    );

    const items = response.getItems();

    items.forEach((item) => {
      this.updateBulkProjectStatus(item);
    });

    return items;
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    await this.loadBulkProjectBatchInformationByPlushieId({
      plushieId,
      useCache: false,
    });
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
