import { Route } from "vue-router";

type ListPageQuery = Record<string | number, unknown>;

export default function listItemPageUrlParser(
  route: Route
): Record<string, ListPageQuery | string | number> {
  let query: ListPageQuery = {};
  let listQuery: ListPageQuery = {};

  if (typeof route.query.query === "string") {
    query = JSON.parse(route.query.query);
  }

  if (typeof route.query.listQuery === "string") {
    listQuery = JSON.parse(route.query.listQuery);
  }

  return {
    query,
    listQuery,
  };
}
