import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PhotoMarkup from "./photo-markup.model";
import PhotoMarkupCreate from "./photo-markup-create.interface";
import PhotoMarkupEdit from "./photo-markup-edit.interface";
import { MarkupData } from "./markup-data";

export default class PhotoMarkupRepository extends BasicRepository<PhotoMarkup> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PhotoMarkup>,
    responseParser: ApiCollectionResponseParserService<PhotoMarkup>
  ) {
    super("photo_markups", api, objectBuilder, responseParser);
  }

  public async getByImageId(imageId: string): Promise<PhotoMarkup | undefined> {
    const collection = await this.fetchList({ imageId });

    return collection.getItems()[0];
  }

  public async getByImageIds(imageIds: string[]): Promise<PhotoMarkup[]> {
    const collection = await this.fetchList(
      { imageId: imageIds },
      1,
      imageIds.length
    );

    return collection.getItems();
  }

  public async createMarkup({
    item,
    svgContent,
  }: PhotoMarkupCreate): Promise<PhotoMarkup> {
    const data: Dictionary<string | MarkupData> = {
      id: item.id,
      image_id: item.image,
      entity_type_id: item.entityType,
      markup: item.markup,
      svg_content: svgContent,
    };

    const response = await this.api.post(this.resourceName, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async editMarkup(markupEdit: PhotoMarkupEdit): Promise<PhotoMarkup> {
    const data: Dictionary<MarkupData | string> = {
      markup: markupEdit.markup,
      svg_content: markupEdit.svgContent,
    };

    const response = await this.api.put(
      `${this.resourceName}/${markupEdit.id}`,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }
}
