


















































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import { Resource } from "@/modules/account/resource";
import OrderInformation from "@/modules/plushie/order-information.model";

import EditableText from "./EditableText.vue";
import OrderNumber from "./OrderNumber.vue";

import PlushieStore from "../store";
import Plushie from "../plushie.model";
import PlushieInformationUpdate from "../plushie-information-update.interface";
import { PlushieStatusValue } from "../plushie-status.value";
import { ProductValue } from "../product.value";

@Component({
  components: {
    EditableText,
    InlineSpinner,
    OrderNumber,
  },
})
export default class OrderDetailsCard extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fIsLoading = true;

  private fPlushieDataStore: PlushieStore;

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get orderInformation(): OrderInformation | undefined {
    return this.fPlushieDataStore.getOrderInformationByPlushieId(
      this.plushieId
    );
  }

  get plushie(): Plushie | undefined {
    if (this.plushieId == null) {
      return undefined;
    }

    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  get store(): DirectoryValue | undefined {
    if (this.plushieId == null) {
      return undefined;
    }

    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    if (plushie == null) {
      return undefined;
    }

    return this.fPlushieDataStore.getStoreById(plushie.store);
  }

  get canEditCustomerDescription(): boolean {
    if (!this.plushie) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      [
        PlushieStatusValue.REVIEW,
        PlushieStatusValue.PENDING_BULK_PRODUCTION,
      ].includes(this.plushie.status) &&
      user.hasPermissionForResource(Resource.PLUSHIE_UPDATE)
    );
  }

  get showCustomerDescription(): boolean {
    if (!this.plushie) {
      return false;
    }

    return [
      ProductValue.BUDSIE,
      ProductValue.SELFIE,
      ProductValue.SPECIALITY_COMMISSION,
      ProductValue.PETSIE_CAT,
      ProductValue.PETSIE_DOG,
      ProductValue.PETSIE_OTHER,
      ProductValue.IMAGINABLE,
      ProductValue.HUGGABLES_CAT,
      ProductValue.HUGGABLES_DOG,
      ProductValue.HUGGABLES_OTHER,
      ProductValue.FOREVERS_CAT,
      ProductValue.FOREVERS_DOG,
      ProductValue.FOREVERS_OTHER,
      ProductValue.WAGGABLES_DOG,
      ProductValue.BUDSIES_PALS,
      ProductValue.BUDSIES_PUPPET,
      ProductValue.SELFIES_PUPPET,
      ProductValue.BOBBLEHEADS,
      ProductValue.FIGURINES,
      ProductValue.PETSIES_BOBBLEHEADS,
      ProductValue.PETSIES_FIGURINES,
      ProductValue.GOLF_HEAD_COVER_DOG,
      ProductValue.GOLF_HEAD_COVER_CAT,
      ProductValue.GOLF_HEAD_COVER_OTHER,
      ProductValue.BULK_SAMPLE,
      ProductValue.KEYCHAIN_BULK_SAMPLE,
      ProductValue.PILLOW_BULK_SAMPLE,
      ProductValue.PLUSHIE_BATCH,
      ProductValue.PILLOW_BATCH,
      ProductValue.KEYCHAIN_BATCH,
    ].includes(this.plushie.product);
  }

  public async updateDescription(description: string): Promise<void> {
    if (!this.plushie) {
      return;
    }

    const value: PlushieInformationUpdate = {
      plushieId: this.plushieId,
      description: description,
    };

    await this.fPlushieDataStore.updatePlushieInformation(value);
  }

  private async getPlushieData(plushieId: string) {
    const [plushie] = await Promise.all([
      this.fPlushieDataStore.loadPlushieById({ id: plushieId }),
      this.fPlushieDataStore.loadOrderInformationByPlushieId(plushieId),
    ]);

    if (plushie) {
      await this.fPlushieDataStore.loadStoreById(plushie.store);
    }
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);
    this.fIsLoading = false;
  }
}
