import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import ApiService from "../api/api.service";
import BasicRepository from "../api/basic.repository";
import ObjectBuilderInterface from "../api/object-builder.interface";
import Sender from "./sender.model";

export default class SenderRepository extends BasicRepository<Sender> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Sender>,
    responseParser: ApiCollectionResponseParserService<Sender>
  ) {
    super("senders", api, objectBuilder, responseParser);
  }
}
