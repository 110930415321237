import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Carrier from "./carrier.model";

export default class CarrierRepository extends BasicRepository<Carrier> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Carrier>,
    responseParser: ApiCollectionResponseParserService<Carrier>
  ) {
    super("carriers", api, objectBuilder, responseParser);
  }

  protected prepareData(item: Carrier): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
      name: item.name,
      code: item.code,
    };

    return data;
  }
}
