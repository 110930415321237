


























































































import Vue from "vue";
import { Component, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import Factory from "@/modules/factory/factory.model";
import { Resource } from "@/modules/account/resource";

import StrictCapacityModeStore from "../store";

@Component({
  metaInfo: {
    title: "Strict Capacity Mode Management",
  },
  components: {
    ActionButton,
    FormErrors,
    LoadingSpinner,
  },
})
export default class StrictCapacityModeManagementPage extends Vue {
  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fErrorsList: string[] = [];
  private fFactoryDataStore: FactoryStore;
  private fFactoryStrictCapacityModeStore: StrictCapacityModeStore;
  private fIsLoading = false;

  get canManageFactoriesList(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_STRICT_CAPACITY_MODE_MANAGE
    );
  }

  get enabledFactories(): Factory[] {
    const result: Factory[] = [];

    const factoriesList = this.fFactoryDataStore.activeFactoriesList;

    factoriesList.forEach((factory) => {
      const isExist = this.fFactoryStrictCapacityModeStore.getFactoryStrictCapacityModeById(
        factory.id
      );

      if (!isExist) {
        return;
      }

      result.push(factory);
    });

    return result;
  }

  get errorsList(): string[] {
    return this.fErrorsList;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get notEnabledFactories(): Factory[] {
    const result: Factory[] = [];

    const factoriesList = this.fFactoryDataStore.activeFactoriesList;

    factoriesList.forEach((factory) => {
      const isExist = this.fFactoryStrictCapacityModeStore.getFactoryStrictCapacityModeById(
        factory.id
      );

      if (!isExist) {
        result.push(factory);

        return;
      }
    });

    return result;
  }

  public constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fFactoryStrictCapacityModeStore = getModule(
      StrictCapacityModeStore,
      dataStore
    );
  }

  public async disableItem(factory: Factory): Promise<void> {
    this.fErrorsList = [];

    try {
      await this.fFactoryStrictCapacityModeStore.disableFactoryStrictCapacityModeById(
        factory.id
      );
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);

      this.fErrorsList.push(...errors);
    }
  }

  public async enableItem(factory: Factory): Promise<void> {
    this.fErrorsList = [];

    try {
      await this.fFactoryStrictCapacityModeStore.enableFactoryStrictCapacityModeById(
        factory.id
      );
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);

      this.fErrorsList.push(...errors);
    }
  }

  protected created(): void {
    void this.loadData();
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    const factories = await this.fFactoryDataStore.loadFactories();

    const factoryIds = factories.map((factory) => factory.id);

    try {
      await this.fFactoryStrictCapacityModeStore.loadFactoryStrictCapacityModeByIds(
        {
          ids: factoryIds,
        }
      );
    } finally {
      this.fIsLoading = false;
    }
  }
}
