












































import { Component, Vue } from "vue-property-decorator";
import jQuery from "jquery";

import PageHeader from "./PageHeader.vue";
import PageFooter from "./PageFooter.vue";
import MainMenu from "./MainMenu.vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Foundation = require("foundation-sites");

@Component({
  components: {
    PageHeader,
    PageFooter,
    MainMenu,
  },
  metaInfo: {},
})
export default class AdminPage extends Vue {
  private fMenu?: FoundationSites.OffCanvas;

  protected mounted(): void {
    const el = this.$refs.mainMenuSection as HTMLElement;

    this.fMenu = new Foundation.OffCanvas(jQuery(el));
  }

  protected destroyed(): void {
    if (!this.fMenu) {
      return;
    }

    this.fMenu.destroy();
  }
}
