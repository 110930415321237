





































import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import { Dictionary } from "@/lib/Dictionary.type";

import TagAlertAssignRules from "./TagAlertAssignRules.vue";
import AlertEditForm from "./AlertEditForm.vue";

import AlertStore from "../store";
import AlertRepository from "../alert.repository";
import Alert from "../alert.model";

@Component({
  metaInfo() {
    const component = this as PlushieAlertEditPage;

    return {
      title:
        component.alert != null ? component.alert.title : "Hashtag Alert Edit",
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    AlertEditForm,
    TagAlertAssignRules,
  },
})
export default class PlushieAlertEditPage extends Vue {
  @Prop()
  public readonly alertId!: string;

  @Prop()
  public readonly query!: Record<string, any>;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("PlushieAlertRepository")
  private fAlertRepository!: AlertRepository;

  private fAlertDataStore: AlertStore;
  private fFileStorageDataStore: FileStorageStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get alert(): Alert | undefined {
    return this.fAlertDataStore.getPlushieAlertById(this.alertId);
  }

  constructor() {
    super();
    this.fAlertDataStore = getModule(AlertStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public getAlertsOrder(): Promise<string[]> {
    return this.fAlertRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  public onCancel(): void {
    void this.$router.push({
      name: "PlushieAlertsListPage",
      query: this.listQuery,
    });
  }

  public onAlertUpdated(): void {
    void this.$router.push({
      name: "PlushieAlertsListPage",
      query: this.listQuery,
    });
  }

  private async getAlertData(alertId: string): Promise<void> {
    const alert = await this.fAlertDataStore.loadPlushieAlertById(alertId);

    if (!alert) {
      return;
    }

    await this.fFileStorageDataStore.loadItemsByIds([alert.iconStorageId]);
  }

  @Watch("alertId", { immediate: true })
  private async _onAlertIdChange() {
    if (this.alertId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getAlertData(this.alertId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
