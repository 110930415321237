





























































































import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";
import DatePicker from "vuejs-datepicker";

import ColorsAutocomplete from "@/lib/components/ColorsAutocomplete.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import autoindent from "@/lib/directives/auto-indent.directive";
import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import FactoryStore from "@/modules/factory/store";
import Factory from "@/modules/factory/factory.model";
import PlushieStore from "@/modules/plushie/store";
import ProductionProcessStore from "@/modules/production-process/store";
import { Command } from "@/modules/production-process/command";
import SettingStore from "@/modules/settings/store";
import Setting from "@/modules/settings/setting.model";

@Component({
  components: {
    ColorsAutocomplete,
    FormErrors,
    FormField,
    SubmitButton,
    DatePicker,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class StartSampleProductionCommand extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  public taskDetails?: string;
  public factoryId?: string;
  public expectedDueDate?: Date;
  public negotiatedPrice?: number;

  private fFactoryStore: FactoryStore;
  private fPlushieStore: PlushieStore;
  private fProductionProcessStore: ProductionProcessStore;
  private fSettingStore: SettingStore;

  private fIsDataLoading = true;

  get availableCommands(): Set<Command> {
    return this.fProductionProcessStore.getAvailableCommandsForPlushieId(
      this.plushieId
    );
  }

  get designerComment(): string | undefined {
    const designInformation = this.fPlushieStore.getDesignInformationByPlushieId(
      this.plushieId
    );

    if (!designInformation) {
      return;
    }

    return designInformation.comment;
  }

  get factoriesOptionsList(): Factory[] {
    return this.fFactoryStore.activeFactoriesList;
  }

  get isDataLoading(): boolean {
    return this.fIsDataLoading;
  }

  get isFormDisabled(): boolean {
    return this.isDisabled || !this.isStartSampleProductionAvailable;
  }

  get isStartSampleProductionAvailable(): boolean {
    return this.availableCommands.has(Command.StartSampleProduction);
  }

  get turnaroundDaysCount(): number {
    const setting = this.fSettingStore.getSettingById(
      Setting.BULK_PROJECT_BATCH_TURNAROUND_DAYS_COUNT
    );

    if (!setting || !setting.value) {
      return 0;
    }

    return Number.parseInt(setting.value);
  }

  constructor() {
    super();
    this.fFactoryStore = getModule(FactoryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
    this.fProductionProcessStore = getModule(ProductionProcessStore, rootStore);
    this.fSettingStore = getModule(SettingStore, rootStore);
  }

  protected data(): Record<string, string | number | undefined | Date> {
    return {
      factoryId: this.factoryId,
      taskDetails: this.taskDetails,
      expectedDueDate: this.expectedDueDate,
      negotiatedPrice: this.negotiatedPrice,
    };
  }

  protected async created(): Promise<void> {
    await this.loadData();
    this.prefillTaskDetails();
  }

  protected async performSubmit(): Promise<void> {
    if (
      !this.taskDetails ||
      !this.factoryId ||
      !this.expectedDueDate ||
      !this.negotiatedPrice
    ) {
      return;
    }

    await this.fProductionProcessStore.startSampleProduction({
      plushie: this.plushieId,
      factoryIdToAssign: this.factoryId,
      taskDetails: this.taskDetails,
      expectedDueDate: this.expectedDueDate.toISOString().split("T")[0],
      negotiatedPrice: this.negotiatedPrice,
    });
  }

  private getDefaultDueDateDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() + this.turnaroundDaysCount);
    return date;
  }

  private async loadData(): Promise<void> {
    this.fIsDataLoading = true;

    await Promise.all([
      this.fFactoryStore.loadFactories(),
      this.fPlushieStore.loadDesignInformationByPlushieId({
        plushieId: this.plushieId,
        useCache: true,
      }),
      this.fSettingStore.loadSettingById(
        Setting.BULK_PROJECT_BATCH_TURNAROUND_DAYS_COUNT
      ),
    ]);

    this.fIsDataLoading = false;
  }

  private prefillTaskDetails(): void {
    this.taskDetails = this.designerComment || "";

    this.expectedDueDate = this.getDefaultDueDateDate();
  }
}
