import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class RejectionReasonProductRelation implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fRejectionReason: string,
    private fProduct: string
  ) {
    [fId, fRejectionReason, fProduct].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get rejectionReason() {
    return this.fRejectionReason;
  }

  get product() {
    return this.fProduct;
  }
}
