import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import TagSearchResult from "./tag-search-result.model";

export default class TagSearchResultFactory
  implements ObjectBuilderInterface<TagSearchResult> {
  public buildFromJSON(data: { [key: string]: any }): TagSearchResult {
    return new TagSearchResult(data.result);
  }
}
