import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class RoleResource implements MutableValue {
  public isNew = true;
  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fRole: string,
    private fResource: string
  ) {
    [fId, fRole, fResource].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get role() {
    return this.fRole;
  }

  get resource() {
    return this.fResource;
  }
}
