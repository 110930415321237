


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";
import { Dictionary } from "@/lib/Dictionary.type";

import ProductionTimeNormsHistoryItemsGroup from "./History/ItemsGroup.vue";

import FactoryStore from "../../store";
import HistoryItem from "../../production-time-norm-history-item.model";

interface HistoryGroup {
  items: HistoryItem[];
  title: string;
}

@Component({
  components: {
    LoadingSpinner,
    ProductionTimeNormsHistoryItemsGroup,
  },
})
export default class ProductionTimeNormHistory extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  private fIsLoading = true;

  private fAccountDataStore: AccountStore;
  private fFactoryDataStore: FactoryStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  get historyGroups(): Dictionary<HistoryGroup> {
    const groups: Dictionary<HistoryGroup> = {};

    for (const historyItem of this.historyItems) {
      if (!historyItem.createdAt) {
        continue;
      }

      const groupKey = historyItem.createdAt.getTime();

      if (!groups[groupKey]) {
        groups[groupKey] = {
          title: historyItem.createdAt.toLocaleString(),
          items: [],
        };
      }

      groups[groupKey].items.push(historyItem);
    }

    return groups;
  }

  get historyItems(): HistoryItem[] {
    return this.fFactoryDataStore.getProductionTimeNormItemsByFactoryId(
      this.factoryId
    );
  }

  private async getFactoryData(factoryId: string) {
    const historyItems = await this.fFactoryDataStore.loadProductionTimeNormHistoryItemsByFactoryId(
      { factoryId }
    );

    if (historyItems.length < 1) {
      return;
    }

    const userIds: string[] = [];
    historyItems.forEach((item) => {
      if (!userIds.includes(item.user)) {
        userIds.push(item.user);
      }
    });

    await Promise.all([
      this.fAccountDataStore.loadUserInfosByIds(userIds),
      this.fAccountDataStore.loadUserRolesByUserId({ userIds: userIds }),
      this.fAccountDataStore.loadRoles(),
      this.fFactoryDataStore.loadHistoryTypes(),
    ]);
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (!this.factoryId) {
      return;
    }

    this.fIsLoading = true;

    await this.getFactoryData(this.factoryId);
    this.fIsLoading = false;
  }
}
