




































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import Product from "@/modules/plushie/product.model";

import FactoryStore from "../../store";
import Factory from "../../factory.model";
import ProductAllocation from "../../product-allocation.model";
import { ProductAllocationSlotTypeValue } from "../../product-allication-slot-type.value";

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class ProductAllocationViewForm extends Vue {
  @Prop({ required: true })
  public readonly slotType!: ProductAllocationSlotTypeValue;

  private fIsLoading = false;

  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  get columnWidth(): string {
    const columnsCount = this.factories.length ? this.factories.length : 1;

    const value = Math.round((80 * 100) / columnsCount) / 100;

    return value.toString() + "%";
  }

  get getValueByFactoryAndProduct(): (
    factoryId: string,
    productId: string
  ) => number | undefined {
    return (factoryId: string, productId: string) => {
      const item = this.allocationItems.find(
        (item) => item.factory === factoryId && item.product === productId
      );

      const value = item ? item.distributionPercent : 0;
      return value ? value : undefined;
    };
  }

  get factories(): Factory[] {
    return this.fFactoryDataStore.activeFactoriesList;
  }

  get products(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get allocationItems(): ProductAllocation[] {
    return this.fFactoryDataStore.getProductAllocationsBySlotType(
      this.slotType
    );
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fFactoryDataStore.loadProductAllocations(),
        this.fFactoryDataStore.loadFactories(),
        this.fPlushieDataStore.loadProducts(),
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
