import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieRelation from "./plushie-relation.model";

export default class PlushieRelationFactory
  implements ObjectBuilderInterface<PlushieRelation> {
  public buildFromJSON(data: { [key: string]: any }): PlushieRelation {
    const value = new PlushieRelation(data.id, data.period.toString());

    (value as any).fCreatedAt = new Date(data.createdAt);

    return value;
  }
}
