import ApiCollectionResponseParserService from "../api/api-collection-response-parser.service";
import ApiService from "../api/api.service";
import BasicRepository from "../api/basic.repository";
import ObjectBuilderInterface from "../api/object-builder.interface";
import EmailLayout from "./email-layout.model";

export default class EmailLayoutRepository extends BasicRepository<EmailLayout> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<EmailLayout>,
    responseParser: ApiCollectionResponseParserService<EmailLayout>
  ) {
    super("layouts", api, objectBuilder, responseParser);
  }
}
