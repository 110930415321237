




































































































import { Component, Vue, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  ServerResponse,
  ColumnFilterDropdownOption,
  RowClickEventData,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";
import { saveAs } from "file-saver";

import ActionButton from "@/lib/components/ActionButton.vue";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import FactoryStore from "@/modules/factory/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import FactoryInvoiceStore from "@/modules/factory-invoice/store";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";
import { Resource } from "@/modules/account/resource";

import MassActionsCard from "./MassActionsCard.vue";

import { FactoryInvoiceStatusValue } from "../factory-invoice-status.value";
import MassActionServiceInterface from "../mass-action-service.interface";
import FactoryInvoiceRepository from "../factory-invoice.repository";
import ResponseFileNameResolver from "../../general/response-file-name-resolver.service";

interface TableRow {
  id: string;
  incrementalId: string;
  createdAt: string;
  factory?: string;
  itemsQty?: number;
  total?: number;
  status?: string;
  canDownloadPdf: boolean;
}

@Component({
  metaInfo: {
    title: "Factory Invoices",
  },
  components: {
    MassActionsCard,
    ActionButton,
  },
})
export default class FactoryInvoicesListPage extends mixins(
  GeneralListPageMixin
) {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("CreatePaymentsService")
  private fCreatePaymentsService!: MassActionServiceInterface;

  @Inject("FactoryInvoiceRepository")
  private fFactoryInvoiceRepository!: FactoryInvoiceRepository;

  @Inject("window")
  private fWindow!: Window;

  public selectedInvoices: string[] = [];

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fFactoryDataStore: FactoryStore;

  private fLastSelectedItem?: string;
  private fRowsIdsList: string[] = [];

  get isFactoryView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return true;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get isSelectAllAvailable(): boolean {
    return this.selectedInvoices.length < this.fRowsIdsList.length;
  }

  public constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);

    this.fColumns = [
      {
        name: "selection",
        header: "",
        displayConstraint: "min_tablet",
      },
      {
        name: "incrementalId",
        header: "Number",
        isFilterable: true,
        filterKey: "incrementalId",
        cssClass: "_incrementalid-column",
      },
      {
        name: "createdAt",
        header: "Created At",
        isSortable: true,
        sortKey: "createdAt",
        cssClass: "_createdat-column",
        displayConstraint: "min_tablet",
      },
      {
        name: "factory",
        header: "Factory",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "factoryId",
        cssClass: "_factory-column -hide-for-factory",
        displayConstraint: "min_tablet",
      },
      {
        name: "itemsQty",
        header: "# Items",
        cssClass: "_itemsqty-column",
        displayConstraint: "min_tabletL",
      },
      {
        name: "total",
        header: "Total",
        cssClass: "_total-column",
      },
      {
        name: "status",
        header: "Status",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "status",
        cssClass: "_status-column",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "createdAt",
        ascending: false,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public clearSelection(): void {
    this.selectedInvoices = [];
  }

  public toggleItemSelection(id: string): void {
    const index = this.selectedInvoices.indexOf(id);

    if (index === -1) {
      this.selectedInvoices.push(id);
    } else {
      this.selectedInvoices.splice(index, 1);
    }
  }

  public selectAll(): void {
    this.selectedInvoices = [...this.fRowsIdsList];
  }

  public onMassActionProcessed(): void {
    if (!this.fTable) {
      return;
    }

    this.fTable.refresh();
  }

  public onMassActionProcessingFinished(): void {
    this.fIsLoading = false;
  }

  public onMassActionProcessingStarted(): void {
    this.fIsLoading = true;
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    data.event.preventDefault();

    const itemId = data.row.id;

    if (!this.$screen.tabletL) {
      void this.$router.push(this.getItemUrl(itemId, "FactoryInvoiceViewPage"));

      return;
    }

    const selection = document.getSelection();

    if (selection) {
      selection.removeAllRanges();
    }

    if (!data.event.shiftKey) {
      this.toggleItemSelection(itemId);

      return;
    }

    if (!this.fLastSelectedItem) {
      this.toggleItemSelection(itemId);
      this.fLastSelectedItem = itemId;

      return;
    }

    const startIndex = this.fRowsIdsList.indexOf(this.fLastSelectedItem);
    const endIndex = this.fRowsIdsList.indexOf(itemId);

    const isSelect = this.selectedInvoices.includes(this.fLastSelectedItem);

    this.setSelectionForItems(startIndex, endIndex, isSelect);

    this.fLastSelectedItem = undefined;
  }

  get isPayActionAvailable(): (invoiceId: string) => boolean {
    return (invoiceId: string) => {
      const factoryInvoice = this.fFactoryInvoiceDataStore.getFactoryInvoiceById(
        invoiceId
      );

      if (!factoryInvoice) {
        return false;
      }

      if (factoryInvoice.status !== FactoryInvoiceStatusValue.UNPAID) {
        return false;
      }

      const user = this.fUserProvider.getUser();

      if (!user) {
        return false;
      }

      return user.hasPermissionForResource(
        Resource.FACTORY_INVOICE_PAYMENTS_MANAGE
      );
    };
  }

  public async payInvoice(invoiceId: string): Promise<void> {
    await this.fCreatePaymentsService.execute([invoiceId]);

    if (this.fTable) {
      this.fTable.refresh();
    }
  }

  public async downloadPdf(invoiceId: string): Promise<void> {
    const response = await this.fFactoryInvoiceRepository.getPdfByInvoiceId(
      invoiceId
    );

    const blob = new Blob([response.data], {
      type: "application/pdf",
    });

    const fileName =
      ResponseFileNameResolver.resolve(response) ||
      `factory_invoice_${invoiceId}.pdf`;

    saveAs(blob, fileName);
  }

  protected canDownloadPdf(statusId: string | undefined): boolean {
    if (!statusId) {
      return false;
    }

    const status = this.fFactoryInvoiceDataStore.getInvoiceStatusById(statusId);

    if (!status) {
      return false;
    }

    return status.name === "Unpaid" || status.name === "Paid";
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    this.selectedInvoices = [];
    this.fLastSelectedItem = undefined;

    const invoicesCollection = await this.fFactoryInvoiceDataStore.loadFactoryInvoices(
      {
        page,
        limit,
        filter,
        order,
      }
    );

    const invoices = invoicesCollection.getItems();

    const result: TableRow[] = [];

    invoices.forEach((invoice) => {
      const row: TableRow = {
        id: invoice.id,
        incrementalId: invoice.number,
        createdAt: invoice.createdAt
          ? invoice.createdAt.toLocaleDateString()
          : "",
        itemsQty: invoice.itemsQty,
        total: invoice.total ? invoice.total : 0,
        status: invoice.status,
        canDownloadPdf: this.canDownloadPdf(invoice.status),
      };

      if (!this.isFactoryView) {
        const factory = this.fFactoryDataStore.getFactoryById(invoice.factory);
        row.factory = factory ? factory.name : "";
      }

      this.fRowsIdsList.push(invoice.id);

      result.push(row);
    });

    return { data: result, count: invoicesCollection.totalItemsCount };
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  protected async created(): Promise<void> {
    this.fWindow.addEventListener("keyup", this.fKeyUpHandler);
  }

  protected destroyed(): void {
    this.fWindow.removeEventListener("keyup", this.fKeyUpHandler);
  }

  protected async init(): Promise<void> {
    await this.fFactoryInvoiceDataStore.loadInvoiceStatuses();

    const statuses = this.fFactoryInvoiceDataStore.invoiceStatusList;

    const statusOptions: ColumnFilterDropdownOption[] = [];

    statuses.forEach((item) => {
      statusOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "status", statusOptions);
    }

    if (this.isFactoryView) {
      return;
    }

    await this.fFactoryDataStore.loadFactories();

    const factories = this.fFactoryDataStore.factoriesList;

    const factoryOptions: ColumnFilterDropdownOption[] = [];

    factories.forEach((item) => {
      factoryOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "factory", factoryOptions);
    }

    return;
  }

  private setSelectionForItems(
    startIndex: number,
    endIndex: number,
    isSelected: boolean
  ): void {
    if (endIndex < startIndex) {
      [startIndex, endIndex] = [endIndex - 1, startIndex - 1];
    }

    const itemsToToggle = this.fRowsIdsList.slice(startIndex + 1, endIndex + 1);

    itemsToToggle.forEach((id) => {
      const index = this.selectedInvoices.indexOf(id);

      if (isSelected && index === -1) {
        this.selectedInvoices.push(id);
      }

      if (!isSelected && index !== -1) {
        this.selectedInvoices.splice(index, 1);
      }
    });
  }

  private onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 16 || event.charCode === 16) {
      this.fLastSelectedItem = undefined;
    }
  }

  private fKeyUpHandler = (e: KeyboardEvent) => this.onKeyUp(e);
}
