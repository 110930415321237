import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import TagAlertAssignRule from "./tag-alert-assign-rule.model";

export default class TagAlertAssignRuleFactory
  implements ObjectBuilderInterface<TagAlertAssignRule> {
  public buildFromJSON(data: { [key: string]: any }): TagAlertAssignRule {
    const value = new TagAlertAssignRule(data.id, data.tagName, data.alert);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
