import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { FieldOrderDesc } from "@/modules/api/field-order-options";
import { Dictionary } from "@/lib/Dictionary.type";

import ExtraPayment from "./extra-payment.model";
import { ResourceCollection } from "../api/resource.collection";
import ExtraPaymentUpdate from "./extra-payment-update.interface";

export default class ExtraPaymentRepository extends BasicRepository<ExtraPayment> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ExtraPayment>,
    responseParser: ApiCollectionResponseParserService<ExtraPayment>
  ) {
    super("extra_payments", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(
    plushieId: string
  ): Promise<ResourceCollection<ExtraPayment>> {
    return this.fetchList({ plushieId: plushieId });
  }

  public async getByInvoiceId(
    invoiceId: string
  ): Promise<ResourceCollection<ExtraPayment>> {
    return this.fetchList({ invoice: invoiceId }, 1, 999);
  }

  public async getListByDateRange(
    fromDate: Date,
    toDate: Date
  ): Promise<ResourceCollection<ExtraPayment>> {
    return this.fetchList(
      {
        "createdAt[after]": fromDate.toISOString(),
        "createdAt[before]": toDate.toISOString(),
        "order[createdAt]": FieldOrderDesc,
      },
      1,
      9999
    );
  }

  public async updateFault(
    extraPaymentUpdate: ExtraPaymentUpdate
  ): Promise<ExtraPayment> {
    const data: Dictionary<string> = {
      faultId: extraPaymentUpdate.fault,
    };

    const response = await this.api.put(
      this.resourceName + "/" + extraPaymentUpdate.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }

  protected prepareData(item: ExtraPayment): Dictionary<string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      amount: item.amount,
      description: item.description,
    };

    if (item.fault) {
      data.faultId = item.fault;
    }

    if (item.plushie) {
      data.plushieId = item.plushie;
    }

    if (item.invoice) {
      data.invoiceId = item.invoice;
    }

    return data;
  }
}
