module.exports = {
  request: function (req, token) {
    this.options.http._setHeaders.call(this, req, {
      Authorization: "Bearer " + (typeof token === "string" ? token : ""),
    });
  },

  response: function (res) {
    // var headers = this.options.http._getHeaders.call(this, res),
    //  token = headers.Authorization || headers.authorization;

    const baseUrl = res.config.baseURL;

    const responseUrl = res.config.url.replace(baseUrl, "");

    const loginUrl = this.options.loginData.url;

    if (responseUrl !== loginUrl) {
      return;
    }

    const token = res.data.token;

    if (typeof token !== "string") {
      return;
    }

    const tokenParts = token.split(/Bearer:?\s?/i);

    return tokenParts[tokenParts.length > 1 ? 1 : 0].trim();
  },
};
