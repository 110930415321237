export default class InReviewSnapshot {
  constructor(private fProductName: string, private fTotal: string | null) {
    [fProductName, fTotal].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get productName(): string {
    return this.fProductName;
  }

  get total(): string | null {
    return this.fTotal;
  }
}
