export enum Resource {
  ACTION_LOGGER_LOGS_READ = "action_logger_logs_read",
  AUTO_APPROVEMENT_EXCLUDES_MANAGE = "auto_approvement_excludes_manage",
  AUTO_APPROVEMENT_EXCLUDES_VIEW = "auto_approvement_excludes_view",
  PLUSHIE_ALERTS_VIEW = "plushie_alert_view",
  PLUSHIE_ALERTS_MANAGE = "plushie_alert_manage",
  ARTWORKS_UPLOAD = "artworks_upload",
  BATCH_PLUSHIE_CREATE = "batch_plushie_create",
  BODYPARTS_MANAGE = "bodyparts_manage",
  BODYPARTS_VIEW = "bodyparts_view",
  BULK_PROJECT_VIEW = "bulk_project_view",
  BULK_PROJECT_MANAGE = "bulk_project_manage",
  BULK_PROJECT_BATCH_INFORMATION_VIEW = "batch_information_view",
  BULK_PROJECT_BATCH_INFORMATION_MANAGE = "batch_information_manage",
  BULK_SHIPMENTS_MANAGE = "bulk_shipments_manage",
  BULK_SHIPMENTS_READ = "bulk_shipments_read",
  CUSTOMER_PREVIEW_COUNT_VIEW = "preview_count_view",
  CUSTOMER_PREVIEW_COUNT_MANAGE = "preview_count_manage",
  DIRECT_SHIPMENT_INTENTION_VIEW = "direct_shipment_intention_view",
  DIRECT_SHIPMENT_INTENTION_MANAGE = "direct_shipment_intention_manage",
  DIRECT_SHIPMENT_INTENTION_RULE_VIEW = "direct_shipment_intention_rule_view",
  DIRECT_SHIPMENT_INTENTION_RULE_MANAGE = "direct_shipment_intention_rule_manage",
  FACTORY_ASSIGNMENTS_MANAGE = "factory_assignments_manage",
  FACTORY_BILLING_INFO_READ = "factory_billing_info_read",
  FACTORY_BILLING_INFO_MANAGE = "factory_billing_info_manage",
  FACTORY_INVOICE_APPROVE = "invoice_approve",
  FACTORY_INVOICE_PAYMENTS_MANAGE = "factory_invoice_payments_manage",
  FACTORY_INVOICE_PAYMENTS_READ = "factory_invoice_payments_read",
  FACTORY_INVOICE_PRICES_MANAGE = "factory_invoice_prices_manage",
  FACTORY_INVOICE_PRICES_READ = "factory_invoice_prices_read",
  FACTORY_INVOICE_EXTRA_PAYMENTS_MANAGE = "factory_invoice_extra_payments_manage",
  FACTORY_INVOICE_EXTRA_PAYMENTS_READ = "factory_invoice_extra_payments_read",
  FACTORY_INVOICE_PENALTY_BYPASSES_MANAGE = "factory_invoice_penalty_bypasses_manage",
  FACTORY_INVOICE_PENALTIES_MANAGE = "factory_invoice_penalties_manage",
  FACTORY_INVOICE_PENALTIES_READ = "factory_invoice_penalties_read",
  FACTORY_INVOICE_SETTINGS_MANAGE = "factory_invoice_settings_manage",
  FACTORY_INVOICE_SETTINGS_READ = "factory_invoice_settings_read",
  FACTORY_RESTRICTED_MODE_ALERT_VIEW = "factory_restricted_mode_alert_view",
  FACTORY_RESTRICTED_MODE_EXCLUDED_PRODUCTS_MANAGE = "factory_restricted_mode_excluded_products_manage",
  FACTORY_RESTRICTED_MODE_CONFIG_MANAGE = "factory_restricted_mode_config_manage",
  FACTORY_RESTRICTED_MODE_CONFIG_VIEW = "factory_restricted_mode_config_view",
  FACTORY_STRICT_CAPACITY_MODE_MANAGE = "factory_strict_capacity_mode_manage",
  FACTORY_STRICT_CAPACITY_MODE_VIEW = "factory_strict_capacity_mode_view",
  FACTORY_MANAGE = "factory_manage",
  FACTORY_VIEW = "factory_view",
  FACTORY_USERS_MANAGE = "factory_users_manage",
  FACTORY_USERS_VIEW = "factory_users_view",
  INVOICE_APPROVE = "invoice_approve",
  INVOICE_PUBLISH = "invoice_publish",
  INVOICE_READ = "invoice_read",
  LABEL_HEADER_MANAGE = "label_header_manage",
  MANAGE_ORGANIZATIONS = "manage_organizations",
  MANAGE_PLUSHIE_TAGS = "manage_plushie_tags",
  MANAGE_USERS = "manage_users",
  OPERATOR_SESSION_CREATE = "operator_session_create",
  PLUSHIE_APPROVE_REVIEW = "plushie_approve_review",
  PLUSHIE_ATTACHMENT_VIEW = "plushie_attachment_view",
  PLUSHIE_DESIGN_INFORMATION_UPDATE = "plushie_design_information_update",
  PLUSHIE_DESIGNER_COMMENT_TRANSLATION_UPDATE = "plushie_designer_comment_translation_update",
  PLUSHIE_DOCUMENT_EXPORTS_VIEW = "plushie_document_exports_view",
  PLUSHIE_MOVE_TO_DESIGN = "plushie_move_to_design",
  PLUSHIE_MOVE_TO_PRODUCTION = "plushie_move_to_production",
  PLUSHIE_PREPARE_TO_SHIPMENT = "plushie_prepare_to_shipment",
  PLUSHIE_MOVE_TO_QUALITY_INSPECTION = "plushie_move_to_quality_inspection",
  PLUSHIE_PRIORITY_BONUS_MANAGE = "plushie_priority_bonus_manage",
  PLUSHIE_UPDATE = "plushie_update",
  PLUSHIE_IMAGES_VIEW = "plushie_images_view",
  PHOTO_MARKUPS_MANAGE = "manage_photo_markups",
  PHOTO_MARKUPS_READ = "read_photo_markups",
  REPORTING_REVIEWER_STATS_VIEW = "reporting_reviewer_stats_view",
  REPORTING_THROUGHPUT_STATS_VIEW = "reporting_throughput_stats_view",
  TEMPLATES_MANAGE = "templates_manage",
  TEMPLATES_VIEW = "templates_view",
  QA_ASSETS_CREATE = "qa_assets_manage",
  QUALITY_INSPECTION_REJECT = "quality_inspection_reject",
  QUALITY_INSPECTION_APPROVE = "quality_inspection_approve",
  QUALITY_INSPECTION_INIT = "quality_inspection_init",
  QUALITY_INSPECTION_DECISION_READ = "qa_decisions_read",
  QUESTION_TEMPLATE_VIEW = "question_template_view",
  QUESTION_TEMPLATE_MANAGE = "question_template_manage",
  PLUSHIE_IMAGES_MANAGE = "plushie_images_manage",
  PLUSHIE_IMAGES_PRINT_ARTWORK_MANAGE = "plushie_images_print_artwork_manage",
  PLUSHIE_START_SAMPLE_PRODUCTION = "plushie_start_sample_production",
  PRODUCT_ALLOCATION_RULES_MANAGE = "product_allocation_rules_manage",
  PRODUCT_ALLOCATION_RULES_VIEW = "product_allocation_rules_view",
  PRODUCTION_PROCESS_STATUSES_SUMMARIES_READ = "production_process_statuses_summaries_read",
  PRODUCTION_TIME_HISTORY_READ = "production_time_history_read",
  PRODUCTION_TIME_NORM_MANAGE = "production_time_norm_manage",
  PRODUCTION_TIME_NORM_VIEW = "production_time_norm_view",
  REMOTE_PRINTING_CREDENTIALS_MANAGE = "remote_printing_credentials_manage",
  READ_PLUSHIE_TAGS = "read_plushie_tags",
  REJECTION_REASONS_MANAGE = "rejection_reasons_manage",
  REJECTION_REASONS_READ = "rejection_reasons_read",
  REPORTING_FUTURE_TURNAROUND_STATS_VIEW = "reporting_future_turnaround_stats_view",
  REPORTING_PRODUCTION_ESTIMATE_VIEW = "reporting_production_estimate_view",
  REPORTING_PRODUCTION_ESTIMATE_MANAGE = "reporting_production_estimate_manage",
  REPORTING_PRODUCTION_TIME_VIEW = "reporting_production_time_view",
  REPORTING_SNAPSHOT_STATS_VIEW = "reporting_snapshot_stats_view",
  REPORTING_QUALITY_INSPECTION_STATS_VIEW = "reporting_quality_inspection_stats_view",
  QA_DECISION_REJECTION_REASONS_READ = "qa_decision_rejection_reasons_read",
  SETTINGS_MANAGE = "settings_manage",
  SETTINGS_VIEW = "settings_view",
  HOLIDAY_PERIOD_VIEW = "holiday_period_view",
  HOLIDAY_PERIOD_MANAGE = "holiday_period_manage",
  HOLIDAY_PERIOD_PLUSHIE_RELATION_MANAGE = "holiday_period_plushie_relation_manage",
  HOLIDAY_PERIOD_REPORT_VIEW = "holiday_period_report_view",
  SHIPPING_INFORMATION_VIEW = "shipping_information_view",
  SHIPPING_INFORMATION_MANAGE = "shipping_information_manage",
  TRACKING_INFORMATION_VIEW = "tracking_information_view",
  TRACKING_INFORMATION_MANAGE = "tracking_information_manage",
}
