import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import InvoiceItemElement from "./invoice-item-element.model";
import { ResourceCollection } from "../api/resource.collection";

export default class InvoiceItemElementRepository extends BasicRepository<InvoiceItemElement> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<InvoiceItemElement>,
    responseParser: ApiCollectionResponseParserService<InvoiceItemElement>
  ) {
    super("invoice_item_elements", api, objectBuilder, responseParser);
  }

  public async getByItemIds(
    itemIds: string[]
  ): Promise<ResourceCollection<InvoiceItemElement>> {
    return this.fetchList({ invoiceItem: itemIds }, 1, 9999);
  }
}
