import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import DirectShipmentIntentionRule from "./direct-shipment-intention-rule.model";

export default class DirectShipmentIntentionRuleFactory
  implements ObjectBuilderInterface<DirectShipmentIntentionRule> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): DirectShipmentIntentionRule {
    const value = new DirectShipmentIntentionRule(
      data.id,
      data.name,
      data.factory,
      data.forOrderType,
      data.product,
      data.upgrade
    );

    value.isNew = false;

    return value;
  }
}
