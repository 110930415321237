<template>
  <div class="page-404">
    <h1 class="_page-title">
      Oh No! I may have accidentally eaten your page ...
    </h1>

    <div class="_icon">
      <img src="~@/assets/img/404-dog.jpg" alt="" />
    </div>

    <div class="_actions">
      The Page you are looking for doesn't exist.
      <br />
      Go
      <a @click="$router.go(-1)">back</a>, or head over to the
      <router-link :to="{ name: 'LoginPage' }">Home</router-link>
      page to choose a new direction.
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "NotFoundPage",
  metaInfo: {
    title: "Page Not Found",
    meta: [
      {
        vmid: "og:title",
        name: "og:title",
        content: `Page Not Found | Budsies Backend System`,
      },
    ],
  },
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.page-404 {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  ._page-title {
    padding: 0 10%;
  }

  ._icon {
    font-size: 800%;
    margin-top: 3%;
    padding: 0 30%;
    text-align: center;
  }

  ._actions {
    margin-top: 1em;
  }
}
</style>
