import ApiService from "@/modules/api/api.service";

import ProductionExtended from "./production-extended.interface";

export default class ProductionExtendedRepository {
  private readonly fResourceName = "production_extended_stats";

  constructor(private api: ApiService) {}

  public async fetchProductionExtendedList({
    holidayPeriodId,
  }: {
    holidayPeriodId: number;
  }): Promise<ProductionExtended[]> {
    const response = await this.api.get(this.fResourceName, {
      holidayPeriodId,
    });

    return response.data as ProductionExtended[];
  }
}
