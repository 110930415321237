import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import AlertAssignment from "./alert-assignment.model";

export default class PlushieAlertAssignmentRepository extends BasicRepository<AlertAssignment> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<AlertAssignment>,
    responseParser: ApiCollectionResponseParserService<AlertAssignment>
  ) {
    super(
      "plushie_alerts/alert_assignments",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async getByPlushieId(plushieId: string): Promise<AlertAssignment[]> {
    const collection = await this.fetchList({ plushieId: plushieId });

    return collection.getItems();
  }

  public async getByPlushieIds(
    plushieIds: string[]
  ): Promise<AlertAssignment[]> {
    const collection = await this.fetchList({ plushieId: plushieIds }, 1, 9999);

    return collection.getItems();
  }
}
