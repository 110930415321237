




































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import FactoryInvoiceStore from "../../store";
import { FactoryInvoicesSettingValue } from "../../factory-invoices-setting.value";
import FactoryInvoicesSetting from "../../factory-invoices-setting.model";

@Component({
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
})
export default class BuyerInfoEditForm extends mixins(GeneralFormMixin) {
  public name?: string;
  public address?: string;
  public additionalAddress?: string;
  public city?: string;
  public stateRegion?: string;
  public postalCode?: string;
  public country?: string;

  private fIsLoading = true;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, unknown> {
    return {
      name: this.name,
      address: this.address,
      additionalAddress: this.additionalAddress,
      city: this.city,
      stateRegion: this.stateRegion,
      postalCode: this.postalCode,
      country: this.country,
    };
  }

  protected async performSubmit(): Promise<void> {
    const updatePromises: Promise<FactoryInvoicesSetting>[] = [];

    if (this.name !== undefined) {
      updatePromises.push(
        this.fFactoryInvoiceDataStore.updateFactoryInvoicesSettingValue({
          id: FactoryInvoicesSettingValue.STORE_BILLING_INFO_NAME,
          value: this.name,
        })
      );
    }

    if (this.address !== undefined) {
      updatePromises.push(
        this.fFactoryInvoiceDataStore.updateFactoryInvoicesSettingValue({
          id: FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDRESS,
          value: this.address,
        })
      );
    }

    if (this.additionalAddress !== undefined) {
      updatePromises.push(
        this.fFactoryInvoiceDataStore.updateFactoryInvoicesSettingValue({
          id: FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDITIONAL_ADDRESS,
          value: this.additionalAddress,
        })
      );
    }

    if (this.city !== undefined) {
      updatePromises.push(
        this.fFactoryInvoiceDataStore.updateFactoryInvoicesSettingValue({
          id: FactoryInvoicesSettingValue.STORE_BILLING_INFO_CITY,
          value: this.city,
        })
      );
    }

    if (this.stateRegion !== undefined) {
      updatePromises.push(
        this.fFactoryInvoiceDataStore.updateFactoryInvoicesSettingValue({
          id: FactoryInvoicesSettingValue.STORE_BILLING_INFO_STATE_REGION,
          value: this.stateRegion,
        })
      );
    }

    if (this.postalCode !== undefined) {
      updatePromises.push(
        this.fFactoryInvoiceDataStore.updateFactoryInvoicesSettingValue({
          id: FactoryInvoicesSettingValue.STORE_BILLING_INFO_POSTAL_CODE,
          value: this.postalCode,
        })
      );
    }

    if (this.country !== undefined) {
      updatePromises.push(
        this.fFactoryInvoiceDataStore.updateFactoryInvoicesSettingValue({
          id: FactoryInvoicesSettingValue.STORE_BILLING_INFO_COUNTRY,
          value: this.country,
        })
      );
    }

    await Promise.all(updatePromises);

    this.$emit("updated");
  }

  protected async created(): Promise<void> {
    this.fIsLoading = true;

    this.name == undefined;
    this.address == undefined;
    this.additionalAddress == undefined;
    this.city == undefined;
    this.stateRegion == undefined;
    this.postalCode == undefined;
    this.country == undefined;

    try {
      await this.fFactoryInvoiceDataStore.loadFactoryInvoicesSettingsByIds([
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_NAME,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDRESS,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDITIONAL_ADDRESS,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_CITY,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_STATE_REGION,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_POSTAL_CODE,
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_COUNTRY,
      ]);

      const name = this.fFactoryInvoiceDataStore.getFactoryInvoicesSettingById(
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_NAME
      );

      if (name && name.value !== null) {
        this.name = name.value;
      }

      const address = this.fFactoryInvoiceDataStore.getFactoryInvoicesSettingById(
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDRESS
      );

      if (address && address.value !== null) {
        this.address = address.value;
      }

      const additionalAddress = this.fFactoryInvoiceDataStore.getFactoryInvoicesSettingById(
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_ADDITIONAL_ADDRESS
      );

      if (additionalAddress && additionalAddress.value !== null) {
        this.additionalAddress = additionalAddress.value;
      }

      const city = this.fFactoryInvoiceDataStore.getFactoryInvoicesSettingById(
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_CITY
      );

      if (city && city.value !== null) {
        this.city = city.value;
      }

      const stateRegion = this.fFactoryInvoiceDataStore.getFactoryInvoicesSettingById(
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_STATE_REGION
      );

      if (stateRegion && stateRegion.value !== null) {
        this.stateRegion = stateRegion.value;
      }

      const postalCode = this.fFactoryInvoiceDataStore.getFactoryInvoicesSettingById(
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_POSTAL_CODE
      );

      if (postalCode && postalCode.value !== null) {
        this.postalCode = postalCode.value;
      }

      const country = this.fFactoryInvoiceDataStore.getFactoryInvoicesSettingById(
        FactoryInvoicesSettingValue.STORE_BILLING_INFO_COUNTRY
      );

      if (country && country.value !== null) {
        this.country = country.value;
      }
    } finally {
      this.fIsLoading = false;
    }
  }
}
