import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import FutureTurnaround from "./future-turnaround.model";

export default class FutureTurnaroundFactory
  implements ObjectBuilderInterface<FutureTurnaround> {
  public buildFromJSON(data: { [key: string]: any }): FutureTurnaround {
    const value = new FutureTurnaround(
      data.store_name,
      data.product_name,
      data.factory_name,
      data.distribution_percent,
      data.weekly_production,
      data.in_review,
      data.in_design,
      data.ready_for_production,
      data.customer_preview,
      data.customer_preview_approved,
      data.in_production,
      data.in_inspection,
      data.in_rework,
      data.total,
      data.turnaround
    );

    return value;
  }
}
