import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import DirectShipmentIntention from "./direct-shipment-intention.model";

export default class DirectShipmentIntentionFactory
  implements ObjectBuilderInterface<DirectShipmentIntention> {
  public buildFromJSON(data: { [key: string]: any }): DirectShipmentIntention {
    const value = new DirectShipmentIntention(data.id);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
