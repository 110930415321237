import { MutableValue } from "@/modules/api/mutable-value.interface";

import { InvoiceElementTypeValue } from "./invoice-item-element-type.value";

export default class InvoiceItemElement implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fInvoiceItem: string,
    private fType: InvoiceElementTypeValue,
    private fEntity: string,
    private fPrice: number,
    private fNoPriceDefined: boolean,
    private fQty: number,
    private fName: string
  ) {
    [
      fId,
      fInvoiceItem,
      fType,
      fEntity,
      fPrice,
      fNoPriceDefined,
      fQty,
      fName,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get invoiceItem(): string {
    return this.fInvoiceItem;
  }

  get type(): InvoiceElementTypeValue {
    return this.fType;
  }

  get entity(): string {
    return this.fEntity;
  }

  get price(): number {
    return this.fPrice;
  }

  get noPriceDefined(): boolean {
    return this.fNoPriceDefined;
  }

  get qty(): number {
    return this.fQty;
  }

  get name(): string {
    return this.fName;
  }
}
