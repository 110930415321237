<template>
  <header>
    <a class="logo" href="/">
      <img alt="Budsies logo" src="@/assets/logo.png" />
    </a>
  </header>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "PageHeader",
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
header {
  background: color("panel");
  border-bottom: 1px solid color("containerBorder");
  padding: 1em;

  .logo {
    display: block;

    img {
      max-width: 200px;
    }
  }
}
</style>
