


























import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import autoindent from "@/lib/directives/auto-indent.directive";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import dataStore from "@/store";

import FactoryInvoiceStore from "../../store";
import FactoryInvoice from "../../factory-invoice.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class FactoryInvoicePublishAction extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  public vendorNotes?: string;

  private fFactoryInvoiceStore: FactoryInvoiceStore;

  get invoice(): FactoryInvoice | undefined {
    return this.fFactoryInvoiceStore.getFactoryInvoiceById(this.invoiceId);
  }

  constructor() {
    super();
    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }

  protected data(): Record<string, unknown> {
    return {
      vendorNotes: this.vendorNotes,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.invoice) {
      return;
    }

    await this.fFactoryInvoiceStore.publishFactoryInvoice({
      invoice: this.invoice,
      vendorNotes: this.vendorNotes,
    });
  }
}
