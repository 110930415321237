



















import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";
import EditableText from "@/modules/plushie/components/EditableText.vue";

import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import dataStore from "@/store";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";

import BulkProjectStore from "../../store";
import BulkProjectBatchInformation from "../../bulk-project-batch-information.model";

@Component({
  components: {
    EditableText,
    InlineSpinner,
  },
})
export default class BatchInformationNotes extends Vue {
  @Prop({ required: true })
  public readonly batchInformation!: BulkProjectBatchInformation;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fBulkProjectStore: BulkProjectStore;

  constructor() {
    super();

    this.fBulkProjectStore = getModule(BulkProjectStore, dataStore);
  }

  get showNotes(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return (
      [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE].includes(
        user.role
      ) &&
      user.hasPermissionForResource(
        Resource.BULK_PROJECT_BATCH_INFORMATION_VIEW
      )
    );
  }

  get canEditNotes(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.BULK_PROJECT_BATCH_INFORMATION_MANAGE
    );
  }

  public async updateNotes(notes: string): Promise<void> {
    this.batchInformation.notes = notes;

    await this.fBulkProjectStore.saveBulkProjectBatchInformation(
      this.batchInformation
    );
  }
}
