import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import PenaltyBypass from "./penalty-bypass.model";

export default class PenaltyBypassRepository extends BasicRepository<PenaltyBypass> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PenaltyBypass>,
    responseParser: ApiCollectionResponseParserService<PenaltyBypass>
  ) {
    super("penalty_bypasses", api, objectBuilder, responseParser);
  }

  public async getByPlushieId(
    plushieId: string
  ): Promise<PenaltyBypass | undefined> {
    const collection = await this.fetchList({ plushieId: plushieId });

    return collection.getItems()[0];
  }

  protected prepareData(item: PenaltyBypass): Dictionary<string> {
    const data: Dictionary<string> = {
      id: item.id,
      plushieId: item.plushie,
      type: item.type,
    };

    return data;
  }
}
