import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { Value } from "@/modules/api/value.interface";
import ApiCollectionResponseParserInterface from "@/modules/api/api-collection-response-parser.interface";

export default class ApiCollectionResponseParserService<T extends Value>
  implements ApiCollectionResponseParserInterface<T> {
  public parse(
    data: any,
    objectBuilder: ObjectBuilderInterface<T>
  ): ResourceCollection<T> {
    const values: T[] = [];

    data["hydra:member"].forEach((item: any) => {
      const value = objectBuilder.buildFromJSON(item);

      values.push(value);
    });

    let currentPage = 1;
    let pagesCount = 1;

    const totalItemsCount = parseInt(data["hydra:totalItems"], 10);
    let perPage = totalItemsCount;

    if ("hydra:view" in data && "hydra:last" in data["hydra:view"]) {
      let uri = data["hydra:view"]["@id"];
      let params = this.getParamsFromUrl(uri);
      currentPage = parseInt(params.page, 10);
      perPage = parseInt(params.itemsPerPage, 10);

      uri = data["hydra:view"]["hydra:last"];
      params = this.getParamsFromUrl(uri);
      pagesCount = parseInt(params.page, 10);
    }

    return new ResourceCollection<T>(
      values,
      totalItemsCount,
      currentPage,
      pagesCount,
      perPage
    );
  }

  private getParamsFromUrl(url: string): { [key: string]: string } {
    const params: { [key: string]: string } = {};

    url
      .split("?")[1]
      .split("&")
      .forEach((item: string) => {
        const [name, value] = item.split("=");
        params[name] = value;
      });

    return params;
  }
}
