































































import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import Product from "@/modules/plushie/product.model";
import {
  DirectShipmentCompensationEntity,
  directShipmentCompensationEntity,
} from "@/modules/factory-invoice/direct-shipment-compensation.entity";

import ElementPrice from "../../../element-price.model";
import { ElementTypeValue } from "../../../element-type.value";
import Upgrade from "../../../../plushie/upgrade.model";
import { DirectoryValue } from "../../../../api/directory-value.model";

@Component({})
export default class PriceConfigurationEditItem extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop({ required: true })
  public readonly id!: string;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Prop({ default: [] })
  public readonly elementTypes!: DirectoryValue[];

  @Prop({ default: true })
  public readonly isNew!: boolean;

  @Prop({ required: false })
  public readonly value?: ElementPrice;

  public entity?: string;
  public qty?: number;
  public price?: number;
  private fPlushieDataStore: PlushieStore;

  get entityOptions(): (
    | Product
    | Upgrade
    | DirectShipmentCompensationEntity
  )[] {
    const allProducts = this.fPlushieDataStore.activeProductsList;
    const allUpgrades = this.fPlushieDataStore.upgradesList;

    return [...allProducts, ...allUpgrades, directShipmentCompensationEntity];
  }

  constructor() {
    super();

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public deleteItem(): void {
    if (this.value && !confirm("Are you sure?")) {
      return;
    }

    this.$emit("delete");
  }

  public getEntityName(entityId?: string): string {
    if (!entityId) {
      return "";
    }

    if (entityId === directShipmentCompensationEntity.id) {
      return directShipmentCompensationEntity.name;
    }

    const entity =
      this.fPlushieDataStore.getProductById(entityId) ||
      this.fPlushieDataStore.getUpgradeById(entityId);

    return entity ? entity.name : "";
  }

  public onChange(): void {
    if (!this.entity || !this.qty || this.price === undefined) {
      this.$emit("input", undefined);

      return;
    }

    let elementTypeId;
    if (this.entity === directShipmentCompensationEntity.id) {
      elementTypeId = ElementTypeValue.DIRECT_SHIPMENT_COMPENSATION;
    } else if (
      this.fPlushieDataStore.getProductById(this.entity) !== undefined
    ) {
      elementTypeId = ElementTypeValue.PRODUCT;
    } else if (
      this.fPlushieDataStore.getUpgradeById(this.entity) !== undefined
    ) {
      elementTypeId = ElementTypeValue.UPGRADE;
    } else {
      throw new Error(
        `The entity with ID '${this.entity}' of the ElementPrice should be a product or an upgrade or direct shipment compensation`
      );
    }

    const value = new ElementPrice(
      this.id,
      this.factoryId,
      elementTypeId,
      this.entity,
      this.qty,
      this.price
    );

    this.$emit("input", value);
  }

  protected data(): Record<string, unknown> {
    return {
      entity: this.entity,
      qty: this.qty,
      price: this.price,
    };
  }

  @Watch("value", { immediate: true })
  private _onValueChange() {
    if (!this.value) {
      return;
    }

    this.entity = this.value.entity;
    this.qty = this.value.qty;
    this.price = this.value.price;
  }
}
