













import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import ProductionProcessStore from "@/modules/production-process/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

@Component({
  components: {
    FormErrors,
    SubmitButton,
  },
})
export default class CancelPlushieCommand extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fProductionProcessDataStore: ProductionProcessStore;

  constructor() {
    super();
    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  protected async performSubmit(): Promise<void> {
    const isConfirmed = window.confirm(
      "Plushie will be cancelled. You will NOT BE ABLE to revert this action. Do you want to proceed?"
    );

    if (!isConfirmed) return;

    await this.fProductionProcessDataStore.cancelPlushie(this.plushieId);
  }
}
