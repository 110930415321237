import Vue from "vue";
import { Module, VuexModule, Mutation } from "vuex-module-decorators";

import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";

export const name = "UiConfigurationStore";

const displayImagesFieldName = "displayImages";

const getDefaultState = () => {
  return {
    bulkProjectList: {},
    plushieList: {},
    plushieViewPage: {},
  };
};

@Module({ name })
export default class UiConfigurationStore extends VuexModule {
  bulkProjectList: Dictionary<any> = {};
  mainMenuCompact = false;
  plushieList: Dictionary<any> = {};
  plushieViewPage: Dictionary<Dictionary<number | boolean | undefined>> = {};

  // ################################### BULK PROJECT LIST #########################################

  get bulkProjectListDisplayImages(): boolean {
    return !!this.bulkProjectList[displayImagesFieldName];
  }

  @Mutation
  toggleBulkProjectListDisplayImages(): void {
    Vue.set(
      this.bulkProjectList,
      displayImagesFieldName,
      !this.bulkProjectList[displayImagesFieldName]
    );
  }

  // ################################### MAIN MENU STATE #########################################

  get isMainMenuCompact(): boolean {
    return this.mainMenuCompact;
  }

  @Mutation
  setMainMenuCompact(value: boolean): void {
    this.mainMenuCompact = value;
  }

  // ################################### PLUSHIE LIST #########################################

  get plushieListDisplayImages(): boolean {
    return this.plushieList[displayImagesFieldName] == true;
  }

  @Mutation
  togglePlushieListDisplayImages(): void {
    Vue.set(
      this.plushieList,
      displayImagesFieldName,
      !this.plushieList[displayImagesFieldName]
    );
  }

  // ################################### PLUSHIE VIEW PAGE #########################################
  get getPlushieViewDisplayMarkup(): (id: string) => boolean | undefined {
    return (id: string) => {
      if (!this.plushieViewPage[id]) {
        return undefined;
      }

      return this.plushieViewPage[id]["displayMarkup"] as boolean;
    };
  }

  get getPlushieViewQaDisplayMarkup(): (id: string) => boolean | undefined {
    return (id: string) => {
      if (!this.plushieViewPage[id]) {
        return undefined;
      }

      return this.plushieViewPage[id]["qaDisplayMarkup"] as boolean;
    };
  }

  get getPlushieViewArtworkIndex(): (id: string) => number | undefined {
    return (id: string) => {
      if (!this.plushieViewPage[id]) {
        return undefined;
      }

      return this.plushieViewPage[id]["artworkIndex"] as number;
    };
  }

  get getPlushieViewQAAssetIndex(): (id: string) => number | undefined {
    return (id: string) => {
      if (!this.plushieViewPage[id]) {
        return undefined;
      }

      return this.plushieViewPage[id]["qaAssetIndex"] as number;
    };
  }

  @Mutation
  updatePlushieViewDisplayMarkup({
    plushieId,
    displayMarkup,
  }: {
    plushieId: string;
    displayMarkup: boolean;
  }): void {
    if (!this.plushieViewPage[plushieId]) {
      Vue.set(this.plushieViewPage, plushieId, {});
    }

    Vue.set(this.plushieViewPage[plushieId], "displayMarkup", displayMarkup);
  }

  @Mutation
  updatePlushieViewQaDisplayMarkup({
    plushieId,
    qaDisplayMarkup,
  }: {
    plushieId: string;
    qaDisplayMarkup: boolean;
  }): void {
    if (!this.plushieViewPage[plushieId]) {
      Vue.set(this.plushieViewPage, plushieId, {});
    }

    Vue.set(
      this.plushieViewPage[plushieId],
      "qaDisplayMarkup",
      qaDisplayMarkup
    );
  }

  @Mutation
  updatePlushieViewArtworkIndex({
    plushieId,
    currentIndex,
  }: {
    plushieId: string;
    currentIndex: number;
  }): void {
    if (!this.plushieViewPage[plushieId]) {
      Vue.set(this.plushieViewPage, plushieId, {});
    }

    Vue.set(this.plushieViewPage[plushieId], "artworkIndex", currentIndex);
  }

  @Mutation
  updatePlushieViewQAAssetIndex({
    plushieId,
    currentIndex,
  }: {
    plushieId: string;
    currentIndex: number;
  }): void {
    if (!this.plushieViewPage[plushieId]) {
      Vue.set(this.plushieViewPage, plushieId, {});
    }

    Vue.set(this.plushieViewPage[plushieId], "qaAssetIndex", currentIndex);
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
