





















































































































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryInvoiceViewInfo from "./FactoryInvoiceView/Info.vue";
import FactoryInvoiceViewCounterpartiesInfo from "./FactoryInvoiceView/CounterpartiesInfo.vue";
import FactoryInvoiceViewSummary from "./FactoryInvoiceView/Summary.vue";
import FactoryInvoiceViewPaymentSummary from "./FactoryInvoiceView/PaymentSummary.vue";
import FactoryInvoiceViewAddExtraPaymentForm from "./FactoryInvoiceView/AddExtraPaymentForm.vue";
import FactoryInvoiceViewExtraPaymentList from "./FactoryInvoiceView/ExtraPaymentList.vue";
import FactoryInvoiceViewDetails from "./FactoryInvoiceView/Details.vue";
import FactoryInvoicePublishAction from "./FactoryInvoiceView/PublishAction.vue";
import FactoryInvoiceApproveAction from "./FactoryInvoiceView/ApproveAction.vue";
import FactoryInvoiceDownloadPdfAction from "./FactoryInvoiceView/DownloadPdfAction.vue";
import ProductionTimeHistoryTable from "./ProductionTimeHistoryTable.vue";

import { FactoryInvoiceStatusValue } from "../factory-invoice-status.value";
import FactoryInvoice from "../factory-invoice.model";
import FactoryInvoiceRepository from "../factory-invoice.repository";
import FactoryInvoiceStore from "../store/index";

@Component({
  metaInfo() {
    const component = this as FactoryInvoiceViewPage;

    return {
      title: component.invoice
        ? "Factory Invoice #" + component.invoice.number
        : "Factory Invoice",
    };
  },
  components: {
    FactoryInvoiceViewInfo,
    FactoryInvoiceViewCounterpartiesInfo,
    FactoryInvoiceViewSummary,
    FactoryInvoiceViewPaymentSummary,
    FactoryInvoiceViewAddExtraPaymentForm,
    FactoryInvoiceViewExtraPaymentList,
    FactoryInvoiceViewDetails,
    FactoryInvoicePublishAction,
    FactoryInvoiceApproveAction,
    FactoryInvoiceDownloadPdfAction,
    LoadingSpinner,
    ListItemPageNavigation,
    ProductionTimeHistoryTable,
  },
})
export default class FactoryInvoiceViewPage extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("FactoryInvoiceRepository")
  private fFactoryInvoiceRepository!: FactoryInvoiceRepository;

  private fIsLoading = false;
  private fIsProductionTimeHistoryExpanded = false;

  private fFactoryInvoiceStore: FactoryInvoiceStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isFactoryMode(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return true;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get invoice(): FactoryInvoice | undefined {
    return this.fFactoryInvoiceStore.getFactoryInvoiceById(this.invoiceId);
  }

  get isProductionTimeHistoryExpanded(): boolean {
    return this.fIsProductionTimeHistoryExpanded;
  }

  get canViewCounterpartiesInfo(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_BILLING_INFO_READ);
  }

  get plushieIds(): string[] {
    const invoiceItems = this.fFactoryInvoiceStore.getInvoiceItemsByInvoiceId(
      this.invoiceId
    );

    return invoiceItems.map((item) => item.plushie);
  }

  get showPaymentSummary(): boolean {
    const invoice = this.fFactoryInvoiceStore.getFactoryInvoiceById(
      this.invoiceId
    );

    if (!invoice) {
      return false;
    }

    if (invoice.status !== FactoryInvoiceStatusValue.PAID) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.FACTORY_INVOICE_PAYMENTS_READ
    );
  }

  get canAddExtraPayments(): boolean {
    if (!this.invoice) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      !user.hasPermissionForResource(
        Resource.FACTORY_INVOICE_EXTRA_PAYMENTS_MANAGE
      )
    ) {
      return false;
    }

    if (user.role === AuthenticatedUser.ROLE_FACTORY) {
      return this.invoice.status === FactoryInvoiceStatusValue.DRAFT;
    } else if (
      [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE].includes(
        user.role
      )
    ) {
      return [
        FactoryInvoiceStatusValue.DRAFT,
        FactoryInvoiceStatusValue.REVIEW,
      ].includes(this.invoice.status);
    }

    return false;
  }

  get isPublishingAvailable(): boolean {
    const invoice = this.fFactoryInvoiceStore.getFactoryInvoiceById(
      this.invoiceId
    );

    if (!invoice || invoice.hasUnpricedElements) {
      return false;
    }

    if (invoice.status !== FactoryInvoiceStatusValue.DRAFT) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.INVOICE_PUBLISH);
  }

  get isApprovingAvailable(): boolean {
    const invoice = this.fFactoryInvoiceStore.getFactoryInvoiceById(
      this.invoiceId
    );

    if (!invoice) {
      return false;
    }

    if (invoice.status !== FactoryInvoiceStatusValue.REVIEW) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.INVOICE_APPROVE);
  }

  get isDownloadingPdfAvailable(): boolean {
    const invoice = this.fFactoryInvoiceStore.getFactoryInvoiceById(
      this.invoiceId
    );

    if (!invoice) {
      return false;
    }

    return [
      FactoryInvoiceStatusValue.UNPAID,
      FactoryInvoiceStatusValue.PAID,
    ].includes(invoice.status);
  }

  get showProductionTimeHistory(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PRODUCTION_TIME_HISTORY_READ);
  }

  get showUnpricedElementsHint(): boolean {
    return !!this.invoice && this.invoice.hasUnpricedElements;
  }

  constructor() {
    super();

    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public getInvoicesOrder(): Promise<string[]> {
    return this.fFactoryInvoiceRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  public toggleProductionTimeHistory(): void {
    this.fIsProductionTimeHistoryExpanded = !this
      .fIsProductionTimeHistoryExpanded;
  }

  private async getInvoiceData(invoiceId: string) {
    const [factoryInvoice, factoryInvoiceItems] = await Promise.all([
      this.fFactoryInvoiceStore.loaFactoryInvoiceById({
        id: invoiceId,
        useCache: false,
      }),
      this.fFactoryInvoiceStore.loadInvoiceItemsByInvoiceId({ invoiceId }),
      this.fFactoryInvoiceStore.loadInvoiceStatuses(),
      this.fFactoryInvoiceStore.loadExtraPaymentFaults(),
    ]);

    if (!factoryInvoice) {
      return;
    }

    const itemIds = factoryInvoiceItems.map((item) => item.id);

    await this.fFactoryInvoiceStore.loadElementsByInvoiceItemIds({ itemIds });
  }

  @Watch("invoiceId", { immediate: true })
  private async _onInvoiceIdChange() {
    if (!this.invoiceId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getInvoiceData(this.invoiceId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
