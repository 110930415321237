import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import OrderInformation from "./order-information.model";
import { ResourceCollection } from "../api/resource.collection";

export default class OrderInformationRepository extends BasicRepository<OrderInformation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<OrderInformation>,
    responseParser: ApiCollectionResponseParserService<OrderInformation>
  ) {
    super("order_informations", api, objectBuilder, responseParser);
  }

  public async getByOrderId(
    orderId: string
  ): Promise<ResourceCollection<OrderInformation>> {
    return this.fetchList({ orderId: orderId }, 1, 9999);
  }

  public async getByOrderIds(
    orderIds: string[]
  ): Promise<ResourceCollection<OrderInformation>> {
    return this.fetchList({ orderId: orderIds }, 1, 9999);
  }

  protected prepareData(item: OrderInformation): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    return data;
  }
}
