import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import BulkProjectPlushieType from "./bulk-project-plushie-type.model";

export default class BulkProjectPlushieTypeFactory
  implements ObjectBuilderInterface<BulkProjectPlushieType> {
  public buildFromJSON(data: { [key: string]: any }): BulkProjectPlushieType {
    const value = new BulkProjectPlushieType(data.id, data.name);

    value.isNew = false;

    return value;
  }
}
