











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import dataStore from "@/store";

import CustomerPreviewStore from "../store";
import PreviewCount from "../preview-count.model";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class PreviewCountStats extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  private fIsLoading = true;

  private fCustomerPreviewDataStore: CustomerPreviewStore;

  constructor() {
    super();

    this.fCustomerPreviewDataStore = getModule(CustomerPreviewStore, dataStore);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get previewCount(): PreviewCount | undefined {
    return this.fCustomerPreviewDataStore.getPreviewCountByPlushieId(
      this.plushieId
    );
  }

  get displayStats(): boolean {
    if (!this.previewCount) {
      return false;
    }

    if (!this.previewCount.maxValue) {
      return false;
    }

    return true;
  }

  private async getPlushieData(plushieId: string) {
    await Promise.all([
      this.fCustomerPreviewDataStore.loadPreviewCountByPlushieId({
        plushieId: plushieId,
      }),
    ]);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);

    this.fIsLoading = false;
  }
}
