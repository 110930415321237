import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Country from "./country.model";

export default class CountryFactory implements ObjectBuilderInterface<Country> {
  public buildFromJSON(data: { [key: string]: any }): Country {
    const value = new Country(data.id, data.name, data.code);

    value.isNew = false;

    return value;
  }
}
