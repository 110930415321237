import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import { ResourceCollection } from "@/modules/api/resource.collection";
import { PlushieStatusValue } from "@/modules/plushie/plushie-status.value";

import PlushieStatusPeriod from "./plushie-status-period.model";

export default class PlushieStatusPeriodRepository extends BasicRepository<PlushieStatusPeriod> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PlushieStatusPeriod>,
    responseParser: ApiCollectionResponseParserService<PlushieStatusPeriod>
  ) {
    super("plushie_status_periods", api, objectBuilder, responseParser);
  }

  public async getReviewApproveRecordsByPlushies(
    plushieIds: string[]
  ): Promise<ResourceCollection<PlushieStatusPeriod>> {
    const response = await this.api.get(this.resourceName, {
      page: 1,
      itemsPerPage: plushieIds.length,
      status: PlushieStatusValue.READY_FOR_PRODUCTION,
      previousStatus: [PlushieStatusValue.ON_HOLD, PlushieStatusValue.REVIEW],
      plushie: plushieIds,
    });

    return this.responseParser.parse(response.data, this.objectBuilder);
  }
}
