import Vue from "vue";
import Gallery, {
  Options,
  SlideData,
} from "blueimp-gallery/js/blueimp-gallery.min.js";
import { Component, Inject, Prop } from "vue-property-decorator";

@Component
export default class ImagesGallery extends Vue {
  @Prop({ default: true })
  public readonly shouldCloseOnSwipe!: boolean;

  @Inject("window")
  private fWindow!: Window;

  private fGallery?: Gallery;

  get displayControls(): boolean {
    return !this.fWindow.matchMedia("(pointer: coarse)").matches;
  }

  get videoCoverClass(): string {
    const defaultClass = "video-cover";

    if (this.displayControls) {
      return defaultClass;
    }

    return `${defaultClass} toggle`;
  }

  public close(): void {
    if (!this.fGallery) {
      return;
    }

    this.fGallery.close();
  }

  public initGallery(
    slides: SlideData[],
    index: number,
    options: Options = {}
  ): void {
    const galleryOptions = {
      ...{
        container: this.$el,
        titleElement: ".title",
        toggleSlideshowOnSpace: false,
        toggleControlsOnEnter: false,
        closeOnSwipeUpOrDown: this.shouldCloseOnSwipe,
        index: index,
        videoCoverClass: this.videoCoverClass,
        onslide: (index: number) => {
          this.$emit("currentIndexChange", index);
        },
        onclose: () => {
          this.$emit("galleryClose");
        },
        onclosed: () => {
          this.$emit("galleryClosed");
        },
      },
      ...options,
    };

    const slidesCopy = slides.map((slide) => ({ ...slide }));

    const slidesQty = slidesCopy.length;

    slidesCopy.forEach((slide, index) => {
      slide.title = `${++index}/${slidesQty}`;
    });

    this.fGallery = new Gallery(slidesCopy, galleryOptions);
  }

  public refreshLayout(): void {
    if (!this.fGallery) {
      return;
    }

    //initSlides is not declared in the d.ts file but is available
    (this.fGallery as any).initSlides(true);
  }
}
