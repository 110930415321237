import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import LabelHeader from "./label-header.model";

export default class LabelHeaderRepository extends BasicRepository<LabelHeader> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<LabelHeader>,
    responseParser: ApiCollectionResponseParserService<LabelHeader>
  ) {
    super("barcode_label_headers", api, objectBuilder, responseParser);
  }

  protected prepareData(item: LabelHeader): Record<string, string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      name: item.name,
      content: item.content,
    };

    if (item.storeId !== undefined) {
      data.storeId = parseInt(item.storeId, 10);
    }

    return data;
  }
}
