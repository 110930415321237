import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class UpgradeAllocation implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fFactory: string,
    private fUpgrade: string,
    private fCanProduce: boolean
  ) {
    [fId, fFactory, fUpgrade, fCanProduce].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get factory(): string {
    return this.fFactory;
  }

  get upgrade(): string {
    return this.fUpgrade;
  }

  get canProduce(): boolean {
    return this.fCanProduce;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }
}
