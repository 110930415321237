export default class QuestionTemplateShortContentResolverService {
  private static readonly CONTENT_MAX_LENGTH = 120;

  public resolve(content: string): string {
    const cutText =
      content.length >
      QuestionTemplateShortContentResolverService.CONTENT_MAX_LENGTH
        ? content.substr(
            0,
            QuestionTemplateShortContentResolverService.CONTENT_MAX_LENGTH
          ) + "..."
        : content;

    return cutText.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, "");
  }
}
