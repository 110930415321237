import { Route } from "vue-router";

import FactoryRestrictedModeConfigurationEditPage from "./components/ConfigurationEditPage.vue";
import FactoryRestrictedModeManagementPage from "./components/ManagementPage.vue";
import BlockingPlushiesListPage from "./components/BlockingPlushiesListPage.vue";

export default [
  {
    path: "/factory_restricted_mode/configuration",
    name: "FactoryRestrictedModeConfigurationEditPage",
    component: FactoryRestrictedModeConfigurationEditPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory_restricted_mode/manage",
    name: "FactoryRestrictedModeManagementPage",
    component: FactoryRestrictedModeManagementPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/factory_restricted_mode/blocking_plushies",
    name: "BlockingPlushiesListPage",
    component: BlockingPlushiesListPage,
    props: (route: Route) => {
      return {
        factoryId: route.query.factoryId,
      };
    },
    meta: {
      auth: true,
    },
  },
];
