























































































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import BillingInfoRecord from "../../billing-info-record.model";
import FactoryInvoiceStore from "../../store";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class BillingInfoEditForm extends mixins(GeneralFormMixin) {
  @Prop()
  public readonly factoryId?: string;

  @Prop({ default: "Save" })
  public readonly buttonText!: string;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public factoryName?: string;
  public city?: string;
  public stateRegion?: string;
  public postalCode?: string;
  public country?: string;
  public address?: string;
  public additionalAddress?: string;

  private fFactoryInvoiceDataStore: FactoryInvoiceStore;

  get billingInfoRecord(): BillingInfoRecord | undefined {
    if (!this.factoryId) {
      return undefined;
    }

    return this.fFactoryInvoiceDataStore.getBillingInfoRecordByFactoryId(
      this.factoryId
    );
  }

  constructor() {
    super();
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public onCancelClick(): void {
    this.$emit("cancel");
  }

  protected data(): Record<string, string | undefined> {
    return {
      factoryName: this.factoryName,
      city: this.city,
      stateRegion: this.stateRegion,
      postalCode: this.postalCode,
      country: this.country,
      address: this.address,
      additionalAddress: this.additionalAddress,
    };
  }

  protected async performSubmit(): Promise<void> {
    let billingInfoRecord = this.generateBillingInfoRecordObject();

    billingInfoRecord = await this.fFactoryInvoiceDataStore.saveBillingInfoRecord(
      billingInfoRecord
    );
    this.$emit("updated", billingInfoRecord);
  }

  private generateBillingInfoRecordObject(): BillingInfoRecord {
    if (
      !this.factoryId ||
      !this.factoryName ||
      !this.city ||
      !this.stateRegion ||
      !this.postalCode ||
      !this.country
    ) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const id = this.billingInfoRecord
      ? this.billingInfoRecord.id
      : this.fIdGenerator.getId();

    const value = new BillingInfoRecord(
      id,
      this.factoryId,
      this.factoryName,
      this.city,
      this.stateRegion,
      this.postalCode,
      this.country
    );

    (value as any).fAddress = this.address;
    (value as any).fAdditionalAddress = this.additionalAddress;

    if (this.billingInfoRecord) {
      value.isNew = false;
    }

    return value;
  }

  @Watch("factoryId", { immediate: true })
  private _onFactoryIdChange(): void {
    this.factoryName = undefined;
    this.city = undefined;
    this.stateRegion = undefined;
    this.postalCode = undefined;
    this.country = undefined;
    this.address = undefined;
    this.additionalAddress = undefined;

    if (this.billingInfoRecord === undefined) {
      return;
    }

    this.factoryName = this.billingInfoRecord.factoryName;
    this.city = this.billingInfoRecord.city;
    this.stateRegion = this.billingInfoRecord.stateRegion;
    this.postalCode = this.billingInfoRecord.postalCode;
    this.country = this.billingInfoRecord.country;
    this.address = this.billingInfoRecord.address;
    this.additionalAddress = this.billingInfoRecord.additionalAddress;
  }
}
