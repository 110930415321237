





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import dataStore from "@/store";
import Plushie from "@/modules/plushie/plushie.model";

import BatchInformationNotes from "./BatchInformation/Notes.vue";
import BatchInformationNegotiatedPrice from "./BatchInformation/NegotiatedPrice.vue";

import BulkProjectStore from "../store";
import BulkProjectBatchInformation from "../bulk-project-batch-information.model";

@Component({
  components: {
    BatchInformationNegotiatedPrice,
    BatchInformationNotes,
    InlineSpinner,
  },
})
export default class BatchInformation extends Vue {
  @Prop({ required: true })
  public readonly plushie!: Plushie;

  private fIsLoading = true;

  private fBulkProjectStore: BulkProjectStore;

  constructor() {
    super();

    this.fBulkProjectStore = getModule(BulkProjectStore, dataStore);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get batchInformation(): BulkProjectBatchInformation | undefined {
    return this.fBulkProjectStore.getBulkProjectBatchInformationByPlushieId(
      this.plushie.id
    );
  }

  private async loadData(plushie: Plushie) {
    await this.fBulkProjectStore.loadBulkProjectBatchInformationByPlushieId({
      plushieId: plushie.id,
      useCache: false,
    });
  }

  @Watch("plushie.id", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushie) {
      return;
    }

    this.fIsLoading = true;

    await this.loadData(this.plushie);

    this.fIsLoading = false;
  }
}
