





























import { Component, Inject, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";

import dataStore from "@/store";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryEditForm from "./FactoryEditForm.vue";

import FactoryStore from "../store";
import FactoryRepository from "../factory.repository";
import Factory from "../factory.model";

@Component({
  metaInfo() {
    const component = this as FactoryEditPage;

    return {
      title:
        component.factory != null ? component.factory.name : "Factory Edit",
    };
  },
  components: {
    LoadingSpinner,
    ListItemPageNavigation,
    FactoryEditForm,
  },
})
export default class FactoryEditPage extends Vue {
  @Prop()
  public readonly factoryId!: string;

  @Prop()
  public readonly query!: any;

  @Prop()
  public readonly listQuery?: Dictionary<string[]>;

  @Inject("FactoryRepository")
  private fFactoryRepository!: FactoryRepository;

  private fFactoryDataStore: FactoryStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get factory(): Factory | undefined {
    return this.fFactoryDataStore.getFactoryById(this.factoryId);
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }

  public getFactoriesOrder(): Promise<string[]> {
    return this.fFactoryRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  public onCancel(): void {
    void this.$router.push({
      name: "FactoriesListPage",
      query: this.listQuery,
    });
  }

  public onFactoryUpdated(): void {
    void this.$router.push({
      name: "FactoriesListPage",
      query: this.listQuery,
    });
  }

  private async getFactoryData(factoryId: string): Promise<void> {
    await this.fFactoryDataStore.loadFactoryById({
      id: factoryId,
      useCache: false,
    });
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    if (this.factoryId == null) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getFactoryData(this.factoryId);

      this.fIsLoading = false;
    } catch (e) {
      this.fIsLoading = false;
      throw e;
    }
  }
}
