


















import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ImagesGallery from "@/modules/plushie/components/ImagesGallery.vue";
import ActionButton from "@/lib/components/ActionButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import UiConfigurationStore from "@/modules/ui-configuration/store";
import BucketFileHandlerService from "@/modules/file-storage/bucket-file-handler.service";

import PlushieStore from "../../plushie/store";
import QualityInspectionStore from "../store";
import PlushieImage from "../../plushie/plushie-image.model";
import QaAsset from "../qa-asset.model";
import isAssetVideo from "../is-asset-video.function";

@Component({
  components: {
    ActionButton,
    FormErrors,
    ImagesGallery,
  },
})
export default class CompareGallery extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("VideoHandlerService")
  private fVideoHandlerService!: BucketFileHandlerService;

  private fFileStorageDataStore: FileStorageStore;
  private fPlushieDataStore: PlushieStore;
  private fQualityInspectionDataStore: QualityInspectionStore;
  private fUiConfigurationDataStore: UiConfigurationStore;

  private fIsArtworksGalleryLaunched = false;
  private fIsQaAssetsGalleryLaunched = false;

  get artworks(): PlushieImage[] {
    return this.fPlushieDataStore.getPlushieImagesByPlushieId(this.plushieId);
  }

  get qaAssets(): QaAsset[] {
    return this.fQualityInspectionDataStore.getQaAssetsByPlushieId(
      this.plushieId
    );
  }

  get artworkCurrentIndex() {
    const artworkIndex = this.fUiConfigurationDataStore.getPlushieViewArtworkIndex(
      this.plushieId
    );

    return artworkIndex === undefined ? 0 : artworkIndex;
  }

  get qaCurrentIndex() {
    const qaIndex = this.fUiConfigurationDataStore.getPlushieViewQAAssetIndex(
      this.plushieId
    );

    return qaIndex === undefined ? 0 : qaIndex;
  }

  public constructor() {
    super();
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
    this.fUiConfigurationDataStore = getModule(UiConfigurationStore, dataStore);
  }

  public onArtworksGalleryClose() {
    if (!this.fIsQaAssetsGalleryLaunched) {
      return;
    }

    const gallery = this.getQaAssetsGallery();

    if (gallery) {
      this.fIsQaAssetsGalleryLaunched = false;
      gallery.close();
    }
  }

  public onQaAssetsGalleryClose() {
    if (!this.fIsArtworksGalleryLaunched) {
      return;
    }

    const gallery = this.getArtworksGallery();

    if (gallery) {
      this.fIsArtworksGalleryLaunched = false;
      gallery.close();
    }
  }

  public getArtworkThumbnail(artwork: PlushieImage) {
    return this.getThumbnail(artwork.storageItem);
  }

  public getQaAssetThumbnail(qaAsset: QaAsset) {
    const storageItemId = isAssetVideo(qaAsset)
      ? qaAsset.thumbnailStorageItem
      : qaAsset.storageItem;

    return this.getThumbnail(storageItemId);
  }

  public getThumbnail(storageItemId: string, size = 1920, shouldCrop = false) {
    const storageItem = this.fFileStorageDataStore.getItemById(storageItemId);

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      size,
      size,
      shouldCrop
    );
  }

  public async launchGalleries() {
    await this.getPlushieData(this.plushieId);

    this.launchArtworkGallery();
    this.launchQaAssetGallery();
  }

  public launchArtworkGallery() {
    const gallery = this.getArtworksGallery();

    if (!gallery || this.artworkCurrentIndex == null) {
      return;
    }

    const images = this.artworks.map((artwork) => {
      return {
        href: this.getArtworkThumbnail(artwork),
        thumbnail: this.getThumbnail(artwork.storageItem, 200, true),
      };
    });

    gallery.launchGalleria(images, this.artworkCurrentIndex, {
      hidePageScrollbars: false,
      enableKeyboardNavigation: false,
    });

    this.fIsArtworksGalleryLaunched = true;
  }

  public launchQaAssetGallery() {
    const gallery = this.getQaAssetsGallery();

    if (!gallery || this.qaCurrentIndex == null) {
      return;
    }

    const slidesData = this.qaAssets.map((qaAsset) => {
      if (isAssetVideo(qaAsset)) {
        const storageItem = this.fFileStorageDataStore.getItemById(
          qaAsset.storageItem
        );

        return {
          href: this.fVideoHandlerService.getOriginalUrl(
            storageItem
              ? storageItem.timestampedUrl
              : this.fFileStorageDataStore.placeholderUrl
          ),
          thumbnail: this.getThumbnail(qaAsset.thumbnailStorageItem, 200, true),
          poster: this.getQaAssetThumbnail(qaAsset),
          type: "video/mp4",
        };
      } else {
        return {
          href: this.getQaAssetThumbnail(qaAsset),
          thumbnail: this.getThumbnail(qaAsset.storageItem, 200, true),
        };
      }
    });

    gallery.launchGalleria(slidesData, this.qaCurrentIndex, {
      hidePageScrollbars: false,
    });

    this.fIsQaAssetsGalleryLaunched = true;
  }

  public switchToArtwork(index: number) {
    this.fUiConfigurationDataStore.updatePlushieViewArtworkIndex({
      plushieId: this.plushieId,
      currentIndex: index,
    });
  }

  public switchToQaAsset(index: number) {
    this.fUiConfigurationDataStore.updatePlushieViewQAAssetIndex({
      plushieId: this.plushieId,
      currentIndex: index,
    });
  }

  private getArtworksGallery(): ImagesGallery | undefined {
    return this.$refs["artworks-gallery"] as ImagesGallery;
  }

  private getQaAssetsGallery(): ImagesGallery | undefined {
    return this.$refs["qa-assets-gallery"] as ImagesGallery;
  }

  private async getPlushieData(plushieId: string) {
    const [qaAssets, artworks] = await Promise.all([
      this.fQualityInspectionDataStore.loadQaAssetByPlushieId({
        plushieId: plushieId,
      }),
      this.fPlushieDataStore.loadPlushieImageByPlushieId({
        plushieId: plushieId,
      }),
    ]);

    const qaAssetsStorageItemsIds: string[] = [];
    qaAssets.forEach((qaAsset) => {
      qaAssetsStorageItemsIds.push(qaAsset.storageItem);

      if (isAssetVideo(qaAsset)) {
        qaAssetsStorageItemsIds.push(qaAsset.thumbnailStorageItem);
      }
    });

    const artworksStorageItemsIds = artworks.map(
      (qaAsset) => qaAsset.storageItem
    );

    await this.fFileStorageDataStore.loadItemsByIds([
      ...qaAssetsStorageItemsIds,
      ...artworksStorageItemsIds,
    ]);
  }
}
