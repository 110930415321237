













































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";

import FactoryRestrictedModeStore from "../store";

interface AlertInformation {
  warningInfo: {
    plushies: string[];
    daysBeforeBlock: number;
  };
  stuckInfo: {
    plushies: string[];
    largestDelay: number;
  };
}

@Component({
  components: {},
})
export default class FactoryRestrictedModeAlerts extends Vue {
  private fFactoryRestrictedModeDataStore: FactoryRestrictedModeStore;
  private fPlushieDataStore: PlushieStore;

  private fIsLoading = true;

  get alert(): AlertInformation | undefined {
    const alert = this.fFactoryRestrictedModeDataStore
      .factoryRestrictedModeAlerts;

    if (!alert) {
      return undefined;
    }

    return {
      warningInfo: {
        plushies: alert.warningInfo.plushies.slice(0, 20),
        daysBeforeBlock: alert.warningInfo.daysBeforeBlock,
      },
      stuckInfo: {
        plushies: alert.stuckInfo.plushies.slice(0, 20),
        largestDelay: alert.stuckInfo.largestDelay,
      },
    };
  }

  get daysBeforeBlock(): number {
    if (!this.alert) {
      return 0;
    }

    return this.alert.warningInfo.daysBeforeBlock;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get largestDelay(): number {
    if (!this.alert) {
      return 0;
    }

    return this.alert.stuckInfo.largestDelay;
  }

  get stuckPlushies(): string[] {
    if (!this.alert) {
      return [];
    }

    return this.alert.stuckInfo.plushies;
  }

  get warningPlushies(): string[] {
    if (!this.alert) {
      return [];
    }

    return this.alert.warningInfo.plushies;
  }

  get showStuckAlert(): boolean {
    return this.stuckPlushies.length > 0;
  }

  get showWarningAlert(): boolean {
    return this.warningPlushies.length > 0;
  }

  constructor() {
    super();
    this.fFactoryRestrictedModeDataStore = getModule(
      FactoryRestrictedModeStore,
      dataStore
    );

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public getStoreItemIdByPlushieId(plushieId: string): string {
    const plushie = this.fPlushieDataStore.getPlushieById(plushieId);

    if (!plushie) {
      return "";
    }

    return plushie.storeItemId;
  }

  public async mounted(): Promise<void> {
    this.fIsLoading = true;

    try {
      await this.fFactoryRestrictedModeDataStore.loadFactoryInvoiceAlerts();

      if (!this.alert) {
        return;
      }

      await this.fPlushieDataStore.loadPlushiesByIds([
        ...this.alert.stuckInfo.plushies,
        ...this.alert.warningInfo.plushies,
      ]);
    } finally {
      this.fIsLoading = false;
    }
  }
}
