import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiService from "@/modules/api/api.service";
import { Dictionary } from "@/lib/Dictionary.type";

import TagSearchResult from "./tag-search-result.model";

export default class TagSearchResultRepository {
  private fResourceName = "search_requests";

  constructor(
    protected api: ApiService,
    protected objectBuilder: ObjectBuilderInterface<TagSearchResult>
  ) {}

  public async createSearchRequest({
    tags,
    factory,
    product,
  }: {
    tags: string[];
    factory?: string[];
    product?: string[];
  }): Promise<TagSearchResult> {
    const data: Dictionary<string[]> = {
      query: tags,
    };

    if (factory) {
      data.factory = factory;
    }

    if (product) {
      data.product = product;
    }

    const response = await this.api.post(this.fResourceName, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }
}
