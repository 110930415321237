import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import RemotePrintingConfig from "./remote-printing-config.model";

export default class RemotePrintingConfigFactory
  implements ObjectBuilderInterface<RemotePrintingConfig> {
  public buildFromJSON(data: { [key: string]: any }): RemotePrintingConfig {
    const value = new RemotePrintingConfig(
      data.credentials,
      data.printerId.toString()
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
