import { Dictionary } from "@/lib/Dictionary.type";
import ApiService from "@/modules/api/api.service";

import { ApiFilterValue } from "../api/api-filter-value.type";
import AverageQaRoundsApiResponse from "./average-qa-rounds-api-response.interface";
import isAverageQaRoundsApiResponse from "./average-qa-rounds-api-response.typeguard";
import InProductionDaysApiResponse from "./in-production-days-api-response.interface";
import isInProductionDaysApiResponse from "./in-production-days-api-response.typeguard";
import ProductionVolumeApiResponse from "./production-volume-api-response.interface";
import isProductionVolumeApiResponse from "./production-volume-api-response.typeguard";
import RejectionRateApiResponse from "./rejection-rate-api-response.interface";
import isRejectionRateApiResponse from "./rejection-rate-api-response.typeguard";

export default class FactoryPerformanceRepository {
  constructor(protected api: ApiService) {}

  public async getProductionVolumeStatsForFactory(
    factoryId?: string
  ): Promise<ProductionVolumeApiResponse> {
    const data: Dictionary<ApiFilterValue> = {};

    if (factoryId) {
      data.factoryId = factoryId;
    }

    const response = await this.api.get(
      "/factories/performance/production_volume_stats",
      data
    );

    const result = response.data.result;

    if (!isProductionVolumeApiResponse(result)) {
      throw new Error("Unexpected response!");
    }

    return result;
  }

  public async getRejectionRateStatsForFactory(
    factoryId?: string
  ): Promise<RejectionRateApiResponse> {
    const data: Dictionary<ApiFilterValue> = {};

    if (factoryId) {
      data.factoryId = factoryId;
    }

    const response = await this.api.get(
      "/factories/performance/rejection_rate_stats",
      data
    );

    const result = response.data.result;

    if (!isRejectionRateApiResponse(result)) {
      throw new Error("Unexpected response!");
    }

    return result;
  }

  public async getInProductionStatsForFactory(
    factoryId?: string
  ): Promise<InProductionDaysApiResponse> {
    const data: Dictionary<ApiFilterValue> = {};

    if (factoryId) {
      data.factoryId = factoryId;
    }

    const response = await this.api.get(
      "/factories/performance/in_production_stats",
      data
    );

    const result = response.data.result;

    if (!isInProductionDaysApiResponse(result)) {
      throw new Error("Unexpected response!");
    }

    return result;
  }

  public async getAverageQaRoundsStats(
    factoryId?: string
  ): Promise<AverageQaRoundsApiResponse> {
    const data: Dictionary<ApiFilterValue> = {};

    if (factoryId) {
      data.factoryId = factoryId;
    }

    const response = await this.api.get(
      "/factories/performance/average_qa_rounds_stats",
      data
    );

    const result = response.data.result;

    if (!isAverageQaRoundsApiResponse(result)) {
      throw new Error("Unexpected response!");
    }

    return result;
  }
}
