import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import UserFactoryRelation from "./user-factory-relation.model";

export default class UserFactoryRelationFactory
  implements ObjectBuilderInterface<UserFactoryRelation> {
  public buildFromJSON(data: { [key: string]: any }): UserFactoryRelation {
    const value = new UserFactoryRelation(
      data.id,
      data.userId,
      data.factory,
      data.createdAt
    );

    value.isNew = false;

    return value;
  }
}
