import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieTagRelation from "./plushie-tag-relation.model";

export default class PlushieTagRelationFactory
  implements ObjectBuilderInterface<PlushieTagRelation> {
  public buildFromJSON(data: { [key: string]: any }): PlushieTagRelation {
    const value = new PlushieTagRelation(
      data.id,
      data.plushieId,
      data.tag,
      data.isPromoted == true
    );

    value.isNew = false;

    return value;
  }
}
