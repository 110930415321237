import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Organization implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    private fName: string,
    private fType: string
  ) {
    [fId, fName, fType].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get name() {
    return this.fName;
  }

  get type() {
    return this.fType;
  }

  get updatedAt() {
    return this.fUpdatedAt;
  }
}
