import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PlushieImage from "./plushie-image.model";

export default class PlushieImageFactory
  implements ObjectBuilderInterface<PlushieImage> {
  public buildFromJSON(data: { [key: string]: any }): PlushieImage {
    const value = new PlushieImage(
      data.id,
      data.plushie,
      data.type.toString(),
      data.storageItemId,
      +data.serialNumber
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
