import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import AutoApprovementExclude from "./auto-approvement-exclude.model";

export default class AutoApprovementExcludeFactory
  implements ObjectBuilderInterface<AutoApprovementExclude> {
  public buildFromJSON(data: { [key: string]: any }): AutoApprovementExclude {
    const value = new AutoApprovementExclude(data.plushieId);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
