




















































import { Component, Vue, Prop, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import DueDatesManagementStore from "@/modules/due-dates-management/store";
import PlushieStore from "@/modules/plushie/store";
import Factory from "@/modules/factory/factory.model";
import ProductionSchedule from "@/modules/due-dates-management/production-schedule.model";

import BlockingPlushieRepository from "../blocking-plushie.repository";

@Component({
  metaInfo() {
    return {
      title: "Blocking Plushies List",
    };
  },
  components: { LoadingSpinner },
})
export default class BlockingPlushiesListPage extends Vue {
  @Prop({ required: true })
  public readonly factoryId!: string;

  @Inject("BlockingPlushieRepository")
  private fBlockingPlushiesRepository!: BlockingPlushieRepository;

  private fFactoryDataStore: FactoryStore;
  private fDueDatesManagementDataStore: DueDatesManagementStore;
  private fPlushieDataStore: PlushieStore;

  private fIsLoading = false;
  private fBlockingPlushiesIds: string[] = [];

  get blockingPlushiesIds(): string[] {
    const schedules = this.fBlockingPlushiesIds
      .map((id) =>
        this.fDueDatesManagementDataStore.getProductionScheduleByPlushieId(id)
      )
      .filter((item): item is ProductionSchedule => item !== undefined);

    schedules.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1));

    return schedules.map((schedule) => schedule.id);
  }

  get factory(): Factory | undefined {
    return this.fFactoryDataStore.getFactoryById(this.factoryId);
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);

    this.fDueDatesManagementDataStore = getModule(
      DueDatesManagementStore,
      dataStore
    );

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public getStoreItemIdByPlushieId(plushieId: string): string {
    const plushie = this.fPlushieDataStore.getPlushieById(plushieId);

    if (!plushie) {
      return "";
    }

    return plushie.storeItemId;
  }

  public getDueDateByPlushieId(plushieId: string): string {
    const schedule = this.fDueDatesManagementDataStore.getProductionScheduleByPlushieId(
      plushieId
    );

    if (!schedule) {
      return "";
    }

    return schedule.dueDate.toLocaleDateString();
  }

  public getStatusByPlushieId(plushieId: string): string {
    const plushie = this.fPlushieDataStore.getPlushieById(plushieId);

    if (!plushie) {
      return "";
    }

    const status = this.fPlushieDataStore.getPlushieStatusById(plushie.status);

    if (!status) {
      return "";
    }

    return status.name;
  }

  private async getFactoryData(factoryId: string): Promise<void> {
    const blockingPlushies = await this.fBlockingPlushiesRepository.getByFactoryId(
      factoryId,
      100
    );

    const blockingPlushiesIds = blockingPlushies
      .slice(0, 100)
      .map((item) => item.plushie);

    await Promise.all([
      this.fFactoryDataStore.loadFactoryById({ id: this.factoryId }),
      this.fDueDatesManagementDataStore.loadProductionSchedulesByPlushieIds({
        plushieIds: blockingPlushiesIds,
      }),
      this.fPlushieDataStore.loadPlushiesByIds(blockingPlushiesIds),
      this.fPlushieDataStore.loadPlushieStatuses(),
    ]);

    this.fBlockingPlushiesIds = blockingPlushiesIds;
  }

  @Watch("factoryId", { immediate: true })
  private async _onFactoryIdChange() {
    this.fIsLoading = true;

    if (this.factoryId === undefined) {
      return;
    }

    try {
      await this.getFactoryData(this.factoryId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
