











































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { RowClickEventData, ServerResponse } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";

interface TableRow {
  id: string;
  name: string;
}

@Component({
  metaInfo: {
    title: "Penalty Rules Management",
  },
  components: {},
})
export default class PenaltyRuleListPage extends mixins(GeneralListPageMixin) {
  private fFactoryDataStore: FactoryStore;

  private fShowInactiveFactories = false;

  get showInactiveFactoriesCtrlText(): string {
    return this.fShowInactiveFactories ? "Hide inactive" : "Show inactive";
  }

  public constructor() {
    super();

    this.fFactoryDataStore = getModule(FactoryStore, dataStore);

    this.fColumns = [
      {
        name: "name",
        header: "Name",
        cssClass: "_name-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "name",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "PenaltyRuleEditPage"));
  }

  public toggleShowInactiveFactories(): void {
    this.fShowInactiveFactories = !this.fShowInactiveFactories;
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const factoriesCollection = await this.fFactoryDataStore.loadFactoriesWithFilter(
      {
        filter,
        order,
      }
    );

    const result: TableRow[] = [];

    factoriesCollection.getItems().forEach((factory) => {
      result.push({
        id: factory.id,
        name: factory.name,
      });
    });

    return { data: result, count: factoriesCollection.totalItemsCount };
  }

  protected getExtraParams(): Dictionary<string | number | boolean> {
    return this.fShowInactiveFactories
      ? {}
      : {
          isActive: true,
        };
  }

  @Watch("fShowInactiveFactories", { immediate: true })
  private _onShowInactiveFactoriesChange(): void {
    if (!this.fTable) {
      return;
    }

    this.fTable.refresh();
  }
}
