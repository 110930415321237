

































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";

import dataStore from "@/store";
import FactoryStore from "@/modules/factory/store";
import ProductionProcessStore from "@/modules/production-process/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import Factory from "../../factory/factory.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class ReassignFactoryCommand extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  public factory?: string;

  private fFactoryDataStore: FactoryStore;
  private fProductionProcessDataStore: ProductionProcessStore;

  get factoryOptions(): Factory[] {
    return this.fFactoryDataStore.activeFactoriesList;
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);

    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  protected data(): Record<string, unknown> {
    return {
      factory: this.factory,
    };
  }

  protected mounted(): void {
    void this.fFactoryDataStore.loadFactories();
  }

  protected async performSubmit(): Promise<void> {
    if (!this.factory) {
      return;
    }

    await this.fProductionProcessDataStore.reassignFactory({
      plushieId: this.plushieId,
      factoryId: this.factory,
    });
  }
}
