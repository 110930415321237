import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import FactoryInvoicesSetting from "./factory-invoices-setting.model";
import FactoryInvoicesSettingUpdateInterface from "./factory-invoices-setting-update.interface";

export default class FactoryInvoicesSettingRepository extends BasicRepository<FactoryInvoicesSetting> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<FactoryInvoicesSetting>,
    responseParser: ApiCollectionResponseParserService<FactoryInvoicesSetting>
  ) {
    super("factory_invoices/settings", api, objectBuilder, responseParser);
  }

  public async updateSettingValue(
    item: FactoryInvoicesSettingUpdateInterface
  ): Promise<FactoryInvoicesSetting> {
    const data: Dictionary<string | null> = {
      value: item.value,
    };

    const response = await this.api.put(
      this.resourceName + "/" + item.id,
      data
    );

    return this.objectBuilder.buildFromJSON(response.data);
  }
}
