export default class ProductionSnapshot {
  constructor(
    private fFactoryName: string,
    private fProductName: string,
    private fInDesign: number | null,
    private fInProduction: number | null,
    private fCustomerPreview: number | null,
    private fCustomerPreviewApproved: number | null,
    private fQualityInspection: number | null,
    private fReadyForProduction: number | null,
    private fReadyForShipment: number | null,
    private fRework: number | null,
    private fTotal: number | null
  ) {
    [
      fFactoryName,
      fProductName,
      fInDesign,
      fInProduction,
      fCustomerPreview,
      fCustomerPreviewApproved,
      fQualityInspection,
      fReadyForProduction,
      fReadyForShipment,
      fRework,
      fTotal,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get factoryName(): string {
    return this.fFactoryName;
  }

  get productName(): string {
    return this.fProductName;
  }

  get inDesign(): number | null {
    return this.fInDesign;
  }

  get inProduction(): number | null {
    return this.fInProduction;
  }

  get customerPreview(): number | null {
    return this.fCustomerPreview;
  }

  get customerPreviewApproved(): number | null {
    return this.fCustomerPreviewApproved;
  }

  get qualityInspection(): number | null {
    return this.fQualityInspection;
  }

  get readyForProduction(): number | null {
    return this.fReadyForProduction;
  }

  get readyForShipment(): number | null {
    return this.fReadyForShipment;
  }

  get rework(): number | null {
    return this.fRework;
  }

  get total(): number | null {
    return this.fTotal;
  }
}
