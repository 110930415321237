import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import ExcludedProduct from "./excluded-product.model";

export default class ExcludedProductRepository extends BasicRepository<ExcludedProduct> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<ExcludedProduct>,
    responseParser: ApiCollectionResponseParserService<ExcludedProduct>
  ) {
    super(
      "factory_restricted_mode/excluded_products",
      api,
      objectBuilder,
      responseParser
    );
  }

  protected prepareData(item: ExcludedProduct): Record<string, string> {
    const data: Dictionary<string> = {
      id: item.id,
    };

    return data;
  }
}
