import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import MassActionServiceInterface from "@/modules/plushie/mass-action-service.interface";

import ProductionProcessStore from "./store";

export default class BulkMoveToProductionService
  implements MassActionServiceInterface {
  public static readonly ID = "bulkMoveToProduction";

  private fProductionProcessDataStore: ProductionProcessStore;

  constructor() {
    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
  }

  public async execute(plushieIds: string[]): Promise<boolean> {
    if (plushieIds.length < 1) {
      return false;
    }

    await this.fProductionProcessDataStore.bulkMoveToProduction(plushieIds);

    return true;
  }
}
