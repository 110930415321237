






































































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import autoindent from "@/lib/directives/auto-indent.directive";
import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import PlushieStore from "@/modules/plushie/store";

import EmailsManagementStore from "../store";
import Sender from "../sender.model";
import EmailTemplate from "../email-template.model";

@Component({
  metaInfo: {
    title: "Email Template Edit",
  },
  components: {
    FormField,
    FormErrors,
    LoadingSpinner,
    SubmitButton,
  },
  directives: {
    autosize,
    autoindent,
  },
})
export default class EmailTemplateEditPage extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly templateId!: string;

  public code: string | undefined;
  public content: string | undefined;
  public sender: string | undefined;
  public store: number | null = null;
  public subject: string | undefined;

  private fEmailsManagementStore: EmailsManagementStore;
  private fPlushieStore: PlushieStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get storeOptions(): DirectoryValue[] {
    return this.fPlushieStore.storesList;
  }

  get senderOptions(): Sender[] {
    return this.fEmailsManagementStore.sendersList;
  }

  get template(): EmailTemplate | undefined {
    return this.fEmailsManagementStore.getEmailTemplateById(this.templateId);
  }

  constructor() {
    super();

    this.fEmailsManagementStore = getModule(EmailsManagementStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  public onCancelButtonClick(): void {
    this.navigateToListPage();
  }

  protected created(): void {
    void this.loadData();
  }

  protected data(): Record<string, string | number | undefined | null> {
    return {
      code: this.code,
      content: this.content,
      sender: this.sender,
      store: this.store,
      subject: this.subject,
    };
  }

  protected async performSubmit(): Promise<void> {
    const template = this.generateTemplateObject();

    await this.fEmailsManagementStore.saveEmailTemplate(template);
    this.navigateToListPage();
  }

  private fillFormData(): void {
    if (!this.template) {
      return;
    }

    this.code = this.template.code;
    this.store = this.template.store;
    this.sender = this.template.sender;
    this.subject = this.template.subject;
    this.content = this.template.content;
  }

  private generateTemplateObject(): EmailTemplate {
    if (!this.code || !this.sender || !this.subject || !this.content) {
      throw new Error(
        "Unable to create object - some required fields are missing!"
      );
    }

    const template = new EmailTemplate(
      this.templateId,
      this.code,
      this.subject,
      this.content,
      this.sender,
      this.store
    );

    template.isNew = false;

    return template;
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    try {
      await Promise.all([
        this.fPlushieStore.loadStores(),
        this.fEmailsManagementStore.loadEmailTemplates(),
        this.fEmailsManagementStore.loadSenders(),
      ]);

      this.fillFormData();
    } finally {
      this.fIsLoading = false;
    }
  }

  private navigateToListPage(): void {
    void this.$router.push({
      name: "EmailTemplatesListPage",
    });
  }
}
