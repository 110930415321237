import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Message implements MutableValue {
  public isNew = true;

  private fUpdatedAt?: Date;
  private fRecipient?: string;

  constructor(
    private fId: string,
    private fChannel: string,
    private fSender: string,
    private fPlushie: string,
    private fText: string,
    private fTextTranslation: string,
    private fIsRead: boolean,
    private fIsForwardDirection: boolean,
    private fCreatedAt: Date
  ) {
    [
      fId,
      fChannel,
      fSender,
      fPlushie,
      fText,
      fTextTranslation,
      fIsRead,
      fIsForwardDirection,
      fCreatedAt,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get channel(): string {
    return this.fChannel;
  }

  get sender(): string {
    return this.fSender;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get text(): string {
    return this.fText;
  }

  get textTranslation(): string {
    return this.fTextTranslation;
  }

  get isRead(): boolean {
    return this.fIsRead;
  }

  get isForwardDirection(): boolean {
    return this.fIsForwardDirection;
  }

  get createdAt(): Date {
    return this.fCreatedAt;
  }

  get updatedAt(): Date | undefined {
    return this.fUpdatedAt;
  }

  get recipient(): string | undefined {
    return this.fRecipient;
  }
}
