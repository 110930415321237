import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class Factory implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;
  private fUpdatedAt?: Date;

  constructor(
    private fId: string,
    public email: string,
    public name: string,
    public isActive: boolean,
    public language: number,
    public country: number,
    public paSafetyNumber: string
  ) {
    [fId, email, name, isActive, language, country].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get createdAt() {
    return this.fCreatedAt;
  }

  get updatedAt() {
    return this.fUpdatedAt;
  }
}
