






































































































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { create } from "vue-modal-dialogs";
import Raphael from "raphael";

import ImageRotationDialog from "@/modules/general/components/ImageRotationDialog.vue";
import ImagesGalleryFullscreenModal from "@/modules/plushie/components/ImagesGalleryFullscreenModal.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import ActionButton from "@/lib/components/ActionButton.vue";
import MarkedUpImagesGalleryFullscreenModal from "@/modules/photo-markup/components/MarkedUpImagesGalleryFullscreenModal.vue";

import ErrorConverterService from "@/modules/forms/error-converter.service";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import UiConfigurationStore from "@/modules/ui-configuration/store";
import preloadImage from "@/lib/preload-image.function";
import { EntityType } from "@/modules/photo-markup/entity-type";
import PlushieStore from "@/modules/plushie/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import { Dictionary } from "@/lib/Dictionary.type";
import PhotoMarkupStore from "@/modules/photo-markup/store";
import ImagesGalleryFullscreenModalProp from "@/lib/images-gallery-fullscreen-modal-prop";
import MarkedUpImagesGalleryFullscreenModalProp from "@/modules/photo-markup/marked-up-images-gallery-fullscreen-modal-prop";
import PlushieStorageItemNameResolverService from "@/modules/plushie/plushie-storage-item-name-resolver.service";
import Plushie from "@/modules/plushie/plushie.model";
import BucketFileDownloaderService from "@/modules/file-storage/bucket-file-downloader.service";
import Item from "@/modules/file-storage/item.model";
import BucketFileHandlerService from "@/modules/file-storage/bucket-file-handler.service";

import CompareGallery from "./CompareGallery.vue";

import QaAsset from "../qa-asset.model";
import isAssetVideo from "../is-asset-video.function";
import QualityInspectionStore from "../store";
import { PlushieStatusValue } from "../../plushie/plushie-status.value";
import QaAssetType from "../qa-asset-type";

@Component({
  components: {
    ActionButton,
    CompareGallery,
    FormErrors,
  },
})
export default class QaAssetsGallery extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: true })
  public readonly isReadOnly!: boolean;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("QaPhotosDownloaderService")
  private fQaPhotosDownloaderService!: BucketFileDownloaderService;

  @Inject("PlushieStorageItemNameResolverService")
  private fPlushieStorageItemNameResolverService!: PlushieStorageItemNameResolverService;

  @Inject("VideoDownloaderService")
  private fVideoDownloaderService!: BucketFileDownloaderService;

  @Inject("VideoHandlerService")
  private fVideoHandlerService!: BucketFileHandlerService;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("window")
  private fWindow!: Window;

  private fActionErrors: string[] = [];

  private fIsLoading = true;
  private fIsDisabled = false;

  private fFileStorageDataStore: FileStorageStore;
  private fPhotoMarkupDataStore: PhotoMarkupStore;
  private fQualityInspectionDataStore: QualityInspectionStore;
  private fUiConfigurationDataStore: UiConfigurationStore;
  private fPlushieDataStore: PlushieStore;

  get stageStorageItem(): Item | undefined {
    if (!this.stageImage) {
      return;
    }

    return this.fFileStorageDataStore.getItemById(this.stageImage.storageItem);
  }

  get actionErrors(): string[] {
    return this.fActionErrors;
  }

  get isMarkupAvailable(): boolean {
    if (!this.stageImage || this.stageImage.type !== QaAssetType.PHOTO) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (!user.hasPermissionForResource(Resource.PHOTO_MARKUPS_MANAGE)) {
      return false;
    }

    if (!this.plushie) {
      return false;
    }

    return [
      PlushieStatusValue.QUALITY_INSPECTION,
      PlushieStatusValue.PPS_INSPECTION,
      PlushieStatusValue.BULK_INSPECTION,
    ].includes(this.plushie.status);
  }

  get qaAssets(): QaAsset[] {
    return this.fQualityInspectionDataStore.getQaAssetsByPlushieId(
      this.plushieId
    );
  }

  get currentIndex(): number | undefined {
    return this.fUiConfigurationDataStore.getPlushieViewQAAssetIndex(
      this.plushieId
    );
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isDisabled(): boolean {
    return this.fIsDisabled;
  }

  get isTouchDevice(): boolean {
    return this.fWindow.matchMedia("(pointer: coarse)").matches;
  }

  get plushie(): Plushie | undefined {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  get stageImage(): QaAsset | undefined {
    if (this.currentIndex == null) {
      return undefined;
    }

    return this.qaAssets[this.currentIndex];
  }

  get displayMarkup(): boolean {
    const displayMarkup = this.fUiConfigurationDataStore.getPlushieViewQaDisplayMarkup(
      this.plushieId
    );

    if (displayMarkup === undefined) {
      return true;
    }

    return displayMarkup;
  }

  get toggleMarkupButtonText(): string {
    return this.displayMarkup ? "Hide Markup" : "Show Markup";
  }

  get shouldShowRotateButton(): boolean {
    if (!this.stageImage) {
      return false;
    }

    return !this.isVideoAsset(this.stageImage);
  }

  public constructor() {
    super();

    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPhotoMarkupDataStore = getModule(PhotoMarkupStore, dataStore);

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );

    this.fUiConfigurationDataStore = getModule(UiConfigurationStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public async downloadQaAsset(qaAsset: QaAsset): Promise<void> {
    const storageItem = this.fFileStorageDataStore.getItemById(
      qaAsset.storageItem
    );

    if (!this.plushie) {
      throw new Error("Plushie data is not loaded");
    }

    if (!storageItem) {
      throw new Error("Storage item data is not loaded");
    }

    const plushieStorageItemDownloadName = this.fPlushieStorageItemNameResolverService.resolve(
      storageItem,
      this.plushie,
      this.currentIndex
    );

    if (isAssetVideo(qaAsset)) {
      await this.fVideoDownloaderService.download(
        qaAsset,
        plushieStorageItemDownloadName
      );
      return;
    }

    await this.fQaPhotosDownloaderService.download(
      qaAsset,
      plushieStorageItemDownloadName
    );
  }

  public addMarkupToPreview(): void {
    if (!this.stageImage) {
      return;
    }

    const markup = this.fPhotoMarkupDataStore.getMarkupByImageId(
      this.stageImage.id
    );

    if (!markup) {
      return;
    }

    const stageImageElement = this.getStageImageElement();
    const stageImageWrapper = this.getStageImageWrapper();

    if (!stageImageElement || !stageImageWrapper) {
      return;
    }

    const paper = Raphael(
      stageImageWrapper,
      stageImageElement.offsetWidth,
      stageImageElement.offsetHeight
    );

    paper.setViewBox(0, 0, markup.markup.width, markup.markup.height, true);

    const sketchpad = Raphael.sketchpad(paper, {
      editing: false,
      svgClass: "_sketchpad-svg",
    });

    sketchpad.json(markup.markup.strokes);
  }

  public removeMarkupFromPreview(): void {
    const stageImageWrapper = this.getStageImageWrapper();

    if (!stageImageWrapper) {
      return;
    }

    const oldContainers = stageImageWrapper.getElementsByClassName(
      "_sketchpad-svg"
    );

    while (oldContainers.length > 0) oldContainers[0].remove();
  }

  public doesHaveMarkup(qaAsset: QaAsset): boolean {
    return (
      this.fPhotoMarkupDataStore.getMarkupByImageId(qaAsset.id) !== undefined
    );
  }

  public isVideoAsset(qaAsset: QaAsset): boolean {
    return qaAsset.type === QaAssetType.VIDEO;
  }

  public async rotateArtwork(qaAsset: QaAsset): Promise<void> {
    const dialogFunction = create<Record<string, unknown>, boolean>(
      ImageRotationDialog
    );

    await dialogFunction({
      storageItemId: qaAsset.storageItem,
    });
  }

  public async downloadAll(): Promise<void> {
    const downloadPromises: Promise<void>[] = [];

    this.qaAssets.forEach((qaAsset, index) => {
      const storageItem = this.fFileStorageDataStore.getItemById(
        qaAsset.storageItem
      );

      if (!storageItem) {
        throw new Error("Storage item data is not loaded");
      }

      if (!this.plushie) {
        throw new Error("Plushie data is not loaded");
      }

      const plushieStorageItemDownloadName = this.fPlushieStorageItemNameResolverService.resolve(
        storageItem,
        this.plushie,
        index
      );

      if (isAssetVideo(qaAsset)) {
        downloadPromises.push(
          this.fVideoDownloaderService.download(
            qaAsset,
            plushieStorageItemDownloadName
          )
        );
      } else {
        downloadPromises.push(
          this.fQaPhotosDownloaderService.download(
            qaAsset,
            plushieStorageItemDownloadName
          )
        );
      }
    });

    await Promise.all(downloadPromises);
  }

  public getImageLegend(): string {
    if (this.currentIndex == null) {
      return "";
    }

    const currentIndex = this.currentIndex + 1;

    const imagesQty = this.qaAssets.length;

    return `${currentIndex}/${imagesQty}`;
  }

  public getStageThumbnail(qaAsset: QaAsset): string {
    return this.getThumbnail(qaAsset, 600);
  }

  public getFullscreenThumbnail(qaAsset: QaAsset): string {
    return this.getThumbnail(qaAsset, 1920);
  }

  public getPhotoDescription(qaAsset: QaAsset): string {
    return `${qaAsset.createdAt.toLocaleDateString()} - Group #${
      qaAsset.groupNumber
    }`;
  }

  public getThumbnail(
    qaAsset: QaAsset,
    size: number,
    shouldCrop = false
  ): string {
    const storageItemId = isAssetVideo(qaAsset)
      ? qaAsset.thumbnailStorageItem
      : qaAsset.storageItem;

    const storageItem = this.fFileStorageDataStore.getItemById(storageItemId);

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      size,
      size,
      shouldCrop
    );
  }

  public getMarkupQuery(): Dictionary<string> | undefined {
    if (!this.stageImage) {
      return undefined;
    }

    return {
      plushieId: this.plushieId,
      imageId: this.stageImage.id,
      entityTypeId: EntityType.QA_PHOTO,
      returnUrl: this.$router.currentRoute.fullPath,
    };
  }

  public launchGalleria(): void {
    this.displayMarkup
      ? this.launchMarkedUpGalleria()
      : this.launchRegularGalleria();
  }

  public async deleteAsset(qaAsset: QaAsset): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsDisabled = true;
    this.fActionErrors = [];

    try {
      await this.fQualityInspectionDataStore.deleteQaAsset(qaAsset);

      if (this.currentIndex && this.currentIndex > this.qaAssets.length - 1) {
        this.setCurrentIndex(this.currentIndex - 1);
      }

      await this.fFileStorageDataStore.deleteItemById(qaAsset.storageItem);
      if (isAssetVideo(qaAsset)) {
        await this.fFileStorageDataStore.deleteItemById(
          qaAsset.thumbnailStorageItem
        );
      }
    } catch (error) {
      this.fActionErrors = this.fErrorConverterService.describeError(error);
    } finally {
      this.fIsDisabled = false;
    }

    return;
  }

  public openCompareMode(): void {
    const gallery = this.$refs["compare-gallery"] as CompareGallery;
    gallery.launchGalleries();
  }

  public setCurrentIndex(index: number): void {
    this.fUiConfigurationDataStore.updatePlushieViewQAAssetIndex({
      plushieId: this.plushieId,
      currentIndex: index,
    });
  }

  public toggleMarkupDisplay(): void {
    this.fUiConfigurationDataStore.updatePlushieViewQaDisplayMarkup({
      plushieId: this.plushieId,
      qaDisplayMarkup: !this.displayMarkup,
    });

    if (!this.displayMarkup) {
      this.removeMarkupFromPreview();
    } else {
      this.addMarkupToPreview();
    }
  }

  private getStageImageElement(): HTMLImageElement | undefined {
    return this.$refs["stageImage"] as HTMLImageElement | undefined;
  }

  private getStageImageWrapper(): HTMLElement | undefined {
    return this.$refs["stageImageWrapper"] as HTMLElement | undefined;
  }

  private async getPlushieData(plushieId: string) {
    const user = this.fUserProvider.getUser();

    const qaAssets = await this.fQualityInspectionDataStore.loadQaAssetByPlushieId(
      {
        plushieId: plushieId,
      }
    );

    if (user && user.hasPermissionForResource(Resource.PHOTO_MARKUPS_READ)) {
      const imagesIds = qaAssets.map((qaAsset) => qaAsset.id);

      setTimeout(() => {
        void this.loadMarkupData(imagesIds);
      }, 1000);
    }

    const storageItemsIds: string[] = [];

    qaAssets.forEach((qaAsset) => {
      storageItemsIds.push(qaAsset.storageItem);

      if (isAssetVideo(qaAsset)) {
        storageItemsIds.push(qaAsset.thumbnailStorageItem);
      }
    });

    await this.fFileStorageDataStore.loadItemsByIds(storageItemsIds);

    if (
      this.qaAssets.length > 0 &&
      (!this.currentIndex || this.currentIndex > this.qaAssets.length)
    ) {
      this.setCurrentIndex(0);
    }

    void this.preloadImages();
  }

  private async loadMarkupData(imageIds: string[]): Promise<void> {
    await this.fPhotoMarkupDataStore.loadMarkupByImageIds({
      imageIds,
    });

    if (this.displayMarkup) {
      this.addMarkupToPreview();
    }
  }

  private launchMarkedUpGalleria(): void {
    if (this.currentIndex == null) {
      return;
    }
    const slidesData = this.qaAssets.map((qaAsset) => {
      if (isAssetVideo(qaAsset)) {
        const storageItem = this.fFileStorageDataStore.getItemById(
          qaAsset.storageItem
        );

        return {
          href: this.fVideoHandlerService.getOriginalUrl(
            storageItem
              ? storageItem.timestampedUrl
              : this.fFileStorageDataStore.placeholderUrl
          ),
          poster: this.getFullscreenThumbnail(qaAsset),
          thumbnail: this.getThumbnail(qaAsset, 200, true),
          type: "video/mp4",
        };
      } else {
        const markup = this.fPhotoMarkupDataStore.getMarkupByImageId(
          qaAsset.id
        );

        return {
          href: this.getFullscreenThumbnail(qaAsset),
          thumbnail: this.getThumbnail(qaAsset, 200, true),
          markup: markup ? markup.markup : undefined,
          type: "markedUpImage",
        };
      }
    });

    const modalFunction = create(MarkedUpImagesGalleryFullscreenModal);

    const props: MarkedUpImagesGalleryFullscreenModalProp = {
      slides: slidesData,
      index: this.currentIndex,
      currentIndexChangeHandler: (index) => this.setCurrentIndex(index),
    };

    void modalFunction(props);
  }

  private launchRegularGalleria(): void {
    if (this.currentIndex == null) {
      return;
    }
    const slidesData = this.qaAssets.map((qaAsset) => {
      if (isAssetVideo(qaAsset)) {
        const storageItem = this.fFileStorageDataStore.getItemById(
          qaAsset.storageItem
        );

        return {
          href: this.fVideoHandlerService.getOriginalUrl(
            storageItem
              ? storageItem.timestampedUrl
              : this.fFileStorageDataStore.placeholderUrl
          ),
          poster: this.getFullscreenThumbnail(qaAsset),
          thumbnail: this.getThumbnail(qaAsset, 200, true),
          type: "video/mp4",
        };
      } else {
        return {
          href: this.getFullscreenThumbnail(qaAsset),
          thumbnail: this.getThumbnail(qaAsset, 200, true),
        };
      }
    });

    const modalFunction = create(ImagesGalleryFullscreenModal);

    const props: ImagesGalleryFullscreenModalProp = {
      slides: slidesData,
      index: this.currentIndex,
      currentIndexChangeHandler: (index) => this.setCurrentIndex(index),
    };

    void modalFunction(props);
  }

  private async preloadImages() {
    await Promise.all(
      this.qaAssets.map((qaAsset) =>
        preloadImage(this.getStageThumbnail(qaAsset))
      )
    );

    await Promise.all(
      this.qaAssets.map((qaAsset) =>
        preloadImage(this.getFullscreenThumbnail(qaAsset))
      )
    );
  }

  @Watch("qaAssets", { immediate: false })
  private async _onQaAssetsChange() {
    if (this.isLoading) {
      return;
    }

    await this.preloadImages();

    if (this.qaAssets.length > 0 && this.currentIndex === undefined) {
      this.setCurrentIndex(0);
    }
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    await this.getPlushieData(this.plushieId);

    this.fIsLoading = false;

    void this._onStageImageChange();
  }

  @Watch("stageImage", { immediate: false })
  private async _onStageImageChange() {
    await this.$nextTick();

    this.removeMarkupFromPreview();

    if (this.displayMarkup) {
      this.addMarkupToPreview();
    }
  }
}
