









import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import AccountStore from "@/modules/account/store";
import PlushieStore from "@/modules/plushie/store";

import FactoryStore from "../../../store";
import HistoryItem from "../../../production-time-norm-history-item.model";

@Component({
  components: {},
})
export default class ProductionTimeNormHistoryItem extends Vue {
  @Prop({ required: true })
  public readonly historyItem!: HistoryItem;

  private fAccountDataStore: AccountStore;
  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  get product(): string {
    const product = this.fPlushieDataStore.getProductById(
      this.historyItem.product
    );

    if (!product) {
      return "";
    }

    return product.name;
  }

  get type(): string {
    const type = this.fFactoryDataStore.getHistoryTypeById(
      this.historyItem.type
    );

    if (!type) {
      return "";
    }

    return type.name;
  }

  get author() {
    const userInfo = this.fAccountDataStore.getUserInfoById(
      this.historyItem.user
    );

    if (userInfo === undefined) {
      return "";
    }

    const userRelations = this.fAccountDataStore.getUserRelationsByUserId(
      this.historyItem.user
    );

    if (userRelations === undefined || userRelations.userRole === undefined) {
      return "";
    }

    const userRole = this.fAccountDataStore.getUserRoleById(
      userRelations.userRole
    );

    if (userRole === undefined) {
      return "";
    }

    const roleId = userRole.role;

    const role = this.fAccountDataStore.getRoleById(roleId);

    if (role === undefined) {
      return "";
    }

    return `${userInfo.firstName} ${userInfo.lastName} (${role.name})`;
  }

  constructor() {
    super();
    this.fAccountDataStore = getModule(AccountStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
  }
}
