import { MutableValue } from "@/modules/api/mutable-value.interface";
import { ProductValue } from "@/modules/plushie/product.value";

export default class BulkProject implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fCreatedAt: Date,
    private fProduct: ProductValue,
    private fImageStorageItemId: string,
    private fQuantity: number,
    private fSize: number,
    private fDescription: string,
    private fCountry: number,
    private fCustomer: string,
    private fIncrementalId: number,
    private fStatus: string,
    private fName?: string,
    private fPhone?: string,
    private fDeadlineDate?: Date,
    private fClientType?: string,
    private fResponsibleUserId?: string
  ) {
    [
      fId,
      fCreatedAt,
      fProduct,
      fImageStorageItemId,
      fQuantity,
      fSize,
      fDescription,
      fCountry,
      fIncrementalId,
      fStatus,
    ].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  public get id(): string {
    return this.fId;
  }

  public get createdAt(): Date {
    return this.fCreatedAt;
  }

  public get product(): ProductValue {
    return this.fProduct;
  }

  public get imageStorageItemId(): string {
    return this.fImageStorageItemId;
  }

  public get quantity(): number {
    return this.fQuantity;
  }

  public get size(): number {
    return this.fSize;
  }

  public get description(): string {
    return this.fDescription;
  }

  public get country(): number {
    return this.fCountry;
  }

  public get customer(): string {
    return this.fCustomer;
  }

  public get status(): string {
    return this.fStatus;
  }

  public get name(): string | undefined {
    return this.fName;
  }

  public get phone(): string | undefined {
    return this.fPhone;
  }

  public get deadlineDate(): Date | undefined {
    return this.fDeadlineDate;
  }

  public get clientType(): string | undefined {
    return this.fClientType;
  }

  public get incrementalId(): number {
    return this.fIncrementalId;
  }

  public get responsibleUserId(): string | undefined {
    return this.fResponsibleUserId;
  }
}
