export default class BucketFileHandlerService {
  private fBucketHost: string;

  public constructor(bucketHost: string) {
    this.fBucketHost = bucketHost;

    if (!this.fBucketHost.endsWith("/")) {
      this.fBucketHost += "/";
    }
  }

  public getOriginalUrl(fileKey: string): string {
    return `${this.fBucketHost}${fileKey}`;
  }
}
