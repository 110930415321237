import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import RoleResource from "./role-resource.model";

export default class RoleResourceFactory
  implements ObjectBuilderInterface<RoleResource> {
  public buildFromJSON(data: { [key: string]: any }): RoleResource {
    const value = new RoleResource(data.id, data.role, data.resource);

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
