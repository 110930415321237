










































































import { Component, Vue, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {
  ServerResponse,
  ColumnFilterDropdownOption,
  RowClickEventData,
} from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";
import { saveAs } from "file-saver";

import dataStore from "@/store";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import FactoryStore from "@/modules/factory/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import FactoryInvoiceStore from "@/modules/factory-invoice/store";
import ResponseFileNameResolver from "@/modules/general/response-file-name-resolver.service";
import FileStorageStore from "@/modules/file-storage/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";
import { Dictionary } from "@/lib/Dictionary.type";

import BulkShipmentRepository from "../bulk-shipment.repository";
import BulkShipmentStore from "../store";

interface TableRow {
  id: string;
  createdAt: string;
  factory?: string;
  itemsQty?: number;
  trackingNumber: string;
  status: string;
  invoiceId?: string;
  airwayBillStorageItemId?: string;
}

@Component({
  metaInfo: {
    title: "Shipments From Factory",
  },
  components: {},
})
export default class ShipmentsListPage extends mixins(GeneralListPageMixin) {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("BulkShipmentRepository")
  private fBulkShipmentRepository!: BulkShipmentRepository;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fBulkShipmentDataStore: BulkShipmentStore;
  private fFactoryDataStore: FactoryStore;
  private fFactoryInvoiceDataStore: FactoryInvoiceStore;
  private fFileStorageDataStore: FileStorageStore;

  get isFactoryView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return true;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  public constructor() {
    super();

    this.fBulkShipmentDataStore = getModule(BulkShipmentStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);

    this.fColumns = [
      {
        name: "createdAt",
        header: "Created At",
        isSortable: true,
        sortKey: "createdAt",
        cssClass: "_createdat-column",
        displayConstraint: "min_tablet",
      },
      {
        name: "factory",
        header: "Factory",
        isFilterable: true,
        isFilterFromList: true,
        filterKey: "factoryId",
        cssClass: "_factory-column -hide-for-factory",
      },
      {
        name: "itemsQty",
        header: "# Items",
        cssClass: "itemsqty-column",
        displayConstraint: "min_tabletL",
      },
      {
        name: "trackingNumber",
        header: "Tracking Number",
        isSortable: true,
        isFilterable: true,
        filterKey: "trackingNumber",
        cssClass: "_trackingnumber-column",
        displayConstraint: "min_desktop",
      },
      {
        name: "status",
        header: "Status",
        isFilterable: true,
        isFilterFromList: true,
        cssClass: "_status-column",
      },
      {
        name: "invoice",
        header: "Invoice",
        cssClass: "_invoice-column",
        displayConstraint: "min_tablet",
      },
      {
        name: "downloadAirwayBillAction",
        header: "Airway Bill",
        cssClass: "_downloadairwaybillaction-column",
        displayConstraint: "min_tabletL",
      },
      {
        name: "downloadCsvAction",
        header: "Download",
        cssClass: "_downloadcsvaction-column",
        displayConstraint: "min_tablet",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_desktop",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "createdAt",
        ascending: false,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    data.event.preventDefault();

    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "BulkShipmentViewPage"));
  }

  public async downloadCsv(shipmentId: string): Promise<void> {
    const response = await this.fBulkShipmentRepository.getCsvByShipmentId(
      shipmentId
    );

    const blob = new Blob([response.data], {
      type: "text/csv",
    });

    const fileName =
      ResponseFileNameResolver.resolve(response) ||
      `shipment_${shipmentId}.csv`;

    saveAs(blob, fileName);
  }

  public downloadAirwayBill(
    shipmentId: string,
    airwayBillStorageItemId: string
  ): void {
    const storageItem = this.fFileStorageDataStore.getItemById(
      airwayBillStorageItemId
    );

    if (!storageItem) {
      return;
    }

    const originalUrl = this.fImageHandlerService.getOriginalImageUrl(
      storageItem.url
    );

    const fileName =
      storageItem.url.split("/").pop() || `airway-bill-${shipmentId}.jpg`;

    saveAs(originalUrl, fileName);
  }

  public getInvoiceNumber(invoiceId: string): string {
    const invoice = this.fFactoryInvoiceDataStore.getFactoryInvoiceById(
      invoiceId
    );

    if (!invoice) {
      return "";
    }

    return `#${invoice.number}`;
  }

  public getInvoiceViewLink(invoiceId: string): string {
    const route = this.$router.resolve({
      name: "FactoryInvoiceViewPage",
      query: { id: invoiceId },
    });

    return route.href;
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const shipmentsCollection = await this.fBulkShipmentDataStore.loadBulkShipments(
      {
        page,
        limit,
        filter,
        order,
      }
    );

    const shipments = shipmentsCollection.getItems();

    const shipmentIds: string[] = [];
    const airwayBillStorageItemsIds: string[] = [];

    shipments.forEach((shipment) => {
      shipmentIds.push(shipment.id);

      if (shipment.airwayBill != null) {
        airwayBillStorageItemsIds.push(shipment.airwayBill);
      }
    });

    await Promise.all([
      this.fBulkShipmentDataStore.loadStatuses(),
      this.fFileStorageDataStore.loadItemsByIds(airwayBillStorageItemsIds),
      this.fFactoryInvoiceDataStore.loadFactoryInvoicesByShipmentIds({
        shipmentIds,
      }),
    ]);

    const result: TableRow[] = [];

    shipments.forEach((shipment) => {
      const status = this.fBulkShipmentDataStore.getStatusById(shipment.status);
      const invoice = this.fFactoryInvoiceDataStore.getFactoryInvoiceByShipmentId(
        shipment.id
      );

      const row: TableRow = {
        id: shipment.id,
        createdAt: shipment.createdAt
          ? shipment.createdAt.toLocaleDateString()
          : "",
        itemsQty: shipment.itemsQty,
        trackingNumber: shipment.trackingNumber
          ? shipment.trackingNumber.toString()
          : "",
        status: status ? status.name : "",
        invoiceId: invoice ? invoice.id : undefined,
        airwayBillStorageItemId: shipment.airwayBill,
      };

      if (!this.isFactoryView) {
        const factory = this.fFactoryDataStore.getFactoryById(shipment.factory);
        row.factory = factory ? factory.name : "";
      }

      result.push(row);
    });

    return { data: result, count: shipmentsCollection.totalItemsCount };
  }

  protected async init(): Promise<void> {
    await this.fBulkShipmentDataStore.loadStatuses();

    const statuses = this.fBulkShipmentDataStore.getStatusList;

    const statusOptions: ColumnFilterDropdownOption[] = [];

    statuses.forEach((item) => {
      statusOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "status", statusOptions);
    }

    if (this.isFactoryView) {
      return;
    }

    await this.fFactoryDataStore.loadFactories();

    const factories = this.fFactoryDataStore.factoriesList;

    const factoryOptions: ColumnFilterDropdownOption[] = [];

    factories.forEach((item) => {
      factoryOptions.push({ id: item.id, text: item.name });
    });

    if (this.fOptions.listColumns) {
      Vue.set(this.fOptions.listColumns, "factory", factoryOptions);
    }

    return;
  }
}
