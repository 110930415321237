



































































import { Component, Inject } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { RowClickEventData, ServerResponse } from "vue-tables-2-premium";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { ImageHandlerOutputFormat } from "@/modules/file-storage/image-handler-output-format";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import { Dictionary } from "@/lib/Dictionary.type";
import GeneralListPageMixin from "@/lib/mixins/GeneralListPage";

import AlertStore from "../store";
import AlertType from "../alert-type.model";

interface TableRow {
  id: string;
  title: string;
  description: string;
  icon: string;
  displayToFactory: boolean;
  isNotifyAboutMovements: boolean;
}

@Component({
  metaInfo: {
    title: "Manage Hashtag Alerts",
  },
  components: {},
})
export default class PlushieAlertsListPage extends mixins(
  GeneralListPageMixin
) {
  private static readonly ICON_SIZE = 45;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fAlertStore: AlertStore;
  private fFileStorageDataStore: FileStorageStore;

  get canManageAlerts(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.PLUSHIE_ALERTS_MANAGE);
  }

  public constructor() {
    super();

    this.fAlertStore = getModule(AlertStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);

    this.fColumns = [
      {
        name: "title",
        header: "Title",
        cssClass: "_title-column -text",
        isFilterable: true,
        isSortable: true,
      },
      {
        name: "description",
        header: "Description",
        cssClass: "_description-column -text",
        isFilterable: true,
        isSortable: false,
      },
      {
        name: "icon",
        header: "Icon",
        displayConstraint: "min_desktop",
        cssClass: "_icon-column",
      },
      {
        name: "displayToFactory",
        header: "Display to Factory",
        displayConstraint: "min_desktop",
        cssClass: "_displaytofactory-column -checkbox",
      },
      {
        name: "isNotifyAboutMovements",
        header: "Notify About Movements",
        displayConstraint: "min_desktop",
        cssClass: "_notify-about-movements-column -checkbox",
      },
      {
        name: "actions",
        header: "Action",
        cssClass: "_actions-column",
        displayConstraint: "min_tabletL",
      },
    ];

    this.fOptions = {
      filterByColumn: true,
      debounce: 1000,
      orderBy: {
        column: "title",
        ascending: true,
      },
      initialPage: 1,
      perPage: 30,
      perPageValues: [30, 50, 100, 150],
    };
  }

  public async deleteItem(id: string): Promise<void> {
    if (!confirm("Are you sure?")) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.fAlertStore.deletePlushieAlertById(id);

      if (this.fTable) {
        this.fTable.refresh();
      }
    } catch (e) {
      alert(e.message);
      this.fIsLoading = false;
      throw e;
    }
  }

  public getThumbnail(iconStorageItemId?: string): string {
    const storageItem = iconStorageItemId
      ? this.fFileStorageDataStore.getItemById(iconStorageItemId)
      : null;

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      PlushieAlertsListPage.ICON_SIZE,
      PlushieAlertsListPage.ICON_SIZE,
      true,
      ImageHandlerOutputFormat.PNG
    );
  }

  public onRowClick(data: RowClickEventData<TableRow>): void {
    if (!this.canManageAlerts) {
      return;
    }

    const itemId = data.row.id;

    void this.$router.push(this.getItemUrl(itemId, "PlushieAlertEditPage"));
  }

  protected async fetchFromServer(
    page: number,
    limit: number,
    filter: Dictionary<ApiFilterValue>,
    order: QueryOrderParameter
  ): Promise<ServerResponse> {
    const alertsCollection = await this.fAlertStore.loadPlushieAlerts({
      page,
      limit,
      filter,
      order,
    });

    const iconStorageItemIds: string[] = [];

    const result: TableRow[] = [];

    alertsCollection.getItems().forEach((alert) => {
      result.push({
        id: alert.id,
        title: alert.title,
        description: alert.description || "",
        icon: alert.iconStorageId,
        displayToFactory: alert.displayToFactory,
        isNotifyAboutMovements: alert.isNotifyAboutMovements,
      });

      iconStorageItemIds.push(alert.iconStorageId);
    });

    await this.fFileStorageDataStore.loadItemsByIds(iconStorageItemIds);

    return { data: result, count: alertsCollection.totalItemsCount };
  }

  protected getExtraParams(): Dictionary<string | number> {
    return {
      alertType: AlertType.TAG_BASED,
    };
  }
}
