




























import { Component, Prop, Vue, Inject, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
// Import Vue FilePond
import vueFilePond, { VueFilePondComponent } from "vue-filepond";
import { FileStatus } from "filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import image preview and file type validation plugins
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import ErrorConverterService from "@/modules/forms/error-converter.service";
import FileStorageStore from "@/modules/file-storage/store";
import PlushieStore from "@/modules/plushie/store";
import dataStore from "@/store";
import { FileProcessingImageType } from "@/modules/file-storage/file-processing-image-type";
import { Dictionary } from "@/lib/Dictionary.type";

// Create component
const FilePondComponent = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

@Component({
  components: {
    MoonLoader,
    FilePond: FilePondComponent,
  },
})
export default class MessageAttachmentUpload extends Vue {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fPlushieDataStore: PlushieStore;
  private fFileStorageDataStore: FileStorageStore;

  private fRemoveRequestsCount = 0;
  private fIsBusy = false;

  get isBusy(): boolean {
    return this.fIsBusy || this.fRemoveRequestsCount > 0;
  }

  constructor() {
    super();
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public clearInput(): void {
    const fileInput = this.getFileInput();
    if (!fileInput) {
      return;
    }

    fileInput.removeFiles();
  }

  public browseFiles(): void {
    const fileInput = this.getFileInput();
    if (!fileInput || this.disabled) {
      return;
    }

    fileInput.browse();
  }

  public async detectFileType(source: File, type: string): Promise<string> {
    if (type) {
      return type;
    }

    const extension = source.name.toLowerCase().split(".").pop();
    if (!extension) {
      return "";
    }

    return "image/" + extension;
  }

  public processUpload(
    fieldName: string,
    file: File,
    metadata: Dictionary<any>,
    load: (id: string) => void,
    error: (errorText: string) => void,
    progress: (
      lengthComputable: boolean,
      loaded: number,
      total: number
    ) => void,
    abort: () => void
    // transfer: (transferId: string) => void,
    // options: any
  ): { abort: () => void } | void {
    if (this.disabled) {
      error("Operations are disabled!");
      return;
    }

    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);
    if (!plushie) {
      error("Plushie not found!!!");
      return;
    }

    let isAborted = false;

    this.fFileStorageDataStore
      .uploadFile({
        file,
        imageType: FileProcessingImageType.MessageAttachment,
        product: plushie.product,
        onUploadProgress: (e: ProgressEvent) => {
          progress(e.lengthComputable, e.loaded, e.total);
        },
      })
      .then((item) => {
        if (isAborted) {
          return;
        }

        load(item.id);
        this.$emit("attachmentAdded", item);
      })
      .catch((err) => {
        const errors = this.fErrorConverterService.describeError(err);
        error(errors[0]);
      });

    return {
      abort: () => {
        isAborted = true;
        abort();
      },
    };
  }

  public async processRevert(
    storageItemId: string,
    load: () => void,
    error: (errorText: string) => void
  ): Promise<void> {
    if (this.disabled) {
      return;
    }

    this.fRemoveRequestsCount++;

    try {
      await this.fFileStorageDataStore.deleteItemById(storageItemId);

      const fileInput = this.getFileInput();
      if (!fileInput) {
        return;
      }

      load();

      this.$emit("attachmentRemoved", storageItemId);
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      error(errors[0]);
    } finally {
      this.fRemoveRequestsCount--;
    }
  }

  public onFileProcessed(): void {
    this.updateStatus();
  }

  public onFileProcessStart(): void {
    this.updateStatus();
  }

  public onAllFilesProcessed(): void {
    this.updateStatus();
  }

  public onFileAbort(): void {
    this.updateStatus();
  }

  public onFileAdded(error: Error): void {
    if (error) {
      return;
    }

    this.updateStatus();
  }

  public onFileAddStart(): void {
    this.updateStatus();
  }

  protected mounted(): void {
    const dropzone = this.getDropzone();
    const dropzoneOverlay = this.getDropzoneOverlay();

    if (!dropzone || !dropzoneOverlay) {
      return;
    }

    dropzone.addEventListener("dragover", this.fDragHoverHandler, {
      capture: true,
    });

    dropzoneOverlay.addEventListener("dragleave", this.fDragHoverHandler, {
      capture: true,
    });

    dropzoneOverlay.addEventListener("drop", this.fDragDropHandler, {
      capture: true,
    });
  }

  private getDropzone(): HTMLElement | undefined {
    return this.$refs["dropzone"] as HTMLElement;
  }

  private getDropzoneOverlay(): HTMLElement | undefined {
    return this.$refs["dropzone-overlay"] as HTMLElement;
  }

  private getFileInput(): VueFilePondComponent | undefined {
    return (this.$refs["file-input"] as unknown) as VueFilePondComponent;
  }

  private updateStatus(): void {
    const fileInput = this.getFileInput();
    if (!fileInput) {
      this.fIsBusy = false;
      return;
    }

    const files = fileInput.getFiles();
    let isBusy = false;

    for (const file of files) {
      if (
        [
          FileStatus.PROCESSING,
          FileStatus.LOADING,
          FileStatus.PROCESSING_QUEUED,
        ].includes(file.status)
      ) {
        isBusy = true;
        break;
      }
    }

    this.fIsBusy = isBusy;
  }

  private onDropzoneDragHover(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const dropzone = this.getDropzone();
    if (!dropzone) {
      return;
    }

    if (e.type == "dragover" && !this.disabled) {
      dropzone.classList.add("-drag-hover");
    } else {
      dropzone.classList.remove("-drag-hover");
    }
  }

  private async onDropzoneDrop(e: DragEvent): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    const dropzone = this.getDropzone();
    if (dropzone) {
      dropzone.classList.remove("-drag-hover");
    }

    const fileInput = this.getFileInput();
    if (!e.dataTransfer || !fileInput || this.disabled) {
      return;
    }

    const droppedFiles = Array.from(e.dataTransfer.files);

    try {
      await fileInput.addFiles(droppedFiles);
    } catch (e) {
      //
    }
  }

  private fDragHoverHandler = (e: DragEvent) => this.onDropzoneDragHover(e);
  private fDragDropHandler = (e: DragEvent) => this.onDropzoneDrop(e);

  @Watch("isBusy")
  private _onIsBusyChange(newValue: boolean): void {
    this.$emit("isBusyChanged", newValue);
  }
}
