










import { Component, Prop, Vue } from "vue-property-decorator";

import LoginForm from "./LoginForm.vue";

@Component({
  metaInfo: {
    title: "Login",
  },
  components: {
    LoginForm,
  },
})
export default class LoginPage extends Vue {
  @Prop()
  public redirect?: string;
}
