import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PlushieTagRelation implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fTag: string,
    private fIsPromoted: boolean
  ) {
    [fId, fPlushie, fTag, fIsPromoted].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id() {
    return this.fId;
  }

  get isPromoted() {
    return this.fIsPromoted;
  }

  get plushie() {
    return this.fPlushie;
  }

  get tag() {
    return this.fTag;
  }
}
