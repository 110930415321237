import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import OperatorSession from "./operator-session.model";

export default class OperatorSessionRepository extends BasicRepository<OperatorSession> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<OperatorSession>,
    responseParser: ApiCollectionResponseParserService<OperatorSession>
  ) {
    super("operator_sessions", api, objectBuilder, responseParser);
  }

  protected prepareData(item: OperatorSession): Dictionary<string | number> {
    const data: Dictionary<string | number> = {
      id: item.id,
      plushieId: item.plushie,
      plushieStatusId: item.plushieStatus,
      timeSpent: item.timeSpent,
    };

    return data;
  }
}
