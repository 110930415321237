import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ShippingInformation from "./shipping-information.model";

export default class ShippingInformationFactory
  implements ObjectBuilderInterface<ShippingInformation> {
  public buildFromJSON(data: { [key: string]: any }): ShippingInformation {
    const value = new ShippingInformation(
      data.id,
      data.methodName,
      data.firstName,
      data.country,
      data.streetAddress1,
      data.lastName || "",
      data.companyName || "",
      data.streetAddress2 || "",
      data.city || "",
      data.postalCode || "",
      data.phone || "",
      data.state || ""
    );

    value.isNew = false;

    return value;
  }
}
