













import { Component, Prop } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";

import ShippingInformationEditForm from "./EditForm.vue";

@Component({
  components: {
    ShippingInformationEditForm,
  },
})
export default class ShippingInformationEditDialog extends DialogComponent<void> {
  @Prop({ required: true })
  public readonly plushieId!: string;
}
