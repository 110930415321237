import MassActionServiceInterface from "./mass-action-service.interface";
import PlushiesArtworksExportJobRepository from "./plushies-artworks-export-job.repository";

export default class BulkPlushiesArtworksDownloadService
  implements MassActionServiceInterface {
  public static readonly ID = "bulkPlushiesArtworksDownload";

  private fPlushiesArtworksExportJobRepository: PlushiesArtworksExportJobRepository;
  private fWindow: Window;

  constructor(
    plushiesArtworksExportJobRepository: PlushiesArtworksExportJobRepository,
    window: Window
  ) {
    this.fPlushiesArtworksExportJobRepository = plushiesArtworksExportJobRepository;
    this.fWindow = window;
  }

  public async execute(plushieIds: string[]): Promise<boolean> {
    if (plushieIds.length < 1) {
      return false;
    }

    const downloadUrl = await this.fPlushiesArtworksExportJobRepository.createPlushiesArtworksExportJob(
      plushieIds
    );

    this.fWindow.location.href = downloadUrl;

    return true;
  }
}
