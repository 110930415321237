import { MutableValue } from "../api/mutable-value.interface";

export default class PlushieRelation implements MutableValue {
  public isNew = true;

  private fCreatedAt?: Date;

  constructor(private fId: string, private fPeriod: string) {
    [fId, fPeriod].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get period(): string {
    return this.fPeriod;
  }

  get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }
}
