













































































































































































































import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";

import rootStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import PlushieStore from "@/modules/plushie/store";
import ShippingInformation from "@/modules/plushie/shipping-information.model";
import CountryStore from "@/modules/country/store";
import Country from "@/modules/country/country.model";
import CountryRegion from "@/modules/country/country-region.model";

@Component({
  components: {
    FormErrors,
    FormField,
    MoonLoader,
    SubmitButton,
  },
})
export default class ShippingInformationEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  public firstName?: string;
  public lastName?: string;
  public companyName?: string;
  public streetAddress1?: string;
  public streetAddress2?: string;
  public city?: string;
  public postalCode?: string;
  public countryCode?: string;
  public phone?: string;
  public region?: string;

  private fCountryStore: CountryStore;
  private fPlushieStore: PlushieStore;

  private fIsLoading = true;
  private fIsCountryRegionsLoading = false;

  get countryOptions(): Country[] {
    return this.fCountryStore.countries;
  }

  get selectedCountry(): Country | undefined {
    if (!this.countryCode) {
      return;
    }

    return this.fCountryStore.getCountryByCode(this.countryCode);
  }

  get isRegionRelatedFieldsDisabled(): boolean {
    return this.isDisabled || this.fIsCountryRegionsLoading;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get isSelectedCountryHasRegions(): boolean {
    return !!this.regionsList && this.regionsList.length > 0;
  }

  get shippingInformation(): ShippingInformation | undefined {
    const shippingInformation = this.fPlushieStore.getShippingInformationByPlushieId(
      this.plushieId
    );

    return shippingInformation;
  }

  get regionsList(): undefined | CountryRegion[] {
    if (
      !this.shippingInformation ||
      !this.selectedCountry ||
      this.fIsCountryRegionsLoading
    ) {
      return;
    }

    return this.fCountryStore.getCountryRegionsListByCountryId(
      this.selectedCountry.id
    );
  }

  constructor() {
    super();
    this.fCountryStore = getModule(CountryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  public onCountryChange(): void {
    if (!this.selectedCountry) {
      return;
    }

    this.region = undefined;

    void this.loadCountryRegions(this.selectedCountry.id);
  }

  protected data(): Record<string, unknown> {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      companyName: this.companyName,
      streetAddress1: this.streetAddress1,
      streetAddress2: this.streetAddress2,
      city: this.city,
      postalCode: this.postalCode,
      countryCode: this.countryCode,
      phone: this.phone,
      region: this.region,
    };
  }

  protected async created(): Promise<void> {
    await this.loadData();
    this.prefillFormData();
  }

  protected async performSubmit(): Promise<void> {
    const shippingInformation = this.getShippingInformationInstance();

    if (!shippingInformation) {
      return;
    }

    await this.fPlushieStore.saveShippingInformation(shippingInformation);

    this.$emit("close");
  }

  private getShippingInformationInstance(): ShippingInformation | undefined {
    if (!this.shippingInformation) {
      throw new Error("Shipping information is not defined");
    }

    if (
      !this.firstName ||
      !this.lastName ||
      !this.streetAddress1 ||
      !this.city ||
      !this.postalCode ||
      !this.countryCode
    ) {
      return;
    }

    return new ShippingInformation(
      this.plushieId,
      this.shippingInformation.methodName,
      this.firstName,
      this.countryCode,
      this.streetAddress1,
      this.lastName,
      this.companyName || "",
      this.streetAddress2 || "",
      this.city,
      this.postalCode,
      this.phone || "",
      this.region || ""
    );
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    const [shippingInformation] = await Promise.all([
      this.fPlushieStore.loadShippingInformationByPlushieId(this.plushieId),
      this.fCountryStore.loadCountries(),
    ]);

    if (!shippingInformation) {
      this.fIsLoading = false;
      return;
    }

    const country = this.fCountryStore.getCountryByCode(
      shippingInformation.country
    );

    if (!country) {
      this.fIsLoading = false;
      return;
    }

    await this.loadCountryRegions(country.id);

    this.fIsLoading = false;
  }

  private async loadCountryRegions(countryId: string): Promise<void> {
    this.fIsCountryRegionsLoading = true;

    await this.fCountryStore.loadCountryRegionsByCountryId({
      countryId,
    });

    this.fIsCountryRegionsLoading = false;
  }

  private prefillFormData(): void {
    if (!this.shippingInformation) {
      return;
    }

    this.firstName = this.shippingInformation.firstName;
    this.lastName = this.shippingInformation.lastName;
    this.companyName = this.shippingInformation.companyName;
    this.streetAddress1 = this.shippingInformation.streetAddress1;
    this.streetAddress2 = this.shippingInformation.streetAddress2;
    this.city = this.shippingInformation.city;
    this.postalCode = this.shippingInformation.postalCode;
    this.countryCode = this.shippingInformation.country;
    this.phone = this.shippingInformation.phone;
    this.region = this.shippingInformation.state;
  }
}
