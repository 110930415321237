import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import AssetsUploadPage from "./components/AssetsUploadPage.vue";
import RejectionReasonsListPage from "./components/RejectionReasonsListPage.vue";
import RejectionReasonAddPage from "./components/RejectionReasonAddPage.vue";
import RejectionReasonEditPage from "./components/RejectionReasonEditPage.vue";
import UseOfRejectionReasonPage from "./components/UseOfRejectionReason/Page.vue";

export default [
  {
    path: "/quality_inspection/upload_photos",
    name: "QAAssetsUploadPage",
    component: AssetsUploadPage,
    meta: {
      auth: true,
    },
  },
  {
    path: "/rejection_reason/list",
    name: "RejectionReasonsListPage",
    component: RejectionReasonsListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/rejection_reason/edit",
    name: "RejectionReasonEditPage",
    component: RejectionReasonEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["reasonId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/rejection_reason/add",
    name: "RejectionReasonAddPage",
    component: RejectionReasonAddPage,
    props: (route: Route) => {
      return listItemPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/reports/use_of_rejection_reason",
    name: "UseOfRejectionReasonPage",
    component: UseOfRejectionReasonPage,
    meta: {
      auth: true,
    },
  },
];
