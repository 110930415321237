import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Payment from "./payment.model";

export default class PaymentFactory implements ObjectBuilderInterface<Payment> {
  public buildFromJSON(data: { [key: string]: any }): Payment {
    const value = new Payment(
      data.id,
      data.invoice,
      new Date(data.paidAt),
      data.type,
      data.confirmationNumber
    );

    value.notes = data.notes;

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUser = data.userId;

    value.isNew = false;

    return value;
  }
}
