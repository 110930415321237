export default class BarcodePrintJob {
  public constructor(private fPlushieId: string) {
    [fPlushieId].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get plushieId() {
    return this.fPlushieId;
  }
}
