














































import { Component, Vue } from "vue-property-decorator";
import { EmojiPicker } from "vue-emoji-picker";

@Component({
  components: {
    EmojiPicker,
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
})
export default class MessagesSendingFormEmojiPicker extends Vue {
  data(): Record<string, unknown> {
    return {
      input: "",
      search: "",
    };
  }

  append(emoji: string): void {
    this.$emit("picked", emoji);
  }
}
