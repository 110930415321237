import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import FactoryPlushieStatusCapacity from "./factory-plushie-status-capacity.model";

export default class FactoryPlushieStatusCapacityFactory
  implements ObjectBuilderInterface<FactoryPlushieStatusCapacity> {
  public buildFromJSON(data: {
    [key: string]: any;
  }): FactoryPlushieStatusCapacity {
    const value = new FactoryPlushieStatusCapacity(
      data.id,
      data.factory,
      data.plushieStatus.toString(),
      +data.value,
      data.usePullMode == true
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
