











































































































import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { saveAs } from "file-saver";

import ActionButton from "@/lib/components/ActionButton.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import ListItemPageNavigation from "@/lib/components/ListItemPageNavigation.vue";
import TrackingNumber from "@/lib/components/TrackingNumber.vue";
import EditableText from "@/modules/plushie/components/EditableText.vue";

import dataStore from "@/store";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import FactoryStore from "@/modules/factory/store";
import FileStorageStore from "@/modules/file-storage/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import ResponseFileNameResolver from "@/modules/general/response-file-name-resolver.service";
import FactoryInvoiceStore from "@/modules/factory-invoice/store";
import FactoryInvoice from "@/modules/factory-invoice/factory-invoice.model";

import PublishAction from "./BulkShipmentViewPage/PublishAction.vue";
import ItemsList from "./BulkShipmentViewPage/ItemsList.vue";

import BulkShipmentRepository from "../bulk-shipment.repository";
import BulkShipment from "../bulk-shipment.model";
import BulkShipmentStore from "../store/index";
import { BulkShipmentStatusValue } from "../bulk-shipment-status.value";

@Component({
  metaInfo() {
    const component = this as BulkShipmentViewPage;

    return {
      title: component.bulkShipment
        ? "Bulk Shipment #" + component.bulkShipment.id
        : "Bulk Shipment",
    };
  },
  components: {
    ActionButton,
    EditableText,
    ItemsList,
    LoadingSpinner,
    ListItemPageNavigation,
    PublishAction,
    TrackingNumber,
  },
})
export default class BulkShipmentViewPage extends Vue {
  @Prop({ required: true })
  public readonly bulkShipmentId!: string;

  @Prop()
  public readonly query!: Record<string, any>;

  @Prop()
  public readonly listQuery?: Record<string, any>;

  @Inject("AuthenticatedUserProvider")
  private fAuthenticatedUserProvider!: AuthenticatedUserProvider;

  @Inject("BulkShipmentRepository")
  private fBulkShipmentRepository!: BulkShipmentRepository;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fIsLoading = false;

  private fBulkShipmentStore: BulkShipmentStore;
  private fFactoryDataStore: FactoryStore;
  private fFileStorageDataStore: FileStorageStore;
  private fFactoryInvoiceDataStore: FactoryInvoiceStore;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get factoryName(): string {
    if (!this.bulkShipment) {
      return "";
    }

    const factoryId = this.bulkShipment.factory;

    const factory = this.fFactoryDataStore.getFactoryById(factoryId);

    return factory ? factory.name : "";
  }

  get canEditTrackingNumber(): boolean {
    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_SHIPMENTS_MANAGE);
  }

  get canPublish(): boolean {
    if (!this.bulkShipment) {
      return false;
    }

    if (this.bulkShipment.status !== BulkShipmentStatusValue.DRAFT) {
      return false;
    }

    const user = this.fAuthenticatedUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BULK_SHIPMENTS_MANAGE);
  }

  get isPublished(): boolean {
    if (!this.bulkShipment) {
      return false;
    }

    return this.bulkShipment.status === BulkShipmentStatusValue.PUBLISHED;
  }

  get invoice(): FactoryInvoice | undefined {
    if (!this.bulkShipment) {
      return undefined;
    }

    const invoice = this.fFactoryInvoiceDataStore.getFactoryInvoiceByShipmentId(
      this.bulkShipment.id
    );

    return invoice;
  }

  get shipmentDate(): string {
    if (!this.bulkShipment) {
      return "";
    }

    return this.bulkShipment.createdAt
      ? this.bulkShipment.createdAt.toLocaleDateString()
      : "";
  }

  get statusName(): string {
    if (!this.bulkShipment) {
      return "";
    }

    const status = this.fBulkShipmentStore.getStatusById(
      this.bulkShipment.status
    );

    return status ? status.name : "";
  }

  get trackingNumber(): string {
    return this.bulkShipment?.trackingNumber || "";
  }

  get bulkShipment(): BulkShipment | undefined {
    return this.fBulkShipmentStore.getBulkShipmentById(this.bulkShipmentId);
  }

  constructor() {
    super();

    this.fBulkShipmentStore = getModule(BulkShipmentStore, dataStore);
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fFactoryInvoiceDataStore = getModule(FactoryInvoiceStore, dataStore);
  }

  public async downloadAirwayBill(): Promise<void> {
    if (!this.bulkShipment || !this.bulkShipment.airwayBill) {
      return;
    }

    const storageItem = this.fFileStorageDataStore.getItemById(
      this.bulkShipment.airwayBill
    );

    if (!storageItem) {
      return;
    }

    const originalUrl = this.fImageHandlerService.getOriginalImageUrl(
      storageItem.url
    );

    const response = await fetch(originalUrl);

    if (!response.ok) {
      throw new Error("Failed to download the airway bill");
    }

    const blobBody = await response.blob();

    const fileName =
      storageItem.url.split("/").pop() ||
      `airway-bill-${this.bulkShipmentId}.jpg`;

    saveAs(blobBody, fileName);
  }

  public async downloadCsv(): Promise<void> {
    const response = await this.fBulkShipmentRepository.getCsvByShipmentId(
      this.bulkShipmentId
    );

    const blob = new Blob([response.data], {
      type: "text/csv",
    });

    const fileName =
      ResponseFileNameResolver.resolve(response) ||
      `shipment_${this.bulkShipmentId}.csv`;

    saveAs(blob, fileName);
  }

  public getBulkShipmentsOrder(): Promise<string[]> {
    return this.fBulkShipmentRepository.getListOfIds(
      this.query.order,
      this.query.filter
    );
  }

  public async updateTrackingNumber(value?: string): Promise<void> {
    if (!value || !this.bulkShipment) {
      return;
    }

    await this.fBulkShipmentStore.editBulkShipment({
      id: this.bulkShipment.id,
      airwayBill: this.bulkShipment.airwayBill,
      trackingNumber: value,
    });
  }

  protected created(): void {
    void Promise.all([
      this.fFactoryDataStore.loadFactories(),
      this.fBulkShipmentStore.loadStatuses(),
    ]);
  }

  private async getBulkShipmentData(bulkShipmentId: string) {
    const [bulkShipment] = await Promise.all([
      this.fBulkShipmentStore.loaBulkShipmentById({
        id: bulkShipmentId,
      }),
      this.fFactoryInvoiceDataStore.loadFactoryInvoicesByShipmentIds({
        shipmentIds: [bulkShipmentId],
      }),
    ]);

    if (bulkShipment && bulkShipment.airwayBill) {
      await this.fFileStorageDataStore.loadItemsByIds([
        bulkShipment.airwayBill,
      ]);
    }
  }

  @Watch("bulkShipmentId", { immediate: true })
  private async _onBulkShipmentIdChange() {
    if (!this.bulkShipmentId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getBulkShipmentData(this.bulkShipmentId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
