



























































import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import IdGenerator from "@/lib/services/id-generator";

import BodyPartStore from "../../store";
import { ValueType } from "../../value-type";
import BodyPart from "../../body-part.model";
import PlushieValueRelation from "../../plushie-value-relation.model";
import Value from "../../value.model";

@Component({
  name: "BodyPartEditFormItem",
  components: {},
})
export default class BodyPartEditFormItem extends Vue {
  private static readonly THUMBNAIL_SIZE = 90;

  @Prop({ required: true })
  public readonly bodyPart!: BodyPart;

  @Prop({ default: () => [] })
  public readonly childrenBodyParts!: BodyPart[];

  @Prop({ required: true })
  public readonly plushieId!: string;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fBodyPartDataStore: BodyPartStore;
  private fFileStorageDataStore: FileStorageStore;

  private fIsLoading = false;
  private fShowDetailedBodyParts = false;

  private fCurrentValueIds: string[] = [];
  private fError?: string;

  get error(): string | undefined {
    return this.fError;
  }

  get canBeDetailed(): boolean {
    return this.bodyPart.canBeDetailed && this.childrenBodyParts.length > 0;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get relations(): PlushieValueRelation[] {
    return this.fBodyPartDataStore.getPlushieValueRelationsByPlushieIdAndBodyPartId(
      this.plushieId,
      this.bodyPart.id
    );
  }

  get childrenRelations(): PlushieValueRelation[] {
    const relations: PlushieValueRelation[] = [];

    this.childrenBodyParts.forEach((bodyPart) => {
      relations.push(
        ...this.fBodyPartDataStore.getPlushieValueRelationsByPlushieIdAndBodyPartId(
          this.plushieId,
          bodyPart.id
        )
      );
    });

    return relations;
  }

  get showDetailedBodyParts(): boolean {
    return this.canBeDetailed && this.fShowDetailedBodyParts;
  }

  set showDetailedBodyParts(value: boolean) {
    this.fShowDetailedBodyParts = value;
  }

  get showDetailedCheckbox(): boolean {
    return this.canBeDetailed;
  }

  get values(): Value[] {
    const values = this.fBodyPartDataStore.getBodyPartValuesByBodyPartId(
      this.bodyPart.id
    );

    const displayValues: Value[] = [];
    values.forEach((value) => {
      if (value.isEnabled) {
        displayValues.push(value);
        return;
      }

      if (this.isValueChecked(value)) {
        displayValues.push(value);
      }
    });

    return displayValues;
  }

  constructor() {
    super();
    this.fBodyPartDataStore = getModule(BodyPartStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  isValueChecked(value: Value): boolean {
    if (!this.relations) {
      return false;
    }

    const relation = this.relations.find(
      (relation) => relation.value === value.id
    );

    return relation !== undefined;
  }

  isCurrentValue(value: Value): boolean {
    if (!this.fCurrentValueIds) {
      return false;
    }

    return this.fCurrentValueIds.includes(value.id);
  }

  async onValueClick(value: Value): Promise<void> {
    if (this.relations === undefined) {
      return;
    }

    if (this.fIsLoading || !value.isEnabled) {
      return;
    }

    this.fIsLoading = true;
    this.fError = undefined;

    try {
      if (this.bodyPart.maxValues > 1) {
        await this.processMultiValueClick(value);
      } else {
        await this.processSingleValueClick(value);
      }
    } catch (e) {
      this.fError = e.message;
    } finally {
      this.fIsLoading = false;
    }
  }

  resolveValueIconStyle(value: Value): string {
    switch (value.type) {
      case ValueType.COLOR: {
        const color = value.color || "#000000";

        return `background-color: ${color}; border-radius: 50%; border: 1px solid black;`;
      }
      case ValueType.IMAGE: {
        const thumbnailUrl = this.getValueThumbnail(value);

        return `background-image: url(${thumbnailUrl})`;
      }
    }

    return "";
  }

  protected data(): Record<string, unknown> {
    return {
      fError: this.fError,
    };
  }

  protected mounted(): void {
    if (!this.relations) {
      return;
    }

    this.fCurrentValueIds = this.relations.map((relation) => relation.value);
    this.showDetailedBodyParts =
      !this.relations.length && !!this.childrenRelations.length;
  }

  private async createRelation(value: Value): Promise<PlushieValueRelation> {
    const relation = new PlushieValueRelation(
      this.fIdGenerator.getId(),
      this.plushieId,
      value.id
    );

    relation.isNew = true;

    return this.fBodyPartDataStore.savePlushieValueRelation(relation);
  }

  private async deleteRelation(relation: PlushieValueRelation): Promise<void> {
    return this.fBodyPartDataStore.deletePlushieValueRelation(relation.id);
  }

  private getValueThumbnail(value: Value): string {
    if (!value.storageItem) {
      return this.fImageHandlerService.getThumbnailUrl(
        this.fFileStorageDataStore.placeholderUrl,
        BodyPartEditFormItem.THUMBNAIL_SIZE,
        BodyPartEditFormItem.THUMBNAIL_SIZE,
        false
      );
    }

    const storageItem = this.fFileStorageDataStore.getItemById(
      value.storageItem
    );

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      BodyPartEditFormItem.THUMBNAIL_SIZE,
      BodyPartEditFormItem.THUMBNAIL_SIZE,
      false
    );
  }

  private async processSingleValueClick(value: Value): Promise<void> {
    const isChecked = this.isValueChecked(value);
    if (isChecked) {
      return;
    }

    if (this.relations.length) {
      await this.updateRelation(this.relations[0], value);
      return;
    }

    await this.createRelation(value);
  }

  private async processMultiValueClick(value: Value): Promise<void> {
    const isChecked = this.isValueChecked(value);

    if (isChecked) {
      const relation = this.relations.find(
        (relation) => relation.value === value.id
      );

      if (!relation) {
        throw new Error(
          "Something went wrong! We are unable to unset this value..."
        );
      }

      await this.deleteRelation(relation);
      return;
    }

    if (this.relations.length >= this.bodyPart.maxValues) {
      throw new Error(
        `This body part can't have more than ${this.bodyPart.maxValues} values`
      );
    }

    await this.createRelation(value);
  }

  private async updateRelation(
    relation: PlushieValueRelation,
    value: Value
  ): Promise<PlushieValueRelation> {
    return this.fBodyPartDataStore.updateRelationValue({
      relationId: relation.id,
      value: value.id,
    });
  }
}
