



































































































import { Component, Prop, Vue, Inject, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import FormErrors from "@/lib/components/FormErrors.vue";

import dataStore from "@/store";
import { Resource } from "@/modules/account/resource";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import AuthenticatedUser from "@/modules/account/authenticated-user.model";
import ErrorConverterService from "@/modules/forms/error-converter.service";
import PlushieStore from "@/modules/plushie/store";
import { DirectoryValue } from "@/modules/api/directory-value.model";

import { ExtraPaymentAuthorValue } from "../../extra-payment-author.value";
import ExtraPayment from "../../extra-payment.model";
import FactoryInvoiceStore from "../../store/index";
import FactoryInvoice from "../../factory-invoice.model";
import { ExtraPaymentFaultValue } from "../../extra-payment-fault.value";
import { FactoryInvoiceStatusValue } from "../../factory-invoice-status.value";

@Component({
  components: {
    FormErrors,
  },
})
export default class FactoryInvoiceViewExtraPaymentList extends Vue {
  @Prop({ required: true })
  public readonly invoiceId!: string;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  @Inject("ErrorConverterService")
  private fErrorConverterService!: ErrorConverterService;

  private fFactoryInvoiceStore: FactoryInvoiceStore;
  private fPlushieStore: PlushieStore;

  private fErrorsList: string[] = [];
  private fIsDisabled = false;
  private fIsLoading = false;

  get errorsList(): string[] {
    return this.fErrorsList;
  }

  get faultOptions(): DirectoryValue[] {
    const values = [...this.fFactoryInvoiceStore.extraPaymentFaults];
    values.push(new DirectoryValue("", "No one's"));

    return values;
  }

  get isDisabled(): boolean {
    return this.fIsDisabled;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get invoice(): FactoryInvoice | undefined {
    return this.fFactoryInvoiceStore.getFactoryInvoiceById(this.invoiceId);
  }

  get extraPayments(): ExtraPayment[] {
    const items = this.fFactoryInvoiceStore.getExtraPaymentsByInvoiceId(
      this.invoiceId
    );

    items.sort((a, b) => ((a.createdAt || -1) > (b.createdAt || -1) ? 1 : -1));

    return items;
  }

  get isFactoryView(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return true;
    }

    return user.role === AuthenticatedUser.ROLE_FACTORY;
  }

  get showFault(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.role !== AuthenticatedUser.ROLE_FACTORY;
  }

  get isFaultEditable(): boolean {
    if (!this.invoice) {
      return false;
    }

    if (!this.showFault) {
      return false;
    }

    return [
      FactoryInvoiceStatusValue.DRAFT,
      FactoryInvoiceStatusValue.REVIEW,
    ].includes(this.invoice.status);
  }

  get totalQICharges(): number {
    const noOneFaultCharges = this.getTotalByFault(undefined);

    return (
      noOneFaultCharges + this.budsiesFaultCharges + this.customerFaultCharges
    );
  }

  get budsiesFaultCharges(): number {
    return this.getTotalByFault(ExtraPaymentFaultValue.BUDSIES);
  }

  get customerFaultCharges(): number {
    return this.getTotalByFault(ExtraPaymentFaultValue.CUSTOMER);
  }

  constructor() {
    super();

    this.fFactoryInvoiceStore = getModule(FactoryInvoiceStore, dataStore);
    this.fPlushieStore = getModule(PlushieStore, dataStore);
  }

  public canDeletePayment(payment: ExtraPayment): boolean {
    if (!this.invoice) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    if (
      !user.hasPermissionForResource(
        Resource.FACTORY_INVOICE_EXTRA_PAYMENTS_MANAGE
      )
    ) {
      return false;
    }

    if (
      user.role === AuthenticatedUser.ROLE_FACTORY &&
      payment.author !== ExtraPaymentAuthorValue.FACTORY
    ) {
      return false;
    }

    if (
      [AuthenticatedUser.ROLE_ADMIN, AuthenticatedUser.ROLE_STORE].includes(
        user.role
      ) &&
      this.invoice.status === FactoryInvoiceStatusValue.REVIEW
    ) {
      return true;
    }

    return this.invoice.status === FactoryInvoiceStatusValue.DRAFT;
  }

  public async deletePayment(payment: ExtraPayment): Promise<void> {
    if (this.isDisabled) {
      return;
    }

    if (!confirm("Are you sure?")) {
      return;
    }

    this.fErrorsList = [];

    this.fIsDisabled = true;

    try {
      await this.fFactoryInvoiceStore.deleteExtraPayment(payment);
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      this.fErrorsList.push(...errors);
    } finally {
      this.fIsDisabled = false;
    }
  }

  public getStoreItemId(payment: ExtraPayment): string {
    if (!payment.plushie) {
      return "";
    }

    const plushie = this.fPlushieStore.getPlushieById(payment.plushie);

    if (!plushie) {
      return "";
    }

    return plushie.storeItemId;
  }

  public getAuthorName(payment: ExtraPayment): string {
    if (!payment.author) {
      return "";
    }

    const author = this.fFactoryInvoiceStore.getExtraPaymentAuthorById(
      payment.author
    );

    return author ? author.name : "";
  }

  public getFaultName(payment: ExtraPayment): string {
    if (!payment.fault) {
      return "?";
    }

    const fault = this.fFactoryInvoiceStore.getExtraPaymentFaultById(
      payment.fault
    );

    return fault ? fault.name : "?";
  }

  public async updatePaymentFault(
    payment: ExtraPayment,
    faultId: string
  ): Promise<void> {
    if (this.isDisabled) {
      return;
    }

    this.fErrorsList = [];

    this.fIsDisabled = true;

    try {
      await this.fFactoryInvoiceStore.updateExtraPaymentFaultValue({
        id: payment.id,
        fault: faultId,
      });
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);
      this.fErrorsList.push(...errors);
    } finally {
      this.fIsDisabled = false;
    }
  }

  protected created(): void {
    void this.fFactoryInvoiceStore.loadExtraPaymentFaults();
    void this.fFactoryInvoiceStore.loadExtraPaymentAuthors();
  }

  private getTotalByFault(fault?: ExtraPaymentFaultValue): number {
    return this.extraPayments
      .filter((item) => item.fault === fault)
      .reduce((sum, current) => {
        return sum + +current.amount;
      }, 0);
  }

  private async loadData(invoiceId: string) {
    const payments = await this.fFactoryInvoiceStore.loadExtraPaymentsByInvoiceId(
      { invoiceId }
    );

    const plushiesIds = payments
      .map((item) => item.plushie)
      .filter((item): item is string => item !== undefined);

    if (!plushiesIds.length) {
      return;
    }

    await this.fPlushieStore.loadPlushiesByIds(plushiesIds);
  }

  @Watch("extraPayments", { immediate: false })
  private async _onExtraPaymentsChange() {
    if (this.isLoading) {
      return;
    }

    const plushiesIds = this.extraPayments
      .map((item) => item.plushie)
      .filter((item): item is string => item !== undefined);

    if (!plushiesIds.length) {
      return;
    }

    await this.fPlushieStore.loadPlushiesByIds(plushiesIds);
  }

  @Watch("invoiceId", { immediate: true })
  private async _onInvoiceIdChange() {
    if (!this.invoiceId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.loadData(this.invoiceId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
