import type { Dictionary } from "vue-router/types/router";

import ApiService from "@/modules/api/api.service";

import BarcodePrintJob from "./barcode-print-job.model";

export default class BarcodePrintJobRepository {
  constructor(protected api: ApiService) {}

  public async getPdfByPlushieId(plushieId: string): Promise<any> {
    return this.api.get(`barcode/${plushieId}/pdf`, undefined, undefined, {
      responseType: "blob",
    });
  }

  public async save(item: BarcodePrintJob): Promise<void> {
    const data = this.prepareData(item);

    await this.api.post("barcode_print_jobs", data);
  }

  protected prepareData(
    item: BarcodePrintJob
  ): Dictionary<string | number | boolean> {
    return {
      plushieId: item.plushieId,
    };
  }
}
