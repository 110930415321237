import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { DIContainer } from "@/app.container";
import { Dictionary } from "@/lib/Dictionary.type";
import dataStore from "@/store";
import { ApiFilterValue } from "@/modules/api/api-filter-value.type";
import { QueryOrderParameter } from "@/modules/api/query-order-parameter";
import { ResourceCollection } from "@/modules/api/resource.collection";

import RemotePrintingConfig from "../remote-printing-config.model";
import RemotePrintingConfigUpdate from "../remote-printing-config-update.interface";
import RemotePrinter from "../remote-printer.model";
import LabelHeader from "../label-header.model";
import LabelHeaderProductRelation from "../label-header-product-relation.model";

const name = "BarcodeStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    remotePrintingConfig: {},
    remotePrinter: {},
    labelHeader: {},
    labelHeaderProductRelation: {},
    headerProductRelations: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class BarcodeStore extends VuexModule {
  remotePrintingConfig: Dictionary<RemotePrintingConfig> = {};
  remotePrinter: Dictionary<RemotePrinter> = {};
  labelHeader: Dictionary<LabelHeader> = {};
  labelHeaderProductRelation: Dictionary<LabelHeaderProductRelation> = {};

  headerProductRelations: Dictionary<string[]> = {};

  // ################################### REMOTE PRINTING CONFIGS #########################################

  get getRemotePrintingConfigById(): (
    id: string
  ) => RemotePrintingConfig | undefined {
    return (id: string) => {
      return this.remotePrintingConfig[id];
    };
  }

  @Mutation
  updateRemotePrintingConfig(payload: RemotePrintingConfig): void {
    Vue.set(this.remotePrintingConfig, payload.id, payload);
  }

  @Action({ rawError: true })
  async saveRemotePrintingConfig(
    remotePrintingConfig: RemotePrintingConfig
  ): Promise<RemotePrintingConfig> {
    const item = await DIContainer.RemotePrintingConfigRepository.save(
      remotePrintingConfig
    );

    this.updateRemotePrintingConfig(item);

    return item;
  }

  @Action({ rawError: true })
  async editRemotePrintingConfig(
    remotePrintingConfigUpdate: RemotePrintingConfigUpdate
  ): Promise<RemotePrintingConfig> {
    const item = await DIContainer.RemotePrintingConfigRepository.updateConfig(
      remotePrintingConfigUpdate
    );

    this.updateRemotePrintingConfig(item);

    return item;
  }

  @Action({ rawError: true })
  async loadRemotePrintingConfigById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<RemotePrintingConfig | undefined> {
    if (useCache && this.remotePrintingConfig[id]) {
      return this.remotePrintingConfig[id];
    }

    const config = await DIContainer.RemotePrintingConfigRepository.getById(id);

    if (!config) {
      return undefined;
    }

    this.updateRemotePrintingConfig(config);

    return config;
  }

  // ################################### REMOTE PRINTERS #########################################

  get getRemotePrintersList(): RemotePrinter[] {
    const list: RemotePrinter[] = [];

    Object.keys(this.remotePrinter).forEach((id) => {
      list.push(this.remotePrinter[id]);
    });

    list.sort((a, b) => (a.id > b.id ? 1 : -1));

    return list;
  }

  @Mutation
  clearRemotePrinter(): void {
    this.remotePrinter = {};
  }

  @Mutation
  updateRemotePrinter(payload: RemotePrinter): void {
    Vue.set(this.remotePrinter, payload.id, payload);
  }

  @Action({ rawError: true })
  async loadRemotePrinters(useCache = false): Promise<RemotePrinter[]> {
    let items: RemotePrinter[] = [];

    if (useCache && Object.keys(this.remotePrinter).length > 0) {
      Object.keys(this.remotePrinter).forEach((key) => {
        items.push(this.remotePrinter[key]);
      });

      return items;
    }

    this.clearRemotePrinter();

    items = await DIContainer.RemotePrinterRepository.getList();

    items.forEach((item) => {
      this.updateRemotePrinter(item);
    });

    return items;
  }

  // ################################### LABEL HEADERS #########################################

  get getLabelHeaderById(): (id: string) => LabelHeader | undefined {
    return (id: string) => {
      return this.labelHeader[id];
    };
  }

  @Mutation
  removeLabelHeader(id: string): void {
    const headerProductRelationIds = this.headerProductRelations[id];

    if (headerProductRelationIds) {
      headerProductRelationIds.forEach((relationId) => {
        delete this.labelHeaderProductRelation[relationId];
      });
    }

    delete this.headerProductRelations[id];
    delete this.labelHeader[id];
  }

  @Mutation
  updateLabelHeader(payload: LabelHeader): void {
    Vue.set(this.labelHeader, payload.id, payload);
  }

  @Action({ rawError: true })
  async saveLabelHeader(labelHeader: LabelHeader): Promise<LabelHeader> {
    const item = await DIContainer.LabelHeaderRepository.save(labelHeader);

    this.updateLabelHeader(item);

    return item;
  }

  @Action({ rawError: true })
  async deleteLabelHeaderById(id: string): Promise<void> {
    await DIContainer.LabelHeaderRepository.deleteById(id);

    this.removeLabelHeader(id);

    return;
  }

  @Action({ rawError: true })
  async loadLabelHeaderById({
    id,
    useCache = true,
  }: {
    id: string;
    useCache?: boolean;
  }): Promise<LabelHeader | undefined> {
    if (useCache && this.labelHeader[id]) {
      return this.labelHeader[id];
    }

    const item = await DIContainer.LabelHeaderRepository.getById(id);

    if (!item) {
      return;
    }

    this.updateLabelHeader(item);

    return item;
  }

  @Action({ rawError: true })
  async loadLabelHeaders({
    page = 1,
    limit = 20,
    filter,
    order,
  }: {
    page?: number;
    limit?: number;
    filter?: Dictionary<ApiFilterValue>;
    order?: QueryOrderParameter;
  }): Promise<ResourceCollection<LabelHeader>> {
    const collection = await DIContainer.LabelHeaderRepository.getList(
      page,
      limit,
      filter,
      order
    );

    const items = collection.getItems();

    items.forEach((item) => {
      this.updateLabelHeader(item);
    });

    return collection;
  }

  // ################################### LABEL HEADER PRODUCT RELATIONS #########################################
  get getLabelHeaderProductRelationsByHeaderId(): (
    headerId: string
  ) => LabelHeaderProductRelation[] {
    return (headerId: string) => {
      if (!this.headerProductRelations[headerId]) {
        return [];
      }

      const relations: LabelHeaderProductRelation[] = [];

      this.headerProductRelations[headerId].forEach((relationId) => {
        relations.push(this.labelHeaderProductRelation[relationId]);
      });

      return relations;
    };
  }

  get getLabelHeaderProductRelationByHeaderIdAndProductId(): (
    headerId: string,
    productId: string
  ) => LabelHeaderProductRelation | undefined {
    return (headerId: string, productId: string) => {
      if (!this.headerProductRelations[headerId]) {
        return undefined;
      }

      let result: LabelHeaderProductRelation | undefined = undefined;

      this.headerProductRelations[headerId].forEach((relationId) => {
        const relation = this.labelHeaderProductRelation[relationId];

        if (relation.product === productId) {
          result = relation;
          return;
        }
      });

      return result;
    };
  }

  @Mutation
  updateLabelHeaderProductRelation(payload: LabelHeaderProductRelation): void {
    Vue.set(this.labelHeaderProductRelation, payload.id, payload);

    if (this.headerProductRelations[payload.header] === undefined) {
      return;
    }

    if (!this.headerProductRelations[payload.header].includes(payload.id)) {
      this.headerProductRelations[payload.header].push(payload.id);
    }
  }

  @Mutation
  updateLabelHeaderProductRelations({
    headerId,
    relations,
  }: {
    headerId: string;
    relations: LabelHeaderProductRelation[];
  }): void {
    const relationIds: string[] = [];

    relations.forEach((relation) => {
      Vue.set(this.labelHeaderProductRelation, relation.id, relation);
      relationIds.push(relation.id);
    });

    Vue.set(this.headerProductRelations, headerId, relationIds);
  }

  @Mutation
  removeLabelHeaderProductRelation(id: string): void {
    const relation = this.labelHeaderProductRelation[id];

    const headerId = relation.header;

    delete this.labelHeaderProductRelation[id];

    const index = this.headerProductRelations[headerId].indexOf(id);

    if (index !== -1) {
      this.headerProductRelations[headerId].splice(index, 1);
    }
  }

  @Action({ rawError: true })
  async deleteLabelHeaderProductRelation(id: string): Promise<void> {
    await DIContainer.LabelHeaderProductRelationRepository.deleteById(id);

    this.removeLabelHeaderProductRelation(id);
  }

  @Action({ rawError: true })
  async loadLabelHeaderProductRelationByHeaderId({
    headerId,
    useCache = true,
  }: {
    headerId: string;
    useCache?: boolean;
  }): Promise<LabelHeaderProductRelation[]> {
    let relations: LabelHeaderProductRelation[] = [];

    if (useCache && this.headerProductRelations[headerId]) {
      const relationsIds = this.headerProductRelations[headerId];

      relationsIds.forEach((id) => {
        relations.push(this.labelHeaderProductRelation[id]);
      });

      return relations;
    }

    relations = await DIContainer.LabelHeaderProductRelationRepository.getByHeaderId(
      headerId
    );

    this.updateLabelHeaderProductRelations({
      headerId,
      relations,
    });

    return relations;
  }

  @Action({ rawError: true })
  async saveLabelHeaderProductRelation(
    relation: LabelHeaderProductRelation
  ): Promise<LabelHeaderProductRelation> {
    const item = await DIContainer.LabelHeaderProductRelationRepository.save(
      relation
    );

    this.updateLabelHeaderProductRelation(item);

    return item;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
