import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import PermissionResource from "./permission-resource.model";

export default class PermissionResourceFactory
  implements ObjectBuilderInterface<PermissionResource> {
  public buildFromJSON(data: { [key: string]: any }): PermissionResource {
    const value = new PermissionResource(data.id, data.name, data.service);

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
