















import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";

import ProductAllocation from "../../../product-allocation.model";
import { ProductAllocationSlotTypeValue } from "../../../product-allication-slot-type.value";

@Component({})
export default class ProductAllocationEditItem extends Vue {
  @Prop({ required: true })
  public readonly slotType!: ProductAllocationSlotTypeValue;

  @Prop({ required: true })
  public readonly factoryId!: string;

  @Prop({ required: true })
  public readonly productId!: string;

  @Prop({ required: true })
  public readonly id!: string;

  @Prop({ required: true })
  public readonly value!: ProductAllocation;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  public distributionPercent?: number;

  public onChange(): void {
    if (this.distributionPercent === undefined) {
      this.$emit("input", undefined);
      return;
    }

    if (!this.distributionPercent) {
      this.distributionPercent = 0;
    }

    const value = new ProductAllocation(
      this.id,
      this.factoryId,
      this.productId,
      this.distributionPercent,
      this.slotType
    );

    this.$emit("input", value);
  }

  protected data(): Record<string, unknown> {
    return {
      distributionPercent: this.distributionPercent,
    };
  }

  @Watch("value", { immediate: true })
  private _onValueChange() {
    if (!this.value) {
      return;
    }

    this.distributionPercent = this.value.distributionPercent;
  }
}
