







































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import autosize from "v-autosize";

import ColorsAutocomplete from "@/lib/components/ColorsAutocomplete.vue";
import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import autoindent from "@/lib/directives/auto-indent.directive";

@Component({
  components: {
    ColorsAutocomplete,
    FormField,
    FormErrors,
    SubmitButton,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class EditableText extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly value!: string;

  @Prop({ default: false })
  public readonly multiline?: boolean;

  @Prop({ default: false })
  public readonly required!: boolean;

  @Prop({ required: true })
  public readonly saveCallback!: (value?: string | number) => Promise<void>;

  @Prop({ default: "text" })
  public readonly type!: "text" | "number";

  @Prop()
  public readonly step: "number" | undefined;

  public currentValue?: string | number;

  private fIsInEditMode = false;

  get isInEditMode(): boolean {
    return this.fIsInEditMode;
  }

  get showTextValue(): boolean {
    return this.type === "text" && !!this.value;
  }

  public switchToEditMode(): void {
    this.fIsInEditMode = true;
    this.submitErrors = [];

    this.$nextTick(() => {
      this.currentValue = this.value;
    });
  }

  public async onCancelClick(): Promise<void> {
    this.fIsInEditMode = false;
    this.submitErrors = [];

    await this.$validator.reset();

    this.currentValue = undefined;
  }

  protected data(): Record<string, unknown> {
    return {
      currentValue: this.currentValue,
    };
  }

  protected async performSubmit(): Promise<void> {
    await this.saveCallback(this.currentValue);

    this.fIsInEditMode = false;

    await this.$validator.reset();

    this.currentValue = undefined;
  }
}
