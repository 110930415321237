






































import { Component, Vue, Inject } from "vue-property-decorator";

import FactoryPerformanceReport from "@/modules/factory/components/PerformanceReport/Report.vue";
import StatusesSummaries from "@/modules/production-process/components/StatusesSummaries.vue";
import UnreadMessages from "@/modules/messaging/components/UnreadMessages.vue";
import ProductionTimeAlertReport from "@/modules/reports/components/ProductionTimeAlertReport.vue";

import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";

@Component({
  components: {
    FactoryPerformanceReport,
    ProductionTimeAlertReport,
    StatusesSummaries,
    UnreadMessages,
  },
  metaInfo: {
    title: "Dashboard",
  },
})
export default class DashboardPage extends Vue {
  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  get canViewFactoryPerformanceReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.FACTORY_VIEW);
  }

  get canViewProductionTimeAlertReport(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.REPORTING_PRODUCTION_TIME_VIEW
    );
  }

  get isStatusesSummariesAvailable(): boolean {
    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(
      Resource.PRODUCTION_PROCESS_STATUSES_SUMMARIES_READ
    );
  }
}
