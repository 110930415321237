import { MutableValue } from "@/modules/api/mutable-value.interface";

import { DirectShipmentIntentionRuleOrderType } from "./direct-shipment-intention-rule-order-type";

export default class DirectShipmentIntentionRule implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fName: string,
    private fFactory: string,
    private fForOrderType: DirectShipmentIntentionRuleOrderType,
    private fProduct?: string,
    private fUpgrade?: string
  ) {
    [fId, fName, fFactory, fForOrderType].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get name(): string {
    return this.fName;
  }

  get factory(): string {
    return this.fFactory;
  }

  get product(): string | undefined {
    return this.fProduct;
  }

  get upgrade(): string | undefined {
    return this.fUpgrade;
  }

  get forOrderType(): DirectShipmentIntentionRuleOrderType {
    return this.fForOrderType;
  }
}
