import { Route } from "vue-router";

import listPageUrlParser from "@/lib/services/list-page-url-parser.function";
import listItemPageUrlParser from "@/lib/services/list-item-page-url-parser.function";

import AlertAddPage from "./components/AlertAddPage.vue";
import AlertEditPage from "./components/AlertEditPage.vue";
import AlertsListPage from "./components/AlertsListPage.vue";

export default [
  {
    path: "/plushie_alerts/list",
    name: "PlushieAlertsListPage",
    component: AlertsListPage,
    props: (route: Route) => {
      return listPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/plushie_alert/add",
    name: "PlushieAlertAddPage",
    component: AlertAddPage,
    props: (route: Route) => {
      return listItemPageUrlParser(route);
    },
    meta: {
      auth: true,
    },
  },
  {
    path: "/plushie_alert/edit",
    name: "PlushieAlertEditPage",
    component: AlertEditPage,
    props: (route: Route) => {
      const props = listItemPageUrlParser(route);

      if (typeof route.query.id === "string") {
        props["alertId"] = route.query.id;
      }

      return props;
    },
    meta: {
      auth: true,
    },
  },
];
