import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ExtraPayment from "./extra-payment.model";

export default class ExtraPaymentFactory
  implements ObjectBuilderInterface<ExtraPayment> {
  public buildFromJSON(data: { [key: string]: any }): ExtraPayment {
    const value = new ExtraPayment(data.id, +data.amount, data.description);

    value.fault = data.fault ? data.fault : undefined;
    value.plushie = data.plushieId ? data.plushieId : undefined;
    value.invoice = data.invoice ? data.invoice : undefined;

    (value as any).fUser = data.userId;
    (value as any).fAuthor = data.author;
    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
