import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductAllocation from "./product-allocation.model";

export default class ProductAllocationFactory
  implements ObjectBuilderInterface<ProductAllocation> {
  public buildFromJSON(data: { [key: string]: any }): ProductAllocation {
    const value = new ProductAllocation(
      data.id,
      data.factory,
      data.product.toString(),
      data.distributionPercent,
      data.slotType
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    value.isNew = false;

    return value;
  }
}
