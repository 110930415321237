import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import BillingInfoRecord from "./billing-info-record.model";

export default class BillingInfoRecordRepository extends BasicRepository<BillingInfoRecord> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BillingInfoRecord>,
    responseParser: ApiCollectionResponseParserService<BillingInfoRecord>
  ) {
    super("billing_info_records", api, objectBuilder, responseParser);
  }

  public async getByFactoryId(
    factoryId: string,
    useCache = true
  ): Promise<BillingInfoRecord | undefined> {
    const collection = await this.fetchList(
      { factoryId: factoryId },
      1,
      1,
      undefined,
      useCache
    );

    return collection.getItems()[0];
  }

  protected prepareData(item: BillingInfoRecord): Dictionary<string> {
    const data: Dictionary<string> = {
      id: item.id,
      factoryId: item.factory,
      factoryName: item.factoryName,
      city: item.city,
      stateRegion: item.stateRegion,
      postalCode: item.postalCode,
      country: item.country,
    };

    data.address = item.address || "";

    data.additionalAddress = item.additionalAddress || "";

    return data;
  }
}
