












































































































































import { mixins } from "vue-class-component";
import { Component, Inject } from "vue-property-decorator";
import { TableOptions } from "vue-tables-2-premium";
import DatePicker from "vuejs-datepicker";
import { getModule } from "vuex-module-decorators";

import MoonLoader from "vue-spinner/src/MoonLoader.vue";

import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import FactoryStore from "@/modules/factory/store";
import PlushieStore from "@/modules/plushie/store";
import rootStore from "@/store";
import Product from "@/modules/plushie/product.model";
import Factory from "@/modules/factory/factory.model";

import ThroughputReport from "../throughput-report.model";
import ThroughputReportRepository from "../throughput-report.repository";
import { ThroughputReportTimePeriod } from "../throughput-report-time-period";

enum ThroughputReportState {
  INITIAL = "initial",
  INITIAL_DATA_LOADED = "initial-data-loaded",
  DATA_LOADED = "data-loaded",
}

@Component({
  components: {
    DatePicker,
    FormErrors,
    FormField,
    MoonLoader,
    SubmitButton,
  },
})
export default class ThroughputReportPage extends mixins(GeneralFormMixin) {
  @Inject("ThroughputReportRepository")
  private fThroughputReportRepository!: ThroughputReportRepository;

  public timePeriod?: ThroughputReportTimePeriod;
  public productId?: string;
  public factoryId?: string;
  public startDate?: Date;
  public endDate?: Date;

  private fFactoryStore!: FactoryStore;
  private fPlushieStore!: PlushieStore;

  private fThroughputReport: ThroughputReport[] = [];
  private fState: ThroughputReportState = ThroughputReportState.INITIAL;

  get factoryOptionsList(): Factory[] {
    return this.fFactoryStore.activeFactoriesList;
  }

  get productOptionsList(): Product[] {
    return this.fPlushieStore.activeProductsList;
  }

  get showLoadingIndicator(): boolean {
    return this.fState === ThroughputReportState.INITIAL;
  }

  get showReportTable(): boolean {
    return this.fState === ThroughputReportState.DATA_LOADED;
  }

  get tableColumns(): string[] {
    return [
      "timePeriod",
      "assignedToFactory",
      "designed",
      "submittedToInspection",
      "passedInspection",
      "bulkShipped",
    ];
  }

  get tableData(): ThroughputReport[] {
    return this.fThroughputReport;
  }

  get tableOptions(): TableOptions {
    return {
      columnsClasses: {
        timePeriod: "_time-period",
      },
      headings: {
        timePeriod: "Time Period",
        assignedToFactory: "Assigned To Factory",
        designed: "Designed",
        submittedToInspection: "Submitted To Inspection",
        passedInspection: "Passed Inspection",
        bulkShipped: "Bulk Shipped",
      },
      filterable: false,
      toggleGroups: true,
      groupBy: "factoryName",
      orderBy: { column: "factoryName", ascending: true },
      perPage: 30,
      perPageValues: [10, 20, 30, 50, 100],
      sortable: [],
    };
  }

  get timePeriodOptionsList(): ThroughputReportTimePeriod[] {
    return Object.values(ThroughputReportTimePeriod);
  }

  constructor() {
    super();

    this.fFactoryStore = getModule(FactoryStore, rootStore);
    this.fPlushieStore = getModule(PlushieStore, rootStore);
  }

  protected created(): void {
    void this.loadInitialData();
  }

  protected data(): Record<string, unknown> {
    return {
      timePeriod: this.timePeriod,
      productId: this.productId,
      factoryId: this.factoryId,
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  protected async performSubmit(): Promise<void> {
    if (!this.timePeriod || !this.startDate) {
      throw new Error("Not all required fields are filled!");
    }

    if (this.endDate && this.startDate && this.endDate < this.startDate) {
      throw new Error("'End Date' can't be less than 'Start Date'");
    }

    let startDate;
    let endDate;

    if (this.startDate) {
      startDate = new Date(this.startDate);
      startDate.setHours(0, 0, 0, 0);
      startDate.setHours(
        startDate.getHours() - startDate.getTimezoneOffset() / 60
      );
    }

    if (this.endDate) {
      endDate = new Date(this.endDate);
      endDate.setHours(23, 59, 59, 999);
      endDate.setHours(endDate.getHours() - endDate.getTimezoneOffset() / 60);
    }

    await this.loadTableData(
      this.timePeriod,
      this.factoryId,
      startDate,
      endDate,
      this.productId
    );
  }

  private async loadInitialData(): Promise<void> {
    await Promise.all([
      this.fPlushieStore.loadProducts(),
      this.fFactoryStore.loadFactories(),
    ]);

    this.fState = ThroughputReportState.INITIAL_DATA_LOADED;
  }

  private async loadTableData(
    timePeriod: ThroughputReportTimePeriod,
    factoryId?: string,
    startDate?: Date,
    endDate?: Date,
    productId?: string
  ): Promise<void> {
    this.fThroughputReport = await this.fThroughputReportRepository.getList(
      timePeriod,
      factoryId,
      startDate,
      endDate,
      productId
    );

    this.fState = ThroughputReportState.DATA_LOADED;
  }
}
