import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import UserFactoryRelation from "./user-factory-relation.model";

export default class UserFactoryRelationRepository extends BasicRepository<UserFactoryRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<UserFactoryRelation>,
    responseParser: ApiCollectionResponseParserService<UserFactoryRelation>
  ) {
    super("user_factory_relations", api, objectBuilder, responseParser);
  }

  public async getByUserId(
    userId: string
  ): Promise<UserFactoryRelation | undefined> {
    const collection = await this.fetchList(
      {
        userId: userId,
      },
      1,
      1
    );

    return collection.getItems()[0];
  }

  public async getByUserIds(userIds: string[]): Promise<UserFactoryRelation[]> {
    const collection = await this.fetchList(
      {
        userId: userIds,
      },
      1,
      999
    );

    return collection.getItems();
  }

  public async getByFactoryId(
    factoryId: string
  ): Promise<UserFactoryRelation[]> {
    const collection = await this.fetchList(
      {
        factory: factoryId,
      },
      1,
      999
    );

    return collection.getItems();
  }

  protected prepareData(
    relation: UserFactoryRelation
  ): Dictionary<string | number | boolean> {
    return {
      id: relation.id,
      factory: relation.factory,
      userId: relation.user,
    };
  }
}
