




























import { Component, Inject, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import IdGenerator from "@/lib/services/id-generator";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import AccountStore from "@/modules/account/store";
import UserInfo from "@/modules/account/user-info.model";
import { MetaRoleValue } from "@/modules/account/meta-role.value";

import FactoryStore from "../../store";
import Factory from "../../factory.model";
import UserFactoryRelation from "../../user-factory-relation.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class UserFactoryRelationsEditForm extends mixins(
  GeneralFormMixin
) {
  @Prop({ required: true })
  public readonly factory!: Factory;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  public selectedUser: string | undefined;

  private fAccountDataStore: AccountStore;
  private fFactoryDataStore: FactoryStore;

  private fFactoryUserInfosList: UserInfo[] = [];

  get userOptions(): UserInfo[] {
    return this.fFactoryUserInfosList.filter(
      (userInfo) =>
        !this.fFactoryDataStore.getUserFactoryRelationByUserId(userInfo.id)
    );
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fAccountDataStore = getModule(AccountStore, dataStore);
  }

  protected data(): Record<string, unknown> {
    return {
      selectedUser: undefined,
    };
  }

  protected created(): void {
    void this.loadData();
  }

  protected async performSubmit(): Promise<void> {
    const userFactoryRelation = this.generateRelationObject();

    await this.fFactoryDataStore.saveUserFactoryRelation(userFactoryRelation);
    this.selectedUser = undefined;
  }

  private generateRelationObject(): UserFactoryRelation {
    if (!this.selectedUser) {
      throw new Error("Unable to create object - user field is empty");
    }

    return new UserFactoryRelation(
      this.fIdGenerator.getId(),
      this.selectedUser,
      this.factory.id,
      new Date()
    );
  }

  private async loadData(): Promise<void> {
    this.fFactoryUserInfosList = await this.fAccountDataStore.loadUserInfosByMetaRoleIds(
      [MetaRoleValue.ROLE_FACTORY]
    );
    void this.fFactoryDataStore.loadUserFactoryRelationByUserIds({
      userIds: this.fFactoryUserInfosList.map((userInfo) => userInfo.id),
      useCache: false,
    });
  }
}
