import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class PlushieStatusPeriod implements MutableValue {
  public isNew = true;

  private fFactory?: string;
  private fPreviousStatus?: string;
  private fQuitDate?: Date;

  constructor(
    private fId: string,
    private fPlushie: string,
    private fStatus: string,
    private fAssignDate: Date,
    private fUser: string
  ) {
    [fId, fPlushie, fStatus, fAssignDate, fUser].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get plushie(): string {
    return this.fPlushie;
  }

  get status(): string {
    return this.fStatus;
  }

  get assignDate(): Date {
    return this.fAssignDate;
  }

  get user(): string {
    return this.fUser;
  }

  get factory(): string | undefined {
    return this.fFactory;
  }

  get previousStatus(): string | undefined {
    return this.fPreviousStatus;
  }

  get quitDate(): Date | undefined {
    return this.fQuitDate;
  }
}
