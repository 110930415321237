var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"factory-perfomace-report card"},[_c('div',{staticClass:"_header _card-header card-divider"},[_c('h3',{staticClass:"_header-title _card-title"},[_vm._v("Factory statistics")]),(_vm.isShowFactorySelect)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedFactoryId),expression:"selectedFactoryId"}],attrs:{"disabled":_vm.isLoading},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedFactoryId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onSelectedFactoryChange]}},[_c('option',{attrs:{"value":""}},[_vm._v("All Factories")]),_vm._l((_vm.activeFactoriesList),function(factory){return _c('option',{key:factory.id,domProps:{"value":factory.id}},[_vm._v(" "+_vm._s(factory.name)+" ")])})],2):(_vm.firstFactory)?_c('h4',{staticClass:"_header-factory-name"},[_vm._v(" "+_vm._s(_vm.firstFactory.name)+" ")]):_vm._e()]),_c('div',{staticClass:"_content card-section"},[(_vm.isLoading)?_c('loading-spinner'):(_vm.isShowErrorMessage)?_c('div',{staticClass:"_error-message"},[_vm._v(" Something went wrong ")]):[_c('div',{staticClass:"_stats-container"},[_c('progress-bar',{attrs:{"title":"Production Volume","footerText":_vm.productionVolumeFooterText,"steps":_vm.productionVolumeProgressBarSteps,"value":_vm.productionVolumeValue}}),_c('ul',{staticClass:"_stats-list"},[_c('li',{staticClass:"_stats-item"},[_vm._v(" - Today: "+_vm._s(_vm.productionVolumeTodayCount)+" ")]),_c('li',{staticClass:"_stats-item"},[_vm._v(" - Last 7 days: "+_vm._s(_vm.productionVolumeStats.week.count)+" "),_c('span',[_vm._v(" (avg. "+_vm._s(_vm.productionVolumeStats.week.average.toLocaleString( "en-US", _vm.numberFormatParams ))+"/day) ")]),_c('span',{staticClass:"_item-percantage",class:{
                '-good': _vm.productionVolumeStats.week.difference.isImproved,
              }},[_vm._v(" "+_vm._s(_vm.productionVolumeStats.week.difference.value.toLocaleString( "en-US", _vm.numberFormatParams ))+"% ")])]),_c('li',{staticClass:"_stats-item"},[_vm._v(" - Last 30 days: "+_vm._s(_vm.productionVolumeStats.month.count)+" "),_c('span',[_vm._v(" (avg. "+_vm._s(_vm.productionVolumeStats.month.average.toLocaleString( "en-US", _vm.numberFormatParams ))+"/day) ")]),_c('span',{staticClass:"_item-percantage",class:{
                '-good': _vm.productionVolumeStats.month.difference.isImproved,
              }},[_vm._v(" "+_vm._s(_vm.productionVolumeStats.month.difference.value.toLocaleString( "en-US", _vm.numberFormatParams ))+"% ")])])])],1),_c('div',{staticClass:"_stats-container"},[_c('progress-bar',{attrs:{"title":"First Pass Rejection Rate","footerText":_vm.rejectionRateFooterText,"steps":_vm.rejectionRateProgressBarSteps,"value":_vm.rejectionRateStats.month.count}}),_c('ul',{staticClass:"_stats-list"},[_c('li',{staticClass:"_stats-item"},[_vm._v(" - Last 7 days: "+_vm._s(_vm.rejectionRateStats.week.count)+"% "),_c('span',{staticClass:"_item-percantage",class:{
                '-good': _vm.rejectionRateStats.week.difference.isImproved,
              }},[_vm._v(" "+_vm._s(_vm.rejectionRateStats.week.difference.value.toLocaleString( "en-US", _vm.numberFormatParams ))+"% ")])]),_c('li',{staticClass:"_stats-item"},[_vm._v(" - Last 30 days: "+_vm._s(_vm.rejectionRateStats.month.count)+"% "),_c('span',{staticClass:"_item-percantage",class:{
                '-good': _vm.rejectionRateStats.month.difference.isImproved,
              }},[_vm._v(" "+_vm._s(_vm.rejectionRateStats.month.difference.value.toLocaleString( "en-US", _vm.numberFormatParams ))+"% ")])])])],1),_c('performance-report-average-qa-rounds-section',{staticClass:"_stats-container",attrs:{"averageQaRoundsApiResponse":_vm.factoryAverageQaRoundsStatsApiResponse}}),_c('div',{staticClass:"_stats-container"},[_c('progress-bar',{attrs:{"title":"Days between moving to Design and Shipping to Main Facility","footerText":_vm.inProductionDaysFooterText,"steps":_vm.inProductionDaysProgressBarSteps,"value":_vm.inProductionDaysProgressBarValue}}),_c('ul',{staticClass:"_stats-list"},[_c('li',{staticClass:"_stats-item"},[_vm._v(" - Last 7 days: "+_vm._s(_vm.inProductionDaysStats.week.count)+" days "),_c('span',{staticClass:"_item-percantage",class:{
                '-good': _vm.inProductionDaysStats.week.difference.isImproved,
              }},[_vm._v(" "+_vm._s(_vm.inProductionDaysStats.week.difference.value.toLocaleString( "en-US", _vm.numberFormatParams ))+"% ")])]),_c('li',{staticClass:"_stats-item"},[_vm._v(" - Last 30 days: "+_vm._s(_vm.inProductionDaysStats.month.count)+" days "),_c('span',{staticClass:"_item-percantage",class:{
                '-good': _vm.inProductionDaysStats.month.difference.isImproved,
              }},[_vm._v(" "+_vm._s(_vm.inProductionDaysStats.month.difference.value.toLocaleString( "en-US", _vm.numberFormatParams ))+"% ")])])])],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }