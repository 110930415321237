





























































































import { Component, Prop, Watch, Inject } from "vue-property-decorator";
import jQuery from "jquery";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";

import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";
import PlushieStore from "@/modules/plushie/store";
import QualityInspectionStore from "@/modules/quality-inspection/store";
import StorageItemReferenceInterface from "@/modules/file-storage/storage-item-reference.interface";
import IdGenerator from "@/lib/services/id-generator";

import { EditMarkup } from "../EditMarkup";
import { EntityType } from "../entity-type";
import PhotoMarkupStore from "../store";
import PhotoMarkup from "../photo-markup.model";

@Component({
  metaInfo: {
    title: "Edit markup",
  },
  components: {
    FormErrors,
    SubmitButton,
    LoadingSpinner,
  },
})
export default class EditMarkupPage extends mixins(GeneralFormMixin) {
  private static readonly IMAGE_SIZE = 1920;

  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ required: true })
  public readonly imageId!: string;

  @Prop({ required: true })
  public readonly entityTypeId!: EntityType;

  @Prop()
  public readonly returnUrl?: string;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  @Inject("window")
  private fWindow!: Window;

  private fFileStorageDataStore: FileStorageStore;
  private fPlushieDataStore: PlushieStore;
  private fQualityInspectionDataStore: QualityInspectionStore;
  private fPhotoMarkupDataStore: PhotoMarkupStore;

  private fEditMarkup?: EditMarkup;
  private fImageUrl?: string;

  private fIsLoading = true;

  get image(): StorageItemReferenceInterface | undefined {
    if (this.entityTypeId === EntityType.ARTWORK) {
      return this.fPlushieDataStore.getPlushieImageById(this.imageId);
    } else if (this.entityTypeId === EntityType.QA_PHOTO) {
      return this.fQualityInspectionDataStore.getQaAssetById(this.imageId);
    }

    return undefined;
  }

  get imageUrl(): string | undefined {
    return this.fImageUrl;
  }

  get photoMarkup(): PhotoMarkup | undefined {
    return this.fPhotoMarkupDataStore.getMarkupByImageId(this.imageId);
  }

  get customerDescription(): string {
    const plushie = this.fPlushieDataStore.getPlushieById(this.plushieId);

    return plushie ? plushie.description : "";
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  constructor() {
    super();

    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fPhotoMarkupDataStore = getModule(PhotoMarkupStore, dataStore);

    this.fQualityInspectionDataStore = getModule(
      QualityInspectionStore,
      dataStore
    );
  }

  public initMarkupPlugin(): void {
    const photoMarkup = this.fPhotoMarkupDataStore.getMarkupByImageId(
      this.imageId
    );

    const container = jQuery(this.$el as HTMLElement);
    const artwork = this.getImageElement();

    if (!artwork) {
      throw new Error("Image element is not available");
    }

    this.fEditMarkup = new EditMarkup(
      jQuery(artwork),
      container,
      this.fWindow,
      photoMarkup ? photoMarkup.markup : undefined
    );
  }

  public onCancelClick(): void {
    this.goBack();
  }

  protected data(): Record<string, unknown> {
    return {
      fImageUrl: this.fImageUrl,
    };
  }

  protected async afterSubmit(): Promise<void> {
    if (this.submitErrors.length) {
      return;
    }

    this.goBack();

    return;
  }

  protected async performSubmit(): Promise<void> {
    if (!this.fEditMarkup || !this.fEditMarkup.markup) {
      throw new Error("No changes found!");
    }

    if (this.photoMarkup) {
      await this.fPhotoMarkupDataStore.editPhotoMarkup({
        id: this.photoMarkup.id,
        markup: this.fEditMarkup.markup,
        svgContent: this.fEditMarkup.getSvgContent(),
      });

      return;
    }

    const value = new PhotoMarkup(
      this.fIdGenerator.getId(),
      this.imageId,
      this.entityTypeId,
      this.fEditMarkup.markup
    );

    value.isNew = true;

    await this.fPhotoMarkupDataStore.createPhotoMarkup({
      item: value,
      svgContent: this.fEditMarkup.getSvgContent(),
    });

    void this.$router.push({ name: "DashboardPage" });
  }

  private getImageElement(): HTMLImageElement | undefined {
    return this.$refs["imageToMarkup"] as HTMLImageElement;
  }

  private getImageUrl(
    image: StorageItemReferenceInterface
  ): string | undefined {
    const storageItem = this.fFileStorageDataStore.getItemById(
      image.storageItem
    );

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      EditMarkupPage.IMAGE_SIZE,
      EditMarkupPage.IMAGE_SIZE,
      false
    );
  }

  private goBack(): void {
    if (this.returnUrl) {
      void this.$router.push(this.returnUrl);
      return;
    }

    void this.$router.push({
      name: "PlushieViewPage",
      query: {
        id: this.plushieId,
      },
    });
  }

  private async loadData(imageId: string, plushieId: string) {
    let image: StorageItemReferenceInterface | undefined;

    if (this.entityTypeId === EntityType.ARTWORK) {
      await this.fPlushieDataStore.loadPlushieImagesByIds({ ids: [imageId] });
      image = this.fPlushieDataStore.getPlushieImageById(imageId);
    } else if (this.entityTypeId === EntityType.QA_PHOTO) {
      image = await this.fQualityInspectionDataStore.loadQaAssetById({
        id: imageId,
      });
    } else {
      throw new Error("Unknown entity type");
    }

    if (!image) {
      throw new Error("Image not found!");
    }

    await Promise.all([
      this.fPhotoMarkupDataStore.loadMarkupByImageId({
        imageId,
        useCache: false,
      }),
      this.fFileStorageDataStore.loadItemsByIds([image.storageItem]),
      this.fPlushieDataStore.loadPlushieById({ id: plushieId }),
    ]);

    const imageUrl = this.getImageUrl(image);
    if (!imageUrl) {
      throw new Error("Image storage item is not available!");
    }

    await this.loadImage(imageUrl);
  }

  private async loadImage(url: string) {
    this.fImageUrl = url;

    await this.$nextTick();

    const element = this.getImageElement();

    if (!element) {
      throw new Error("Image element is not available");
    }

    return new Promise((resolve, reject) => {
      element.onload = () => resolve(undefined);
      element.onerror = (e) => reject(e);
    });
  }

  @Watch("imageId", { immediate: true })
  private async _onImageIdChange() {
    this.fIsLoading = true;

    try {
      await this.loadData(this.imageId, this.plushieId);
      this.initMarkupPlugin();
    } finally {
      this.fIsLoading = false;
    }
  }
}
