import { MutableValue } from "../api/mutable-value.interface";

export default class BulkProjectPlushieRelation implements MutableValue {
  public isNew = true;
  private fCreatedAt?: Date;

  constructor(
    private fId: string,
    private fBulkProject: string,
    private fPlushieType: string,
    private fPlushie: string
  ) {
    [fId, fBulkProject, fPlushieType, fPlushie].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  public get id(): string {
    return this.fId;
  }

  public get createdAt(): Date | undefined {
    return this.fCreatedAt;
  }

  public get bulkProject(): string {
    return this.fBulkProject;
  }

  public get plushieType(): string {
    return this.fPlushieType;
  }

  public get plushie(): string {
    return this.fPlushie;
  }
}
