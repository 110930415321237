import ApiService from "@/modules/api/api.service";

export default class PlushieExportJobRepository {
  constructor(protected resourceName: string, protected api: ApiService) {}

  public async createPlushieExportAsExcelJob(
    plushieIds: string[]
  ): Promise<string> {
    const response = await this.api.post(`${this.resourceName}`, {
      format: "xls",
      plushiesIds: plushieIds,
    });

    return (response.data as { downloadUrl: string }).downloadUrl;
  }
}
