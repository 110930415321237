






























import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";

import FormField from "@/lib/components/FormField.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";

import dataStore from "@/store";
import PlushieStore from "@/modules/plushie/store";
import Product from "@/modules/plushie/product.model";
import { Dictionary } from "@/lib/Dictionary.type";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";

import FactoryRestrictedModeStore from "../../store";
import ExcludedProduct from "../../excluded-product.model";

@Component({
  components: {
    FormField,
    FormErrors,
    SubmitButton,
  },
})
export default class ExcludedProductsEditForm extends mixins(GeneralFormMixin) {
  private fPlushieDataStore: PlushieStore;
  private fFactoryRestrictedModeDataStore: FactoryRestrictedModeStore;

  private fUpdatingProducts: Dictionary<boolean> = {};

  get getProductOptions(): Product[] {
    return this.fPlushieDataStore.activeProductsList;
  }

  constructor() {
    super();

    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fFactoryRestrictedModeDataStore = getModule(
      FactoryRestrictedModeStore,
      dataStore
    );
  }

  public isProductUpdating(productId: string): boolean {
    return this.fUpdatingProducts[productId];
  }

  public isProductExcluded(productId: string): boolean {
    return (
      this.fFactoryRestrictedModeDataStore.getExcludedProductById(productId) !==
      undefined
    );
  }

  public onProductChange(product: Product, value: boolean): void {
    if (!value) {
      void this.deleteExcludedProduct(product);
    } else {
      void this.addExcludedProduct(product);
    }
  }

  protected async mounted(): Promise<void> {
    await Promise.all([
      this.fPlushieDataStore.loadProducts(),
      this.fFactoryRestrictedModeDataStore.loadExcludedProducts(),
    ]);
  }

  private async deleteExcludedProduct(product: Product) {
    this.markProductAsUpdating(product.id);

    try {
      const excludedProduct = this.fFactoryRestrictedModeDataStore.getExcludedProductById(
        product.id
      );

      if (excludedProduct === undefined) {
        this.submitErrors = [];
        return;
      }

      await this.fFactoryRestrictedModeDataStore.deleteExcludedProduct(
        excludedProduct
      );

      this.submitErrors = [];
    } catch (e) {
      this.submitErrors.push(
        `Something went wrong! Unable to remove the product '${product.name}'`
      );
    } finally {
      this.markProductAsAvailable(product.id);
    }
  }

  private async addExcludedProduct(product: Product) {
    const excludedProduct = new ExcludedProduct(product.id);

    this.markProductAsUpdating(product.id);

    try {
      await this.fFactoryRestrictedModeDataStore.saveExcludedProduct(
        excludedProduct
      );
      this.submitErrors = [];
    } catch (e) {
      const errors = this.fErrorConverterService.describeError(e);

      this.submitErrors.push(
        `Unable to add the product '${product.name}': ${errors[0]}`
      );
    } finally {
      this.markProductAsAvailable(product.id);
    }
  }

  private markProductAsUpdating(productId: string) {
    Vue.set(this.fUpdatingProducts, productId, true);
  }

  private markProductAsAvailable(productId: string) {
    Vue.set(this.fUpdatingProducts, productId, false);
  }
}
