import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import Product from "./product.model";

export default class ProductFactory implements ObjectBuilderInterface<Product> {
  public buildFromJSON(data: { [key: string]: any }): Product {
    const value = new Product(data.id.toString(), data.name);

    value.isNew = false;

    return value;
  }
}
