import ApiService from "../api/api.service";

export default class CopyAttachmentToArtworkRequestRepository {
  private readonly fResourceName = "copy_attachment_to_artwork_requests";
  constructor(private fApi: ApiService) {}

  public async create(attachmentId: string): Promise<string> {
    const response = await this.fApi.post(this.fResourceName, {
      attachmentId,
    });

    return response.data.artworkId as string;
  }
}
