










import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class FormField extends Vue {
  @Prop({ default: null })
  public error?: string;

  @Prop({ default: true })
  public showErrorMessage!: boolean;
}
