


















import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import InlineSpinner from "@/lib/components/InlineSpinner.vue";

import dataStore from "@/store";
import FileStorageStore from "@/modules/file-storage/store";
import { ImageHandlerOutputFormat } from "@/modules/file-storage/image-handler-output-format";
import ImageHandlerService from "@/modules/file-storage/image-handler.service";

import AlertStore from "../store";
import Alert from "../alert.model";

@Component({
  components: {
    InlineSpinner,
  },
})
export default class PlushieAssignedAlertsList extends Vue {
  private static readonly ICON_SIZE = 30;

  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: false })
  public readonly showTitle!: boolean;

  @Inject("ImageHandlerService")
  private fImageHandlerService!: ImageHandlerService;

  private fAlertDataStore: AlertStore;
  private fFileStorageDataStore: FileStorageStore;

  private fIsLoading = false;

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get alerts(): Alert[] {
    const result: Alert[] = [];

    const alerts = this.fAlertDataStore.plushieAlertsList;
    const alertsIds = this.fAlertDataStore.getPlushieAlertsByPlushieId(
      this.plushieId
    );

    alerts.forEach((alert) => {
      if (!alertsIds.includes(alert.id)) {
        return;
      }

      result.push(alert);
    });

    return result;
  }

  constructor() {
    super();

    this.fAlertDataStore = getModule(AlertStore, dataStore);
    this.fFileStorageDataStore = getModule(FileStorageStore, dataStore);
  }

  public getIconThumbnail(alert: Alert): string {
    const storageItem = alert.iconStorageId
      ? this.fFileStorageDataStore.getItemById(alert.iconStorageId)
      : null;

    const url = storageItem
      ? storageItem.timestampedUrl
      : this.fFileStorageDataStore.placeholderUrl;

    return this.fImageHandlerService.getThumbnailUrl(
      url,
      PlushieAssignedAlertsList.ICON_SIZE,
      PlushieAssignedAlertsList.ICON_SIZE,
      true,
      ImageHandlerOutputFormat.PNG
    );
  }

  private async getPlushieData(plushieId: string) {
    const alertIds = await this.fAlertDataStore.loadPlushieAlertsByPlushieId({
      plushieId,
    });

    await this.fAlertDataStore.loadPlushieAlertsByIds(alertIds);

    await this.loadAlertsIcons(this.alerts);
  }

  private async loadAlertsIcons(alerts: Alert[]): Promise<void> {
    const iconStorageItemIds: string[] = [];

    alerts.forEach((alert: Alert) => {
      if (!alert) {
        return;
      }

      iconStorageItemIds.push(alert.iconStorageId);
    });

    await this.fFileStorageDataStore.loadItemsByIds(iconStorageItemIds);
  }

  @Watch("alerts", { immediate: true })
  private _onAlertsChange() {
    if (this.isLoading) {
      return;
    }

    void this.loadAlertsIcons(this.alerts);
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    this.fIsLoading = true;

    try {
      await this.getPlushieData(this.plushieId);
    } finally {
      this.fIsLoading = false;
    }
  }
}
