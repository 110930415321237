import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import QaDecision from "./qa-decision.model";

export default class QaDecisionFactory
  implements ObjectBuilderInterface<QaDecision> {
  public buildFromJSON(data: { [key: string]: any }): QaDecision {
    const value = new QaDecision(
      data.id,
      data.plushieId,
      data.factoryId,
      data.authorId,
      data.isApprove == true
    );

    (value as any).fCreatedAt = new Date(data.createdAt);

    value.isNew = false;

    return value;
  }
}
