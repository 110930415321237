import chunk from "lodash.chunk";

import { Dictionary } from "@/lib/Dictionary.type";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { FieldOrderDesc } from "@/modules/api/field-order-options";

import Attachment from "./attachment.model";

export default class AttachmentRepository extends BasicRepository<Attachment> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Attachment>,
    responseParser: ApiCollectionResponseParserService<Attachment>
  ) {
    super("attachments", api, objectBuilder, responseParser);
  }

  public async createAttachment(
    messageId: string,
    storageItemId: string,
    mime: string
  ): Promise<Attachment> {
    const data: Dictionary<string> = {
      messageId: messageId,
      storageItemId: storageItemId,
      mime,
    };

    const response = await this.api.post(this.resourceName, data);

    return this.objectBuilder.buildFromJSON(response.data);
  }

  public async getByMessageIds(messageIds: string[]): Promise<Attachment[]> {
    let attachments: Attachment[] = [];

    const chunks = chunk(messageIds, ApiService.MAX_GUIDS_PER_GET_REQUEST);
    const requestPromises = [];

    for (let index = 0; index < chunks.length; index++) {
      const ids = chunks[index];

      requestPromises.push(
        this.fetchList(
          {
            message: ids,
          },
          1,
          9999,
          [["createdAt", FieldOrderDesc]]
        )
      );
    }

    const collections = await Promise.all(requestPromises);

    collections.map((collection) => {
      attachments = attachments.concat(collection.getItems());
    });

    return attachments;
  }
}
