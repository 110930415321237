import { MutableValue } from "@/modules/api/mutable-value.interface";

export default class RemotePrinter implements MutableValue {
  public isNew = true;

  constructor(private fId: string, private fName: string) {
    [fId, fName].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get name(): string {
    return this.fName;
  }
}
