import ObjectBuilderInterface from "../api/object-builder.interface";
import EmailTemplate from "./email-template.model";

export default class EmailTemplateFactory
  implements ObjectBuilderInterface<EmailTemplate> {
  public buildFromJSON(data: { [key: string]: any }): EmailTemplate {
    const value = new EmailTemplate(
      data.id,
      data.code,
      data.subject,
      data.content,
      data.sender,
      data.storeId
    );

    value.isNew = false;

    return value;
  }
}
