




import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ExtraPaymentFormPlushieAutocompleteItem extends Vue {
  @Prop({ required: true })
  public readonly item!: { text: string; value: string };
}
