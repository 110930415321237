import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import PermissionResource from "./permission-resource.model";

export default class PermissionResourceRepository extends BasicRepository<PermissionResource> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<PermissionResource>,
    responseParser: ApiCollectionResponseParserService<PermissionResource>
  ) {
    super("resources", api, objectBuilder, responseParser);
  }

  protected prepareData(item: PermissionResource): Record<string, string> {
    return {
      id: item.id,
      name: item.name,
      service: item.service,
    };
  }
}
