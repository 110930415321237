var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bulk-projects-list-page grid-x"},[_c('div',{staticClass:"cell small-12"},[_c('div',{staticClass:"_page-title"},[_c('h1',[_vm._v(_vm._s(_vm.pageTitle))])]),_c('div',{staticClass:"_actions"},[_c('div',{staticClass:"_buttons-group"},[_c('router-link',{staticClass:"button small",attrs:{"to":{ name: 'BulkProjectCreatePage' }}},[_vm._v(" Create Bulk Project ")])],1),_c('div',{staticClass:"_artwork-display-control-wrapper"},[_c('a',{staticClass:"_artwork-display-control",on:{"click":function($event){return _vm.toggleDisplayArtworks()}}},[_vm._v(_vm._s(_vm.displayArtworksCtrlText))])])]),_c('div',{staticClass:"_page-content"},[(_vm.isInitialized)?_c('v-server-table',{ref:"listTable",staticClass:"_items-grid",class:{
          '-loading': _vm.isLoading,
          '-artworks-hidden': !_vm.shouldDisplayArtworks,
        },attrs:{"columns":_vm.getColumns(),"options":_vm.options},on:{"loading":_vm.onLoading,"loaded":_vm.onLoaded,"row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"afterTable",fn:function(){return [(_vm.filtersError)?_c('div',{staticClass:"_filters-error"},[_vm._v(" "+_vm._s(_vm.filtersError)+" ")]):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
        var row = ref.row;
return [_c('a',{staticClass:"_edit-item-action _action button -small",attrs:{"href":_vm.getItemUrl(row.id, 'BulkProjectViewPage'),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Open ")])]}},{key:"artwork",fn:function(ref){
        var row = ref.row;
return [_c('img',{attrs:{"src":_vm.getThumbnail(row.artwork),"alt":""}})]}}],null,false,2930038678)}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }