









































































import { Component, Inject, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import autosize from "v-autosize";

import ColorsAutocomplete from "@/lib/components/ColorsAutocomplete.vue";
import SubmitButton from "@/lib/components/SubmitButton.vue";
import FormErrors from "@/lib/components/FormErrors.vue";
import FormField from "@/lib/components/FormField.vue";
import UpgradesList from "@/modules/plushie/components/UpgradesList.vue";
import BodyPartsEditForm from "@/modules/body-part/components/BodyPartsEditForm.vue";
import BodyPartsViewForm from "@/modules/body-part/components/BodyPartsViewForm.vue";

import ProductionProcessStore from "@/modules/production-process/store";
import dataStore from "@/store";
import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import autoindent from "@/lib/directives/auto-indent.directive";
import PlushieStore from "@/modules/plushie/store";
import { UiPersisterStore } from "@/modules/ui-persister/store";
import { ProductValue } from "@/modules/plushie/product.value";
import { DirectoryValue } from "@/modules/api/directory-value.model";
import AuthenticatedUserProvider from "@/modules/account/authenticated-user-provider.service";
import { Resource } from "@/modules/account/resource";
import Plushie from "@/modules/plushie/plushie.model";
import doesPlushieHaveBodyParts from "@/modules/plushie/does-plushie-have-body-parts.function";

import { Command } from "../../command";

@Component({
  components: {
    ColorsAutocomplete,
    BodyPartsEditForm,
    BodyPartsViewForm,
    FormField,
    FormErrors,
    SubmitButton,
    UpgradesList,
  },
  directives: {
    autoindent,
    autosize,
  },
})
export default class ReviewApproveForm extends mixins(GeneralFormMixin) {
  @Prop({ required: true })
  public readonly plushieId!: string;

  @Prop({ default: false })
  public readonly showUpgradesAndBodyParts!: boolean;

  @Inject("AuthenticatedUserProvider")
  private fUserProvider!: AuthenticatedUserProvider;

  private fProductionProcessDataStore: ProductionProcessStore;
  private fPlushieDataStore: PlushieStore;
  private fUiPersisterDataStore: UiPersisterStore;

  private fCommandForSubmit:
    | Command.ApproveReview
    | Command.ApproveReviewAndSkipDesign
    | undefined = undefined;

  get availableCommands(): Set<Command> {
    return this.fProductionProcessDataStore.getAvailableCommandsForPlushieId(
      this.plushieId
    );
  }

  get comment(): string {
    return (
      this.fUiPersisterDataStore.getPlushieReviewComment(this.plushieId) || ""
    );
  }

  set comment(comment: string) {
    this.fUiPersisterDataStore.updatePlushieReviewComment({
      plushieId: this.plushieId,
      comment: comment,
    });
  }

  get doesPlushieHaveBodyParts(): boolean {
    if (!this.plushie) {
      return false;
    }

    return doesPlushieHaveBodyParts(this.plushie.product);
  }

  get isApproveButtonLoading(): boolean {
    return (
      this.isSubmitting && this.fCommandForSubmit === Command.ApproveReview
    );
  }

  get isApproveAndSkipDesignButtonLoading(): boolean {
    return (
      this.isSubmitting &&
      this.fCommandForSubmit === Command.ApproveReviewAndSkipDesign
    );
  }

  get isReviewApproveAvailable(): boolean {
    return this.availableCommands.has(Command.ApproveReview);
  }

  get isReviewApproveAndSkipDesignAvailable(): boolean {
    return this.availableCommands.has(Command.ApproveReviewAndSkipDesign);
  }

  get isDesignerCommentRequired(): boolean {
    if (!this.plushieId) {
      return true;
    }

    if (!this.plushie) {
      return true;
    }

    return [
      ProductValue.BUDSIE,
      ProductValue.SELFIE,
      ProductValue.BULK_SAMPLE,
      ProductValue.SPECIALITY_COMMISSION,
      ProductValue.PETSIE_CAT,
      ProductValue.PETSIE_DOG,
      ProductValue.PETSIE_OTHER,
      ProductValue.IMAGINABLE,
      ProductValue.HUGGABLES_CAT,
      ProductValue.HUGGABLES_DOG,
      ProductValue.HUGGABLES_OTHER,
      ProductValue.FOREVERS_CAT,
      ProductValue.FOREVERS_DOG,
      ProductValue.FOREVERS_OTHER,
      ProductValue.WAGGABLES_DOG,
      ProductValue.BUDSIES_PALS,
      ProductValue.BUDSIES_PUPPET,
      ProductValue.SELFIES_PUPPET,
      ProductValue.BOBBLEHEADS,
      ProductValue.FIGURINES,
      ProductValue.PETSIES_BOBBLEHEADS,
      ProductValue.PETSIES_FIGURINES,
      ProductValue.GOLF_HEAD_COVER_DOG,
      ProductValue.GOLF_HEAD_COVER_CAT,
      ProductValue.GOLF_HEAD_COVER_OTHER,
    ].includes(this.plushie.product);
  }

  get isApproveButtonDisabled(): boolean {
    return this.isDisabled || !this.isReviewApproveAvailable;
  }

  get isApproveAndSkipDesignButtonDisabled(): boolean {
    return this.isDisabled || !this.isReviewApproveAndSkipDesignAvailable;
  }

  get plushie(): Plushie | undefined {
    return this.fPlushieDataStore.getPlushieById(this.plushieId);
  }

  get showBodyPartsEditForm(): boolean {
    if (!this.doesPlushieHaveBodyParts) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BODYPARTS_MANAGE);
  }

  get showBodyPartsViewForm(): boolean {
    if (!this.doesPlushieHaveBodyParts) {
      return false;
    }

    const user = this.fUserProvider.getUser();

    if (!user) {
      return false;
    }

    return user.hasPermissionForResource(Resource.BODYPARTS_VIEW);
  }

  get showReviewApproveAndSkipDesignButton(): boolean {
    if (!this.plushie) {
      return false;
    }

    return this.plushie.product === ProductValue.BULK_SAMPLE;
  }

  get showWarning(): boolean {
    return (
      !this.isReviewApproveAvailable &&
      !this.isReviewApproveAndSkipDesignAvailable
    );
  }

  get upgrades(): DirectoryValue[] {
    const ids = this.fPlushieDataStore.getPlushieUpgradesByPlushieId(
      this.plushieId
    );

    const result: DirectoryValue[] = [];

    ids.forEach((id) => {
      const upgrade = this.fPlushieDataStore.getUpgradeById(id);
      if (!upgrade) {
        return;
      }

      result.push(upgrade);
    });

    return result;
  }

  constructor() {
    super();

    this.fProductionProcessDataStore = getModule(
      ProductionProcessStore,
      dataStore
    );
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
    this.fUiPersisterDataStore = getModule(UiPersisterStore, dataStore);
  }

  public useApproveReviewCommandForSubmit(): void {
    this.fCommandForSubmit = Command.ApproveReview;
  }

  public useApproveReviewAndSkipDesignCommandForSubmit(): void {
    this.fCommandForSubmit = Command.ApproveReviewAndSkipDesign;
  }

  protected async performSubmit(): Promise<void> {
    if (this.isDesignerCommentRequired && !this.comment) {
      throw new Error("Not all required params are specified");
    }

    if (!this.fCommandForSubmit) {
      throw new Error("Command is not specified");
    }

    switch (this.fCommandForSubmit) {
      case Command.ApproveReview:
        await this.reviewApprove();
        break;
      case Command.ApproveReviewAndSkipDesign:
        await this.reviewApproveAndSkipDesign();
        break;
      default:
        throw new Error("Command is not supported");
    }

    this.$emit("reviewApproved");

    this.fUiPersisterDataStore.deletePlushieReviewComment({
      plushieId: this.plushieId,
    });
  }

  private async getPlushieData(plushieId: string) {
    await this.fPlushieDataStore.loadPlushieById({
      id: plushieId,
    });
  }

  private async reviewApprove() {
    if (!this.isReviewApproveAvailable) {
      throw new Error("Approve Review command is not available");
    }

    await this.fProductionProcessDataStore.reviewApprove({
      plushieId: this.plushieId,
      comment: this.comment,
    });
  }

  private async reviewApproveAndSkipDesign() {
    if (!this.isReviewApproveAndSkipDesignAvailable) {
      throw new Error(
        "Approve Review And Skip Design command is not available"
      );
    }

    await this.fProductionProcessDataStore.reviewApproveAndSkipDesign({
      plushieId: this.plushieId,
      comment: this.comment,
    });
  }

  @Watch("plushieId", { immediate: true })
  private async _onPlushieIdChange() {
    if (!this.plushieId) {
      return;
    }

    await this.getPlushieData(this.plushieId);

    await this.$validator.reset();
  }
}
