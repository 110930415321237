import { MutableValue } from "@/modules/api/mutable-value.interface";

import { ElementTypeValue } from "./element-type.value";

export default class ElementPrice implements MutableValue {
  public isNew = true;

  constructor(
    private fId: string,
    private fFactory: string,
    private fType: ElementTypeValue,
    private fEntity: string,
    private fQty: number,
    private fPrice: number
  ) {
    [fId, fFactory, fType, fEntity, fQty, fPrice].forEach((arg, index) => {
      if (arg === undefined) {
        throw new Error(`Undefined value passed at position: ${index}`);
      }
    });
  }

  get id(): string {
    return this.fId;
  }

  get factory(): string {
    return this.fFactory;
  }

  get type(): ElementTypeValue {
    return this.fType;
  }

  get entity(): string {
    return this.fEntity;
  }

  get qty(): number {
    return this.fQty;
  }

  get price(): number {
    return this.fPrice;
  }
}
