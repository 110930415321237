import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import RestrictedModeStatus from "./restricted-mode-status.model";

export default class RestrictedModeStatusFactory
  implements ObjectBuilderInterface<RestrictedModeStatus> {
  public buildFromJSON(data: { [key: string]: any }): RestrictedModeStatus {
    return new RestrictedModeStatus(data.factoryId, data.statusId.toString());
  }
}
