import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";

import UserGroupRelation from "./user-group-relation.model";

export default class UserGroupRelationRepository extends BasicRepository<UserGroupRelation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<UserGroupRelation>,
    responseParser: ApiCollectionResponseParserService<UserGroupRelation>
  ) {
    super("user_group_relations", api, objectBuilder, responseParser);
  }

  public async getByUser(
    userId: string
  ): Promise<UserGroupRelation | undefined> {
    const collection = await this.fetchList({ userId: userId }, 1, 1);

    return collection.getItems().shift();
  }
}
