import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";

import Value from "./value.model";
import { FieldOrderAsc } from "../api/field-order-options";

export default class ValueRepository extends BasicRepository<Value> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<Value>,
    responseParser: ApiCollectionResponseParserService<Value>
  ) {
    super("body_part_values", api, objectBuilder, responseParser);
  }

  public async getByBodyPartIds(ids: string[]): Promise<Value[]> {
    const collection = await this.fetchList({ bodyPart: ids }, 1, 999, [
      ["sn", FieldOrderAsc],
    ]);

    return collection.getItems();
  }

  protected prepareData(item: Value): Record<string, string> {
    const data: Record<string, string> = {
      id: item.id,
    };

    return data;
  }
}
