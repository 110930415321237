import ApiService from "@/modules/api/api.service";

import RemotePrinter from "./remote-printer.model";

export default class RemotePrinterRepository {
  constructor(protected api: ApiService) {}

  public async getList(): Promise<RemotePrinter[]> {
    const response = await this.api.get("barcode_remote_printers");
    const result: RemotePrinter[] = [];
    response.data.forEach((item: any) => {
      result.push(new RemotePrinter(item["id"], item["name"]));
    });
    return result;
  }
}
