import ObjectBuilderInterface from "@/modules/api/object-builder.interface";
import ApiCollectionResponseParserService from "@/modules/api/api-collection-response-parser.service";
import ApiService from "@/modules/api/api.service";
import BasicRepository from "@/modules/api/basic.repository";
import { Dictionary } from "@/lib/Dictionary.type";

import BulkProjectBatchInformation from "./bulk-project-batch-information.model";

export default class BulkProjectBatchInformationRepository extends BasicRepository<BulkProjectBatchInformation> {
  constructor(
    api: ApiService,
    objectBuilder: ObjectBuilderInterface<BulkProjectBatchInformation>,
    responseParser: ApiCollectionResponseParserService<BulkProjectBatchInformation>
  ) {
    super(
      "bulk_project_batch_informations",
      api,
      objectBuilder,
      responseParser
    );
  }

  public async fetchByPlushieId(
    plushieId: string
  ): Promise<BulkProjectBatchInformation | undefined> {
    const response = await this.fetchList(
      {
        id: plushieId,
      },
      1,
      1
    );

    const items = response.getItems();

    return items.length ? items[0] : undefined;
  }

  protected prepareData(
    item: BulkProjectBatchInformation
  ): Dictionary<string | number | boolean> {
    const data: Dictionary<string | number | boolean> = {
      notes: item.notes ?? "",
      useCustomSewInLabel: item.useCustomSewInLabel,
    };

    if (item.negotiatedPrice) {
      data.negotiatedPrice = String(item.negotiatedPrice);
    }

    return data;
  }
}
