













import { Component, Prop } from "vue-property-decorator";
import { DialogComponent } from "vue-modal-dialogs";

import CreateBulkShipmentForm from "./CreateBulkShipmentForm.vue";

@Component({
  components: { CreateBulkShipmentForm },
})
export default class CreateBulkShipmentDialog extends DialogComponent<boolean> {
  @Prop({ required: true })
  public readonly plushieIds!: string[];

  @Prop({ required: true })
  public readonly factoryId!: string;
}
