import ObjectBuilderInterface from "@/modules/api/object-builder.interface";

import ProductSetting from "./product-setting.model";

export default class ProductSettingFactory
  implements ObjectBuilderInterface<ProductSetting> {
  public buildFromJSON(data: { [key: string]: any }): ProductSetting {
    const value = new ProductSetting(
      data.id,
      data.period.toString(),
      data.product.toString(),
      new Date(data.internationalDueDate),
      new Date(data.domesticDueDate)
    );

    (value as any).fCreatedAt = new Date(data.createdAt);
    (value as any).fUpdatedAt = new Date(data.updatedAt);

    return value;
  }
}
