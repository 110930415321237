import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { Dictionary } from "@/lib/Dictionary.type";
import { DIContainer } from "@/app.container";
import dataStore from "@/store";

import Value from "../value.model";
import BodyPart from "../body-part.model";
import PlushieValueRelation from "../plushie-value-relation.model";
import PlushieValueRelationUpdate from "../plushie-value-relation-update.interface";
import BodyPartStoreActionContext from "../body-part-store-action-context.type";
import {
  GetBodyPartById,
  GetBodyPartByPlushieValueRelation,
  GetPlushieValueRelationsByPlushieIdAndBodyPartId,
} from "../body-part-store-getters.type";
import removeConflictingPlushieValueRelations from "../remove-conflicting-plushie-value-relations.function";

const name = "BodyPartStore";

if ((dataStore.state as any)[name]) {
  dataStore.unregisterModule(name);
}

const getDefaultState = () => {
  return {
    bodyPart: {},
    plushieValueRelation: {},
    value: {},
    bodyPartRelations: {},
    productRelations: {},
    plushieRelations: {},
  };
};

@Module({ name, dynamic: true, store: dataStore })
export default class BodyPartStore extends VuexModule {
  bodyPart: Dictionary<BodyPart> = {};
  plushieValueRelation: Dictionary<PlushieValueRelation> = {};
  value: Dictionary<Value> = {};

  bodyPartRelations: Dictionary<string[]> = {};
  productRelations: Dictionary<string[]> = {};
  plushieRelations: Dictionary<string[]> = {};

  // ################################### BODY PARTS #########################################
  get getBodyPartById(): GetBodyPartById {
    return (id: string) => this.bodyPart[id];
  }

  get getBodyPartsByIds(): (ids: string[]) => BodyPart[] {
    return (ids: string[]) => {
      const list: BodyPart[] = [];

      ids.forEach((id) => {
        if (this.bodyPart[id] !== undefined) {
          list.push(this.bodyPart[id]);
        }
      });

      list.sort((a, b) => (a.adminSn > b.adminSn ? 1 : -1));

      return list;
    };
  }

  get getEnabledBodyPartsByProductId(): (productId: string) => BodyPart[] {
    return (productId: string) => {
      if (!this.productRelations[productId]) {
        return [];
      }

      const list: BodyPart[] = [];

      this.productRelations[productId].forEach((bodyPartId) => {
        if (
          this.bodyPart[bodyPartId] !== undefined &&
          this.bodyPart[bodyPartId].isEnabled
        ) {
          list.push(this.bodyPart[bodyPartId]);
        }
      });

      list.sort((a, b) => (a.adminSn > b.adminSn ? 1 : -1));

      return list;
    };
  }

  @Mutation
  updateBodyPart(payload: BodyPart): void {
    Vue.set(this.bodyPart, payload.id, payload);

    if (
      this.productRelations[payload.product] !== undefined &&
      !this.productRelations[payload.product].includes(payload.id)
    ) {
      this.productRelations[payload.product].push(payload.id);
    }
  }

  @Mutation
  updateProductRelations({
    productId,
    bodyParts,
  }: {
    productId: string;
    bodyParts: BodyPart[];
  }): void {
    bodyParts.forEach((bodyPart) => {
      if (bodyPart.product !== productId) {
        throw new Error(
          "All body parts should belong to the specified product!"
        );
      }
    });

    const bodyPartIds: string[] = [];

    bodyParts.forEach((bodyPart) => {
      bodyPartIds.push(bodyPart.id);
      Vue.set(this.bodyPart, bodyPart.id, bodyPart);
    });

    Vue.set(this.productRelations, productId, bodyPartIds);
  }

  @Action({ rawError: true })
  async loadBodyPartsByIds(
    ids: string[]
  ): Promise<Dictionary<BodyPart | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<BodyPart | undefined> = {};

    ids.forEach((id) => {
      if (!this.bodyPart[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.bodyPart[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.BodyPartRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateBodyPart(item);
    });

    result = { ...result, ...items };

    return result;
  }

  @Action({ rawError: true })
  async loadBodyPartsByProductId({
    productId,
    useCache = true,
  }: {
    productId: string;
    useCache?: boolean;
  }): Promise<BodyPart[]> {
    let bodyParts: BodyPart[] = [];

    if (useCache && this.productRelations[productId]) {
      const bodyPartIds = this.productRelations[productId];

      bodyPartIds.forEach((id) => {
        bodyParts.push(this.bodyPart[id]);
      });

      return bodyParts;
    }

    bodyParts = await DIContainer.BodyPartRepository.getByProduct(productId);

    this.updateProductRelations({ productId, bodyParts });

    return bodyParts;
  }

  // ################################### VALUES #########################################

  get getValueById(): (id: string) => Value | undefined {
    return (id: string) => this.value[id];
  }

  get getValuesByIds(): (ids: string[]) => Value[] {
    return (ids: string[]) => {
      const list: Value[] = [];

      ids.forEach((id) => {
        if (this.value[id] !== undefined) {
          list.push(this.value[id]);
        }
      });

      list.sort((a, b) => (a.sn > b.sn ? 1 : -1));

      return list;
    };
  }

  get getBodyPartValuesByBodyPartId(): (bodyPartId: string) => Value[] {
    return (bodyPartId: string) => {
      if (!this.bodyPartRelations[bodyPartId]) {
        return [];
      }

      const values: Value[] = [];

      this.bodyPartRelations[bodyPartId].forEach((valueId) => {
        values.push(this.value[valueId]);
      });

      return values;
    };
  }

  @Mutation
  updateValue(payload: Value): void {
    Vue.set(this.value, payload.id, payload);

    if (
      this.bodyPartRelations[payload.bodyPart] !== undefined &&
      !this.bodyPartRelations[payload.bodyPart].includes(payload.id)
    ) {
      this.bodyPartRelations[payload.bodyPart].push(payload.id);
    }
  }

  @Mutation
  updateBodyPartRelations({
    bodyPartId,
    values,
  }: {
    bodyPartId: string;
    values: Value[];
  }): void {
    values.forEach((value) => {
      if (value.bodyPart !== bodyPartId) {
        throw new Error("All values should belong to the specified body part!");
      }
    });

    const valuesIds: string[] = [];

    values.forEach((value) => {
      valuesIds.push(value.id);
      Vue.set(this.value, value.id, value);
    });

    Vue.set(this.bodyPartRelations, bodyPartId, valuesIds);
  }

  @Action({ rawError: true })
  async loadValuesByBodyPartIds({
    bodyPartIds,
    useCache = true,
  }: {
    bodyPartIds: string[];
    useCache?: boolean;
  }): Promise<Dictionary<Value[]>> {
    const missing: string[] = [];
    const result: Dictionary<Value[]> = {};

    bodyPartIds.forEach((bodyPartId) => {
      if (useCache && this.bodyPartRelations[bodyPartId]) {
        result[bodyPartId] = this.getBodyPartValuesByBodyPartId(bodyPartId);
        return;
      }

      missing.push(bodyPartId);
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.BodyPartValueRepository.getByBodyPartIds(
      missing
    );

    const bodyPartValues: Dictionary<Value[]> = {};

    items.forEach((item) => {
      if (!bodyPartValues[item.bodyPart]) {
        bodyPartValues[item.bodyPart] = [];
      }

      bodyPartValues[item.bodyPart].push(item);
    });

    for (const bodyPartId in bodyPartValues) {
      const values = bodyPartValues[bodyPartId];

      if (!values) {
        continue;
      }

      this.updateBodyPartRelations({ bodyPartId, values });
    }

    return { ...result, ...bodyPartValues };
  }

  @Action({ rawError: true })
  async loadValuesByIds(ids: string[]): Promise<Dictionary<Value | undefined>> {
    const missing: string[] = [];
    let result: Dictionary<Value | undefined> = {};

    ids.forEach((id) => {
      if (!this.value[id]) {
        missing.push(id);
        return;
      }

      result[id] = this.value[id];
    });

    if (!missing.length) {
      return result;
    }

    const items = await DIContainer.BodyPartValueRepository.getByIds(missing);

    Object.keys(items).forEach((id) => {
      const item = items[id];
      if (!item) {
        return;
      }

      this.updateValue(item);
    });

    result = { ...result, ...items };

    return result;
  }

  // ################################### PLUSHIE VALUE RELATIONS #########################################
  get getPlushieValueRelationsByPlushieId(): (
    plushieId: string
  ) => PlushieValueRelation[] {
    return (plushieId: string) => {
      if (!this.plushieRelations[plushieId]) {
        return [];
      }

      const relations: PlushieValueRelation[] = [];

      this.plushieRelations[plushieId].forEach((relationId) => {
        relations.push(this.plushieValueRelation[relationId]);
      });

      return relations;
    };
  }

  get getBodyPartByPlushieValueRelation(): GetBodyPartByPlushieValueRelation {
    return (relation: PlushieValueRelation) => {
      const value = this.value[relation.value];
      if (!value) {
        return;
      }

      return this.getBodyPartById(value.bodyPart);
    };
  }

  get getPlushieValueRelationsByPlushieIdAndBodyPartId(): GetPlushieValueRelationsByPlushieIdAndBodyPartId {
    return (plushieId: string, bodyPartId: string) => {
      if (!this.plushieRelations[plushieId]) {
        return [];
      }

      if (!this.value) {
        return [];
      }

      const relations: PlushieValueRelation[] = [];

      this.plushieRelations[plushieId].forEach((relationId) => {
        const relationValueId = this.plushieValueRelation[relationId].value;

        const value = this.value[relationValueId];

        if (value.bodyPart === bodyPartId) {
          relations.push(this.plushieValueRelation[relationId]);
        }
      });

      return relations;
    };
  }

  @Mutation
  updatePlushieValueRelation(payload: PlushieValueRelation): void {
    Vue.set(this.plushieValueRelation, payload.id, payload);

    if (this.plushieRelations[payload.plushie] === undefined) {
      return;
    }

    if (!this.plushieRelations[payload.plushie].includes(payload.id)) {
      this.plushieRelations[payload.plushie].push(payload.id);
    }
  }

  @Mutation
  updatePlushieRelations({
    plushieId,
    relations,
  }: {
    plushieId: string;
    relations: PlushieValueRelation[];
  }): void {
    const relationIds: string[] = [];

    relations.forEach((relation) => {
      Vue.set(this.plushieValueRelation, relation.id, relation);
      relationIds.push(relation.id);
    });

    Vue.set(this.plushieRelations, plushieId, relationIds);
  }

  @Mutation
  removePlushieRelation(id: string): void {
    const relation = this.plushieValueRelation[id];

    const plushieId = relation.plushie;

    delete this.plushieValueRelation[id];

    const index = this.plushieRelations[plushieId].indexOf(id);

    if (index !== -1) {
      this.plushieRelations[plushieId].splice(index, 1);
    }
  }

  @Action({ rawError: true })
  async deletePlushieValueRelation(id: string): Promise<void> {
    const valueRelation = this.plushieValueRelation[id];
    const plushieId = valueRelation.plushie;

    await DIContainer.BodyPartValuePlushieRelationRepository.deleteById(id);

    this.removePlushieRelation(id);

    void this.context.dispatch(
      "onBodyPartValuePlushieRelationChanged",
      plushieId
    );

    return;
  }

  @Action({ rawError: true })
  async loadPlushieValueRelationsByPlushieId({
    plushieId,
    useCache = true,
  }: {
    plushieId: string;
    useCache?: boolean;
  }): Promise<PlushieValueRelation[]> {
    let relations: PlushieValueRelation[] = [];

    if (useCache && this.plushieRelations[plushieId]) {
      const relationsIds = this.plushieRelations[plushieId];

      relationsIds.forEach((id) => {
        relations.push(this.plushieValueRelation[id]);
      });

      return relations;
    }

    relations = await DIContainer.BodyPartValuePlushieRelationRepository.getByPlushieId(
      plushieId
    );

    this.updatePlushieRelations({
      plushieId,
      relations,
    });

    return relations;
  }

  @Action({ rawError: true })
  async savePlushieValueRelation(
    relation: PlushieValueRelation
  ): Promise<PlushieValueRelation> {
    const item = await DIContainer.BodyPartValuePlushieRelationRepository.save(
      relation
    );

    this.updatePlushieValueRelation(item);
    removeConflictingPlushieValueRelations(
      this.context as BodyPartStoreActionContext,
      item
    );

    void this.context.dispatch(
      "onBodyPartValuePlushieRelationChanged",
      item.plushie
    );

    return item;
  }

  @Action({ rawError: true })
  async updateRelationValue(
    plushieValueRelationUpdate: PlushieValueRelationUpdate
  ): Promise<PlushieValueRelation> {
    const item = await DIContainer.BodyPartValuePlushieRelationRepository.updateValue(
      plushieValueRelationUpdate
    );

    this.updatePlushieValueRelation(item);

    void this.context.dispatch(
      "onBodyPartValuePlushieRelationChanged",
      item.plushie
    );

    return item;
  }

  // ################################### EVENTS #########################################

  @Action({ rawError: true })
  async onPlushieUpdated(plushieId: string): Promise<void> {
    if (!this.plushieRelations[plushieId]) {
      return;
    }

    const relations = await this.loadPlushieValueRelationsByPlushieId({
      plushieId,
      useCache: false,
    });

    if (!relations.length) {
      return;
    }

    const valuesIds = relations.map((relation) => relation.value);

    if (!valuesIds.length) {
      return;
    }

    const values = await this.loadValuesByIds(valuesIds);

    let bodyPartIds: string[] = [];

    valuesIds.forEach((id) => {
      const value = values[id];
      if (!value) {
        return;
      }

      bodyPartIds.push(value.bodyPart);
    });

    bodyPartIds = Array.from(new Set(bodyPartIds));

    await this.loadBodyPartsByIds(bodyPartIds);

    return;
  }

  // ################################### DATA WIPING #########################################

  @Mutation
  resetState(): void {
    const state = (dataStore.state as any)[name];

    if (state) {
      Object.assign(state, getDefaultState());
    }
  }
}
