





















































import Component, { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Inject } from "vue-property-decorator";

import SubmitButton from "@/lib/components/SubmitButton.vue";
import LoadingSpinner from "@/lib/components/LoadingSpinner.vue";
import FormErrors from "@/lib/components/FormErrors.vue";

import GeneralFormMixin from "@/lib/mixins/GeneralForm";
import IdGenerator from "@/lib/services/id-generator";
import PlushieStore from "@/modules/plushie/store";
import Upgrade from "@/modules/plushie/upgrade.model";
import dataStore from "@/store";

import UpgradeAllocationEditFormItem from "./EditForm/EditItem.vue";

import Factory from "../../factory.model";
import FactoryStore from "../../store";
import UpgradeAllocation from "../../upgrade-allocation.model";

interface EditItem {
  value: UpgradeAllocation;
  isNew: boolean;
  id: string;
}

@Component({
  components: {
    FormErrors,
    LoadingSpinner,
    SubmitButton,
    UpgradeAllocationEditFormItem,
  },
})
export default class UpgradeAllocationEditForm extends mixins(
  GeneralFormMixin
) {
  @Inject("IdGenerator")
  private fIdGenerator!: IdGenerator;

  private fIsLoading = false;

  private fFactoryDataStore: FactoryStore;
  private fPlushieDataStore: PlushieStore;

  private fAllocationItems: EditItem[] = [];

  get factories(): Factory[] {
    return this.fFactoryDataStore.activeFactoriesList;
  }

  get isLoading(): boolean {
    return this.fIsLoading;
  }

  get upgrades(): Upgrade[] {
    return this.fPlushieDataStore.upgradeListForFactories;
  }

  constructor() {
    super();
    this.fFactoryDataStore = getModule(FactoryStore, dataStore);
    this.fPlushieDataStore = getModule(PlushieStore, dataStore);
  }

  public getEditItemByFactoryAndUpgrade(
    factoryId: string,
    upgradeId: string
  ): EditItem | undefined {
    return this.fAllocationItems.find(
      (item) =>
        item.value.factory === factoryId && item.value.upgrade === upgradeId
    );
  }

  public onItemChange(
    factoryId: string,
    upgradeId: string,
    isChecked: boolean
  ): void {
    const item = this.getEditItemByFactoryAndUpgrade(factoryId, upgradeId);

    if (!item) {
      return;
    }

    item.value = new UpgradeAllocation(
      item.value.id,
      factoryId,
      upgradeId,
      isChecked
    );
  }

  protected created(): void {
    void this.loadData();
  }

  protected async performSubmit(): Promise<void> {
    const values = this.fAllocationItems.map((item) => item.value);

    const items = await this.fFactoryDataStore.saveUpgradeAllocations(values);
    this.fAllocationItems = this.createEditItems(items);
  }

  private createEditItems(values: UpgradeAllocation[]): EditItem[] {
    const allocationItems: EditItem[] = [];

    this.upgrades.forEach((upgrade) => {
      this.factories.forEach((factory) => {
        const item = values.find(
          (value) =>
            value.upgrade === upgrade.id && value.factory === factory.id
        );

        if (!item) {
          const value = new UpgradeAllocation(
            this.fIdGenerator.getId(),
            factory.id,
            upgrade.id,
            false
          );

          allocationItems.push({
            value: value,
            id: value.id,
            isNew: true,
          });

          return;
        }

        allocationItems.push({
          value: item,
          id: item.id,
          isNew: false,
        });
      });
    });

    return allocationItems;
  }

  private async loadData(): Promise<void> {
    this.fIsLoading = true;

    try {
      const [allocations] = await Promise.all([
        this.fFactoryDataStore.loadUpgradeAllocations(false),
        this.fFactoryDataStore.loadFactories(),
        this.fPlushieDataStore.loadUpgrades(),
      ]);

      this.fAllocationItems = this.createEditItems(allocations);
    } finally {
      this.fIsLoading = false;
    }
  }
}
